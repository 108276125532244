import React, { useState } from 'react';
import {
  Droppable,
  Draggable,
  DropResult,
  DragDropContext,
  DroppableProvided,
  DraggableProvided,
  DraggableStateSnapshot,
} from 'react-beautiful-dnd';
import './UserAssignmentForChecklist.scss';
import { TRANSLATIONS } from 'types/enums';
import { useTranslation } from 'react-i18next';
import InputWithLabel from 'components/Input/InputWithLabel';
import ImageLinks from 'utils/ImageLinks';

enum droppableAreas {
  supervisors = 'supervisors',
  availableUsers = 'availableUsers',
}

type Props = {
  draggableCards: any;
  setDraggableCards: (data: any) => void;
  supervisorsList: any[];
  setSupervisorsList: (list: any[]) => void;
};

function UserAssignmentForChecklist({
  draggableCards,
  setDraggableCards,
  supervisorsList,
  setSupervisorsList,
}: Props) {
  const { t } = useTranslation();
  const [userSearch, setUserSearch] = useState<string>('');
  const [searchedUserList, setSearchedUserList] = useState<any[]>([]);

  const onDragEnd = (result: DropResult) => {
    const { source, destination } = result;

    if (!destination) {
      return;
    }

    const listSourceKey = source.droppableId;
    const listDestinationKey = destination.droppableId;
    const isSearchedUser = userSearch.length > 0;

    /**
     * source is supervisor,
     * - remove from supervisor list
     * - get destination source
     * - check if not availableUsers dest
     * - If not, add to dc.assignedUsers[dest] (with duplicate check)
     * ---------
     * - If yes, already removed from supervisors list

     * destination is supervisor,
     * - is source available users
     * - If yes, then check for duplicates and add
     * ---------
     * - If no, check for duplicates and add
     * - If no duplicates, then remove from dc.assignedUsers[source]
     */

    if (listSourceKey === droppableAreas.supervisors
      && listDestinationKey === droppableAreas.supervisors
    ) return;

    // Here we only handle transactions of supervisors
    if (listSourceKey === droppableAreas.supervisors
      || listDestinationKey === droppableAreas.supervisors
    ) {
      if (listSourceKey === droppableAreas.supervisors) {
        const isDropAreaForAvailableUsers = listDestinationKey === droppableAreas.availableUsers;
        const movedItem = supervisorsList[source.index];
        const newSupervisorsList = supervisorsList
          .filter(s => Number(s.id) !== Number(movedItem.id));

        if (!isDropAreaForAvailableUsers) {
          // For dc.assignedUsers[destinationKey]
          const destinationUsers = draggableCards.assignedUsers[listDestinationKey];
          const userExists = destinationUsers
            ?.find((u: any) => Number(u?.id) === Number(movedItem?.id));

          if (!userExists && movedItem?.isEnabled) {
            destinationUsers.splice(destination.index, 0, movedItem);
            setDraggableCards((prev: any) => ({
              ...prev,
              assignedUsers: {
                ...prev.assignedUsers,
                [listDestinationKey]: destinationUsers,
              },
            }));
          }
        }
        setSupervisorsList(movedItem?.isEnabled || isDropAreaForAvailableUsers
          ? [...newSupervisorsList]
          : [...supervisorsList]);
      } else {
        // By default this condition only runs when destination is supervisors
        const isSourceAvailableUsers = listSourceKey === droppableAreas.availableUsers;
        if (isSourceAvailableUsers) {
          let sourceItems = draggableCards.availableUsers;
          let movedItem: any = null;

          if (isSearchedUser) {
            sourceItems = searchedUserList;
          }
          movedItem = sourceItems[source.index];

          const userExists = supervisorsList
            .findIndex(supervisor => Number(supervisor.id) === Number(movedItem.id));

          if (userExists < 0) {
            setSupervisorsList([...supervisorsList, movedItem]);
          }
        } else {
          // If user dragged from dc.assignedUsers
          const sourceItems = draggableCards.assignedUsers[listSourceKey];
          const movedItem = sourceItems[source.index];

          const userExists = supervisorsList
            .findIndex(supervisor => Number(supervisor.id) === Number(movedItem.id));

          if (userExists < 0) {
            setSupervisorsList([...supervisorsList, movedItem]);

            sourceItems.splice(source.index, 1);
            setDraggableCards((prev: any) => ({
              ...prev,
              assignedUsers: {
                ...prev.assignedUsers,
                [listSourceKey]: sourceItems,
              },
            }));
          }
        }
      }
      return;
    }

    // Below we only handle transactions between available users and checklists (assigned users)
    if (listSourceKey === listDestinationKey) {
      const items = Array.from(draggableCards[listSourceKey]
        || draggableCards.assignedUsers[listSourceKey]);
      const [reorderedItem] = items.splice(source.index, 1);
      items.splice(destination.index, 0, reorderedItem);

      if (!(listSourceKey === droppableAreas.availableUsers)) {
        setDraggableCards((prev: any) => ({
          ...prev,
          assignedUsers: {
            ...prev.assignedUsers,
            [listSourceKey]: items,
          },
        }));
      }
    } else {
      let sourceItems = Array.from(draggableCards.assignedUsers[listSourceKey]
          || draggableCards[listSourceKey]);

      const isUserDraggedFromAvailableUsersList = listSourceKey === droppableAreas.availableUsers;

      let movedItem: any = null;

      if (isUserDraggedFromAvailableUsersList && isSearchedUser) {
        sourceItems = searchedUserList;
        movedItem = sourceItems[source.index];
      } else {
        [movedItem] = sourceItems.splice(source.index, 1);
      }

      const destinationItems = Array.from(draggableCards.assignedUsers[listDestinationKey]
        || draggableCards[listDestinationKey]);
      destinationItems.splice(destination.index, 0, movedItem);

      if (listDestinationKey === droppableAreas.availableUsers) {
        setDraggableCards((prev: any) => ({
          ...prev,
          assignedUsers: {
            ...prev.assignedUsers,
            [listSourceKey]: sourceItems,
          },
        }));
      } else if (isUserDraggedFromAvailableUsersList) {
        if (!movedItem?.isEnabled) return;
        const existingUsers = draggableCards.assignedUsers[listDestinationKey];
        const userExists = existingUsers?.find((u: any) => Number(u?.id) === Number(movedItem?.id));
        if (!userExists) {
          setDraggableCards((prev: any) => ({
            ...prev,
            assignedUsers: {
              ...prev.assignedUsers,
              [listDestinationKey]: destinationItems,
            },
          }));
        }
      } else {
        if (!movedItem?.isEnabled) return;
        const existingUsers = draggableCards.assignedUsers[listDestinationKey];
        const userExists = existingUsers?.find((u: any) => Number(u?.id) === Number(movedItem?.id));
        if (!userExists) {
          setDraggableCards((prev: any) => ({
            ...prev,
            assignedUsers: {
              ...prev.assignedUsers,
              [listSourceKey]: sourceItems,
              [listDestinationKey]: destinationItems,
            },
          }));
        }
      }
    }
  };

  const handleUserSearch = (e: any) => {
    setUserSearch(e.target.value);
    const availableUsersFromSearch = draggableCards.availableUsers?.filter((user: any) => {
      if (user?.name?.toLowerCase().includes(e.target.value.toLowerCase())) {
        return user;
      }
    });
    setSearchedUserList(availableUsersFromSearch);
  };

  const activeAvailableUsersList = userSearch.length
    ? searchedUserList
    : draggableCards.availableUsers;

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <div className="user_assignment-droppables-container">
        <div className="user_assignment-available-users-container">
          <InputWithLabel
            onChange={handleUserSearch}
            placeholder={t(TRANSLATIONS.SEARCH)}
            value={userSearch}
            labelClassName="search-header"
            type="search"
            maxLength={50}
            inputClassName="search-input"
            containerClassName="user_assignment-search"
          />
          <Droppable droppableId={droppableAreas.availableUsers}>
            {(provided: DroppableProvided) => (
              <div
                ref={provided.innerRef}
                {...provided.droppableProps}
                className="user_assignment-checklist-droppable-container user_assignment-checklist-droppable-container-half-width"
              >
                <p>
                  {userSearch.length > 0
                    ? t(TRANSLATIONS.SEARCHED_USERS)
                    : t(TRANSLATIONS.ALL_USERS)}
                </p>
                {activeAvailableUsersList.map((user: any, index: number) => (
                  <Draggable
                    index={index}
                    key={`${user?.id}`}
                    draggableId={`available_users-${user?.id}`}
                  >
                    {(providedInner: DraggableProvided, snapshot: DraggableStateSnapshot) => (
                      <>
                        <div
                          ref={providedInner.innerRef}
                          {...providedInner.draggableProps}
                          {...providedInner.dragHandleProps}
                          className={`
                            user_assignment-user-list-card
                            ${!user?.isEnabled && 'user_assignement-user-not-available'}
                          `}
                          style={{
                            ...providedInner.draggableProps.style,
                            transform: snapshot.isDragging ? providedInner.draggableProps.style?.transform : 'translate(0px, 0px)',
                          }}
                          title={
                            `Name: ${user?.name}\nOrg: ${user?.registeredCompany}\nEmail: ${user?.email}`
                          }
                        >
                          <div className="user_assignment-icon-text-row-flex">
                            <p>{user?.name}</p>
                            <p className="user_assignement-user-company-name">
                              {user?.registeredCompany}
                            </p>
                            {
                              !user?.isEnabled && (
                                <p className="user_assignement-user-not-available">
                                  ({t(TRANSLATIONS.UNAVAILABLE)})
                                </p>
                              )
                            }
                          </div>
                          <p>{user?.email}</p>
                        </div>
                        {
                          snapshot.isDragging && (
                            <div
                              className="user_assignment-user-list-card"
                              style={{ transform: 'none !important' }}
                            >
                              <div className="user_assignment-icon-text-row-flex">
                                <p>{user?.name}</p>
                                <p className="user_assignement-user-company-name">
                                  {user?.registeredCompany}
                                </p>
                              </div>
                              <p>{user?.email}</p>
                            </div>
                          )
                        }
                      </>
                    )}
                  </Draggable>
                ))}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </div>

        <div className="user_assignment-right-droppables-container">
          {/* Supervisors box */}
          <Droppable droppableId={droppableAreas.supervisors}>
            {(provided: DroppableProvided) => (
              <div
                ref={provided.innerRef}
                {...provided.droppableProps}
                className="user_assignment-checklist-droppable-container"
              >
                <p>
                  <img
                    src={ImageLinks.espectro.star}
                    alt="supervisor-icon"
                    width={18}
                    height={18}
                  />
                  {t(TRANSLATIONS.SUPERVISORS)}
                </p>
                {supervisorsList.map((user: any, index: number) => (
                  <Draggable
                    key={`${user?.id}`}
                    draggableId={`supervisors-${user?.id}`}
                    index={index}
                  >
                    {(providedInner: DraggableProvided) => (
                      <div
                        title={
                          `Name: ${user?.name}\nOrg: ${user?.registeredCompany}\nEmail: ${user?.email}`
                        }
                        ref={providedInner.innerRef}
                        {...providedInner.draggableProps}
                        {...providedInner.dragHandleProps}
                        className="user_assignment-user-list-card"
                      >
                        <div className="user_assignment-icon-text-row-flex">
                          <p>{user?.name}</p>
                          <p className="user_assignement-user-company-name">
                            {user?.registeredCompany}
                          </p>
                        </div>
                        <p>{user?.email}</p>
                      </div>
                    )}
                  </Draggable>
                ))}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
          {/* Checklist box */}
          {
            Object.keys(draggableCards.assignedUsers)?.map((checklistTitle: string) => (
              <Droppable droppableId={checklistTitle}>
                {(provided: DroppableProvided) => (
                  <div
                    ref={provided.innerRef}
                    {...provided.droppableProps}
                    className="user_assignment-checklist-droppable-container"
                  >
                    <p>{checklistTitle}</p>
                    {draggableCards.assignedUsers[checklistTitle]
                      .map((user: any, index: number) => (
                        <Draggable
                          key={`${user?.id}`}
                          draggableId={`assigned_users-${checklistTitle}-${user?.id}`}
                          index={index}
                        >
                          {(providedInner: DraggableProvided) => (
                            <div
                              ref={providedInner.innerRef}
                              {...providedInner.draggableProps}
                              {...providedInner.dragHandleProps}
                              className="user_assignment-user-list-card"
                              title={
                                `Name: ${user?.name}\nOrg: ${user?.registeredCompany}\nEmail: ${user?.email}`
                              }
                            >
                              <div className="user_assignment-icon-text-row-flex">
                                <p>{user?.name}</p>
                                <p className="user_assignement-user-company-name">
                                  {user?.registeredCompany}
                                </p>
                              </div>
                              <p>{user?.email}</p>
                            </div>
                          )}
                        </Draggable>
                      ))}
                    {provided.placeholder}
                  </div>
                )}
              </Droppable>
            ))
          }
        </div>
      </div>
    </DragDropContext>
  );
}

export default UserAssignmentForChecklist;
