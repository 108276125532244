import React, { useState } from 'react';
import { NavLink as Link } from 'react-router-dom';
import HighlightButton from 'components/Button/HighlightButton';
import request from 'utils/request';
import LoadingSpinner from 'components/LoadingSpinner';

import 'features/internal-users/user/RegisterNewAccount/RegisterNewAccount.scss';
import { useTranslation } from 'react-i18next';
import { TRANSLATIONS } from 'types/enums';
import Input from 'components/Input';
import { Checkbox, CheckboxProps, withStyles, Select, MenuItem } from '@material-ui/core';
import CircleCheckedFilled from '@material-ui/icons/CheckCircle';
import CircleUnchecked from '@material-ui/icons/RadioButtonUnchecked';
import i18next from 'i18next';
import { isEspectro } from '../../../../constants';

type State = {
  password: string;
  passwordConfirm: string;
  submitting: boolean;
  errors: any;
  email: string;
  isLoadingUserInfo: boolean;
  passwordChanged: boolean;
};

const RegisterNewAccount = () => {
  const nameError = {
    msg: '',
  };
  const surnameError = {
    msg: '',
  };
  const TermsAndConditionsError = {
    msg: '',
  };
  const emailError = {
    msg: '',
  };
  const OperationTypeError = {
    msg: '',
  };

  const [submitting, setSubmitting] = useState(false);
  const [name, setName] = useState('');
  const [surname, setSurname] = useState('');
  const [secondSurname, setSecondSurname] = useState('');
  const [email, setEmail] = useState('');
  const [TermsAndConditions, setTermsAndConditions] = useState(false);
  // errors
  const [nameErr, setNameErr] = useState(nameError);
  const [surnameErr, setSurnameErr] = useState(surnameError);
  const [emailErr, setEmailErr] = useState(emailError);
  const [operationType, setOperationType] = useState();
  const [TermsAndConditionsErr, setTermsAndConditionsErr] = useState(TermsAndConditionsError);
  const [OperationTypeErr, setOperationTypeErr] = useState(OperationTypeError);
  const { t } = useTranslation();

  const operationTypeOptions = [
    {
      id: 1,
      name: t(TRANSLATIONS.DISTRIBUTION_CENTER),
    },
    {
      id: 2,
      name: t(TRANSLATIONS.FACTORY),
    },
  ];

  const OrangeCheckbox = withStyles({
    root: {
      '&$checked': {
        color: '#EFBE3F',
      },
    },
    checked: {},
  })((MUIRadioProps: CheckboxProps) => <Checkbox color="default" {...MUIRadioProps} />);

  const submit = async () => {
    setNameErr(nameError);
    setSurnameErr(surnameError);
    setEmailErr(emailError);
    setTermsAndConditionsErr(TermsAndConditionsError);
    setOperationTypeErr(OperationTypeError);
    if (!isEspectro && name.length < 2) {
      setNameErr({ msg: `${t(TRANSLATIONS.NAME)}  ${t(TRANSLATIONS.TOO_SHORT)}` });
      return;
    }
    if (!isEspectro && surname.length < 2) {
      setSurnameErr({ msg: `${t(TRANSLATIONS.FIRST_SURNAME)} ${t(TRANSLATIONS.TOO_SHORT)}` });
      return;
    }
    if (email.length < 7) {
      setEmailErr({ msg: `${t(TRANSLATIONS.EMAIL)} ${t(TRANSLATIONS.TOO_SHORT)}` });
      return;
    }
    if (!email.includes('@')) {
      setEmailErr({ msg: `${t(TRANSLATIONS.ENTER_A_CORRECT_EMAIL_FORMAT)}` });
      return;
    }
    if (!TermsAndConditions) {
      setTermsAndConditionsErr({ msg: t(TRANSLATIONS.YOU_MUST_ACCEPT_TERMS_AND_CONDITIONS) });
      return;
    }
    if (!isEspectro && !operationType) {
      setOperationTypeErr({ msg: t(TRANSLATIONS.YOU_MUST_ACCEPT_TERMS_AND_CONDITIONS) });
      return;
    }
    const userName = `${name.trim()} ${surname.trim()} ${secondSurname?.trim() ?? ''}`.trim();
    try {
      const url = '/api/v1/users/register-by-email';

      if (!isEspectro) {
        await request.post(url, { email, userName, lng: i18next.language, operationType: 1 });
      } else {
        await request.post(url, { email, lng: i18next.language, operationType: 1 });
      }

      setTimeout(() => {
        setSubmitting(true);
      }, 250);
    } catch (e) {
      console.log('Error: ', e);
    }
  };

  const handleInputChangeName = (e: any) => {
    setName(e.target.value);
  };
  const handleInputChangeSurname = (e: any) => {
    setSurname(e.target.value);
  };
  const handleInputChangeSecondSurname = (e: any) => {
    setSecondSurname(e.target.value);
  };
  const handleInputChangeEmail = (e: any) => {
    setEmail(e.target.value);
  };
  const handleOperationTypeChange = (e: any) => {
    setOperationType(e.target.value);
  };

  const enterSubmit = (e: React.KeyboardEvent<HTMLFormElement>) => {
    if (e.keyCode === 13 && !e.shiftKey) {
      e.preventDefault();
      e.stopPropagation();
      submit();
    }
  };

  const handleCheckbox = (event: any) => {
    setTermsAndConditions(event.target.checked);
  };

  const openInNewTab = (url: string) => {
    window.open(url, '_blank', 'noopener,noreferrer');
  };

  const goToLogin = () => {
    window.open('/login', '_self', 'noopener,noreferrer');
  };

  return (
    <div className="register-bg">
      <div className="register">
        <div className="container">
          <div className="row">
            <div className="col-md-6 offset-md-3 card main-card-container">
              {!submitting ? (
                <p className="header-text">
                  {t(TRANSLATIONS.REGISTER_ON)}<br /><span className="font-bold">{isEspectro ? 'Espectro' : 'Safety 360'}</span>
                </p>
              ) : (
                <p className="header-text text-center font-bold">
                  {t(TRANSLATIONS.CHECK_YOUR_EMAIL)}
                </p>
              )}
              {!submitting ? (
                <>
                  <form onSubmit={submit} onKeyDown={enterSubmit}>
                    {!isEspectro
                      && (
                      <>
                        <p className="input-label">
                          {t(TRANSLATIONS.NAME)}*
                        </p>
                        <Input
                          type="text"
                          placeholder="Julio"
                          onChange={text => handleInputChangeName(text)}
                          value={name}
                          error={nameErr.msg}
                          errorHeight
                          name="name"
                        />
                      </>
                      )}
                    {!isEspectro
                      && (
                      <>
                        <p className="input-label">
                          {t(TRANSLATIONS.FIRST_SURNAME)}*
                        </p>
                        <Input
                          type="text"
                          placeholder="López"
                          onChange={text => handleInputChangeSurname(text)}
                          value={surname}
                          error={surnameErr.msg}
                          errorHeight
                          name="surname"
                        />
                      </>
                      )}
                    {!isEspectro
                      && (
                      <>
                        <p className="input-label">
                          `{t(TRANSLATIONS.SECOND_SURNAME)} ({t(TRANSLATIONS.OPTIONAL)})
                        </p>
                        <Input
                          type="text"
                          placeholder="Moreno"
                          onChange={text => handleInputChangeSecondSurname(text)}
                          value={secondSurname}
                          errorHeight
                          name="secondSurname"
                        />
                      </>
                      )}
                    <p className="input-label">
                      {t(TRANSLATIONS.EMAIL)}*
                    </p>
                    <Input
                      type="email"
                      placeholder="JaneDoe@example.com"
                      onChange={text => handleInputChangeEmail(text)}
                      value={email}
                      error={emailErr.msg}
                      errorHeight
                      name="email"
                    />
                    {!isEspectro
                      && (
                      <>
                        <p className="input-label">
                          {t(TRANSLATIONS.OPERATION)}*
                        </p>
                        <Select
                          value={operationType}
                          onChange={index => handleOperationTypeChange(index)}
                          style={{ minWidth: '164px' }}
                        >
                          {operationTypeOptions?.map((item: any) => {
                            return (
                              <MenuItem key={item.name} value={item.id}>
                                {item.name}
                              </MenuItem>
                            );
                          })}
                        </Select>
                      </>
                      )}
                    {OperationTypeErr.msg && (
                      <p className="textError">
                        {t(TRANSLATIONS.YOU_MUST_ENTER_YOUR_OPERATION_TYPE)}
                      </p>
                    )}
                  </form>
                  <p className="detail-text">
                    {t(TRANSLATIONS.ALREADY_HAVE_AN_ACCOUNT)} &nbsp;
                    <Link
                      to="/login"
                      className="link"
                      tabIndex={-1}
                    >
                      {t(TRANSLATIONS.LOGIN)}
                    </Link>
                  </p>
                  <div className="chechbox-container">
                    <OrangeCheckbox
                      icon={<CircleUnchecked />}
                      checkedIcon={<CircleCheckedFilled />}
                      onChange={e => handleCheckbox(e)}
                      checked={TermsAndConditions}
                    />
                    <p className="styleText">
                      <p onClick={() => openInNewTab('/privacy-policy')}>
                        {t(TRANSLATIONS.TERMS_AND_CONDITIONS)}
                      </p>
                    </p>
                  </div>
                  {TermsAndConditionsErr.msg && (
                    <p className="textError">
                      {t(TRANSLATIONS.YOU_MUST_ACCEPT_TERMS_AND_CONDITIONS)}
                    </p>
                  )}
                  <HighlightButton
                    text={t(TRANSLATIONS.REGISTER).toUpperCase()}
                    className="main-action"
                    onClick={submit}
                  />
                  {submitting && <LoadingSpinner className="register-spinner" />}
                </>
              ) : (
                <>
                  <p className="text-center">
                    {t(TRANSLATIONS.FOLLOW_THE_INSTRUCTIONS_RECEIVED)}
                  </p>
                  <div className="text-center">
                    <HighlightButton
                      text={t(TRANSLATIONS.GO_TO_LOGIN).toUpperCase()}
                      className="main-action"
                      onClick={() => goToLogin()}
                    />
                  </div>
                </>
              )}
            </div>
          </div>
          <div className="row mt-5">
            <div className="footer">
              <p className="footer-text"> powered by ARVOLUTION </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RegisterNewAccount;
