/* eslint-disable jsx-a11y/no-noninteractive-element-to-interactive-role */
import { Button, CircularProgress } from '@material-ui/core';
import i18next from 'i18n';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { TRANSLATIONS } from 'types/enums';

const userLanguage = i18next.language?.indexOf('en') === 0 ? 'en' : 'es';

interface Props{
  comment: string;
  inputRef: any;
  isLoading: Boolean;
  onSave: () => void;
  onCancel: () => void;
}

export default function CommentInput({ comment, inputRef, isLoading, onSave, onCancel }: Props) {
  const [isInvalidComment, setIsInvalidComment] = useState(false);
  const { t } = useTranslation();
  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.innerText = comment;
      inputRef.current.focus();
    }
  }, [comment, inputRef]);

  useEffect(() => {
    let invalidCommentTimeout: null | NodeJS.Timeout = null;
    if (isInvalidComment) {
      invalidCommentTimeout = setTimeout(() => {
        setIsInvalidComment(false);
      }, 2000);
    }
    return () => {
      if (invalidCommentTimeout) {
        clearTimeout(invalidCommentTimeout);
      }
    };
  }, [isInvalidComment]);

  function handleOnSave() {
    if (inputRef.current) {
      if (inputRef.current.innerText.length > 250) {
        setIsInvalidComment(true);
        return;
      }
      setIsInvalidComment(false);
      onSave();
    }
  }

  return (
    <div>
      <div role="textbox" className="actionlog-comment-input">
        <p>
          <span
            className={`actionlog-comment-textarea ${userLanguage} ${isInvalidComment ? 'invalid-comment' : ''}`}
            role="textbox"
            contentEditable
            ref={inputRef}
          />
          {
            isInvalidComment && (
              <span className="invalid-comment-description">
                {t(TRANSLATIONS.COMMENT_SHOULD_BE_UNDER_N_CHARACTER, {
                  maxSize: 250,
                })}
              </span>
            )

          }
        </p>
      </div>
      <div className="action-area">
        <Button variant="contained" onClick={handleOnSave} className="save-button">
          {isLoading ? <CircularProgress color="inherit" size={20} /> : t(TRANSLATIONS.SAVE)}
        </Button>
        {!isLoading && (
          <Button type="button" onClick={onCancel} className="cancel-button">
            {t(TRANSLATIONS.CANCEL)}
          </Button>
        )}
      </div>
    </div>
  );
}
