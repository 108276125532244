import React from 'react';

import TileWithImage from 'components/TileWithImage/TileWithImage';
import LoadingSpinner from 'components/LoadingSpinner';

import safetyAcademyLogo from 'assets/icons/safety-academy-logo.png';

import './ListWithTitle.scss';

const ListWithTitle = (
  { list, text, loading } : {
    list: Array<{
      bannerImage: string,
      title:string,
      content:string,
      slug:string
    }>,
    text: string,
    loading: boolean,
  },
) => (
  <div className="list-with-title">
    <span className="list-with-title__title">
      {text}
    </span>
    {loading ? (
      <LoadingSpinner />
    ) : (
      <ul className="list-with-title__list">
        {list?.length > 0 && list.map(({ bannerImage, title, content, slug }) => (
          <TileWithImage
            img={bannerImage || safetyAcademyLogo}
            title={title}
            subtitle={content}
            key={`${title} at ${content}`}
            slug={slug}
          />
        ))}
      </ul>
    )}
  </div>
);

export default ListWithTitle;
