import React, { useEffect, useRef, useState, useCallback } from 'react';
// @ts-ignore
import './WebPortal5S.scss';
import BasicButton from 'components/Button/BasicButton';
import { useSelector } from 'react-redux';
import { selectLocationsToAccess, selectSelectedLocationsSlug } from 'store/selectors/globalFilters.selectors';
import LoadingSpinner from 'components/LoadingSpinner';
import { useTranslation } from 'react-i18next';
import request from 'utils/request';
import { toast } from 'react-toastify';
import Iframe from 'components/Iframe';
import ChartTabs from 'components/Tabs/ChartTabs';
import Modal from 'components/Modal';
import ProgressBar from 'components/ProgressBar';
import { TRANSLATIONS } from 'types/enums';
import axios from 'axios';
import Header from 'components/Header';

export enum SECTION_TYPES {
  Seleccionar = 'Clasificar',
  Ordenar = 'Ordenar',
  Limpiar = 'Limpiar',
  Estandarizar = 'Estandarizar',
  Disciplina = 'Disciplina',

}

export enum UploadErrorMessage {
  CANCEL_FILE_UPLOAD = 'CANCEL_FILE_UPLOAD',
}

const WebPortal5S = () => {
  const { t } = useTranslation();
  const tabs = [
    { title: t(TRANSLATIONS.OPERATIONAL), value: '', type: TRANSLATIONS.OPERATIONAL, index: 0 },
    { title: t(TRANSLATIONS.CORPORATE), value: '', type: TRANSLATIONS.CORPORATE, index: 1 },
  ];
  const [selectedTab, setSelectedTab] = useState(tabs[0]);
  const [uploading, setLoading] = useState(false);
  const [showDownloadModal, setShowDownloadModal] = useState<boolean>(false);
  const [showProgressBar, setShowProgressBar] = useState<boolean>(false);
  const [progressPercentage, setProgressPercentage] = useState<number>(0);

  const fileRef = useRef<HTMLInputElement>(null);
  const [downloadError, setDownloadError] = useState<boolean>(false);
  const [sentViaEmail, setSentViaEmail] = useState<boolean>(false);

  const fileDownloadLink = 'https://arvolution-safety-development.s3.us-east-2.amazonaws.com/5S/PlanAnual02022023.xlsx';

  const [isOpen, setIsOpen] = useState<boolean>(false);

  const locations = useSelector(selectLocationsToAccess);
  const accessLevel = useSelector(selectSelectedLocationsSlug);
  const [, setFilter] = useState<{ type: string, ids?: string }>({ type: 'bus' });

  const handleAttachmentsUpload = async (e: any) => {
    setLoading(true);
    const { files } = e.target;
    if (files?.length) {
      const config = {
        headers: { 'content-type': 'multipart/form-data' },
      };
      const date = new Date().getFullYear().toString();
      const data = new FormData();

      data.set('file', files[0]);
      data.set('year', date);

      try {
        await request.post('/api/v1/upload-checklist-users', data, config);
        setLoading(false);
        toast.success('Uploaded Successfully!');
        if (fileRef.current !== null) {
          fileRef.current.value = '';
        }
      } catch (err) {
        toast.error('Error while uploading');
        console.log('error para David del file upload: ', err);
      } finally {
        setLoading(false);
      }
    }
  };

  useEffect(() => {
    if (!accessLevel.businessUnit) {
      setFilter({ type: 'bus' });
    } else if (accessLevel.businessUnit && !accessLevel.zone) {
      setFilter({ type: 'zones' });
    } else if (accessLevel.zone && !accessLevel.subzone) {
      setFilter({ type: 'subzones' });
    } else if (accessLevel.subzone && !accessLevel.location) {
      setFilter({ type: 'locations' });
    } else {
      setFilter({ type: 'location' });
    }
  }, [accessLevel]);

  const handleCloseInstructions = () => setIsOpen(false);
  const handleUploadButton = () => {
    if (fileRef.current !== null) {
      fileRef.current.click();
    }
  };

  const handleOpenInstructions = () => setIsOpen(true);

  const closeModal = () => {
    setSentViaEmail(false);
    setDownloadError(false);
    setShowDownloadModal(false);
    setShowProgressBar(false);
    setProgressPercentage(0);
  };

  const onClickDownload = () => {
    setShowDownloadModal(true);
  };

  const downloadFiveSExcel = useCallback(
    async () => {
      try {
        setSentViaEmail(false);
        setDownloadError(false);
        setShowDownloadModal(true);
        setShowProgressBar(true);
        const url = `/api/v1/5s/excel?locationIds=${JSON.stringify(locations)}`;
        const res = await axios({
          url,
          responseType: 'blob',
          method: 'GET',
          onDownloadProgress: (progressEvent: any) => {
            const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
            setProgressPercentage(percentCompleted);
          },
        });
        const isJsonBlob = (resData: any) => resData instanceof Blob && resData.type === 'application/json';
        const responseData = isJsonBlob(res?.data) ? await (res?.data)?.text() : res?.data || {};
        const responseJson = (typeof responseData === 'string') ? JSON.parse(responseData) : responseData;
        if (responseJson?.sentViaEmail) {
          return setSentViaEmail(true);
        }
        const downloadUrl = URL.createObjectURL(new Blob([responseData], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' }));
        const a = document.createElement('a');
        a.href = downloadUrl;
        setShowDownloadModal(false);
        setShowProgressBar(false);
        setProgressPercentage(0);
        a.click();
      } catch (error) {
        setDownloadError(true);
        setProgressPercentage(0);
      }
    }, [locations],
  );

  return (
    <div className="container-fluid overview-wrapper">
      {showDownloadModal && (
      <Modal
        modalContainerStyles={{ width: '70vw',
          left: '20%',
          maxHeight: '90vh',
          minHeight: '20vh',
          height: 'fit-content',
          display: 'flex',
          flexDirection: 'column',
          position: 'absolute',
          top: '50%',
          transform: 'translateY(-50%)' }}
        onOverlayClick={closeModal}
      >
        <div className="downloadMoal">
          <p className="downloadMoal__title">{downloadError ? `${t(TRANSLATIONS.SOMETHING_WENT_WRONG)}...` : t(TRANSLATIONS.DOWNLOAD_DATA)}</p>
          {showProgressBar ? (
            <>
              <p className="downloadMoal__downloading_title">{downloadError ? t(TRANSLATIONS.DOWNLOAD_FAIL_RETRY_THE_DOWNLOAD_PLEASE) : sentViaEmail ? t(TRANSLATIONS.THE_DATA_IS_TAKING_A_LONG_TIME_TO_DOWNLOAD_A_DOWNLOAD_LINK_WILL_BE_SENT_TO_YOUR_EMAIL_TO_CONTINUE) : t(TRANSLATIONS.DOWNLOADING_DATA)}</p>
              {!downloadError && !sentViaEmail && (
                <div className="downloadMoal__progress_bar">
                  <p className="downloadMoal__progress_bar__title">{progressPercentage}%</p>
                  <ProgressBar bgcolor="#4472C4" height={16} progress={progressPercentage} />
                </div>
              )}
            </>
          ) : (
            <>
              <p className="downloadMoal__description">{t(TRANSLATIONS.THE_DATA_FOR_THE_LOCATIONS_THAT_ARE_FILTERED_IN_THE_TOP_FILTER_BAR_WILL_BE_DOWNLOADED)}</p>
              <div className="downloadMoal__header">
                <Header showLogo={false} showSettings={false} />
              </div>
            </>
          )}
          <div className="downloadMoal__button_container">
            {!sentViaEmail && (
              downloadError ? (
                <BasicButton text={t(TRANSLATIONS.RETRY_AGAIN)} className="downloadMoal__button_container__download_button" onClick={downloadFiveSExcel} />
              ) : (
                <BasicButton disabled={showProgressBar} text={t(TRANSLATIONS.DOWNLOAD)} className="downloadMoal__button_container__download_button" onClick={downloadFiveSExcel} />
              )
            )}
            <BasicButton text={t(TRANSLATIONS.CANCEL)} className="downloadMoal__button_container__cancel_button" onClick={closeModal} />
          </div>
        </div>
      </Modal>
      )}
      <div className="checklist_section__body">
        {isOpen && (
          <>
            <div className="checklist_section_instructions">
              <h2>Instrucciones</h2>
              <BasicButton text="Cancelar" className="checklist_section_button_filled" onClick={handleCloseInstructions} />
            </div>
            <div>
              El plan anual se subirá solamente una vez al año,
              para ello se recomienda seguir las instrucciones siguientes:
            </div>
            <ol>
              <li>
                Si aún no se tiene la plantilla a subir correcta,
                será necesario descargarla pulsando “Descargar plantilla”

                <ul>
                  <li>
                    Seleccionar la BU
                  </li>
                  <li>
                    Seleccionar el centro de distribución
                  </li>
                  <li>
                    Seleccionar el área deseada
                  </li>
                  <li>
                    Seleccionar las subárea donde se aplicaran 5S
                  </li>
                  <li>
                    Seleccionar el checklist a aplicarse en esta locación específica
                  </li>
                  <li>
                    En el cruce con de las filas con las columnas de los meses se estará
                    ingresando el ID del empleado que estará aplicando dicho checklist
                  </li>
                </ul>

              </li>
              <li>
                Una vez se tenga la plantilla con los datos esperados,
                se deberá asegurar que no se muestren ningún tipo de errores en el archivo,
                de mostrarse alguno de ellos se
                deberá cambiar de opción seleccionada por una válida.
              </li>
              <li>
                Ahora que se tiene el archivo de manera correcta se puede proceder a subirla
              </li>
              <li>
                Para ello se deberá dar clic en la opción “Subir plantilla”
              </li>
              <li>
                El siguiente paso será seleccionar el archivo del plan maestro,
                y subirlo a la plataforma en el apartado correspondiente,
                de ser exitoso se podrá observar las asignaciones,
                de no ser así se deberá volver al paso 1
              </li>
            </ol>

            <div>
              {uploading && <LoadingSpinner />}
              <a target="_blank" rel="noreferrer" href={fileDownloadLink} title={fileDownloadLink} data-renderer-mark="true">
                <BasicButton text="Descargar plantilla" className="checklist_section_button" />
              </a>

              <BasicButton
                text={uploading ? 'Cargando artículo' : 'Subir plantilla'}
                className="checklist_section_button_filled"
                onClick={handleUploadButton}
              />
              <input
                ref={fileRef}
                className="hidden"
                type="file"
                onChange={handleAttachmentsUpload}
                name="file"
                multiple
                accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
              />
            </div>
          </>
        )}
        {!isOpen && (
          <>
            <div className="chart_header">
              <h2>Resultados 5s</h2>
              <div className="chart_filters">
                <BasicButton text={t(TRANSLATIONS.DOWNLOAD_DATA)} className="checklist_section_download_button_filled" onClick={onClickDownload} />
                <BasicButton text="Ingresar calendarización" className="checklist_section_button_filled" onClick={handleOpenInstructions} />
                {/* <div className="drop-downs-rowed">
                  <ChartDropdown
                    onChange={handleMonthChange}
                    selectedDropDownItem={selectedMonth}
                    dropDownItems={getMonthsDropDown(t) as DropYearAndMonthDropDown[]}
                    dropDownOpen={periodDropDown}
                    setDropDownOpen={setPeriodDropDown}
                    labelClassName="label-default"
                  />
                  <ChartDropdown
                    onChange={handleYearChange}
                    selectedDropDownItem={selectedYear}
                    dropDownItems={
                      getYearDropDownFromBaseYear() as DropYearAndMonthDropDown[]
                    }
                    dropDownOpen={yearDropDown}
                    setDropDownOpen={setYearDropDown}
                    labelClassName="label-default"
                  />
                </div> */}
              </div>
            </div>
            <ChartTabs
              tabsItems={tabs}
              selectedTab={selectedTab}
              setSelectedTab={setSelectedTab as any}
              selectedTabStyle="selected-tab"
              customStyle="tabs-container"
            />
            {selectedTab.index === 0 && (
              <Iframe
                iframe="https://app.powerbi.com/reportEmbed?reportId=ff275f09-0ded-464f-97fe-52d300c9e11c&autoAuth=true&ctid=cef04b19-7776-4a94-b89b-375c77a8f936"
                iframeTitle="5S"
                pageName="ReportSection"
              />
            )}

            {selectedTab.index === 1 && (
              <Iframe
                iframe="https://app.powerbi.com/reportEmbed?reportId=ff275f09-0ded-464f-97fe-52d300c9e11c&autoAuth=true&ctid=cef04b19-7776-4a94-b89b-375c77a8f936"
                iframeTitle="5S"
                pageName="ReportSection39fb258a46b8c4f7de30"
              />
            )}
            {/* <div>
              {loading ? <div className="chart_body"> <LoadingSpinner /> </div> : (
                <>
                  <div className="chart">
                    {!buData?.length ? <EmptyState /> : <Chart series={data.series} type="bar"
                    options={data.options} height={300} width={600} />}
                  </div>
                  <div className="table">
                    <ReactTable
                      data={filter?.type === 'location' ? locationBuData : buData}
                      columns={columns}
                      showPagination={false}
                      className="-striped max-height"
                      NoDataComponent={EmptyState}
                    />
                  </div>
                </>
              )}
            </div> */}
          </>
        )}
      </div>
    </div>
  );
};
export default WebPortal5S;
