import React from 'react';

import './CategoryContainer.scss';

export type CategoryContainerProps = {
  categoryTitle: string;
  categorySubtitle: string;
  categoryImage: any;
  categoryPercentage: number;
};

const CategoryContainer = (props: CategoryContainerProps) => {
  const {
    categoryTitle,
    categorySubtitle,
    categoryImage,
    categoryPercentage,
  } = props;

  const className1 = categoryPercentage >= 90 ? 'category-percentage-completed' : categoryPercentage >= 80 ? 'category-percentage-warning' : 'category-percentage-error';

  return (
    <div className="main-container">
      <div className="category-title-container">
        <div className="category-img-container">
          <img src={categoryImage} alt="user" width={33} height={50} />
        </div>
        <div className="right-section">
          <p className="category-title">{categoryTitle}</p>
          <p className="category-subtitle">{categorySubtitle}</p>
        </div>
      </div>
      <p className={className1}>{`${categoryPercentage}%`}</p>
    </div>
  );
};

export default CategoryContainer;
