import React from 'react';
import { Backdrop, Modal } from '@material-ui/core';
import './ValueOutOfBoundsModalStyle.scss';
import { TRANSLATIONS } from 'types/enums';
import { useTranslation } from 'react-i18next';
import styles from './styles';

type Props = {
  isOpen: boolean;
  onPressNo: () => void;
  onPressYes: () => void;
  isOpenToggle: () => void;
};

const ValueOutOfBoundsModal = ({
  isOpen,
  onPressNo,
  onPressYes,
  isOpenToggle,
}: Props) => {
  const { t } = useTranslation();

  const onCloseCallback = (_: {}, reason: 'backdropClick' | 'escapeKeyDown') => {
    if (!(reason === 'backdropClick' || reason === 'escapeKeyDown')) {
      isOpenToggle();
    }
  };

  return (
    <Modal
      open={isOpen}
      onClose={onCloseCallback}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 1,
        onClick: () => null,
      }}
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
    >
      <div
        style={styles.valueOutOfBoundsModalContainer as any}
      >
        <div className="value_out_of_bounds__overlay_head">
          <p className="value_out_of_bounds__title">
            {t(TRANSLATIONS.VALUE_OUT_OF_RANGE)}
          </p>

          <div
            className="value_out_of_bounds_date_picker_container"
          >
            <p>
              {t(TRANSLATIONS.VALUE_OUT_OF_RANGE_WISH_TO_CONTINUE)}
            </p>
          </div>

          <div className="value_out_of_bounds-buttons-wrapper">
            <button
              type="button"
              onClick={onPressNo}
              className="value_out_of_bounds-save-button value_out_of_bounds-reverse-style"
            >
              {t(TRANSLATIONS.NO)}
            </button>
            <button
              type="button"
              onClick={onPressYes}
              className="value_out_of_bounds-save-button"
            >
              {t(TRANSLATIONS.YES)}
            </button>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default ValueOutOfBoundsModal;
