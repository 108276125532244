import React, { useCallback, useState, useEffect } from 'react';
import { useDropzone } from 'react-dropzone';
import { LinearProgress } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { TRANSLATIONS } from 'types/enums';
import BasicButton from 'components/Button/BasicButton';
import { ReactComponent as DocsIcon } from '../../assets/icons/docs-icon.svg';
import './FileDropZoneStyles.scss';
import useFileUploader from './useFileUploader';

interface FileDropZoneProps{
  fetchSignedS3Url: () => Promise<string | undefined>,
  onFinishUploading: () => Promise<void>
  maxSize: number,
  accept: string | string[],
  S3fileUrl: string | undefined,
}

const FileDropZone = (props: FileDropZoneProps) => {
  const { maxSize, fetchSignedS3Url, onFinishUploading, accept, S3fileUrl } = props;
  const [file, setFile] = useState<File | null>(null);
  const { t } = useTranslation();

  const [status, setStatus] = useState<{
    rejected: boolean;
  }>({
    rejected: false,
  });

  const [uploadStatus, executeUploading, cancelUpload] = useFileUploader();

  useEffect(() => {
    if (uploadStatus.uploaded) {
      onFinishUploading();
    }
  }, [onFinishUploading, uploadStatus.uploaded]);

  const onDrop = useCallback(acceptedFiles => {
    if (!acceptedFiles.length) {
      return setStatus(previousStatus => ({
        ...previousStatus,
        rejected: true,
      }));
    }
    setStatus(previousStatus => ({
      ...previousStatus,
      rejected: false,
    }));
    setFile(acceptedFiles[0]);
  }, []);

  async function uploadFile() {
    if (!file) return;
    setStatus(previousState => ({
      ...previousState,
      isUploading: true,
    }));
    const s3FileUrl = S3fileUrl || (await fetchSignedS3Url());
    executeUploading({ s3FileUrl, data: file });
  }

  function onCancelUpload() {
    cancelUpload();
    setFile(null);
  }

  const {
    isDragActive,
    getRootProps,
    getInputProps,
    isDragReject,
    rejectedFiles,
  } = useDropzone({
    onDrop,
    accept,
    minSize: 0,
    maxSize,
  });

  function renderDescription() {
    if (rejectedFiles.length > 0 && rejectedFiles[0].size > maxSize) {
      return (
        <p className="text-danger mt-2">
          {t(TRANSLATIONS.FILE_IS_TOO_LARGE)}.
          {maxSize
                && t(TRANSLATIONS.MAXIMUM_ALLOWED_FILE_SIZE, {
                  size: `${(maxSize / 1048576).toFixed(2)} MB`,
                })}
        </p>
      );
    }
    if (!isDragActive) return t(TRANSLATIONS.CLICK_HERE_DROP_TO_UPLOAD);
    if (isDragActive && !isDragReject) return t(TRANSLATIONS.DROP_IT_HERE);
    if (isDragReject) return t(TRANSLATIONS.FILE_TYPE_NOT_ACCEPTED_SORRY);
  }

  console.log(rejectedFiles);

  function reUpload() {
    setFile(null);
    cancelUpload();
  }

  return (
    <div className="text-center file-drop-zone">
      {!file && (
        <div
          {...getRootProps({
            className: 'file-upload-interactive-container',
          })}
        >
          <input {...getInputProps()} />

          <div className="upload-file-Icon">
            <DocsIcon />
          </div>
          <p className="file-drop-zone__description">{renderDescription()}</p>
        </div>
      )}
      {!!file && (
        <>
          <div>
            <div className="upload-file-Icon">
              <DocsIcon />
            </div>
            <p className="file-drop-zone__description">{file.name}</p>
            {!!file && !uploadStatus.isUploading && !status.rejected && !uploadStatus.errorMessage && (
              <div>
                <p className="cancel-upload" onClick={onCancelUpload}>
                  {t(TRANSLATIONS.REMOVE_FILE)}
                </p>
              </div>
            )}
          </div>
          {!!uploadStatus.isUploading && (
            <div className="file-uploading-status">
              <div className="upload-percent-label-container">
                <p>{t(TRANSLATIONS.UPLOADING)}....</p>
                <p>{uploadStatus.uploadingPercent}%</p>
              </div>

              <LinearProgress
                variant="determinate"
                value={uploadStatus.uploadingPercent}
                color="primary"
              />
            </div>
          )}
          {!!file && !!uploadStatus.isUploading && (
            <div>
              <p className="cancel-upload mt-2" onClick={onCancelUpload}>
                {t(TRANSLATIONS.CANCEL)}
              </p>
            </div>
          )}
          {!!file
            && !uploadStatus.isUploading
            && !status.rejected
            && !uploadStatus.errorMessage && (
              <BasicButton
                onClick={uploadFile}
                className="upload-button"
                text={t(TRANSLATIONS.UPLOAD)}
                background="#56CD9D"
              />
          )}
          {!!uploadStatus.errorMessage && (
            <div className="error-container">
              <p className="error-heading"> {t(TRANSLATIONS.ERROR)} </p>
              <p className="error-description">{`Uploading Failed ${uploadStatus.errorMessage}`}</p>
              <p className="retry-upload" onClick={reUpload}>
                {t(TRANSLATIONS.RETRY)}
              </p>
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default FileDropZone;
