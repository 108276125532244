import React, { useMemo } from 'react';
import { FormControl, MenuItem, Select } from '@material-ui/core';
import { ACTION_LOG_SUMMARY_MODAL_STATUS, TRANSLATIONS } from 'types/enums';
import './MeetingForSummaryModalStyle.scss';
import { useGetRoutineAvailableInfoQuery } from 'generated/graphql';
import { generateMeetingList } from 'features/action-logs/RoutinesListing/routinesListHelper';
import { Routine } from 'features/action-logs/RoutinesListing/RoutinesListing';
import { useTranslation } from 'react-i18next';

type MeetingForSummaryModalProps = {
  modalStatus: ACTION_LOG_SUMMARY_MODAL_STATUS;
  meeting: Routine;
  handleMeetingChange: (meeting: Routine) => void;
};
function MeetingForSummaryModal(props: MeetingForSummaryModalProps) {
  const { t } = useTranslation();
  const { data } = useGetRoutineAvailableInfoQuery(
    {
      variables: { forFilter: false },
    },
  );

  const meetingsList = generateMeetingList(data?.getRoutinesAvailable as any);
  const meetingsListHash = useMemo(() => {
    const hash: any = {};
    meetingsList.forEach(meetingsListItem => {
      if (!hash[meetingsListItem.value]) {
        hash[meetingsListItem.value] = meetingsListItem.label;
      }
    });
    return hash;
  }, [meetingsList]);
  const { modalStatus, meeting, handleMeetingChange } = props;
  function onChange(e: React.ChangeEvent<{ value: string, }>) {
    handleMeetingChange({
      label: meetingsListHash[e?.target?.value] || '' as any,
      value: e?.target?.value || '' as any,
    });
  }

  return modalStatus === ACTION_LOG_SUMMARY_MODAL_STATUS.SUMMARY ? (
    <div className="check">
      <p className="extra_title">{t(TRANSLATIONS.REVIEW_IN)}</p>
      <p className="extra_sub_title">{meeting.label}</p>
    </div>
  ) : (
    <div className="check selection_dropdown">
      <p className="extra_title">{t(TRANSLATIONS.REVIEW_IN)}</p>
      <FormControl>
        <Select
          displayEmpty
          id="meeting_select"
          variant="outlined"
          value={meeting.value}
          label={meeting.label}
          name={meeting.label}
          onChange={onChange as any}
          renderValue={() => (meetingsListHash[meeting.value]
            ? meeting.label
            : t(TRANSLATIONS.SELECT))}
        >
          <MenuItem value="" disabled>
            {t(TRANSLATIONS.SELECT)}
          </MenuItem>
          {meetingsList.map((item: Routine) => (
            <MenuItem
              value={item.value}
              key={item.value}
            >{item.label}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </div>
  );
}
export default MeetingForSummaryModal;
