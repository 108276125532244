import ExpandableTable from 'components/ExpandableTable';
import { format, getMonth, getYear, startOfMonth, subDays } from 'date-fns';
import { GkpiDataResponse, useGetCountryAndLocationListQuery, useGetgkpiDataQuery } from 'generated/graphql';
import React, { useState, useEffect, useMemo } from 'react';
import { getMonthRange } from 'utils/dateHelpers';
import DatePicker from 'react-datepicker';
import { CellProps, Column } from 'react-table';
import { es, enUS } from 'date-fns/esm/locale';
import { MultiSelect } from 'react-multi-select-component';
import { Option } from 'react-multi-select-component/dist/types/lib/interfaces';
import ChartDropdown from 'components/Dropdown/ChartDropdown';
import { Item } from 'components/Dropdown/ChartDropdown/ChartDropdown';
import { SelectInfo } from 'rc-menu/lib/interface';
import './GKPIRepresentationStyles.scss';
import i18next from 'i18n';
import { CircularProgress } from '@material-ui/core';
import BasicButton from 'components/Button/BasicButton';
import { useHistory } from 'react-router-dom';
import { TRANSLATIONS } from 'types/enums';
import { useTranslation } from 'react-i18next';
import { ReactComponent as DownTrend } from '../../../../assets/icons/down-trend.svg';
import { ReactComponent as UpTrend } from '../../../../assets/icons/up-trend.svg';
import { ReactComponent as CalenderIcon } from '../../../../assets/icons/calender-icon.svg';

const LANG = i18next.language?.indexOf('en') === 0 ? 'en' : 'es';
const DateLocale = i18next.language?.indexOf('en') === 0 ? enUS : es;
const INITIAL_SELECTED_STATE: Item = { slug: '', label: 'All' };

const GKPIRepresentation = () => {
  const [startDate, setStartDate] = useState<Date>(startOfMonth(subDays(new Date(), 360)));
  const [endDate, setEndDate] = useState<Date>(startOfMonth(new Date()));
  const [GKPIOptions, setGKPIOptions] = useState<Option[]>([]);
  const [selectedGKPI, setSelectedGKPI] = useState<Option[]>([]);
  const [visibleGKPI, setVisibleGKPI] = useState<Pick<GkpiDataResponse, 'GKPI' | 'data'>[]>([]);
  const [countryOptions, setCountryOptions] = useState<Item[]>([]);
  const [selectedCountry, setSelectedCountry] = useState<Item>(INITIAL_SELECTED_STATE);
  const [selectedLocation, setSelectedLocation] = useState<Item>(INITIAL_SELECTED_STATE);
  const [locationOptions, setLocationOptions] = useState<Item[]>([]);
  const [countryDropDownOpen, setCountryDropDownOpen] = useState<boolean>(false);
  const [locationDropDownOpen, setLocationDropDownOpen] = useState<boolean>(false);
  const history = useHistory();
  const { t } = useTranslation();

  const GKPI = useGetgkpiDataQuery({
    variables: {
      country: selectedCountry.slug,
      location: selectedLocation.slug,
      from: startDate,
      to: endDate,
    },
  });

  const countryAndLocationList = useGetCountryAndLocationListQuery();

  useEffect(() => {
    if (countryAndLocationList.data?.getCountryAndLocationList) {
      const availableCountries = countryAndLocationList
        .data
        .getCountryAndLocationList.map(countryEntry => ({
          label: countryEntry?.country || '',
          slug: countryEntry?.country || '',
        }));

      setCountryOptions(availableCountries);
      setSelectedCountry(availableCountries[0] || { label: 'Not Found', slug: '' });
    }
  }, [countryAndLocationList.data]);

  useEffect(() => {
    if (selectedCountry?.slug) {
      const availableLocations = countryAndLocationList
        .data
        ?.getCountryAndLocationList
        ?.find(countryEntry => countryEntry?.country === selectedCountry.slug)?.locations;
      if (availableLocations) {
        setLocationOptions(
          availableLocations.map(location => ({ slug: location || '', label: location || '' })),
        );
        setSelectedLocation(INITIAL_SELECTED_STATE);
      }
    }
  }, [countryAndLocationList.data, selectedCountry]);

  useEffect(() => {
    if (GKPI.data?.getGKPIData) {
      setGKPIOptions(GKPI.data.getGKPIData.map(gkpi => ({ label: gkpi?.GKPI || '', value: gkpi?.GKPI || '' })));
    }
  }, [GKPI.data]);

  useEffect(() => {
    if (selectedGKPI.length) {
      if (GKPI.data?.getGKPIData) {
        const filteredGKPI = GKPI.data?.getGKPIData
          .filter(GKPIEntry => selectedGKPI.map(GKPIData => GKPIData.value).indexOf(GKPIEntry?.GKPI || '') > -1)
          .map(filteredGKPIEntry => ({
            GKPI: filteredGKPIEntry?.GKPI || '',
            data: filteredGKPIEntry?.data,
          }));
        setVisibleGKPI(filteredGKPI);
      }
      return;
    }
    if (GKPI.data?.getGKPIData) {
      setVisibleGKPI(GKPI.data?.getGKPIData.map(GKPIEntry => ({ GKPI: GKPIEntry?.GKPI || '', data: GKPIEntry?.data })));
    }
  }, [selectedGKPI, GKPI.data]);

  const cellRenderer = (cellInfo: CellProps<{}, string>) => {
    const cellValue = JSON.parse(cellInfo.value);
    const value = typeof cellValue.value === 'number' ? cellValue.value.toFixed(2) : cellValue.value;
    return (
      <div className="d-flex">
        <span>
          {value} {cellValue.change < 0 && <DownTrend />}
          {cellValue.change > 0 && <UpTrend />}
        </span>
      </div>
    );
  };

  const cellRendererGKPI = (cellInfo: CellProps<{}, string>) => {
    const cellValue = (cellInfo.value);
    return (
      <div className="d-flex pl-3">
        {cellValue}
      </div>
    );
  };

  function handleCountrySelection(info: SelectInfo) {
    const selectedOption = countryOptions.find(countryEntry => countryEntry.slug === info.key);
    if (selectedOption) setSelectedCountry(selectedOption);
  }

  function handleLocationSelection(info: SelectInfo) {
    const selectedOption = locationOptions.find(locationEntry => locationEntry.slug === info.key);
    if (selectedOption) setSelectedLocation(selectedOption);
  }
  function filterOption(options: Option[], filter: string) {
    return options.filter(option => option.label.toLowerCase().search(filter.toLowerCase()) > -1);
  }

  const columns = useMemo(() => {
    const columnsList: Column[] = [
      {
        Header: 'GKPI',
        accessor: 'GKPI',
        Cell: cellRendererGKPI,
      },
    ];
    const months = getMonthRange(startDate, endDate);
    months.map(month => {
      return columnsList.push(
        {
          id: `${getMonth(month) + 1}-${getYear(month)}-cy`,
          Header: `${format(month, 'LLL', { locale: DateLocale })} ${getYear(month)}`,
          accessor: (row: any) => {
            const content = row.data[getYear(month)]?.[getMonth(month) + 1]?.[0].content;
            if (content) {
              return JSON.stringify({
                value: content.AC,
                change: content.AC_VS_LY,
              });
            }
            return JSON.stringify({
              value: '-',
              change: 0,
            });
          },
          Cell: cellRenderer,
        },
        {
          id: `${getMonth(month) + 1}-${getYear(month)}-ly`,
          Header: `${format(month, 'LLL', { locale: DateLocale })} ${getYear(month) - 1}`,
          accessor: (row: any) => {
            const content = row.data[getYear(month)]?.[getMonth(month) + 1]?.[0].content;
            if (content) {
              return JSON.stringify({
                value: content.LY,
                change: -1 * content.AC_VS_LY,
              });
            }
            return JSON.stringify({
              value: '-',
              change: 0,
            });
          },
          Cell: cellRenderer,
        },
      );
    });
    return columnsList;
  }, [startDate, endDate]);

  function handleStartDateChange(date: any) {
    setStartDate(date);
  }

  function handleEndDateChange(date: any) {
    setEndDate(date);
  }

  function redirectToGKPIUpload() {
    history.push('/analytics/GKPI/upload');
  }

  return (
    <div className="container-fluid gkpi-analytics">
      <div className="w-100 d-flex flex-row  justify-content-between gkpi-header">
        <div className="heading">GKPIs</div>
        <BasicButton
          onClick={redirectToGKPIUpload}
          className="upload-button"
          text={t(TRANSLATIONS.UPLOAD)}
          background="#56CD9D"
        />
      </div>
      <div className="gkpi-filters-header-section w-100 d-flex flex-row justify-content-between flex-wrap align-content-start">
        <div className="d-flex">
          <MultiSelect
            options={GKPIOptions}
            value={selectedGKPI}
            onChange={setSelectedGKPI}
            labelledBy="Select GKPI"
            filterOptions={filterOption}
            overrideStrings={{
              allItemsAreSelected: t(TRANSLATIONS.ALL_ITEMS_ARE_SELECTED),
              clearSearch: t(TRANSLATIONS.CLEAR_SEARCH),
              noOptions: t(TRANSLATIONS.NO_OPTIONS),
              search: t(TRANSLATIONS.SEARCH),
              selectAll: t(TRANSLATIONS.SELECT_ALL),
              selectAllFiltered: t(TRANSLATIONS.SELECT_ALL_FILTERED),
              selectSomeItems: `${t(TRANSLATIONS.SELECT)}...`,
            }}
          />

          <div className="date-range-selector">
            <CalenderIcon className="calender-icon" />
            <DatePicker
              selected={startDate}
              onChange={handleStartDateChange}
              selectsStart
              startDate={startDate}
              endDate={endDate}
              maxDate={new Date()}
              dateFormat="MMM yyyy"
              locale={LANG}
              showMonthYearPicker
            />
            <p>-</p>
            <DatePicker
              selected={endDate}
              onChange={handleEndDateChange}
              selectsEnd
              minDate={startDate}
              maxDate={new Date()}
              startDate={startDate}
              endDate={endDate}
              dateFormat="MMM yyyy"
              locale={LANG}
              showMonthYearPicker
            />
          </div>
        </div>
        <div className="location-selector d-flex flex-row justify-content-end">
          <div className="country-selector d-flex flex-row align-items-center">
            <div>{t(TRANSLATIONS.COUNTRY)}:</div>
            <ChartDropdown
              onChange={handleCountrySelection}
              selectedDropDownItem={selectedCountry}
              dropDownOpen={countryDropDownOpen}
              setDropDownOpen={setCountryDropDownOpen}
              labelClassName="country-selection"
              dropDownItems={countryOptions}
            />
          </div>

          {selectedCountry && selectedCountry.slug && (
            <div className="location-selector d-flex flex-row align-items-center">
              <div className="pl-2">Plant:</div>
              <ChartDropdown
                onChange={handleLocationSelection}
                selectedDropDownItem={selectedLocation}
                dropDownOpen={locationDropDownOpen}
                setDropDownOpen={setLocationDropDownOpen}
                labelClassName="location-selection"
                dropDownItems={locationOptions}
              />
            </div>
          )}
        </div>
      </div>
      <div className="gkpi-representation-table">
        {GKPI.loading && (
          <div className="loader">
            <CircularProgress size={30} />
          </div>
        )}
        {!GKPI.loading && visibleGKPI.length === 0 && (
          <div className="loader">
            <p className="message">No records Found</p>
          </div>
        )}
        {!GKPI.loading && visibleGKPI.length > 0 && (
          <ExpandableTable columns={columns} data={visibleGKPI} isExpandable={false} />
        )}
      </div>
    </div>
  );
};

export default GKPIRepresentation;
