import React from 'react';
import './Tabs.scss';

type tabItem = {
  title: string;
  value?: string;
};
type props = {
  tabsItems: tabItem[];
  setSelectedTab: (item: tabItem) => void;
  selectedTab: tabItem;
};

const Tabs = (props: props) => {
  const { tabsItems, setSelectedTab, selectedTab } = props;

  return (
    <div className="tabs">
      {tabsItems.map(item => (
        <div
          key={`${item.title}`}
          role="button"
          onClick={() => {
            setSelectedTab(item);
          }}
          className={`tab-item ${item.title === selectedTab.title ? 'tab-item-selected' : ''}`}
        >
          <p className="tab-title">{item.title}</p>
        </div>
      ))}
    </div>
  );
};

export default Tabs;
