import i18next from 'i18next';
import { initReactI18next } from 'react-i18next';

import {
  TRANSLATIONS,
  BLOCKED_REASONS,
  TICKET_TYPE,
  SECURITY_STATUS,
  RISK_LEVEL,
  PRIORITY,
} from './types/enums';

export const pluralize = (str: string) => `${str}_plural`;

const resources = {
  es: {
    translation: {
      [TRANSLATIONS.ADD_ANOTHER]: 'Agregar otro+',
      [TRANSLATIONS.CONTESTAR_CHECKLIST]: 'Contestar checklist',
      [TRANSLATIONS.APPLY_OWDS]: 'Aplicar OWDs',
      [TRANSLATIONS.SEARCH_CHECKLIST]: 'Listas de verificación de búsqueda',
      [TRANSLATIONS.NUMBERS]: 'Números enteros',
      [TRANSLATIONS.DECIMAL]: 'Números decimales',
      [TRANSLATIONS.CHECKLIST_OPTIONS]: 'Opciones de lista de verificación',
      [TRANSLATIONS.SELECT_DISTRIBUTION_PARAGRAPH]: 'Para poder acceder a la configuración de las OWDs es necesario seleccionar un Centro de Distribución utilizando la barra superior',
      [TRANSLATIONS.LOADING_FILE]: 'Cargando archivo',
      [TRANSLATIONS.LAST_LOAD]: 'Última carga',
      [TRANSLATIONS.TEMPLATE]: 'Plantilla',
      [TRANSLATIONS.OVER_WRITE_FILE]: 'Sobreescribir archivo',
      [TRANSLATIONS.DOWNLOAD_FILE]: 'Descargar archivo',
      [TRANSLATIONS.DOWNLOAD_TEMPLATE]: 'Descargar Plantilla',
      [TRANSLATIONS.ARCHIVO]: 'Archivo',
      [TRANSLATIONS.WITHIN_24_HOURS]: 'Dentro de 24 horas',
      [TRANSLATIONS.MORE_24_HOURS]: 'Más de 24 horas',
      [TRANSLATIONS.week1]: '1er sem',
      [TRANSLATIONS.week2]: '2da sem',
      [TRANSLATIONS.week3]: '3ra sem',
      [TRANSLATIONS.week4]: '4ta sem',
      [TRANSLATIONS.week5]: '5ta sem',
      [TRANSLATIONS.week6]: '6ta sem',
      [TRANSLATIONS.week7]: '7ma sem',
      [TRANSLATIONS.week8]: '8ma sem',
      [TRANSLATIONS.week9]: '9na sem',
      [TRANSLATIONS.week10]: '10ma sem',
      [TRANSLATIONS.week11]: 'sem 11',
      [TRANSLATIONS.week12]: 'sem 12',
      [TRANSLATIONS.week13]: 'sem 13',
      [TRANSLATIONS.week14]: 'sem 14',
      [TRANSLATIONS.week15]: 'sem 15',
      [TRANSLATIONS.week16]: 'sem 16',
      [TRANSLATIONS.week17]: 'sem 17',
      [TRANSLATIONS.week18]: 'sem 18',
      [TRANSLATIONS.week19]: 'sem 19',
      [TRANSLATIONS.week20]: 'sem 20',
      [TRANSLATIONS.week21]: 'sem 21',
      [TRANSLATIONS.week22]: 'sem 22',
      [TRANSLATIONS.week23]: 'sem 23',
      [TRANSLATIONS.week24]: 'sem 24',
      [TRANSLATIONS.week25]: 'sem 25',
      [TRANSLATIONS.week26]: 'sem 26',
      [TRANSLATIONS.week27]: 'sem 27',
      [TRANSLATIONS.week28]: 'sem 28',
      [TRANSLATIONS.week29]: 'sem 29',
      [TRANSLATIONS.week30]: 'sem 30',
      [TRANSLATIONS.week31]: 'sem 31',
      [TRANSLATIONS.week32]: 'sem 32',
      [TRANSLATIONS.week33]: 'sem 33',
      [TRANSLATIONS.week34]: 'sem 34',
      [TRANSLATIONS.week35]: 'sem 35',
      [TRANSLATIONS.week36]: 'sem 36',
      [TRANSLATIONS.week37]: 'sem 37',
      [TRANSLATIONS.week38]: 'sem 38',
      [TRANSLATIONS.week39]: 'sem 39',
      [TRANSLATIONS.week40]: 'sem 40',
      [TRANSLATIONS.week41]: 'sem 41',
      [TRANSLATIONS.week42]: 'sem 42',
      [TRANSLATIONS.week43]: 'sem 43',
      [TRANSLATIONS.week44]: 'sem 44',
      [TRANSLATIONS.week45]: 'sem 45',
      [TRANSLATIONS.week46]: 'sem 46',
      [TRANSLATIONS.week47]: 'sem 47',
      [TRANSLATIONS.week48]: 'sem 48',
      [TRANSLATIONS.week49]: 'sem 49',
      [TRANSLATIONS.week50]: 'sem 50',
      [TRANSLATIONS.week51]: 'sem 51',
      [TRANSLATIONS.week52]: 'sem 52',
      [TRANSLATIONS.week53]: 'sem 53',
      [TRANSLATIONS.week54]: 'sem 54',
      [TRANSLATIONS.REFERNCIA]: 'Referencia',
      [TRANSLATIONS.DATE_INCIDENT]: 'Fecha del incidente',
      [TRANSLATIONS.DATE_REPORTED]: 'Fecha del registro',
      [TRANSLATIONS.VALIDITY]: 'Vigencia',
      [TRANSLATIONS.NATURE]: 'Naturaleza',
      [TRANSLATIONS.CAUSE]: 'Causa',
      [TRANSLATIONS.INVITATION_SENT]: 'Invitación enviada',
      [TRANSLATIONS.SUB_CAUSE]: 'Causa raiz',
      [TRANSLATIONS.CREATE_YOUR_PASSWORD_TO_ACCESS]: 'Crea tu contraseña para acceder a la plataforma',
      [TRANSLATIONS.PASSWORD]: 'Contraseña',
      [TRANSLATIONS.CONFIRM_PASSWORD]: 'Confirmar contraseña',
      [TRANSLATIONS.FORGOT_YOUR_PASSWORD]: '¿Se te olvidó tu contraseña?',
      [TRANSLATIONS.I_REMEMBER_MY_PASSWORD]: 'Recuerdo mi contraseña',
      [TRANSLATIONS.RECOVER_IT_HERE]: 'Recupérala aquí.',
      [TRANSLATIONS.LETS_FIND_YOUR_ACC]: 'Busquemos tu cuenta ',
      [TRANSLATIONS.TYPE_YOUR_EMAIL]: 'Escribe el correo electrónico asociado a tu cuenta.',
      [TRANSLATIONS.SEND_ME_THE_LINK]: 'ENVIAR CORREO',
      [TRANSLATIONS.EMAIL_SENT]: 'Listo! Correo enviado',
      [TRANSLATIONS.YOU_WILL_RECEIVE_AN_EMAIL]: 'Te llegará un correo a',
      [TRANSLATIONS.TO_RESET_PASSWORD]: 'para que reestablezcas tu contraseña.',
      [TRANSLATIONS.ALREADY_HAVE_AN_ACCOUNT]: '¿Ya tienes cuenta?',
      [TRANSLATIONS.LOGIN]: 'Inicia sesión',
      [TRANSLATIONS.GO_TO_LOGIN]: 'Ir a inicio de sesión',
      [TRANSLATIONS.EMAIL]: 'Correo',
      [TRANSLATIONS.LOADING]: 'Cargando',
      [TRANSLATIONS.YOUR_PASSWORD_WAS_SUCCESSFULLY_CREATED]: 'Tu contraseña fue creada exitosamente.',
      [TRANSLATIONS.YOU_CAN_START_USING_THE_APP]: '¡Ya puedes empezar a utilizar la app!',
      [TRANSLATIONS.OPEN_APP]: 'Abrir app',
      [TRANSLATIONS.CONTACT]: 'Contacto',
      [TRANSLATIONS.THIS_URL_HAS_EXPIRED]: 'Ya expiró esta liga',
      [TRANSLATIONS.LOGIN_TO_ACCESS_SAFETY_DASHBOARD]: 'Inicia sesión para entrar al dashboard de Safety 360',
      [TRANSLATIONS.LOGIN_TOP_ESPECTRO_DASHBOARD]: 'Inicia sesión para entrar al dashboard de Espectro',
      [TRANSLATIONS.LOGGING_IN]: 'Iniciando Sesión',
      [TRANSLATIONS.NAME]: 'Nombre',
      [TRANSLATIONS.FIRST_SURNAME]: 'Primer apellido',
      [TRANSLATIONS.SECOND_SURNAME]: 'Segundo apellido',
      [TRANSLATIONS.NAME_OF_THE_COMPANY]: 'Nombre de la compania',
      [TRANSLATIONS.DESIGNATION]: 'Designación',
      [TRANSLATIONS.OPTIONAL]: 'Opcional',
      [TRANSLATIONS.BLOCKED_REASON]: 'Motivo de bloqueo',
      [TRANSLATIONS.SEARCH]: 'Buscar',
      [TRANSLATIONS.BLOCK]: 'Bloquear',
      [TRANSLATIONS.UNBLOCK]: 'Desbloquear',
      [TRANSLATIONS.CD_SETTINGS]: 'Configuración {{ location }}',
      [TRANSLATIONS.BACK_TO]: 'Volver a',
      [TRANSLATIONS.SETTINGS]: 'Configuración',
      [TRANSLATIONS.BLOCK_UNBLOCK]: 'Bloquear/Desbloquear',
      [TRANSLATIONS.EXIT]: 'Salir',
      [TRANSLATIONS.FILE_UPLOAD]: 'Carga de archivos',
      [TRANSLATIONS.WELCOME]: 'Bienvenido',
      [TRANSLATIONS.YOU_ARE_VIEWING]: 'Estás viendo',
      [BLOCKED_REASONS.SELF_ASSESSMENT]: 'Asesoría propia',
      [BLOCKED_REASONS.MANUAL]: 'Manual',
      [BLOCKED_REASONS.VISIT_DOCTOR]: 'Bloqueo Medico',
      [TRANSLATIONS.TOTAL_REPORTS]: 'Reportes totales',
      [TRANSLATIONS.REPORTS]: 'Reportes',
      [TRANSLATIONS.UNIQUE]: 'Únicos',
      [TRANSLATIONS.AVERAGE]: 'Prom.',
      [TRANSLATIONS.UNIQUE_USERS]: 'Usuarios únicos',
      [TRANSLATIONS.AVERAGE_PER_USER]: 'Prom. por usuario',
      [TRANSLATIONS.AVERAGE_DAILY_PER_USER]: 'Prom. diario por usuario',
      [TRANSLATIONS.LAST_7_DAYS]: 'Últ. 7 días',
      [TRANSLATIONS.LAST_30_DAYS]: 'Últ. 30 días',
      [TRANSLATIONS.LAST_90_DAYS]: 'Últ. 90 días',
      [TRANSLATIONS.MONTH_TO_DATE]: 'Mes Corriente',
      [TRANSLATIONS.YEAR_TO_DATE]: 'Año corriente',
      [TRANSLATIONS.PRECIDING_PERIOD]: 'Period. ant.',
      [TRANSLATIONS.REPORT_HISTORY]: 'Evolución de reportes',
      [TRANSLATIONS.WHERE_ARE_YOU]: '¿En dónde estás?',
      [TRANSLATIONS.ZONE]: 'Zona',
      [TRANSLATIONS.AGENCIA_DC]: 'CD',
      [TRANSLATIONS.CONTINUE]: 'CONTINUAR',
      [TRANSLATIONS.NEW_REPORT]: 'Nuevo reporte',
      [TRANSLATIONS.REPORT_DATA]: 'Datos del ACI',
      [TRANSLATIONS.WHO_IS_REPORTING_IT]: '¿Quien lo esta reportando?',
      [TICKET_TYPE.ACT]: 'Acto',
      [TICKET_TYPE.CONDITION]: 'Condición',
      [SECURITY_STATUS.SECURE]: 'Seguro',
      [SECURITY_STATUS.INSECURE]: 'Inseguro',
      [TRANSLATIONS.WHO_COMMITTED_THE_ACT]: '¿Quién cometió el acto?',
      [TRANSLATIONS.IT_WAS_NOT_POSSIBLE_TO_IDENTIFY]: 'No fue posible identificarlo',
      [TRANSLATIONS.IT_IS_GOING_TO_BE_AN_ANONYMOUS_REPORT]: 'Será un reporte anónimo',
      [TRANSLATIONS.IT_IS_AN_ANONYMOUS_REPORT]: 'Es un reporte anónimo',
      [TRANSLATIONS.I_COULDNT_IDENTIFY_THE_PERSON]: 'No logré identificar a la persona',
      [TRANSLATIONS.SEARCH_THE_LIST_AGAIN]: 'Volver a buscar en la lista',
      [TRANSLATIONS.BACK_TO_SEARCH_BAR]: 'Regresar a buúsqueda',
      [TRANSLATIONS.WHERE_DID_YOU_SEE]: '¿Dónde lo viste?',
      [TRANSLATIONS.WHERE_DID_YOU_SEE_IN_DC_AREA]: '¿Dónde lo viste?',
      [TRANSLATIONS.CD_AREA]: 'Área del CD*',
      [TRANSLATIONS.IN_WHICH_AREA_OF_DISTRIBUTION_CENTER]: '¿En qué área del Centro de Distribución?*',
      [TRANSLATIONS.SPECIFY_THE_PLACE_IN_THE_SUBAREA]: 'Especifica el lugar en la subárea',
      [TRANSLATIONS.TYPE_OF_RISK]: 'Tipo de riesgo',
      [TRANSLATIONS.RISK_GROUP]: 'Clasificación',
      [TRANSLATIONS.WHAT_RISK_TYPE_WAS_PREVENTED]: '¿Qué riesgo se previene al realizarlo?*',
      [TRANSLATIONS.WHAT_RISK_TYPE_WAS_PREVENTED_WITH_ACT]: '¿Qué riesgo se previene al realizarlo?*',
      [TRANSLATIONS.WHAT_RISK_TYPE_WAS_PREVENTED_WITH_CONDITION]: '¿Qué riesgo se previene con esta condición segura?*',
      [TRANSLATIONS.WHO_HAD_THIS_BEHAVIOR]: '¿Quién cometió el acto?',
      [TRANSLATIONS.WHO_SUBMITTED_THIS_BEHAVIOR]: '¿Quién reportó el acto?',
      [TRANSLATIONS.WHO_HAD_THIS_CONDITION]: '¿Quién cometió la condición?',
      [TRANSLATIONS.WHO_SUBMITTED_THIS_CONDITION]: '¿Quién reportó la condición?',
      [TRANSLATIONS.WHO_SUBMITTED_THIS_ENVIRONMENTAL_RISK]: '¿Quién reportó el riesgo ambiental?',
      [TRANSLATIONS.SELECT_RISK_TYPE]: 'Elige el tipo de riesgo',
      [TRANSLATIONS.SELECT_RISK_GROUP]: 'Elige la clasificación de riesgo',
      [TRANSLATIONS.DESCRIPTION]: 'Descripción',
      [TRANSLATIONS.OF_THE_ACT]: 'del acto',
      [TRANSLATIONS.OF_THE_CONDITION]: 'de la condición',
      [TRANSLATIONS.RISK_LEVEL_TRANSLATION]: 'Nivel de riesgo',
      [RISK_LEVEL.HIGH]: 'Alto',
      [RISK_LEVEL.LOW]: 'Bajo',
      [PRIORITY.MEDIUM]: 'Medio',
      [TRANSLATIONS.CORRECTIVE_ACTION]: 'Acción correctiva',
      [TRANSLATIONS.UPLOAD_REGISTRATION]: 'Subir registro',
      [TRANSLATIONS.UPLOAD_EVIDENCE]: 'Subir evidencia',
      [TRANSLATIONS.ACI_OVERVIEW]: 'Resumen del ACI',
      [TRANSLATIONS.LEVEL]: 'Nivel',
      [TRANSLATIONS.FINISH_AND_SEND]: 'Terminar y enviar',
      [TRANSLATIONS.THANK_YOU]: 'Gracias',
      [TRANSLATIONS.WITH_YOUR_HELP_WE_CAN_PREVENT_ACCIDENTS_IN_FUTURE]: '¡Con tu ayuda podemos prevenir más accidentes a futuro!',
      [TRANSLATIONS.SENT_SUCCESSFULLY]: 'Enviado exitosamente',
      [TRANSLATIONS.SIO_SUCCESSFULLY_SENT]: 'ACI enviado exitosamente',
      [TRANSLATIONS.THANKS_TO_YOU_WE_CAN_IMPROVE_SECURITY_AMONG_OUR_COLLABORATORS]: 'Gracias a ti podemos mejorar la seguridad entre nuestros colaboradores.',
      [TRANSLATIONS.SUCCESSFUL_REGISTRATION]: '¡Registro exitoso!',
      [TRANSLATIONS.YOUR_REGISTRATION_WAS_SENT_SUCCESSFULLY]: 'Tu registro fue enviado con éxito',
      [TRANSLATIONS.THE_REPORT_HAS_BEEN_SUCCESSFULLY_SENT]: 'Tu reporte fue enviado con éxito',
      [TRANSLATIONS.DOWNLOAD]: 'Descargar',
      [TRANSLATIONS.CERTIFICATES]: 'certificados',
      [TRANSLATIONS.AMBIENTAL_RISKS]: 'MA',
      [TRANSLATIONS.BOTH]: 'ambos',
      [TRANSLATIONS.INCIDENTS]: 'ACIs',
      [TRANSLATIONS.INCIDENTS_PER_POSITION]: 'ACIs por posición',
      [TRANSLATIONS.INCIDENTS_NATIONAL]: 'ACIs (Nacional)',
      [TRANSLATIONS.ALL_ZONES]: 'Todas las Zonas',
      [TRANSLATIONS.DRVS]: "DRV's",
      [TRANSLATIONS.DISTRIBUTION_CENTERS]: 'Centro de Distribución',
      [pluralize(TRANSLATIONS.DISTRIBUTION_CENTERS)]: 'Centros de Distribución',
      [TRANSLATIONS.ACIS]: 'ACIs',
      [TRANSLATIONS.LOGOUT]: 'Salir',
      [TRANSLATIONS.GO_TO_DASHBOARD]: 'Regresar al tablero',
      [TRANSLATIONS.REQUIRED]: 'Requerido',
      [TRANSLATIONS.DECEMBER]: 'Diciembre',
      [TRANSLATIONS.NOVEMBER]: 'Noviembre',
      [TRANSLATIONS.OCTOBER]: 'Octubre',
      [TRANSLATIONS.SEPTEMBER]: 'Septiembre',
      [TRANSLATIONS.AUGUST]: 'Agosto',
      [TRANSLATIONS.JULY]: 'Julio',
      [TRANSLATIONS.JUNE]: 'Junio',
      [TRANSLATIONS.MAY]: 'Mayo',
      [TRANSLATIONS.APRIL]: 'Abril',
      [TRANSLATIONS.MARCH]: 'Marzo',
      [TRANSLATIONS.FEBRUARY]: 'Febrero',
      [TRANSLATIONS.JANUARY]: 'Enero',
      [TRANSLATIONS.ALL_MONTHLY_OPTIONS]: 'Todos los meses',
      // external users module - start
      [TRANSLATIONS.EXTERNAL]: 'Externos',
      [TRANSLATIONS.INTERNAL]: 'Internos',
      [TRANSLATIONS.SEARCH_USERS]: 'Buscar usuarios',
      [TRANSLATIONS.ADD_USER_TO_DC]: 'Agregar usuario a',
      [TRANSLATIONS.USER_ALREADY_EXISTS]: 'Valida si ya existe el usuario',
      [TRANSLATIONS.DOES_USER_ACCOUNT]: '¿El usuario ya tiene cuenta de Safety360?',
      [TRANSLATIONS.YES_HE_HAS]: 'Si, ya tiene cuenta',
      [TRANSLATIONS.ENTER_EMAIL]: 'Escribe el correo',
      [TRANSLATIONS.NO_CREATE_SCRATCH]: 'No, crear desde cero',
      [TRANSLATIONS.POSITION]: 'Puesto',
      [TRANSLATIONS.WORKS_AT]: 'Trabaja en',
      [TRANSLATIONS.TYPE_OF_COLLABORATOR]: 'Tipo de colaborador',
      [TRANSLATIONS.SELECT_DISTRIBUTION_CENTER]: 'Selecciona un Centro de Distribución',
      [TRANSLATIONS.CHOOSE]: 'ELEGIR',
      [TRANSLATIONS.PERMISSIONS]: 'Permisos',
      [TRANSLATIONS.ROUTINES]: 'Rutinas',
      [TRANSLATIONS.TRAININGS]: 'Capacitaciones',
      [TRANSLATIONS.ACCIDENTABILITY]: 'Accidentabilidad',
      [TRANSLATIONS.ANALYTICS]: 'Análisis',
      [TRANSLATIONS.CANCEL]: 'Cancelar',
      [TRANSLATIONS.ADD]: 'Agregar',
      [TRANSLATIONS.ACCESS]: 'Acceso',
      [TRANSLATIONS.INFORMATION_NOT_AVAILABLE]: 'Información no disponible',
      [TRANSLATIONS.EDIT_INFORMATION]: 'Editar información',
      [TRANSLATIONS.DELETE_USER]: 'Eliminar usuario',
      [TRANSLATIONS.DELETE_USER_OPTION_MENU]: 'Eliminar usuario',
      [TRANSLATIONS.CONFIRM]: 'Confirmar',
      [TRANSLATIONS.ENTER_NAME]: 'Escribe el nombre',
      [TRANSLATIONS.YES]: 'Sí',
      [TRANSLATIONS.NO]: 'No',
      [TRANSLATIONS.USERS]: 'Usuarios',
      [TRANSLATIONS.CHECK_YOUR_EMAIL]: 'Por favor revise su correo electrónico.',
      [TRANSLATIONS.ASK_USER_WHICH_EMAIL]: 'Pregunta al usuario qué correo usó para registrarse en Safety360',
      [TRANSLATIONS.SOMETHING_WENT_WRONG_TRY_LATER]: 'Algo salió mal. Por favor, inténtelo de nuevo más tarde.',
      [TRANSLATIONS.USER_NOT_EXIST_CREATE_NEW]: 'El usuario no existe, verifica el correo o crea el usuario',
      [TRANSLATIONS.DELIVERY_MAN]: 'Repartidor',
      [TRANSLATIONS.SUPPLIER]: 'Proveedor',
      [TRANSLATIONS.WAREHOUSE_ASSISTANT]: 'Ayudante de bodega',
      [TRANSLATIONS.PEOPLE_AND_MANAGEMENT]: 'Gente y gestion',
      [TRANSLATIONS.FORKLIFT_DRIVER]: 'Montacarguista',
      // external users module - end
      [TRANSLATIONS.WEEKLY_REPORTS]: 'Reportes de ACIs',
      [TRANSLATIONS.BOXES]: 'cajas',
      [TRANSLATIONS.WHO_COMMITTED]: 'Quién cometió',
      [TICKET_TYPE.ACT]: 'Acto',
      [TRANSLATIONS.BEHAVIOR]: 'Acto',
      [TICKET_TYPE.CONDITION]: 'Condición',
      [TRANSLATIONS.DATE_REPORTED]: 'Inicio',
      [TRANSLATIONS.REPORTS_LIST]: 'Lista de ACIs',
      [TRANSLATIONS.ALL]: 'Todos',
      [TRANSLATIONS.BEHAVIORS]: 'Actos',
      [TRANSLATIONS.CONDITIONS]: 'Condiciones',
      [TRANSLATIONS.DOWNLOAD_LIST]: 'Descargar ACIs',
      [TRANSLATIONS.PLACE]: 'Lugar',
      [TRANSLATIONS.TOTAL]: 'Total',
      [TRANSLATIONS.OPENED]: 'Abiertos',
      [TRANSLATIONS.CLOSED]: 'Cerrados',
      [TRANSLATIONS.ASSIGNED]: 'Asignados',
      [TRANSLATIONS.CLOSE]: 'Cerrar',
      [TRANSLATIONS.REPORT]: 'Mis reportes',
      [TRANSLATIONS.ASSIGN]: 'Asignar',
      [TRANSLATIONS.INSIDE_DC]: 'Dentro de Ubicación',
      [TRANSLATIONS.OUTSIDE_DC]: 'Fuera de Ubicación',
      [TRANSLATIONS.CONDITION]: 'Condición',
      [TRANSLATIONS.ENVIRONMENTAL_RISK]: 'Riesgo ambiental',
      [TRANSLATIONS.ACT]: 'Actos',
      [TRANSLATIONS.DESCRIPTION_OF_THE_CONDITION]: 'Descripción de la condición',
      [TRANSLATIONS.DESCRIBE_THE_BEHAVIOR]: 'Descripción del acto',
      [TRANSLATIONS.CONFIRM_USER_DELETION]: 'Seguro que quieres eliminar a',
      [TRANSLATIONS.PIN_CREATED_SUCCESSFULLY]: 'PIN generado exitosamente',
      [TRANSLATIONS.REGION]: 'Zona',
      [TRANSLATIONS.SELECT_REGION]: 'Elige zona',
      [TRANSLATIONS.BUSINESS_UNIT]: 'Regional',
      [TRANSLATIONS.SELECT_DC]: 'Elige CD',
      [TRANSLATIONS.SELECT]: 'Elige',
      [TRANSLATIONS.SELECT_ANSWER]: 'Elegir respuesta',
      [TRANSLATIONS.SELECT_SUBLOCATION]: 'Elige sub-locación',
      [TRANSLATIONS.SELECT_POSITION]: 'Elige una posición',
      [TRANSLATIONS.WRITE_YOUR_8_DIGIT_SAP_NUMBER]: 'Escribe tu #SAP de 8 dígitos',
      [TRANSLATIONS.WRITE_AN_8_DIGIT_SAP_NUMBER]: 'Escribe un #SAP de 8 dígitos',
      [TRANSLATIONS.SAP_SUCCESSFULLY_VALIDATED]: '#SAP validado correctamente',
      [TRANSLATIONS.PLEASE_MAKE_SURE_ITS_AN_8_DIGIT_NUMBER]: ' Asegúrate de que sean 8 dígitos por favor.',
      [TRANSLATIONS.TO_KEEP_A_TRACK_RECORD_OF_YOUR_REPORTS_TYPE_IN_YOUR_SAP_NUMBER]: 'Para guardar histórico de tus reportes, escribe tu número SAP.',
      [TRANSLATIONS.IF_YOU_LEAVE_IT_EMPTY_OR_INCORRECT_THIS_WILL_COUNT_AS_ANONYMOUS_REPORT]: 'Si lo dejas vacío o incorrecto contará como reporte anónimo ☝🏼',
      [TRANSLATIONS.CLEAR]: 'Limpiar',
      [TRANSLATIONS.OVERVIEW]: 'Overview',
      [TRANSLATIONS.DIST_CENTERS]: 'Ubicaciones',
      [TRANSLATIONS.HOME]: 'Inicio',
      [TRANSLATIONS.BLOCK_UNBLOCK_SHORT]: 'Bloq./Desbloq.',
      [TRANSLATIONS.PRECEDING_PERIOD]: 'Periodo anterior',
      [TRANSLATIONS.AVERAGE]: 'Promedio',
      [TRANSLATIONS.PARTICIPATION]: 'Participación',
      [TRANSLATIONS.DAILY_AVG_PER_USER]: 'Prom. diario por usuario',
      [TRANSLATIONS.ALL_THE_BUS]: 'Todas las BUs',
      [TRANSLATIONS.ALL]: 'Todo',
      [TRANSLATIONS.BU]: 'País',
      [TRANSLATIONS.PASSWORD_TOO_SHORT]: 'Contraseña muy corta',
      [TRANSLATIONS.PASSWORD_NO_MATCH]: 'Las contraseñas no son iguales',
      [TRANSLATIONS.DO_NOT_USE_YOUR_OLD_PASSWORD]: 'No utilices tu antigua contraseña',
      [TRANSLATIONS.ROUTINES_FOLLOWUP]: 'Seguimiento de rutinas',
      [TRANSLATIONS.WEEK]: 'Semana',
      [TRANSLATIONS.TRAININGS_PERCENTAGE]: '% DE CAPACITACIÓN',
      [TRANSLATIONS.SIF_ROUTE_PERCENTAGE]: '% DE RUTAS',
      [TRANSLATIONS.TOTAL_SIF_ROUTES]: 'TOTAL',
      [TRANSLATIONS.COURSES]: 'Cursos',
      [TRANSLATIONS.HC_TO_BE_TRAINED]: 'HC a capacitar',
      [TRANSLATIONS.TRAINED]: 'Capacitados',
      [TRANSLATIONS.IMAGE]: 'Imagen',
      [TRANSLATIONS.USER_ACCESS_DISCLAIMER]: 'Si no ves las ubicaciones correctas escribe a soporte en el chat de abajo a la derecha.',
      [TRANSLATIONS.USER_MISSING_DISCLAIMER]: 'Si hacen falta usuarios internos escribe a soporte en el chat de abajo a la derecha.',
      [TRANSLATIONS.SECURE_CONDITION_DISCLAIMER]: 'Las secciones de abierto, cerrados y asignados no incluyen actos o condiciones seguras',
      [TRANSLATIONS.YOU_DONT_HAVE_ANY_LOCATION_ASSIGNED_PLEASE_CONTACT_SUPPORT]: 'No tienes ninguna ubicación asignada, comunícate con soporte en el chat de abajo a la derecha.',
      [TRANSLATIONS.DOWNLOAD_ALL]: 'Descargar Todo',
      [TRANSLATIONS.INCIDENTS_PER_POSITION]: 'ACIs por posición',
      [TRANSLATIONS.INCIDENTS_PER_AREA]: 'ACIs por área',
      [TRANSLATIONS.RESET]: 'Limpiar',
      [TRANSLATIONS.SAFE]: 'Seguros',
      [TRANSLATIONS.PLANT]: 'CD/Planta',
      [TRANSLATIONS.ID]: 'ID',
      [TRANSLATIONS.SHARP]: 'Sharp',
      [TRANSLATIONS.STATUS]: 'Status',
      [TRANSLATIONS.REASON]: 'Motivo bloqueo',
      [TRANSLATIONS.ACTIONS]: 'Acciones',
      [TRANSLATIONS.BLOCKED]: 'Bloqueado',
      [TRANSLATIONS.MEDIACAL_EXAMINATION]: 'Examen médico',
      [TRANSLATIONS.NO_DATA]: 'No hay data',
      [TRANSLATIONS.NO_DATA_FOUND_SO_FAR]: 'Hasta el momento no se encontraron datos para esta sección',
      [TRANSLATIONS.CURRENT_PROGRESS]: 'Prog. Actual',
      [TRANSLATIONS.TOTAL_PROGRESS]: 'Progreso Total',
      [TRANSLATIONS.LAST]: 'Última',
      [TRANSLATIONS.DOWNLOAD_ROUTINES_DB]: 'Descargar la base de datos de Rutinas',
      [TRANSLATIONS.SEARCH_LOCATION]: 'Ubicación de búsqueda',
      [TRANSLATIONS.NO_MATCHES_FOUND]: 'No se encontraron coincidencias',
      [TRANSLATIONS.CONSEQUENCES]: 'Consecuencias',
      [TRANSLATIONS.SELECT_SUBZONE_OR_DISTRIBUTION_CENTER]: 'Por favor escoge una UEN o CD para mostrar el progreso por usuario',
      [TRANSLATIONS.CRITICAL_CLIENTS]: 'Clientes críticos',
      [TRANSLATIONS.DIARIA_LOGÍSTICA]: 'Diaria Logística',
      [TRANSLATIONS.RUTINA_MATUTINA_DE_REPARTO]: 'Rutina matutina de reparto (delivery)',
      [TRANSLATIONS.RUTINA_DE_CAMBIO_DE_TURNO]: 'Rutina de cambio de turno (warehouse)',
      [TRANSLATIONS.RUTINA_SEMANAL_DE_ALMACÉN]: 'Rutina semanal de Almacén',
      [TRANSLATIONS.RUTINA_SEMANAL_DE_DIST]: 'Rutina semanal de Distribución',
      [TRANSLATIONS.RUTINA_SEMANAL_DE_PEOPLE]: 'Rutina semanal de People',
      [TRANSLATIONS.RUTINA_SEMANAL_DE_FLOTA]: 'Rutina semanal de Flota',
      [TRANSLATIONS.RUTINA_SEMANAL_DE_PLANNING]: 'Rutina semanal de Planning (Financiera VLC)',
      [TRANSLATIONS.RUTINA_SEMANAL_DE_SAFETY]: 'Rutina semanal de Safety (Financiera VLC)',
      [TRANSLATIONS.RUTINE_SEMANAM_DE_GESTION]: 'Rutina semanal de Gestión',
      [TRANSLATIONS.AT_HOME]: 'En casa',
      [TRANSLATIONS.HOSPITAL]: 'Hospital',
      [TRANSLATIONS.STABLE]: 'Estable',
      [TRANSLATIONS.STABLE_WITH_OXYGEN]: 'Estable con oxígeno',
      [TRANSLATIONS.SERIOUS_WITH_OXYGEN]: 'Serio con oxígeno',
      [TRANSLATIONS.SERIOUS_WITHOUT_OXYGEN]: 'Serio sin oxígeno',
      [TRANSLATIONS.LAST_UPDATED_ON_MONTH_DATE_DAY]: 'Últ. actualización el {{date}} de {{month}}, hace {{day}} días.',
      [TRANSLATIONS.LAST_UPDATED_TODAY]: 'Última actualización hoy.',
      [TRANSLATIONS.UPDATE_USER_STATUS_TODAY_MONTH_DATE]: 'Actualizar estado de {{user}} del día de hoy {{date}} de {{month}}.',
      [TRANSLATIONS.WHERE_IS_USER]: '¿En dónde se encuentra {{user}}?',
      [TRANSLATIONS.REMEMBER_THAT_SENDING_THIS_INFORMATION_IS_VERY_IMPORTANT_FOR_US_TO_GIVE_YOU_THE_BEST_POSSIBLE_SUPPORT_THROUGH_THE_QUARANTINE_PROCESS]: 'Recuerda que enviar esta información es clave para que podamos brindarte el mayor apoyo posible.',
      [TRANSLATIONS.SAVE]: 'Guardar',
      [TRANSLATIONS.ADD_COMMENT]: 'Agregar comentario',
      [TRANSLATIONS.COMMENT_CREATED_AT]: '{{date}} a las {{time}}',
      [TRANSLATIONS.COMMENT_SHOULD_BE_UNDER_N_CHARACTER]: 'El comentario debe tener menos de {{maxSize}} caracteres',
      [TRANSLATIONS.EDIT]: 'Cambiar',
      [TRANSLATIONS.DELETE]: 'Eliminar',
      [TRANSLATIONS.DELETE_FILTER]: 'Eliminar Filtros',
      [TRANSLATIONS.PLACEHOLDER_TEXT]: 'Texto comodín de',
      [TRANSLATIONS.DELETING]: 'Borrando',
      [TRANSLATIONS.PARTICIPANT]: 'Participante',
      [TRANSLATIONS.OWNER]: 'Dueño',
      [TRANSLATIONS.IF_NOT_NECESSARY_RETURN_TO_UPDATE_TOMORROW]: 'De no ser necesario, regresa a actualizar hasta el día de mañana.',
      [TRANSLATIONS.STATUS_UPDATED_AS_OF_TODAY_DATE_MONTH]: 'Estado actualizado al día de hoy {{date}} de {{month}}',
      [TRANSLATIONS.UPDATED_TODAY_AT_TIME]: 'Actualizado hoy a las {{time}}.',
      [TRANSLATIONS.YOUR_QUARANTINE_HAS]: '¡Tu cuarentena ha',
      [TRANSLATIONS.CONCLUDED]: 'concluido!',
      [TRANSLATIONS.PHYSICAL_STATE]: 'Estado físico',
      [TRANSLATIONS.PILLAR]: 'Pilar',
      [TRANSLATIONS.SAFETY]: 'Safety',
      [TRANSLATIONS.MANAGEMENT]: 'Gestión',
      [TRANSLATIONS.PLANNING]: 'Planning',
      [TRANSLATIONS.DISTRIBUTION]: 'Distrib.',
      [TRANSLATIONS.FLEET]: 'Flota',
      [TRANSLATIONS.STOCK]: 'Almacén',
      [TRANSLATIONS.PEOPLE]: 'People',
      [TRANSLATIONS.WAREHOUSE]: 'Warehouse',
      [TRANSLATIONS.NOTE]: 'Nota',
      [TRANSLATIONS.SEARCH_KPI]: 'Buscar KPIs',
      [TRANSLATIONS.NO_RESULTS_FOUND]: 'No hay resultados',
      [TRANSLATIONS.SEARCH_TO_ADD_USERS]: 'Buscar Para Agregar Usuarios',
      [TRANSLATIONS.ACTION_LOG_TABLE_FOR_LOCATION_NAME]: 'Tablero de tareas de {{locationName}}',
      [TRANSLATIONS.VIOLENCE_PREVENTION]: 'Prevención de violencia',
      [TRANSLATIONS.DELETE_FILTERS]: 'Borrar filtros',
      [TRANSLATIONS.ALL_ROUTINES]: 'Todas las rutinas',
      [TRANSLATIONS.ALL_KPIS]: 'Todos los KPIs',
      [TRANSLATIONS.ALL_USERS]: 'Todos los usuarios',
      [TRANSLATIONS.SELECT_THE_ROUTINE_YOU_WANT_TO_SEE]: 'Selecciona la rutina que deseas ver',
      [TRANSLATIONS.SELECT_THE_KPIS_YOU_WANT_TO_SEE]: 'Selecciona los KPIs que deseas ver',
      [TRANSLATIONS.SELECT_THE_USERS_YOU_WANT_TO_SEE]: 'Selecciona los usuarios que deseas ver',
      [TRANSLATIONS.NO_DUE_DATE_SELECTED]: 'Sin fecha compromiso',
      [TRANSLATIONS.DELETE_COMMENT]: 'Eliminar comentario',
      [TRANSLATIONS.CLICK_HERE_DROP_TO_UPLOAD]: '¡Haz clic aquí o deja un archivo para cargarlo!',
      [TRANSLATIONS.DROP_IT_HERE]: 'Déjalo aquí',
      [TRANSLATIONS.FILE_TYPE_NOT_ACCEPTED_SORRY]: 'Lo sentimos, tipo de archivo inaceptable',
      [TRANSLATIONS.MAXIMUM_ALLOWED_FILE_SIZE]: 'Tamaño de archivo máximo : {{size}}',
      [TRANSLATIONS.REMOVE_FILE]: 'Eliminar archivo',
      [TRANSLATIONS.UPLOADING]: 'Subiendo',
      [TRANSLATIONS.UPLOAD]: 'Subir',
      [TRANSLATIONS.ERROR]: 'Error del servidor',
      [TRANSLATIONS.RETRY]: 'Vuelve a intentar',
      [TRANSLATIONS.FILE_IS_TOO_LARGE]: 'El archivo es demasiado grande',
      [TRANSLATIONS.SERVER_ERROR]: 'Error del servidor',
      [TRANSLATIONS.PROCESSING_YOUR_FILE]: 'Procesando tu archivo',
      [TRANSLATIONS.PROCESSING_FAILED_WITH_MESSAGE]: 'Procesamiento fallido {{msg}}',
      [TRANSLATIONS.CLICK_TO_VISIT]: 'Haz clic para visitar {{location}}',
      [TRANSLATIONS.REUPLOAD]: 'Volver a subir',
      [TRANSLATIONS.FINISHED_FILE_PROCESSING]: 'Procesamiento de archivos terminado',
      [TRANSLATIONS.DONE]: 'Listo',
      [TRANSLATIONS.ALL_ITEMS_ARE_SELECTED]: 'Todos elementos están seleccionados',
      [TRANSLATIONS.CLEAR_SEARCH]: 'Borrar búsqueda',
      [TRANSLATIONS.NO_OPTIONS]: 'No hay opciones ',
      [TRANSLATIONS.SELECT_ALL]: 'Seleccionar todo',
      [TRANSLATIONS.SELECT_ALL_FILTERED]: 'Seleccionar todo (filtrado)',
      [TRANSLATIONS.ROUTINE_COMPLIANCE_HEADING]: 'Cumplimiento de rutinas',
      [TRANSLATIONS.ROUTINE_COMPLIANCE_COMPLETED_PERCENTAGE]: '% rutinas',
      [TRANSLATIONS.ROUTINE_COMPLIANCE_COMPLIANCE]: 'Cumplimiento',
      [TRANSLATIONS.ROUTINE_COMPLIANCE_EXECUTED]: 'Ejecutadas',
      [TRANSLATIONS.ROUTINE_COMPLIANCE_PENDING]: 'Pendientes',
      [TRANSLATIONS.ROUTINE_COMPLIANCE_DOWNLOAD_DATA]: 'Descargar datos',
      [TRANSLATIONS.ROUTINE_COMPLIANCE_RANKING]: 'Ranking de CDs en MAZ',
      [TRANSLATIONS.ROUTINE_COMPLIANCE_PLANNED]: 'Planes',
      [TRANSLATIONS.ROUTINE_COMPLIANCE_SEARCH]: 'Buscar por GRO...',
      [TRANSLATIONS.ROUTINE_COMPLIANCE_REGION]: 'País / Región',
      [TRANSLATIONS.ROUTINE_COMPLIANCE_BU]: 'Por BU',
      [TRANSLATIONS.ROUTINE_COMPLIANCE_CD]: 'DC',
      [TRANSLATIONS.TOOLKIT_HEADING]: 'Prevención de violencia',
      [TRANSLATIONS.TOOLKIT_CURRENT_MONTH]: 'Mes pasado',
      [TRANSLATIONS.TOOLKIT_PREVIOUS_MONTH]: 'Mes actual',
      [TRANSLATIONS.META]: 'Meta',
      [TRANSLATIONS.NOTES]: 'Notas para',
      [TRANSLATIONS.COMMENT_BY]: 'Comentario escrito por',
      [TRANSLATIONS.LATEST_EDIT]: 'Última edición',
      [TRANSLATIONS.SAVE_NOTE]: 'Guardar nota',
      [TRANSLATIONS.SIF_STATISTICS]: 'Estadistics SIF',
      [TRANSLATIONS.USER_TYPE]: 'Tipo de usuario',
      [TRANSLATIONS.USER_ROLE_TRANSLATION]: 'Rol de usuario',
      [TRANSLATIONS.UPDATE]: 'Actualizar',
      [TRANSLATIONS.PASSWORD_RECOVERY]: 'Recuperacion de contraseña',
      [TRANSLATIONS.UPDATE_YOUR_PASSWORD_TO_ACCESS]: 'Actualiza tu contraseña para acceder a la plataforma',
      [TRANSLATIONS.YOUR_PASSWORD_WAS_SUCCESSFULLY_RESTORED]: 'Tú contraseña fue restaurada exitosamente.',
      [TRANSLATIONS.YOU_CAN_USE_THE_APP_AGAIN]: '¡Ya puedes volver a utilizar la app!',
      [TRANSLATIONS.REDIRECT_TIMEOUT]: 'Favor de esperar un momento mientras te redirigimos. Si después de 10 segundos no has sido redireccionado, favor de hacer click',
      [TRANSLATIONS.HERE]: 'Aquí',
      [TRANSLATIONS.PREVENTION_CAMPAIGNS]: 'Campaña Preventiva',
      [TRANSLATIONS.BEST_PRACTICES]: 'Buenas Prácticas',
      [TRANSLATIONS.MONTHLY_TOTAL]: 'Total Mensual',
      [TRANSLATIONS.BOARDS]: 'Tableros',
      [TRANSLATIONS.OWD]: 'OWDs',
      [TRANSLATIONS.PDT]: 'PDTs',
      [TRANSLATIONS.SAFETY_NEWS]: 'Noticias Safety',
      [TRANSLATIONS.TOP]: 'Máximo',
      [TRANSLATIONS.BOTTOM]: 'Minimo',
      [TRANSLATIONS.SELECT_MONTH]: 'Seleccionar Mes',
      [TRANSLATIONS.PYRAMID]: 'PIRÁMIDE',
      [TRANSLATIONS.COUNTRY]: 'País',
      [TRANSLATIONS.PLEASE_UPLOAD_A_FILE]: 'Por favor, sube un archivo',
      [TRANSLATIONS.LAST_UPDATED_ON]: 'Última actualización el {{monthAndYear}}',
      [TRANSLATIONS.TELEMETRY]: 'Telemetría',
      [TRANSLATIONS.CURRENT_MONTH]: 'Mes actual',
      [TRANSLATIONS.BY_CATEGORY]: 'Por categoría',
      [TRANSLATIONS.TELEMETRY_BY_BU]: 'Telemetría por BU',
      [TRANSLATIONS.REGULAR_SPEEDING]: 'Exceso de velocidad regular',
      [TRANSLATIONS.CRITICAL_SPEEDING]: 'Exceso de velocidad crítico',
      [TRANSLATIONS.CURVE_SPEEDING]: 'Exceso de velocidad en curva',
      [TRANSLATIONS.HARSH_TURNING]: 'Giro brusco',
      [TRANSLATIONS.SEATBELT]: 'Cinturón de seguridad',
      [TRANSLATIONS.VEHICLE]: 'Vehículo',
      [TRANSLATIONS.ACTION_LOGS]: 'Planes de Acción',
      [TRANSLATIONS.AMBIENTAL_RISKS]: 'Riesgos Ambientales',
      [TRANSLATIONS.PRODUCTION_PERFORMANCE_TRACKING]: 'Planeación de la Producción',
      [TRANSLATIONS.BY_BU]: 'por BU',
      [TRANSLATIONS.BY_POSITION]: 'por Posición',
      [TRANSLATIONS.BY_ROUTINES]: 'por Rutina',
      [TRANSLATIONS.CLICK_HERE]: '¡Click Aquí!',
      [TRANSLATIONS.BY]: 'por',
      [TRANSLATIONS.COUNTRY]: 'País',
      [TRANSLATIONS.QR_CODE]: 'Código QR',
      [TRANSLATIONS.SEARCH_ROUTINE]: 'Buscar rutina',
      [TRANSLATIONS.MANAGEMENT_TOOLS]: 'Herramienta de gestión',
      [TRANSLATIONS.SIF_ROUTE]: 'Ruta SIF',
      [TRANSLATIONS.SIF_COMPLANICE]: 'Cumplimiento SIF',
      [TRANSLATIONS.SAFETY_TRAINING]: 'Safety Training',
      [TRANSLATIONS.INVALID_CREDENTIALS_TRY_AGAIN]: 'Credenciales invalidas. ¡Intenta otra vez!',
      [TRANSLATIONS.THERE_IS_NO_NOTE_ADDED_FOR_THIS_ACTION_LOG]: 'No hay nota para este plan de acción...',
      [TRANSLATIONS.THERE_ARE_NO_ACTION_LOGS_IN_THIS_COLUMN_YET]: 'Todavía no hay planes de acción en esta columna.',
      [TRANSLATIONS.ADD_ACTION_LOG]: 'Agregar plan de acción',
      [TRANSLATIONS.OPEN]: 'Abierto',
      [TRANSLATIONS.IN_PROGRESS]: 'En progreso',
      [TRANSLATIONS.IN_REVIEW]: 'En revisión',
      [TRANSLATIONS.COMPLETED]: 'Completado',
      [TRANSLATIONS.WRITE_ACTION_HERE]: 'Escribir acción aquí...',
      [TRANSLATIONS.SUCCESS_CRITERIA]: 'Criterio de éxito',
      [TRANSLATIONS.WRITE_SUCCESS_CRITERIA_HERE]: 'Escribir criterio de éxito aqui...',
      [TRANSLATIONS.REVIEW_IN]: 'REVISAR EN',
      [TRANSLATIONS.SAVE_ACTION_LOG]: 'Guardar tarea',
      [TRANSLATIONS.CREATE_ACTION_LOG]: 'Crear tarea',
      [TRANSLATIONS.SHOULD_BE_CLOSED_TODAY]: 'Debe cerrarse hoy',
      [TRANSLATIONS.SHOULD_HAVE_BEEN_CLOSED]: 'Debió cerrarse hace',
      [TRANSLATIONS.DAY]: 'día',
      [TRANSLATIONS.DAYS]: 'días',
      [TRANSLATIONS.TO_BE_CLOSED]: 'para el cierre',
      [TRANSLATIONS.THERE_ARE_NO_COMMENTS_YET]: 'Aún no hay comentarios',
      [TRANSLATIONS.CREATED]: 'Creado',
      [TRANSLATIONS.DUE_DATE]: 'Fecha compromiso',
      [TRANSLATIONS.SINCE]: 'desde',
      [TRANSLATIONS.IN]: 'en',
      [TRANSLATIONS.WRITE_A_NOTE]: 'Escribir una nota',
      [TRANSLATIONS.WAIT_A_MOMENT]: 'Espera un momento',
      [TRANSLATIONS.IF_YOU_LEAVE_IT_WITHOUT_SAVING_YOUR_CHANGES_WILL_LOST_WANT_TO_STAY_HERE]: 'Si sales sin guardar tus cambios, estos se perderán. ¿Deseas permanecer aquí?',
      [TRANSLATIONS.LEAVE_WITHOUT_SAVE]: 'Salir sin guardar',
      [TRANSLATIONS.DELETE_CARD]: 'Eliminar Tarjeta',
      [TRANSLATIONS.NOT_ENOUGH_PERMISSIONS_TO_ACCESS]: 'Permisos insuficientes para acceder',
      [TRANSLATIONS.WEB_ACCESS]: 'Acceso web',
      [TRANSLATIONS.INDICATORS]: 'Indicadores',
      [TRANSLATIONS.DVR_CENTRAL_BAJIO]: 'DRV Central Bajío ',
      [TRANSLATIONS.METROPOLITAN_SBU]: 'UEN Metropolitana',
      [TRANSLATIONS.METROPOLITAN]: 'Metropolitana',
      [TRANSLATIONS.CD_SAN_PABLO]: 'CD San Pablo',
      [TRANSLATIONS.WAREHOUSE_HELPERS]: 'Ayudante de almacén',
      [TRANSLATIONS.FORKLIFTERS]: 'Montacarguistas',
      [TRANSLATIONS.SKAP_DISTRIBUTION]: 'Distribución',
      [TRANSLATIONS.SKAP]: 'SKAP',
      [TRANSLATIONS.SORRY_THERE_WAS_AN_ERROR]: 'Lo sentimos, hubo un error',
      [TRANSLATIONS.RELOAD_PAGE]: 'Recargar página',
      [TRANSLATIONS.IMAPACTED_HC]: 'HC impactado',
      [TRANSLATIONS.FUNCTIONAL_INDUCTION]: 'Inducción funcional',
      [TRANSLATIONS.TECHNICAL_SKILLS]: 'Habilidades técnicas',
      [TRANSLATIONS.AUTONOMOUS_SKILLS]: 'Habilidades autónomas',
      [TRANSLATIONS.FUNCTIONAL]: 'Funcional',
      [TRANSLATIONS.TECHNICAL]: 'Técnicos',
      [TRANSLATIONS.AUTONOMOUS]: 'Autónomos',
      [TRANSLATIONS.AUTONOMY_LEVEL]: 'Nivel de autonomía',
      [TRANSLATIONS.HEAD_COUNT]: 'Head count',
      [TRANSLATIONS.RAMP_UP]: 'Ramp up',
      [TRANSLATIONS.AREA]: 'Área',
      [TRANSLATIONS.HC_TOTAL]: 'HC total',
      [TRANSLATIONS.DOWNLOAD_EXCEL]: 'Descarga Excel',
      [TRANSLATIONS.ALL_THE_TEAM]: 'Todo el equipo',
      [TRANSLATIONS.NEW_COLLABORATORS]: 'Colaboradores nuevos',
      [TRANSLATIONS.OLD_COLLABORATORS]: 'Colaboradores antiguos',
      [TRANSLATIONS.DELAY_TASK]: 'Tareas atrasadas',
      [TRANSLATIONS.IN_WAREHOUSE]: 'En CD',
      [TRANSLATIONS.IN_FACTORY]: 'En planta',
      [TRANSLATIONS.LOCATION]: 'Ubicación',
      [TRANSLATIONS.CONDITION_WAS_CLOSED_IMMEDIATELY]: 'La condición fue cerrada de inmediato',
      [TRANSLATIONS.COULD_IT_BE_SIF]: '¿Podría ser un SIF?',
      [TRANSLATIONS.SIF_PRECURSOR]: 'SIF Precursor',
      [TRANSLATIONS.SIF_POTENTIAL]: 'SIF Potencial',
      [TRANSLATIONS.DEPARTMENT]: 'Departamento',
      [TRANSLATIONS.CHECKLISTS]: 'Mis inspecciones',
      [TRANSLATIONS.LAST_WEEK]: 'En la última semana',
      [TRANSLATIONS.NEW_CHECKLIST]: 'Nueva inspección',
      [TRANSLATIONS.CREATE_NEW_CHECKLIST]: 'Crear nueva inspección',
      [TRANSLATIONS.CREATE_NEW_TYPE]: 'Crear nueva activo',
      [TRANSLATIONS.TITLE_CHECKLIST]: 'Nombre de la inspección',
      [TRANSLATIONS.ENTER_TITLE]: 'Escribir pregunta',
      [TRANSLATIONS.FREQUENCY]: 'Frecuencia',
      [TRANSLATIONS.DAILY]: 'Diaria',
      [TRANSLATIONS.ADD_SECTION]: 'Agregar sección',
      [TRANSLATIONS.ENTER_DESCRIPTION]: 'Agregar descripción general',
      [TRANSLATIONS.ADD_ITEM]: 'Agregar pregunta',
      [TRANSLATIONS.ADD_INSRUCTION]: 'Agregar instrucción',
      [TRANSLATIONS.SECTION_DELETED]: 'Sección eliminada',
      [TRANSLATIONS.ITEM_DELETED]: 'Objeto eliminado',
      [TRANSLATIONS.BIT_DELETED]: 'Bit eliminado',
      [TRANSLATIONS.ADD_BIT]: 'Agregar respuesta',
      [TRANSLATIONS.TYPE_OF_BIT]: 'Tipo de respuesta',
      [TRANSLATIONS.CONFIGURE]: 'Configurar',
      [TRANSLATIONS.IS_EXPANDED_DEFAULT]: 'Expandir por defecto',
      [TRANSLATIONS.IS_EXPANDED_DETAILS]: 'Al habilitar esta opción, la sección estará expandida por defecto en cuanto se empiece la inspección',
      [TRANSLATIONS.SAP_SHARP]: '#SAP/SHARP',
      [TRANSLATIONS.CREATE_AN_ACCOUNT]: 'Crear una cuenta',
      [TRANSLATIONS.DONT_HAVE_AN_ACCOUNT]: '¿No tienes cuenta',
      [TRANSLATIONS.ALREADY_REGISTERED]: 'Ya estas registrado',
      [TRANSLATIONS.REGISTER_ON]: 'Registrate en',
      [TRANSLATIONS.REGISTER]: 'Registrar',
      [TRANSLATIONS.TERMS_AND_CONDITIONS]: 'He leído y acepto los términos y condiciones',
      [TRANSLATIONS.FOLLOW_THE_INSTRUCTIONS_RECEIVED]: 'Sigue las instrucciones que recibiste en tu correo para crear una contraseña',
      [TRANSLATIONS.TOO_SHORT]: 'muy corto',
      [TRANSLATIONS.YOU_MUST_ACCEPT_TERMS_AND_CONDITIONS]: 'Debes aceptar los términos y condiciones',
      [TRANSLATIONS.ENTER_A_CORRECT_EMAIL_FORMAT]: 'ingresar un formato de email correcto',
      [TRANSLATIONS.OPERATION]: 'Operación',
      [TRANSLATIONS.CATEGORY]: 'Categoría',
      [TRANSLATIONS.PROCEDURE]: 'Procedimiento',
      [TRANSLATIONS.PRIORITY_T]: 'Prioridad',
      [TRANSLATIONS.LOGISTIC]: 'Logística',
      [TRANSLATIONS.YOU_MUST_ENTER_YOUR_OPERATION_TYPE]: 'Debes seleccionar un tipo de operación',
      [TRANSLATIONS.DISTRIBUTION_CENTER]: 'Centro de distribución',
      [TRANSLATIONS.FACTORY]: 'Planta',
      [TRANSLATIONS.OPTIONS]: 'Opciones',
      [TRANSLATIONS.SECTION_OF_TOTAL]: 'Sección {{current}} de {{total}}',
      [TRANSLATIONS.TITLE_SECTION]: 'Título sección',
      [TRANSLATIONS.SOMETHING_SHORT]: '*Recomendamos algo corto',
      [TRANSLATIONS.QUESTION_OF_TOTAL]: 'Pregunta {{current}} de {{total}}',
      [TRANSLATIONS.ANSWER_OF_TOTAL]: 'Respuesta {{current}} de {{total}}',
      [TRANSLATIONS.PLACEHOLDER_INFO]: '*Se muestra por primera vez cuando el campo de texto aún no tiene nada escrito.',
      [TRANSLATIONS.ENTER_OPTION_HIT_ENTER]: 'Escribe una opción y presiona Enter',
      [TRANSLATIONS.SEND_PDF_WHEN_FINALIZED]: 'Enviar correo electrónico con PDF cuando haya terminado a:',
      [TRANSLATIONS.SWITCH_TO_DROPDOWN]: '*Recomendamos que cambies a Lista desplegable de opciones debido a la cantidad de opciones que requieres.',
      [TRANSLATIONS.SWITCH_TO_VERTICAL]: '*Cambiar a Vertical si necesitas más de 3 opciones. Si no, no se visualizarán las demás opciones.',
      [TRANSLATIONS.CHECKLIST_NAME]: 'Nombre de la inspección',
      [TRANSLATIONS.LAST_ACTIVITY]: 'Última actividad',
      [TRANSLATIONS.CREATION_DATE]: 'Fecha creación',
      [TRANSLATIONS.FIRST_CHECKLIST]: '¡Haz tu primera inspección!',
      [TRANSLATIONS.START_FIRST_CHECKLIST]: 'Para comenzar presiona "Nueva inspección".',
      [TRANSLATIONS.ADD_LOGIC]: 'Agregar lógica',
      [TRANSLATIONS.QUIT]: 'Quitar',
      [TRANSLATIONS.LOGIC]: 'Lógica',
      [TRANSLATIONS.SHOW_IF]: 'Si eso',
      [TRANSLATIONS.CHOOSE_QUESTION]: 'Elegir pregunta',
      [TRANSLATIONS.SECTION]: 'Sección',
      [TRANSLATIONS.ANSWER]: 'Pregunta',
      [TRANSLATIONS.EQUALS]: 'es igual a',
      [TRANSLATIONS.GREATER_THAN]: 'Mas grande que',
      [TRANSLATIONS.LESS_THAN]: 'Menos que',
      [TRANSLATIONS.ANSWERED]: 'se ha contestado',
      [TRANSLATIONS.TYPE_OF_NUMBER]: 'Tipo de número',
      [TRANSLATIONS.EXIT_WITHOUT_SAVING]: 'Salir sin guardar',
      [TRANSLATIONS.BACK]: 'Regresar',
      [TRANSLATIONS.SHOW_QUESTION]: 'Mostrar pregunta',
      [TRANSLATIONS.SEND_EMAIL]: 'Enviar correo electrónico con respuesta a',
      [TRANSLATIONS.TRIGGER_ACTION_LOG]: 'Registro de acciones de activación',
      [TRANSLATIONS.THEN]: 'Entonces',
      [TRANSLATIONS.AN_ERROR_HAS_OCCURRED]: 'Ha ocurrido un error.',
      [TRANSLATIONS.FILE_UPLOADED_SUCCESSFULLY]: 'Se cargó correctamente el archivo.',
      [TRANSLATIONS.COPY_QR_CODE]: 'Copiar codigo QR',
      [TRANSLATIONS.SUBAREA]: 'Subárea',
      [TRANSLATIONS.NOTE_UPLOADED_FILES_WILL_NOT_BE_REFLECTED_IMMEDIATELY_ONCE_YOU_UPLOAD_ALL_YOUR_FILES_PLEASE_REFRESH_THE_PAGE]: 'Nota: Los archivos que se suban no se reflejarán inmediatamente, una vez que subas todos tus archivos por favor actualiza la página.',
      [TRANSLATIONS.YOUR_FILE_HAS_STARTED_TO_LOAD_IT_MAY_TAKE_A_FEW_MINUTES]: 'Tu archivo ha comenzado a cargarse, puede tardar unos minutos.',
      [TRANSLATIONS.USER_UPDATED_SUCCESSFULLY]: 'Usuario actualizado con éxito.',
      [TRANSLATIONS.UPDATED_USER_NOW_DOES_NOT_HAVE_ANY_ROLE]: 'El usuario actualizado ahora no tiene ningún rol, asigne un permiso al usuario en la pestaña de permisos.',
      [TRANSLATIONS.SEARCH_THE_USER_BY_QRCODE_TO_CONFIGURE]: 'Busca el usuario por código QR para configurar',
      [TRANSLATIONS.SELECT_LOCATION]: 'Selecciona una ubicación',
      [TRANSLATIONS.OPERATIONAL]: 'Operativo',
      [TRANSLATIONS.CORPORATE]: 'Corporativo',
      [TRANSLATIONS.DOWNLOAD_DATA]: 'Descargar datos',
      [TRANSLATIONS.THE_DATA_FOR_THE_LOCATIONS_THAT_ARE_FILTERED_IN_THE_TOP_FILTER_BAR_WILL_BE_DOWNLOADED]: 'Se descargarán los datos de la(s) ubicación(es) que estén filtradas en la barra superior de filtrado.',
      [TRANSLATIONS.SELECT_DOCUMENT]: 'Selecciona documento',
      [TRANSLATIONS.SELECT_THE_DOCUMENT_YOU_WANT_TO_DOWNLOAD]: 'Selecciona el documento que deseas descargar',
      [TRANSLATIONS.DOWNLOADING_DATA]: 'Descargando datos...',
      [TRANSLATIONS.CHOOSE_HERE]: 'Elige aquí',
      [TRANSLATIONS.WITHOUT_POSITION]: 'Sin posición',
      [TRANSLATIONS.RETRY_AGAIN]: 'Reintentar',
      [TRANSLATIONS.SOMETHING_WENT_WRONG]: 'Algo falló',
      [TRANSLATIONS.DOWNLOAD_FAIL_RETRY_THE_DOWNLOAD_PLEASE]: 'Error en la descarga. Reintenta la descarga por favor.',
      [TRANSLATIONS.THE_DATA_IS_TAKING_A_LONG_TIME_TO_DOWNLOAD_A_DOWNLOAD_LINK_WILL_BE_SENT_TO_YOUR_EMAIL_TO_CONTINUE]: 'Los datos están tardando mucho en descargar, te será enviado un link de descarga a tu correo para poder continuar.',
      [TRANSLATIONS.OTHERS]: 'Otros',
      [TRANSLATIONS.SEND_EMAIL_WHEN_FINISHED]: 'Enviar correo electrónico con PDF cuando haya terminado a:',
      [TRANSLATIONS.TASK_DESCRIPTION]: 'Descripción de tarea',
      [TRANSLATIONS.USERS_ESP]: 'Usuarios',
      [TRANSLATIONS.SEARCH_QUESTION_TYPE]: 'Buscar tipo de respuesta',
      [TRANSLATIONS.ATLEAST_TWO_OPTIONS]: 'Por favor ingrese al menos dos opciones',
      [TRANSLATIONS.TITLE]: 'Título',
      [TRANSLATIONS.DOWNLOAD_REPORT]: 'Descargar reporte',
      [TRANSLATIONS.ROUTINE_SAVED]: 'Rutina guardada!',
      [TRANSLATIONS.WELCOME_TO]: 'Bienvenido a',
      [TRANSLATIONS.SELECT_OPTION]: 'Seleccione una opción',
      [TRANSLATIONS.LITE]: `● Acceso App Movil
      ● Inspecciones: Cumplimiento de formularios.
      ● Rutinas: Asignación de inspecciones por puesto y por frecuencia.
      ● Reporteo: Generación de accionables para seguimiento en dashboard web.`,
      [TRANSLATIONS.BUSINESS]: `Todo en Lite, más:
      ● Gestión tablero de tareas web: Seguimiento de tareas en tablero Kanban.
      ● Analíticos web: Información relevante de inspecciones, rutinas, usabilidad y sugerencias.
      ● Construcción de formularios: Formularios customizables con lógica y acciones.
      ● Conectores BI: Descarga de información en formato csv, xlsx y conexión con herramienta de análisis de datos.`,
      [TRANSLATIONS.OUTLINES]: 'Mapa',
      [TRANSLATIONS.TOTAL_PAGES]: 'Total de páginas',
      [TRANSLATIONS.PREVIOUS]: 'Anterior',
      [TRANSLATIONS.NEXT]: 'Siguiente',
      [TRANSLATIONS.NESTED_CHECKLIST_ERROR]: 'Complete la lista de verificación anidada',
      [TRANSLATIONS.REPLACE_FILE]: 'Reemplazar archivo',
      [TRANSLATIONS.GO_BACK]: 'Regresar',
      [TRANSLATIONS.ADD_USER]: 'Agregar usuario',
      [TRANSLATIONS.NEW_USER]: 'Nuevo usuario',
      [TRANSLATIONS.SEND_INVITATION]: 'Enviar invitación',
      [TRANSLATIONS.INVITE_USER_MAIL]: 'Invita a usuarios por correo.',
      [TRANSLATIONS.QUESTIONS]: 'Preguntas',
      [TRANSLATIONS.LOGICAL_QUESTION]: 'Lógica condicional',
      [TRANSLATIONS.TOTAL_CHECKLIST_REPORTS]: 'Reportes totales',
      [TRANSLATIONS.SHARE]: 'Compartir',
      [TRANSLATIONS.SHAREABLE_COMMENT]: 'Compartir',
      [TRANSLATIONS.ADD_SECTION_DESCRIPTION]: 'Agregar descripción sección',
      [TRANSLATIONS.ENABLE_REPEATITION]: 'Habilitar repetición',
      [TRANSLATIONS.ADJUSTMENTS]: 'Ajustes',
      [TRANSLATIONS.ASSETS]: 'Activos',
      [TRANSLATIONS.LOGICAL_CONDITION_HIGHLIGHT]: 'Se muestra si la respuesta de la pregunta "{{questionTitle}}" es "{{answer}}"',
      [TRANSLATIONS.LOGICAL_CONDITION_TEXT_WHEN_ANSWERED]: 'Muestra si se responde la pregunta "{{questionTitle}}".',
      [TRANSLATIONS.WORK_ORDERS]: 'Trabajos',
      [TRANSLATIONS.NEW_WORK_ORDER]: 'Nuevo Trabajo',
      [TRANSLATIONS.JOB_NAME]: 'Nombre trabajo',
      [TRANSLATIONS.INSPECTIONS]: 'Inspecciones',
      [TRANSLATIONS.STEPS]: 'Pasos',
      [TRANSLATIONS.THERE_ARE_NO_JOBS_YET]: 'No hay Trabajos aún',
      [TRANSLATIONS.PRESS_PLUS_NEW_JOB_TO_START]: 'Presiona ”+ Nuevo Trabajo” para comenzar.',
      [TRANSLATIONS.DELETE_WORK]: 'Eliminar trabajo',
      [TRANSLATIONS.THIS_WORK_WILL_BE_DELETED_EVERYWHERE_THIS_ACTION_CANNOT_BE_UNDONE]: 'Este trabajo será eliminado en todas partes. Esta acción no se puede deshacer.',
      [TRANSLATIONS.DELETE_PERMANENTLY]: 'Borrar permanentemente',
      [TRANSLATIONS.REQUIRED_FIELDS]: 'Por favor responda todas las preguntas requeridas',
      [TRANSLATIONS.DRILLS_CHECKLIST]: 'Borrar checklist',
      [TRANSLATIONS.YOU_WILL_NOT_BE_ABLE_TO_REVERSE_THE_ACTION_DO_YOU_WANT_TO_DELETE_THE_CHECKLIST]: 'No podrás revertir la acción. ¿Deseas borrar el checklist?',
      [TRANSLATIONS.YES_DELETE]: 'Si, borrar',
      [TRANSLATIONS.DELETE_INSTANCE]: 'Eliminar instancia',
      [TRANSLATIONS.REPORT_SUBMITTED_SUCCESSFULLY]: 'Informe enviado exitosamente',
      [TRANSLATIONS.YOU_CAN_NOW_CLOSE_THIS_TAB]: 'Ahora puede cerrar esta pestaña.',
      [TRANSLATIONS.ENABLE_PARAGRAPH]: 'Habilitar párrafo en la respuesta',
      [TRANSLATIONS.ENABLE_DECIMALS]: 'Aceptar decimales',
      [TRANSLATIONS.WRITE]: 'Escribe',
      [TRANSLATIONS.ASSET_TYPE]: 'Tipo de activo',
      [TRANSLATIONS.INSPECT_NAME]: 'Nombre para inspecciones',
      [TRANSLATIONS.UNIQUE_NAME]: 'Nombre único',
      [TRANSLATIONS.ADD_ASSETS]: 'Agregar activo',
      [TRANSLATIONS.UNIQUE_SUBTITLE]: 'Este es un código para que identifiques los activos entre sí.',
      [TRANSLATIONS.MY_ASSETS]: 'Mis activos',
      [TRANSLATIONS.ARCHIVED]: 'Archivados',
      [TRANSLATIONS.ASSET_TYPES]: 'Tipos de activos',
      [TRANSLATIONS.ADD_ASSET]: 'Agregar activo',
      [TRANSLATIONS.FIND_ACTIVE]: 'Buscar activo',
      [TRANSLATIONS.LAST_INSPECTION_DATE]: 'Últ. fecha de inspección',
      [TRANSLATIONS.OPEN_ACTIONS]: 'Acciones abiertas',
      [TRANSLATIONS.THERE_ARE_NO_ASSETS_YET]: 'No hay activos aún',
      [TRANSLATIONS.PRESS_ADD_ASSET_TO_GET_STARTED]: 'Presiona ”Agregar activo” para comenzar.',
      [TRANSLATIONS.ADD_ASSET_TYPE]: 'Agregar tipo de activo',
      [TRANSLATIONS.CHANGES_ARE_SAVED_AUTOMATICALLY]: 'Los cambios se guardan automáticamente',
      [TRANSLATIONS.ENTER_ASSET_TYPE]: 'Escribe tipo de activo',
      [TRANSLATIONS.NEW_FIELD_DESCRIPTION]: 'Dale un nombre y elige un tipo de respuesta. Una vez creado no podrás editar el tipo de campo.',
      [TRANSLATIONS.DELETE_FIELD_DESCRIPTION]: 'Este campo se puede utilizar en otros tipos. Al eliminarlo aquí, también se eliminará en todos los demás tipos. Esta acción no se puede deshacer.',
      [TRANSLATIONS.ANONYMOUS]: 'Anónimo',
      [TRANSLATIONS.NAME_OF_INSPECTION]: 'Nombre para inspecciones',
      [TRANSLATIONS.NAME_INSPECTION]: 'Nombre inspecciones',
      [TRANSLATIONS.TYPE]: 'Tipo',
      [TRANSLATIONS.DETAILS]: 'Detalles',
      [TRANSLATIONS.ADD_RIGHT_PANEL_FIELDS]: 'Para agregar más campos para este tipo de activo presione “+ Crear nuevo campo”',
      [TRANSLATIONS.CREATE_NEW_FIELD]: 'Crear nuevo campo',
      [TRANSLATIONS.NEW_FIELD]: 'Nuevo campo',
      [TRANSLATIONS.CREATE]: 'Crear',
      [TRANSLATIONS.RENAME_FIELD]: 'Cambiar nombre de campo',
      [TRANSLATIONS.RENAME_DESCRIPTION]: 'Se le cambiará el nombre en todos los lugares donde se utilice.',
      [TRANSLATIONS.DELETE_FIELD]: 'Eliminar campo',
      [TRANSLATIONS.INVITE_USERS]: 'Invitar usuarios',
      [TRANSLATIONS.CHECKLIST_SELECTION]: 'Selección de lista de verificación',
      [TRANSLATIONS.SELECT_WHICH_USERS_WILL_BE_ABLE_TO_PARTICIPATE_IN_THIS_WORK]: 'Selecciona que usuarios podrán participar en este trabajo',
      [TRANSLATIONS.ACTIVE]: 'Activa',
      [TRANSLATIONS.FINALIZED]: 'Finalizada',
      [TRANSLATIONS.TEXT]: 'Texto',
      [TRANSLATIONS.DATE]: 'Fecha',
      [TRANSLATIONS.NUMBER]: 'Número',
      [TRANSLATIONS.INVITING_YOU_TO_ESPECTRO]: ' te está invitando a Espectro',
      [TRANSLATIONS.SHARE_PDF]: 'Compartir PDF',
      [TRANSLATIONS.THERE_ARE_NO_COMPLETED_JOBS_YET]: 'No hay trabajos finalizados aún',
      [TRANSLATIONS.WAIT_FOR_THE_ASSIGNED_USERS_TO_FINISH_TO_VIEW_THEM_HERE]: 'Espera a que terminen los usuarios asignados para visualizarlos aquí',
      [TRANSLATIONS.CANCEL_JOB]: 'Cancelar trabajo',
      [TRANSLATIONS.TOTAL_QUESSTIONS]: 'Total de preguntas',
      [TRANSLATIONS.PICK_A_DATE]: 'Elige fecha',
      [TRANSLATIONS.CANCEL_WORK_ORDER]: 'Cancelar trabajos',
      [TRANSLATIONS.CANCELLED]: 'Cancelado',
      [TRANSLATIONS.REASON_FOR_CANCELLATION]: 'Escribe motivo de cancelación',
      [TRANSLATIONS.TYPE_EMAIL_AND_PRESS_ENTER]: 'Escribe el correo electrónico y presiona enter',
      [TRANSLATIONS.SEND_PDF_VIA_EMAIL]: 'Enviar PDF por correo electrónico',
      [TRANSLATIONS.ADD_EMAILS_TO_SEND]: 'Agregar correos electrónicos para enviar',
      [TRANSLATIONS.SIGN_OFF]: 'Cerrar sesión',
      [TRANSLATIONS.DUPLICATE]: 'Duplicada',
      [TRANSLATIONS.DELETE_CHECKLIST]: 'Eliminar lista de verificación',
      [TRANSLATIONS.WILL_BE_DELETED_THIS_ACTION_CANNOT_BE_UNDONE]: 'será borrado. Esta acción no se puede deshacer.',
      [TRANSLATIONS.IT_WILL_GET_DELETED_AS_SOON_AS_THE_FOLLOWING_WORK_ORDERS_ARE_FNISHED]: 'Se eliminará tan pronto como finalicen las siguientes Órdenes de Trabajo:',
      [TRANSLATIONS.YOU_WILL_STILL_SEE_THE_PDF_REPORTS_AFTER_DELETION]: 'Los reportes PDF generados previamente quedarán guardados.',
      [TRANSLATIONS.WAIT_FOR_WORK_ORDERS_TO_BE_CANCELED_TO_VIEW_THEM_HERE]: 'Espera a que cancelen órdenes de trabajo para visualizarlos aquí',
      [TRANSLATIONS.NO_JOBS_CANCELED_YET]: 'No hay trabajos cancelados aún',
      [TRANSLATIONS.ENTER_PLACEHOLDER_TEXT]: 'Agregar texto del marcador',
      [TRANSLATIONS.UPLOAD_MEDIA_REFERENCE]: 'Subir referencia multimedia',
      [TRANSLATIONS.REPLACE_MEDIA_REFERENCE]: 'Reemplazar referencia de medios',
      [TRANSLATIONS.USER_MANUAL]: 'Manual de usuario',
      [TRANSLATIONS.LOOK_FOR_MANUAL]: 'Busca manual...',
      [TRANSLATIONS.HOW_TO_MAKE_AN_INSPECTION_CHECKLIST]: 'Cómo hacer una inspección/checklist.',
      [TRANSLATIONS.HOW_TO_CREATE_A_WORK_ORDER]: 'Cómo crear una orden de trabajo.',
      [TRANSLATIONS.KIND_OF_QUESTIONS]: 'Tipos de preguntas.',
      [TRANSLATIONS.IS_SIGNED]: 'Está firmado',
      [TRANSLATIONS.IS_NOT_SIGNED]: 'No está firmado',
      [TRANSLATIONS.IS_UPLOADED]: 'Se agregó',
      [TRANSLATIONS.IS_IMAGE_UPLOADED]: 'Is uploaded',
      [TRANSLATIONS.IS_NOT_UPLOADED]: 'No se agregó',
      [TRANSLATIONS.NO_WO_NAME]: 'Para guardar la orden de trabajo es necesario darle un nombre.',
      [TRANSLATIONS.ADD_AN_IMAGE_TO_SAVE]: 'Para guardar primero debes agregar una imagen.',
      [TRANSLATIONS.ADDED_REASON_FOR_CANCELLATION]: 'Motivo de cancelación',
      [TRANSLATIONS.ENABLE_CAMERA_CAPTURE]: 'Habilitar solo la captura de cámara',
      [TRANSLATIONS.CREATE_WORK_ORDER]: 'Crear trabajo',
      [TRANSLATIONS.CHECKLIST]: 'Inspecciones',
      [TRANSLATIONS.AVAILABLE]: 'disponsible',
      [TRANSLATIONS.START_DATE]: 'Fecha de inicio',
      [TRANSLATIONS.END_DATE]: 'Fecha final',
      [TRANSLATIONS.PLEASE_SELECT_VALIDITY_FOR_WORK_ORDER]: 'Por favor seleccione validez para orden de trabajo',
      [TRANSLATIONS.EDIT_WORK_ORDER]: 'Editar trabajo',
      [TRANSLATIONS.SEARCHED_USERS]: 'Usuarios buscados',
      [TRANSLATIONS.UNAVAILABLE]: 'No disponible',
      [TRANSLATIONS.SEARCH_OR_PRESS_ENTER_AFTER_TYPING_EMAIL]: 'Busque o presione Intro después de escribir el correo electrónico',
      [TRANSLATIONS.SUPERVISORS]: 'Supervisores',
      [TRANSLATIONS.PROCESSING]: 'Procesando',
      [TRANSLATIONS.AUTO_REMOVE_SIGNATURE]: 'Eliminar firma si el usuario cambia su respuesta',
      [TRANSLATIONS.AUTO_HIDE_SIGNATURE]: 'Mostrar firma hasta que se hayan contestado todas las preguntas requeridas',
      [TRANSLATIONS.SURNAME]: 'Surname',
      [TRANSLATIONS.THIS_WILL_BE_PREFILLED_AS_DEFAULT_ANSWER]: 'Esta se completará previamente como respuesta predeterminada.',
      [TRANSLATIONS.SELECT_A_DEFAULT_OPTION]: 'Seleccione una opción predeterminada',
      [TRANSLATIONS.SET_MINIMUM_AND_MAXIMUM]: 'Agregar valores mínimos y máximos',
      [TRANSLATIONS.MINIMUM_VALUE]: 'Establecer valor mínimo',
      [TRANSLATIONS.MAXIMUM_VALUE]: 'Establecer valor máximo',
      [TRANSLATIONS.MAXIMUM_VALUE_SHOULD_BE_GREATER_THAN_MINIMUM_VALUE]: 'El valor máximo debe ser mayor que el valor mínimo',
      [TRANSLATIONS.MINIMUM_VALUE_SHOULD_BE_LESSER_THAN_MAXIMUM_VALUE]: 'El valor mínimo debe ser menor que el valor máximo',
      [TRANSLATIONS.SHOW_INPUT_FOR_SIGNEE_NAME]: 'Mostrar campo de entrada para el nombre del firmante',
      [TRANSLATIONS.REQUIRES_SIGNATURE]: 'Firmar antes de enviar',
      [TRANSLATIONS.SHOW_OTHER_AS_OPTION]: 'Mostrar \'Otro\' como opción',
      [TRANSLATIONS.END_DATE_SHOULD_BE_A_LATER_DATE]: 'La fecha de finalización debe ser posterior.',
      [TRANSLATIONS.VALUE_OUT_OF_RANGE]: '¡Valor fuera de rango!',
      [TRANSLATIONS.VALUE_OUT_OF_RANGE_WISH_TO_CONTINUE]: 'Este valor está fuera de rango. ¿Desea continuar?',
      [TRANSLATIONS.MONTH]: 'Mes',
      [TRANSLATIONS.TODAY]: 'Hoy',
      [TRANSLATIONS.REINVITE]: 'Reinvitar',
      [TRANSLATIONS.FETCHING_REPORT]: 'Obteniendo informe',
      [TRANSLATIONS.SELECTED_CHECKLISTS]: 'Listas de verificación seleccionadas',
    },
  },
  en: {
    translation: {

      [TRANSLATIONS.WRITE]: 'Write',
      [TRANSLATIONS.UNIQUE_SUBTITLE]: 'This is a code for you to identify assets from each other.',
      [TRANSLATIONS.CREATE_NEW_TYPE]: 'Create New Asset',
      [TRANSLATIONS.ADD_ASSETS]: 'Add Asset',
      [TRANSLATIONS.UNIQUE_NAME]: 'Unique name',
      [TRANSLATIONS.INSPECT_NAME]: 'Name to inspect',
      [TRANSLATIONS.ASSET_TYPE]: 'Asset type',
      [TRANSLATIONS.REQUIRED_FIELDS]: 'Please answer all the required Questions',
      [TRANSLATIONS.GREATER_THAN]: 'Greater Than',
      [TRANSLATIONS.LESS_THAN]: 'Less Than',
      [TRANSLATIONS.ADD_ANOTHER]: 'Add another+',
      [TRANSLATIONS.SHARE]: 'Share',
      [TRANSLATIONS.LOGICAL_QUESTION]: 'Conditional logic',
      [TRANSLATIONS.QUESTIONS]: 'Questions',
      [TRANSLATIONS.REPLACE_FILE]: 'Replace file',
      [TRANSLATIONS.INVITE_USER_MAIL]: 'Invite user by mail.',
      [TRANSLATIONS.SEND_INVITATION]: 'Send Invitation',
      [TRANSLATIONS.ADD_USER]: 'Add User',
      [TRANSLATIONS.GO_BACK]: 'Back',
      [TRANSLATIONS.NEW_USER]: 'New User',
      [TRANSLATIONS.SELECT_OPTION]: 'Select an option',
      [TRANSLATIONS.CONTESTAR_CHECKLIST]: 'Answer checklist',
      [TRANSLATIONS.APPLY_OWDS]: 'Apply OWDs',
      [TRANSLATIONS.LOADING_FILE]: 'Loading file',
      [TRANSLATIONS.SELECT_DISTRIBUTION_CENTER]: 'Select a Distribution Center',
      [TRANSLATIONS.SELECT_DISTRIBUTION_PARAGRAPH]: 'In order to access the configuration of the OWDs it is necessary to select a Distribution Center using the top bar',
      [TRANSLATIONS.LAST_LOAD]: 'Last load',
      [TRANSLATIONS.TEMPLATE]: 'Template',
      [TRANSLATIONS.DOWNLOAD_FILE]: 'Download file',
      [TRANSLATIONS.DOWNLOAD_TEMPLATE]: 'Download template',
      [TRANSLATIONS.OVER_WRITE_FILE]: 'Overwrite File',
      [TRANSLATIONS.ARCHIVO]: 'File',
      [TRANSLATIONS.WITHIN_24_HOURS]: 'Less than 24 hours',
      [TRANSLATIONS.MORE_24_HOURS]: 'More than 24 hours',
      [TRANSLATIONS.week1]: 'week 1',
      [TRANSLATIONS.week2]: 'week 2',
      [TRANSLATIONS.week3]: 'week 3',
      [TRANSLATIONS.week4]: 'week 4',
      [TRANSLATIONS.week5]: 'week 5',
      [TRANSLATIONS.week6]: 'week 6',
      [TRANSLATIONS.week7]: 'week 7',
      [TRANSLATIONS.week8]: 'week 8',
      [TRANSLATIONS.week9]: 'week 9',
      [TRANSLATIONS.week10]: 'week 10',
      [TRANSLATIONS.week11]: 'week 11',
      [TRANSLATIONS.week12]: 'week 12',
      [TRANSLATIONS.week13]: 'week 13',
      [TRANSLATIONS.week14]: 'week 14',
      [TRANSLATIONS.week15]: 'week 15',
      [TRANSLATIONS.week16]: 'week 16',
      [TRANSLATIONS.week17]: 'week 17',
      [TRANSLATIONS.week18]: 'week 18',
      [TRANSLATIONS.week19]: 'week 19',
      [TRANSLATIONS.week20]: 'week 20',
      [TRANSLATIONS.week21]: 'week 21',
      [TRANSLATIONS.week22]: 'week 22',
      [TRANSLATIONS.week23]: 'week 23',
      [TRANSLATIONS.week24]: 'week 24',
      [TRANSLATIONS.week25]: 'week 25',
      [TRANSLATIONS.week26]: 'week 26',
      [TRANSLATIONS.week27]: 'week 27',
      [TRANSLATIONS.week28]: 'week 28',
      [TRANSLATIONS.week29]: 'week 29',
      [TRANSLATIONS.week30]: 'week 30',
      [TRANSLATIONS.week31]: 'week 31',
      [TRANSLATIONS.week32]: 'week 32',
      [TRANSLATIONS.week33]: 'week 33',
      [TRANSLATIONS.week34]: 'week 34',
      [TRANSLATIONS.week35]: 'week 35',
      [TRANSLATIONS.week36]: 'week 36',
      [TRANSLATIONS.week37]: 'week 37',
      [TRANSLATIONS.week38]: 'week 38',
      [TRANSLATIONS.week39]: 'week 39',
      [TRANSLATIONS.week40]: 'week 40',
      [TRANSLATIONS.week41]: 'week 41',
      [TRANSLATIONS.week42]: 'week 42',
      [TRANSLATIONS.week43]: 'week 43',
      [TRANSLATIONS.week44]: 'week 44',
      [TRANSLATIONS.week45]: 'week 45',
      [TRANSLATIONS.week46]: 'week 46',
      [TRANSLATIONS.week47]: 'week 47',
      [TRANSLATIONS.week48]: 'week 48',
      [TRANSLATIONS.week49]: 'week 49',
      [TRANSLATIONS.week50]: 'week 50',
      [TRANSLATIONS.week51]: 'week 51',
      [TRANSLATIONS.week52]: 'week 52',
      [TRANSLATIONS.week53]: 'week 53',
      [TRANSLATIONS.week54]: 'week 54',
      [TRANSLATIONS.REFERNCIA]: 'Reference',
      [TRANSLATIONS.DATE_INCIDENT]: 'Incident Date',
      [TRANSLATIONS.DATE_REPORTED]: 'Registration Date',
      [TRANSLATIONS.VALIDITY]: 'Validity',
      [TRANSLATIONS.NATURE]: 'Nature',
      [TRANSLATIONS.CAUSE]: 'Cause',
      [TRANSLATIONS.SUB_CAUSE]: 'Sub Cause',
      [TRANSLATIONS.WELCOME_TO]: 'Welcome To',
      [TRANSLATIONS.ROUTINE_SAVED]: 'Routine Saved!',
      [TRANSLATIONS.CREATE_YOUR_PASSWORD_TO_ACCESS]: 'Create your password to access',
      [TRANSLATIONS.FORGOT_YOUR_PASSWORD]: 'Forgot your password?',
      [TRANSLATIONS.I_REMEMBER_MY_PASSWORD]: 'I remember my password',
      [TRANSLATIONS.RECOVER_IT_HERE]: 'Click here',
      [TRANSLATIONS.LETS_FIND_YOUR_ACC]: 'Lets find your account',
      [TRANSLATIONS.TYPE_YOUR_EMAIL]: 'Enter the email associated with your account.',
      [TRANSLATIONS.SEND_ME_THE_LINK]: 'Email me recovery link',
      [TRANSLATIONS.EMAIL_SENT]: 'Check your email!',
      [TRANSLATIONS.YOU_WILL_RECEIVE_AN_EMAIL]: 'You will receive a link at',
      [TRANSLATIONS.TO_RESET_PASSWORD]: 'to reset your password',
      [TRANSLATIONS.PASSWORD]: 'Password',
      [TRANSLATIONS.CONFIRM_PASSWORD]: 'Confirm password',
      [TRANSLATIONS.ALREADY_HAVE_AN_ACCOUNT]: 'Already have an account?',
      [TRANSLATIONS.LOGIN]: 'Login',
      [TRANSLATIONS.GO_TO_LOGIN]: 'Go to login',
      [TRANSLATIONS.EMAIL]: 'Email',
      [TRANSLATIONS.LOADING]: 'Loading',
      [TRANSLATIONS.YOUR_PASSWORD_WAS_SUCCESSFULLY_CREATED]: 'Your password was successfully created.',
      [TRANSLATIONS.YOU_CAN_START_USING_THE_APP]: 'You can start using the App!',
      [TRANSLATIONS.OPEN_APP]: 'Open app',
      [TRANSLATIONS.CONTACT]: 'Contact',
      [TRANSLATIONS.THIS_URL_HAS_EXPIRED]: 'This URL has expired',
      [TRANSLATIONS.LOGIN_TO_ACCESS_SAFETY_DASHBOARD]: 'Login to access Safety 360 dashboard',
      [TRANSLATIONS.LOGIN_TOP_ESPECTRO_DASHBOARD]: 'Login to access Espectro dashboard',
      [TRANSLATIONS.LOGGING_IN]: 'Logging in',
      [TRANSLATIONS.NAME]: 'Name',
      [TRANSLATIONS.FIRST_SURNAME]: 'First surname',
      [TRANSLATIONS.SECOND_SURNAME]: 'Second surname',
      [TRANSLATIONS.NAME_OF_THE_COMPANY]: 'Name of the Company',
      [TRANSLATIONS.DESIGNATION]: 'Designation',
      [TRANSLATIONS.OPTIONAL]: 'Optional',
      [TRANSLATIONS.REGISTER]: 'Register',
      [TRANSLATIONS.BLOCKED_REASON]: 'Blocked reason',
      [TRANSLATIONS.SEARCH]: 'Search',
      [TRANSLATIONS.BLOCK]: 'Block',
      [TRANSLATIONS.UNBLOCK]: 'Unblock',
      [TRANSLATIONS.CD_SETTINGS]: '{{ location }} Settings',
      [TRANSLATIONS.BACK_TO]: 'Back to',
      [TRANSLATIONS.SETTINGS]: 'Settings',
      [TRANSLATIONS.BLOCK_UNBLOCK]: 'Block/Unblock',
      [TRANSLATIONS.EXIT]: 'Exit',
      [TRANSLATIONS.FILE_UPLOAD]: 'File Upload',
      [TRANSLATIONS.WELCOME]: 'Welcome',
      [TRANSLATIONS.YOU_ARE_VIEWING]: 'You are viewing',
      [BLOCKED_REASONS.SELF_ASSESSMENT]: 'Self-assessment',
      [BLOCKED_REASONS.MANUAL]: 'Manual',
      [BLOCKED_REASONS.VISIT_DOCTOR]: 'Doctor visit',
      [TRANSLATIONS.REPORTS]: 'Reports',
      [TRANSLATIONS.TOTAL_REPORTS]: 'Total SIOs',
      [TRANSLATIONS.UNIQUE]: 'Unique',
      [TRANSLATIONS.AVERAGE]: 'Avg.',
      [TRANSLATIONS.UNIQUE_USERS]: 'Unique users',
      [TRANSLATIONS.AVERAGE_PER_USER]: 'Avg. per user',
      [TRANSLATIONS.AVERAGE_DAILY_PER_USER]: 'Daily avg. per user',
      [TRANSLATIONS.LAST_7_DAYS]: 'Last 7 days',
      [TRANSLATIONS.LAST_30_DAYS]: 'Last 30 days',
      [TRANSLATIONS.LAST_90_DAYS]: 'Last 90 days',
      [TRANSLATIONS.MONTH_TO_DATE]: 'Current month',
      [TRANSLATIONS.YEAR_TO_DATE]: 'Current year',
      [TRANSLATIONS.PRECIDING_PERIOD]: 'Preceding period',
      [TRANSLATIONS.REPORT_HISTORY]: 'Report history',
      [TRANSLATIONS.MONTH_TO_DATE]: 'MTD',
      [TRANSLATIONS.WHERE_ARE_YOU]: 'Where are you?',
      [TRANSLATIONS.ZONE]: 'Zone',
      [TRANSLATIONS.AGENCIA_DC]: 'DC',
      [TRANSLATIONS.CONTINUE]: 'Continue',
      [TRANSLATIONS.NEW_REPORT]: 'New report',
      [TRANSLATIONS.REPORT_DATA]: 'Report data',
      [TRANSLATIONS.WHO_IS_REPORTING_IT]: "Who's reporting?",
      [TICKET_TYPE.ACT]: 'Behaviour',
      [TICKET_TYPE.CONDITION]: 'Condition',
      [SECURITY_STATUS.SECURE]: 'Safe',
      [SECURITY_STATUS.INSECURE]: 'Unsafe',
      [TRANSLATIONS.WHO_COMMITTED_THE_ACT]: 'Who committed the act?*',
      [TRANSLATIONS.IT_WAS_NOT_POSSIBLE_TO_IDENTIFY]: 'Non-identified user',
      [TRANSLATIONS.IT_IS_GOING_TO_BE_AN_ANONYMOUS_REPORT]: 'It is going to be an anonymous report',
      [TRANSLATIONS.IT_IS_AN_ANONYMOUS_REPORT]: 'It is an anonymous report',
      [TRANSLATIONS.I_COULDNT_IDENTIFY_THE_PERSON]: "Couldn't identify user",
      [TRANSLATIONS.SEARCH_THE_LIST_AGAIN]: 'Search the list again',
      [TRANSLATIONS.BACK_TO_SEARCH_BAR]: 'Back to search bar',
      [TRANSLATIONS.WHERE_DID_YOU_SEE]: 'Where did you see',
      [TRANSLATIONS.WHERE_DID_YOU_SEE_IN_DC_AREA]: 'Where did you see in CD area',
      [TRANSLATIONS.CD_AREA]: 'CD area *',
      [TRANSLATIONS.IN_WHICH_AREA_OF_DISTRIBUTION_CENTER]: 'In which area of the Distribution Center? *',
      [TRANSLATIONS.SPECIFY_THE_PLACE_IN_THE_SUBAREA]: 'Specify the place in the subarea',
      [TRANSLATIONS.TYPE_OF_RISK]: 'Risk type',
      [TRANSLATIONS.RISK_GROUP]: 'Classification',
      [TRANSLATIONS.WHAT_RISK_TYPE_WAS_PREVENTED]: 'What type of risk was prevented?*',
      [TRANSLATIONS.WHAT_RISK_TYPE_WAS_PREVENTED_WITH_ACT]: 'What type of risk was prevented with this behaviour?*',
      [TRANSLATIONS.WHAT_RISK_TYPE_WAS_PREVENTED_WITH_CONDITION]: 'What type of risk was prevented with this condition?*',
      [TRANSLATIONS.WHO_HAD_THIS_BEHAVIOR]: 'Who had this behaviour?',
      [TRANSLATIONS.WHO_SUBMITTED_THIS_BEHAVIOR]: 'Who reported this behaviour?',
      [TRANSLATIONS.WHO_HAD_THIS_CONDITION]: 'Who had this condition?',
      [TRANSLATIONS.WHO_SUBMITTED_THIS_CONDITION]: 'Who reported this condition?',
      [TRANSLATIONS.WHO_SUBMITTED_THIS_ENVIRONMENTAL_RISK]: 'Who reported this environmental risk?',
      [TRANSLATIONS.SELECT_RISK_TYPE]: 'Select type of risk',
      [TRANSLATIONS.SELECT_RISK_GROUP]: 'Select risk group',
      [TRANSLATIONS.BEHAVIOR]: 'Behaviour',
      [TRANSLATIONS.DESCRIPTION]: 'Description',
      [TRANSLATIONS.OF_THE_ACT]: 'of the act',
      [TRANSLATIONS.OF_THE_CONDITION]: 'of the condition',
      [TRANSLATIONS.RISK_LEVEL_TRANSLATION]: 'Risk level',
      [RISK_LEVEL.HIGH]: 'High',
      [RISK_LEVEL.LOW]: 'Low',
      [PRIORITY.MEDIUM]: 'Medium',
      [TRANSLATIONS.CORRECTIVE_ACTION]: 'Corrective action',
      [TRANSLATIONS.UPLOAD_REGISTRATION]: 'Upload file',
      [TRANSLATIONS.UPLOAD_EVIDENCE]: 'Upload evidence',
      [TRANSLATIONS.ACI_OVERVIEW]: 'Summary',
      [TRANSLATIONS.LEVEL]: 'Level',
      [TRANSLATIONS.FINISH_AND_SEND]: 'Send',
      [TRANSLATIONS.THANK_YOU]: 'Thank You',
      [TRANSLATIONS.SENT_SUCCESSFULLY]: 'Sent successfully',
      [TRANSLATIONS.SIO_SUCCESSFULLY_SENT]: 'SIO sent successfully',
      [TRANSLATIONS.WITH_YOUR_HELP_WE_CAN_PREVENT_ACCIDENTS_IN_FUTURE]: 'With your help we can prevent more accidents in the future!',
      [TRANSLATIONS.THANKS_TO_YOU_WE_CAN_IMPROVE_SECURITY_AMONG_OUR_COLLABORATORS]: 'Thanks to you we can improve security among our collaborators.',
      [TRANSLATIONS.SUCCESSFUL_REGISTRATION]: 'Successful registration!',
      [TRANSLATIONS.YOUR_REGISTRATION_WAS_SENT_SUCCESSFULLY]: 'Your registration was sent successfully',
      [TRANSLATIONS.THE_REPORT_HAS_BEEN_SUCCESSFULLY_SENT]: 'The report has been successfully sent',
      [TRANSLATIONS.DOWNLOAD]: 'Download',
      [TRANSLATIONS.CERTIFICATES]: 'certificates',
      [TRANSLATIONS.AMBIENTAL_RISKS]: 'Environmental Risks',
      [TRANSLATIONS.BOTH]: 'both',
      [TRANSLATIONS.INCIDENTS]: 'SIOs',
      [TRANSLATIONS.INCIDENTS_PER_POSITION]: 'SIOs per position',
      [TRANSLATIONS.ALL_ZONES]: 'All zones',
      [TRANSLATIONS.DRVS]: 'DRVs',
      [TRANSLATIONS.DISTRIBUTION_CENTERS]: 'Location',
      [pluralize(TRANSLATIONS.DISTRIBUTION_CENTERS)]: 'Locations',
      [TRANSLATIONS.ACIS]: 'Reports',
      [TRANSLATIONS.LOGOUT]: 'Logout',
      [TRANSLATIONS.GO_TO_DASHBOARD]: 'Go to dashboard',
      [TRANSLATIONS.REQUIRED]: 'Required',
      [TRANSLATIONS.DECEMBER]: 'December',
      [TRANSLATIONS.NOVEMBER]: 'November',
      [TRANSLATIONS.OCTOBER]: 'October',
      [TRANSLATIONS.SEPTEMBER]: 'September',
      [TRANSLATIONS.AUGUST]: 'August',
      [TRANSLATIONS.JULY]: 'July',
      [TRANSLATIONS.JUNE]: 'June',
      [TRANSLATIONS.MAY]: 'May',
      [TRANSLATIONS.APRIL]: 'April',
      [TRANSLATIONS.MARCH]: 'March',
      [TRANSLATIONS.FEBRUARY]: 'February',
      [TRANSLATIONS.JANUARY]: 'January',
      [TRANSLATIONS.ALL_MONTHLY_OPTIONS]: 'All',
      // external users module - start
      [TRANSLATIONS.EXTERNAL]: 'External',
      [TRANSLATIONS.INTERNAL]: 'Internal',
      [TRANSLATIONS.SEARCH_USERS]: 'Search users',
      [TRANSLATIONS.ADD_USER_TO_DC]: 'Add user to',
      [TRANSLATIONS.USER_ALREADY_EXISTS]: 'Check if user already exists',
      [TRANSLATIONS.DOES_USER_ACCOUNT]: 'Does the user already have a Safety360 account?',
      [TRANSLATIONS.YES_HE_HAS]: 'Yes, they do',
      [TRANSLATIONS.ENTER_EMAIL]: 'Enter email',
      [TRANSLATIONS.NO_CREATE_SCRATCH]: 'No, create user from scratch',
      [TRANSLATIONS.POSITION]: 'Position',
      [TRANSLATIONS.PERMISSIONS]: 'Permissions',
      [TRANSLATIONS.ROUTINES]: 'Routines',
      [TRANSLATIONS.TRAININGS]: 'Trainings',
      [TRANSLATIONS.ACCIDENTABILITY]: 'Accidentability',
      [TRANSLATIONS.ANALYTICS]: 'Analytics',
      [TRANSLATIONS.CANCEL]: 'Cancel',
      [TRANSLATIONS.ADD]: 'Add',
      [TRANSLATIONS.ACCESS]: 'Access',
      [TRANSLATIONS.INFORMATION_NOT_AVAILABLE]: 'Information not available',
      [TRANSLATIONS.EDIT_INFORMATION]: 'Edit',
      [TRANSLATIONS.DELETE_USER]: 'Delete User',
      [TRANSLATIONS.DELETE_USER_OPTION_MENU]: 'Delete',
      [TRANSLATIONS.CONFIRM]: 'Confirm',
      [TRANSLATIONS.ENTER_NAME]: 'Enter name',
      [TRANSLATIONS.YES]: 'Yes',
      [TRANSLATIONS.NO]: 'No',
      [TRANSLATIONS.USERS]: 'Users',
      [TRANSLATIONS.CHECK_YOUR_EMAIL]: 'Please check your email.',
      [TRANSLATIONS.ASK_USER_WHICH_EMAIL]: 'Ask the user what email he used to register for Safety360.',
      [TRANSLATIONS.SOMETHING_WENT_WRONG_TRY_LATER]: 'Something went wrong. Please try again later.',
      [TRANSLATIONS.USER_NOT_EXIST_CREATE_NEW]: 'User does not exist, verify the email or create a new user',
      [TRANSLATIONS.DELIVERY_MAN]: 'Delivery Driver',
      [TRANSLATIONS.SUPPLIER]: 'Supplier',
      [TRANSLATIONS.WAREHOUSE_ASSISTANT]: 'Warehouse Assistant',
      [TRANSLATIONS.PEOPLE_AND_MANAGEMENT]: 'People and Management',
      [TRANSLATIONS.FORKLIFT_DRIVER]: 'Forklift Driver',
      [TRANSLATIONS.INVITATION_SENT]: 'Invitation sent',
      // external users module - end
      [TRANSLATIONS.WEEKLY_REPORTS]: 'Weekly Reports',
      [TRANSLATIONS.BOXES]: 'boxes',
      [TRANSLATIONS.WHO_COMMITTED]: 'Who did it',
      [TICKET_TYPE.ACT]: 'Act',
      [TICKET_TYPE.CONDITION]: 'Condition',
      [TRANSLATIONS.DATE_REPORTED]: 'Date Reported',
      [TRANSLATIONS.REPORTS_LIST]: 'SIO List',
      [TRANSLATIONS.ALL]: 'All',
      [TRANSLATIONS.BEHAVIORS]: 'Behaviours',
      [TRANSLATIONS.CONDITIONS]: 'Conditions',
      [TRANSLATIONS.DOWNLOAD_LIST]: 'Download list',
      [TRANSLATIONS.PLACE]: 'Place',
      [TRANSLATIONS.TOTAL]: 'Total',
      [TRANSLATIONS.OPENED]: 'Opened',
      [TRANSLATIONS.CLOSED]: 'Closed',
      [TRANSLATIONS.ASSIGNED]: 'Assigned',
      [TRANSLATIONS.CLOSE]: 'Close',
      [TRANSLATIONS.REPORT]: 'Report',
      [TRANSLATIONS.ASSIGN]: 'Assign',
      [TRANSLATIONS.INSIDE_DC]: 'Inside Location',
      [TRANSLATIONS.OUTSIDE_DC]: 'Outside Location',
      [TRANSLATIONS.CONDITION]: 'Condition',
      [TRANSLATIONS.ENVIRONMENTAL_RISK]: 'Environmental risk',
      [TRANSLATIONS.ACT]: 'Acts',
      [TRANSLATIONS.DESCRIPTION_OF_THE_CONDITION]: 'Describe the condition',
      [TRANSLATIONS.DESCRIBE_THE_BEHAVIOR]: 'Describe the behaviour',
      [TRANSLATIONS.CONFIRM_USER_DELETION]: 'Are you sure you want to delete',
      [TRANSLATIONS.PIN_CREATED_SUCCESSFULLY]: 'PIN created successfully',
      [TRANSLATIONS.REGION]: 'Region',
      [TRANSLATIONS.LOCATION]: 'Location',
      [TRANSLATIONS.BUSINESS_UNIT]: 'Business Unit',
      [TRANSLATIONS.SELECT_REGION]: 'Select region',
      [TRANSLATIONS.SELECT_DC]: 'Select Location',
      [TRANSLATIONS.SELECT]: 'Select',
      [TRANSLATIONS.SELECT_ANSWER]: 'Select answer type',
      [TRANSLATIONS.SELECT_SUBLOCATION]: 'Select Sublocation',
      [TRANSLATIONS.SELECT_POSITION]: 'Select a position',
      [TRANSLATIONS.WRITE_YOUR_8_DIGIT_SAP_NUMBER]: 'Type in your 8-digit #SAP',
      [TRANSLATIONS.WRITE_AN_8_DIGIT_SAP_NUMBER]: 'Type in an 8-digit #SAP',
      [TRANSLATIONS.SAP_SUCCESSFULLY_VALIDATED]: '#SAP successfully validated',
      [TRANSLATIONS.PLEASE_MAKE_SURE_ITS_AN_8_DIGIT_NUMBER]: "Please make sure it's an 8-digit number.",
      [TRANSLATIONS.TO_KEEP_A_TRACK_RECORD_OF_YOUR_REPORTS_TYPE_IN_YOUR_SAP_NUMBER]: 'To keep a track record of your reports, type in your SAP number.',
      [TRANSLATIONS.IF_YOU_LEAVE_IT_EMPTY_OR_INCORRECT_THIS_WILL_COUNT_AS_ANONYMOUS_REPORT]: 'If you leave it empty or incorrect this will count as anonymous report ☝🏼',
      [TRANSLATIONS.INCIDENTS_NATIONAL]: 'SIOs (National)',
      [TRANSLATIONS.CLEAR]: 'Clear',
      [TRANSLATIONS.OVERVIEW]: 'Overview',
      [TRANSLATIONS.DIST_CENTERS]: 'Locations',
      [TRANSLATIONS.HOME]: 'Home',
      [TRANSLATIONS.BLOCK_UNBLOCK_SHORT]: 'Block/Unblock',
      [TRANSLATIONS.PRECEDING_PERIOD]: 'Preceding period',
      [TRANSLATIONS.AVERAGE]: 'Average',
      [TRANSLATIONS.PARTICIPATION]: 'Participation',
      [TRANSLATIONS.DAILY_AVG_PER_USER]: 'Daily avg. per user',
      [TRANSLATIONS.ALL_THE_BUS]: 'All the BUs',
      [TRANSLATIONS.ALL]: 'All',
      [TRANSLATIONS.BU]: 'Country',
      [TRANSLATIONS.PASSWORD_TOO_SHORT]: 'Password too short',
      [TRANSLATIONS.PASSWORD_NO_MATCH]: 'Passwords do not match',
      [TRANSLATIONS.DO_NOT_USE_YOUR_OLD_PASSWORD]: 'Do not use your old password',
      [TRANSLATIONS.ROUTINES_FOLLOWUP]: 'Routines Follow-up',
      [TRANSLATIONS.WEEK]: 'Week',
      [TRANSLATIONS.TRAININGS_PERCENTAGE]: 'TRAININGS %',
      [TRANSLATIONS.SIF_ROUTE_PERCENTAGE]: 'SIF ROUTE %',
      [TRANSLATIONS.TOTAL_SIF_ROUTES]: 'TOTAL',
      [TRANSLATIONS.COURSES]: 'Courses',
      [TRANSLATIONS.HC_TO_BE_TRAINED]: 'HC to be trained',
      [TRANSLATIONS.TRAINED]: 'Trained',
      [TRANSLATIONS.IMAGE]: 'Image',
      [TRANSLATIONS.USER_ACCESS_DISCLAIMER]: "If you don't see the correct locations, get in touch with our support team in the bottom-right corner.",
      [TRANSLATIONS.USER_MISSING_DISCLAIMER]: 'If internal users are missing, get in touch with our support team in the bottom-right corner.',
      [TRANSLATIONS.SECURE_CONDITION_DISCLAIMER]: 'The opened, closed and assigned sections, do not include secure SIOs',
      [TRANSLATIONS.YOU_DONT_HAVE_ANY_LOCATION_ASSIGNED_PLEASE_CONTACT_SUPPORT]: 'You do not have any location assigned. Please get in touch with our support team in the bottom-right corner.',
      [TRANSLATIONS.DOWNLOAD_ALL]: 'Download All',
      [TRANSLATIONS.INCIDENTS_PER_POSITION]: 'SIOs per position',
      [TRANSLATIONS.INCIDENTS_PER_AREA]: 'SIOs per area',
      [TRANSLATIONS.RESET]: 'Reset',
      [TRANSLATIONS.SAFE]: 'Safe',
      [TRANSLATIONS.PLANT]: 'CD/Plant',
      [TRANSLATIONS.ID]: 'ID',
      [TRANSLATIONS.SHARP]: 'Sharp',
      [TRANSLATIONS.STATUS]: 'Status',
      [TRANSLATIONS.REASON]: 'Reason to block',
      [TRANSLATIONS.ACTIONS]: 'Actions',
      [TRANSLATIONS.BLOCKED]: 'Blocked',
      [TRANSLATIONS.MEDIACAL_EXAMINATION]: 'Medical Examination',
      [TRANSLATIONS.NO_DATA]: 'No Data',
      [TRANSLATIONS.NO_DATA_FOUND_SO_FAR]: 'So far no data has been found for this section',
      [TRANSLATIONS.CURRENT_PROGRESS]: 'Curr. Progress',
      [TRANSLATIONS.TOTAL_PROGRESS]: 'Total Progress',
      [TRANSLATIONS.LAST]: 'Last',
      [TRANSLATIONS.DOWNLOAD_ROUTINES_DB]: 'Download the Routines data base',
      [TRANSLATIONS.SEARCH_LOCATION]: 'Search location',
      [TRANSLATIONS.NO_MATCHES_FOUND]: 'No matches found',
      [TRANSLATIONS.CONSEQUENCES]: 'Consequences',
      [TRANSLATIONS.SELECT_SUBZONE_OR_DISTRIBUTION_CENTER]: 'Please select a subzone or a distribution center to view routine progress of each user',
      [TRANSLATIONS.CRITICAL_CLIENTS]: 'Critical clients',
      [TRANSLATIONS.BY_BU]: 'By BU',
      [TRANSLATIONS.BY_POSITION]: 'By Position',
      [TRANSLATIONS.BY_ROUTINES]: 'By Routines',
      // we need to translate this to english
      [TRANSLATIONS.DIARIA_LOGÍSTICA]: 'Daily logistics',
      [TRANSLATIONS.RUTINA_MATUTINA_DE_REPARTO]: 'Morning delivery routine (delivery)',
      [TRANSLATIONS.RUTINA_DE_CAMBIO_DE_TURNO]: 'Shift change routine (warehouse)',
      [TRANSLATIONS.RUTINA_SEMANAL_DE_ALMACÉN]: 'Weekly warehouse routine',
      [TRANSLATIONS.RUTINA_SEMANAL_DE_DIST]: 'Weekly Distribution routine',
      [TRANSLATIONS.RUTINA_SEMANAL_DE_PEOPLE]: 'Weekly People routine',
      [TRANSLATIONS.RUTINA_SEMANAL_DE_FLOTA]: 'Weekly Fleet routine',
      [TRANSLATIONS.RUTINA_SEMANAL_DE_PLANNING]: 'Weekly Planning routine (Financiera VLC)',
      [TRANSLATIONS.RUTINA_SEMANAL_DE_SAFETY]: 'Weekly Safety routine (Financiera VLC)',
      [TRANSLATIONS.RUTINE_SEMANAM_DE_GESTION]: 'Weekly Management routine',
      [TRANSLATIONS.AT_HOME]: 'At home',
      [TRANSLATIONS.HOSPITAL]: 'Hospital',
      [TRANSLATIONS.STABLE]: 'Stable',
      [TRANSLATIONS.STABLE_WITH_OXYGEN]: 'Stable with oxygen',
      [TRANSLATIONS.SERIOUS_WITH_OXYGEN]: 'Serious with oxygen',
      [TRANSLATIONS.SERIOUS_WITHOUT_OXYGEN]: 'Serious without oxygen',
      [TRANSLATIONS.LAST_UPDATED_ON_MONTH_DATE_DAY]: 'Last Updated {{month}} {{date}}, {{day}} days ago.',
      [TRANSLATIONS.LAST_UPDATED_TODAY]: 'Last Updated Today.',
      [TRANSLATIONS.UPDATE_USER_STATUS_TODAY_MONTH_DATE]: 'Update status of {{user}} today {{month}} {{date}}.',
      [TRANSLATIONS.WHERE_IS_USER]: 'Where is {{user}}?',
      [TRANSLATIONS.REMEMBER_THAT_SENDING_THIS_INFORMATION_IS_VERY_IMPORTANT_FOR_US_TO_GIVE_YOU_THE_BEST_POSSIBLE_SUPPORT_THROUGH_THE_QUARANTINE_PROCESS]: 'Remember that sending this information is very important for us to give you the best possible support through the quarantine process.',
      [TRANSLATIONS.SAVE]: 'Save',
      [TRANSLATIONS.ADD_COMMENT]: 'Add Comment',
      [TRANSLATIONS.COMMENT_CREATED_AT]: '{{date}} at {{time}}',
      [TRANSLATIONS.COMMENT_SHOULD_BE_UNDER_N_CHARACTER]: 'Comment should be under {{maxSize}} characters',
      [TRANSLATIONS.EDIT]: 'Edit',
      [TRANSLATIONS.DELETE]: 'Delete',
      [TRANSLATIONS.DELETING]: 'Deleting',
      [TRANSLATIONS.PARTICIPANT]: 'Participant',
      [TRANSLATIONS.OWNER]: 'Owner',
      [TRANSLATIONS.IF_NOT_NECESSARY_RETURN_TO_UPDATE_TOMORROW]: 'If not necessary, return to update tomorrow.',
      [TRANSLATIONS.STATUS_UPDATED_AS_OF_TODAY_DATE_MONTH]: 'Status updated as of today {{month}} {{date}}.',
      [TRANSLATIONS.UPDATED_TODAY_AT_TIME]: 'Updated today at {{time}}',
      [TRANSLATIONS.YOUR_QUARANTINE_HAS]: 'Your quarantine has',
      [TRANSLATIONS.CONCLUDED]: 'concluded',
      [TRANSLATIONS.PHYSICAL_STATE]: 'Physical state',
      [TRANSLATIONS.PILLAR]: 'Pillar',
      [TRANSLATIONS.MANAGEMENT]: 'Management',
      [TRANSLATIONS.PLANNING]: 'Planning',
      [TRANSLATIONS.DISTRIBUTION]: 'Distrib.',
      [TRANSLATIONS.FLEET]: 'Fleet',
      [TRANSLATIONS.STOCK]: 'Stock',
      [TRANSLATIONS.PEOPLE]: 'People',
      [TRANSLATIONS.WAREHOUSE]: 'Warehouse',
      [TRANSLATIONS.NOTE]: 'Note',
      [TRANSLATIONS.SEARCH_KPI]: 'Search KPIs',
      [TRANSLATIONS.SEARCH_ROUTINE]: 'Search Routines',
      [TRANSLATIONS.NO_RESULTS_FOUND]: 'No results found',
      [TRANSLATIONS.SEARCH_TO_ADD_USERS]: 'Search To Add Users',
      [TRANSLATIONS.ACTION_LOG_TABLE_FOR_LOCATION_NAME]: 'Action log table for {{locationName}}',
      [TRANSLATIONS.VIOLENCE_PREVENTION]: 'Violence prevention',
      [TRANSLATIONS.DELETE_FILTERS]: 'Delete filters',
      [TRANSLATIONS.ALL_ROUTINES]: 'All routines',
      [TRANSLATIONS.ALL_KPIS]: 'All KPIs',
      [TRANSLATIONS.ALL_USERS]: 'All users',
      [TRANSLATIONS.SELECT_THE_ROUTINE_YOU_WANT_TO_SEE]: 'Select the routine you want to see',
      [TRANSLATIONS.SELECT_THE_KPIS_YOU_WANT_TO_SEE]: 'Select the KPIs you want to see',
      [TRANSLATIONS.SELECT_THE_USERS_YOU_WANT_TO_SEE]: 'Select the users you want to see',
      [TRANSLATIONS.NO_DUE_DATE_SELECTED]: 'No due date selected',
      [TRANSLATIONS.DELETE_COMMENT]: 'Delete comment',
      [TRANSLATIONS.CLICK_HERE_DROP_TO_UPLOAD]: 'Click here or drop a file to upload!',
      [TRANSLATIONS.DROP_IT_HERE]: 'Drop it here',
      [TRANSLATIONS.FILE_TYPE_NOT_ACCEPTED_SORRY]: 'File type not accepted, sorry!',
      [TRANSLATIONS.MAXIMUM_ALLOWED_FILE_SIZE]: 'Maximum allowed file size : {{size}}',
      [TRANSLATIONS.REMOVE_FILE]: 'Remove file',
      [TRANSLATIONS.UPLOADING]: 'Uploading',
      [TRANSLATIONS.UPLOAD]: 'Upload',
      [TRANSLATIONS.ERROR]: 'Error',
      [TRANSLATIONS.RETRY]: 'Retry',
      [TRANSLATIONS.FILE_IS_TOO_LARGE]: 'File is too large',
      [TRANSLATIONS.SERVER_ERROR]: 'Server error',
      [TRANSLATIONS.PROCESSING_YOUR_FILE]: 'Processing your file',
      [TRANSLATIONS.PROCESSING_FAILED_WITH_MESSAGE]: 'Processing failed {{msg}}',
      [TRANSLATIONS.CLICK_TO_VISIT]: 'Click to visit {{location}}',
      [TRANSLATIONS.REUPLOAD]: 'Re-upload',
      [TRANSLATIONS.FINISHED_FILE_PROCESSING]: 'Finished file processing',
      [TRANSLATIONS.DONE]: 'Done',
      [TRANSLATIONS.ALL_ITEMS_ARE_SELECTED]: 'All items are selected',
      [TRANSLATIONS.CLEAR_SEARCH]: 'Clear search',
      [TRANSLATIONS.NO_OPTIONS]: 'No options',
      [TRANSLATIONS.SELECT_ALL]: 'Select all',
      [TRANSLATIONS.SELECT_ALL_FILTERED]: 'Select all (filtered)',
      [TRANSLATIONS.ROUTINE_COMPLIANCE_HEADING]: 'Routine compliance',
      [TRANSLATIONS.ROUTINE_COMPLIANCE_COMPLETED_PERCENTAGE]: 'routine %',
      [TRANSLATIONS.ROUTINE_COMPLIANCE_COMPLIANCE]: 'Compliance',
      [TRANSLATIONS.ROUTINE_COMPLIANCE_EXECUTED]: 'Executed',
      [TRANSLATIONS.ROUTINE_COMPLIANCE_PENDING]: 'Pending',
      [TRANSLATIONS.ROUTINE_COMPLIANCE_DOWNLOAD_DATA]: 'Download data',
      [TRANSLATIONS.ROUTINE_COMPLIANCE_RANKING]: 'DC Ranking for MAZ',
      [TRANSLATIONS.ROUTINE_COMPLIANCE_PLANNED]: 'Plans',
      [TRANSLATIONS.ROUTINE_COMPLIANCE_SEARCH]: 'Search By GRO...',
      [TRANSLATIONS.ROUTINE_COMPLIANCE_REGION]: 'Country/ Region',
      [TRANSLATIONS.ROUTINE_COMPLIANCE_BU]: 'Per BU',
      [TRANSLATIONS.ROUTINE_COMPLIANCE_CD]: 'CD',
      [TRANSLATIONS.TOOLKIT_HEADING]: 'Violence prevention',
      [TRANSLATIONS.TOOLKIT_CURRENT_MONTH]: 'Current month',
      [TRANSLATIONS.TOOLKIT_PREVIOUS_MONTH]: 'Previous month',
      [TRANSLATIONS.META]: 'Goal',
      [TRANSLATIONS.NOTES]: 'Notes for',
      [TRANSLATIONS.COMMENT_BY]: 'Comment written by',
      [TRANSLATIONS.LATEST_EDIT]: 'Latest Edit',
      [TRANSLATIONS.SAVE_NOTE]: 'Save note',
      [TRANSLATIONS.SIF_STATISTICS]: 'SIF Statistics',
      [TRANSLATIONS.USER_TYPE]: 'User Type',
      [TRANSLATIONS.USER_ROLE_TRANSLATION]: 'User Role',
      [TRANSLATIONS.UPDATE]: 'Update',
      [TRANSLATIONS.PASSWORD_RECOVERY]: 'Password recovery',
      [TRANSLATIONS.UPDATE_YOUR_PASSWORD_TO_ACCESS]: 'Update your password to access',
      [TRANSLATIONS.YOUR_PASSWORD_WAS_SUCCESSFULLY_RESTORED]: 'Your password was successfully restored.',
      [TRANSLATIONS.YOU_CAN_USE_THE_APP_AGAIN]: 'You can use the app again!',
      [TRANSLATIONS.REDIRECT_TIMEOUT]: 'Please wait while you are being redirected. If you are not being redirected after 10 seconds click',
      [TRANSLATIONS.HERE]: 'Here',
      [TRANSLATIONS.PREVENTION_CAMPAIGNS]: 'Preventive Campaign',
      [TRANSLATIONS.BEST_PRACTICES]: 'Best Practices',
      [TRANSLATIONS.MONTHLY_TOTAL]: 'Monthly Total',
      [TRANSLATIONS.BOARDS]: 'Boards',
      [TRANSLATIONS.OWD]: 'OWDs',
      [TRANSLATIONS.PDT]: 'PDTs',
      [TRANSLATIONS.SAFETY_NEWS]: 'Safety News',
      [TRANSLATIONS.TOP]: 'TOP',
      [TRANSLATIONS.BOTTOM]: 'BOTTOM',
      [TRANSLATIONS.SELECT_MONTH]: 'Select Month',
      [TRANSLATIONS.PYRAMID]: 'PYRAMID',
      [TRANSLATIONS.COUNTRY]: 'Country',
      [TRANSLATIONS.PLEASE_UPLOAD_A_FILE]: 'Please upload a file',
      [TRANSLATIONS.LAST_UPDATED_ON]: 'Last updated on {{monthAndYear}}',
      [TRANSLATIONS.TELEMETRY]: 'Telemetry',
      [TRANSLATIONS.CURRENT_MONTH]: 'Current month',
      [TRANSLATIONS.BY_CATEGORY]: 'By category',
      [TRANSLATIONS.TELEMETRY_BY_BU]: 'Telemetry by BU',
      [TRANSLATIONS.REGULAR_SPEEDING]: 'Regular speeding',
      [TRANSLATIONS.CRITICAL_SPEEDING]: 'Critical speeding',
      [TRANSLATIONS.CURVE_SPEEDING]: 'Curve speeding',
      [TRANSLATIONS.HARSH_TURNING]: 'Harsh turning',
      [TRANSLATIONS.SEATBELT]: 'Seatbelt',
      [TRANSLATIONS.VEHICLE]: 'Vehicle',
      [TRANSLATIONS.CLICK_HERE]: 'Click Here!',
      [TRANSLATIONS.ACTION_LOGS]: 'Action Logs',
      [TRANSLATIONS.AMBIENTAL_RISKS]: 'Environmental Risks',
      [TRANSLATIONS.PRODUCTION_PERFORMANCE_TRACKING]: 'Production Performance Tracking',
      [TRANSLATIONS.ACTION_LOGS]: 'Action Logs',
      [TRANSLATIONS.BY]: 'by',
      [TRANSLATIONS.COUNTRY]: 'Country',
      [TRANSLATIONS.SIF_ROUTE]: 'SIF Route',
      [TRANSLATIONS.SIF_COMPLANICE]: 'SIF Compliance',
      [TRANSLATIONS.SAFETY_TRAINING]: 'Trainings',
      [TRANSLATIONS.QR_CODE]: 'QR Code',
      [TRANSLATIONS.SHAREABLE_COMMENT]: 'Share',
      [TRANSLATIONS.INVALID_CREDENTIALS_TRY_AGAIN]: 'Invalid Credentials. Try Again!',
      [TRANSLATIONS.MANAGEMENT_TOOLS]: 'Management Tools',
      [TRANSLATIONS.THERE_IS_NO_NOTE_ADDED_FOR_THIS_ACTION_LOG]: 'There is no note added for this action log...',
      [TRANSLATIONS.THERE_ARE_NO_ACTION_LOGS_IN_THIS_COLUMN_YET]: 'There are no action logs in this column yet.',
      [TRANSLATIONS.ADD_ACTION_LOG]: 'Add action log',
      [TRANSLATIONS.OPEN]: 'Open',
      [TRANSLATIONS.IN_PROGRESS]: 'In progress',
      [TRANSLATIONS.IN_REVIEW]: 'In review',
      [TRANSLATIONS.COMPLETED]: 'Completed',
      [TRANSLATIONS.WRITE_ACTION_HERE]: 'Write action here',
      [TRANSLATIONS.SUCCESS_CRITERIA]: 'Success criteria',
      [TRANSLATIONS.WRITE_SUCCESS_CRITERIA_HERE]: 'Write success criteria here...',
      [TRANSLATIONS.REVIEW_IN]: 'REVIEW IN',
      [TRANSLATIONS.SAVE_ACTION_LOG]: 'Save action log',
      [TRANSLATIONS.CREATE_ACTION_LOG]: 'Create action log',
      [TRANSLATIONS.SHOULD_BE_CLOSED_TODAY]: 'Should be closed today',
      [TRANSLATIONS.SHOULD_HAVE_BEEN_CLOSED]: 'Should have been closed',
      [TRANSLATIONS.DAY]: 'day',
      [TRANSLATIONS.DAYS]: 'days',
      [TRANSLATIONS.TO_BE_CLOSED]: 'to be closed',
      [TRANSLATIONS.THERE_ARE_NO_COMMENTS_YET]: 'There are no comments yet',
      [TRANSLATIONS.CREATED]: 'Created',
      [TRANSLATIONS.DUE_DATE]: 'Due date',
      [TRANSLATIONS.SINCE]: 'since',
      [TRANSLATIONS.IN]: 'in',
      [TRANSLATIONS.WRITE_A_NOTE]: 'Write a note',
      [TRANSLATIONS.WAIT_A_MOMENT]: 'Wait a moment',
      [TRANSLATIONS.IF_YOU_LEAVE_IT_WITHOUT_SAVING_YOUR_CHANGES_WILL_LOST_WANT_TO_STAY_HERE]: 'If you leave without saving, your changes will be lost, do you want to stay?',
      [TRANSLATIONS.LEAVE_WITHOUT_SAVE]: 'Leave without saving',
      [TRANSLATIONS.DELETE_CARD]: 'Delete card',
      [TRANSLATIONS.NOT_ENOUGH_PERMISSIONS_TO_ACCESS]: 'Not enough permissions to access',
      [TRANSLATIONS.WEB_ACCESS]: 'Web Access',
      [TRANSLATIONS.INDICATORS]: 'Indicators',
      [TRANSLATIONS.DVR_CENTRAL_BAJIO]: 'DRV Central Bajio',
      [TRANSLATIONS.METROPOLITAN_SBU]: 'Metropolitan SBU',
      [TRANSLATIONS.METROPOLITAN]: 'Metropolitan',
      [TRANSLATIONS.CD_SAN_PABLO]: 'CD San ​​Pablo',
      [TRANSLATIONS.WAREHOUSE_HELPERS]: 'Store helper',
      [TRANSLATIONS.FORKLIFTERS]: 'Forklifters',
      [TRANSLATIONS.SKAP_DISTRIBUTION]: 'Distribution',
      [TRANSLATIONS.SKAP]: 'SKAP',
      [TRANSLATIONS.SORRY_THERE_WAS_AN_ERROR]: 'Sorry, there was an error',
      [TRANSLATIONS.RELOAD_PAGE]: 'reload page',
      [TRANSLATIONS.IMAPACTED_HC]: 'HC impacted',
      [TRANSLATIONS.FUNCTIONAL_INDUCTION]: 'Induction functional ',
      [TRANSLATIONS.TECHNICAL_SKILLS]: 'Technical skills',
      [TRANSLATIONS.AUTONOMOUS_SKILLS]: 'Autonomous skills',
      [TRANSLATIONS.FUNCTIONAL]: 'Functional',
      [TRANSLATIONS.TECHNICAL]: 'Technical',
      [TRANSLATIONS.AUTONOMOUS]: 'Autonomous',
      [TRANSLATIONS.AUTONOMY_LEVEL]: 'Autonomy level',
      [TRANSLATIONS.HEAD_COUNT]: 'Head count',
      [TRANSLATIONS.RAMP_UP]: 'Ramp up',
      [TRANSLATIONS.AREA]: 'Area',
      [TRANSLATIONS.HC_TOTAL]: 'HC total',
      [TRANSLATIONS.DOWNLOAD_EXCEL]: 'Download Excel',
      [TRANSLATIONS.ALL_THE_TEAM]: 'All the team',
      [TRANSLATIONS.NEW_COLLABORATORS]: 'New collaborators',
      [TRANSLATIONS.OLD_COLLABORATORS]: 'Old collaborators',
      [TRANSLATIONS.DELAY_TASK]: 'Delay task',
      [TRANSLATIONS.IN_WAREHOUSE]: 'In warehouse',
      [TRANSLATIONS.IN_FACTORY]: 'In factory',
      [TRANSLATIONS.CONDITION_WAS_CLOSED_IMMEDIATELY]: 'The condition was closed immediately',
      [TRANSLATIONS.COULD_IT_BE_SIF]: 'Could it be a SIF?',
      [TRANSLATIONS.SIF_PRECURSOR]: 'SIF Precursor',
      [TRANSLATIONS.SIF_POTENTIAL]: 'SIF Potencial',
      [TRANSLATIONS.DEPARTMENT]: 'Department',
      [TRANSLATIONS.CHECKLISTS]: 'My checklists',
      [TRANSLATIONS.SEARCH_CHECKLIST]: 'Search Checklists',
      [TRANSLATIONS.LAST_WEEK]: 'En la última semana',
      [TRANSLATIONS.NEW_CHECKLIST]: 'New checklist',
      [TRANSLATIONS.CREATE_NEW_CHECKLIST]: 'New checklist',
      [TRANSLATIONS.TITLE]: 'Title',
      [TRANSLATIONS.TITLE_CHECKLIST]: 'Checklist title',
      [TRANSLATIONS.ENTER_TITLE]: 'Type question here',
      [TRANSLATIONS.FREQUENCY]: 'Frequency',
      [TRANSLATIONS.DAILY]: 'Daily',
      [TRANSLATIONS.ADD_SECTION]: 'Add section',
      [TRANSLATIONS.ENTER_DESCRIPTION]: 'Add description',
      [TRANSLATIONS.CHECKLIST_OPTIONS]: 'Checklist Options',
      [TRANSLATIONS.NUMBERS]: 'Integer numbers',
      [TRANSLATIONS.DECIMAL]: 'DECIMAL numbers',
      [TRANSLATIONS.ADD_ITEM]: 'Add question',
      [TRANSLATIONS.ADD_INSRUCTION]: 'Add instruction',
      [TRANSLATIONS.SECTION_DELETED]: 'Section deleted',
      [TRANSLATIONS.ITEM_DELETED]: 'Item deleted',
      [TRANSLATIONS.BIT_DELETED]: 'Bit deleted',
      [TRANSLATIONS.ADD_BIT]: 'Add bit',
      [TRANSLATIONS.TYPE_OF_BIT]: 'answer type',
      [TRANSLATIONS.CONFIGURE]: 'Configure',
      [TRANSLATIONS.IS_EXPANDED_DEFAULT]: 'Expanded by default',
      [TRANSLATIONS.IS_EXPANDED_DETAILS]: 'Enabling this will show the expanded section as soon as the checklist is started.',
      [TRANSLATIONS.SAP_SHARP]: '#SAP/SHARP',
      [TRANSLATIONS.CREATE_AN_ACCOUNT]: 'Create an account',
      [TRANSLATIONS.DONT_HAVE_AN_ACCOUNT]: "Don't have an account",
      [TRANSLATIONS.ALREADY_REGISTERED]: 'Already registered',
      [TRANSLATIONS.REGISTER_ON]: 'Register on',
      [TRANSLATIONS.TERMS_AND_CONDITIONS]: 'I have read and agree to the Terms & Conditions',
      [TRANSLATIONS.FOLLOW_THE_INSTRUCTIONS_RECEIVED]: 'Follow the instructions you have received in your email to create a password',
      [TRANSLATIONS.TOO_SHORT]: 'too short',
      [TRANSLATIONS.YOU_MUST_ACCEPT_TERMS_AND_CONDITIONS]: 'You must accept Terms & Conditions',
      [TRANSLATIONS.ENTER_A_CORRECT_EMAIL_FORMAT]: 'enter a correct email format',
      [TRANSLATIONS.OPERATION]: 'Operation',
      [TRANSLATIONS.CATEGORY]: 'Category',
      [TRANSLATIONS.PROCEDURE]: 'Procedure',
      [TRANSLATIONS.PRIORITY_T]: 'Priority',
      [TRANSLATIONS.LOGISTIC]: 'Logistics',
      [TRANSLATIONS.YOU_MUST_ENTER_YOUR_OPERATION_TYPE]: 'You must select an operation type',
      [TRANSLATIONS.DISTRIBUTION_CENTER]: 'Distribution center',
      [TRANSLATIONS.FACTORY]: 'Factory',
      [TRANSLATIONS.OPTIONS]: 'Options',
      [TRANSLATIONS.SECTION_OF_TOTAL]: 'Section {{current}} of {{total}}',
      [TRANSLATIONS.TITLE_SECTION]: 'Section title',
      [TRANSLATIONS.SOMETHING_SHORT]: '*We recommend something short',
      [TRANSLATIONS.QUESTION_OF_TOTAL]: 'Question {{current}} out of {{total}}',
      [TRANSLATIONS.ANSWER_OF_TOTAL]: 'Answer {{current}} out of {{total}}',
      [TRANSLATIONS.PLACEHOLDER_INFO]: "*It is shown when the input doesn't have anything typed in yet.",
      [TRANSLATIONS.ENTER_OPTION_HIT_ENTER]: 'Type option and press Enter',
      [TRANSLATIONS.SEND_PDF_WHEN_FINALIZED]: 'Send email with PDF when finished to:',
      [TRANSLATIONS.SWITCH_TO_DROPDOWN]: '*We recommend that you switch to Option Dropdown due to the number of options added.',
      [TRANSLATIONS.SWITCH_TO_VERTICAL]: '*We recommend that you switch to Option Vertical due to the number of options added.',
      [TRANSLATIONS.CHECKLIST_NAME]: 'Checklist name',
      [TRANSLATIONS.LAST_ACTIVITY]: 'Last activity',
      [TRANSLATIONS.CREATION_DATE]: 'Creation date',
      [TRANSLATIONS.FIRST_CHECKLIST]: 'Create your first checklist!',
      [TRANSLATIONS.START_FIRST_CHECKLIST]: 'To start press "New checklist".',
      [TRANSLATIONS.ADD_LOGIC]: 'Add logic',
      [TRANSLATIONS.QUIT]: 'Quit',
      [TRANSLATIONS.LOGIC]: 'Logic',
      [TRANSLATIONS.SHOW_IF]: 'If',
      [TRANSLATIONS.CHOOSE_QUESTION]: 'Choose question',
      [TRANSLATIONS.SECTION]: 'Section',
      [TRANSLATIONS.ANSWER]: 'Answer',
      [TRANSLATIONS.EQUALS]: 'Equals to',
      [TRANSLATIONS.ANSWERED]: 'Answered',
      [TRANSLATIONS.TYPE_OF_NUMBER]: 'type of number',
      [TRANSLATIONS.EXIT_WITHOUT_SAVING]: 'Exit without saving',
      [TRANSLATIONS.NESTED_CHECKLIST_ERROR]: 'Please Complete Nested Checklist!',
      [TRANSLATIONS.BACK]: 'Back',
      [TRANSLATIONS.SHOW_QUESTION]: 'Show question',
      [TRANSLATIONS.SEND_EMAIL]: 'Send email with response to',
      [TRANSLATIONS.TRIGGER_ACTION_LOG]: 'Trigger action log',
      [TRANSLATIONS.THEN]: 'Then',
      [TRANSLATIONS.COPY_QR_CODE]: 'Copy QR code',
      [TRANSLATIONS.AN_ERROR_HAS_OCCURRED]: 'An error has occurred.',
      [TRANSLATIONS.FILE_UPLOADED_SUCCESSFULLY]: 'File uploaded successfully.',
      [TRANSLATIONS.SUBAREA]: 'Subarea',
      [TRANSLATIONS.NOTE_UPLOADED_FILES_WILL_NOT_BE_REFLECTED_IMMEDIATELY_ONCE_YOU_UPLOAD_ALL_YOUR_FILES_PLEASE_REFRESH_THE_PAGE]: 'Note: Uploaded files will not be reflected immediately, once you upload all your files please refresh the page.',
      [TRANSLATIONS.YOUR_FILE_HAS_STARTED_TO_LOAD_IT_MAY_TAKE_A_FEW_MINUTES]: 'Your file has started to load, it may take a few minutes.',
      [TRANSLATIONS.USER_UPDATED_SUCCESSFULLY]: 'User updated successfully.',
      [TRANSLATIONS.UPDATED_USER_NOW_DOES_NOT_HAVE_ANY_ROLE]: 'The updated user now does not have any role, please assign a permission to the user in permissions tab.',
      [TRANSLATIONS.SEARCH_THE_USER_BY_QRCODE_TO_CONFIGURE]: 'Search the user by QR code to configure',
      [TRANSLATIONS.SELECT_LOCATION]: 'Select a location',
      [TRANSLATIONS.OPERATIONAL]: 'Operational',
      [TRANSLATIONS.CORPORATE]: 'Corporate',
      [TRANSLATIONS.DOWNLOAD_DATA]: 'Download data',
      [TRANSLATIONS.THE_DATA_FOR_THE_LOCATIONS_THAT_ARE_FILTERED_IN_THE_TOP_FILTER_BAR_WILL_BE_DOWNLOADED]: 'The data for the location(s) that are filtered in the top filter bar will be downloaded.',
      [TRANSLATIONS.SELECT_DOCUMENT]: 'Select document',
      [TRANSLATIONS.SELECT_THE_DOCUMENT_YOU_WANT_TO_DOWNLOAD]: 'Select the document you want to download',
      [TRANSLATIONS.DOWNLOADING_DATA]: 'Downloading data...',
      [TRANSLATIONS.CHOOSE_HERE]: 'Choose here',
      [TRANSLATIONS.WITHOUT_POSITION]: 'Without position',
      [TRANSLATIONS.RETRY_AGAIN]: 'Retry',
      [TRANSLATIONS.SOMETHING_WENT_WRONG]: 'Something went wrong',
      [TRANSLATIONS.DOWNLOAD_FAIL_RETRY_THE_DOWNLOAD_PLEASE]: 'Download failed. Please retry.',
      [TRANSLATIONS.THE_DATA_IS_TAKING_A_LONG_TIME_TO_DOWNLOAD_A_DOWNLOAD_LINK_WILL_BE_SENT_TO_YOUR_EMAIL_TO_CONTINUE]: 'The data is taking a long time to download, a download link will be sent to your email to continue.',
      [TRANSLATIONS.OTHERS]: 'Others',
      [TRANSLATIONS.SEND_EMAIL_WHEN_FINISHED]: 'Send email with PDF when finished to:',
      [TRANSLATIONS.TASK_DESCRIPTION]: 'Task description',
      [TRANSLATIONS.USERS_ESP]: 'Users',
      [TRANSLATIONS.SEARCH_QUESTION_TYPE]: 'Search answer type',
      [TRANSLATIONS.ATLEAST_TWO_OPTIONS]: 'Please add at least two options.',
      [TRANSLATIONS.DOWNLOAD_REPORT]: 'Download report',
      [TRANSLATIONS.LITE]: `● Mobile App Access
      ● Inspections: Fill inspections on your phone
      ● Routines: Assignment of inspections by position and by frequency.
      ● Reporting: Generation of action items for monitoring on the web dashboard.`,
      [TRANSLATIONS.BUSINESS]: `Everything in Lite, plus:
      ● Web task board management: Task tracking on the kanban board.
      ● Web analytics: relevant information on inspections, routines, usability and suggestions.
      ● Form builder: Customizable forms with logic and Actions.
      ● BI Connectors: Download information in csv, xlsx and connection with data analysis tool.`,
      [TRANSLATIONS.OUTLINES]: 'Outlines',
      [TRANSLATIONS.TOTAL_PAGES]: 'Total Pages',
      [TRANSLATIONS.PREVIOUS]: 'Previous',
      [TRANSLATIONS.NEXT]: 'Next',
      [TRANSLATIONS.TOTAL_CHECKLIST_REPORTS]: 'Total reports',
      [TRANSLATIONS.ADD_SECTION_DESCRIPTION]: 'Add section description',
      [TRANSLATIONS.ENABLE_REPEATITION]: 'Repeatable',
      [TRANSLATIONS.ADJUSTMENTS]: 'Adjustments',
      [TRANSLATIONS.ASSETS]: 'Assets',
      [TRANSLATIONS.LOGICAL_CONDITION_HIGHLIGHT]: 'It shows when answer to question "{{questionTitle}}" is "{{answer}}"',
      [TRANSLATIONS.LOGICAL_CONDITION_TEXT_WHEN_ANSWERED]: 'It shows when question "{{questionTitle}}" is answered',
      [TRANSLATIONS.WORK_ORDERS]: 'Work orders',
      [TRANSLATIONS.NEW_WORK_ORDER]: 'New job',
      [TRANSLATIONS.JOB_NAME]: 'Job name',
      [TRANSLATIONS.INSPECTIONS]: 'Inspections',
      [TRANSLATIONS.STEPS]: 'Steps',
      [TRANSLATIONS.THERE_ARE_NO_JOBS_YET]: 'There are no jobs yet',
      [TRANSLATIONS.PRESS_PLUS_NEW_JOB_TO_START]: 'Press ”+ New Job” to start.',
      [TRANSLATIONS.DELETE_WORK]: 'Delete Work',
      [TRANSLATIONS.THIS_WORK_WILL_BE_DELETED_EVERYWHERE_THIS_ACTION_CANNOT_BE_UNDONE]: 'This work will be deleted everywhere. This action cannot be undone.',
      [TRANSLATIONS.DELETE_PERMANENTLY]: 'Delete permanently',
      [TRANSLATIONS.DRILLS_CHECKLIST]: 'Drilling checklist',
      [TRANSLATIONS.YOU_WILL_NOT_BE_ABLE_TO_REVERSE_THE_ACTION_DO_YOU_WANT_TO_DELETE_THE_CHECKLIST]: 'You will not be able to reverse the action. Do you want to delete the checklist?',
      [TRANSLATIONS.YES_DELETE]: 'Yes, delete',
      [TRANSLATIONS.DELETE_INSTANCE]: 'Delete Instance',
      [TRANSLATIONS.REPORT_SUBMITTED_SUCCESSFULLY]: 'Report submitted successfully',
      [TRANSLATIONS.YOU_CAN_NOW_CLOSE_THIS_TAB]: 'You can now close this tab.',
      [TRANSLATIONS.ENABLE_PARAGRAPH]: 'Enable paragraph in response',
      [TRANSLATIONS.ENABLE_DECIMALS]: 'Enable decimal',
      [TRANSLATIONS.MY_ASSETS]: 'My assets',
      [TRANSLATIONS.ARCHIVED]: 'Archived',
      [TRANSLATIONS.ASSET_TYPES]: 'Asset Types',
      [TRANSLATIONS.ADD_ASSET]: 'Add asset',
      [TRANSLATIONS.FIND_ACTIVE]: 'Find asset',
      [TRANSLATIONS.THERE_ARE_NO_ASSETS_YET]: 'There are no assets yet',
      [TRANSLATIONS.PRESS_ADD_ASSET_TO_GET_STARTED]: 'Press ”Add asset” to get started.',
      [TRANSLATIONS.ADD_ASSET_TYPE]: 'Add asset type',
      [TRANSLATIONS.CHANGES_ARE_SAVED_AUTOMATICALLY]: 'Changes are saved automatically',
      [TRANSLATIONS.ENTER_ASSET_TYPE]: 'Enter asset type',
      [TRANSLATIONS.NEW_FIELD_DESCRIPTION]: "Give it a name and pick a type of answer. Once created you won't be able to edit the type of field.",
      [TRANSLATIONS.DELETE_FIELD_DESCRIPTION]: 'This field may be used in other types. Deleting it here will also delete it in every other type. This action cannot be undone.',
      [TRANSLATIONS.ANONYMOUS]: 'Anonymous',
      [TRANSLATIONS.NAME_OF_INSPECTION]: 'Name of inspection',
      [TRANSLATIONS.NAME_INSPECTION]: 'Inspection name',
      [TRANSLATIONS.TYPE]: 'Type',
      [TRANSLATIONS.DETAILS]: 'Details',
      [TRANSLATIONS.ADD_RIGHT_PANEL_FIELDS]: 'To add more fields for this type of asset press “+ Create new field”',
      [TRANSLATIONS.CREATE_NEW_FIELD]: 'Create new field',
      [TRANSLATIONS.NEW_FIELD]: 'New field',
      [TRANSLATIONS.CREATE]: 'Create',
      [TRANSLATIONS.RENAME_FIELD]: 'Rename field',
      [TRANSLATIONS.RENAME_DESCRIPTION]: 'It will be renamed everywhere it is being used.',
      [TRANSLATIONS.DELETE_FIELD]: 'Delete field',
      [TRANSLATIONS.INVITE_USERS]: 'Invite users',
      [TRANSLATIONS.CHECKLIST_SELECTION]: 'Checklist selection',
      [TRANSLATIONS.SELECT_WHICH_USERS_WILL_BE_ABLE_TO_PARTICIPATE_IN_THIS_WORK]: 'Select which users will be able to participate in this work',
      [TRANSLATIONS.ACTIVE]: 'Active',
      [TRANSLATIONS.FINALIZED]: 'Finalized',
      [TRANSLATIONS.TEXT]: 'Text',
      [TRANSLATIONS.DATE]: 'Date',
      [TRANSLATIONS.NUMBER]: 'Number',
      [TRANSLATIONS.INVITING_YOU_TO_ESPECTRO]: ' is inviting you to Espectro',
      [TRANSLATIONS.SHARE_PDF]: 'Share PDF',
      [TRANSLATIONS.THERE_ARE_NO_COMPLETED_JOBS_YET]: 'There are no completed jobs yet',
      [TRANSLATIONS.WAIT_FOR_THE_ASSIGNED_USERS_TO_FINISH_TO_VIEW_THEM_HERE]: 'Wait for the assigned users to finish to view them here',
      [TRANSLATIONS.CANCEL_JOB]: 'Cancel job',
      [TRANSLATIONS.TOTAL_QUESSTIONS]: 'Total questions',
      [TRANSLATIONS.PICK_A_DATE]: 'Pick a date',
      [TRANSLATIONS.CANCEL_WORK_ORDER]: 'Cancel Work Order',
      [TRANSLATIONS.CANCELLED]: 'Cancelled',
      [TRANSLATIONS.REASON_FOR_CANCELLATION]: 'Write reason for cancellation',
      [TRANSLATIONS.TYPE_EMAIL_AND_PRESS_ENTER]: 'Type email and press enter',
      [TRANSLATIONS.SEND_PDF_VIA_EMAIL]: 'Send PDF via email',
      [TRANSLATIONS.ADD_EMAILS_TO_SEND]: 'Add emails to send',
      [TRANSLATIONS.DUPLICATE]: 'Duplicate',
      [TRANSLATIONS.DELETE_CHECKLIST]: 'Delete checklist',
      [TRANSLATIONS.WILL_BE_DELETED_THIS_ACTION_CANNOT_BE_UNDONE]: 'will be deleted. This action can not be undone.',
      [TRANSLATIONS.IT_WILL_GET_DELETED_AS_SOON_AS_THE_FOLLOWING_WORK_ORDERS_ARE_FNISHED]: 'It will get deleted as soon as the following Work Orders are finished:',
      [TRANSLATIONS.YOU_WILL_STILL_SEE_THE_PDF_REPORTS_AFTER_DELETION]: "You'll still see the PDF reports after deletion.",
      [TRANSLATIONS.WAIT_FOR_WORK_ORDERS_TO_BE_CANCELED_TO_VIEW_THEM_HERE]: 'Wait for work orders to be canceled to view them here',
      [TRANSLATIONS.NO_JOBS_CANCELED_YET]: 'No jobs canceled yet',
      [TRANSLATIONS.SIGN_OFF]: 'Sign out',
      [TRANSLATIONS.ENTER_PLACEHOLDER_TEXT]: 'Add placeholder text',
      [TRANSLATIONS.UPLOAD_MEDIA_REFERENCE]: 'Upload media reference',
      [TRANSLATIONS.REPLACE_MEDIA_REFERENCE]: 'Replace media reference',
      [TRANSLATIONS.USER_MANUAL]: 'User manual',
      [TRANSLATIONS.LOOK_FOR_MANUAL]: 'Look for manual...',
      [TRANSLATIONS.HOW_TO_MAKE_AN_INSPECTION_CHECKLIST]: 'How to make an inspection/checklist.',
      [TRANSLATIONS.HOW_TO_CREATE_A_WORK_ORDER]: 'How to create a work order.',
      [TRANSLATIONS.KIND_OF_QUESTIONS]: 'Kind of questions.',
      [TRANSLATIONS.IS_SIGNED]: 'Is signed',
      [TRANSLATIONS.IS_UPLOADED]: 'Is uploaded',
      [TRANSLATIONS.IS_IMAGE_UPLOADED]: 'Is uploaded',
      [TRANSLATIONS.IS_NOT_SIGNED]: 'Is not signed',
      [TRANSLATIONS.IS_NOT_UPLOADED]: 'Is not uploaded',
      [TRANSLATIONS.NO_WO_NAME]: 'To save the work order you need to give it a name.',
      [TRANSLATIONS.ADD_AN_IMAGE_TO_SAVE]: 'Add an image to save.',
      [TRANSLATIONS.ADDED_REASON_FOR_CANCELLATION]: 'Reason for cancellation',
      [TRANSLATIONS.ENABLE_CAMERA_CAPTURE]: 'Enable camera capture only',
      [TRANSLATIONS.CREATE_WORK_ORDER]: 'Create work order',
      [TRANSLATIONS.CHECKLIST]: 'Checklist',
      [TRANSLATIONS.AVAILABLE]: 'available',
      [TRANSLATIONS.START_DATE]: 'Start Date',
      [TRANSLATIONS.END_DATE]: 'End Date',
      [TRANSLATIONS.PLEASE_SELECT_VALIDITY_FOR_WORK_ORDER]: 'Please select validity for Work order',
      [TRANSLATIONS.EDIT_WORK_ORDER]: 'Edit work order',
      [TRANSLATIONS.SEARCHED_USERS]: 'Searched Users',
      [TRANSLATIONS.UNAVAILABLE]: 'Unavailable',
      [TRANSLATIONS.SEARCH_OR_PRESS_ENTER_AFTER_TYPING_EMAIL]: 'Search or press ENTER after typing email',
      [TRANSLATIONS.SUPERVISORS]: 'Supervisors',
      [TRANSLATIONS.PROCESSING]: 'Processing',
      [TRANSLATIONS.AUTO_REMOVE_SIGNATURE]: 'Auto remove when questions tampered',
      [TRANSLATIONS.AUTO_HIDE_SIGNATURE]: 'Hide until section answered',
      [TRANSLATIONS.SURNAME]: 'Surname',
      [TRANSLATIONS.THIS_WILL_BE_PREFILLED_AS_DEFAULT_ANSWER]: 'This will be pre-filled as the default answer.',
      [TRANSLATIONS.SELECT_A_DEFAULT_OPTION]: 'Select a default option',
      [TRANSLATIONS.SET_MINIMUM_AND_MAXIMUM]: 'Add minimum and maximum values',
      [TRANSLATIONS.MINIMUM_VALUE]: 'Set minimum value',
      [TRANSLATIONS.MAXIMUM_VALUE]: 'Set maximum value',
      [TRANSLATIONS.MAXIMUM_VALUE_SHOULD_BE_GREATER_THAN_MINIMUM_VALUE]: 'Maximum value should be greater than min value',
      [TRANSLATIONS.MINIMUM_VALUE_SHOULD_BE_LESSER_THAN_MAXIMUM_VALUE]: 'Minimum value should be lesser than max value',
      [TRANSLATIONS.SHOW_INPUT_FOR_SIGNEE_NAME]: 'Show input field for signee\'s name',
      [TRANSLATIONS.REQUIRES_SIGNATURE]: 'Sign before submit',
      [TRANSLATIONS.SHOW_OTHER_AS_OPTION]: 'Show \'Other\' as option',
      [TRANSLATIONS.END_DATE_SHOULD_BE_A_LATER_DATE]: 'End date should be a later date',
      [TRANSLATIONS.VALUE_OUT_OF_RANGE]: 'Value out of range!',
      [TRANSLATIONS.VALUE_OUT_OF_RANGE_WISH_TO_CONTINUE]: 'This value is out of range. Do you wish to continue?',
      [TRANSLATIONS.MONTH]: 'Month',
      [TRANSLATIONS.TODAY]: 'Today',
      [TRANSLATIONS.REINVITE]: 'Reinvite',
      [TRANSLATIONS.FETCHING_REPORT]: 'Fetching report',
      [TRANSLATIONS.SELECTED_CHECKLISTS]: 'Selected Checklists',
    },
  },
};

function getParameterByName(name: any) {
  const url = window.location.href;
  // eslint-disable-next-line
  name = name.replace(/[\[\]]/g, '\\$&');
  const regex = new RegExp(`[?&]${name}(=([^&#]*)|&|#|$)`);
  const results = regex.exec(url);
  if (!results) return null;
  if (!results[2]) return '';
  return decodeURIComponent(results[2].replace(/\+/g, ' '));
}

i18next.use(initReactI18next).init({
  resources,
  lng: getParameterByName('lng') as string || navigator.language,
  // lng: navigator.language,
  fallbackLng: 'es',
  interpolation: {
    escapeValue: false,
  },
});

export default i18next;
