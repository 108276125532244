import React, { useState, useEffect, useRef, ChangeEvent, KeyboardEvent, useCallback } from 'react';
import './UserSearchAutocomplete.scss';
import { toast } from 'react-toastify';
import request from 'utils/request';
import useDebounce from 'utils/hooks/useDebounce';
import { useTranslation } from 'react-i18next';
import { TRANSLATIONS } from 'types/enums';
import { EMAIL_REGEX } from '../../../../constants';

interface Option {
  label: string;
  value: string;
}

type Props = {
  existingReceivers: string[];
  setReceivers: (val: string[]) => void;
};

const UserSearchAutocomplete = ({
  setReceivers,
  existingReceivers,
}: Props) => {
  const { t } = useTranslation();

  const [value, setValue] = useState<string>('');
  const [options, setOptions] = useState<Option[]>([]);
  const [isDropdownVisible, setDropdownVisible] = useState<boolean>(false);
  const [
    showHighlightValidationFailure,
    setShowHighlightValidationFailure,
  ] = useState<boolean>(false);

  const inputRef = useRef<HTMLDivElement>(null);

  const fetchUsers = useCallback(async (inputValue: string) => {
    if (inputValue) {
      const usersFetched = await request.get(`/api/v1/search-users-of-company/${inputValue}`);
      setOptions(usersFetched.users);
    } else {
      setOptions([]);
    }
  }, []);

  const fetchUsersDebounce = useDebounce(fetchUsers, 400);

  const handleInputChange = async (event: ChangeEvent<HTMLInputElement>) => {
    const inputValue = event.target.value;
    if (showHighlightValidationFailure) setShowHighlightValidationFailure(false);
    setValue(inputValue);
    fetchUsersDebounce(inputValue);
    setDropdownVisible(true);
  };

  const handleOptionClick = (option: Option) => {
    if (!existingReceivers.includes(option.label)) {
      setReceivers([...existingReceivers, option.label]);
    } else {
      toast.warning('Email already added');
    }
    setValue('');
    setDropdownVisible(false);
  };

  const handleKeyDown = (event: KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      event.preventDefault();
      setDropdownVisible(false);
      const emailValue = event.currentTarget.value?.trim();

      const validationTest = EMAIL_REGEX.test(emailValue);
      if (!validationTest) {
        setShowHighlightValidationFailure(true);
        return;
      }
      if (!existingReceivers.includes(emailValue)) {
        setReceivers([...existingReceivers, emailValue]);
      } else {
        toast.warning('Email already added');
      }
      setValue('');
    }
  };

  const handleClickOutside = (event: MouseEvent) => {
    if (inputRef.current && !inputRef.current.contains(event.target as Node)) {
      setDropdownVisible(false);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const onDelete = (index: number) => {
    if (index >= 0) {
      const itemList = [...existingReceivers];
      itemList.splice(index, 1);
      setReceivers([...itemList]);
    }
  };

  return (
    <div className="autocomplete-container" ref={inputRef}>
      <div className="autocomplete-root">
        <input
          id="autocomplete-input"
          className={showHighlightValidationFailure ? 'error-case-highlight' : 'autocomplete-input'}
          type="text"
          value={value}
          onChange={handleInputChange}
          onKeyDown={handleKeyDown}
          placeholder={t(TRANSLATIONS.SEARCH_OR_PRESS_ENTER_AFTER_TYPING_EMAIL)}
        />
        {isDropdownVisible && options.length > 0 && (
          <ul className="autocomplete-listbox">
            {options.map((option: Option) => (
              <li
                key={option.label}
                className="autocomplete-option"
                onClick={() => handleOptionClick(option)}
              >
                <p>{option.label}</p>
                <p>{option.value}</p>
              </li>
            ))}
          </ul>
        )}
      </div>

      <div className="pill-container">
        {existingReceivers.map((val, index) => (
          <div className="pill" key={val}>
            {val}
            <button
              type="button"
              className="cross-button"
              onClick={() => onDelete(index)}
            >
              &times;
            </button>
          </div>
        ))}
      </div>
    </div>
  );
};

export default UserSearchAutocomplete;
