import ImageLinks from 'utils/ImageLinks';

export enum ACTION_LOG_PILLAR_IMAGE {
  SAFETY = 'SAFETY',
  MANAGEMENT = 'MANAGEMENT',
  PLANNING = 'PLANNING',
  DISTRIBUTION = 'DISTRIBUTION',
  FLEET = 'FLEET',
  STOCK = 'STOCK',
  PEOPLE = 'PEOPLE',
  WAREHOUSE = 'WAREHOUSE',
}

export const pillarImages = {
  [ACTION_LOG_PILLAR_IMAGE.SAFETY]: ImageLinks.dpo.positiveShield,
  [ACTION_LOG_PILLAR_IMAGE.MANAGEMENT]: ImageLinks.dpo.management,
  [ACTION_LOG_PILLAR_IMAGE.PLANNING]: ImageLinks.dpo.planning,
  [ACTION_LOG_PILLAR_IMAGE.DISTRIBUTION]: ImageLinks.dpo.distribution,
  [ACTION_LOG_PILLAR_IMAGE.FLEET]: ImageLinks.dpo.fleet,
  [ACTION_LOG_PILLAR_IMAGE.STOCK]: ImageLinks.dpo.stock,
  [ACTION_LOG_PILLAR_IMAGE.PEOPLE]: ImageLinks.dpo.people,
  [ACTION_LOG_PILLAR_IMAGE.WAREHOUSE]: ImageLinks.warehouse,
};
