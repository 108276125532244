import React, { useState } from 'react';
import { useCubeQuery } from '@cubejs-client/react';
import './FactoryPlanningStyles.scss';
import rankingWhiteboard from 'assets/icons/ranking-whiteboard.svg';
import { format, getDaysInMonth, parseISO } from 'date-fns';
import { set, subDays } from 'date-fns/esm';
import { TRANSLATIONS } from 'types/enums';
import { useTranslation } from 'react-i18next';
import { lastDayOfMonth } from 'date-fns/esm/fp';
import ChartDropdown from 'components/Dropdown/ChartDropdown';
import { useSelector } from 'react-redux';
import { selectLocationsToAccess } from 'store/selectors/globalFilters.selectors';

export default function FactoryRanking() {
  const { t } = useTranslation();
  const locationsToAccess = useSelector(selectLocationsToAccess);

  const dropDownItems = [{
    slug: 'yesterday',
    label: 'Ayer',
//   }, {
//     slug: 'month-to-date',
//     label: t(TRANSLATIONS.MONTH_TO_DATE),
  // }, {
  //   slug: 'last-7-days',
  //   label: t(TRANSLATIONS.LAST_7_DAYS),
  // }, {
  //   slug: 'last-30-days',
  //   label: t(TRANSLATIONS.LAST_30_DAYS),
  }];

  const [dropDownOpen, setDropDownOpen] = useState(false);
  const [selectedDropDownItem, setSelectedDropDownItem] = useState(dropDownItems[0]);

  const getDateRange = () => {
    const currentDate = new Date();
    let filter = ['', ''] as any[];
    if (selectedDropDownItem.slug === 'yesterday') {
      filter = [subDays(currentDate, 2), subDays(currentDate, 1)];
    }
    if (selectedDropDownItem.slug === 'last-7-days') {
      filter = [subDays(currentDate, 7), currentDate];
    }
    if (selectedDropDownItem.slug === 'last-30-days') {
      filter = [subDays(currentDate, 30), currentDate];
    }
    if (selectedDropDownItem.slug === 'month-to-date') {
      const lastMonthDay = lastDayOfMonth(currentDate);
      const firstDayLastMonth = set(lastMonthDay, { date: 1 });
      filter = [firstDayLastMonth, lastMonthDay];
    }
    return [format(filter[0], 'yyyy-MM-dd'), format(filter[1], 'yyyy-MM-dd')];
  };

  const handleDropDownChange = ({ key }: any) => {
    setSelectedDropDownItem(dropDownItems.find(
      item => item.slug === key,
    ) || dropDownItems[0]);
    setDropDownOpen(false);
  };

  const dateRange = getDateRange() as unknown as string[];
  const { resultSet: totalProductions } = useCubeQuery({
    measures: [
      'DailyProductionPlannings.realSum',
      'DailyProductionPlannings.totalPlanned',
      'DailyProductionPlannings.totalPlannedFactory',
    ],
    timeDimensions: [{
      dimension: 'DailyProductionPlannings.day',
      dateRange,
    }],
    dimensions: ['Locations.externalid'],
    filters: [{
      dimension: 'DailyProductionPlannings.locationId',
      operator: 'equals',
      values: locationsToAccess,
    }],
  });

  const factoryPlannedSum = totalProductions?.series()[2]?.series as any;

  let values: any = [];
  if (totalProductions?.series()) {
    totalProductions?.series()[0]?.series.forEach((location, i) => {
      const factoryPlanned = factoryPlannedSum[i].value;
      const real = location.value;
      const adherence = (location.value / factoryPlanned) * 100;

      values.push({
        name: location.x,
        factoryPlanned,
        real,
        adherence,
      });
    });
  }

  values = values.sort((v: any, b: any) => b.adherence - v.adherence);

  return (
    <div className="factory-rankings-analytics">
      <div className="header-container">
        <div className="header-title">
          <img
            src={rankingWhiteboard}
            alt=""
            className="hc-icon"
          />
          <p className="text-bold overview-header">Ranking de plantas (ayer)</p>
        </div>
      </div>
      <div className="table-details table-row">
        <p style={{ textAlign: 'center' }}>Planta</p>
        <p>Vol. planeado (hl)</p>
        <p>Vol. real (hl)</p>
        <p>% adherence</p>
      </div>
      <div className="table">
        { values.map((location: any, i: any) => (
          <div className="table-row">
            <p>{i + 1}. { location.name }</p>
            <p>{ Math.round(location.factoryPlanned).toLocaleString() }</p>
            <p>{ Math.round(location.real).toLocaleString() }</p>
            <p>{ isFinite(location.adherence) ? Math.round(location.adherence.toFixed(1)) : '' }%</p>
          </div>
        ))}
      </div>
      <ChartDropdown
        onChange={handleDropDownChange}
        selectedDropDownItem={selectedDropDownItem}
        dropDownItems={dropDownItems}
        dropDownOpen={dropDownOpen}
        setDropDownOpen={setDropDownOpen}
        labelClassName="label-default"
      />
    </div>
  );
}
