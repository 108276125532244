import { TRANSLATIONS, UserTypes } from 'types/enums';
import gauzeIcon from 'assets/icons/gauge-gray.svg';
import gauzeGreenIcon from 'assets/icons/gauge-green.svg';
import homeIcon from 'assets/icons/home-gray.svg';
import homeGreenIcon from 'assets/icons/home-green.svg';
import searchicon from 'assets/icons/search-icon.png';
import executedBeer from 'assets/icons/beer.png';
import analyticsGreenIcon from 'assets/icons/analytics-green.svg';
import analyticsIcon from 'assets/icons/analytics-gray.svg';
import ACIcon from 'assets/icons/ac.svg';
import safetyAcademyIcon from 'assets/icons/academy-icon.svg';
import safetyAcademyIconGreen from 'assets/icons/academy-icon-green.svg';
import boardIconGreen from 'assets/icons/board-green.svg';
import workOrderIcon from 'assets/icons/file-good.svg';
import workOrderInActiveIcon from 'assets/icons/work-ordder-inactive.svg';
import boardIcon from 'assets/icons/board-grey.png';
import fileUserIcon from 'assets/icons/file-user.svg';
import owdIcon from 'assets/icons/owd.svg';
import owdIconGreen from 'assets/icons/owd-green.svg';
import combinedShape from 'assets/icons/espectro/combinedShape.png';
import combinedShapeGrey from 'assets/icons/espectro/combinedShapeGrey.png';
import { RoutesForNavigationDrawerWithVerticalMenu } from 'types/interfaces';
import { isUserBreweryPlantManager, userCanReportSIOs } from 'utils/canUserAccessFeature';
import { isEspectro } from '../constants';
import ImageLinks from './ImageLinks';

const defaultRoutes = (
  t: any, userType: any, location: any, userDetails?: any,
) => {
  const companyId = userDetails?.toJS()?.company?.id;

  const routes: RoutesForNavigationDrawerWithVerticalMenu[] = [];
  if (isEspectro) {
    const espectroRoutes = [{
      listItemText: t(TRANSLATIONS.WORK_ORDERS),
      routeName: '/espectro/work-orders',
      listItemIcon: workOrderInActiveIcon,
      listItemIconActive: workOrderIcon,
    },
    {
      listItemText: t(TRANSLATIONS.CHECKLISTS),
      routeName: '/espectro/checklist-builder',
      listItemIcon: boardIcon,
      listItemIconActive: boardIconGreen,
    },
    companyId !== 21 ? {
      listItemText: t(TRANSLATIONS.MY_ASSETS),
      routeName: '/espectro/assets-dashboard',
      listItemIcon: combinedShapeGrey,
      listItemIconActive: combinedShape,
    } : undefined,
    {
      listItemText: t(TRANSLATIONS.ANALYTICS),
      routeName: '/analytics/routines',
      listItemIcon: gauzeIcon,
      listItemIconActive: gauzeGreenIcon,
    },
    {
      listItemText: t(TRANSLATIONS.USERS),
      routeName: '/all-users',
      listItemIcon: ImageLinks.espectro.users,
      listItemIconActive: ImageLinks.espectro.usersActive,
    },
    {
      listItemText: t(TRANSLATIONS.ACTION_LOGS),
      routeName: '/action-log/board',
      listItemIcon: boardIcon,
      listItemIconActive: boardIconGreen,
    },
    {
      listItemText: t(TRANSLATIONS.REPORTS),
      routeName: '/espectro/download-report',
      listItemIcon: fileUserIcon,
      listItemIconActive: boardIconGreen,
    },
    ];
    return espectroRoutes.filter(r => !!r) as any;
  }
  const isSupply = userType === 'SubzoneGeneralManagerSupply' || userType === 'DistributionCenterManagerSupply' || userType === 'CorporateSupply';

  if (userType === 'BreweryViewer' || userType === 'BreweryViewerManager' || isUserBreweryPlantManager(userDetails?.getIn(['roleDetails', 'slug']), userType)) {
    routes.push(
      {
        listItemText: t(TRANSLATIONS.OVERVIEW),
        routeName: '/analytics/production-planning',
        listItemIcon: gauzeIcon,
        listItemIconActive: gauzeGreenIcon,
      },
      {
        listItemText: 'Agregar rutina',
        routeName: '/upload/daily-routine',
        listItemIcon: executedBeer,
        listItemIconActive: executedBeer,
      },
      {
        listItemText: 'Safety Academy',
        routeName: '/safety-academy',
        listItemIcon: safetyAcademyIcon,
        listItemIconActive: safetyAcademyIcon,
      },
    );
    return routes;
  }
  const analyticsSubRoutes = [];
  analyticsSubRoutes.push({
    listItemText: t(TRANSLATIONS.ACIS),
    routeName: '/analytics/incidents',
  });
  if (!isSupply) {
    analyticsSubRoutes.push({
      listItemText: t(TRANSLATIONS.ROUTINES),
      routeName: '/analytics/routines',
    });
    analyticsSubRoutes.push({
      listItemText: 'Cashless',
      routeName: '/analytics/cashless',
    });
    analyticsSubRoutes.push({
      listItemText: '5S',
      routeName: '/analytics/5S',
    });
    analyticsSubRoutes.push({
      listItemText: t(TRANSLATIONS.SIF_ROUTE),
      routeName: '/analytics/sif-route',
    });
    analyticsSubRoutes.push({
      listItemText: t(TRANSLATIONS.SIF_COMPLANICE),
      routeName: '/analytics/sif-complaince',
    });
    analyticsSubRoutes.push({
      listItemText: t(TRANSLATIONS.ACCIDENTABILITY),
      routeName: '/analytics/accidentability',
    });
    analyticsSubRoutes.push({
      listItemText: t(TRANSLATIONS.SIF_STATISTICS),
      routeName: '/analytics/sif-statistics',
    });
    analyticsSubRoutes.push({
      listItemText: t(TRANSLATIONS.CONSEQUENCES),
      routeName: '/analytics/consequences',
    });
    analyticsSubRoutes.push({
      listItemText: t(TRANSLATIONS.SAFETY_TRAINING),
      routeName: '/analytics/safety-training',
    });
    analyticsSubRoutes.push({
      listItemText: 'Capex',
      routeName: '/analytics/capex',
    });
    analyticsSubRoutes.push({
      listItemText: t(TRANSLATIONS.VIOLENCE_PREVENTION),
      routeName: '/analytics/violence-prevention',
    });
  }
  if (
    userType === UserTypes.DistributionCenterManagerDPO
      || userType === UserTypes.SubzoneGeneralManagerDPO
        || userType === UserTypes.EspectroViewOnly
          || userType === UserTypes.CorporateDPO
  ) {
    analyticsSubRoutes.push({
      listItemText: 'GKPI',
      routeName: '/analytics/GKPI',
    });
    analyticsSubRoutes.push({
      listItemText: t(TRANSLATIONS.SKAP),
      routeName: '/analytics/skap',
    });
  }
  if (!isSupply) {
    analyticsSubRoutes.push({
      listItemText: 'DMS',
      routeName: '/analytics/driver-management-system',
    });
  }

  routes.push(
    {
      listItemText: t(TRANSLATIONS.OVERVIEW),
      routeName: '/analytics/overview',
      listItemIcon: gauzeIcon,
      listItemIconActive: gauzeGreenIcon,
    },
    {
      listItemText: 'Analytics',
      routeName: '/analytics',
      listItemIcon: analyticsIcon,
      listItemIconActive: analyticsGreenIcon,
      collapseList:
        location?.get('slug') === 'newlands-brewery'
          ? [
            {
              listItemText: t(TRANSLATIONS.INCIDENTS),
              routeName: '/analytics/incidents',
            },
          ]
          : analyticsSubRoutes,
    },
    {
      listItemText: t(TRANSLATIONS.SEARCH_USERS),
      routeName: '/all-users',
      listItemIcon: searchicon,
      listItemIconActive: searchicon,
    },
    {
      listItemText: t(TRANSLATIONS.DIST_CENTERS),
      routeName: '/distribution-centers',
      listItemIcon: homeIcon,
      listItemIconActive: homeGreenIcon,
    },
  );

  if (!isSupply) {
    routes.push({
      listItemText: 'Safety Academy',
      routeName: '/safety-academy',
      listItemIcon: safetyAcademyIcon,
      listItemIconActive: safetyAcademyIconGreen,
    });
  }

  if (
    userType === UserTypes.DistributionCenterManagerDPO
    || userType === UserTypes.SubzoneGeneralManagerDPO
      || userType === UserTypes.EspectroViewOnly
        || userType === UserTypes.CorporateDPO
  ) {
    routes.push({
      listItemText: t(TRANSLATIONS.BOARDS),
      routeName: '/action-log/board',
      listItemIcon: boardIcon,
      listItemIconActive: boardIconGreen,
    });
  }

  routes.push({
    listItemText: t(TRANSLATIONS.CHECKLISTS),
    routeName: '/espectro/checklist-builder',
    listItemIcon: boardIcon,
    listItemIconActive: boardIconGreen,
  });
  if (userCanReportSIOs(userDetails?.getIn(['roleDetails', 'slug']), userDetails?.get('features'))) {
    routes.push(
      {
        listItemText: `${t(TRANSLATIONS.REPORT)} ${t(TRANSLATIONS.INCIDENTS)}`,
        routeName: '/ac',
        listItemIcon: ACIcon,
        listItemIconActive: analyticsGreenIcon,
      },
    );
  }

  routes.push({
    listItemText: t(TRANSLATIONS.OWD),
    routeName: '/owd',
    listItemIcon: owdIcon,
    listItemIconActive: owdIconGreen,
  });

  if (userDetails?.get('email').includes('arvolution')) {
    routes.push({
      listItemText: t(TRANSLATIONS.PDT),
      routeName: '/pdt',
      listItemIcon: owdIcon,
      listItemIconActive: owdIconGreen,
    });
  }
  analyticsSubRoutes.push({
    listItemText: t(TRANSLATIONS.OWD),
    routeName: '/analytics/owd',
  });

  return routes;
};

// TODO: Build a User-Access framework and use across web-dashboard.

export default defaultRoutes;
