import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { EditorState, convertToRaw } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import draftToHtml from 'draftjs-to-html';
import { format } from 'date-fns';
import es from 'date-fns/locale/es';
import { toast, ToastOptions } from 'react-toastify';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import 'react-toastify/dist/ReactToastify.css';
// @ts-ignore
import DatePicker, { registerLocale } from 'react-datepicker';

import deleteIcon from 'assets/icons/delete.svg';
import backArrow from 'assets/icons/arrow-left.svg';
import gallery from 'assets/icons/gallery.svg';
import roundCheck from 'assets/icons/round-check.svg';
import pendingCheck from 'assets/icons/pending-check.svg';
import file from 'assets/icons/file.svg';
import video from 'assets/icons/video.svg';
import { ReactComponent as UploadIcon } from 'assets/icons/icon-upload.svg';

import Header from 'components/Header';
import CheckBoxWithIcon from 'components/CheckBoxWithIcon';
import PressurableButton from 'components/Button/PressedButtonWithImage/PressurableButton';

import usePrevious from 'utils/hooks/usePrevious';
import request from 'utils/request';

import { POSTS_TYPES, TRANSLATIONS } from 'types/enums';

import './PostUpload.scss';

registerLocale('es', es);

const PostUpload = () => {
  registerLocale('es', es);
  const history = useHistory();
  const { t } = useTranslation();

  const postTypeOptions = [
    {
      label: 'Noticias Generales',
      value: POSTS_TYPES.NEWS,
    }, {
      label: 'Campaña Preventiva',
      value: POSTS_TYPES.PREVENTION_CAMPAIGNS,
    }, {
      label: 'Buena Practica',
      value: POSTS_TYPES.BEST_PRACTICES,
    },
  ];

  const [selectedOption, setSelectedOption] = useState<any>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState('');
  const [editorState, setEditorState] = useState<EditorState>(EditorState.createEmpty());
  const [title, setTitle] = useState('');
  const [subTitle, setSubTitle] = useState('');
  const [bannerImg, setBannerImg] = useState('');
  const [author, setAuthor] = useState('');
  const [isPickerOpen, setIsPickerOpen] = useState(false);
  const [date, setDate] = useState<Date | number>(new Date());
  const [attachedFiles, setAttachedFiles] = useState<any>([]);
  const [totalSize, setTotalSize] = useState(0);

  const fieldsList = [{
    label: 'Título',
    required: true,
    selected: !!title,
  }, {
    label: 'Subtitle',
    required: false,
    selected: !!subTitle,
  }, {
    label: 'Fotografía de portada',
    required: false,
    selected: !!bannerImg,
  }, {
    label: 'Autor',
    required: true,
    selected: !!author,
  }, {
    label: 'Fecha',
    required: true,
    selected: !!date,
  }, {
    label: 'Descripción',
    required: true,
    selected: draftToHtml(convertToRaw(editorState.getCurrentContent())).trim() !== '<p></p>',
  }, {
    label: 'Archivos adjuntos',
    required: false,
    selected: attachedFiles?.length > 0,
  }];

  const handleSelectOption = (value: POSTS_TYPES) => {
    setSelectedOption(postTypeOptions.find(option => option.value === value));
  };

  const handleDateSelect = () => {
    setIsPickerOpen(false);
  };

  const handleBannerUpload = (e: any) => {
    setBannerImg(e.target.files[0]);
  };

  const handleAttachmentsUpload = (e: any) => {
    const { files } = e.target;
    if (files?.length) {
      setAttachedFiles((prev: any) => [...prev, ...files]);
    }
  };

  useEffect(() => {
    let total = 0;
    total = attachedFiles.map((f: any) => {
      total += f.size;
      return total;
    });
    // @ts-ignore
    setTotalSize(total.length > 0 ? (total[total.length - 1] / 1000000) : 0);
  }, [attachedFiles]);

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const handleSuccess = (response: string) => {
    setAttachedFiles([]);
    setAuthor('');
    setBannerImg('');
    setTitle('');
    setSubTitle('');
    setEditorState(EditorState.createEmpty());
    toast.success('Post creado correctamente');
  };

  const contentInJs = draftToHtml(convertToRaw(editorState.getCurrentContent()));

  const canSubmit = (title && title.trim()) && (author && author.trim()) && date && contentInJs.trim() !== '<p></p>';

  const getFileIcon = (type: string) => {
    let icon = gallery;
    if (type.includes('video')) {
      icon = video;
    } else if (type.includes('application')) {
      icon = file;
    }

    return icon;
  };

  const toolbarOptions = {
    options: ['inline', 'blockType', 'fontSize', 'list', 'textAlign', 'history', 'embedded', 'emoji', 'image'],
    blockType: {
      inDropdown: true,
      options: ['Normal', 'H1', 'H2', 'H3', 'H4', 'H5', 'H6', 'Blockquote', 'Code'],
      className: 'toolbarOptions',
      component: undefined,
      dropdownClassName: undefined,
    },
    inline: { inDropdown: true },
    list: { inDropdown: true },
    textAlign: { inDropdown: true },
    link: { inDropdown: true },
    history: { inDropdown: true },
  };

  const toastConfig = {
    position: 'top-right',
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
  } as ToastOptions;

  const prevLoading = usePrevious(loading);
  if (loading && prevLoading !== loading) {
    toast.info(t(TRANSLATIONS.LOADING), toastConfig);
  }

  const prevError = usePrevious(error);
  if (error && prevError !== error) {
    toast.error(`Ha ocurrido un error, vuelva a intentarlo. ${error}`, toastConfig);
  }

  const handleSubmit = async (event: any) => {
    event.preventDefault();
    const data = new FormData(event.target);

    data.set('type', selectedOption.value);
    data.set('bannerImage', bannerImg);
    data.set('content', contentInJs);
    data.set('postedAt', `${date}`);

    const config = {
      headers: { 'content-type': 'multipart/form-data' },
    };

    try {
      setLoading(true);
      const response = await request.post('/api/v1/create-post', data, config);
      console.log(response);
      handleSuccess(response);
    } catch (err) {
      setError(err);
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <Header hideFilters />
      <div className="post-upload">
        <div className="post-upload__side-menu">
          <button type="button" onClick={() => history.push('/')}>
            <img src={backArrow} alt="back to home" /> Inicio
          </button>
        </div>
        <form className="post-upload__right" onSubmit={handleSubmit}>
          <div
            className="post-upload__right__go-back"
            onClick={() => history.goBack()}
            role="presentation"
          >
            <img src={backArrow} alt="back" />{t(TRANSLATIONS.BACK_TO_SEARCH_BAR)}
          </div>
          <div className="post-upload__right__first-step">
            <h1>Safety Academy</h1>
            <p>¿Qué tipo de material deseas subir?</p>
            <div className="post-upload__right__first-step__material-type">
              {postTypeOptions.map(({ label, value }) => (
                <PressurableButton
                  label={label}
                  value={value}
                  onClick={() => handleSelectOption(value)}
                  pressed={selectedOption?.value === value}
                  key={value}
                />
              ))}
            </div>
            {selectedOption && (
              <div className="post-upload__right__second-step">
                <div className="post-upload__right__second-step__left">
                  <input
                    className="post-upload__right__second-step__left__title"
                    placeholder="Agregar título..."
                    value={title}
                    onChange={(e: any) => (setTitle(e.target.value))}
                    name="title"
                  />
                  <input
                    className="post-upload__right__second-step__left__subtitle"
                    placeholder="Agregar subtítulo..."
                    value={subTitle}
                    onChange={(e: any) => (setSubTitle(e.target.value))}
                    name="subtitle"
                  />
                  <div className="post-upload__right__second-step__left__banner-dropzone">
                    {bannerImg ? (
                      <>
                        <img className="post__upload-fit-image " src={URL.createObjectURL(bannerImg)} height="340px" width="auto" alt="banner" />
                        <button
                          onClick={() => setBannerImg('')}
                          type="button"
                          className="post-upload__right__second-step__left__attached-files__upload-files__remove-btn"
                        >
                          <img src={deleteIcon} alt="delete file" width={30} height={30} />
                        </button>
                      </>
                    ) : (
                      <div>
                        <img src={gallery} alt="upload banner" />
                        <span>Agregar fotografia de portada</span>
                        <input
                          type="file"
                          onChange={handleBannerUpload}
                          accept="image/png, image/gif, image/jpeg, image/jpg"
                          name="bannerImage"
                        />
                      </div>
                    )}
                  </div>
                  <div className="post-upload__right__second-step__left__author-date">
                    <input
                      placeholder="Agregar autor..."
                      value={author}
                      onChange={(e: any) => (setAuthor(e.target.value))}
                      name="author"
                    /> |
                    {isPickerOpen ? (
                      <DatePicker
                        selected={date}
                        onChange={setDate}
                        onSelect={handleDateSelect}
                        locale={es}
                        inline
                        maxDate={new Date()}
                      />
                    ) : (
                      <button onClick={() => setIsPickerOpen(true)} type="button">
                        {format(date, 'dd MMMM yyyy')}
                      </button>
                    )}
                  </div>
                  <Editor
                    editorState={editorState}
                    toolbarClassName="toolbarClassName"
                    wrapperClassName="wrapperClassName"
                    editorClassName="editorClassName"
                    onEditorStateChange={setEditorState}
                    toolbar={toolbarOptions}
                  />
                  <div className="post-upload__right__second-step__left__attached-files">
                    <span>archivos adjuntos</span>
                    <span className="__mandatory-fields-post-upload-open __mandatory-fields-post-upload-open">
                      *Sólo se aceptan imágenes, videos y pdfs  <br />
                      *Máximo tamaño permitido: 10 MB (total)
                    </span>
                    <div className="post-upload__right__second-step__left__attached-files__list">
                      {attachedFiles?.length > 0 && attachedFiles?.map(
                        (attachedFile: any, index: number) => (
                          <div
                            className="post-upload__right__second-step__left__attached-files__upload-files"
                            key={index}
                          >
                            <img
                              src={
                                attachedFile.type.includes('image')
                                  ? URL.createObjectURL(attachedFile)
                                  : getFileIcon(attachedFile.type)
                              }
                              alt="attachment"
                            />
                            {!attachedFile.type.includes('image') && (<p>{attachedFile.name}</p>)}
                            <button
                              onClick={() => setAttachedFiles(
                                attachedFiles.filter(
                                  (item: any) => item.name !== attachedFile.name,
                                ),
                              )}
                              type="button"
                              className="post-upload__right__second-step__left__attached-files__upload-files__remove-btn"
                            >
                              <img src={deleteIcon} alt="delete file" width={30} height={30} />
                            </button>
                          </div>
                        ))}
                      {totalSize < 10
                        ? (
                          <button
                            className="post-upload__right__second-step__left__attached-files__upload-files"
                            type="button"
                          >
                            <p>+ Agregar archivo</p>
                            <input
                              type="file"
                              onChange={handleAttachmentsUpload}
                              name="attachments"
                              multiple
                              accept="application/pdf, image/*, video/*"
                            />
                          </button>
                        ) : (
                          <p style={{ color: 'red' }} className="__mandatory-fields-post-upload-open __mandatory-fields-post-upload-open">
                            Tamaño máximo superado. Por favor, elimina archivos para poder continuar
                          </p>
                        )}
                    </div>
                    <p className="__mandatory-fields-post-upload-open __mandatory-fields-post-upload-open">
                      Tamaño total: {parseFloat(totalSize.toString()).toFixed(2)} MB
                    </p>
                  </div>
                  <button
                    className="post-upload__right__second-step__left__submit-button"
                    type="submit"
                    disabled={!canSubmit || totalSize > 10}
                  >
                    <UploadIcon />
                    Publicar Material
                  </button>
                </div>
                <div className="post-upload__right__second-step__right">
                  <p className="post-upload__right__second-step__right__disclaimer">
                    Si dejas campos vacíos, no se mostrarán en la publicación final.
                  </p>
                  <div>
                    <span className="post-upload__right__second-step__right__mandatory-fields">
                      *Campos obligatorios
                    </span>
                    <ul>
                      {fieldsList?.map(({ label, required, selected }) => (
                        <CheckBoxWithIcon
                          label={label}
                          selectedIcon={roundCheck}
                          deSelectedIcon={pendingCheck}
                          selected={selected}
                          required={required}
                          key={label}
                        />
                      ))}
                    </ul>
                  </div>
                </div>
              </div>
            )}
          </div>
        </form>
      </div>
    </>
  );
};

export default PostUpload;
