import React, { useState } from 'react';
import ChartDropdown from 'components/Dropdown/ChartDropdown/ChartDropdown';

const UserTypeDropdown = (props: any) => {
  const { userType, usersTypesArray, onChange, isLoading } = props;
  const [dropDownOpenType, setDropDownOpenType] = useState(false);

  const [selectedDropDownItemType, setSelectedDropDownItemType] = useState(
    usersTypesArray.find((item: any) => item.slug === userType),
  );

  const handleValueChange = (value: any) => {
    setDropDownOpenType(false);
    const newSelectedUser = usersTypesArray.find((item: any) => item.label === value);
    setSelectedDropDownItemType(newSelectedUser);
    onChange(value);
  };

  return (
    <ChartDropdown
      onChange={({ key }: any) => handleValueChange(key)}
      selectedDropDownItem={selectedDropDownItemType}
      dropDownItems={usersTypesArray}
      dropDownOpen={dropDownOpenType}
      setDropDownOpen={setDropDownOpenType}
      labelClassName="label-default"
      isLoading={isLoading}
    />
  );
};

export default UserTypeDropdown;
