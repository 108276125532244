import React, { useState, useCallback } from 'react';
import Iframe from 'components/Iframe';
import { TRANSLATIONS } from 'types/enums';
import { useTranslation } from 'react-i18next';
import ChartTabs from 'components/Tabs/ChartTabs';
import axios from 'axios';
import Header from 'components/Header';
import { useSelector } from 'react-redux';
import { selectLocationsToAccess } from 'store/selectors/globalFilters.selectors';
import Modal from 'components/Modal';
import BasicButton from 'components/Button/BasicButton';
import ProgressBar from 'components/ProgressBar';
import './OWD.scss';

const OWDPowerBi = () => {
  const { t } = useTranslation();
  const [showDownloadModal, setShowDownloadModal] = useState<boolean>(false);
  const [showProgressBar, setShowProgressBar] = useState<boolean>(false);
  const [progressPercentage, setProgressPercentage] = useState<number>(0);
  const [downloadError, setDownloadError] = useState<boolean>(false);
  const [sentViaEmail, setSentViaEmail] = useState<boolean>(false);

  const locations = useSelector(selectLocationsToAccess);

  const tabs = [
    { title: t(TRANSLATIONS.OPERATIONAL), value: '', type: TRANSLATIONS.OPERATIONAL, index: 0 },
    { title: t(TRANSLATIONS.CORPORATE), value: '', type: TRANSLATIONS.CORPORATE, index: 1 },
  ];
  const [selectedTab, setSelectedTab] = useState(tabs[0]);

  const closeModal = () => {
    setSentViaEmail(false);
    setDownloadError(false);
    setShowDownloadModal(false);
    setShowProgressBar(false);
    setProgressPercentage(0);
  };

  const onClickDownload = () => {
    setShowDownloadModal(true);
  };

  const downloadOwdExcel = useCallback(
    async () => {
      try {
        setSentViaEmail(false);
        setDownloadError(false);
        setShowDownloadModal(true);
        setShowProgressBar(true);
        const url = `/api/v1/owd/excel?locationIds=${JSON.stringify(locations)}`;
        const res = await axios({
          url,
          responseType: 'blob',
          method: 'GET',
          onDownloadProgress: (progressEvent: any) => {
            const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
            setProgressPercentage(percentCompleted);
          },
        });
        const isJsonBlob = (resData: any) => resData instanceof Blob && resData.type === 'application/json';
        const responseData = isJsonBlob(res?.data) ? await (res?.data)?.text() : res?.data || {};
        const responseJson = (typeof responseData === 'string') ? JSON.parse(responseData) : responseData;
        if (responseJson?.sentViaEmail) {
          return setSentViaEmail(true);
        }
        const downloadUrl = URL.createObjectURL(new Blob([responseData], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' }));
        const a = document.createElement('a');
        a.href = downloadUrl;
        setShowDownloadModal(false);
        setShowProgressBar(false);
        setProgressPercentage(0);
        a.click();
      } catch (error) {
        setDownloadError(true);
        setProgressPercentage(0);
      }
    }, [locations],
  );

  return (
    <div>
      {showDownloadModal && (
      <Modal
        modalContainerStyles={{ width: '70vw',
          left: '20%',
          maxHeight: '90vh',
          minHeight: '20vh',
          height: 'fit-content',
          display: 'flex',
          flexDirection: 'column',
          position: 'absolute',
          top: '50%',
          transform: 'translateY(-50%)' }}
        onOverlayClick={closeModal}
      >
        <div className="downloadMoal">
          <p className="downloadMoal__title">{downloadError ? `${t(TRANSLATIONS.SOMETHING_WENT_WRONG)}...` : t(TRANSLATIONS.DOWNLOAD_DATA)}</p>
          {showProgressBar ? (
            <>
              <p className="downloadMoal__downloading_title">{downloadError ? t(TRANSLATIONS.DOWNLOAD_FAIL_RETRY_THE_DOWNLOAD_PLEASE) : sentViaEmail ? t(TRANSLATIONS.THE_DATA_IS_TAKING_A_LONG_TIME_TO_DOWNLOAD_A_DOWNLOAD_LINK_WILL_BE_SENT_TO_YOUR_EMAIL_TO_CONTINUE) : t(TRANSLATIONS.DOWNLOADING_DATA)}</p>
              {!downloadError && !sentViaEmail && (
              <div className="downloadMoal__progress_bar">
                <p className="downloadMoal__progress_bar__title">{progressPercentage}%</p>
                <ProgressBar bgcolor="#4472C4" height={16} progress={progressPercentage} />
              </div>
              )}
            </>
          ) : (
            <>
              <p className="downloadMoal__description">{t(TRANSLATIONS.THE_DATA_FOR_THE_LOCATIONS_THAT_ARE_FILTERED_IN_THE_TOP_FILTER_BAR_WILL_BE_DOWNLOADED)}</p>
              <div className="downloadMoal__header">
                <Header showLogo={false} showSettings={false} />
              </div>
            </>
          )}
          <div className="downloadMoal__button_container">
            {!sentViaEmail && (
              downloadError ? (
                <BasicButton text={t(TRANSLATIONS.RETRY_AGAIN)} className="downloadMoal__button_container__download_button" onClick={downloadOwdExcel} />
              ) : (
                <BasicButton disabled={showProgressBar} text={t(TRANSLATIONS.DOWNLOAD)} className="downloadMoal__button_container__download_button" onClick={downloadOwdExcel} />
              )
            )}
            <BasicButton text={t(TRANSLATIONS.CANCEL)} className="downloadMoal__button_container__cancel_button" onClick={closeModal} />
          </div>
        </div>
      </Modal>
      )}
      <div className="chartHeader">
        <h4 className="chartHeader__title">OWDs</h4>
        <BasicButton text={t(TRANSLATIONS.DOWNLOAD_DATA)} className="chartHeader__download_button" onClick={onClickDownload} />
      </div>
      <ChartTabs
        tabsItems={tabs}
        selectedTab={selectedTab}
        setSelectedTab={setSelectedTab as any}
        selectedTabStyle="selected-tab"
        customStyle="tabs-container"
      />
      {selectedTab.index === 0 && (
        <Iframe
          iframe="https://app.powerbi.com/reportEmbed?reportId=0ff31fc3-f5fc-435a-89fb-ed3797999cda&autoAuth=true&ctid=cef04b19-7776-4a94-b89b-375c77a8f936"
          iframeTitle="OWDs"
          pageName="ReportSection"
        />
      )}
      {selectedTab.index === 1 && (
        <Iframe
          iframe="https://app.powerbi.com/reportEmbed?reportId=0ff31fc3-f5fc-435a-89fb-ed3797999cda&autoAuth=true&ctid=cef04b19-7776-4a94-b89b-375c77a8f936"
          iframeTitle="OWDs"
          pageName="ReportSectionb39b5c9bbb7fdb222570"
        />
      )}
    </div>
  );
};

export default OWDPowerBi;
