import React, { useEffect, useState } from 'react';
import Chart from 'react-apexcharts';
import {
  differenceInCalendarDays,
  format,
  parseISO,
  startOfDay,
  startOfMonth,
  subDays,
} from 'date-fns';
import { useTranslation } from 'react-i18next';
import { SelectInfo } from 'rc-menu/es/interface';
import { fromJS } from 'immutable';
import userIcon from '../../../assets/icons/user-light.svg';
import request from '../../../utils/request';
import { TRANSLATIONS } from '../../../types/enums';
import ChartDropdown from '../../../components/Dropdown/ChartDropdown';
import ChartTabs from '../../../components/Tabs/ChartTabs';
import { getTimeFiltersFromState } from '../../../utils/dateHelpers';

const OverviewPage = () => {
  const { t } = useTranslation();

  const dropDownItems = [{
    slug: 'last-7-days',
    label: t(TRANSLATIONS.LAST_7_DAYS),
  }, {
    slug: 'last-30-days',
    label: t(TRANSLATIONS.LAST_30_DAYS),
  }, {
    slug: 'month-to-date',
    label: t(TRANSLATIONS.MONTH_TO_DATE),
  }];

  const [selectedDropDownItem, setSelectedDropDownItem] = useState(dropDownItems[0]);
  const [dropDownOpen, setDropDownOpen] = useState(false);

  const handleDropDownChange = ({ key }: SelectInfo) => {
    setSelectedDropDownItem(dropDownItems.find(
      item => item.slug === key,
    ) || dropDownItems[0]);
    setDropDownOpen(false);
  };

  const [chartApiResponse, setChartApiResponse] = useState(null as any);

  const tabsItems = [{
    title: t(TRANSLATIONS.REPORTS),
    value: chartApiResponse?.totalReports || 0,
  }, {
    title: t(TRANSLATIONS.UNIQUE),
    value: chartApiResponse?.uniqueReporters || 0,
  }, {
    title: t(TRANSLATIONS.AVERAGE_PER_USER),
    value: chartApiResponse?.averageReportsByUser || 0,
  }];

  const [selectedTab, setSelectedTab] = useState(tabsItems[0]);

  // const solidLineData = [150, 210, 170, 140, 70, 180, 305];

  useEffect(() => {
    (async () => {
      let startTime = null;
      let endTime = null;

      if (selectedDropDownItem.slug === dropDownItems[0].slug) {
        // last 7 days
        startTime = subDays(startOfDay(new Date()), 14);
        endTime = startOfDay(new Date());
      }
      if (selectedDropDownItem.slug === dropDownItems[1].slug) {
        // last 30 days
        startTime = subDays(startOfDay(new Date()), 60);
        endTime = startOfDay(new Date());
      }
      if (selectedDropDownItem.slug === dropDownItems[2].slug) {
        // month to date
        const startMonth = startOfMonth(new Date());
        const numofDays = differenceInCalendarDays(new Date(), startMonth);
        startTime = subDays(startOfDay(new Date()), numofDays * 2);
        endTime = startOfDay(new Date());
      }
      const timeFilters = getTimeFiltersFromState(fromJS({
        timeFilter: {
          startTime,
          endTime,
        },
      }));

      const response = await request.get('/api/v1/analytics/incidents-summary', {
        ...timeFilters,
      }).catch(
        console.error,
      );
      if (response) {
        setChartApiResponse(response);
      }
      // TODO: sample data follows - delete when necessary
      const ResponseBody = {
        totalReports: 300, // Total incidents in the time period
        uniqueReporters: 20, // Unique users that reported on the time period
        averageReportsByUser: 4.1, // Avereage reports by user
        totalUsers: 100, // Total users on the DB. Explained more below.
        incidentsByTime: {
          data: [150, 190, 205, 190, 195, 95, 200, 150, 210, 170, 140, 70, 180, 305],
          labels: [
            '2020-07-01T00:00:00',
            '2020-07-02T00:00:00',
            '2020-07-03T00:00:00',
            '2020-07-04T00:00:00',
            '2020-07-05T00:00:00',
            '2020-07-06T00:00:00',
            '2020-07-07T00:00:00',
            '2020-07-08T00:00:00',
            '2020-07-09T00:00:00',
            '2020-07-10T00:00:00',
            '2020-07-11T00:00:00',
            '2020-07-12T00:00:00',
            '2020-07-13T00:00:00',
            '2020-07-14T00:00:00',
          ],
        },
        uniqueReportersByTime: {
          data: [35, 23, 15, 21, 20, 24, 26, 28, 22, 21, 19, 11, 17, 24],
          labels: [
            '2020-07-01T00:00:00',
            '2020-07-02T00:00:00',
            '2020-07-03T00:00:00',
            '2020-07-04T00:00:00',
            '2020-07-05T00:00:00',
            '2020-07-06T00:00:00',
            '2020-07-07T00:00:00',
            '2020-07-08T00:00:00',
            '2020-07-09T00:00:00',
            '2020-07-10T00:00:00',
            '2020-07-11T00:00:00',
            '2020-07-12T00:00:00',
            '2020-07-13T00:00:00',
            '2020-07-14T00:00:00',
          ],
        },
      };
      setChartApiResponse(ResponseBody);
    })();
  }, [selectedDropDownItem]);

  const getChartVars = () => {
    if (chartApiResponse === null) {
      return ({
        solidLineData: [],
        dottedLineData: [],
        averageLineData: [],
        labels: [],
      });
    }
    let seriesToConsider = null;
    let labelsToConsider = null;

    if (selectedTab.title === tabsItems[0].title) {
      seriesToConsider = chartApiResponse.incidentsByTime.data;
      labelsToConsider = chartApiResponse.incidentsByTime.labels;
    }

    if (selectedTab.title === tabsItems[1].title) {
      seriesToConsider = chartApiResponse.uniqueReportersByTime.data;
      labelsToConsider = chartApiResponse.uniqueReportersByTime.labels;
    }
    if (seriesToConsider === null) {
      seriesToConsider = chartApiResponse.incidentsByTime.data.map(
        (d: number, i: number) => parseFloat((chartApiResponse.incidentsByTime.data[i]
          / chartApiResponse.uniqueReportersByTime.data[i]
        ).toFixed(2)),
      );
      labelsToConsider = chartApiResponse.incidentsByTime.labels;
    }

    const midOfSeries = Math.ceil(seriesToConsider.length / 2);
    const dottedLineData = seriesToConsider.slice(0, midOfSeries);
    const solidLineData = seriesToConsider.slice(midOfSeries);
    const averageLinePos = (solidLineData.reduce(
      (acc: number, node: number) => acc + node,
      0,
    )) / solidLineData.length;
    const averageLinePoints = new Array(solidLineData.length).fill(averageLinePos.toFixed(2));
    return ({
      solidLineData,
      dottedLineData,
      averageLineData: averageLinePoints,
      labels: (labelsToConsider || []).map((l: string) => {
        return format(parseISO(l), 'd MMMM');
      }),
    });
  };

  const chartVars = getChartVars();

  const chartData = {
    series: [{
      name: selectedDropDownItem.label,
      data: chartVars.solidLineData,
    },
    {
      name: t(TRANSLATIONS.PRECEDING_PERIOD),
      data: chartVars.dottedLineData,
    },
    {
      name: 'Avg',
      data: chartVars.averageLineData,
    },
    ],
    options: {
      colors: ['#70AD47', '#4472C4', '#F39C1F'],
      chart: {
        toolbar: {
          show: false,
        },
        height: 350,
        type: 'line',
        zoom: {
          enabled: false,
        },
      },
      dataLabels: {
        enabled: false,
      },
      stroke: {
        width: [3, 3, 3],
        curve: 'straight',
        dashArray: [0, 4, 0],
      },
      markers: {
        size: 0,
        hover: {
          sizeOffset: 6,
        },
      },
      xaxis: {
        categories: chartVars.labels,
      },
      yaxis: {
        opposite: true,
        tickAmount: 4,
        // min: 0,
        // max: 310,
        forceNiceScale: true,
      },
      tooltip: {
        y: [
          {
            title: {
              formatter(val: any) {
                return `${val}`;
              },
            },
          },
          {
            title: {
              formatter(val: any) {
                return `${val}`;
              },
            },
          },
        ],
      },
      grid: {
        borderColor: '#f1f1f1',
        padding: {
          top: 0,
          right: 25,
          bottom: 0,
          left: 25,
        },
      },
    },
  };
  if (chartApiResponse === null) {
    return (<div>Loading...</div>);
  }
  return (
    <>
      <div style={{
        margin: 25,
        display: 'flex',
        justifyContent: 'space-between',
      }}
      >
        <ChartTabs
          tabsItems={tabsItems}
          setSelectedTab={setSelectedTab as any}
          selectedTab={selectedTab}
        />
        <div>
          <img
            src={userIcon}
            alt="Users"
            style={{
              margin: '0 5px',
            }}
          />
          <span>HC</span>
          <br />
          <span>{chartApiResponse.totalUsers}</span>
        </div>
      </div>
      <div style={{
        margin: 50,
      }}
      >
        <Chart options={chartData.options} series={chartData.series} type="line" height={350} width={600} />
        <ChartDropdown
          onChange={handleDropDownChange}
          selectedDropDownItem={selectedDropDownItem}
          dropDownItems={dropDownItems}
          dropDownOpen={dropDownOpen}
          setDropDownOpen={setDropDownOpen}
        />
      </div>
    </>
  );
};

export default OverviewPage;
