import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { TRANSLATIONS } from 'types/enums';
import defaultRoutes from 'utils/defaultRoutes';
import { selectUserLocation, selectUserType } from 'store/selectors/user.selectors';
import deleteIcon from 'assets/icons/delete.svg';
import { ReactComponent as UploadIcon } from 'assets/icons/icon-upload.svg';

import HeaderWithNavigation from 'components/HeaderWithNavigation';
import './FileUploadHome.scss';
import MonthPicker from 'components/MonthlyTimeFilter/MonthPicker';
// @ts-ignore
import { OnlyMonthlyOptions } from 'components/MonthlyTimeFilter/MonthlyTimeFilter';
import { setMonthlyDropdownChange } from 'store/actions/timeFilter.actions';
import ChartDropdown from 'components/Dropdown/ChartDropdown';
import { useHistory } from 'react-router-dom';
import { toast, ToastOptions } from 'react-toastify';
import request from 'utils/request';
import usePrevious from 'utils/hooks/usePrevious';
import { useLastFileUploadDateQueryQuery } from 'generated/graphql';
import { isCurrentMonth, monthData } from 'utils/dateHelpers';

const FileUploadAnalytics = () => {
  const { data: accidentabilityFileLastUploadDate,
    error: fetchAccidentabilityFileLastUploadDateError,
    refetch: accidentabilityFileLastUploadDateRefetch } = useLastFileUploadDateQueryQuery();
  const { t } = useTranslation();
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const history = useHistory();
  const userType = useSelector(selectUserType);
  const location = useSelector(selectUserLocation);

  const options = [
    {
      slug: 'ACCIDENTABILITY',
      label: 'Accidentability',
      value: '',
      subtitle: 'Carga una nueva campaña preventiva, buenas prácticas y/o noticias de seguridad.',
      template: '',
      showBU: false,
      note: '',
    },
    {
      slug: 'CASHLESS',
      label: 'Cashless',
      value: '',
      subtitle: 'Carga una nueva campaña preventiva, buenas prácticas y/o noticias de seguridad.',
      template: 'https://arvolution-safety-temp.s3.us-east-2.amazonaws.com/AnalyticsFiles-Templates/CASHLESS+TEMPLATE.xlsx',
      showBU: false,
      note: '',

    },
    {
      slug: 'TELEMETRIA',
      label: 'Telemetria',
      value: '',
      subtitle: 'Carga una nueva campaña preventiva, buenas prácticas y/o noticias de seguridad.',
      template: '',
      showBU: false,
      note: '',
    },
    {
      slug: 'SIF_ROUTES',
      label: 'SIF Routes',
      value: '',
      subtitle: 'Carga una nueva campaña preventiva, buenas prácticas y/o noticias de seguridad.',
      template: 'https://arvolution-safety-temp.s3.us-east-2.amazonaws.com/AnalyticsFiles-Templates/SIF+ROUTE+TEMPLATE.xlsx',
      showBU: false,
      note: '',
    },
    {
      slug: 'CAPEX',
      label: 'CAPEX',
      value: '',
      subtitle: 'Carga una nueva campaña preventiva, buenas prácticas y/o noticias de seguridad.',
      template: 'https://arvolution-safety-temp.s3.us-east-2.amazonaws.com/AnalyticsFiles-Templates/CAPEX+TEMPLATE.xlsx',
      showBU: true,
      note: '',
    },
    {
      slug: 'VIOLENCE_PREVENTION',
      label: 'Prevencion de violencia',
      value: '',
      subtitle: 'Carga una nueva campaña preventiva, buenas prácticas y/o noticias de seguridad.',
      template: 'https://arvolution-safety-temp.s3.us-east-2.amazonaws.com/AnalyticsFiles-Templates/TOOLKIT+TEMPLATE.xlsx',
      showBU: false,
      note: '',
    },
    {
      slug: 'DMS',
      label: 'Driver Management System',
      value: '',
      subtitle: 'Carga una nueva campaña preventiva, buenas prácticas y/o noticias de seguridad.',
      template: 'https://arvolution-safety-temp.s3.us-east-2.amazonaws.com/AnalyticsFiles-Templates/DMS_TEMPLATE.xlsx',
      showBU: false,
      note: '',
    },
    {
      slug: 'SIF_STATS',
      label: t(TRANSLATIONS.SIF_STATISTICS),
      value: '',
      subtitle: 'Carga una nueva campaña preventiva, buenas prácticas y/o noticias de seguridad.',
      template: 'https://arvolution-safety-temp.s3.us-east-2.amazonaws.com/AnalyticsFiles-Templates/SIF+STATS+TEMPLATE.xlsx',
      showBU: false,
      note: '',
    },
    {
      slug: 'SIF_COMPLIANCE',
      label: 'SIF Compliance',
      value: '',
      subtitle: 'Carga una nueva campaña preventiva, buenas prácticas y/o noticias de seguridad.',
      template: 'https://arvolution-safety-temp.s3.us-east-2.amazonaws.com/AnalyticsFiles-Templates/SIF+COMPLIANCE+TEMPLATE.xlsx',
      showBU: false,
      note: '',
    },
  ];

  const BUoptions = [{
    slug: 'All BU',
    label: 'All BU',
    value: '',
    subtitle: 'Carga una nueva campaña preventiva, buenas prácticas y/o noticias de seguridad.',
    template: '',
  },
  {
    slug: 'Mexico',
    label: 'Mexico',
    value: '',
    subtitle: 'Carga una nueva campaña preventiva, buenas prácticas y/o noticias de seguridad.',
    template: '',
  },
  {
    slug: 'Colombia',
    label: 'Colombia',
    value: '',
    subtitle: 'Carga una nueva campaña preventiva, buenas prácticas y/o noticias de seguridad.',
    template: '',
  },
  {
    slug: 'Ecuador',
    label: 'Ecuador',
    value: '',
    subtitle: 'Carga una nueva campaña preventiva, buenas prácticas y/o noticias de seguridad.',
    template: '',
  },
  {
    slug: 'Peru',
    label: 'Peru',
    value: '',
    subtitle: 'Carga una nueva campaña preventiva, buenas prácticas y/o noticias de seguridad.',
    template: '',
  },
  {
    slug: 'CAC',
    label: 'CAC',
    value: '',
    subtitle: 'Carga una nueva campaña preventiva, buenas prácticas y/o noticias de seguridad.',
    template: '',
  },
  ];

  const div = document.getElementById('root');

  useEffect(() => {
    if (div) {
      div.style.background = 'white';
      return () => {
        div.style.background = '#F7F7F7';
      };
    }
  }, [div]);

  const dispatch = useDispatch();
  const [timeFilterOption, setTimeFilterOption] = useState(OnlyMonthlyOptions[0].value as any);
  const [time, setTime] = useState(OnlyMonthlyOptions[0]);
  const [dropDownOpen, setDropDownOpen] = useState(false);
  const [dropDownOpenBU, setDropDownOpenBU] = useState(false);
  const [selectedBU, setSelectedBU] = useState(BUoptions[0]);
  const [attachedFiles, setAttachedFiles] = useState<any>([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<unknown>();

  const handleMonthChange = (value: 'typeOf MonthlyOptions') => {
    const month = OnlyMonthlyOptions.find((i: any) => i.value === value) as any;
    setTimeFilterOption(value);
    setTime(month);
    dispatch(setMonthlyDropdownChange(value));
  };

  const [selectedDropFileType, setSelectedDropFileType] = useState({
    slug: 'Selecciona un tipo de archivo',
    label: 'Selecciona un tipo de archivo',
    value: '',
    subtitle: 'Carga una nueva campaña preventiva, buenas prácticas y/o noticias de seguridad.',
    template: '',
    showBU: false,
  });

  const handleDrop = ({ key }: { key: any }) => {
    setSelectedDropFileType(options.find(item => item.slug === key) || options[0]);
    setDropDownOpen(false);
  };

  const handleDropBU = ({ key }: { key: any }) => {
    setSelectedBU(BUoptions.find(item => item.slug === key) || BUoptions[0]);
    setDropDownOpenBU(false);
  };

  const handleAttachmentsUpload = (e: any) => {
    const { files } = e.target;
    if (files?.length) {
      setAttachedFiles((prev: any) => [...prev, ...files]);
    }
  };

  const toastConfig = {
    position: 'top-right',
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
  } as ToastOptions;

  const handleSuccess = (response: string) => {
    setAttachedFiles([]);
    console.log('Archivo subido correctamente', response);
    toast.success('Archivo subido correctamente');
  };

  const HandleUpload = async () => {
    const config = {
      headers: { 'content-type': 'multipart/form-data' },
    };
    const data = new FormData();

    data.set('analytics', attachedFiles[0]);
    data.set('date', time.startTime);
    data.set('fileType', selectedDropFileType.slug);
    data.set('month', time.label);
    data.set('country', selectedBU.value);

    try {
      setLoading(true);
      const response = await request.post('/api/v1/analytics-uploads', data, config);
      handleSuccess(response);
    } catch (err) {
      setError(err);
      console.log('error para David del file upload: ', err);
    } finally {
      setLoading(false);
    }
    if (selectedDropFileType.slug === 'ACCIDENTABILITY') {
      accidentabilityFileLastUploadDateRefetch();
    }
  };

  const prevLoading = usePrevious(loading);
  if (loading && prevLoading !== loading) {
    toast.info(t(TRANSLATIONS.LOADING), toastConfig);
  }

  const prevError = usePrevious(error);
  if (error && prevError !== error) {
    console.log(error, prevError);
    toast.error(`Ha ocurrido un error, vuelva a intentarlo. ${error}`, toastConfig);
  }

  if (fetchAccidentabilityFileLastUploadDateError) {
    console.log('Failed to fetch Accidentability Last file updated date');
    accidentabilityFileLastUploadDateRefetch();
  }

  return (
    <>
      <HeaderWithNavigation routes={defaultRoutes(t, userType, location, undefined)}>
        <div className="container-fluid overview-wrapper" style={{ minHeight: '80vh' }}>
          <div className="file-upload">
            <h1> {t(TRANSLATIONS.ANALYTICS)} </h1>
            <p> {selectedDropFileType.subtitle} </p>
            <div style={{ marginTop: '70px', display: 'flex', marginBottom: '20px' }}>
              <ChartDropdown
                onChange={handleDrop}
                selectedDropDownItem={selectedDropFileType}
                dropDownItems={options}
                dropDownOpen={dropDownOpen}
                setDropDownOpen={setDropDownOpen}
                labelClassName="label-default"
              />
              {
                selectedDropFileType.slug !== 'Selecciona un tipo de archivo'
                && (
                  selectedDropFileType.slug === 'ACCIDENTABILITY'
                    ? accidentabilityFileLastUploadDate?.lastFileUploadDate?.updatedAt
                      ? (
                        <div className={`last-file-upload-date${isCurrentMonth(accidentabilityFileLastUploadDate?.lastFileUploadDate?.updatedAt) ? '-upload-required' : ''}`}>
                          <p>
                            {t(TRANSLATIONS.LAST_UPDATED_ON, {
                              monthAndYear: monthData(
                              accidentabilityFileLastUploadDate?.lastFileUploadDate?.updatedAt,
                              ),
                            })}
                          </p>
                        </div>
                      )
                      : (
                        <div className="last-file-upload-date">
                          <p>
                            {t(TRANSLATIONS.PLEASE_UPLOAD_A_FILE)}
                          </p>
                        </div>
                      )
                    : (
                      <MonthPicker
                        currentValue={timeFilterOption}
                        onChange={handleMonthChange}
                      />
                    )
                )
              }
              <div style={{ flex: 1 }} />
              {selectedDropFileType.showBU
                ? (
                  <ChartDropdown
                    onChange={handleDropBU}
                    selectedDropDownItem={selectedBU}
                    dropDownItems={BUoptions}
                    dropDownOpen={dropDownOpenBU}
                    setDropDownOpen={setDropDownOpenBU}
                    labelClassName="label-default"
                  />
                ) : <div style={{ flex: 1 }} />}
            </div>
            <div style={{ marginTop: '20px', marginBottom: '40px' }}>
              {selectedDropFileType.template && (
                <a style={{ color: 'blue' }} href={selectedDropFileType.template} target="_blank" rel="noreferrer">  Por favor utiliza esta plantilla como ejemplo <br /> para subir tus archivos de la manera correcta.  </a>
              )}
            </div>
            {
              selectedDropFileType.slug !== 'Selecciona un tipo de archivo'
              && attachedFiles?.length <= 0 && (
                <>
                  <div className="post-upload__right__second-step__left__attached-files">
                    <div className="post-upload__right__second-step__left__attached-files__list">
                      <br />
                      <button
                        className="post-upload__right__second-step__left__attached-files__upload-files"
                        type="button"
                        style={{ width: '40vw' }}
                      >
                        <p>+ Agregar archivo</p>
                        <input
                          type="file"
                          onChange={handleAttachmentsUpload}
                          name="attachments"
                          multiple
                          accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                        />
                      </button>
                    </div>
                  </div>
                  <p style={{ color: 'red', marginTop: '10px', marginBottom: '30px' }}> *Recuerda que solamente se aceptan archivos con el mismo formato que la plantilla.
                    Los archivos no deben contener filtros o tablas dinámicas.
                    No olvides asegurarte de que el nombre de la hoja del excel es el mismo que
                    contiene la plantilla.
                  </p>
                </>
              )
            }
            {
              attachedFiles?.length > 0 && attachedFiles?.map(
                (attachedFile: any) => (
                  <>
                    <div className="post-upload__right__second-step__left__attached-files">
                      <div className="post-upload__right__second-step__left__attached-files__list">
                        <div
                          className="post-upload__right__second-step__left__attached-files__upload-files"
                        >
                          {!attachedFile.type.includes('image') && (<p>{attachedFile.name}</p>)}
                          <button
                            onClick={() => setAttachedFiles(
                              attachedFiles.filter(
                                (item: any) => item.name !== attachedFile.name,
                              ),
                            )}
                            type="button"
                            className="post-upload__right__second-step__left__attached-files__upload-files__remove-btn"
                          >
                            <img src={deleteIcon} alt="delete file" width={30} height={30} />
                          </button>
                        </div>
                      </div>
                    </div>
                    <p style={{ color: 'red', marginBottom: '20px' }}> *Recuerda que solamente se aceptan archivos con el mismo formato que la plantilla.
                      Los archivos no deben contener filtros o tablas dinámicas.
                      No olvides asegurarte de que el nombre de la hoja del excel es el mismo que
                      contiene la plantilla.
                    </p>
                    <button
                      style={{ marginBottom: '40px' }}
                      className="post-upload__right__second-step__left__submit-button"
                      type="submit"
                      disabled={attachedFiles === 0}
                      onClick={async () => {
                        HandleUpload();
                      }}
                    >
                      <UploadIcon />
                      Upload File
                    </button>
                  </>
                ))
            }
          </div>
        </div>
      </HeaderWithNavigation>
    </>
  );
};

export default FileUploadAnalytics;
