import React, { useState } from 'react';

import Input from '../Input';
import './InputWithIcon.scss';

type Props = {
  onChange(e: any): void;
  placeholder: string;
  icon: string;
  errorIcon?: string;
  value?: string;
  name?: string;
  error?: string;
  type?: 'text' | 'password';
  style?: React.CSSProperties;
  imageStyles?: React.CSSProperties;
  className?: string;
  activeBorderColor?: string;
  inactiveBorderColor?: string;
  errorHeight?: boolean; // if it is true, it gives the error a default 24px height
  activeBoxShadow?: string;
  clickable?: boolean;
  errorTxt?: boolean;
  autoComplete?: boolean;
};

const InputWithIcon = (props: Props) => {
  const {
    onChange,
    placeholder,
    icon,
    errorIcon,
    value,
    name,
    error,
    type,
    style,
    className,
    errorHeight = false,
    imageStyles,
    clickable,
    errorTxt,
    autoComplete,
  } = props;

  const [passwordType, setPasswordType] = useState('password');
  function click() {
    if (passwordType === 'password') {
      setPasswordType('text');
    }
    if (passwordType === 'text') {
      setPasswordType('password');
    }
  }
  return (
    <div className="input-with-icon">
      {errorIcon
        ? <img onClick={() => ((type === 'password') ? click() : () => false)} src={error ? errorIcon : icon} alt="icon" className="input-icon" style={{ ...(imageStyles || {}), cursor: clickable ? 'pointer' : 'default' }} />
        : <img onClick={() => ((type === 'password') ? click() : () => false)} src={icon} alt="icon" className="input-icon" style={{ ...(imageStyles || {}), cursor: clickable ? 'pointer' : 'default' }} />}
      <Input
        {...props}
        type={type === 'password' ? passwordType : type}
        placeholder={placeholder}
        onChange={onChange}
        name={name}
        error={error}
        className={`input ${className} ${error ? 'error' : ''}`}
        style={{ borderColor: error ? '#D47420' : '#8A99AA', boxShadow: 'none', ...style }}
        value={value}
        errorHeight={errorHeight}
        errorTxt={errorTxt}
        autoComplete={autoComplete}
      />
    </div>
  );
};

export default InputWithIcon;
