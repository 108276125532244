import axios from 'axios';

export async function requestImageUploadPermission(imageExtension: string, target: string) {
  try {
    const url = '/api/v1/image-upload';
    /*
    image validation on server side is checking if file extension is in between 3 to 4 length
    so we are splitting it in the next line of code and if file extension is not
    available the we are hard coding the image type
    */
    let fileExtension = 'jpeg';
    if (imageExtension.includes('/')) {
      [, fileExtension] = imageExtension.split('/');
    }
    return await axios.post(url, {
      fileExtension,
      target,
    });
  } catch (e) {
    console.log('e requestImageUploadPermission(): ', (e as Error).message || e);
  }
}

export async function uploadObjectImage(presignedUrl: string, uri: string) {
  try {
    const url = presignedUrl;

    const img = await fetch(uri);
    const blob = await img.blob();

    const response = await fetch(url, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/octet-stream',
      },
      body: blob,
    });

    if (response && response.status >= 200 && response.status <= 299) {
      console.log('uploadImage() uploaded image to S3 successfully');
      return response.url.split('?')[0];
    }
  } catch (e) {
    console.log('e uploadImage(): ', (e as Error).message || e);
  }
}

export const uploadToS3 = async (imageUrl: any) => {
  const s3Target = 'ROUTINES_AVAILABLE';
  const response = await requestImageUploadPermission('jpeg', s3Target);
  if (response?.data?.preSignedUrl) {
    const binaryData = [];
    binaryData.push(imageUrl);
    const imgUrl = window.URL.createObjectURL(new Blob(binaryData, { type: 'image/jpg' }));
    const url = await uploadObjectImage(response.data.preSignedUrl, imgUrl);
    return url;
  }
};

export function dataURLtoBlob(dataurl: any) {
  const arr = dataurl.split(','); const mime = arr[0].match(/:(.*?);/)[1];
  const bstr = atob(arr[1]); let n = bstr.length; const
    u8arr = new Uint8Array(n);
  // eslint-disable-next-line no-plusplus
  while (n--) {
    u8arr[n] = bstr.charCodeAt(n);
  }
  return new Blob([u8arr], { type: mime });
}
