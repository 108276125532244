import React from 'react';
import { Backdrop, Button, Fade, Modal } from '@material-ui/core';
import { TRANSLATIONS } from 'types/enums';
import { useTranslation } from 'react-i18next';

interface ModalProps {
  isOpen: boolean;
  onCancel: () => void;
  onConfirm: () => void;
}

const CommentDeleteModal: React.FC<ModalProps> = ({ isOpen, onCancel, onConfirm }) => {
  const { t } = useTranslation();
  function handleOnConfirm() {
    onCancel();
    onConfirm();
  }
  return (
    <Modal
      className="comment-delete-modal"
      open={isOpen}
      onClose={onCancel}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 1,
      }}
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
    >
      <Fade in={isOpen}>
        <div className="comment-delete-modal__content">
          <header>
            <span>{t(TRANSLATIONS.DELETE_COMMENT)}</span>
          </header>
          <footer>
            <Button variant="contained" onClick={handleOnConfirm} className="delete_button">
              {t(TRANSLATIONS.DELETE)}
            </Button>
            <Button onClick={onCancel} className="cancel-button">
              {t(TRANSLATIONS.CANCEL)}
            </Button>
          </footer>
        </div>
      </Fade>
    </Modal>
  );
};

export default CommentDeleteModal;
