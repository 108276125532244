import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { TRANSLATIONS } from 'types/enums';
import ImageLinks from 'utils/ImageLinks';
import './ChecklistHeader.scss';
import ActionButton from 'components/Espectro/Buttons/Action/Action';
import Tabs from 'components/Espectro/Tabs';
import { TabItem } from 'components/Espectro/Tabs/Tabs';
import { CHECKLIST_TITLE_MAX_LENGTH_V1 } from 'features/Espectro/constants';

interface ChecklistHeaderProps {
  goBack: () => void;
  sectionsLength: number;
  loading: boolean;
  showShareButton: boolean;
  handleSaveRoutine: any;
  checklistTile?: string;
  onChangeTitle: (e: any) => void;
  onToggleEditTitle?: (height: number) => void;
  generateShareableToken: () => void;
  selectedTab?: TabItem;
  setSelectedTab: (i:TabItem) => void;
  tabs: TabItem[];
  checklistVersion?: number;
}

const ChecklistHeader = ({
  goBack,
  sectionsLength,
  loading,
  handleSaveRoutine,
  checklistTile,
  showShareButton = false,
  onChangeTitle,
  onToggleEditTitle,
  generateShareableToken,
  tabs,
  selectedTab = tabs[0],
  setSelectedTab,
  checklistVersion,
}: ChecklistHeaderProps) => {
  const { t } = useTranslation();
  const inputEl = useRef<HTMLInputElement>(null);
  const parentRef = useRef<HTMLDivElement>(null);

  const [editTitle, setEditTitle] = useState<boolean>(false);
  const toggleEditTitle = () => {
    setEditTitle(prev => !prev);
  };

  useEffect(() => {
    if (editTitle && inputEl?.current) {
      inputEl.current.focus();
    }
    if (parentRef.current) {
      const { height } = parentRef.current?.getBoundingClientRect();
      if (onToggleEditTitle) {
        onToggleEditTitle(height);
      }
    }
  }, [editTitle]);

  return (
    <div ref={parentRef} className="checklistHeader__container">
      <div className="checklist_header-back-and-title-container">
        <div role="button" className="checklistHeader__back-v1" onClick={goBack}>
          <img alt="backArrow" src={ImageLinks.espectro.backArrow} />
          <p>{t(TRANSLATIONS.BACK)}</p>
        </div>
        <div className="checklistHeader__tab-edit-v1">
          <div className="checklistHeader__edit-container-v1">
            {editTitle ? (
              <input
                ref={inputEl}
                className="checklistHeader_input-v1"
                type="text"
                placeholder={t(TRANSLATIONS.ENTER_TITLE)}
                value={checklistTile}
                onChange={onChangeTitle}
                onBlur={toggleEditTitle}
                maxLength={CHECKLIST_TITLE_MAX_LENGTH_V1}
              />
            ) : (
              <div className="checklistHeader_edit">
                <p onClick={toggleEditTitle}>{checklistTile}</p>
                <img onClick={toggleEditTitle} height={16} width={16} src={ImageLinks.edit} alt="edit" />
              </div>
            )}
          </div>
          {
            showShareButton && (
              <p className="checklist-header__version-text">
                Version {checklistVersion}
              </p>
            )
          }
        </div>
      </div>
      <div className="checklist_header-tabs-container remove-extra-padding-margins">
        <Tabs
          selectedTab={selectedTab}
          setSelectedTab={setSelectedTab}
          tabsItems={tabs}
        />
      </div>

      <div className="checklistHeader__button-container">
        {
          showShareButton && (
            <ActionButton
              onClick={generateShareableToken}
              className="checklist-header-action-button-share"
              text={(
                <div>
                  <img
                    alt="copy"
                    id="shareStyle"
                    style={{ marginRight: 8 }}
                    width={18}
                    height={18}
                    src={ImageLinks.espectro.shareIcon}
                  />
                  {t(TRANSLATIONS.SHARE)}
                </div>
              )}
              disabled={!sectionsLength || loading}
              background="#FFFFFF"
              style={{ color: '#000000', border: '1px solid #D6D6D6', fontWeight: 400 }}
            />
          )
        }
        <ActionButton
          className="checklist-header-action-button-save"
          onClick={handleSaveRoutine}
          text={t(TRANSLATIONS.SAVE)}
          disabled={!sectionsLength || loading}
          background="#6C63FF"
        />
      </div>
    </div>
  );
};

export default ChecklistHeader;
