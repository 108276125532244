import React from 'react';
import Modal from '@material-ui/core/Modal';

interface OverlayProps {
  open: boolean;
  onClose: () => void;
  children: any;
}
const Overlay = ({ open, onClose, children }: OverlayProps) => {
  return (
    <Modal
      open={open}
      onClose={onClose}
    >
      {children}
    </Modal>
  );
};

export default Overlay;
