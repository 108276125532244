import { ChecklistBit, ChecklistItem, ChecklistSection } from 'espectro-utils/checklistRendererUtils/types';
import { IMAGE_SAVING_STATUS } from 'types/enums';
import { requestImageUploadPermission, uploadObjectImage } from './ImageHelper';

const uploadImagesIfAny = async (
  checklist: ChecklistSection[],
  setImageSavingStatus: React.Dispatch<React.SetStateAction<string>>,
) => {
  let imagesCount = 0;
  let uploadedImagesCount = 0;
  const uploadToS3 = async (imageUrl: string) => {
    const s3Target = 'ROUTINES_AVAILABLE';
    const response = await requestImageUploadPermission('jpeg', s3Target);
    setImageSavingStatus(IMAGE_SAVING_STATUS.IN_PROGRESS);
    if (response?.data?.preSignedUrl) {
      const binaryData = [];
      binaryData.push(imageUrl);
      const imgUrl = window.URL.createObjectURL(new Blob(binaryData, { type: 'image/jpg' }));
      const url = await uploadObjectImage(response.data.preSignedUrl, imgUrl);
      uploadedImagesCount += 1;

      if (imagesCount === uploadedImagesCount) setImageSavingStatus(IMAGE_SAVING_STATUS.DONE);

      return url;
    }
    setImageSavingStatus(IMAGE_SAVING_STATUS.DONE);
  };

  checklist.forEach((section: ChecklistSection) => {
    section.items.forEach((item: ChecklistItem) => {
      item.bits.forEach(async (bit: ChecklistBit) => {
        // @ts-ignore
        if (bit.type === 'SIGNATURE') {
          if (bit.answer) {
            imagesCount += 1;
            const newUrl = await uploadToS3(bit.answer);
            if (newUrl) {
              bit.answer = newUrl;
            }
          }
        }

        // @ts-ignore
        if (bit.type === 'DRIBBLE') {
          if (bit.answer) {
            imagesCount += 1;
            const newUrl = await uploadToS3(bit.answer);
            if (newUrl) {
              bit.answer = newUrl;
            }
          }
        }

        // @ts-ignore
        if (bit.type === 'IMAGE_PICKER') {
          if (bit.answer && bit.answer.length > 0) {
            bit.answer.forEach(async (img: string, imgIndex: number) => {
              if (img) {
                imagesCount += 1;
                const newUrl = await uploadToS3(img);
                if (newUrl) {
                  bit.answer[imgIndex] = newUrl;
                }
              }
            });
          }
        }
      });
    });
  });

  if (imagesCount === 0) {
    setImageSavingStatus(IMAGE_SAVING_STATUS.DONE);
    return IMAGE_SAVING_STATUS.DONE;
  }
};

export default uploadImagesIfAny;
