import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { selectUserType, selectUserLocation, selectUser } from 'store/selectors/user.selectors';
import defaultRoutes from 'utils/defaultRoutes';
import SKAP from 'features/SKAP';
import HeaderWithNavigation from 'components/HeaderWithNavigation';
import { isEspectro } from '../../constants';
import AnalyticsOverview from './Overview';
import AnalyticsIncidents from './Incidents';
import AnalyticsProductionPlanning from './AnalyticsProductionPlanning';
import Accidentability from './Accidentability/Accidentability';
import Consequences from './Consequences';
import Cashless from './Cashless/Cashless';
import SifRoute from './SifRoute/SifRoute';
import RoutinesHome from './Routines/RoutinesHome';
import './AnalyticsStyles.scss';
import GKPIRepresentation from '../KPI/GKPI/GKPIRepresentation';
import UploadGKPI from '../KPI/GKPI/UploadGKPI';
import StatisticsSIF from './StatisticsSIF';
import ComplainceSIF from './ComplainceSIF';
import DriverManagmentSystem from './DriverManagmentSystem';
import SafetyTraining from './SafetyTraining';
import VoliencePrevention from './VoliencePrevention';
import Capex from './Capex';
import WebPortal5S from './5S/WebPortal5S';
import OWD from './OWD';

type Props = {};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export default function AnalyticsWrapper(props: Props) {
  const { t } = useTranslation();
  const userType = useSelector(selectUserType);
  const userDetails = useSelector(selectUser);
  const location = useSelector(selectUserLocation);

  return (
    <HeaderWithNavigation routes={defaultRoutes(t, userType, location, userDetails)}>
      <Switch>
        <Route path="/analytics/overview" component={AnalyticsOverview} />
        <Route path="/analytics/incidents" component={AnalyticsIncidents} />
        <Route path="/analytics/production-planning" component={AnalyticsProductionPlanning} />
        <Route path="/analytics/routines" component={RoutinesHome} />
        <Route path="/analytics/accidentability" component={Accidentability} />
        <Route path="/analytics/consequences" component={Consequences} />
        <Route path="/analytics/cashless" component={Cashless} />
        <Route path="/analytics/5S" component={WebPortal5S} />
        <Route path="/analytics/sif-route" component={SifRoute} />
        <Route path="/analytics/GKPI" exact component={GKPIRepresentation} />
        <Route path="/analytics/GKPI/upload" exact component={UploadGKPI} />
        <Route path="/analytics/driver-management-system" component={DriverManagmentSystem} />
        <Route path="/analytics/sif-statistics" exact component={StatisticsSIF} />
        <Route path="/analytics/sif-complaince" component={ComplainceSIF} />
        <Route path="/analytics/safety-training" component={SafetyTraining} />
        <Route path="/analytics/Capex" component={Capex} />
        <Route path="/analytics/violence-prevention" component={VoliencePrevention} />
        <Route path="/analytics/skap" component={SKAP} />
        <Route path="/analytics/owd" component={OWD} />
        <Route path="/" component={() => <Redirect to={isEspectro ? '/espectro/checklist-builder' : '/analytics/overview'} />} />
      </Switch>
    </HeaderWithNavigation>
  );
}
