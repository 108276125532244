import {
  CapexCommentsDocument,
} from 'generated/graphql';

/* eslint-disable import/prefer-default-export */
export const capexCommentRefetchQueries = (capexId: string) => {
  return [
    {
      query: CapexCommentsDocument,
      variables: {
        capexId,
      },
    },
  ];
};
