import { TSearchedUsers } from 'features/action-logs/SummaryModal/SummaryModalComponent/ResponsiblesAvatarWithTooltip/searchHandlerAPI';
import { ExtractedContent } from 'features/action-logs/SummaryModal/SummaryModalComponent/ResponsiblesAvatarWithTooltip/userSearchTypes';
import { BU } from 'types/enums';
// eslint-disable-next-line import/prefer-default-export
export function contentExtractor(contents: TSearchedUsers[]): ExtractedContent[] {
  return contents.map(content => ({
    key: content.slug,
    title: content.name,
    subTitle: content.position,
    ...content,
  }));
}

export const BULISTMap = {
  [BU.Mexico]: {
    label: 'Mexico', slug: 'mexico',
  },
  [BU.Perú]: {
    label: 'Perú', slug: 'peru',
  },
  [BU.Ecuador]: {
    label: 'Ecuador', slug: 'ecuador',
  },
  [BU.Colombia]: {
    label: 'Colombia', slug: 'colombia',
  },
  [BU.CAC]: {
    label: 'CAC', slug: 'cac',
  },
};

export const buList = [
  { ...BULISTMap[BU.Mexico], bu: [BULISTMap[BU.Mexico].slug] },
  { ...BULISTMap[BU.Perú], bu: [BULISTMap[BU.Perú].slug] },
  { ...BULISTMap[BU.Ecuador], bu: [BULISTMap[BU.Ecuador].slug] },
  { ...BULISTMap[BU.Colombia], bu: [BULISTMap[BU.Colombia].slug] },
  { ...BULISTMap[BU.CAC], bu: [BULISTMap[BU.CAC].slug] },
];

export const buListRoutines = [
  { label: BULISTMap[BU.Mexico].label, value: BULISTMap[BU.Mexico].slug },
  { label: BULISTMap[BU.Perú].label, value: BULISTMap[BU.Perú].slug },
  { label: BULISTMap[BU.Ecuador].label, value: BULISTMap[BU.Ecuador].slug },
  { label: BULISTMap[BU.Colombia].label, value: BULISTMap[BU.Colombia].slug },
  { label: BULISTMap[BU.CAC].label, value: BULISTMap[BU.CAC].slug },
];
