import React, { useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { TRANSLATIONS } from 'types/enums';
import defaultRoutes from 'utils/defaultRoutes';
import { selectUserLocation, selectUserType } from 'store/selectors/user.selectors';

import TileWithImage from 'components/TileWithImage/TileWithImage';
import HeaderWithNavigation from 'components/HeaderWithNavigation';
import ListItemHorizontal from 'components/ListItemHorizontal/ListItemHorizontal';

import AnalyticsIcon from 'assets/icons/Analytics.svg';
// import AccidentabilityIcon from 'assets/icons/Shield-Positive.svg';

import './FileUploadHome.scss';

const FileUploadHome = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const userType = useSelector(selectUserType);
  const location = useSelector(selectUserLocation);

  const listItems = useMemo(() => [
    // {
    //   title: 'Headcount',
    //   subtitle: 'Carga archivo de headcount por toda la zona o por región.',
    //   onClick: () => console.log('openUploadModal'),
    //   hasTemplate: true,
    //   hideFileInput: false,
    // },
    // {
    //   title: 'Ranking Safety',
    //   subtitle: 'Carga arrchivo con ranking de Safety',
    //   onClick: () => console.log('openUploadModal'),
    //   hasTemplate: false,
    //   hideFileInput: false,
    // },

    {
      title: 'Safety academy',
      subtitle: 'Carga una nueva campaña preventiva, buenas prácticas y/o noticias de seguridad.',
      onClick: () => history.push('/file-upload/academy'),
      hideFileInput: true,
      hasTemplate: false,
    },
  ], [history]);

  // Needed to style the whole page white
  const div = document.getElementById('root');

  useEffect(() => {
    if (div) {
      div.style.background = 'white';
      return () => {
        div.style.background = '#F7F7F7';
      };
    }
  }, [div]);

  return (
    <HeaderWithNavigation routes={defaultRoutes(t, userType, location, undefined)}>
      <div className="container-fluid overview-wrapper">
        <div className="file-upload">
          <h1>Carga de Archivos</h1>

          <div className="file-upload__buttons-row">
            {/* <TileWithImage
              img={AccidentabilityIcon}
              title={t(TRANSLATIONS.ACCIDENTABILITY)}
              subtitle="Carga archivos con ACIs, LTIs, TRIs, FAT, SIF, LTIR y SR."
              link="accidentability"
            /> */}
            <TileWithImage
              img={AnalyticsIcon}
              title={t(TRANSLATIONS.ANALYTICS)}
              subtitle="Carga archivos de CAPEX, Telemetría, Cashless y Prevención de la violencia.."
              link="analytics"
            />
          </div>

          <div className="file-upload__bottom">
            {listItems?.map(({ title, subtitle, onClick, hasTemplate, hideFileInput }) => (
              <ListItemHorizontal
                onClick={onClick}
                title={title}
                subtitle={subtitle}
                hasTemplate={hasTemplate}
                hideFileInput={hideFileInput}
              />
            ))}
          </div>
        </div>
      </div>
    </HeaderWithNavigation>
  );
};

export default FileUploadHome;
