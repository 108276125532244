/* eslint-disable no-shadow */
import React, { FC, useState, useMemo, useCallback, useRef, useEffect } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { debounce, escapeRegExp, groupBy } from 'lodash';
import { FILTER_ACCESS_LEVELS, GLOBAL_SEARCH_TYPES, TRANSLATIONS } from 'types/enums';
import { SearchableLocation } from 'types/interfaces';
import ImageLinks from 'utils/ImageLinks';
import { useTranslation } from 'react-i18next';
import {
  setBusinessUnit,
  setZone,
  setSubzone,
  setLocation,
  getAnalyticsFilters,
} from '../../../store/actions/globalFilters.actions';
import './GlobalSearch.scss';
import ResultCard from './ResultCard';

function mapSectionTitle(title: FILTER_ACCESS_LEVELS) {
  const titles = {
    [FILTER_ACCESS_LEVELS.BUSINESS_UNIT]: 'Business Units',
    [FILTER_ACCESS_LEVELS.DISTRIBUTION_CENTER]: 'Distribution Center',
    [FILTER_ACCESS_LEVELS.SUBZONE]: 'Sub Zone',
    [FILTER_ACCESS_LEVELS.ZONE]: 'Zone',
    [FILTER_ACCESS_LEVELS.CLUSTER]: 'Cluster',
  };
  return titles[title] || '';
}
interface Props {
  setBusinessUnit: Function;
  setZone: Function;
  setSubzone: Function;
  setLocation: Function;
  searchableLocations: SearchableLocation[];
  businessUnitSlug: string;
  zoneSlug: string;
  subzoneSlug: string;
}

interface LocationSuggestions{
  [FILTER_ACCESS_LEVELS.BUSINESS_UNIT]?: SearchableLocation[];
  [FILTER_ACCESS_LEVELS.DISTRIBUTION_CENTER]?: SearchableLocation[];
  [FILTER_ACCESS_LEVELS.SUBZONE]?: SearchableLocation[];
  [FILTER_ACCESS_LEVELS.ZONE]?: SearchableLocation[];
}

const GlobalSearch: FC<Props> = props => {
  const {
    setBusinessUnit,
    setZone,
    setSubzone,
    setLocation,
    searchableLocations,
    businessUnitSlug,
    zoneSlug,
    subzoneSlug,
  } = props;

  const [suggestions, setSuggestions] = useState<LocationSuggestions>({});
  const [isActive, setIsActive] = useState(false);
  const inputRef = useRef<HTMLInputElement>(null);
  const node = useRef<HTMLDivElement>(null);

  const { t } = useTranslation();

  function clearSuggestions() {
    setSuggestions({});
  }

  function clearField() {
    if (inputRef.current) {
      inputRef.current.value = '';
      inputRef.current.focus();
    }
    clearSuggestions();
  }

  function handleOnBlur() {
    if (inputRef.current) {
      inputRef.current.value = '';
    }
    clearSuggestions();
    setIsActive(false);
  }

  function handleOnFocus() {
    setIsActive(true);
  }

  function handleClickOutside(e: any) {
    if (node.current?.contains(e.target)) {
      return;
    }
    handleOnBlur();
  }

  const handleSuggestionsRequested = useCallback(
    (e: any) => {
      const searchBy = e.target.value;
      if (searchBy && searchableLocations) {
        const matchedElements = searchableLocations
          .map(
            location => ({
              ...location,
              foundAt: location.name.toLowerCase().search(escapeRegExp(searchBy).toLowerCase()),
            } as SearchableLocation & { foundAt: number }),
          )
          .filter(location => location.foundAt > -1)
          .sort((locationFirst, locationSecond) => locationFirst.foundAt - locationSecond.foundAt);
        const groupedElements = groupBy(matchedElements, matchedElement => matchedElement.subType);
        setSuggestions(groupedElements);
      } else {
        clearSuggestions();
      }
    },
    [searchableLocations],
  );

  const debouncedHandleSuggestionsRequested = useMemo(
    () => debounce(handleSuggestionsRequested, 200),
    [handleSuggestionsRequested],
  );

  function eventPersistAndHandleRequest(e: any) {
    e.persist();
    debouncedHandleSuggestionsRequested(e);
  }

  function handleLocationSelected(location: SearchableLocation) {
    if (location.bu?.slug && businessUnitSlug !== location.bu.slug) {
      setBusinessUnit({ slug: location.bu.slug, name: location.bu.name });
    }

    if (location.zone?.slug && zoneSlug !== location.zone.slug) {
      setZone({ slug: location.zone.slug, name: location.zone.name });
    }

    if (location.subzone?.slug && subzoneSlug !== location.subzone.slug) {
      setSubzone({ slug: location.subzone.slug, name: location.subzone.name });
    }

    switch (location.subType) {
      case FILTER_ACCESS_LEVELS.BUSINESS_UNIT:
        setBusinessUnit({ slug: location.slug, name: location.name });
        break;
      case FILTER_ACCESS_LEVELS.ZONE:
        setZone({ slug: location.slug, name: location.name });
        break;
      case FILTER_ACCESS_LEVELS.SUBZONE:
        setSubzone({ slug: location.slug, name: location.name });
        break;
      case FILTER_ACCESS_LEVELS.DISTRIBUTION_CENTER:
        setLocation({ slug: location.slug, name: location.name });
        break;
      default:
    }
  }

  function handleSuggestionSelected(selectedItem: any) {
    handleOnBlur();
    switch (selectedItem.type) {
      case GLOBAL_SEARCH_TYPES.LOCATION:
        handleLocationSelected(selectedItem);
        break;
      default:
    }
  }
  const suggestedItem = Object.values(suggestions);
  const limitList = suggestedItem.length > 3 ? 5 : 10;

  // Render function need to be modified as We include more Type
  // of Searches in GLobal Search.
  function sectionRenderer(section: SearchableLocation[]) {
    return (
      <div>
        <h4 className="section_heading">{mapSectionTitle(section[0].subType)}</h4>
        {section.slice(0, limitList).map(location => (
          <ResultCard
            onClick={handleSuggestionSelected}
            key={location.slug}
            suggestedItem={location}
          />
        ))}
      </div>
    );
  }

  useEffect(() => {
    if (isActive) {
      window.addEventListener('mousedown', handleClickOutside);
    } else {
      window.removeEventListener('mousedown', handleClickOutside);
    }
    return () => {
      window.removeEventListener('mousedown', handleClickOutside);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isActive]);

  return (
    <div className="global_search" ref={node}>
      <div className="input_with_icon">
        <img src={ImageLinks.searchIcon} alt="icon" className="icon_search" />
        <input
          ref={inputRef}
          disabled={false}
          placeholder={t(TRANSLATIONS.SEARCH_LOCATION)}
          className={`${isActive ? 'active' : 'in-active'} global-search-input`}
          onChange={eventPersistAndHandleRequest}
          onFocus={handleOnFocus}
        />
        <img
          src={ImageLinks.closeMaterial}
          alt="icon"
          className={`${inputRef.current?.value ? 'active' : 'in-active'} icon_close`}
          onClick={clearField}
        />
      </div>
      {isActive && inputRef.current?.value && (
        <div className="global_search__result_pad">
          {suggestedItem.length ? (
            suggestedItem.map(section => sectionRenderer(section))
          ) : (
            <div className="empty_result">
              <p>{t(TRANSLATIONS.NO_MATCHES_FOUND)}</p>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

function mapStateToProps(state: any) {
  return {
    businessUnitSlug: state.getIn(['globalFilters', 'businessUnit', 'slug']),
    zoneSlug: state.getIn(['globalFilters', 'zone', 'slug']),
    subzoneSlug: state.getIn(['globalFilters', 'subzone', 'slug']),
    analyticsFilters: state.getIn(['globalFilters', 'analyticsFilters', 'businessUnits'])?.toJS(),
    searchableLocations: state.getIn(['globalFilters', 'searchableLocations']),
    recentSearches: state.getIn(['globalFilters', 'recentSearches'])?.toJS(),
  };
}

function mapDispatchToProps(dispatch: any) {
  return bindActionCreators(
    {
      setBusinessUnit,
      setZone,
      setSubzone,
      setLocation,
      getAnalyticsFilters,
    },
    dispatch,
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(GlobalSearch);
