import React from 'react';
import { Link } from 'react-router-dom';
import qs from 'qs';

// @ts-ignore
import Logo from '../../../assets/icons/company-logo-yellow.png';
import BuyIcon from '../../../assets/icons/ta-da-icon.png';
import SellIcon from '../../../assets/icons/beer-bottle.png';
import { ReactComponent as Truck } from '../../../assets/icons/truck.svg';

import { ReactComponent as TruckSteeringWheel } from '../../../assets/icons/truck-steering-wheel.svg';
import './AnonymousTruckScan.scss';

const AnonymousTruckScann = (props: any) => {
  const truckNumber = qs.parse(props.location.search?.substring(1))?.tn;
  const banners = [{
    icon: () => <img src={BuyIcon} alt="" />,
    text: 'Comprar unas chelas',
    // @ts-ignore
    href: 'https://www.tadadelivery.com.mx/inicio',
  }, {
    icon: () => <img src={SellIcon} alt="" />,
    text: 'Vende nuestras marcas',
    // @ts-ignore
    url: `/sell-brands?truck-number=${truckNumber}`,
  }, {
    icon: TruckSteeringWheel,
    text: 'Reporta como manejo',
    url: `/tc?sn=${truckNumber}`,
  }];

  const RenderLink = ({ children, item, className }: any) => (item.href ? (
    <a className={className} href={item.href}>
      { children }
    </a>
  ) : (
    <Link to={item.url} className={className}>
      { children }
    </Link>
  ));

  return (
    <div className="container anonymous-truck-scann">
      <div className="row">
        <div className="col-md-6 offset-md-3 main-container" style={{ marginTop: 0, paddingBottom: 200 }}>
          <img
            // height={300}
            src={Logo}
            alt="banner"
          />
          <div className="truck-number-container">
            <Truck />
            <p>{ qs.parse(props.location.search?.substring(1))?.tn }</p>
          </div>
          <p className="details-text">¿Qué deseas hacer?</p>
          { banners.map((i: any) => (
            <RenderLink className="card action-banner" item={i} key={i.text}>
              <i.icon />
              <p>{ i.text }</p>
            </RenderLink>
          ))}

        </div>
      </div>
    </div>
  );
};

export default AnonymousTruckScann;
