import React, { useContext, useState } from 'react';
import { Avatar, Button, Box, FormControl, Select, CircularProgress } from '@material-ui/core';
import EditOutlined from '@material-ui/icons/EditOutlined';
import DeleteOutlineOutlined from '@material-ui/icons/DeleteOutlineOutlined';
import { useUpdateUserInfoMutation, useUpdateUserAccessLocationMutation, useReinviteEspectroUserMutation } from 'generated/graphql';
import ImageLinks from 'utils/ImageLinks';
import ProfileStatus from 'assets/icons/um-profile-status.svg';
import request from 'utils/request';
import { ReplayOutlined } from '@material-ui/icons';
import { toast, ToastOptions } from 'react-toastify';
import moment from 'moment';
import { connect } from 'react-redux';
import ConfirmationModal from 'components/Modal/ConfirmationModal';
import { DOCTOR_STATUS, TRANSLATIONS } from 'types/enums';
import { isEspectroEnabled } from 'features/Espectro/constants';
import _ from 'lodash';
import { useTranslation } from 'react-i18next';
import { UserManagementContext } from '../../context';

const Profile = (props: any) => {
  const { t } = useTranslation();
  const {
    isEditing,
    isLoading,
    setIsEditing,
    setIsLoading,
    originalUser,
    editingUser,
    accessLocationsIds,
    clearContext,
  } = useContext(UserManagementContext);
  const isUserNotOnboarded = (editingUser?.getIn(['profile', 'name']) as string)?.includes('NEW_ESPECTRO_USER');

  const [setUpdateUserInfo] = useUpdateUserInfoMutation();
  const [setUpdateUserAccessLocation] = useUpdateUserAccessLocationMutation();
  const profile = originalUser?.get('profile')?.toJS();
  const [status, setStatus] = useState('');
  const [showDeleteUserConfirmationModal, setShowDeleteUserConfirmationModal] = useState<boolean>(
    false,
  );
  const [deleteUserConfirmationModal, setDeleteUserConfirmationModal] = useState({
    isLoading: false,
    description: 'Do you want to delete this user?',
    okButtonText: t(TRANSLATIONS.DELETE),
  });

  const { getUserInfo, isAuthorisedForOWD } = props;

  const [
    reinviteUserMutation,
    {
      loading: isReinviteMutationLoading,
    },
  ] = useReinviteEspectroUserMutation({
    fetchPolicy: 'no-cache',
    onError: e => alert(JSON.stringify(e)),
    onCompleted: d => {
      if (d.reinviteEspectroUser?.isSuccess) {
        toast.success(t(TRANSLATIONS.INVITATION_SENT));
      } else {
        toast.error('Error occurred!');
      }
    },
  });

  const toastConfig = {
    position: 'top-right',
    autoClose: 4000,
    hideProgressBar: true,
    closeOnClick: true,
    pauseOnHover: false,
    draggable: false,
    progress: undefined,
  } as ToastOptions;

  function toggleDeleteConfirmationModel() {
    setShowDeleteUserConfirmationModal(prevState => !prevState);
  }

  const handleDeleteUser = async () => {
    try {
      setDeleteUserConfirmationModal(prevState => ({ ...prevState, isLoading: true }));
      await request.delete(`/api/v1/users/${profile?.slug}`);
      toast.success('User deleted successfully', toastConfig);
      clearContext();
      toggleDeleteConfirmationModel();
      await getUserInfo();
    } catch (e) {
      setDeleteUserConfirmationModal(prevState => ({
        ...prevState,
        description: 'Failed to delete user',
        okButtonText: 'Retry',
        isLoading: false,
      }));
    }
  };

  const handleUpdateUser = async () => {
    const editingUserProfileInfo = editingUser?.get('profile')?.toJS();
    const editingUserGeneralInfo = editingUser?.get('general')?.toJS();
    const editingUserPermissionsInfo = editingUser?.get('permissions')?.toJS();

    try {
      setIsLoading(true);
      if (editingUserProfileInfo?.id) {
        await setUpdateUserAccessLocation({
          variables: {
            locationIds: accessLocationsIds,
            userId: editingUserProfileInfo?.id,
          },
        });
      }

      const routines: any = [];

      if (editingUserPermissionsInfo?.routines) {
        Object.values(editingUserPermissionsInfo?.routines).forEach((item: any) => {
          if (item?.routines && item?.routines?.length) {
            const grantedRoutines = item?.routines?.filter((r: any) => r.granted);
            routines.push(...grantedRoutines);
          }
        });
      }

      await setUpdateUserInfo({
        variables: {
          id: editingUserProfileInfo?.id,
          general: {
            locationId: editingUserGeneralInfo?.distributionCenter?.id,
            position: editingUserGeneralInfo?.position,
          },
          permission: {
            routines,
            type: editingUserPermissionsInfo?.type?.type,
          },
          roleId: editingUserPermissionsInfo?.roleId,
          userInfo: {
            isAuthorisedForOWD,
          },
        },
      });

      await toast.success(t(TRANSLATIONS.USER_UPDATED_SUCCESSFULLY));
      if (profile?.distributionCenter?.type === 'FACTORY'
        && editingUserGeneralInfo?.distributionCenter?.type === 'DISTRIBUTION_CENTER') {
        toast.warning(t(TRANSLATIONS.UPDATED_USER_NOW_DOES_NOT_HAVE_ANY_ROLE));
      }
      setIsEditing(false);
      clearContext();
      await getUserInfo();
    } catch (e) {
      if (!editingUserGeneralInfo?.position) {
        toast.error('There was an error. The user needs a position to be assigned. You can assign it on the "General" tab.', toastConfig);
      } else if (!editingUserGeneralInfo?.distributionCenter?.id) {
        toast.error('There was an error. The user needs a location to be assigned. You can assign it on the "General" tab.', toastConfig);
      } else {
        toast.error(`An Error Occured. ${e}`, toastConfig);
      }
    } finally {
      setIsLoading(false);
    }
  };

  const handleUserStatus = async (slug: string, userStatus: string) => {
    const url = `/api/v1/users/${slug}/${userStatus}`;
    try {
      setIsLoading(true);
      if (userStatus === 'send-to-doctor') {
        await request.post(url, {});
      } else {
        await request.patch(url);
      }

      toast.success('User status updated successfully', toastConfig);
      clearContext();
      await getUserInfo();
    } catch (e) {
      toast.error(`User Status Updatation Failed ${e}`);
    } finally {
      setIsLoading(false);
    }
  };

  const handleStatusChange = (e: any) => {
    setStatus(e.target?.value);
    handleUserStatus(profile?.slug, e.target.value);
  };

  return (
    <Box>
      {showDeleteUserConfirmationModal && (
        <ConfirmationModal
          title={`${t(TRANSLATIONS.DELETE)} User`}
          description={deleteUserConfirmationModal.description}
          isOpen={showDeleteUserConfirmationModal}
          onOk={() => handleDeleteUser()}
          okButtonText={deleteUserConfirmationModal.okButtonText}
          okButtonIcon={ImageLinks.deleteIcon}
          cancelButtonText={t(TRANSLATIONS.CANCEL)}
          cancelButtonIcon={ImageLinks.leftArrowIcon}
          onCancel={toggleDeleteConfirmationModel}
          isOkButtonLoading={deleteUserConfirmationModal.isLoading}
          cancelButtonStyle={{ width: '110px' }}
          okButtonStyle={{ color: '#C00000', width: '106px' }}
        />
      )}
      <div className="profile-container">
        <div className="d-flex align-items-center">
          {profile?.picture ? (
            <Avatar className="profile-avatar" alt={profile?.name} src={profile?.picture} />
          ) : (
            <Avatar className="profile-avatar">
              {profile?.name
                ?.match(/(^\S\S?|\b\S)?/g)
                .join('')
                .match(/(^\S|\S$)?/g)
                .join('')
                .toUpperCase()}
            </Avatar>
          )}
          <div className="pl-2">
            <h3 className="profile-name">{isUserNotOnboarded ? t(TRANSLATIONS.INVITATION_SENT) : profile?.name}</h3>
            <h5 className="profile-position">{_.startCase(_.toLower(profile?.position))} </h5>
            <span className="profile-user-type">
              {profile?.employeeNumber ? 'Interno' : 'Externo'}
            </span>
          </div>
        </div>
        <div style={{ minWidth: '178px' }}>
          <Button
            variant="outlined"
            size="small"
            disabled={isLoading || !props.hasManagmentAccess}
            style={{ color: '#c00000' }}
            onClick={
              isEditing
                ? () => setIsEditing((s: boolean) => !s)
                : () => toggleDeleteConfirmationModel()
            }
          >
            {isEditing ? t(TRANSLATIONS.CANCEL) : <DeleteOutlineOutlined style={{ color: 'red' }} />}
          </Button>
          {
            !isUserNotOnboarded ? (
              <Button
                style={{ color: `${isEditing ? 'white' : '#2b323b'}` }}
                className="ml-2"
                color="primary"
                size="small"
                disabled={isLoading || !props.hasManagmentAccess}
                variant={isEditing ? 'contained' : 'outlined'}
                onClick={
                  isEditing
                    ? () => handleUpdateUser()
                    : () => {
                      setIsEditing((s: boolean) => !s);
                      setIsLoading(true);
                    }
                }
                startIcon={isEditing ? '' : <EditOutlined style={{ color: 'black' }} />}
              >
                {isEditing
                  ? t(TRANSLATIONS.SAVE) : isEspectroEnabled
                    ? 'Editar' : t(TRANSLATIONS.EDIT)}
              </Button>
            ) : (
              <Button
                style={{ color: `${isEditing ? 'white' : '#2b323b'}` }}
                className="ml-2"
                color="primary"
                size="small"
                disabled={isLoading || !props.hasManagmentAccess || isReinviteMutationLoading}
                variant={isEditing ? 'contained' : 'outlined'}
                onClick={() => {
                  reinviteUserMutation({
                    variables: {
                      userId: editingUser?.getIn(['profile', 'id']),
                    },
                  });
                }}
                startIcon={isReinviteMutationLoading
                  ? <CircularProgress color="inherit" size={18} />
                  : <ReplayOutlined style={{ color: 'black' }} />}
              >
                {t(TRANSLATIONS.REINVITE)}
              </Button>
            )
          }
        </div>
      </div>
      {isEditing ? (
        <div className="profile-status-container" style={{ border: '2px dotted #808080' }}>
          <div className="d-flex align-items-center py-1 pl-1">
            <img width="24px" src={ImageLinks.edit} alt="profile-status" />
            <span className="pl-2">Editando, al terminar da click en ”Guardar”.</span>
          </div>
        </div>
      ) : (
        <div className="profile-status-container">
          <div className="d-flex align-items-center">
            <img src={ProfileStatus} alt="profile-status" />
            <span className="pl-2">
              Activo desde el{' '}
              {profile?.createdAt ? moment(profile?.createdAt).format('Do MMMM YYYY') : ''}
            </span>
          </div>
          {profile?.doctorStatus === DOCTOR_STATUS.IN_TEST ? (
            <p className="greyColored">En pruebas...</p>
          ) : profile?.doctorStatus === DOCTOR_STATUS.QUARANTINE ? (
            <p className="greyColored">En cuarentena</p>
          ) : (
            !isEspectroEnabled
            && (
            <FormControl variant="outlined">
              <Select
                native
                className="select-status"
                value={status}
                disabled={isLoading}
                onChange={handleStatusChange}
                label={t(TRANSLATIONS.CHOOSE)}
              >
                <option value="" disabled>
                  {t(TRANSLATIONS.CHOOSE)}
                </option>
                {profile?.isBlocked ? (
                  <>
                    <option value="unblock">Unblock</option>
                    <option value="send-to-doctor">Medical Examination</option>
                  </>
                ) : (
                  <option value="block">Block</option>
                )}
              </Select>
            </FormControl>
            )
          )}
        </div>
      )}
    </Box>
  );
};

function mapStateToProps(state: any) {
  return {
    hasManagmentAccess: isEspectroEnabled ? true : state.getIn(['user', 'hasUserManagementAccess']),
  };
}

export default connect(mapStateToProps)(Profile);
