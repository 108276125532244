import React, { useCallback, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import ChartDropdown from 'components/Dropdown/ChartDropdown';
import BasicButton from 'components/Button/BasicButton';
import { selectCurrentAccesLevel } from 'store/selectors/distributionCenters.selectors';
import { selectSelectedLocationsSlug } from 'store/selectors/globalFilters.selectors';
import { generateQueryDates, routinesMonthSelectordropDownItems } from 'utils/dateHelpers';
import { SelectInfo } from 'rc-menu/lib/interface';
import LoadingSpinner from '../../../components/LoadingSpinner';
import ExpandableTable from '../../../components/ExpandableTable';
import { FILTER_ACCESS_LEVELS, TRANSLATIONS } from '../../../types/enums';
import useGetAnalyticsTraining, { FetchError } from './hooks/useGetAnalyticsTraining';

export default function AnalyticsTraining() {
  const accessLevel = useSelector(selectCurrentAccesLevel);
  const { t } = useTranslation();
  const selectedLocations = useSelector(selectSelectedLocationsSlug);
  const [dropDownOpen, setDropDownOpen] = useState(false);

  const dropDownItems = routinesMonthSelectordropDownItems(true);

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [selectedDropDownItem, _setSelectedDropDownItem] = useState(dropDownItems[0]);
  /**
  const [dropDownOpen, setDropDownOpen] = useState(false);
  const handleDropDownChange = ({ key }: SelectInfo) => {
    setSelectedDropDownItem(dropDownItems.find(
      item => item.slug === key,
    ) || dropDownItems[0]);
    setDropDownOpen(false);
  };
  */
  const usersTraining = useGetAnalyticsTraining({
    selectedTimeRange: selectedDropDownItem,
  });

  const handleDropDownChange = ({ key }: SelectInfo) => {
    _setSelectedDropDownItem(dropDownItems.find(item => item.slug === key) || dropDownItems[0]);
    setDropDownOpen(false);
  };

  const downloadTrainingsExcel = useCallback(() => {
    /*
     * Showing Routines Data after 1st jan 2021. so it is hard coded
     */
    const params = generateQueryDates(
      selectedDropDownItem.content.startWeek,
      selectedDropDownItem.content.lastDayOfLastWeek,
    ) as any;

    if (accessLevel === FILTER_ACCESS_LEVELS.DISTRIBUTION_CENTER) {
      params[FILTER_ACCESS_LEVELS.SUBZONE] = selectedLocations.subzone;
    }
    if (accessLevel === FILTER_ACCESS_LEVELS.DISTRIBUTION_CENTER && selectedLocations.location) {
      params[FILTER_ACCESS_LEVELS.DISTRIBUTION_CENTER] = selectedLocations.location;
    }
    if (accessLevel === FILTER_ACCESS_LEVELS.SUBZONE) {
      params[FILTER_ACCESS_LEVELS.ZONE] = selectedLocations.zone;
    }
    if (accessLevel === FILTER_ACCESS_LEVELS.ZONE) {
      params[FILTER_ACCESS_LEVELS.BUSINESS_UNIT] = selectedLocations.businessUnit;
    }
    const qs = Object.keys(params)
      .map((key: string) => `${encodeURIComponent(key)}=${encodeURIComponent(params[key])}`)
      .join('&');
    window.open(`/api/v1/get-trainings-excel?${qs}`, '_blank');
  }, [selectedLocations, accessLevel, selectedDropDownItem]);

  const rowElements = (trainingMap: any) => ({
    courses: trainingMap.get('name'),
    usersToTrain: trainingMap.get('usersToTrain').toLocaleString(),
    usersTrained: trainingMap.get('usersTrained').toLocaleString(),
    percentage: `${(
      (trainingMap.get('usersTrained') / trainingMap.get('usersToTrain') || 0) * 100
    ).toFixed(1)}%`,
  });

  const data = useMemo(() => {
    return [...(usersTraining.result || [])].map((training: any) => ({
      ...rowElements(training),
      subRows: [...training.get('areas')].map((userZone: any) => ({
        ...rowElements(userZone),
        subRows: [...userZone.get('workPosition')].map((positions: any) => (accessLevel === FILTER_ACCESS_LEVELS.DISTRIBUTION_CENTER && positions.get('users')
          ? {
            ...rowElements(positions),
            subRows: [...positions.get('users')].map((user: any) => ({
              ...rowElements(user),
            })),
          }
          : {
            ...rowElements(positions),
          })),
      })),
    }));
  }, [accessLevel, usersTraining.result]);

  const columns = useMemo(
    () => [
      {
        Header: t(TRANSLATIONS.COURSES),
        accessor: 'courses',
        getProps: (state: any) => {
          let marginLeft;
          if (state.row.depth === 1) marginLeft = 10;
          if (state.row.depth === 2) marginLeft = 20;
          if (state.row.depth) {
            return {
              style: {
                fontWeight: 'bold',
                paddingLeft: marginLeft,
              },
            };
          }
          return {};
        },
      },
      {
        Header: t(TRANSLATIONS.HC_TO_BE_TRAINED),
        accessor: 'usersToTrain',
      },
      {
        Header: t(TRANSLATIONS.TRAINED),
        accessor: 'usersTrained',
      },
      {
        Header: `% ${t(TRANSLATIONS.TRAINED).toLowerCase()}`,
        accessor: 'percentage',
        getProps: (state: any) => {
          const color = () => {
            // eslint-disable-next-line radix
            const percentage = Math.round(parseInt(state.value.split('%')[0]));
            if (percentage <= 70) {
              return '#C00000';
            }
            if (percentage > 70 && percentage < 90) {
              return '#404040';
            }
            return '#70AD47';
          };
          return {
            style: {
              color: color(),
            },
          };
        },
      },
    ],
    [t],
  ) as any;

  if (usersTraining.loading || usersTraining.error?.message === FetchError.WAITING) {
    return (
      <div className="col-12 card routines-home__card">
        <LoadingSpinner height={300} />
      </div>
    );
  }

  return (
    <div>
      <div className="card">
        <ExpandableTable {...{ columns, data, isExpandable: true }} />
        {/* <div className="row pt-5 pb-2 pl-3 pr-3">
          <ChartDropdown
            onChange={handleDropDownChange}
            selectedDropDownItem={selectedDropDownItem}
            dropDownItems={dropDownItems}
            dropDownOpen={dropDownOpen}
            setDropDownOpen={setDropDownOpen}
            labelClassName="label-default"
          />
        </div> */}
      </div>
      <div
        style={{
          flexDirection: 'row',
          display: 'flex',
          justifyContent: 'flex-start',
          marginLeft: 20,
          paddingBottom: 100,
        }}
      >
        <div>
          <ChartDropdown
            onChange={handleDropDownChange}
            selectedDropDownItem={selectedDropDownItem}
            dropDownItems={dropDownItems}
            dropDownOpen={dropDownOpen}
            setDropDownOpen={setDropDownOpen}
            labelClassName="label-default"
          />
        </div>
        <BasicButton
          text="Download Trainings Excel"
          // @ts-ignore
          onClick={downloadTrainingsExcel}
          style={{ color: '#70AD47', padding: '7px 8px' }}
          background="#F0F0F0"
        />
      </div>
    </div>
  );
}
