import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
// @ts-ignore
import ReactTable from 'react-table-legacy';
import request from 'utils/request';
import EmptyState from 'components/EmptyState/EmptyState';
import LoadingSpinner from 'components/LoadingSpinner';
import { TRANSLATIONS } from 'types/enums';

interface responseDataType {
  gro: string;
  region: string;
  routeTotals: number;
  routeSif: number;
  sif_percent: number;
}
interface $RowType {
  value: string;
}

const SifRoutesTable = (props: any) => {
  const { searchField } = props;
  const [data, setData] = useState<responseDataType[]>();
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const { t } = useTranslation();

  useEffect(() => {
    const getSifGroData = async () => {
      try {
        setIsLoading(true);
        const res = await request.get('/api/v1/sif-by-gro');
        if (searchField) {
          const filteredData = res.filter((regionalManager: responseDataType) => {
            return (
              regionalManager.gro.toLowerCase().includes(searchField.toLowerCase())
              || regionalManager.region.toLowerCase().includes(searchField.toLowerCase())
            );
          });
          setData(filteredData);
        } else {
          setData(res);
        }
      } catch (error) {
        console.error(error);
      } finally {
        setIsLoading(false);
      }
    };
    getSifGroData();
  }, [searchField]);

  const columns = useMemo(
    () => [
      {
        Header: 'GRO',
        minWidth: 110,
        id: 'manager',
        accessor: 'gro',
        Cell: (row: $RowType) => <p className="text-left font-bold margin-left-25">{row.value}</p>,
      },
      {
        Header: t(TRANSLATIONS.REGION),
        minWidth: 110,
        id: 'region',
        accessor: 'region',
        Cell: (row: $RowType) => <p className="text-left font-bold margin-left-25">{row.value}</p>,
      },
      {
        Header: 'Rutas Totales',
        id: 'routeTotals',
        accessor: 'routeTotals',
        minWidth: 95,
        Cell: (row: $RowType) => (
          <p className="text-left font-bold margin-left-25">
            {Number(row.value || 0).toLocaleString()}
          </p>
        ),
      },
      {
        Header: 'Rutas SIF',
        id: 'routeSif',
        accessor: 'routeSif',
        Cell: (row: $RowType) => (
          <p className="text-left font-bold margin-left-25">
            {row.value ? Number(row.value).toLocaleString() : 0}
          </p>
        ),
      },
      {
        Header: '% SIF',
        id: 'sif_percent',
        accessor: 'sif_percent',
        Cell: (row: $RowType) => (
          <p className="text-left font-bold margin-left-25">{`${row.value}`}</p>
        ),
      },
    ],
    [t],
  );

  if (isLoading) return <LoadingSpinner height={231} />;

  return (
    <div className="table-by-regional-manager">
      {data?.length ? (
        <ReactTable
          data={data}
          columns={columns}
          showPagination={false}
          defaultPageSize={data?.length}
          className="-striped max-height"
          NoDataComponent={() => <EmptyState />}
        />
      ) : (
        <EmptyState />
      )}
    </div>
  );
};

export default SifRoutesTable;
