import React from 'react';
import { useTranslation } from 'react-i18next';
import { TRANSLATIONS } from 'types/enums';

import IncidentsPieChart from './IncidentsPieChart';
import TotalIncidentsChart from './TotalIncidentsChart';
import IncidentsBarChart from './IncidentsBarChart';
import IncidentsByRiskTypeBarChart from './IncidentsByRiskTypeBarChart';
import IncidentsByWorkLocationChart from './IncidentsByWorkLocationChart';

type Props = {
};

export default function AnalyticsIncidentsWrapper() {
  const { t } = useTranslation();
  return (
    <div className="container-fluid">
      <div className="row" style={{ marginBottom: -14 }}>
        <div className="col-12 px-2 mb-3">
          <div
            style={{
              padding: 15,
              backgroundColor: 'rgb(242, 242, 242)',
              borderRadius: 10,
            }}
          >
            <p
              style={{
                fontSize: 13,
              }}
            >
              { t(TRANSLATIONS.SECURE_CONDITION_DISCLAIMER) }
            </p>
          </div>
        </div>
        <div className="col-8 pl-2">
          <div className="card mb-3 pt-15 px-0">
            <IncidentsPieChart />
          </div>
        </div>
        <div className="col-4 pl-2">
          <div className="card mb-3">
            <TotalIncidentsChart />
          </div>
        </div>
        <div className="col-12 pl-2">
          <div className="card mb-3 pt-15 px-0">
            <IncidentsBarChart />
          </div>
        </div>
        <div className="col-6 pl-2">
          <div className="card mb-3 pt-15 px-0">
            <IncidentsByRiskTypeBarChart />
          </div>
        </div>
        <div className="col-6 pl-2">
          <div className="card mb-3 pt-15 px-0">
            <IncidentsByWorkLocationChart />
          </div>
        </div>
      </div>
    </div>
  );
}
