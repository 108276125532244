import React, { useState } from 'react';
import ImageLinks from 'utils/ImageLinks';
import { TRANSLATIONS } from 'types/enums';
import { useTranslation } from 'react-i18next';
import { capitalizeFirst } from 'utils/stringHelpers';
import SafetyTrainingTable from './SafetyTrainingTable';
import './SafetyTraining.scss';
import AnalyticsTraining from '../AnalyticsTraining';
import SafetyTrainingChart2 from './SafetyTrainingChart2';

const SafetyTraining = () => {
  const { t } = useTranslation();
  const [searchField, setSeachField] = useState<string>('');
  const handleSearchField = (event: { target: { value: string } }) => {
    setSeachField(event.target.value);
  };
  return (
    <div className="container-fluid overview-wrapper">
      <h1>Capacitaciones</h1>
      <div className="col-12 card routines-home__card">
        <div className="training-chart-label">
          % Capactación
        </div>
        <SafetyTrainingChart2 />
      </div>

      <div>
        <h2> Por Región</h2>
      </div>
      <div className="margin8 safety-training safety-training-por-region-buscar-container">
        <div className="safety-training-por-region-buscar">
          <img className="margin8" src={ImageLinks.searchIcon} alt="search" />
          <input
            type="search"
            placeholder="Buscar"
            onChange={handleSearchField}
          />
        </div>
      </div>
      <div>
        <div className="margin7">
          <div className="col-12 card routines-home__card">
            <SafetyTrainingTable searchField={searchField} />
          </div>
        </div>
      </div>
      <h2>{capitalizeFirst(t(TRANSLATIONS.TRAININGS_PERCENTAGE))}</h2>
      <AnalyticsTraining />
    </div>
  );
};

export default SafetyTraining;
