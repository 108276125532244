import React from 'react';
import './UserRegisterSuccess.scss';
import Modal from '../../../components/Modal';
import greenTick from '../../../assets/icons/check-green.svg';
import {TRANSLATIONS} from "../../../types/enums";
import {useTranslation} from "react-i18next";

type Props = {
  pin: number;
  name: string;
  position: string;
  permissionsInString: string;
  close?: ()=>void;
};

const UserRegisterSuccess = (props: Props) => {
  const { pin, name, position, permissionsInString, close } = props;
  const { t } = useTranslation();
  return (
    <Modal
      onOverlayClick={() => {
        if (close) {
          close();
        }
      }}
      modalContainerStyles={{
        width: '40vw',
        left: '30vw',
        height: '50vh',
        top: '25vh',
      }}
    >
      <div className="mainContainer">
        <img
          src={greenTick}
          style={{
            width: '50px',
            height: '50px',
          }}
          alt="Success"
        />
        <span>{t(TRANSLATIONS.PIN_CREATED_SUCCESSFULLY)}</span>
        <h1>{`${pin}`}</h1>
        <div className="detailsContainer">
          <div className="labelValueContainer">
            <span className="labelForPinDetails">{t(TRANSLATIONS.NAME)}(s)</span>
            <span className="valueForPinDetails">{name}</span>
          </div>

          <div className="labelValueContainer">
            <span className="labelForPinDetails">{t(TRANSLATIONS.POSITION)}</span>
            <span className="valueForPinDetails">{position}</span>
          </div>

          <div
            className="labelValueContainer"
            style={{
              marginTop: '20px',
            }}
          >
            <span className="labelForPinDetails">{t(TRANSLATIONS.PERMISSIONS)}</span>
            <span className="valueForPinDetails">{permissionsInString}</span>
          </div>

        </div>
      </div>
    </Modal>

  );
};

export default UserRegisterSuccess;
