export const selectGlobalFilters = (state: any) => state.get('globalFilters');

export const selectLocationsToAccess = (state: any) => {
  return (
    state.getIn(['globalFilters', 'locationsToAccess'])?.toArray().map((v: any) => v.toString()) || []
  );
};

export const selectSelectedLocationsSlug = (state: any) => {
  return {
    businessUnit: state.getIn(['globalFilters', 'businessUnit', 'slug']),
    zone: state.getIn(['globalFilters', 'zone', 'slug']),
    subzone: state.getIn(['globalFilters', 'subzone', 'slug']),
    location: state.getIn(['globalFilters', 'location', 'slug']),
    cluster: state.getIn(['globalFilters', 'cluster', 'slug']),
  };
};

export const selectSelectedLocation = (state: any) => {
  return {
    locationName: state.getIn(['globalFilters', 'location', 'name']),
    locationSlug: state.getIn(['globalFilters', 'location', 'slug']),
  };
};

export const selectSelectedBusinessUnit = (state: any) => {
  return {
    name: state.getIn(['globalFilters', 'businessUnit', 'name']),
    slug: state.getIn(['globalFilters', 'businessUnit', 'slug']),
  };
};

export const selectAllClusters = (state: any) => {
  return state.getIn(['globalFilters', 'allClusters'])?.toJS();
};
