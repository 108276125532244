import React, { useCallback, useEffect, useState } from 'react';
import ChecklistRendererComponent from 'components/Espectro/ChecklistRenderer';
import { isMobile } from 'react-device-detect';
import { Backdrop, Button, Fade, Modal, Drawer } from '@material-ui/core';
import './ChecklistRenderer.scss';
import { ChecklistAnswers, ChecklistBit, ChecklistBitTypes, ChecklistItem, ChecklistProps, ChecklistSection, bitsShouldNotBeConsideredTowardsProgress } from 'espectro-utils/checklistRendererUtils/types';
import { RenderBitInitializer, mergeChecklistWithAnswers, shouldRenderItem } from 'espectro-utils/checklistRendererUtils/ChecklistRendererUtils';
import { useTranslation } from 'react-i18next';
import LoadingSpinner from 'components/LoadingSpinner/LoadingSpinner';
import ActionButton from 'components/Espectro/Buttons/Action/Action';
import { COLOR_CODE, IMAGE_SAVING_STATUS, TRANSLATIONS } from 'types/enums';
import { useParams } from 'react-router-dom';
import { format } from 'date-fns';
import { useGetRoutineAvailableFromShareableTokenQuery, useSaveRoutinesAnonymouslyMutation } from 'generated/graphql';
import { cloneDeep } from 'lodash';
import ImageLinks from 'utils/ImageLinks';
import NestedChecklist from 'components/Espectro/NestedChecklist/NestedChecklist';
import Overlay from 'components/Espectro/Overlay';
import uploadImagesIfAny from 'utils/uploadImagesHelper';
import ConfirmationModalSharableChecklist from 'components/Modal/ConfirmationModal/ConfirmationModalSharableChecklist';
import { requestImageUploadPermission, uploadObjectImage } from 'utils/ImageHelper';
import { ReactComponent as EspectroFullLogo } from '../../../assets/icons/espectro-logo-circle.svg';
import { getChecklistHash, getSectionAndItemIndex } from '../utils';
import { showIfOperators } from '../types';

export default function ChecklistRenderer() {
  const { t } = useTranslation();
  const [selectedNestedChecklistAnswers,
    setSelectedNestedChecklistAnswers] = useState<ChecklistAnswers | null>(null);
  const [answers, setAnswers] = useState<ChecklistAnswers>([[]]);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [totalQuestions, setTotalQuestions] = useState(0);
  const [progressScore, setProgressScore] = useState(0);
  const [isSuccess, setIsSuccess] = useState<boolean>(false);
  const [checklistIndex, setChecklistIndex] = useState<number>(0);
  const [checklistItemIndex, setChecklistItemIndex] = useState<number>(0);
  const [errorMessage, setErrorMessage] = useState(null);
  const [imageSavingStatus, setImageSavingStatus] = useState('');
  const [finalUpdatedChecklist, setFinalUpdatedChecklist] = useState<ChecklistSection[]>();
  let nestedImagesCount = 0;
  let uploadedNestedImagesCount = 0;
  let continueNestedSave: boolean = true;
  const [imageNestedSavingStatus, setImageNestedSavingStatus] = useState('');
  const [
    selectedNestedChecklistHash, setSelectedNestedChecklistHash] = useState<string | null>(null);
  const { token }: { token: string } = useParams();
  const [nestedChecklistAnswersMap,
    setNestedChecklistAnswersMap] = useState<{[key: string]: ChecklistAnswers}>({});
  const [checklist, setChecklist] = useState<ChecklistSection[]>([]);
  const [nestedChecklist, setNestedChecklist] = useState<ChecklistSection[]>([]);
  const [openSlider, setOpenSlider] = useState(false);
  const [sectionalScore, setSectionalScore] = useState(Array(checklist.length).fill(0));
  const [isOpen, setIsOpen] = useState(false);
  const [nestedSecIndex, setNestedSectionIndex] = useState(0);
  const [nestedItemIndex, setNestedItemIndex] = useState(0);
  const [notesInput, setNotesInput] = useState('');
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [showConfirmationModal, setShowConfirmationModal] = useState<boolean>(false);
  const [selectedSection, setSelectedSection] = useState<number>(0);
  const [selectedSectionItem, setSelectedSectionItem] = useState<number>(0);
  const [isQuestionTitleInputFocused, setIsQuestionTitleInputFocused] = useState<boolean>(false);
  const [previousId, setPreviousId] = useState<string>('');
  const [notesList, setNotesList] = useState<{
    [key: string]: {
      [key: string]: {
        [key: string]: string;
      };
    }
  }>();
  const [currentNestedChecklistNotes, setCurrentNestedChecklistNotes] = useState<{
    [key: string]: string;
  }>();
  const [width, setWidth] = useState<number>(window.innerWidth);

  function handleWindowSizeChange() {
    setWidth(window.innerWidth);
  }
  useEffect(() => {
    window.addEventListener('resize', handleWindowSizeChange);
    return () => {
      window.removeEventListener('resize', handleWindowSizeChange);
    };
  }, []);

  const {
    data,
    loading: isGetRoutineQueryLoading,
  } = useGetRoutineAvailableFromShareableTokenQuery({
    variables: {
      shareableToken: token,
    },
    onCompleted: d => {
      const checklistJSON = d.getRoutineAvailableFromShareableToken?.content.checklist;
      const initialAnswers = checklistJSON.map((s: ChecklistSection) => s.items.map(
        (i: ChecklistItem) => i.bits.map(q => RenderBitInitializer(q)),
      ));
      const mergedChecklist = mergeChecklistWithAnswers(checklistJSON,
        initialAnswers);
      setChecklist(mergedChecklist);
      setAnswers(initialAnswers);
    },
    onError: e => {
      console.log('Error--', e.message);
      // @ts-ignore
      setErrorMessage('Invalid token. Please check your shareable token.');
    },
  });

  const resetCurrentNestedChecklistNotesList = () => {
    setCurrentNestedChecklistNotes(undefined);
  };

  const [saveRoutineAnswerAnonymously] = useSaveRoutinesAnonymouslyMutation({
    onCompleted: () => {
      setIsSuccess(true);
      setIsSubmitting(false);
    },
    onError: e => {
      continueNestedSave = false;
      console.log('ON ERROR OF SAVE', { e });
    },
  });

  const checkIfSpecificQuestionAnswered = useCallback((
    sectionIndex: number,
    itemIndex: number,
    bitIndex: number,
    operator: string,
    value: any,
  ) => {
    if (operator === showIfOperators.equals) {
      const answer = checklist[sectionIndex]?.items[itemIndex]?.bits[bitIndex]?.answer;
      if (answer === value
        || (typeof answer === 'object'
          && checklist[sectionIndex]?.items[itemIndex]?.bits[bitIndex]
            .type !== ChecklistBitTypes.DATE
          && answer?.includes(value)
        )
      ) return true;
      if ((typeof answer === 'object'
          && checklist[sectionIndex]?.items[itemIndex]?.bits[bitIndex]
            .type !== ChecklistBitTypes.DATE
      )) {
        const typeOfQuestionWithCondition = checklist[sectionIndex].items[itemIndex].bits[0].type;
        const isQuestionOfTypeDate = typeOfQuestionWithCondition
          ? typeOfQuestionWithCondition === ChecklistBitTypes.DATE : false;
        const answeredItemDate = answer ? new Date(answer) : null;
        if (isQuestionOfTypeDate && answeredItemDate !== null) {
          const dayNumber = answeredItemDate.getDay();
          return dayNumber === value;
        }
      }
      return false;
    }
  }, [checklist]);

  const checkShowIf = useCallback((item: ChecklistItem) => {
    if (!item.showIf) return true;

    const { key } = item.showIf;
    const { sectionIndex: sIdx, itemIndex: iIdx } = getSectionAndItemIndex(key);

    if (checkIfSpecificQuestionAnswered(
      sIdx,
      iIdx,
      0,
      item.showIf.operator,
      item.showIf.value,
    )) {
      return true;
    }
    return false;
  }, [checkIfSpecificQuestionAnswered]);

  const countTotalQuestions = useCallback(() => {
    let total = 0;
    checklist.forEach((c: any) => {
      c.items.forEach((item: ChecklistItem) => {
        if (!bitsShouldNotBeConsideredTowardsProgress.includes(item?.bits?.[0]?.type)) {
          if (checkShowIf(item)) total += 1;
        }
      });
    });

    setTotalQuestions(total);
  }, [checkShowIf, checklist]);

  const countTotalAnsweredQuestions = useCallback(() => {
    let total = 0;
    checklist.forEach((s: any, sIndex: number) => {
      answers[sIndex].forEach(answer => {
        if (answer[0] !== null && answer[0] !== '') total += 1;
      });
    });

    setProgressScore(total);
  }, [answers, checklist]);

  const findSectionalScore = useCallback((arr:any) => {
    setSectionalScore(arr);
  }, []);

  useEffect(() => {
    if (checklist.length > 0) {
      countTotalQuestions();
    }
  }, [checklist, countTotalQuestions]);

  useEffect(() => {
    countTotalQuestions();
    countTotalAnsweredQuestions();
  }, [countTotalAnsweredQuestions, countTotalQuestions, sectionalScore]);

  useEffect(() => {
    if (imageNestedSavingStatus === IMAGE_SAVING_STATUS.IN_PROGRESS) setIsSubmitting(true);
    if (imageNestedSavingStatus === IMAGE_SAVING_STATUS.DONE && continueNestedSave) {
      setIsSubmitting(true);
      setTimeout(async () => {
        saveRoutineAnswerAnonymously({
          variables: {
            args: {
              answers: finalUpdatedChecklist,
              shareableToken: token,
              completedAt: format(new Date(), "yyyy-MM-dd'T'HH:mm:ss"),
            },
          },
        });
        setSelectedNestedChecklistHash(null);
        setImageNestedSavingStatus('');
      }, 2000);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [imageNestedSavingStatus]);

  const uploadToS3 = async (imageUrl: string) => {
    const s3Target = 'ROUTINES_AVAILABLE';
    const response = await requestImageUploadPermission('jpeg', s3Target);
    setImageNestedSavingStatus(IMAGE_SAVING_STATUS.IN_PROGRESS);
    if (response?.data.preSignedUrl) {
      const binaryData = [];
      binaryData.push(imageUrl);
      const imgUrl = window.URL.createObjectURL(new Blob(binaryData, { type: 'image/jpg' }));
      const url = await uploadObjectImage(response.data.preSignedUrl, imgUrl);
      uploadedNestedImagesCount += 1;

      if (nestedImagesCount === uploadedNestedImagesCount) {
        setImageNestedSavingStatus(IMAGE_SAVING_STATUS.DONE);
      }
      return url;
    }

    setImageNestedSavingStatus(IMAGE_SAVING_STATUS.DONE);
  };

  const iterateChecklist = (tempChecklist: ChecklistSection) => {
    tempChecklist.items.forEach((item: any) => {
      item.bits.forEach(async (bit: any) => {
        if (bit.type === 'IMAGE_PICKER') {
          if (bit.answer && bit.answer.length > 0) {
            bit.answer.forEach(async (img: string, imgIndex: number) => {
              if (img) {
                nestedImagesCount += 1;
                const newUrl = await uploadToS3(img);
                if (newUrl) {
                  bit.answer[imgIndex] = newUrl;
                }
              }
            });
          }
        }

        if (bit.type === 'DRIBBLE') {
          if (bit.answer) {
            nestedImagesCount += 1;
            const newUrl = await uploadToS3(bit.answer);
            if (newUrl) {
              bit.answer = newUrl;
            }
          }
        }

        if (bit.type === 'SIGNATURE') {
          if (bit.answer) {
            nestedImagesCount += 1;
            const newUrl = await uploadToS3(bit.answer);
            if (newUrl) {
              bit.answer = newUrl;
            }
          }
        }
      });
    });
  };

  const uploadImagesNested = () => {
    finalUpdatedChecklist?.forEach((section: ChecklistSection) => {
      section.items.forEach((item: ChecklistItem) => {
        if (item.bits[0].answer && item.bits[0].type === ChecklistBitTypes.NESTED_CHECKLIST) {
          if (Array.isArray(item.bits[0].answer)) {
            item.bits[0].answer.forEach((answeredSections: any) => {
              if (Array.isArray(answeredSections)) {
                answeredSections.forEach((answeredSection: any) => {
                  iterateChecklist(answeredSection);
                });
              }
            });
          }
        }
      });
    });
    if (nestedImagesCount === 0) setImageNestedSavingStatus(IMAGE_SAVING_STATUS.DONE);
  };

  useEffect(() => {
    if (imageSavingStatus === IMAGE_SAVING_STATUS.IN_PROGRESS) setIsSubmitting(true);
    if (imageSavingStatus === IMAGE_SAVING_STATUS.DONE) {
      setIsSubmitting(true);
      setTimeout(async () => {
        if (Object.keys(nestedChecklistAnswersMap).length > 0) {
          uploadImagesNested();
        } else {
          saveRoutineAnswerAnonymously({
            variables: {
              args: {
                answers: finalUpdatedChecklist,
                shareableToken: token,
                completedAt: format(new Date(), "yyyy-MM-dd'T'HH:mm:ss"),
              },
            },
          });
          setSelectedNestedChecklistHash(null);
        }
        setImageSavingStatus('');
      }, 2000);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [imageSavingStatus]);

  useEffect(() => {
    if (finalUpdatedChecklist && finalUpdatedChecklist.length > 0) {
      uploadImagesIfAny(finalUpdatedChecklist, setImageSavingStatus);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [finalUpdatedChecklist]);

  const mergeNotesToChecklist = (mergedChecklist: ChecklistSection[]): ChecklistSection[] => {
    const parentQuestionNodes = Object.keys(notesList ?? {});
    parentQuestionNodes.forEach(node => {
      const { sectionIndex, itemIndex } = getSectionAndItemIndex(node);
      const nestedChecklistNodes = Object.keys(notesList![node] ?? {});

      nestedChecklistNodes.forEach((nestedChecklistNode: string) => {
        const { bitIndex: count } = getSectionAndItemIndex(nestedChecklistNode);

        const targetChecklistAnswers = mergedChecklist[sectionIndex].items[itemIndex].bits[0]
          .answer;
        const targetChecklistAnswer = targetChecklistAnswers[count - 1];

        const questionNoteNodes = Object.keys(notesList![node][nestedChecklistNode] ?? {});

        questionNoteNodes.forEach(questionNode => {
          const {
            itemIndex: nestedItmIdx,
            sectionIndex: nestedSecIdx,
          } = getSectionAndItemIndex(questionNode);
          targetChecklistAnswer[nestedSecIdx].items[nestedItmIdx].bits[0]
            .additionalFeatures.note = notesList![node][nestedChecklistNode][questionNode] ?? '';
        });
        targetChecklistAnswers[count - 1] = targetChecklistAnswer;
        mergedChecklist[sectionIndex].items[itemIndex].bits[0].answer = targetChecklistAnswers;
      });
    });
    return mergedChecklist;
  };

  const checkIfRequiredQuestionAnswered = (
    index: number,
    itemIndex: number,
    finalUpdatedChecklistList: any,
  ) => {
    if (finalUpdatedChecklistList[index].items[itemIndex]
      .bits[0].type === ChecklistBitTypes.NESTED_CHECKLIST) {
      let returnValue = false;
      /**
       * Check whether the nexted checklist answer is present
       * or not and also the count should be greater than 0
       * if present return true else return false
       */
      Object.keys(nestedChecklistAnswersMap).forEach(key => {
        const {
          sectionIndex, itemIndex: itemIndexFromHash, bitIndex, count,
        } = getSectionAndItemIndex(key);
        if (
          index === sectionIndex
          && itemIndex === itemIndexFromHash
          && bitIndex === 0
          && count > 0
        ) {
          returnValue = true;
        }
      });
      return returnValue;
    }
    const { answer } = finalUpdatedChecklistList[index].items[itemIndex].bits[0];
    if (typeof answer === 'object' && answer.length === 0) return false;
    if (answer || answer === 0) return true;
    return false;
  };

  const saveNestedChecklistAnswersToLocalMap = () => {
    if (selectedNestedChecklistHash) {
      const copyChecklist = cloneDeep(checklist);

      const { count } = getSectionAndItemIndex(selectedNestedChecklistHash || '');
      const matchingKeyForNestedChecklistNotes = `checklist[${checklistIndex}]_item[${checklistItemIndex}]`;
      let notesListClone = cloneDeep(notesList);
      const specificIterationHash = `section[${checklistIndex}]_item[${checklistItemIndex}]_count[${count}]`;
      if (Object.keys(notesListClone ?? {}).includes(matchingKeyForNestedChecklistNotes)) {
        notesListClone![matchingKeyForNestedChecklistNotes]![specificIterationHash] = {
          ...notesListClone![matchingKeyForNestedChecklistNotes]![specificIterationHash],
          ...currentNestedChecklistNotes,
        };
      } else {
        notesListClone = {
          ...notesListClone,
          [matchingKeyForNestedChecklistNotes]: {
            [specificIterationHash]: currentNestedChecklistNotes ?? {},
          },
        };
      }
      setNotesList(notesListClone);
      resetCurrentNestedChecklistNotesList();

      const selectedNestedChecklist = copyChecklist[checklistIndex]
      ?.items[checklistItemIndex]?.bits[0]?.props?.content?.checklist || [];
      if (selectedNestedChecklist && selectedNestedChecklistAnswers) {
        let totalRequired = 0;
        let totalRequiredAnswered = 0;

        if (selectedNestedChecklist.length > 0) {
          // MERGING SELECTED NESTED CHECKLIST WITH IT'S ANSWERS
          const mergedNestedChecklist = mergeChecklistWithAnswers(selectedNestedChecklist,
            selectedNestedChecklistAnswers);

          // CHECKING IF REQUIRED QUESTIONS ANSWERED OR NOT
          mergedNestedChecklist.forEach((c: any, cIndex: number) => {
            c.items.forEach((item: ChecklistItem, itemIndex: number) => {
              if (item.required) {
                if (item.showIf) {
                  // eslint-disable-next-line max-len
                  const isShowIfSatisfied = shouldRenderItem(item.showIf, answers, cIndex, itemIndex, 0);
                  if (isShowIfSatisfied) {
                    totalRequired += 1;
                  }
                } else {
                  totalRequired += 1;
                }
                if (checkIfRequiredQuestionAnswered(cIndex, itemIndex, mergedNestedChecklist)) {
                  totalRequiredAnswered += 1;
                }
              }
            });
          });
        }

        // EXECUTE FURTHER IF REQUIRED QUESTIONS ARE ANSWERED
        if (totalRequired === totalRequiredAnswered) {
          const { completedNames } = copyChecklist[checklistIndex].items[checklistItemIndex];
          // delete "completedNames" property at the time of final response submit
          if (!completedNames || completedNames.length === 0) {
            copyChecklist[checklistIndex].items[checklistItemIndex].completedNames = [{
              name: copyChecklist[checklistIndex].items[checklistItemIndex].title,
              id: 1,
            }];
          } else {
            copyChecklist[checklistIndex].items[
              checklistItemIndex].completedNames = [
              ...checklist[checklistIndex].items[
                checklistItemIndex].completedNames, {
                name: checklist[checklistIndex].items[checklistItemIndex].title,
                id: completedNames[completedNames.length - 1].id + 1,
              }];
          }
          setChecklist(copyChecklist);
          const copy = { ...nestedChecklistAnswersMap };
          copy[selectedNestedChecklistHash] = selectedNestedChecklistAnswers as ChecklistAnswers;
          setNestedChecklistAnswersMap(copy);
          setOpenSlider(false);
          setSelectedNestedChecklistHash(null);
          setSelectedNestedChecklistAnswers(null);
        } else {
          setShowConfirmationModal(prevState => !prevState);
          setIsSubmitting(false);
        }
      }
    }
  };

  const submitResponse = () => {
    const finalUpdatedChecklistList = mergeChecklistWithAnswers(
      checklist, answers as any[][][], nestedChecklistAnswersMap,
    );
    let totalRequired = 0;
    let totalRequiredAnswered = 0;
    const nestedChecklists: any = [];

    finalUpdatedChecklistList.forEach((c: any, cIndex: number) => {
      c.items.forEach((item: ChecklistItem, itemIndex: number) => {
        if (item.required && item.bits[0].type !== ChecklistBitTypes.ACK) {
          if (item.showIf) {
            const isShowIfSatisfied = shouldRenderItem(item.showIf, answers, cIndex, itemIndex, 0);
            if (isShowIfSatisfied) {
              totalRequired += 1;
            }
          } else {
            totalRequired += 1;
          }
          if (checkIfRequiredQuestionAnswered(cIndex, itemIndex, finalUpdatedChecklistList)) {
            totalRequiredAnswered += 1;
          }
        }

        if (item.bits[0].type === 'NESTED_CHECKLIST') {
          nestedChecklists.push({
            sectionIndex: cIndex,
            itemIndex,
          });
        }
      });
    });
    if (totalRequired === totalRequiredAnswered) {
      setIsSubmitting(true);
      setIsSuccess(false);
      setFinalUpdatedChecklist(mergeChecklistWithAnswers(
        checklist, answers as any[][][], nestedChecklistAnswersMap,
      ));
    } else {
      setShowConfirmationModal(prevState => !prevState);
      setIsSubmitting(false);
    }
  };

  function handleConfirmationModelCancel() {
    setShowConfirmationModal(false);
  }
  function toggleConfirmationModal() {
    setShowConfirmationModal(prevState => !prevState);
  }

  const deleteNestedChecklist = useCallback((
    sectionIndex: number,
    itemIndex: number,
    nestedChecklistIndex?: number,
    key?: string,
  ) => {
    const { completedNames } = checklist[sectionIndex].items[itemIndex];
    if (nestedChecklistIndex || nestedChecklistIndex === 0) {
      completedNames.splice(nestedChecklistIndex, 1);
      completedNames.forEach((obj: {name: string, id: number}) => {
        if (nestedChecklistIndex < obj.id) {
          obj.id -= 1;
        }
      });
    } else {
      let tempKey = '';
      Object.keys(nestedChecklistAnswersMap).forEach((k: string, hashIndex: number) => {
        if (k === selectedNestedChecklistHash) {
          tempKey = k;
          completedNames.splice(hashIndex, 1);
          completedNames.forEach((obj: {name: string, id: number}) => {
            if (hashIndex < obj.id) {
              obj.id -= 1;
            }
          });
        }
      });

      if (tempKey) delete nestedChecklistAnswersMap[tempKey];
      setOpenSlider(false);
      setIsModalOpen(false);
    }
    const notesListClone = cloneDeep(notesList);
    const { count } = getSectionAndItemIndex(key ?? '');

    if (notesListClone) {
      const targetNotes = notesListClone![`checklist[${checklistIndex}]_item[${checklistItemIndex}]`];
      if (targetNotes![`section[${checklistIndex}]_item[${checklistItemIndex}]_count[${count}]`]) {
        delete targetNotes![`section[${checklistIndex}]_item[${checklistItemIndex}]_count[${count}]`];
      }
      notesListClone![`checklist[${sectionIndex}]_item[${itemIndex}]`] = targetNotes;
      setNotesList(notesListClone);
    }
  }, [
    checklist,
    notesList,
    checklistIndex,
    checklistItemIndex,
    nestedChecklistAnswersMap,
    selectedNestedChecklistHash,
  ]);

  const handleOpenNestedChecklist = useCallback((
    bit: ChecklistBit,
    sectionIndex: number,
    itemIndex: number,
  ) => {
    const { completedNames } = checklist[sectionIndex].items[itemIndex];

    setNestedChecklist(
      bit.props.content?.checklist as unknown as ChecklistSection[],
    );
    setChecklistIndex(sectionIndex);
    setChecklistItemIndex(itemIndex);
    setOpenSlider(true);
    setSelectedNestedChecklistHash(getChecklistHash(
      {
        sectionIndex,
        itemIndex,
        bitIndex: 0,
        completedChecklists: completedNames,
      },
    ));
  }, [checklist]);

  const handleOpenAnsweredNestedChecklist = useCallback((
    bit: ChecklistBit,
    sectionIndex: number,
    itemIndex: number,
    hash: string,
    answer: ChecklistAnswers,
  ) => {
    setNestedChecklist(
      bit.props.content?.checklist as unknown as ChecklistSection[],
    );
    setOpenSlider(true);
    const { count } = getSectionAndItemIndex(hash);
    const matchingNoteKeyFromNoteList = `checklist[${sectionIndex}]_item[${itemIndex}]`;
    setCurrentNestedChecklistNotes(notesList![matchingNoteKeyFromNoteList]![`section[${checklistIndex}]_item[${checklistItemIndex}]_count[${count}]`]);
    setChecklistIndex(sectionIndex);
    setChecklistItemIndex(itemIndex);
    setSelectedNestedChecklistHash(hash);
    setSelectedNestedChecklistAnswers(answer);
  }, [checklistIndex, checklistItemIndex, notesList]);

  const customBits = useCallback<Required<ChecklistProps>['customBits']>((
    type, _answer, _setAnswer, bit, sectionIndex, itemIndex,
  ) => {
    if (bit.type === ChecklistBitTypes.NESTED_CHECKLIST) {
      return (
        <NestedChecklist
          // @ts-ignore
          bit={bit}
          checklist={checklist}
          sectionIndex={sectionIndex}
          itemIndex={itemIndex}
          nestedChecklistAnswersMap={nestedChecklistAnswersMap}
          deleteNestedChecklist={deleteNestedChecklist}
          // @ts-ignore
          handleOpenNestedChecklist={handleOpenNestedChecklist}
          // @ts-ignore
          handleOpenAnsweredNestedChecklist={handleOpenAnsweredNestedChecklist}
        />
      );
    }
  }, [
    checklist,
    handleOpenAnsweredNestedChecklist,
    handleOpenNestedChecklist,
    nestedChecklistAnswersMap,
    deleteNestedChecklist,
  ]);

  if (isSubmitting || isGetRoutineQueryLoading) {
    return (
      <div className="blur_overlay">
        <LoadingSpinner />
      </div>
    );
  }

  if (isSuccess) {
    return (
      <div className="success_container">
        <img style={{ width: '25%' }} src={ImageLinks.espectro.rocketMan} alt="rocketMan" />
        <h2 className="checklist-submission_success_text">{t(TRANSLATIONS.REPORT_SUBMITTED_SUCCESSFULLY)} </h2>
        <p style={{ fontSize: '14px' }}>{t(TRANSLATIONS.YOU_CAN_NOW_CLOSE_THIS_TAB)}</p>
      </div>
    );
  }

  const onHandleNotesValue = (e: { target: { value: any; innerText: any; }; }) => {
    setNotesInput(e.target.value || e.target.innerText);
  };

  const handleSaveNotes = () => {
    const checklistTemp = JSON.parse(JSON.stringify(checklist));
    if (openSlider && checklistTemp[checklistIndex].items[checklistItemIndex]
      .bits[0].type === ChecklistBitTypes.NESTED_CHECKLIST) {
      const note = {
        ...currentNestedChecklistNotes,
        [`section[${nestedSecIndex}]_item[${nestedItemIndex}]`]: notesInput,
      };
      setCurrentNestedChecklistNotes(note);
    } else {
      checklistTemp[checklistIndex].items[checklistItemIndex].bits[0].additionalFeatures = {
        allowsNote: true,
        note: notesInput,
      };
    }
    setChecklist(checklistTemp);
    setNotesInput('');
    setIsOpen(false);
  };

  const getDefaultNoteValue = () => {
    if (openSlider && checklist[checklistIndex].items[checklistItemIndex]
      .bits[0].type === ChecklistBitTypes.NESTED_CHECKLIST) {
      return currentNestedChecklistNotes?.[`section[${nestedSecIndex}]_item[${nestedItemIndex}]`] || '';
    }
    const tempValue = checklist[checklistIndex]?.items[checklistItemIndex]?.bits[0]
      ?.additionalFeatures?.note;
    return tempValue;
  };

  const onHandleShowNotes = (sectionIndex: React.SetStateAction<number>,
    itemIndex: React.SetStateAction<number>) => {
    setChecklistIndex(sectionIndex);
    setChecklistItemIndex(itemIndex);
    setIsOpen(true);
  };

  const onHandleRenderChecklistNotes = (
    renderSectionIndex: React.SetStateAction<number>,
    renderItemIndex: React.SetStateAction<number>,
  ) => {
    setNestedSectionIndex(renderSectionIndex);
    setNestedItemIndex(renderItemIndex);
    setIsOpen(true);
  };

  const scrollToSection = (index: number, key: string) => {
    const element = document.getElementById(key);
    setSelectedSection(index);
    setSelectedSectionItem(0);
    if (element) {
      element.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }
  };

  const focusInput = (id: string) => {
    const inputElement = document.getElementById(id);
    if (previousId) {
      inputElement?.setAttribute('style', 'border: 0.1rem solid #DEDEDE');
    }
    if (previousId !== id) {
      document.getElementById(previousId)?.setAttribute('style', 'border: 0.1rem solid #DEDEDE');
      document.getElementById(id)?.setAttribute('style', 'border: 1px solid #6C63FF');
      setPreviousId(id);
    }
  };

  const scrollToSectionItems = (sectionIndex: number, itemIndex: number, block: ScrollLogicalPosition = 'start') => {
    const element = document.getElementById(`${sectionIndex}_${itemIndex}`);
    setSelectedSectionItem(itemIndex);
    setSelectedSection(sectionIndex);
    setIsQuestionTitleInputFocused(true);
    focusInput(`${sectionIndex}_${itemIndex}`);
    if (element) {
      element.scrollIntoView({ behavior: 'smooth', block });
    }
  };

  return (
    <>
      <div className="checklist_renderer__container">
        <div style={{ margin: '10px', padding: '10px', marginLeft: 'auto', marginRight: 'auto' }}>
          {errorMessage ? (
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                height: '80vh',
                maxWidth: '500px',
                margin: '0 auto',
                padding: '10px',
                backgroundColor: 'white',
              }}
            >
              {errorMessage && (
                <div style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
                  <img style={{ width: '10%' }} src={ImageLinks.crossIcon} alt="crossIcon" />
                  <span>{errorMessage}</span>
                </div>
              )}
            </div>
          ) : (
            <>
              {checklist.length > 0 && (
                <>
                  {width > 768 && (
                    <div className="sidebar" style={{ top: 20 }}>
                      <h1>{t(TRANSLATIONS.OUTLINES).toUpperCase()}</h1>
                      <>
                        <div style={{ width: '100%' }}>
                          {checklist.map((section, index) => (
                            <div className="checklist-builder__item-container">
                              <>
                                <div>
                                  <div
                                    key={section.key}
                                    className={
                                      selectedSection === index
                                        ? 'checklist-builder__logic-title-active'
                                        : 'checklist-builder__logic-title'
                                    }
                                    role="button"
                                    onClick={() => scrollToSection(index, section.key)}
                                  >
                                    {index + 1}. {section.title}
                                  </div>
                                  {section.items.map((item, itemIndex) => (
                                    <div
                                      role="button"
                                      key={item?.key}
                                      className={
                                        selectedSectionItem === itemIndex
                                        && selectedSection === index
                                          ? 'checklist-builder__logic-title-active item-outline'
                                          : 'checklist-builder__logic-title item-outline'
                                      }
                                      onClick={() => {
                                        scrollToSectionItems(index, itemIndex);
                                      }}
                                    >
                                      - {item.title}
                                    </div>
                                  ))}
                                </div>
                              </>
                            </div>
                          ))}
                        </div>
                      </>
                    </div>
                  )}

                  <ChecklistRendererComponent
                    customBits={customBits}
                    checklist={(checklist as unknown) as ChecklistSection[]}
                    sectionalProgress={findSectionalScore}
                    setAnswers={setAnswers}
                    isChecklistAnswered={false}
                    showNotes={onHandleShowNotes}
                    header={(
                      <div className="checklistRenderer__header_container">
                        <div className="checklistRenderer__header_container_left">
                          <EspectroFullLogo />
                          <div className="checklistRenderer__header_column">
                            <p className="checklistRenderer__header_company_info">
                              {data?.getRoutineAvailableFromShareableToken?.company?.name}
                            </p>
                            <h4 className="checklistRenderer__header_title">
                              {data?.getRoutineAvailableFromShareableToken?.name}
                            </h4>
                            <p className="checklistRenderer__header_description">
                              {data?.getRoutineAvailableFromShareableToken?.description}
                            </p>
                          </div>
                        </div>
                        {/* Section Score */}
                        <p className="checklistRenderer__header_container_right">
                          {progressScore}/{totalQuestions} respondidas
                        </p>
                      </div>
                    )}
                    footer={(
                      <div
                        style={{
                          marginTop: 15,
                          marginBottom: 15,
                          height: 80,
                          marginLeft: 'auto',
                          marginRight: 'auto',
                          width: '40%',
                        }}
                      >
                        {isSubmitting ? (
                          <LoadingSpinner textStyle={{ display: 'none' }} />
                        ) : (
                          <ActionButton
                            text="Enviar"
                            onClick={submitResponse}
                            background={COLOR_CODE.ESPECTRO_PRIMARY}
                            style={{ color: 'white', width: '100%' }}
                          />
                        )}
                      </div>
                    )}
                  />
                </>
              )}
              {openSlider && (
                <Drawer
                  anchor={isMobile ? 'bottom' : 'right'}
                  open={openSlider}
                  onClose={() => {
                    setOpenSlider(false);
                    setNestedChecklist([]);
                    resetCurrentNestedChecklistNotesList();
                    setSelectedNestedChecklistHash(null);
                    setSelectedNestedChecklistAnswers(null);
                  }}
                >
                  <div className="checklistRenderer__nestedChecklist">
                    <ChecklistRendererComponent
                      checklist={nestedChecklist}
                      sectionalProgress={findSectionalScore}
                      answers={selectedNestedChecklistAnswers as ChecklistAnswers}
                      setAnswers={setSelectedNestedChecklistAnswers}
                      isChecklistAnswered={false}
                      showNotes={onHandleRenderChecklistNotes}
                      getDefaultNoteValue={getDefaultNoteValue}
                      header={(
                        <div className="checklistRenderer__header_container">
                          <div className="checklistRenderer__header_column">
                            <h4 className="checklistRenderer__header_title">
                              {data?.getRoutineAvailableFromShareableToken?.name}
                            </h4>
                            <p className="checklistRenderer__header_description">
                              {data?.getRoutineAvailableFromShareableToken?.description}
                            </p>
                          </div>
                        </div>
                      )}
                      footer={(
                        <div className="nested_footer">
                          <button
                            type="button"
                            className="delete_instance_button"
                            onClick={() => setIsModalOpen(true)}
                          >
                            <img
                              src={ImageLinks.espectro.deleteRed}
                              height={24}
                              width={24}
                              alt="delete icon"
                            />
                            <span>{t(TRANSLATIONS.DELETE_INSTANCE)}</span>
                          </button>
                          <Overlay open={isModalOpen} onClose={() => setIsModalOpen(false)}>
                            <div
                              style={{
                                maxWidth: 600,
                                display: 'flex',
                                flexDirection: 'column',
                                marginLeft: 'auto',
                                marginRight: 'auto',
                                backgroundColor: 'white',
                                justifyContent: 'center',
                                alignItems: 'center',
                                marginTop: 200,
                                padding: 10,
                                paddingBottom: 15,
                                paddingTop: 15,
                              }}
                            >
                              <h2>{t(TRANSLATIONS.DRILLS_CHECKLIST)}</h2>
                              <p style={{ paddingBottom: 20 }}>
                                {t(
                                  // eslint-disable-next-line max-len
                                  TRANSLATIONS.YOU_WILL_NOT_BE_ABLE_TO_REVERSE_THE_ACTION_DO_YOU_WANT_TO_DELETE_THE_CHECKLIST,
                                )}
                              </p>
                              <div style={{ display: 'flex', gap: 15 }}>
                                <ActionButton
                                  text={t(TRANSLATIONS.CANCEL)}
                                  onClick={() => setIsModalOpen(false)}
                                  className="cancel_button"
                                />
                                <ActionButton
                                  text={t(TRANSLATIONS.YES_DELETE)}
                                  background={COLOR_CODE.RED}
                                  onClick={() => {
                                    resetCurrentNestedChecklistNotesList();
                                    deleteNestedChecklist(checklistIndex, checklistItemIndex);
                                  }}
                                />
                              </div>
                            </div>
                          </Overlay>
                          <div style={{ flex: 0.5 }}>
                            {isSubmitting ? (
                              <LoadingSpinner textStyle={{ display: 'none' }} />
                            ) : (
                              <ActionButton
                                text="Enviar"
                                onClick={saveNestedChecklistAnswersToLocalMap}
                                background={COLOR_CODE.ESPECTRO_PRIMARY}
                                style={{ color: 'white', height: 40, width: '100%' }}
                              />
                            )}
                          </div>
                        </div>
                      )}
                    />
                  </div>
                </Drawer>
              )}
            </>
          )}
        </div>
        <Modal
          className="comment-delete-modal"
          open={isOpen}
          closeAfterTransition
          BackdropComponent={Backdrop}
          onBackdropClick={() => {
            setIsOpen(false);
          }}
          BackdropProps={{
            timeout: 1,
          }}
          onClose={() => {
            setIsOpen(false);
          }}
          aria-labelledby="simple-modal-title"
          aria-describedby="simple-modal-description"
        >
          <Fade in={isOpen}>
            <div
              className={
                isMobile
                  ? 'checklist_renderer-notes-modal-mobile'
                  : 'checklist_renderer-notes-modal'
              }
            >
              <>
                <header>
                  <span className="header_title">{t(TRANSLATIONS.NOTE)}</span>
                </header>
                <body>
                  <div>
                    <textarea
                      className="descriptionBox"
                      defaultValue={getDefaultNoteValue()}
                      onChange={onHandleNotesValue}
                      // eslint-disable-next-line jsx-a11y/no-autofocus
                      autoFocus
                    />
                  </div>
                </body>
                <footer>
                  <Button variant="contained" className="delete_button" onClick={handleSaveNotes}>
                    {t(TRANSLATIONS.DONE)}
                  </Button>
                </footer>
              </>
            </div>
          </Fade>
        </Modal>
      </div>
      {showConfirmationModal && (
        <ConfirmationModalSharableChecklist
          title={t(TRANSLATIONS.WAIT_A_MOMENT)}
          description={`${t(TRANSLATIONS.REQUIRED_FIELDS)}`}
          isOpen={showConfirmationModal}
          onOk={toggleConfirmationModal}
          okButtonText={`${t(TRANSLATIONS.CONTINUE).toLowerCase()}`}
          onCancel={handleConfirmationModelCancel}
          okButtonStyle={{ backgroundColor: '#6C63FF', color: '#fff' }}
        />
      )}
    </>
  );
}
