import React, { FC } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { upperFirst } from 'lodash';

import SelectDropdown from 'components/Dropdown/SelectDropdown';
import { NameAndSlug } from 'types/interfaces';
import { TRANSLATIONS } from 'types/enums';
import { selectCurrentAccesLevel } from 'store/selectors/distributionCenters.selectors';

import downloadIconBlack from 'assets/icons/download-black.svg';

import './Card.scss';

type Props = {
  item: {
    name: string,
    slug: string,
    incidents: number,
    locationType: string,
  },
  handleClick: ({ name, slug }: NameAndSlug, e: any) => void,
  downloadReport: (dowloadOption: string,
    locationSlug: string,
    level?: string,
    type?: string) => void,
};

const Card: FC<Props> = ({ item: { name, slug, incidents, locationType },
  handleClick, downloadReport }) => {
  const { t } = useTranslation();
  const accessLevel = useSelector(selectCurrentAccesLevel);

  const downloadDropdownContent = [
    { label: t(TRANSLATIONS.INCIDENTS), value: TRANSLATIONS.INCIDENTS },
    { label: upperFirst(t(TRANSLATIONS.CERTIFICATES)), value: TRANSLATIONS.CERTIFICATES },
    // { label: upperFirst(t(TRANSLATIONS.BOTH)), value: TRANSLATIONS.BOTH },
  ];

  if (locationType === 'FACTORY') downloadDropdownContent.push({ label: upperFirst(t(TRANSLATIONS.AMBIENTAL_RISKS)), value: TRANSLATIONS.AMBIENTAL_RISKS });

  const handleDownloadOptionChange = ({ target: { value } }: any) => {
    downloadReport(value, slug, accessLevel, locationType);
  };

  return (
    <div className="custom-card" onClick={e => handleClick({ name, slug }, e)} role="presentation" id="card">
      <span id="card">
        <p className="" id="card">{name}</p>
      </span>
      <p className="custom-card__incidents" id="card">{incidents?.toLocaleString() || 0}</p>
      <div className="custom-card__dropdown" id="dropdown">
        <SelectDropdown
          name="download-card"
          value=""
          dropdownContent={downloadDropdownContent}
          onChange={handleDownloadOptionChange}
          bgColor="transparent"
          placeholder={`${upperFirst(t(TRANSLATIONS.DOWNLOAD))}`}
          icon={downloadIconBlack}
        />
      </div>
    </div>
  );
};

export default Card;
