import React, { useMemo } from 'react';
import Chart from 'react-apexcharts';
import ErrorMessage from 'components/ErrorMessage';
import { BU } from 'types/enums';
import { useSelector } from 'react-redux';
import EmptyState from 'components/EmptyState/EmptyState';
import selectDriverManagementSystem from 'store/selectors/driverManagementSystem.selectors';
import {
  customDMSDataSelectorByYearMonthTypeBuAndSubType,
  DMSSelectorTypes,
} from '../dmsHelper';
import '../DriverManagmentSystemStyles.scss';

type Props = {
  loading: boolean;
  month: string;
  year: number;
  error: boolean;
};

const IncidentsPieChart = ({ year, loading, month, error }: Props) => {
  const driverManagementSystemData = useSelector(selectDriverManagementSystem);
  const selectedMonthData = customDMSDataSelectorByYearMonthTypeBuAndSubType(
    driverManagementSystemData,
    year,
    month,
    DMSSelectorTypes.perWeekGraph,
  );

  const totalIncidentsData = useMemo(() => {
    const tempTotalIncident: number[] = [];

    if (!loading) {
      if (selectedMonthData) {
        Object.values(BU).forEach(bu => {
          tempTotalIncident.push(selectedMonthData[bu].reduce(
            (a: number, b: number) => a + b, 0,
          ));
        });
        if (tempTotalIncident.every(item => item === 0)) {
          return [];
        }
        return tempTotalIncident;
      }
    }
    return tempTotalIncident;
  }, [loading, selectedMonthData]);

  const customOptions = {
    states: {
      hover: {
        filter: {
          type: 'none',
        },
      },
    },
    tooltip: {
      enabled: false,
    },
    dataLabels: {
      enabled: false,
    },
    plotOptions: {
      pie: {
        donut: {
          labels: {
            show: true,
            total: {
              show: true,
              showAlways: true,
              label: 'MAZ',
              fontSize: '18px',
              fontFamily: 'Roboto',
              fontWeight: 700,
              color: '#000000',
              formatter(w: any) {
                return w.globals.seriesTotals.reduce((a: any, b: any) => {
                  return (a + b);
                }, 0).toLocaleString();
              },
            },
          },
        },
        expandOnClick: false,
      },
    },
    fill: {
      colors: ['#DEA153', '#86A85F', '#BE6742', '#A33223', '#5572B9'],
    },
    labels: ['BU Colombia', 'BU México', 'BU Ecuador', 'BU Perú', 'BU CAC'],
    responsive: [{
      breakpoint: 480,
      options: {
        chart: {
          width: 200,
        },
        legend: {
          position: 'bottom',
        },
      },
    }],
    legend: {
      markers: {
        fillColors: ['#DEA153', '#86A85F', '#BE6742', '#A33223', '#5572B9'],
        radius: 2,
      },
      position: 'bottom',
      fontSize: '16px',
      fontFamily: 'Roboto',
      fontWeight: 700,
      formatter(seriesName: any, opts: any) {
        return [seriesName, ' ', opts.w.globals.series[opts.seriesIndex].toLocaleString()];
      },
      horizontalAlign: 'left',
      itemMargin: {
        horizontal: 10,
        vertical: 0,
      },
      onItemClick: {
        toggleDataSeries: false,
      },
    },
  };

  if (error) return <ErrorMessage />;
  if (loading) return <></>;
  if (totalIncidentsData.length === 0) return <EmptyState />;

  return (
    <Chart
      type="donut"
      options={customOptions}
      series={totalIncidentsData}
      height="308px"
    />
  );
};

export default IncidentsPieChart;
