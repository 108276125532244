import React, { useState } from 'react';
import './MultiValueInput.styles.scss';

interface MultiValueInputProps {
  items: string[];
  maxLength?: number;
  placeholder?: string;
  errorMessage?: string;
  validationRegEx?: RegExp;
  onAddItem: (val: string[]) => void;
  onDeleteItem: (val: string[]) => void;
}

const MultiValueInput: React.FC<MultiValueInputProps> = ({
  items,
  onAddItem,
  onDeleteItem,
  maxLength = undefined,
  validationRegEx = undefined,
  placeholder = 'Type and press Enter!',
}) => {
  const [inputValue, setInputValue] = useState<string>('');
  const [highlightValidationFailure, setShowHighlightValidationFailure] = useState<boolean>(false);

  const onAdd = (value: string, e: React.KeyboardEvent<HTMLInputElement>) => {
    e.preventDefault();
    const item = value.trim();
    const updatedInputsList = [...items, value];
    if (item !== '' && !items.includes(value)) {
      if (validationRegEx !== undefined) {
        const validationTest = validationRegEx?.test(item);
        if (!validationTest) {
          setShowHighlightValidationFailure(true);
          return;
        }
      }
      setInputValue('');
      onAddItem(updatedInputsList);
    }
  };

  const onDelete = (index: number) => {
    if (index >= 0) {
      const itemList = [...items];
      itemList.splice(index, 1);
      onDeleteItem(itemList);
    }
  };

  return (
    <div>
      <input
        type="text"
        value={inputValue}
        maxLength={maxLength}
        placeholder={placeholder}
        onChange={e => {
          if (highlightValidationFailure) setShowHighlightValidationFailure(false);
          setInputValue(e.target.value);
        }}
        onKeyDown={(e: React.KeyboardEvent<HTMLInputElement>) => {
          if (e.key === 'Enter') onAdd(inputValue, e);
        }}
        className={highlightValidationFailure ? 'checklist-builder_input_error' : 'add_answers-checklist-bit-input-option'}
      />
      <div className="pill-container">
        {items?.map((item: string, index: number) => (
          <div className="pill" key={item}>
            {item}
            <button
              type="button"
              className="cross-button"
              onClick={() => onDelete(index)}
            >
              &times;
            </button>
          </div>
        ))}
      </div>
    </div>
  );
};

export default MultiValueInput;
