import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { setTimeFilter } from 'store/actions/timeFilter.actions';
import ChartDropdown from 'components/Dropdown/ChartDropdown';
import { selectTimeFilter } from 'store/selectors/timeFilter.selector';
import { semestralPeriodOptionsCalculator } from 'utils/dateHelpers';

const PeriodSelector = ({
  periodOptions = semestralPeriodOptionsCalculator(new Date(2021, 0, 1)),
} : {periodOptions?: any}) => {
  const dispatch = useDispatch();
  const timeFilter = useSelector(selectTimeFilter);
  const [dropDownOpen, setDropDownOpen] = useState(false);
  const [selectedPeriod, setSelectedPeriod] = useState(periodOptions[0]);

  const handleDropDownChange = ({ key }: any) => {
    const selectedOption = periodOptions.find(
      (item: { slug: string; }) => item.slug === key,
    ) || periodOptions[0];
    setSelectedPeriod(selectedOption);
    dispatch(setTimeFilter({
      startTime: selectedOption.startTime,
      endTime: selectedOption.endTime,
      slug: selectedOption.slug,
    }));
    setDropDownOpen(false);
  };

  useEffect(() => {
    const selectedOption = periodOptions.find(
      (item: { slug: string; }) => item.slug === timeFilter.get('slug'),
    );

    setSelectedPeriod(selectedOption || periodOptions[0]);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [timeFilter.get('slug')]);

  return (
    <ChartDropdown
      onChange={handleDropDownChange}
      selectedDropDownItem={selectedPeriod}
      dropDownItems={periodOptions}
      dropDownOpen={dropDownOpen}
      setDropDownOpen={setDropDownOpen}
      labelClassName="label-default"
    />
  );
};

export default PeriodSelector;
