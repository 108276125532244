import React from 'react';
import { useSelector } from 'react-redux';
import { selectUserType } from 'store/selectors/user.selectors';
import { selectLocationsToAccess } from 'store/selectors/globalFilters.selectors';
import DPOSummaryCard from './DPOSummaryCard';
import IncidentsChart from './IncidentsChart';
// import TotalIncidentsChart from '../Incidents/TotalIncidentsChart';
import ParticipationTable from './ParticipationTable';
import LocationTopBottom from './LocationsTopBottom';
import Certificates from './Certificates';
import RoutinesFollowup from './RoutinesFollowup';
import SafetyAcademyPreview from './SafetyAcademyOverview/SafetyAcademyOverview';

import './Overview.scss';

export default function AnalyticsWrapper() {
  const userType = useSelector(selectUserType);
  const locationsToAccess = useSelector(selectLocationsToAccess);
  const isSupply = userType === 'SubzoneGeneralManagerSupply' || userType === 'DistributionCenterManagerSupply' || userType === 'CorporateSupply';
  return (
    <div className="container-fluid overview-wrapper">
      <h1 className="overview__title">Home</h1>
      <div className="row">
        <SafetyAcademyPreview />
      </div>
      <div className="row">
        {!isSupply && locationsToAccess.length === 1 && (
          <div className="col-3 card overview-card">
            <DPOSummaryCard />
          </div>
        )}
        <div className={`${!isSupply && locationsToAccess.length === 1 ? 'col-6' : 'col-9'} card`}>
          <div className="mb-3 p-0">
            <IncidentsChart />
          </div>
        </div>
        <div className="col-3 card overview-card">
          <div className="mb-3">
            <ParticipationTable />
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-3 card p-0">
          <LocationTopBottom />
        </div>
        {!isSupply && (
          <div className="col-4 card p-0">
            <RoutinesFollowup />
          </div>
        )}
        <div className={isSupply ? 'col-9 card' : 'col-5 card'}>
          <Certificates />
        </div>
      </div>
    </div>
  );
}
