import React, { useEffect, useState, useMemo } from 'react';
import CategoryContainer from 'components/SKAP/CategoryContainer';
import DonutChart from 'components/SKAP/DonutChart';
import { useSelector } from 'react-redux';
import Filter from 'components/SKAP/Filter';
import Tabs from 'components/SKAP/Tabs';
import RampUpChart from 'components/SKAP/RampUpChart';
import PieChart from 'components/SKAP/PieChart';
import Table from 'components/SKAP/Table';
import { selectLocationsToAccess } from 'store/selectors/globalFilters.selectors';
import { useTranslation } from 'react-i18next';
import { TRANSLATIONS } from 'types/enums';
import ImageLinks from 'utils/ImageLinks';
import './SKAPStyle.scss';
import LoadingSpinner from 'components/LoadingSpinner';
import ErrorMessage from 'components/ErrorMessage';
import { capitalizeFirst } from 'utils/stringHelpers';
import EmptyState from 'components/EmptyState/EmptyState';
// eslint-disable-next-line @typescript-eslint/camelcase
import { useGetSkapModulesAndSubmodulesCountLazyQuery, useSendSkap_01NotificationMutation } from 'generated/graphql';
import BasicButton from 'components/Button/BasicButton';
import ChartDropdown from 'components/Dropdown/ChartDropdown';
import { selectUser } from 'store/selectors/user.selectors';
import {
  categoryArray,
  CategoryArray,
  TabsArray,
  tabsItemsArray,
  categoriesNullDataArrayFunc,
  tabsNullDataArrayFunc,
  GetModulesAndSubmodulesCountType,
  SelectedTabData,
  RampUpChartData,
  PieChartData,
  TableData,
  tableViewFunc,
  seriesTitleObject,
  rampUpArray,
} from './skapHelper';

const SKAP = () => {
  const { t } = useTranslation();
  const locationsObject = useSelector(selectLocationsToAccess);

  const skapDownloadOptions = [{
    slug: 'general-report',
    label: 'Reporte General',
  }, {
    slug: 'warehouse',
    label: t(TRANSLATIONS.FORKLIFT_DRIVER),
  }, {
    slug: 'delivery',
    label: t(TRANSLATIONS.SKAP_DISTRIBUTION),
  }, {
    slug: 'warehouse-picking',
    label: t(TRANSLATIONS.WAREHOUSE_HELPERS),
  }];

  const filterData = [
    {
      label: t(TRANSLATIONS.ALL_THE_TEAM),
      value: GetModulesAndSubmodulesCountType.AllUsers,
    },
    {
      label: t(TRANSLATIONS.NEW_COLLABORATORS),
      value: GetModulesAndSubmodulesCountType.LastThreeMonths,
    },
    {
      label: t(TRANSLATIONS.OLD_COLLABORATORS),
      value: GetModulesAndSubmodulesCountType.MoreThanThreeMonths,
    },
  ];

  const tableColumns = [
    {
      Header: t(TRANSLATIONS.AREA),
      accessor: 'area',
    },
    {
      Header: t(TRANSLATIONS.HC_TOTAL),
      accessor: 'hcTotal',
    },
    {
      Header: t(TRANSLATIONS.IMAPACTED_HC),
      accessor: 'hCImpactado',
    },
    {
      Header: capitalizeFirst(t(TRANSLATIONS.TOTAL)),
      accessor: 'total',
    },
    {
      Header: t(TRANSLATIONS.FUNCTIONAL),
      accessor: 'funcional',
    },
    {
      Header: t(TRANSLATIONS.TECHNICAL),
      accessor: 'tecnicas',
    },
    {
      Header: t(TRANSLATIONS.AUTONOMOUS),
      accessor: 'autonomo',
    },
  ];

  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [isDataError, setIsDataError] = useState<boolean>(false);
  const [category, setCategory] = useState<CategoryArray>();
  const [tabs, setTabs] = useState<TabsArray>();
  const [rampupChartData, setRampupChartData] = useState<RampUpChartData>();
  const [pieChartData, setPieChartData] = useState<PieChartData>();
  const [tableViewData, setTableViewData] = useState<TableData>();
  const [selectedValue, setSelectedValue] = useState(filterData && filterData[0]);
  const [selectedTab, setSelectedTab] = useState<SelectedTabData>();
  const [isDropDownOpen, setIsDropDownOpen] = useState(false);
  const [isDownloadDropdownOpen, setDownloadDropdownOpen] = useState(false);
  const [selectedDownloadDropdown, setSelectedDownloadDropdown] = useState(skapDownloadOptions[0]);

  const loggedInUser = useSelector(selectUser);
  const { email: loggedInUserEmail } = loggedInUser.toJS();

  const handleDropDownChange = ({ key }: any) => {
    setSelectedDownloadDropdown(
      skapDownloadOptions.find(item => item.slug === key) || skapDownloadOptions[0],
    );
    setDownloadDropdownOpen(false);
  };

  const downloadSKAPExcel = () => {
    window.open(`/api/v1/skap/excel?locations=${JSON.stringify(locationsObject)}&type=${selectedDownloadDropdown.slug}`, '_blank');
  };

  const [sendSKAP01Notifications] = useSendSkap_01NotificationMutation();

  const [callGetSkapModulesAndSubmodulesCountLazyQuery,
    { loading, data }] = useGetSkapModulesAndSubmodulesCountLazyQuery({
    onError: () => {
      setIsDataError(true);
      setIsLoading(false);
    },
    onCompleted: () => {
      setIsLoading(false);
    },
  });

  useEffect(() => {
    if (locationsObject.length) {
      callGetSkapModulesAndSubmodulesCountLazyQuery({ variables: {
        filter: {
          users: selectedValue.value as any,
          locationIds: locationsObject,
        },
      } });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [locationsObject]);

  const categoriesData = useMemo(() => {
    if (data) {
      if (data?.getSKAPModulesAndSubmodulesCount) {
        if (data?.getSKAPModulesAndSubmodulesCount?.countByLevels) {
          return data?.getSKAPModulesAndSubmodulesCount?.countByLevels;
        }
        return [];
      }
      return [];
    }
    return [];
  }, [data]);

  const tabsData = useMemo(() => {
    if (data) {
      if (data?.getSKAPModulesAndSubmodulesCount) {
        if (data?.getSKAPModulesAndSubmodulesCount?.countByLevels) {
          return data?.getSKAPModulesAndSubmodulesCount?.countByPosition;
        }
        return [];
      }
      return [];
    }
    return [];
  }, [data]);

  const pieData = useMemo(() => {
    if (data) {
      if (data?.getSKAPModulesAndSubmodulesCount) {
        if (data?.getSKAPModulesAndSubmodulesCount?.countByAutonomousLevel) {
          return data?.getSKAPModulesAndSubmodulesCount?.countByAutonomousLevel;
        }
        return [];
      }
      return [];
    }
    return [];
  }, [data]);

  const rampUpData = useMemo(() => {
    if (data) {
      if (data?.getSKAPModulesAndSubmodulesCount) {
        if (data?.getSKAPModulesAndSubmodulesCount?.countByMonth) {
          return data?.getSKAPModulesAndSubmodulesCount?.countByMonth;
        }
        return [];
      }
      return [];
    }
    return [];
  }, [data]);

  useEffect(() => {
    if (categoriesData && categoriesData.length) {
      setCategory(categoryArray(categoriesData, t));
    } else {
      setCategory(categoriesNullDataArrayFunc(t));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [categoriesData]);

  useEffect(() => {
    if (tabsData && tabsData.length) {
      const chartData = tabsItemsArray(tabsData, t);
      setTableViewData(tableViewFunc(tabsData, t, categoriesData));
      if (chartData !== undefined && chartData.length) {
        setTabs(chartData);
        setSelectedTab(chartData[0]);
      }
    } else {
      setTableViewData(tableViewFunc(tabsData, t, categoriesData));
      const chartData = tabsNullDataArrayFunc(t);
      setTabs(chartData);
      setSelectedTab(chartData[0]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tabsData]);

  useEffect(() => {
    if (pieData && pieData.length > 0) {
      const isAllZero = pieData.every(item => item?.count === 0);

      if (isAllZero) {
        setPieChartData({ labels: [], series: [] });
      } else {
        setPieChartData(seriesTitleObject(pieData));
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pieData]);

  useEffect(() => {
    const isAllZero = rampUpData.every(item => item?.score === 0);

    if (rampUpData && rampUpData.length) {
      if (isAllZero) {
        setRampupChartData({ month: [], score: [], total: [] });
      } else {
        setRampupChartData(rampUpArray(rampUpData, t));
      }
    } else {
      setRampupChartData({ month: [], score: [], total: [] });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rampUpData]);

  const dropDownOpenCloseHandler = (opened: boolean) => {
    return !opened ? setIsDropDownOpen(false) : setIsDropDownOpen(true);
  };
  const handleDropdownChange = ({ key }: { key: any }) => {
    const selectedDropDownValue = filterData.find((item: { value: any }) => item?.value === key);
    setIsDataError(false);
    setSelectedValue(selectedDropDownValue || filterData[0]);
    callGetSkapModulesAndSubmodulesCountLazyQuery({ variables: {
      filter: {
        users: selectedDropDownValue ? selectedDropDownValue.value as any : filterData[0].value,
        locationIds: locationsObject,
      },
    } });
    setIsDropDownOpen(false);
  };

  const onClickReload = () => {
    callGetSkapModulesAndSubmodulesCountLazyQuery({ variables: {
      filter: {
        users: selectedValue.value as any,
        locationIds: locationsObject,
      },
    } });
  };

  const handleSendSKAP01Notification = async () => {
    try {
      await sendSKAP01Notifications();
      window.alert('Notifications sent');
    } catch {
      window.alert('Notifications sent failed');
    }
  };

  return (
    <>
      <div className="skap" style={{ marginBottom: 30 }}>
        <span className="skap__title">{t(TRANSLATIONS.INDICATORS)}</span>
        <div className="skap__filter">
          <Filter
            onChange={handleDropdownChange}
            selectedDropDownItem={selectedValue}
            dropDownItems={filterData}
            dropDownOpen={isDropDownOpen}
            setDropDownOpen={(opened: boolean) => dropDownOpenCloseHandler(opened)}
            labelClassName="label-default"
          />
        </div>
        <div className="skap__categoryRow">
          {loading || !category || isLoading ? (
            <LoadingSpinner height={215} className="skap__categoryRowLoading" />
          ) : tabs && tabs?.length === 0 ? (
            <EmptyState />
          ) : (
            category && category?.map(item => (
              <div key={item?.slug}>
                <CategoryContainer
                  categoryTitle={item?.slug}
                  categorySubtitle={item?.count === '0' ? '0/0' : item?.count}
                  categoryImage={ImageLinks.categoryUser}
                  categoryPercentage={isDataError ? 0 : item?.percentage}
                />
              </div>
            ))
          )}
        </div>
        {loading || !tabs || isLoading ? (
          <LoadingSpinner height={215} className="skap__loader" />
        ) : tabs && tabs.length === 0 ? (
          <EmptyState />
        ) : (
          tabs && selectedTab && tabs?.length && (
            <div className="skap__tabs">
              <Tabs
                tabsItems={tabs}
                selectedTab={selectedTab}
                setSelectedTab={setSelectedTab as any}
              />
              <div className="skap__chartView">
                {
                  isDataError ? (
                    <ErrorMessage
                      onClickReload={onClickReload}
                      errorImage={ImageLinks.errorImage}
                      title={t(TRANSLATIONS.SORRY_THERE_WAS_AN_ERROR)}
                      buttonText={t(TRANSLATIONS.RELOAD_PAGE)}
                      className="skap__errorView"
                    />
                  )
                    : selectedTab?.chart?.length ? (
                      <>
                        {selectedTab?.chart?.map((item: any) => (
                          <DonutChart
                            key={item?.title}
                            labels={item?.title}
                            series={item?.count}
                            error={false}
                            loading={false}
                          />
                        ))}
                      </>
                    ) : (
                      <ErrorMessage
                        onClickReload={onClickReload}
                        errorImage={ImageLinks.errorImage}
                        title={t(TRANSLATIONS.SORRY_THERE_WAS_AN_ERROR)}
                        buttonText={t(TRANSLATIONS.RELOAD_PAGE)}
                        className="skap__errorView"
                      />
                    )
              }
              </div>
            </div>
          )
        )}
        <div className="skap__rampupChart">
          <p className="skap__rampupTitle">{t(TRANSLATIONS.RAMP_UP)}</p>
          <div className="skap__chartContainer">
            <RampUpChart
              countSeries={rampupChartData ? rampupChartData.score : []}
              totalSeries={rampupChartData ? rampupChartData.total : []}
              categories={rampupChartData ? rampupChartData.month : []}
              error={isDataError}
              loading={loading || isLoading}
            />
          </div>
        </div>
        <div className="skap__piechartTableView">
          <div className="skap__pieChart">
            <p className="skap__tableViewTitle">{t(TRANSLATIONS.AUTONOMY_LEVEL)}</p>
            <PieChart
              series={pieChartData?.series || []}
              labels={pieChartData?.labels || []}
              error={isDataError}
              loading={loading || isLoading}
              onClickReload={onClickReload}
            />
          </div>
          <div className="skap__tableView">
            <p className="skap__tableViewTitle">{t(TRANSLATIONS.HEAD_COUNT)}</p>
            <Table
              data={tableViewData ?? []}
              columns={tableColumns}
              error={isDataError}
              loading={loading || isLoading}
              onClickReload={onClickReload}
            />
          </div>
        </div>
      </div>
      <div
        style={{
          flexDirection: 'row',
          display: 'flex',
          justifyContent: 'flex-start',
          marginLeft: 20,
          paddingBottom: 100,
        }}
      >
        <div>
          <ChartDropdown
            onChange={handleDropDownChange}
            selectedDropDownItem={selectedDownloadDropdown}
            dropDownItems={skapDownloadOptions}
            dropDownOpen={isDownloadDropdownOpen}
            setDropDownOpen={setDownloadDropdownOpen}
            labelClassName="label-default"
          />
        </div>
        <BasicButton
          text={t(TRANSLATIONS.DOWNLOAD_EXCEL)}
          // @ts-ignore
          onClick={downloadSKAPExcel}
          style={{ color: '#70AD47', padding: '7px 8px' }}
          background="#F0F0F0"
        />
        {loggedInUserEmail.includes('arvolution') && (
          <BasicButton
            text="Send SKAP 01 Notification"
            className="upload_button_filled"
            onClick={handleSendSKAP01Notification}
          />
        )}
      </div>
    </>
  );
};

export default SKAP;
