import React, { useEffect, useState } from 'react';
import PeriodSelector from 'components/PeriodSelector/PeriodSelector';
import EmptyState from 'components/EmptyState/EmptyState';
import { useSelector } from 'react-redux';
import { selectTimeFilter } from 'store/selectors/timeFilter.selector';
import moment from 'moment';
import { switchMonthToSpanish } from 'utils/dateHelpers';
import LoadingSpinner from 'components/LoadingSpinner';
import axios from 'axios';
import usePrevious from 'utils/hooks/usePrevious';
import HorizontalBarByMonth from './HorizontalBarByMonth/HorizontalBarByMonth';
import './SifRoute.scss';

const SifRouteMAZ = () => {
  const [months, setMonths] = useState<string[] | undefined>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [totalData, setTotalData] = useState<number[]>([]);
  const timeFilter = useSelector(selectTimeFilter);

  const prevTimeFilterSlug = usePrevious(timeFilter.get('slug'));

  const startDate: string = switchMonthToSpanish(
    moment(timeFilter.get('startTime')).format('MMMM'),
  );
  const endDate: string = switchMonthToSpanish(moment(timeFilter.get('endTime')).format('MMMM'));
  const date: string = moment(timeFilter.get('endTime')).format('YYYY');

  function renderContent() {
    if (isLoading) {
      return <LoadingSpinner />;
    }
    if (!totalData?.length) {
      return <EmptyState />;
    }

    return <HorizontalBarByMonth data={totalData} months={months} />;
  }

  useEffect(() => {
    const getRoutinesData = async () => {
      try {
        setIsLoading(true);
        const response = await axios.get('/api/v1/sif-by-bu', {
          params: {
            from_month: startDate,
            to_month: endDate,
            year: date,
          },
        });
        if (response?.data) {
          const { sifRoutesMAZ } = response.data;
          setTotalData(Object.values(sifRoutesMAZ));
          setMonths(Object.keys(sifRoutesMAZ));
        }
      } catch (error) {
        console.error(error);
      } finally {
        setIsLoading(false);
      }
    };
    if (!!timeFilter.get('slug') && timeFilter.get('slug') !== prevTimeFilterSlug) {
      getRoutinesData();
    }
    // it depends only to timeFilter changes
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [timeFilter.get('slug'), prevTimeFilterSlug]);

  return (
    <div className="row">
      <div className="col-12 card routines-home__card">
        <>
          <span className="routines-home__card__title">MAZ</span>
          {renderContent()}
          <div role="button">
            <PeriodSelector />
          </div>
        </>
      </div>
    </div>
  );
};

export default SifRouteMAZ;
