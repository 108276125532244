import React, { PureComponent } from 'react';
import { NavLink as Link } from 'react-router-dom';
import ImageLinks from 'utils/ImageLinks';
import HighlightButton from 'components/Button/HighlightButton';
import request from 'utils/request';
import LoadingSpinner from 'components/LoadingSpinner';
import InputWithIcon from 'components/Input/InputWithIcon';
import SelectDropdown from 'components/Dropdown/SelectDropdown';

import 'features/internal-users/user/Register/Register.scss';
import { withTranslation } from 'react-i18next';
import { TRANSLATIONS } from 'types/enums';
import PasswordSetSuccess from './PasswordSetSuccess';
import { ABI_COMPANY_ID, COMPANIES_FOR_ABI_ONBOARDING, isEspectro } from '../../../../constants';

type State = {
  password: string;
  passwordConfirm: string;
  submitting: boolean;
  errors: any;
  email: string;
  isLoadingUserInfo: boolean;
  passwordChanged: boolean;
  name?: string;
  firstName?: string;
  lastName?: string
  nameOfTheCompany?: string
  designation?: string
  hostOrganisation?: string;
  department?: string;
  companyId?: number;
  selectedCompany?: string;
};

class Register extends PureComponent<any, State> {
  constructor(props: any) {
    super(props);
    this.state = {
      password: 'abcdefgh',
      passwordConfirm: 'abcdefgh',
      submitting: false,
      errors: {},
      email: '',
      isLoadingUserInfo: true,
      passwordChanged: false,
      name: 'ravi',
      firstName: 'hjhjj',
      lastName: 'Asthana',
      nameOfTheCompany: '',
      designation: '',
      department: '',
      hostOrganisation: '',
      companyId: undefined,
      selectedCompany: '',
    };
    this.submit = this.submit.bind(this);
    this.handleInputChange = this.handleInputChange.bind(this);
    this.enterSubmit = this.enterSubmit.bind(this);
    this.handleSelectCompany = this.handleSelectCompany.bind(this);
  }

  async componentDidMount() {
    try {
      const d = await request.get(`/api/v1/users/token/${this.props.match.params.token}`);
      this.setState({
        isLoadingUserInfo: false,
        email: ((d.user && d.user.email) || ''),
        name: (d.user && d.user.name) || '',
        hostOrganisation: (d.user.company && d.user.company.name) || 'Espectro',
        companyId: Number(d.user.companyId),
      });
    } catch (error) {
      if ((error as any)
        .response.status === 401) return this.setState({ isLoadingUserInfo: false });
      if ((typeof (error as any).response.data) !== 'object') {
        this.setState({ errors: { message: 'Error de red' }, isLoadingUserInfo: false });
      } else {
        this.setState({ errors: (error as any).response.data, isLoadingUserInfo: false });
      }
    }
  }

  isDummyUserNamePresent() {
    return this.state.name && this.state.name.includes('NEW_ESPECTRO_USER');
  }

  async submit() {
    const { t } = this.props;
    this.setState({ errors: {} });
    if (this.state.password.length < 7) {
      this.setState({ errors: { password: { msg: t(TRANSLATIONS.PASSWORD_TOO_SHORT) } } });
      return null;
    }
    if (this.state.password !== this.state.passwordConfirm) {
      this.setState({ errors: { passwordConfirm: { msg: t(TRANSLATIONS.PASSWORD_NO_MATCH) } } });
      return null;
    }
    if (this.isDummyUserNamePresent()) {
      if (!this.state.firstName) {
        this.setState({ errors: { firstName: { msg: 'Requerido' } } });
        return null;
      }
      if (!this.state.lastName) {
        this.setState({ errors: { lastName: { msg: 'Requerido' } } });
        return null;
      }
      if (this.state.companyId === ABI_COMPANY_ID) {
        if (!this.state.selectedCompany) {
          alert('Select Company is required field');
          return null;
        }
        if (this.state.selectedCompany === COMPANIES_FOR_ABI_ONBOARDING[1].value
          && !this.state.nameOfTheCompany) {
          this.setState({ errors: { nameOfTheCompany: { msg: 'Requerido' } } });
          return null;
        }
        if (!this.state.department) {
          this.setState({ errors: { department: { msg: 'Requerido' } } });
          return null;
        }
      }
      if (!this.state.nameOfTheCompany && this.state.companyId !== ABI_COMPANY_ID) {
        this.setState({ errors: { nameOfTheCompany: { msg: 'Requerido' } } });
        return null;
      }
      if (!this.state.designation) {
        this.setState({ errors: { designation: { msg: 'Requerido' } } });
        return null;
      }
    }

    this.setState({ submitting: true, errors: {} });
    try {
      await request.post('/api/v1/users/register', {
        accessToken: this.props.match.params.token,
        password: this.state.password,
        ...(this.isDummyUserNamePresent() && { name: `${this.state.firstName} ${this.state.lastName}` }),
        position: this.state.designation,
        company: this.state.nameOfTheCompany || this.state.selectedCompany,
        departmentName: this.state.department,
      });
      console.log({ departmentName: this.state.department });
      this.setState({ passwordChanged: true });
    } catch (error) {
      if ((typeof (error as any).response.data) !== 'object') {
        this.setState({ errors: { message: 'Error de red' }, submitting: false });
      } else {
        this.setState({ errors: (error as any).response.data, submitting: false });
      }
    }
  }

  handleInputChange(e: any) {
    // @ts-ignore
    this.setState({ [e.target.name]: e.target.value });
  }

  enterSubmit(e: React.KeyboardEvent<HTMLFormElement>) {
    if (e.keyCode === 13 && !e.shiftKey) {
      e.preventDefault();
      e.stopPropagation();
      this.submit();
    }
  }

  handleSelectCompany(event: React.ChangeEvent<{
    name?: string;
    value: unknown;
  }>) {
    const { value } = event.target;
    console.log({ value });
    this.setState({ selectedCompany: value as string });
  }

  render() {
    const {
      errors,
      hostOrganisation,
      companyId,
      selectedCompany,
    } = this.state;
    const { t } = this.props;
    // 500 error responses are not formatted within input field keys
    // but rather contain a single message key
    let nonValidationError = '';
    if (!errors.email && !errors.password && errors.message) {
      nonValidationError = errors.message;
    }
    if (this.state.passwordChanged) {
      return (
        <PasswordSetSuccess register />
      );
    }
    return (
      <div className="register-bg">
        <div className="register">
          <div className="container">
            <div style={{ padding: '16px' }} className="row">
              <div className="col-md-6 offset-md-3 card main-card-container">
                <p className="header-text">
                  <span className="font-bold">{isEspectro ? hostOrganisation : 'Safety 360'}</span>
                  <br />
                  {t(TRANSLATIONS.INVITING_YOU_TO_ESPECTRO)}
                </p>
                {nonValidationError ? (
                  <h3 className="error text-center spacing-top">
                    {nonValidationError}
                  </h3>
                ) : null}
                {!this.state.isLoadingUserInfo
                  ? this.state.email === ''
                    ? (
                      <div className="invalid-token">
                        <h3>{nonValidationError || t(TRANSLATIONS.THIS_URL_HAS_EXPIRED)}</h3>
                        <Link to="/" className="link go-to-home"><p>{t(TRANSLATIONS.GO_TO_LOGIN)}</p></Link>
                      </div>
                    )
                    : (
                      <>
                        <p className="welcome-text">
                          {t(TRANSLATIONS.CREATE_YOUR_PASSWORD_TO_ACCESS)}
                        </p>
                        <InputWithIcon
                          icon={ImageLinks.userIcon}
                          errorIcon={ImageLinks.userWarningIcon}
                          type="text"
                          placeholder={t(TRANSLATIONS.EMAIL)}
                          onChange={() => ''}
                          value={this.state.email}
                          error={errors.email && errors.email.msg}
                          errorHeight
                          name="email"
                        />
                        <form onSubmit={this.submit} onKeyDown={this.enterSubmit}>
                          <InputWithIcon
                            icon={ImageLinks.lockIcon}
                            errorIcon={ImageLinks.lockWarningIcon}
                            type="password"
                            placeholder={t(TRANSLATIONS.PASSWORD)}
                            onChange={this.handleInputChange}
                            name="password"
                            errorHeight
                            error={errors.password && errors.password.msg}
                          />
                          <InputWithIcon
                            icon={ImageLinks.lockIcon}
                            errorIcon={ImageLinks.lockWarningIcon}
                            type="password"
                            placeholder={t(TRANSLATIONS.CONFIRM_PASSWORD)}
                            onChange={this.handleInputChange}
                            name="passwordConfirm"
                            errorHeight
                            error={errors.passwordConfirm && errors.passwordConfirm.msg}
                          />
                          {this.isDummyUserNamePresent() && (
                            <>
                              <InputWithIcon
                                icon={ImageLinks.userIcon}
                                errorIcon={ImageLinks.userWarningIcon}
                                type="text"
                                placeholder={t(TRANSLATIONS.NAME)}
                                onChange={this.handleInputChange}
                                name="firstName"
                                errorHeight
                                error={errors.firstName && errors.firstName.msg}
                              />
                              <InputWithIcon
                                icon={ImageLinks.userIcon}
                                errorIcon={ImageLinks.userWarningIcon}
                                type="text"
                                placeholder={t(TRANSLATIONS.SURNAME)}
                                onChange={this.handleInputChange}
                                name="lastName"
                                errorHeight
                                error={errors.lastName && errors.lastName.msg}
                              />

                              {
                                companyId === ABI_COMPANY_ID ? (
                                  <>
                                    <div className="register_abi-user-additonal-fields-container">
                                      <SelectDropdown
                                        name="company"
                                        dropdownWrapperClassName="register_abi-user-company-selector"
                                        value={selectedCompany as string}
                                        dropdownContent={COMPANIES_FOR_ABI_ONBOARDING}
                                        onChange={this.handleSelectCompany}
                                        bgColor="transparent"
                                        valueStyle={{ color: 'black' }}
                                        placeholder="Select Company"
                                      />
                                    </div>
                                    {
                                      selectedCompany === COMPANIES_FOR_ABI_ONBOARDING[1].label
                                        ? (
                                          <InputWithIcon
                                            icon={ImageLinks.userIcon}
                                            errorIcon={ImageLinks.userWarningIcon}
                                            type="text"
                                            placeholder={t(TRANSLATIONS.NAME_OF_THE_COMPANY)}
                                            onChange={this.handleInputChange}
                                            name="nameOfTheCompany"
                                            errorHeight
                                            error={errors.nameOfTheCompany && errors.nameOfTheCompany.msg}
                                          />
                                        ) : null
                                    }
                                    <InputWithIcon
                                      icon={ImageLinks.userIcon}
                                      errorIcon={ImageLinks.userWarningIcon}
                                      type="text"
                                      placeholder={t(TRANSLATIONS.DEPARTMENT)}
                                      onChange={this.handleInputChange}
                                      name="department"
                                      errorHeight
                                      error={errors.department && errors.department.msg}
                                    />
                                  </>
                                ) : (
                                  <>
                                    <InputWithIcon
                                      icon={ImageLinks.userIcon}
                                      errorIcon={ImageLinks.userWarningIcon}
                                      type="text"
                                      placeholder={t(TRANSLATIONS.NAME_OF_THE_COMPANY)}
                                      onChange={this.handleInputChange}
                                      name="nameOfTheCompany"
                                      errorHeight
                                      error={errors.nameOfTheCompany && errors.nameOfTheCompany.msg}
                                    />
                                  </>
                                )
                              }
                              <InputWithIcon
                                icon={ImageLinks.userIcon}
                                errorIcon={ImageLinks.userWarningIcon}
                                type="text"
                                placeholder={t(TRANSLATIONS.POSITION)}
                                onChange={this.handleInputChange}
                                name="designation"
                                errorHeight
                                error={errors.designation && errors.designation.msg}
                              />
                            </>
                          )}
                        </form>
                        <p className="detail-text">
                          {t(TRANSLATIONS.ALREADY_HAVE_AN_ACCOUNT)} &nbsp;
                          <Link
                            to="/login"
                            className="link"
                            tabIndex={-1}
                          >
                            {t(TRANSLATIONS.LOGIN)}
                          </Link>
                        </p>
                        <HighlightButton
                          text={t(TRANSLATIONS.LOGIN).toUpperCase()}
                          className="main-action"
                          onClick={this.submit}
                        />
                        {this.state.submitting && <LoadingSpinner className="register-spinner" />}
                      </>
                    )
                  : <LoadingSpinner height={397} />}
              </div>
            </div>
            {!isEspectro && (
              <div className="row">
                <div className="footer">
                  <p className="footer-text"> powered by ARVOLUTION </p>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    );
  }
}

export default withTranslation()(Register);
