import HeaderWithNavigation from 'components/HeaderWithNavigation';
import React, { FC, useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import gauzeIcon from 'assets/icons/gauge-gray.svg';
import executedBeer from 'assets/icons/beer.png';
import gauzeGreenIcon from 'assets/icons/gauge-green.svg';
import { COLOR_CODE_NEW, TRANSLATIONS } from 'types/enums';
import InputWithLabel from 'components/Input/InputWithLabel';
import DayPickerInput from 'react-day-picker/DayPickerInput';
import 'react-day-picker/lib/style.css';
import request from 'utils/request';
import useDebounce from 'utils/hooks/useDebounce';
import BasicButton from 'components/Button/BasicButton';
import { format } from 'date-fns/esm';
import LoadingSpinner from 'components/LoadingSpinner';
import './SupplyRoutineMobile.scss';
import AddRoutine from '../AddSupplyRoutine/AddRoutine';
import VerifyRoutine from '../AddSupplyRoutine/VerifyRoutine';

type Props = {

};

const AddSupplyRoutine: FC<Props> = () => {
  const { t } = useTranslation();

  const [selectedTab, setSelectedTab] = useState(0);

  return (
    <div className="supply-routine-mobile">
      <div className="tab-container">
        <div
          className={`tab ${selectedTab === 0 ? 'selected-tab' : ''}`}
          role="button"
          onClick={() => setSelectedTab(0)}
        >
          <p>Nueva rutina</p>
        </div>
        <div
          className={`tab ${selectedTab === 1 ? 'selected-tab' : ''}`}
          role="button"
          onClick={() => setSelectedTab(1)}
        >
          <p>Rutinas pendientes</p>
        </div>
      </div>
      { selectedTab === 0 ? (
        <AddRoutine />
      ) : (
        <VerifyRoutine />
      )}
    </div>
  );
};

export default AddSupplyRoutine;
