import React from 'react';

import './PrivacyPolicy.scss';
import 'features/privacy-policy/PrivacyPolicy.scss';

export default class PrivacyPolicy extends React.Component {
  render() {
    return (
      <div className="container privacy-policy">
        <h2>Penrose Technologies Privacy Policy</h2>

        <p>This Privacy Policy describes how your personal information is collected, used, and
          shared when you visit or make a purchase from https://safety.arvolution.com (the
          “Site”).
        </p>

        <p>PERSONAL INFORMATION WE COLLECT</p>

        <p>When you visit the Site, we automatically collect certain information about your device,
          including information about your web browser, IP address, time zone, and some of the
          cookies that are installed on your device. Additionally, as you browse the Site, we
          collect information about the individual web pages or products that you view, what
          websites or search terms referred you to the Site, and information about how you interact
          with the Site. We refer to this automatically-collected information as “Device
          Information.”
        </p>

        <p>We collect Device Information using the following technologies:</p>

        <p>- “Cookies” are data files that are placed on your device or computer and often include
          an anonymous unique identifier. For more information about cookies, and how to disable
          cookies, visit http://www.allaboutcookies.org.
        </p>
        <p>- “Log files” track actions occurring on the Site, and collect data including your IP
          address, browser type, Internet service provider, referring/exit pages, and date/time
          stamps.
        </p>

        <p>When we talk about “Personal Information” in this Privacy Policy, we are talking both
          about Device Information and Order Information.
        </p>

        <p>HOW DO WE USE YOUR PERSONAL INFORMATION?</p>

        <p>We use the Information that we collect generally to understand the safety issues that are
          reported.
        </p>

        <p>When in line with the preferences you have shared with us, provide you with information
          or advertising relating to our products or services.
        </p>

        <p>We use the Device Information that we collect to help us screen for potential risk and
          fraud (in particular, your IP address), and more generally to improve and optimize our
          Site (for example, by generating analytics about how our customers browse and interact
          with the Site, and to assess the success of our marketing and advertising campaigns).
        </p>

        <p>SHARING YOUR PERSONAL INFORMATION</p>

        <p>We share your Personal Information with third parties to help us use your Personal
          Information, as described above. For example, we use Google Analytics to help us
          understand how our customers use the Site--you can read more about how Google uses your
          Personal Information here: https://www.google.com/intl/en/policies/privacy/. You can also
          opt-out of Google Analytics here: https://tools.google.com/dlpage/gaoptout.
        </p>

        <p>Finally, we may also share your Personal Information to comply with applicable laws and
          regulations, to respond to a subpoena, search warrant or other lawful request for
          information we receive, or to otherwise protect our rights.
        </p>

        <p>BEHAVIOURAL ADVERTISING</p>
        <p>As described above, we use your Personal Information to provide you with targeted
          advertisements or marketing communications we believe may be of interest to you. For more
          information about how targeted advertising works, you can visit the Network Advertising
          Initiative’s (“NAI”) educational page at
          http://www.networkadvertising.org/understanding-online-advertising/how-does-it-work.
        </p>

        <p>You can opt out of targeted advertising by:</p>
        <p>- https://www.google.com/settings/ads/anonymous</p>

        <p>Additionally, you can opt out of some of these services by visiting the Digital
          Advertising Alliance’s opt-out portal at: http://optout.aboutads.info/.
        </p>

        <p>DO NOT TRACK</p>
        <p>Please note that we do not alter our Site’s data collection and use practices when we see
          a Do Not Track signal from your browser.
        </p>

        <p>YOUR RIGHTS</p>
        <p>If you are a European resident, you have the right to access personal information we hold
          about you and to ask that your personal information be corrected, updated, or deleted. If
          you would like to exercise this right, please contact us through the contact information
          below.
        </p>

        <p>Additionally, if you are a European resident we note that we are processing your
          information in order to fulfill contracts we might have with you (for example if you make
          an order through the Site), or otherwise to pursue our legitimate business interests
          listed above. Additionally, please note that your information will be transferred outside
          of Europe, including to Canada and the United States.
        </p>

        <p>DATA RETENTION</p>
        <p>When you place an order through the Site, we will maintain your Order Information for our
          records unless and until you ask us to delete this information.
        </p>

        <p>MINORS</p>
        <p>The Site is not intended for individuals under the age of 16.</p>

        <p>CHANGES</p>
        <p>We may update this privacy policy from time to time in order to reflect, for example,
          changes to our practices or for other operational, legal or regulatory reasons.
        </p>

        <p>CONTACT US</p>
        <p>For more information about our privacy practices, if you have questions, or if you would
          like to make a complaint, please contact us by e-mail at info@arvolution.com or by mail
          using the details provided below:
        </p>

        <p>Retorno 13 #15, Colonia Avante, CDMX, DIF, 04460, Mexico</p>
      </div>
    );
  }
}
