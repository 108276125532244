import React from 'react';
import Chart from 'react-apexcharts';
import EmptyState from 'components/EmptyState/EmptyState';
import ErrorMessage from 'components/ErrorMessage';
import LoadingSpinner from 'components/LoadingSpinner';
import ImageLinks from 'utils/ImageLinks';
import { capitalizeFirst } from 'utils/stringHelpers';

type Props = {
  series: number[];
  labels: string[];
  error: boolean;
  loading: boolean;
  onClickReload?: any;
};

const PieChart = ({ series, labels, error, loading, onClickReload } : Props) => {
  const colors = series.map((item: number) => { return item >= 90 ? '#4472C4' : item >= 80 ? '#CAA038' : '#C00000'; });

  const options = {
    labels,
    colors,
    tooltip: {
      enabled: true,
    },
    states: {
      hover: {
        filter: {
          type: 'none',
        },
      },
    },
    legend: {
      show: false,
    },
    plotOptions: {
      pie: {
        expandOnClick: false,
        dataLabels: {
          offset: -25,
        },
      },
    },
    dataLabels: {
      enabled: true,
      style: {
        fontWeight: 400,
        fontStyle: 'normal',
        fontSize: '12px',
        lineHeight: '14px',
        fontFamily: 'Roboto',
        colors: ['#FFFFFF'],
      },
      dropShadow: {
        enabled: false,
      },
      formatter(val: any, opts:any) {
        const name = opts.w.globals.labels[opts.seriesIndex].split(/(.{8})/).filter((x:string) => x.length <= 5 && x.length > 0);
        return capitalizeFirst(name[0]);
      },
    },
  };

  if (error) return <ErrorMessage onClickReload={onClickReload} errorImage={ImageLinks.errorImage} buttonText="Recargar página" />;
  if (loading) return <LoadingSpinner height={300} />;
  if (series.length === 0 || labels.length === 0) return <EmptyState />;

  return (
    <Chart
      options={options}
      series={series}
      type="pie"
      width="300"
    />
  );
};

export default PieChart;
