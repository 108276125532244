import React from 'react';

type props = {
  bgcolor: string;
  progress: number;
  height: number;
};

const ProgressBar = ({ bgcolor, progress, height }: props) => {
  const Parentdiv = {
    height,
    backgroundColor: '#D9D9D9',
  };

  const Childdiv = {
    height: '100%',
    width: `${progress}%`,
    backgroundColor: bgcolor,
  };

  return (
    <div style={Parentdiv}>
      <div style={Childdiv} />
    </div>
  );
};

export default ProgressBar;
