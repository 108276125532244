import React, { useState } from 'react';
import { useCubeQuery } from '@cubejs-client/react';
import './FactoryRankings.scss';
import rankingWhiteboard from 'assets/icons/ranking-whiteboard.svg';
import { format, getDate, getDaysInMonth, parseISO } from 'date-fns';
import { set, subDays } from 'date-fns/esm';
import { TRANSLATIONS } from 'types/enums';
import { useTranslation } from 'react-i18next';
import { lastDayOfMonth } from 'date-fns/esm/fp';
import ChartDropdown from 'components/Dropdown/ChartDropdown';
import { useSelector } from 'react-redux';
import { selectLocationsToAccess } from 'store/selectors/globalFilters.selectors';

export default function FactoryRanking() {
  const { t } = useTranslation();
  const locationsToAccess = useSelector(selectLocationsToAccess);

  const dropDownItems = [{
    slug: 'month-to-date',
    label: `${t(TRANSLATIONS.MONTH_TO_DATE)} hoy`,
  }, {
    slug: 'month-to-date-yesterday',
    label: `${t(TRANSLATIONS.MONTH_TO_DATE)} ayer`,
  // }, {
  //   slug: 'last-30-days',
  //   label: t(TRANSLATIONS.LAST_30_DAYS),
  }];

  const divisionDropdown = [{
    slug: 'factory',
    label: 'Plantas',
  }, {
    slug: 'business-units',
    label: 'BUs',
  }];

  const [dropDownOpen, setDropDownOpen] = useState(false);
  const [selectedDropDownItem, setSelectedDropDownItem] = useState(dropDownItems[0]);
  const [divisionDropdownOpen, setDivisionDropdownOpen] = useState(false);
  const [selectedDivisionDropdown, setSelectedDivisionDropdown] = useState(divisionDropdown[0]);

  const isYesterday = selectedDropDownItem.slug === 'month-to-date-yesterday';

  const getDateRange = () => {
    const currentDate = new Date();
    let filter = ['', ''] as any[];
    if (selectedDropDownItem.slug === 'last-7-days') {
      filter = [subDays(currentDate, 7), currentDate];
    }
    if (selectedDropDownItem.slug === 'last-30-days') {
      filter = [subDays(currentDate, 30), currentDate];
    }
    if (selectedDropDownItem.slug === 'month-to-date' || isYesterday) {
      const lastMonthDay = lastDayOfMonth(currentDate);
      const firstDayLastMonth = set(lastMonthDay, { date: 1 });
      filter = [firstDayLastMonth, lastMonthDay];
    }
    return [format(filter[0], 'yyyy-MM-dd'), format(filter[1], 'yyyy-MM-dd')];
  };

  const getDateRangeSecond = () => {
    const currentDate = new Date();
    let filter = ['', ''] as any[];

    const lastMonthDay = subDays(currentDate, 1);
    const firstDayLastMonth = set(currentDate, { date: 1 });
    filter = [firstDayLastMonth, lastMonthDay];

    return [format(filter[0], 'yyyy-MM-dd'), format(filter[1], 'yyyy-MM-dd')];
  };

  const handleDropDownChange = ({ key }: any) => {
    setSelectedDropDownItem(dropDownItems.find(
      item => item.slug === key,
    ) || dropDownItems[0]);
    setDropDownOpen(false);
  };

  const handleLocationDropDownChange = ({ key }: any) => {
    setSelectedDivisionDropdown(divisionDropdown.find(
      item => item.slug === key,
    ) || divisionDropdown[0]);
    setDivisionDropdownOpen(false);
  };

  const isBUsOpen = selectedDivisionDropdown.slug === 'business-units';

  const dateRange = getDateRange() as unknown as string[];
  const dateRangeSecond = getDateRangeSecond() as unknown as string[];
  const { resultSet: totalProductions } = useCubeQuery({
    measures: [
      'DailyProductionPlannings.realSum',
      'DailyProductionPlannings.totalPlanned',
      'DailyProductionPlannings.totalPlannedFactory',
    ],
    timeDimensions: [{
      dimension: 'DailyProductionPlannings.day',
      dateRange,
    }],
    dimensions: [isBUsOpen ? 'BusinessUnits.name' : 'Locations.externalid'],
    filters: [{
      dimension: 'DailyProductionPlannings.locationId',
      operator: 'equals',
      values: locationsToAccess,
    }],
  });
  const { resultSet: totalProductionsYesterday } = useCubeQuery({
    measures: [
      'DailyProductionPlannings.realSum',
      'DailyProductionPlannings.totalPlannedFactory',
    ],
    timeDimensions: [{
      dimension: 'DailyProductionPlannings.day',
      dateRange: dateRangeSecond,
    }],
    dimensions: [isBUsOpen ? 'BusinessUnits.name' : 'Locations.externalid'],
    filters: [{
      dimension: 'DailyProductionPlannings.locationId',
      operator: 'equals',
      values: locationsToAccess,
    }],
  });
  const { resultSet: factoryLastMonthlyPlannedResult } = useCubeQuery({
    measures: [
      'DailyProductionPlannings.monthlyPlannedFactoryAvg',
    ],
    timeDimensions: [{
      dimension: 'DailyProductionPlannings.day',
      dateRange: [format(subDays(new Date(), isYesterday ? 2 : 1), 'yyyy-MM-dd'), format(subDays(new Date(), isYesterday ? 1 : 0), 'yyyy-MM-dd')],
    }],
    dimensions: [isBUsOpen ? 'BusinessUnits.name' : 'Locations.externalid'],
    filters: [{
      dimension: 'DailyProductionPlannings.locationId',
      operator: 'equals',
      values: locationsToAccess,
    }],
  });
  const { resultSet: factoryPlannedResult } = useCubeQuery({
    measures: ['DailyProductionPlannings.count'],
    filters: [{
      dimension: 'DailyProductionPlannings.real',
      operator: 'set',
    }, {
      dimension: 'DailyProductionPlannings.locationId',
      operator: 'equals',
      values: locationsToAccess,
    }],
    dimensions: [isBUsOpen ? 'BusinessUnits.name' : 'Locations.externalid'],
    timeDimensions: [{
      dimension: 'DailyProductionPlannings.day',
      dateRange,
    }],
  });

  const factoryPlannedValuesCount = factoryPlannedResult?.series()[0]?.series as any;
  const factoryMonthlyPlanned = factoryLastMonthlyPlannedResult?.series()[0]?.series as any;
  let totalProductionsSeries = totalProductions?.series()[0]?.series;
  const resultSetSeriesPlanned = totalProductions?.series()[1]?.series as any;
  let factoryPlannedSum = totalProductions?.series()[2]?.series as any;

  if (isYesterday) {
    totalProductionsSeries = totalProductionsYesterday?.series()[0]?.series;
    factoryPlannedSum = totalProductionsYesterday?.series()[1]?.series as any;
  }


  let values: any = [];
  const currentDate = new Date();
  if (totalProductionsSeries && factoryPlannedValuesCount && factoryMonthlyPlanned) {
    totalProductionsSeries.forEach((location, i) => {
      const factoryPlanned = factoryPlannedSum[i].value;
      const real = location.value;
      const adherence = (location.value / factoryPlanned) * 100;
      const monthlyCorporatePlan = resultSetSeriesPlanned[i].value;
      const daysInMonth = getDaysInMonth(currentDate);
      const daysWithRealData = (factoryPlannedValuesCount[i]?.value || 0);
      const pendingDays = daysInMonth - daysWithRealData;
      const currentDay = isYesterday ? getDate(currentDate) - 1 : getDate(currentDate);
      const hasMissingDays = daysWithRealData < currentDay;
      const expectedMonthProduction = real + ((monthlyCorporatePlan / daysInMonth) * pendingDays);

      const varianceCorporateMonthlyPlanAndArithmetic = (
        (expectedMonthProduction / monthlyCorporatePlan) * 100
      );

      const factoryPlannedMonth = factoryMonthlyPlanned[i]?.value;
      const factoryPlanAndCorporatePlan = (
        (factoryPlannedMonth / expectedMonthProduction) * 100
      );

      values.push({
        name: hasMissingDays ? `${location.x} *` : location.x,
        factoryPlanned,
        real,
        adherence,
        monthlyCorporatePlan,
        expectedMonthProduction,
        varianceCorporateMonthlyPlanAndArithmetic,
        factoryPlannedMonth,
        factoryPlanAndCorporatePlan,
        // real: location.value,
        // planned: plannedForLocation,
        // plannedMonth: (factoryMonthlyPlanned && factoryMonthlyPlanned[i].value) || 0,
        // expectedMonthlyProduction,
        // averageDailyProduction,
        // percent,


      });
    });
  }

  values = values.sort((v: any, b: any) => b.adherence - v.adherence);

  // console.log('the values: ', values);

  return (
    <div className="factory-rankings-analytics">
      <div className="header-container">
        <div className="header-title">
          <img
            src={rankingWhiteboard}
            alt=""
            className="hc-icon"
          />
          <p className="text-bold overview-header">Ranking de plantas</p>
        </div>
      </div>
      <div className="table-details table-row">
        <p style={{ textAlign: 'center' }}>Planta</p>
        <p>Vol. planeado (hl)</p>
        <p>Vol. real (hl)</p>
        <p>% adherence</p>
        <p>Monthly Plan</p>
        <p>Arithmetic LE</p>
        <p>Variance</p>
        <p>Adjusted LE</p>
        <p>Variance vs Arithmetic</p>
      </div>
      <div className="table">
        { values.map((location: any, i: any) => (
          <div className="table-row">
            <p>{i + 1}. { location.name }</p>
            <p>{ Math.round(location.factoryPlanned).toLocaleString() }</p>
            <p>{ Math.round(location.real).toLocaleString() }</p>
            <p>{ isFinite(location.adherence) ? Math.round(location.adherence.toFixed(1)).toLocaleString() : '' }%</p>
            <p>{ Math.round(location.monthlyCorporatePlan).toLocaleString() }</p>
            <p>{ Math.round((location.expectedMonthProduction || 0)).toLocaleString() }</p>
            <p>{ Math.round((location.varianceCorporateMonthlyPlanAndArithmetic || 0)).toLocaleString().replace('-0', '0') }%</p>
            <p>{ Math.round((location.factoryPlannedMonth || 0)).toLocaleString() }</p>
            <p>{ Math.round((location.factoryPlanAndCorporatePlan || 0)).toLocaleString() }%</p>
          </div>
        ))}
      </div>
      <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', width: 200 }}>
        <ChartDropdown
          onChange={handleLocationDropDownChange}
          selectedDropDownItem={selectedDivisionDropdown}
          dropDownItems={divisionDropdown}
          dropDownOpen={divisionDropdownOpen}
          setDropDownOpen={setDivisionDropdownOpen}
          labelClassName="label-default"
        />
        <ChartDropdown
          onChange={handleDropDownChange}
          selectedDropDownItem={selectedDropDownItem}
          dropDownItems={dropDownItems}
          dropDownOpen={dropDownOpen}
          setDropDownOpen={setDropDownOpen}
          labelClassName="label-default"
        />
      </div>
    </div>
  );
}
