import { useCubeQuery } from '@cubejs-client/react';
import ChartDropdown from 'components/Dropdown/ChartDropdown';
import ChartTabs from 'components/Tabs/ChartTabs';
import React, { useState } from 'react';
import { format } from 'date-fns';
import { useSelector } from 'react-redux';
import { selectLocationsToAccess } from 'store/selectors/globalFilters.selectors';
// eslint-disable-next-line
import { groupBy } from 'lodash';
import LoadingSpinner from 'components/LoadingSpinner';
import EmptyState from 'components/EmptyState/EmptyState';
import { routinesMonthSelectordropDownItems } from 'utils/dateHelpers';
import { useTranslation } from 'react-i18next';
import { TRANSLATIONS } from 'types/enums';

const dropDownItems = routinesMonthSelectordropDownItems(true);

type Props = {
  selectedPillar: string,
  allPillarIds: string[]
};

const LocationTopBottom = ({ selectedPillar, allPillarIds } : Props) => {
  const { t } = useTranslation();
  const tabsItems = [{
    title: t(TRANSLATIONS.TOP),
  }, {
    title: t(TRANSLATIONS.BOTTOM),
  }];

  const locationsToAccess = useSelector(selectLocationsToAccess);
  const [dropDownOpen, setDropDownOpen] = useState(false);
  const [selectedDropDownItem, setSelectedDropDownItem] = useState(dropDownItems[0]);
  const [selectedTab, setSelectedTab] = useState(tabsItems[0]);

  const getDateRange = () => {
    return [
      format(selectedDropDownItem.content.startWeek, 'yyyy-MM-dd'),
      format(selectedDropDownItem.content.lastDayOfLastWeek, 'yyyy-MM-dd'),
    ];
  };

  const dateRange = getDateRange() as unknown as string[];

  const {
    resultSet: routinesToDoQuery,
    isLoading: loadingToDo,
    error: todoQueryError,
  } = useCubeQuery({
    measures: ['UserRoutines.count'],
    timeDimensions: [],
    dimensions: ['RoutinesAvailable.frequency', 'Users.position'],
    filters: [
      {
        dimension: 'Users.locationId',
        operator: 'equals',
        values: locationsToAccess,
      },
      {
        dimension: 'UserRoutines.deletedat',
        operator: 'notSet',
      },
      {
        dimension: 'RoutinesAvailable.pillarId',
        operator: 'equals',
        values: selectedPillar === '' ? allPillarIds : [selectedPillar],
      },
      {
        dimension: 'RoutinesAvailable.slug',
        operator: 'notEquals',
        values: ['pos-exception'],
      },
    ],
  });
  const {
    resultSet: routinesDoneQuery,
    isLoading: loadingDone,
    error: doneQueryError,
  } = useCubeQuery({
    measures: ['Routines.count'],
    timeDimensions: [
      {
        dimension: 'Routines.createdat',
        granularity: 'month',
        dateRange,
      },
    ],
    dimensions: ['RoutinesAvailable.frequency', 'Users.position'],
    filters: [
      {
        dimension: 'Users.locationId',
        operator: 'equals',
        values: locationsToAccess,
      },
      {
        dimension: 'UserRoutines.deletedat',
        operator: 'notSet',
      },
      {
        dimension: 'RoutinesAvailable.pillarId',
        operator: 'equals',
        values: selectedPillar === '' ? allPillarIds : [selectedPillar],
      },
      {
        dimension: 'RoutinesAvailable.slug',
        operator: 'notEquals',
        values: ['pos-exception'],
      },
    ],
  });

  let routinesLocations = [] as any;

  if (routinesToDoQuery?.tablePivot() && routinesDoneQuery?.tablePivot()) {
    const toDo = routinesToDoQuery.tablePivot();
    const done = routinesDoneQuery.tablePivot();

    const toDoG = groupBy(toDo, 'Users.position');
    const doneG = groupBy(done, 'Users.position');

    Object.entries(toDoG).forEach(([key, value]) => {
      let totalRoutinesToDo = 0;
      let totalRoutinesDone = 0;
      value.forEach(r => {
        const frequency = r['RoutinesAvailable.frequency'];
        const doneFrequency = doneG[key]?.find(k => k['RoutinesAvailable.frequency'] === frequency);
        const totalDone = doneFrequency && doneFrequency['Routines.count'] as number;
        const userRoutines = r['UserRoutines.count'] as number;
        if (frequency === '0 0 * * *') totalRoutinesToDo += userRoutines * 6 * selectedDropDownItem.content.weeks;
        if (frequency === '0 0 * * 0') totalRoutinesToDo += userRoutines * selectedDropDownItem.content.weeks;
        if (frequency === '0 0 1 * *') totalRoutinesToDo += userRoutines;

        totalRoutinesDone += (totalDone || 0);
      });
      const completion = (totalRoutinesDone / totalRoutinesToDo) * 100;
      if (key !== 'null') routinesLocations.push({ locationName: key, completion });
    });

    routinesLocations = routinesLocations
      .sort((a: any, b: any) => (a.completion < b.completion ? 1 : -1));
  }

  if (selectedTab.title === 'Bottom') routinesLocations = routinesLocations.reverse();

  const updateTab = (option: any) => {
    setSelectedTab(option);
  };

  // @ts-ignore
  const handleDropDownChange = ({ key }) => {
    setSelectedDropDownItem(dropDownItems.find(
      item => item.slug === key,
    ) || dropDownItems[0]);
    setDropDownOpen(false);
  };

  const isEmpty = (!(loadingToDo && loadingDone) && routinesLocations?.lenght > 0);

  if (
    loadingToDo
    || loadingDone
    || todoQueryError?.message === 'Error: Expected one parameter but nothing found'
    || doneQueryError?.message === 'Error: Expected one parameter but nothing found'
  ) {
    return (
      <div className="overview-locations-top-bottom">
        <LoadingSpinner height={271} />
        <ChartDropdown
          onChange={handleDropDownChange}
          selectedDropDownItem={selectedDropDownItem}
          dropDownItems={dropDownItems}
          dropDownOpen={dropDownOpen}
          setDropDownOpen={setDropDownOpen}
          labelClassName="label-default"
        />
      </div>
    );
  }

  return (
    <div className="overview-locations-top-bottom">
      <div>
        <ChartTabs
          tabsItems={tabsItems}
          setSelectedTab={updateTab}
          selectedTab={selectedTab}
        />
        <div className="table-description">
          <p />
          <p style={{ maxWidth: 70 }}>%</p>
        </div>
      </div>
      <div className="table">
        { isEmpty || !routinesLocations.length ? (
          <EmptyState />
        ) : (
          routinesLocations.map((location: any) => (
            <div className="table-row" key={location.locationName}>
              <p>{ location.locationName }</p>
              <p>{ (location.completion || 0).toFixed(2) }%</p>
            </div>
          )))}
      </div>
      <ChartDropdown
        onChange={handleDropDownChange}
        selectedDropDownItem={selectedDropDownItem}
        dropDownItems={dropDownItems}
        dropDownOpen={dropDownOpen}
        setDropDownOpen={setDropDownOpen}
        labelClassName="label-default"
      />
    </div>
  );
};

export default LocationTopBottom;
