import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import ImageLinks from 'utils/ImageLinks';
import Modal from 'components/Modal';
import { toast } from 'react-toastify';
import { COLOR_CODE_NEW, TRANSLATIONS, UserTypes } from 'types/enums';
import LabelledInputWithImage from 'components/Input/LabelledInputWithImage/LabelledInputWithImage';
import BasicButton from 'components/Button/BasicButton';
import request from 'utils/request';
import LoadingSpinner from 'components/LoadingSpinner';
import locationIcon from 'assets/icons/location.svg';
// eslint-disable-next-line @typescript-eslint/camelcase
import { useGetAllLocationsLazyQuery } from 'generated/graphql';
import './AddUserModal.scss';

type UserInfoType = {
  position: string;
  location: string;
  name: string;
};

type props = {
  onAdd: (data: UserInfoType) => void,
  closeModal: () => void;
};

const AddUserModal = (props: props) => {
  const { t } = useTranslation();

  const [invalidFields, setInvalidFields] = useState([] as string[]);
  const { closeModal, onAdd } = props;
  const [loading, setLoading] = useState<boolean>(false);
  const [positionsForBu, setPositionsForBu] = useState<any>([]);
  const [locations, setLocations] = useState<any>([]);
  const [userInfo, setUserInfo] = useState<UserInfoType>({ position: '', location: '', name: '' });

  function compareFn(a: any, b: any) {
    if (a.text < b.text) {
      return -1;
    }
    if (a.text > b.text) {
      return 1;
    }
    return 0;
  }

  const [callGetAllLocations] = useGetAllLocationsLazyQuery({
    fetchPolicy: 'cache-and-network',
    onCompleted: (res: any) => {
      const locationArray = res.getLocations
        ?.map((loc: any) => ({ text: loc?.name, value: loc?.slug }));
      locationArray.sort(compareFn);
      setLocations(locationArray);
      setLoading(false);
    },
    onError: () => {
      toast.error(t(TRANSLATIONS.SOMETHING_WENT_WRONG_TRY_LATER));
      setLoading(false);
      closeModal();
    },
  });

  useEffect(() => {
    setLoading(true);
    callGetAllLocations();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const submitHandler = async () => {
    setInvalidFields([]);
    const invalidFieldsTemp = [];
    if (userInfo.name.trim().length < 3) {
      invalidFieldsTemp.push('firstName');
    }
    if (!userInfo.position) {
      invalidFieldsTemp.push('position');
    }
    if (!userInfo.location) {
      invalidFieldsTemp.push('location');
    }
    if (invalidFieldsTemp.length > 0) {
      setInvalidFields(invalidFieldsTemp);
      return;
    }
    setLoading(true);
    try {
      const data = await request.post(`/api/v1/distribution-centers/${userInfo.location}/users`, {
        name: userInfo.name,
        position: userInfo.position === t(TRANSLATIONS.WITHOUT_POSITION) ? '' : userInfo.position,
        type: UserTypes.CertificatesOnly,
        createUserAccessEntry: true,
      });
      if (data) {
        const locationName = locations.find((loc: any) => loc.value === userInfo.location);
        const createdUserInfo = {
          position: userInfo.position,
          location: locationName?.text,
          name: userInfo.name,
          pin: data?.user?.pin,
          shortSlug: data?.user?.shortSlug,
        };
        setLoading(false);
        if (onAdd) {
          onAdd(createdUserInfo);
        }
      } else {
        toast.error(TRANSLATIONS.SOMETHING_WENT_WRONG_TRY_LATER);
        setLoading(false);
        closeModal();
      }
    } catch (e) {
      toast.error(TRANSLATIONS.SOMETHING_WENT_WRONG_TRY_LATER);
      console.error(e);
      setLoading(false);
      closeModal();
    }
  };

  const onChangeFirstName = (e: any) => {
    setUserInfo({
      ...userInfo,
      name: e.target.value,
    });
  };

  const onChangePosition = (e: any) => {
    setUserInfo({
      ...userInfo,
      position: e.target.value,
    });
  };

  const onChangeLocation = async (e: any) => {
    setLoading(true);
    setUserInfo({
      ...userInfo,
      location: e.target.value,
    });
    try {
      const response = await request.get(`/api/v1/positions-by-bu?cdSlug=${e.target.value}`);
      const positions = response?.positions?.map(({ position }: {position: string}) => (
        { text: position, value: position })
      );
      if (positions.length === 0) {
        positions.push(
          { text: t(TRANSLATIONS.WITHOUT_POSITION), value: t(TRANSLATIONS.WITHOUT_POSITION) },
        );
      }
      positions.sort(compareFn);
      setPositionsForBu(positions);
      setLoading(false);
    } catch (err) {
      console.error(err);
      toast.error(TRANSLATIONS.SOMETHING_WENT_WRONG_TRY_LATER);
      setLoading(false);
      closeModal();
    }
  };

  if (loading) {
    return (
      <Modal modalContainerStyles={{
        width: '60vw',
        left: '20%',
        maxHeight: '80vh',
        minHeight: '30vh',
        height: 'fit-content',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        position: 'absolute',
        top: '50%',
        transform: 'translateY(-50%)',
      }}
      >
        <LoadingSpinner />
      </Modal>
    );
  }

  return (
    <div className="addUser">
      <Modal
        modalContainerStyles={{ width: '60vw',
          left: '20%',
          maxHeight: '90vh',
          minHeight: '20vh',
          height: 'fit-content',
          display: 'flex',
          flexDirection: 'column',
          position: 'absolute',
          top: '50%',
          transform: 'translateY(-50%)' }}
        onOverlayClick={closeModal}
      >
        <div className="addUser__header">
          <div className="addUser__header__userNameAndIcon">
            <span>{t(TRANSLATIONS.ADD_USER_TO_DC)}</span>
          </div>
          <div className="addUser__header__userIcon">
            <img
              src={ImageLinks.userIcon}
              alt="UserPicture"
            />
          </div>
        </div>
        <div className="addUser__name">
          <LabelledInputWithImage
            onChange={e => onChangeFirstName(e)}
            placeholder={t(TRANSLATIONS.ENTER_NAME)}
            label={`${t(TRANSLATIONS.NAME)}(s)*`}
            value={userInfo.name}
            inputStyle={invalidFields.includes('firstName') ? {
              borderColor: COLOR_CODE_NEW.PRIMARY,
            } : {}}
          />
          {(invalidFields.includes('firstName') && !userInfo.name) && (
            <span className="addUser__name__input--error">{t(TRANSLATIONS.ENTER_NAME)}</span>
          )}
        </div>
        <div className="addUser__location">
          <LabelledInputWithImage
            inputStyle={invalidFields.includes('location') ? {
              borderColor: COLOR_CODE_NEW.PRIMARY,
              width: '250px',
            } : { width: '250px' }}
            onChange={e => onChangeLocation(e)}
            icon={locationIcon}
            placeholder={t(TRANSLATIONS.LOCATION)}
            label={t(TRANSLATIONS.LOCATION)}
            value={userInfo.location}
            dropdownOptions={locations || []}
            type="dropdown"
          />
          {(invalidFields.includes('location') && !userInfo.location) && (
            <span className="addUser__location__input--error">{t(TRANSLATIONS.SELECT_LOCATION)}</span>
          )}
        </div>
        {userInfo.location && (
          <div className="addUser__position">
            <LabelledInputWithImage
              inputStyle={invalidFields.includes('position') ? {
                borderColor: COLOR_CODE_NEW.PRIMARY,
                width: '250px',
              } : { width: '250px' }}
              onChange={e => onChangePosition(e)}
              icon={ImageLinks.positionIcon}
              placeholder={t(TRANSLATIONS.POSITION)}
              label={t(TRANSLATIONS.POSITION)}
              value={userInfo.position}
              dropdownOptions={positionsForBu || []}
              type="dropdown"
            />
            {(invalidFields.includes('position') && !userInfo.position) && (
              <span className="addUser__position__input--error">{t(TRANSLATIONS.SELECT_POSITION)}</span>
            )}
          </div>
        )}
        <div className="addUser__bottom_container">
          <div className="addUser__bottom_container__buttons">
            <div>
              <BasicButton
                text={t(TRANSLATIONS.CANCEL)}
                onClick={closeModal}
                className="addUser__bottom_container__buttons__cancel"
              />

              <BasicButton
                text={t(TRANSLATIONS.ADD)}
                onClick={submitHandler}
                className="addUser__bottom_container__buttons__add"
              />
            </div>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default AddUserModal;
