import React, { useState, useMemo, useEffect } from 'react';
import { MultiSelect } from 'react-multi-select-component';
import SeparatorWithTitle from 'components/Separator/SeparatorWithTitle';
import ChartDropdown from 'components/Dropdown/ChartDropdown';
import { FILTER_ACCESS_LEVELS, TRANSLATIONS } from 'types/enums';
import { useTranslation } from 'react-i18next';
import { getTranslatedDateTimeValue, getWeeksInfoForBusinessCalender } from 'utils/dateHelpers';
import { selectCurrentAccesLevel } from 'store/selectors/distributionCenters.selectors';
import { addDays, format } from 'date-fns';
import { capitalizeFirst } from 'utils/stringHelpers';
import { useCubeQuery } from '@cubejs-client/react';
import LoadingSpinner from 'components/LoadingSpinner';
import { useSelector } from 'react-redux';
import { selectUser } from 'store/selectors/user.selectors';
import StackedBarWithLine from './StackedBarWithLine/StackedBarWithLine';
import TableByRegionalManager from './TableByRegionalManager/TableByRegionalManager';
import TopCds from './TopCds/TopCds';
import Routines from './Routines';
import './RoutinesStyles.scss';
import RoutinesExcelDownload from './RoutinesExcelDownload';
import { DATA_STUDIO_REPORT_ULRS, isEspectro } from '../../../constants';

enum DropDowns {
  Month = 'Month',
  Range = 'Range',
  Pillar = 'Pillar'
}

const buList = [
  { label: 'México', value: 'mexico' },
  { label: 'Perú', value: 'peru' },
  { label: 'Ecuador', value: 'ecuador' },
  { label: 'Colombia', value: 'colombia' },
  { label: 'CAC', value: 'cac' },
];

const monthOptions = new Array(12).fill(null).map((_, index) => ({
  slug: capitalizeFirst(getTranslatedDateTimeValue(new Date(1997, index, 1), 'MMM')),
  label: capitalizeFirst(getTranslatedDateTimeValue(new Date(1997, index, 1), 'MMM')),
  months: [capitalizeFirst(format(new Date(1997, index, 1), 'MMMM'))],
  monthNum: index,
}));

const rangeSixMonths = () => {
  const currentYear = new Date().getFullYear();
  const result = [];
  for (let i = 2020; i <= currentYear; i++) {
    if (i !== currentYear) {
      result.push({
        slug: `Ene - Jun ${i}`,
        label: `Ene - Jun ${i}`,
        dateRange: [
          format(getWeeksInfoForBusinessCalender(0, i).startWeek, 'yyyy-MM-dd'),
          format(getWeeksInfoForBusinessCalender(5, i).lastDayOfLastWeek, 'yyyy-MM-dd'),
        ],
        months: ['January', 'February', 'March', 'April', 'May', 'June'],
      });
      result.push({
        slug: `Jul - Dic ${i}`,
        label: `Jul - Dic ${i}`,
        dateRange: [
          format(getWeeksInfoForBusinessCalender(6, i).startWeek, 'yyyy-MM-dd'),
          format(getWeeksInfoForBusinessCalender(11, i).lastDayOfLastWeek, 'yyyy-MM-dd'),
        ],
        months: ['July', 'August', 'September', 'October', 'November', 'December'],
      });
    } else {
      const currentMonth = new Date().getMonth() + 1;
      result.push({
        slug: `Ene - Jun ${i}`,
        label: `Ene - Jun ${i}`,
        dateRange: [
          format(getWeeksInfoForBusinessCalender(0, i).startWeek, 'yyyy-MM-dd'),
          format(getWeeksInfoForBusinessCalender(5, i).lastDayOfLastWeek, 'yyyy-MM-dd'),
        ],
        months: ['January', 'February', 'March', 'April', 'May', 'June'].slice(0, currentMonth),
      });
      if (currentMonth > 6) {
        result.push({
          slug: `Jul - Dic ${i}`,
          label: `Jul - Dic ${i}`,
          dateRange: [
            format(getWeeksInfoForBusinessCalender(6, i).startWeek, 'yyyy-MM-dd'),
            format(getWeeksInfoForBusinessCalender(11, i).lastDayOfLastWeek, 'yyyy-MM-dd'),
          ],
          months: ['July', 'August', 'September', 'October', 'November', 'December'].slice(
            0,
            currentMonth - 6,
          ),
        });
      }
    }
  }
  return result.reverse();
};

const halfYearlyDropDownOptions = rangeSixMonths();

const RoutinesHome = () => {
  const { t } = useTranslation();
  const { resultSet: pillars, isLoading: isLoadingPillars, error } = useCubeQuery({
    dimensions: [
      'PillarsList.id',
      'PillarsList.name',
    ],
  });
  const [dropDownOpen, setDropDownOpen] = useState('');
  const userData = useSelector(selectUser);

  const userCompanyInfo = userData?.get('company');

  const [selectedMonth, setSelectedMonth] = useState({
    slug: t(TRANSLATIONS.SELECT_MONTH),
    label: t(TRANSLATIONS.SELECT_MONTH),
    months: [''],
    dateRange: ['2021-01-04T14:37:34.953Z', '2021-01-31T14:37:34.953Z'],
    businessCalenderInfo: {},
  });

  // @ts-ignore
  const [pillarsList, setPillarsList] = useState<{
    slug: any;
    label: any;
  }[]>([]);
  const [selectedPillars, setSelectedPillars] = useState({ slug: '', label: 'Select Pillar' });
  const [allPillarIds, setAllPillarIds] = useState<string[]>([]);

  const [selectedPeriodRangeSixMonth, setSelectedPeriodRangeSixMonth] = useState(
    halfYearlyDropDownOptions[0],
  );
  const [selectedBUs, setSelectedBUs] = useState(buList);

  const handleDropDowns = (key: string, showDropdown:boolean = false) => {
    if (showDropdown) setDropDownOpen(key);
    else setDropDownOpen('');
  };

  useEffect(() => {
    // @ts-ignore
    if (pillars && pillars?.loadResponses[0]?.data) {
      const pillarTempList: { slug: any; label: any; }[] = [];
      const ids: string[] = [];
      // @ts-ignore
      pillars?.loadResponses[0]?.data.forEach(pillarItem => {
        if (pillarItem['PillarsList.id'] && pillarItem['PillarsList.name']) {
          ids.push(pillarItem['PillarsList.id'].toString());
          pillarTempList.push(
            { slug: pillarItem['PillarsList.id'], label: capitalizeFirst(pillarItem['PillarsList.name']) },
          );
        }
      });
      setPillarsList([{ slug: '', label: 'All Pillars' }, ...pillarTempList]);
      setAllPillarIds(ids);
    }
  }, [pillars]);

  const handleDropDownChangeDateRange = ({ key }: { key: any }) => {
    setSelectedPeriodRangeSixMonth(
      halfYearlyDropDownOptions.find(item => item.slug === key) || halfYearlyDropDownOptions[0],
    );
    setSelectedMonth({
      slug: 'Select Month',
      label: 'Select Month',
      months: [''],
      dateRange: [''],
      businessCalenderInfo: {},
    });
    handleDropDowns('');
  };

  const currentMonthDateRange = useMemo(() => {
    const dropdownSelectedYear = addDays(
      new Date(selectedPeriodRangeSixMonth.dateRange[0]),
      15,
    ).getFullYear();
    const monthDropdownArray = monthOptions.filter(
      month => selectedPeriodRangeSixMonth.months.includes(month.months[0]),
    );
    return monthDropdownArray.map(month => ({
      slug: `${month.slug} ${dropdownSelectedYear}`,
      label: `${month.label} ${dropdownSelectedYear}`,
      months: month.months,
      dateRange: [
        format(
          getWeeksInfoForBusinessCalender(month.monthNum, dropdownSelectedYear).startWeek,
          'yyyy-MM-dd',
        ),
        format(
          getWeeksInfoForBusinessCalender(month.monthNum, dropdownSelectedYear).lastDayOfLastWeek,
          'yyyy-MM-dd',
        ),
      ],
      businessCalenderInfo: {
        ...getWeeksInfoForBusinessCalender(month.monthNum, dropdownSelectedYear),
      },
    }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedMonth]);

  const handleDropMonthChange = ({ key }: { key: any }) => {
    setSelectedMonth(
      currentMonthDateRange.find(item => item.slug === key)
      || currentMonthDateRange[0],
    );
    handleDropDowns('');
  };

  const handleDropPillar = ({ key }: { key: any }) => {
    setSelectedPillars(
      pillarsList.find(item => item.slug.toString() === key.toString())
      || pillarsList[0],
    );
    handleDropDowns('');
  };

  const getDataStudioURL = () => {
    const iFrameStringUrl = DATA_STUDIO_REPORT_ULRS[userCompanyInfo.get('name')];
    return iFrameStringUrl;
  };
  const iframeUrl = getDataStudioURL();

  return (
    <div className="container-fluid overview-wrapper routines-home">
      <h2 className="routines-home__title">{`${t(TRANSLATIONS.ROUTINE_COMPLIANCE_HEADING)}`}</h2>
      {isEspectro && iframeUrl && (
        <div className="iframe-container">
          <iframe className="iframe" title="data studio" src={iframeUrl} />
        </div>
      )}
      {!isEspectro && (
      <div style={{ display: 'flex', marginBottom: '20px', alignItems: 'center', justifyContent: 'flex-start', marginTop: '40px' }}>
        <ChartDropdown
          onChange={handleDropDownChangeDateRange}
          selectedDropDownItem={selectedPeriodRangeSixMonth}
          dropDownItems={halfYearlyDropDownOptions}
          dropDownOpen={dropDownOpen === DropDowns.Range}
          setDropDownOpen={flag => handleDropDowns(DropDowns.Range, flag)}
          labelClassName="label-default"
        />
        <ChartDropdown
          onChange={handleDropMonthChange}
          // @ts-ignore
          selectedDropDownItem={selectedMonth}
          dropDownItems={currentMonthDateRange}
          dropDownOpen={dropDownOpen === DropDowns.Month}
          setDropDownOpen={flag => handleDropDowns(DropDowns.Month, flag)}
          labelClassName="label-default"
        />
        {!error && isLoadingPillars ? <div className="mb-4"><LoadingSpinner /></div>
          : (
            <ChartDropdown
              onChange={handleDropPillar}
              selectedDropDownItem={selectedPillars}
              dropDownItems={pillarsList}
              dropDownOpen={dropDownOpen === DropDowns.Pillar}
              setDropDownOpen={flag => handleDropDowns(DropDowns.Pillar, flag)}
              labelClassName="label-default"
            />
          )}
        {selectedMonth.months[0] === ''
          && (
            <>
              <div style={{ flex: 1 }} />
              <div style={{ flex: 1 }} />
            </>
          )}
        <div style={{ flex: 1 }} />
      </div>
      )}
      {!isEspectro && (
      <>
        <div className="row">
          <div className="col-12 card routines-home__card">
            <span className="routines-home__card__title">MAZ</span>
            <StackedBarWithLine
              allPillarIds={allPillarIds}
              selectedPillar={selectedPillars.slug.toString()}
              months={selectedMonth.months[0] !== '' ? selectedMonth.months : selectedPeriodRangeSixMonth.months}
              dateRange={selectedMonth.months[0] !== '' ? selectedMonth.dateRange : selectedPeriodRangeSixMonth.dateRange}
            />
          </div>
        </div>
        <SeparatorWithTitle title="GRO" />
        <div className="row">
          <div className="col-12 card routines-home__card">
            <TableByRegionalManager
              allPillarIds={allPillarIds}
              selectedPillar={selectedPillars.slug.toString()}
            />
          </div>
        </div>
        <SeparatorWithTitle title={`${t(TRANSLATIONS.ROUTINE_COMPLIANCE_BU)}`} />
        <div className="row routines-home__filters-row">
          <MultiSelect
            options={buList}
            value={selectedBUs}
            onChange={setSelectedBUs}
            labelledBy="Ver todas"
            disableSearch
          />
        </div>
        <div className="row">
          {selectedBUs?.map(({ value, label }) => (
            <div className="col-6 card routines-home__card">
              <span className="routines-home__card__title">{label}</span>
              <StackedBarWithLine
                allPillarIds={allPillarIds}
                selectedPillar={selectedPillars.slug.toString()}
                key={value}
                bu={value}
                months={selectedMonth.months[0] !== '' ? selectedMonth.months : selectedPeriodRangeSixMonth.months}
                dateRange={selectedMonth.months[0] !== '' ? selectedMonth.dateRange : selectedPeriodRangeSixMonth.dateRange}
              />
            </div>
          ))}
        </div>
        <SeparatorWithTitle title={`Ranking de ${t(TRANSLATIONS.ROUTINE_COMPLIANCE_CD)}s`} />
        <div className="row">
          <div className="col-6 card routines-home__card override-routines-home__card">
            <TopCds
              allPillarIds={allPillarIds}
              selectedPillar={selectedPillars.slug.toString()}
              isTopOffenders={false}
              dateRange={selectedMonth.months[0] !== '' ? selectedMonth.dateRange : selectedPeriodRangeSixMonth.dateRange}
            />
          </div>
          <div className="col-6 card routines-home__card override-routines-home__card">
            <TopCds
              allPillarIds={allPillarIds}
              selectedPillar={selectedPillars.slug.toString()}
              isTopOffenders
              dateRange={selectedMonth.months[0] !== '' ? selectedMonth.dateRange : selectedPeriodRangeSixMonth.dateRange}
            />
          </div>
        </div>
        <SeparatorWithTitle title="Detalle de rutinas" />
        <Routines
          allPillarIds={allPillarIds}
          selectedPillar={selectedPillars.slug.toString()}
        />
      </>
      )}
      {/* {(accessLevel === FILTER_ACCESS_LEVELS.SUBZONE
      || accessLevel === FILTER_ACCESS_LEVELS.DISTRIBUTION_CENTER) && <RoutinesExcelDownload />} */}
    </div>
  );
};

export default RoutinesHome;
