import React, { useState } from 'react';
import { format } from 'date-fns';

import ChartTabs from 'components/Tabs/ChartTabs';
import PillWithIcon from 'components/PillWithIcon/PillWithIcon';
import LoadingSpinner from 'components/LoadingSpinner';

import './SafetyAcademyPreview.scss';
import { parseISO } from 'date-fns/esm';
import { POSTS_TYPES, TRANSLATIONS } from 'types/enums';
import { useGetPostsQuery } from 'generated/graphql';

import { useTranslation } from 'react-i18next';

export const postAuthor = 'Juan Carlos León';

const SafetyAcademyPreview = () => {
  const { t } = useTranslation();
  const tabs = [
    { title: t(TRANSLATIONS.PREVENTION_CAMPAIGNS), value: '', type: POSTS_TYPES.PREVENTION_CAMPAIGNS },
    { title: t(TRANSLATIONS.BEST_PRACTICES), value: '', type: POSTS_TYPES.BEST_PRACTICES },
  ];
  const [selectedTab, setSelectedTab] = useState(tabs[0]);

  const { loading, data, error } = useGetPostsQuery({
    variables: { limit: 1, type: selectedTab.type as any },
  });

  const posts = data?.getPosts?.posts;
  const postObject = posts && posts[0];
  const {
    title,
    content,
    postedAt,
    attachments,
    bannerImage,
    author,
  } = postObject || {};

  return (
    <div className="safety-academy-preview">
      <ChartTabs
        tabsItems={tabs}
        selectedTab={selectedTab}
        setSelectedTab={setSelectedTab as any}
      />
      {error && <div> Something Went Wrong </div>}
      {!error && !loading ? (
        <div className="main">
          <div className="main__top">
            <img alt="banner" src={bannerImage as any} />
            <div className="main__top__right">
              <div>
                <span className="main__top__right__title">{title}</span>
                <span className="main__top__right__data">
                  {author} {postedAt && format(parseISO(postedAt), 'dd/MM/yyyy')}
                </span>
              </div>
              <ul className="main__top__right__buttons-row">
                {(attachments || []).map(attachment => (
                  <PillWithIcon pill={attachment} key={attachment?.url} />
                ))}
              </ul>
            </div>
          </div>
          <div
            className="main__bottom"
            style={{ overflowWrap: 'break-word' }}
            // eslint-disable-next-line react/no-danger
            dangerouslySetInnerHTML={{
              __html: content || '',
            }}
          />
        </div>
      ) : (
        <LoadingSpinner height={352} />
      )}
    </div>
  );
};

export default SafetyAcademyPreview;
