import React, { CSSProperties } from 'react';
import './Action.scss';

type ActionButtonProps = {
  text: any;
  onClick?(e?: any): void;
  className?: string;
  background?: string;
  disabled?: boolean;
  style?: CSSProperties;
};
const ActionButton = ({
  text,
  style,
  disabled,
  onClick,
  className,
  background,
}:ActionButtonProps) => {
  return (
    <button
      className={`${className} actionButton__main`}
      type="button"
      disabled={disabled}
      onClick={onClick}
      style={{ background, ...style }}
    >{text}
    </button>
  );
};

export default ActionButton;
