import { ChecklistAnswers } from 'espectro-utils/checklistRendererUtils/types';
import { ChecklistBit } from 'features/Espectro/types';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import ImageLinks from 'utils/ImageLinks';
import Overlay from 'components/Espectro/Overlay';
import ActionButton from 'components/Espectro/Buttons/Action/Action';
import { COLOR_CODE, TRANSLATIONS } from 'types/enums';

type Props = {
  checklist: any;
  bit: ChecklistBit;
  sectionIndex: number;
  itemIndex: number;
  nestedChecklistAnswersMap: {
    [key: string]: any;
  };
  deleteNestedChecklist: (
    nestedChecklistIndex: number,
    sectionIndex: number,
    itemIndex: number,
    key?: string,
  ) => void;
  handleOpenNestedChecklist: (bit: ChecklistBit, sectionIndex: number, itemIndex: number) => void
  handleOpenAnsweredNestedChecklist: (bit: ChecklistBit, sectionIndex: number,
    itemIndex: number, hash: string, answer: ChecklistAnswers) => void
};

function NestedChecklist({
  checklist,
  bit,
  deleteNestedChecklist,
  itemIndex,
  nestedChecklistAnswersMap,
  sectionIndex,
  handleOpenNestedChecklist,
  handleOpenAnsweredNestedChecklist,
}: Props) {
  const canBeRepeated = checklist[sectionIndex].items[itemIndex].canBeRepeated ?? false;
  const staticPrefix = `checklist[${sectionIndex}].items[${itemIndex}].bits[0]`;
  const [matchingKeys, setMatchingKeys] = useState<string[]>([]);
  const { t } = useTranslation();
  const answersKeysLength = Object.keys(nestedChecklistAnswersMap).length;
  const [isModalOpen, setIsModalOpen] = useState(false);

  useEffect(() => {
    setMatchingKeys(Object.keys(
      nestedChecklistAnswersMap,
    ).filter(uKey => uKey.startsWith(staticPrefix)));
  }, [answersKeysLength, nestedChecklistAnswersMap, staticPrefix]);

  const deleteNestedAnswerMap = (key: string, index: number, secI: number, itmI: number) => {
    deleteNestedChecklist(secI, itmI, index, key);
    delete nestedChecklistAnswersMap[key];
    setIsModalOpen(false);

    setMatchingKeys(Object.keys(
      nestedChecklistAnswersMap,
    ).filter(uKey => uKey.startsWith(staticPrefix)));
  };

  return (
    <div className="ChecklistQuestions__nestedchecklist_container">
      {(matchingKeys.length) ? (
        <div>
          {matchingKeys.map((key: string, index) => {
            return (
              <div style={{ display: 'flex', gap: 10, alignItems: 'center', justifyContent: 'center', marginBottom: '1rem' }}>
                <div
                  role="button"
                  onClick={() => {
                    handleOpenAnsweredNestedChecklist(
                      bit,
                      sectionIndex,
                      itemIndex,
                      key,
                      nestedChecklistAnswersMap[key],
                    );
                  }}
                  style={{ color: '#56CD9D', padding: '8px', border: '1px solid #F0F0F0', borderRadius: '8px' }}
                >
                  {index + 1}.{' '}{bit.props.title}
                </div>

                <button
                  type="button"
                  className="delete_button"
                  onClick={() => setIsModalOpen(true)}
                >
                  <img src={ImageLinks.espectro.deleteBlack} width={30} height={30} alt="delete" />
                </button>
                <Overlay open={isModalOpen} onClose={() => setIsModalOpen(false)}>
                  <div style={{ maxWidth: 600, display: 'flex', flexDirection: 'column', marginLeft: 'auto', marginRight: 'auto', backgroundColor: 'white', justifyContent: 'center', alignItems: 'center', marginTop: 200, padding: 10, paddingBottom: 15, paddingTop: 15 }}>
                    <h2>{t(TRANSLATIONS.DRILLS_CHECKLIST)}</h2>
                    <p style={{ paddingBottom: 20 }}>{t(TRANSLATIONS.YOU_WILL_NOT_BE_ABLE_TO_REVERSE_THE_ACTION_DO_YOU_WANT_TO_DELETE_THE_CHECKLIST)}
                    </p>
                    <div style={{ display: 'flex', gap: 15 }}>
                      <ActionButton text={t(TRANSLATIONS.CANCEL)} onClick={() => setIsModalOpen(false)} className="cancel_button" />
                      <ActionButton
                        text={t(TRANSLATIONS.YES_DELETE)}
                        background={COLOR_CODE.RED}
                        onClick={() => deleteNestedAnswerMap(key, index, sectionIndex, itemIndex)}
                      />
                    </div>
                  </div>
                </Overlay>
              </div>
            );
          })}
          {canBeRepeated && (
            <button
              onClick={() => handleOpenNestedChecklist(bit, sectionIndex, itemIndex)}
              type="button"
              className="ChecklistQuestions__nestedchecklist_addanother"
            >{t(TRANSLATIONS.ADD_ANOTHER)}
            </button>
          )}
        </div>
      ) : (
        <button
          onClick={() => handleOpenNestedChecklist(bit, sectionIndex, itemIndex)}
          type="submit"
          className="ChecklistQuestions__nestedchecklist_button"
        >
          {t(TRANSLATIONS.CONTESTAR_CHECKLIST)}
        </button>
      )}
    </div>
  );
}

export default NestedChecklist;
