import React, { useState, useRef } from 'react';
import './PDT.scss';
import { useTranslation } from 'react-i18next';
import req from 'utils/request';
import { TRANSLATIONS } from 'types/enums';
// import get from 'lodash/get';
import { toast } from 'react-toastify';
import BasicButton from 'components/Button/BasicButton';
// import DatePicker from 'react-datepicker';

const validFileExtensions = ['.csv', '.xlsx'];
const Validate = (files: any) => {
  for (let i = 0; i < files.length; i++) {
    const oInput = files[i];

    const sFileName = oInput.name;
    if (sFileName.length > 0) {
      let blnValid = false;
      for (let j = 0; j < validFileExtensions.length; j++) {
        const sCurExtension = validFileExtensions[j];
        console.log({ sCurExtension, sFileName });
        if (sFileName.substr(sFileName.length - sCurExtension.length, sCurExtension.length)
          .toLowerCase() === sCurExtension.toLowerCase()) {
          blnValid = true;
          break;
        }
      }

      if (!blnValid) {
        alert(`Sorry, ${sFileName} is invalid, allowed extensions are: ${validFileExtensions.join(', ')}`);
        return false;
      }
    }
  }

  return true;
};

const PDTContainer = () => {
  const { t } = useTranslation();
  const fileRef = useRef<HTMLInputElement>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const handleUploadButton = () => {
    if (fileRef.current !== null) {
      fileRef.current.click();
    }
  };

  const handleAttachmentsUpload = async (e: any) => {
    const { files } = e.target;
    if (files?.length && Validate(files)) {
      const config = {
        headers: { 'content-type': 'multipart/form-data' },
      };
      const data = new FormData();

      data.set('file', files[0]);

      try {
        setLoading(true);
        await req.post('/api/v1/pdt/upload-external-company-excel', data, config);
        setLoading(false);
        toast.success('Uploaded Successfully!');
      } catch (err) {
        setLoading(false);
        toast.error('Error while uploading');
      } finally {
        if (fileRef.current !== null) {
          setLoading(false);
          fileRef.current.value = '';
        }
      }
    }
  };

  return (
    <section className="PDT_container">
      <div className="pdt_header">
        <p className="title">{t(TRANSLATIONS.PDT)}</p>
        <div className="upload_container">
          <div className="upload_button">
            <BasicButton
              text="Upload"
              className="upload_button_filled"
              onClick={handleUploadButton}
              disabled={loading}
            />
            <input
              ref={fileRef}
              className="hidden"
              type="file"
              onChange={handleAttachmentsUpload}
              name="file"
              multiple
              accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
            />
          </div>
        </div>
      </div>
    </section>
  );
};

export default PDTContainer;
