import { Tooltip } from '@material-ui/core';
import React, { LegacyRef } from 'react';
import './InputWithLabel.scss';

type Props = {
  onChange(e: any): void;
  placeholder?: string;
  value?: string;
  name?: string;
  label?: string;
  error?: string;
  type?: 'text' | 'password' | 'number' | 'search';
  style?: React.CSSProperties;
  maxLength?: number;
  inputClassName?: string;
  labelClassName?: string;
  containerClassName?: string;
  labelStyles?: React.CSSProperties;
  inputImg?: any,
  disabled?: boolean,
  showTooltip?: boolean,
  inputRef?:LegacyRef<HTMLInputElement>
};

const InputWithLabel = ({
  onChange,
  placeholder,
  value,
  name,
  label = '',
  error,
  type = 'text',
  style,
  maxLength = 200,
  inputClassName = '',
  labelClassName = '',
  containerClassName = '',
  labelStyles = {},
  inputImg = '',
  disabled = false,
  showTooltip = false,
  inputRef,
}: Props) => (
  <div className={`input-with-label ${containerClassName}`}>
    {label ? (
      <div className={`labels ${labelClassName}`}>
        {
          showTooltip ? (
            <Tooltip title="Read Only" arrow placement="right">
              <p style={labelStyles} className="default-label">{label}</p>
            </Tooltip>
          ) : (
            <p style={labelStyles} className="default-label">{label}</p>
          )
        }
        <p className="error-text">{error}</p>
      </div>
    ) : null}
    <div style={{ position: 'relative' }}>
      {inputImg && <img className="inputImg" src={inputImg} alt="img" />}
      <input
        ref={inputRef}
        style={{ ...style, paddingLeft: inputImg ? '45px' : '' }}
        type={type}
        value={value}
        placeholder={placeholder}
        name={name}
        className={`form-input  ${inputClassName} ${error ? 'form-error' : ''}`}
        onChange={onChange}
        maxLength={maxLength}
        disabled={disabled}
      />
      {inputImg && <img className="inputImg" src={inputImg} alt="img" />}
    </div>
  </div>
);

export default InputWithLabel;
