import React from 'react';
import './UserRegisterSuccess.scss';
import Modal from 'components/Modal';
import greenTick from 'assets/icons/check-green.svg';
import duplicate from 'assets/icons/duplicate.svg';
import { TRANSLATIONS } from 'types/enums';
import { useTranslation } from 'react-i18next';
import { capitalize } from 'utils/stringHelpers';

type UserInfoType = {
  position: string;
  location: string;
  name: string;
  pin: string;
  shortSlug: string;
};

type Props = {
  userInfo: UserInfoType;
  close?: ()=>void;
};

const UserRegisterSuccess = (props: Props) => {
  const { userInfo, close } = props;
  const { t } = useTranslation();

  const copyToClipboard = () => {
    navigator.clipboard.writeText(userInfo.shortSlug);
  };

  return (
    <Modal
      onOverlayClick={() => {
        if (close) {
          close();
        }
      }}
      modalContainerStyles={{
        width: '60vw',
        height: '65vh',
      }}
    >
      <div className="mainContainer">
        <div className="mainContainer__img-container">
          <img
            src={greenTick}
            className="mainContainer__img-container__img"
            alt="Success"
          />
        </div>
        <span className="mainContainer__pin-header">{t(TRANSLATIONS.PIN_CREATED_SUCCESSFULLY)}</span>
        <span className="mainContainer__pin">{`${userInfo.pin}`}</span>
        <div className="mainContainer__qrcode-container">
          <span className="mainContainer__qrcode-container__title">{t(TRANSLATIONS.QR_CODE)}</span>
          <div className="mainContainer__qrcode-container__code-container">
            <span className="mainContainer__qrcode-container__code-container__title">{userInfo.shortSlug}</span>
            <img onClick={copyToClipboard} className="mainContainer__qrcode-container__code-container__icon" src={duplicate} alt="qrcode" />
          </div>
          <span className="mainContainer__qrcode-container__description">{t(TRANSLATIONS.SEARCH_THE_USER_BY_QRCODE_TO_CONFIGURE)}</span>
        </div>
        <div className="mainContainer__border" />
        <div className="mainContainer__details">
          <div className="mainContainer__details__labelValue">
            <span className="mainContainer__details__labelValue__label">{t(TRANSLATIONS.NAME)}(s)</span>
            <span className="mainContainer__details__labelValue__value">{capitalize(userInfo.name)}</span>
          </div>
          <div className="mainContainer__details__labelValue">
            <span className="mainContainer__details__labelValue__label">{t(TRANSLATIONS.POSITION)}</span>
            <span className="mainContainer__details__labelValue__value">{userInfo.position.toUpperCase()}</span>
          </div>
          <div className="mainContainer__details__labelValue">
            <span className="mainContainer__details__labelValue__label">{t(TRANSLATIONS.LOCATION)}</span>
            <span className="mainContainer__details__labelValue__value">{capitalize(userInfo.location)}</span>
          </div>
        </div>
        <div className="mainContainer__border" />
      </div>
    </Modal>

  );
};

export default UserRegisterSuccess;
