import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { upperFirst } from 'lodash';

import NavigationDrawerWithSideMenu from 'components/NavigationDrawer/NavigationDrawerWithVerticalMenu';
import SelectDropdown from 'components/Dropdown/SelectDropdown';
import MonthlyTimeFilter from 'components/MonthlyTimeFilter';
import LoadingSpinner from 'components/LoadingSpinner';
import Card from 'components/Cards/Card';
import Header from 'components/Header';
import Disclaimer from 'components/Disclaimer/Disclaimer';

import { selectCurrentAccesLevel, selectLocationsToShow } from 'store/selectors/distributionCenters.selectors';
import { getZonesWithIncidents } from 'store/actions/corporateDashboard.actions';
import { setBusinessUnit, setCluster, setSubzone, setZone } from 'store/actions/globalFilters.actions';
import { downloadIncidents, downloadCertificates, downloadAmbientalRisks } from 'store/actions/distributionCenter.actions';
import { setMonthlyDropdownChange } from 'store/actions/timeFilter.actions';
import { selectSelectedLocationsSlug } from 'store/selectors/globalFilters.selectors';
import { selectUser, selectUserLocation, selectUserType } from 'store/selectors/user.selectors';

import defaultRoutes from 'utils/defaultRoutes';
import { FILTER_ACCESS_LEVELS, TRANSLATIONS } from 'types/enums';
import { NameAndSlug } from 'types/interfaces';

import './style.scss';
import { userCanDownloadExcelSupply } from 'utils/canUserAccessFeature';

const DistributionCenters = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();
  const userType = useSelector(selectUserType);
  const userDetails = useSelector(selectUser);
  const user = useSelector((state: any) => state.get('user'))?.toJS();

  const arrayOfLocationToShow = useSelector(selectLocationsToShow);
  const accessLevel = useSelector(selectCurrentAccesLevel);
  const selectedLocations = useSelector(selectSelectedLocationsSlug);
  const location = useSelector(selectUserLocation);

  const [currentMonth, setCurrentMonth] = useState('currentMonth');
  const [isUpdatingInfo, setUpdatingInfo] = useState(false);
  const isSupply = userType === 'DoctorReporterOnlySupply'
    || userType === 'SubzoneGeneralManagerSupply'
    || userType === 'DistributionCenterManagerSupply'
    || userType === 'ReporterOnlySupply'
    || userType === 'CorporateSupply';

  const updateZonesWithIncidents = async () => {
    setUpdatingInfo(true);
    await dispatch(getZonesWithIncidents());
    setUpdatingInfo(false);
  };
  useEffect(() => {
    dispatch(setMonthlyDropdownChange('currentMonth'));
  }, [dispatch]);

  useEffect(() => {
    updateZonesWithIncidents();
    // eslint-disable-next-line
  }, [dispatch, currentMonth]);

  const routes = defaultRoutes(t, userType, location, userDetails);

  let downloadDropdownContent = [
    { label: t(TRANSLATIONS.INCIDENTS), value: TRANSLATIONS.INCIDENTS },
    { label: upperFirst(t(TRANSLATIONS.CERTIFICATES)), value: TRANSLATIONS.CERTIFICATES },
    // { label: upperFirst(t(TRANSLATIONS.BOTH)), value: TRANSLATIONS.BOTH },
  ];

  if (isSupply || userCanDownloadExcelSupply(user?.roleDetails?.slug, user?.features)) {
    downloadDropdownContent = [
      { label: t(TRANSLATIONS.INCIDENTS), value: TRANSLATIONS.INCIDENTS },
      { label: upperFirst(t(TRANSLATIONS.CERTIFICATES)), value: TRANSLATIONS.CERTIFICATES },
      { label: upperFirst(t(TRANSLATIONS.AMBIENTAL_RISKS)), value: TRANSLATIONS.AMBIENTAL_RISKS },
    ];
  }

  const handleCardClick = ({ name, slug }: NameAndSlug, e: any) => {
    if (e.target.id === 'card') {
      switch (accessLevel) {
        case FILTER_ACCESS_LEVELS.DISTRIBUTION_CENTER:
          history.push(`/distribution-centers/${slug}`);
          break;
        case FILTER_ACCESS_LEVELS.CLUSTER:
          dispatch(setCluster({ name, slug }));
          break;
        case FILTER_ACCESS_LEVELS.SUBZONE:
          dispatch(setSubzone({ name, slug }));
          break;
        case FILTER_ACCESS_LEVELS.ZONE:
          dispatch(setZone({ name, slug }));
          break;
        case FILTER_ACCESS_LEVELS.BUSINESS_UNIT:
          dispatch(setBusinessUnit({ name, slug }));
          break;
        default:
          break;
      }
    }
  };

  const downloadReport = (dowloadOption: string,
    locationSlug: string,
    level?: string,
    locationType?: string) => {
    const param = `${level}=${locationSlug}`;
    const isFactory = locationType === 'FACTORY' || false;

    switch (dowloadOption) {
      case TRANSLATIONS.BOTH:
        dispatch(downloadIncidents(param, isFactory));
        dispatch(downloadCertificates(param));
        break;
      case TRANSLATIONS.INCIDENTS:
        dispatch(downloadIncidents(param, isFactory));
        break;
      case TRANSLATIONS.CERTIFICATES:
        dispatch(downloadCertificates(param));
        break;
      case TRANSLATIONS.AMBIENTAL_RISKS:
        dispatch(downloadAmbientalRisks(param));
        break;
      default:
        break;
    }
  };

  const handleDownloadOptionChange = ({ target: { value } }: any) => {
    // downloadReport(value, null, accessLevel);
    if (accessLevel === FILTER_ACCESS_LEVELS.DISTRIBUTION_CENTER) {
      return downloadReport(
        value, selectedLocations.subzone, FILTER_ACCESS_LEVELS.SUBZONE,
      );
    }
    if (accessLevel === FILTER_ACCESS_LEVELS.SUBZONE) {
      return downloadReport(
        value, selectedLocations.zone, FILTER_ACCESS_LEVELS.ZONE,
      );
    }
    if (accessLevel === FILTER_ACCESS_LEVELS.ZONE) {
      return downloadReport(
        value, selectedLocations.businessUnit, FILTER_ACCESS_LEVELS.BUSINESS_UNIT,
      );
    }
    downloadReport(value, '', '');
  };

  const handleMonthChange = (value: 'typeOf MonthlyOptions') => {
    setCurrentMonth(value);
    dispatch(setMonthlyDropdownChange(value));
  };

  return (
    <>
      <Header />
      <NavigationDrawerWithSideMenu {...{ routes, openDrawer: true }} />
      {arrayOfLocationToShow ? (
        <div className="distribution-centers">
          <div>
            <div className="distribution-centers__top-menu">
              <div className="distribution-centers__top-menu__buttons">
                <SelectDropdown
                  name="Download Dropdown"
                  onChange={handleDownloadOptionChange}
                  value=""
                  dropDownClassName="dropdown-constraints"
                  dropdownContent={downloadDropdownContent}
                  className="download-dropdown"
                  placeholder={`${upperFirst(t(TRANSLATIONS.DOWNLOAD_ALL))}`}
                  bgColor="transparent"
                />
                <MonthlyTimeFilter
                  onChange={handleMonthChange}
                  currentValue={currentMonth}
                  t={t}
                  bgColor="transparent"
                />
                { isUpdatingInfo && (<LoadingSpinner text=" " height={25} />)}
              </div>
            </div>
            <div className="distribution-centers__list">
              {arrayOfLocationToShow?.list?.map((item: any) => {
                return (
                  <Card
                    item={item}
                    handleClick={handleCardClick}
                    key={item.slug}
                    downloadReport={downloadReport}
                  />
                );
              })}
            </div>
          </div>
          { arrayOfLocationToShow?.list?.size === 0 && (
            <p
              style={{ textAlign: 'center' }}
            >
              {t(TRANSLATIONS.YOU_DONT_HAVE_ANY_LOCATION_ASSIGNED_PLEASE_CONTACT_SUPPORT)}
            </p>
          )}
          <Disclaimer message={TRANSLATIONS.USER_ACCESS_DISCLAIMER} />
        </div>
      ) : (
        <div className="distribution-centers__loader">
          <LoadingSpinner />
        </div>
      )}
    </>
  );
};

export default DistributionCenters;
