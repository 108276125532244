import { TRANSLATIONS } from 'types/enums';
import {
  CountByPosition,
  Maybe,
  MonthWiseScore,
} from 'generated/graphql';

export enum GetModulesAndSubmodulesCountType {
  AllUsers = 'ALL_USERS',
  LastThreeMonths = 'LAST_THREE_MONTHS',
  MoreThanThreeMonths = 'MORE_THAN_THREE_MONTHS'
}

export enum CATEGORYS {
  IMPACTED = 'impacted',
  FUNCIONAL = 'funcional',
  TECHNICAL = 'técnicas',
  AUTONOMOUS = 'autonomía',
}

export enum MONTH {
  JANUARY = 'ENERO',
  FEBRUARY = 'FEBRERO',
  MARCH = 'MARZO',
  APRIL = 'ABRIL',
  MAY = 'MAYO',
  JUNE = 'JUNIO',
  JULY = 'JULIO',
  AUGUST = 'AGOSTO',
  SEPTEMBER = 'SEPTIEMBRE',
  OCTOBER = 'OCTUBRE',
  NOVEMBER = 'NOVIEMBRE',
  DECEMBER = 'DICIEMBRE',
}

export enum CATEGORYS_VALUE {
  HC_IMPACTADO = 'HC impactado',
  INDUCCION_FUNIONAL = 'Inducción funcional',
  HABILIDADES_TECHICAS = 'Habilidades técnicas',
  HABILIDADES_AUTONOMAS = 'Habilidades autónomas',
}

export enum TABS_CHART {
  WAREHOUSE_OPERATOR = 'warehouse_operator',
  DEVLIVERY = 'delivery',
  WAREHOUSE_PICKING_SORTING = 'warehouse_picking_sorting',
}

export enum TABS_CHART_VALUE {
  MONTACARGUISTAS = 'Montacarguistas',
  REPARTO = 'Reparto',
  AYUDANTES_DE_ALMACEN = 'Ayudantes de almacén',
}

export enum SKAP_TITLE {
  FUNCTIONAL = 'Funcional',
  TECHNICAL = 'Technical',
  AUTONOMOUS = 'Autonomous',
}

export const categoriesNullDataArrayFunc = (t: Function) => {
  const categoriesNullDataArray = [
    {
      slug: t(TRANSLATIONS.IMAPACTED_HC),
      count: '0',
      total: 0,
      percentage: 0,
    },
    {
      slug: t(TRANSLATIONS.FUNCTIONAL_INDUCTION),
      count: '0',
      total: 0,
      percentage: 0,
    },
    {
      slug: t(TRANSLATIONS.TECHNICAL_SKILLS),
      count: '0',
      total: 0,
      percentage: 0,
    },
    {
      slug: t(TRANSLATIONS.AUTONOMOUS_SKILLS),
      count: '0',
      total: 0,
      percentage: 0,
    },
  ];
  return categoriesNullDataArray;
};
export const tabsNullDataArrayFunc = (t: Function) => {
  const tabsNullDataArray = [
    {
      title: t(TRANSLATIONS.FORKLIFT_DRIVER),
      total: 0,
      impactedCount: 0,
      chart: [{
        title: t(TRANSLATIONS.FUNCTIONAL),
        count: 0,
      },
      {
        title: t(TRANSLATIONS.TECHNICAL),
        count: 0,
      },
      {
        title: t(TRANSLATIONS.AUTONOMOUS),
        count: 0,
      },
      ],
    },
    {
      title: t(TRANSLATIONS.SKAP_DISTRIBUTION),
      total: 0,
      impactedCount: 0,
      chart: [{
        title: t(TRANSLATIONS.FUNCTIONAL),
        count: 0,
      },
      {
        title: t(TRANSLATIONS.TECHNICAL),
        count: 0,
      },
      {
        title: t(TRANSLATIONS.AUTONOMOUS),
        count: 0,
      }],
    },
    {
      title: t(TRANSLATIONS.WAREHOUSE_HELPERS),
      total: 0,
      impactedCount: 0,
      chart: [{
        title: t(TRANSLATIONS.FUNCTIONAL),
        count: 0,
      },
      {
        title: t(TRANSLATIONS.TECHNICAL),
        count: 0,
      },
      {
        title: t(TRANSLATIONS.AUTONOMOUS),
        count: 0,
      }],
    },
  ];
  return tabsNullDataArray;
};

export type RampUpData = {
  title: string;
  series: number;
}[];

export type PieChartData = {
  labels: string[];
  series: number[];
};
export type RampUpChartData = {
  month: string[];
  score: number[];
  total: number[];
};

export type CategoryArray = {
  slug: any;
  total?: number;
  count: string;
  percentage: number;
}[];

export type CategoryData = {
  slug: any;
  total: number;
  count: number;
}[];

export type TabChartArrayData = {
  title: string;
  count: number;
};
export type SelectedTabData = {
  title: string;
  total?: number;
  impactedCount?: number;
  chart: TabChartArrayData[];
};

export type TabsData = {
  slug: string;
  total: number;
  impactedCount: number;
  data: TabChartArrayData[];
}[];

export type TabsArray = {
  title: string;
  total?: number;
  impactedCount?: number;
  chart: TabChartArrayData[];
}[];

export const categoryArray = (categories: any, t: Function) => {
  let categoryData = <CategoryArray>[];
  if (categories.length !== 0) {
    categories.map((category: any) => {
      let slug = <string>'';
      switch (category?.slug) {
        case CATEGORYS.IMPACTED:
          slug = t(TRANSLATIONS.IMAPACTED_HC);
          break;
        case CATEGORYS.FUNCIONAL:
          slug = t(TRANSLATIONS.FUNCTIONAL_INDUCTION);
          break;
        case CATEGORYS.TECHNICAL:
          slug = t(TRANSLATIONS.TECHNICAL_SKILLS);
          break;
        case CATEGORYS.AUTONOMOUS:
          slug = t(TRANSLATIONS.AUTONOMOUS_SKILLS);
          break;
        default:
      }
      const data = {
        slug,
        total: category?.total,
        count: `${category?.count}/${category?.total}`.toString(),
        percentage: category?.percentage,
      };
      return categoryData.push(data);
    });
  } else {
    categoryData = categoriesNullDataArrayFunc(t);
  }
  return categoryData;
};

export const tabsItemsArray = (tabItems: Array<Maybe<CountByPosition>>, t: Function) => {
  let tabChartData = <TabsArray>[];
  if (tabItems.length !== 0) {
    tabItems.map(tabs => {
      let slug = <string>'';
      switch (tabs?.slug) {
        case TABS_CHART.WAREHOUSE_PICKING_SORTING:
          slug = t(TRANSLATIONS.WAREHOUSE_HELPERS);
          break;
        case TABS_CHART.DEVLIVERY:
          slug = t(TRANSLATIONS.SKAP_DISTRIBUTION);
          break;
        case TABS_CHART.WAREHOUSE_OPERATOR:
          slug = t(TRANSLATIONS.FORKLIFT_DRIVER);
          break;
        default:
      }
      const chart = <any>[];
      tabs?.data.forEach((element: any) => {
        chart.push({
          title: SKAP_TITLE.FUNCTIONAL === element?.title
            ? t(TRANSLATIONS.FUNCTIONAL)
            : SKAP_TITLE.TECHNICAL === element?.title
              ? t(TRANSLATIONS.TECHNICAL)
              : t(TRANSLATIONS.AUTONOMOUS),
          count: element.percentage,
        });
      });
      const data = {
        title: slug,
        chart,
        total: tabs?.total,
        impactedCount: tabs?.impactedCount,
      };
      return tabChartData.push(data);
    });
  } else {
    tabChartData = tabsNullDataArrayFunc(t);
  }
  return tabChartData;
};

export type TableData = {
  area: string;
  hcTotal: number;
  hCImpactado: number;
  total: string;
  funcional: string;
  tecnicas: string;
  autonomo: string;
}[];

export const tableViewFunc = (dataArg: any, t: Function, totalData: any) => {
  const tableDataArr = <TableData>[];
  let functionalValue = 0;
  let technicalValue = 0;
  let autonomousValue = 0;
  let totalPercentage = <any>null;

  const total = {
    area: '',
    hcTotal: 0,
    hCImpactado: 0,
    total: '',
    funcional: '',
    tecnicas: '',
    autonomo: '',
  };

  if (dataArg.length > 0) {
    dataArg.forEach((data: any) => {
      data.data.forEach((innerData: any) => {
        if (data.impactedCount === 0 || data.total === 0) {
          totalPercentage = 0;
        } else {
          totalPercentage = Math.round((data.impactedCount / data.total) * 100);
        }
        if (innerData.title === SKAP_TITLE.FUNCTIONAL) {
          functionalValue = innerData.percentage;
        }
        if (innerData.title === SKAP_TITLE.TECHNICAL) {
          technicalValue = innerData.percentage;
        }
        if (innerData.title === SKAP_TITLE.AUTONOMOUS) {
          autonomousValue = innerData.percentage;
        }

        return true;
      });

      let slug = '';

      switch (data.slug) {
        case TABS_CHART.WAREHOUSE_PICKING_SORTING:
          slug = t(TRANSLATIONS.WAREHOUSE_HELPERS);
          break;
        case TABS_CHART.DEVLIVERY:
          slug = t(TRANSLATIONS.SKAP_DISTRIBUTION);
          break;
        case TABS_CHART.WAREHOUSE_OPERATOR:
          slug = t(TRANSLATIONS.FORKLIFT_DRIVER);
          break;
        default:
      }

      tableDataArr.push({
        area: slug,
        hcTotal: data.total,
        hCImpactado: data.impactedCount,
        total: `${totalPercentage}%`,
        funcional: `${functionalValue}%`,
        tecnicas: `${technicalValue}%`,
        autonomo: `${autonomousValue}%`,
      });

      return true;
    });

    total.area = 'Total';

    totalData.forEach((data: any) => {
      if (data.slug.toLowerCase().includes(CATEGORYS.FUNCIONAL.toLowerCase())) {
        total.funcional = `${data.percentage}%`;
      }
      if (data.slug.toLowerCase().includes(CATEGORYS.TECHNICAL.toLowerCase())) {
        total.tecnicas = `${data.percentage}%`;
      }
      if (data.slug.toLowerCase().includes(CATEGORYS.AUTONOMOUS.toLowerCase())) {
        total.autonomo = `${data.percentage}%`;
      }
      if (data.slug.toLowerCase().includes(CATEGORYS.IMPACTED.toLowerCase())) {
        total.hcTotal = data.total;
        total.hCImpactado = data.count;
        total.total = `${data.percentage}%`;
      }
    });
  }
  tableDataArr.push(total);

  return tableDataArr;
};

export const seriesTitleObject = (dataArg: any) => {
  const labelArr = <any>[];
  const seriesArr = <any>[];

  dataArg.map((data: any) => {
    labelArr.push(data.title);
    seriesArr.push(data.count);

    return true;
  });

  const tempSeriesTitleObject = { labels: labelArr, series: seriesArr };

  return tempSeriesTitleObject;
};
export const rampUpArray = (rampUpChart: Array<Maybe<MonthWiseScore>>, t: Function) => {
  const monthArray = <any>[];
  const countArray = <any>[];
  const totalArray = <any>[];

  rampUpChart.map((item: any) => {
    let months = <string>'';
    let total = <number>0;

    switch (item?.month) {
      case MONTH.JANUARY:
        months = t(TRANSLATIONS.JANUARY);
        total = 72.8;
        break;
      case MONTH.FEBRUARY:
        months = t(TRANSLATIONS.FEBRUARY);
        total = 75.7;
        break;
      case MONTH.MARCH:
        months = t(TRANSLATIONS.MARCH);
        total = 76.8;
        break;
      case MONTH.APRIL:
        months = t(TRANSLATIONS.APRIL);
        total = 78.2;
        break;
      case MONTH.MAY:
        months = t(TRANSLATIONS.MAY);
        total = 79.4;
        break;
      case MONTH.JUNE:
        months = t(TRANSLATIONS.JUNE);
        total = 80.7;
        break;
      case MONTH.JULY:
        months = t(TRANSLATIONS.JULY);
        total = 82.3;
        break;
      case MONTH.AUGUST:
        months = t(TRANSLATIONS.AUGUST);
        total = 83.0;
        break;
      case MONTH.SEPTEMBER:
        months = t(TRANSLATIONS.SEPTEMBER);
        total = 83.8;
        break;
      case MONTH.OCTOBER:
        months = t(TRANSLATIONS.OCTOBER);
        total = 84.5;
        break;
      case MONTH.NOVEMBER:
        months = t(TRANSLATIONS.NOVEMBER);
        total = 85.0;
        break;
      case MONTH.DECEMBER:
        months = t(TRANSLATIONS.DECEMBER);
        total = 85.0;
        break;
      default:
    }
    const totalScore = total - item.score;
    if (totalScore > 0) {
      totalArray.push(totalScore);
      countArray.push(item.score);
    } else {
      totalArray.push(0);
      countArray.push(total);
    }
    monthArray.push(months);
    return true;
  });
  const rampUpChartObj = { month: monthArray.reverse(),
    score: countArray.reverse(),
    total: totalArray.reverse() };
  return rampUpChartObj;
};
