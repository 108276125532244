/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React, { FC, useCallback, useEffect, useState } from 'react';
// @ts-ignore
import DatePicker, { CalendarContainer } from 'react-datepicker';
import {
  addMilliseconds,
  endOfDay,
  endOfToday,
  format,
  getDate,
  getMonth,
  getYear,
  setDate,
  setMonth,
  setYear,
  startOfDay,
  startOfTomorrow,
  subDays,
  subMinutes,
} from 'date-fns';
import arrowBack from '../../assets/icons/chevron-left.svg';
import arrowNext from '../../assets/icons/chevron-right.svg';
// @ts-ignore
import es from './es';
import arrow from '../../assets/icons/chevron-down.svg';

import 'react-datepicker/dist/react-datepicker.css';
import './Calendar.scss';
import { getCurrentDate } from '../../utils/dateHelpers';
import { TimeFilterOption } from '../../types/enums';

const months = [
  'Enero',
  'Febrero',
  'Marzo',
  'Abril',
  'Mayo',
  'Junio',
  'Julio',
  'Agosto',
  'Septiembre',
  'Octubre',
  'Noviembre',
  'Diciembre',
];

type Props = {
  onClose?: any;
  initialState?: any;
  excludeLastHour?: boolean;
};

const Calendar: FC<Props> = props => {
  const {
    onClose,
    initialState,
    excludeLastHour,
  } = props;

  const calendarOptions = [
    { label: 'últ. hora', value: TimeFilterOption.LAST_HOUR },
    { label: 'últ. turno (hoy)', value: TimeFilterOption.LAST_DAY },
    { label: 'últ. semana', value: TimeFilterOption.LAST_WEEK },
    { label: 'últ. mes', value: TimeFilterOption.LAST_MONTH },
  ];

  if (excludeLastHour) calendarOptions.shift();

  // region Hooks
  const [mount, setMount] = useState(false);
  const [firstCalendar, setValueFirstCalendar] = useState(new Date());
  const [secondCalendar, setValueSecondCalendar] = useState(new Date());
  const [calendarOptionsState, setOption] = useState(0);
  const [showSecondCalendar, setShowSecondCalendar] = useState(false);
  const [activeForm, setActiveForm] = useState(0);
  const [since, setSince] = useState('');
  const [to, setTo] = useState('');
  const [dropDown, toggleDropDown] = useState<boolean>(false);

  const secondCalendarVisible = useCallback(
    () => {
      if (getMonth(firstCalendar) !== getMonth(secondCalendar)) {
        setShowSecondCalendar(true);
      } else {
        setShowSecondCalendar(false);
      }
    },
    [firstCalendar, secondCalendar],
  );

  // built-in options handler
  const calculateStart = useCallback(
    () => {
      // PS: use new Date() instead of firstCalendar since it's not set yet until the next re-render
      switch (calendarOptionsState) {
        case TimeFilterOption.LAST_WEEK:
          return setValueSecondCalendar(subDays(addMilliseconds(endOfToday(), 1), 7));
        case TimeFilterOption.LAST_MONTH:
          return setValueSecondCalendar(subDays(addMilliseconds(endOfToday(), 1), 30));
        case TimeFilterOption.CUSTOM_PERIOD:
          break;
        default:
          return setValueSecondCalendar(new Date());
      }
    },
    [calendarOptionsState],
  );

  // the built-in periods calendar updater (magic happens in calculateStart callback)
  useEffect(() => {
    if (calendarOptionsState === TimeFilterOption.CUSTOM_PERIOD) return;
    // reset first calendar in case it was some custom value to today's value
    setValueFirstCalendar(new Date());
    calculateStart();
  }, [calendarOptionsState, calculateStart]);

  // set input fields in response to clicking on dates [setting 'to' and 'since']
  useEffect(() => {
    if (calendarOptionsState === TimeFilterOption.CUSTOM_PERIOD) {
      // tslint:disable-next-line: max-line-length
      setSince(`${getDate(secondCalendar)}/${months[getMonth(secondCalendar)]}/${getYear(
        secondCalendar,
      )}`);
      // tslint:disable-next-line: max-line-length
      setTo(`${getDate(firstCalendar)}/${months[getMonth(firstCalendar)]}/${getYear(firstCalendar)}`);
    }
    // eslint-disable-next-line
  }, [calendarOptionsState, firstCalendar, calculateStart, secondCalendar]);

  // show second calendar
  useEffect(() => {
    secondCalendarVisible();
  }, [secondCalendar, secondCalendarVisible]);

  // auto-close when one of the built-in time options are chosen
  useEffect(() => {
    // Only close the calendar if the selected option is not the custom value one
    if (calendarOptionsState !== TimeFilterOption.CUSTOM_PERIOD) {
      toggleDropDown(false);
    }
    // eslint-disable-next-line
  }, [secondCalendar]);

  // region handlers
  function handleSecondCalendar(newDate: any) {
    // You can't choose a custom period unless you're using the custom period option!
    if (calendarOptionsState === TimeFilterOption.CUSTOM_PERIOD) {
      // convert to a date without hours, minutes,
      // seconds (otherwise binary search  in UserLineChart
      const dateZeroHoured = new Date(newDate);
      dateZeroHoured.setHours(0, 0, 0);
      if (activeForm === 0) setValueSecondCalendar(dateZeroHoured);
      else setValueFirstCalendar(dateZeroHoured);
    }
  }

  function handleToChange(e: any) {
    setTo(e.target.value);
  }

  function handleToEnter(e: any) {
    if (e.key === 'Enter') {
      const arrTo = to.split('/');
      const fCalendarDay = getDate(firstCalendar);
      const fCalendarMonth = months[getMonth(firstCalendar)];
      const fCalendarYear = getYear(firstCalendar);

      if (arrTo.length !== 3) {
        setTo(`${fCalendarDay}/${fCalendarMonth}/${fCalendarYear}`);
      } else if (arrTo[0] !== fCalendarDay.toString()) {
        setTo(`${arrTo[0]}/${fCalendarMonth}/${fCalendarYear}`);
        setValueFirstCalendar(setDate(firstCalendar, parseInt(arrTo[0], 10)));
      } else if (arrTo[1] !== fCalendarMonth && months.indexOf(arrTo[1]) !== -1) {
        setTo(`${fCalendarDay}/${arrTo[1]}/${fCalendarYear}`);
        setValueFirstCalendar(setMonth(firstCalendar, months.indexOf(arrTo[1])));
      } else if (arrTo[2] !== fCalendarYear.toString() && (arrTo[2].length === 4)) {
        setTo(`${fCalendarDay}/${fCalendarMonth}/${arrTo[2]}`);
        setValueFirstCalendar(setYear(firstCalendar, parseInt(arrTo[2], 10)));
      } else {
        setTo(`${fCalendarDay}/${fCalendarMonth}/${fCalendarYear}`);
      }
    }
  }

  function handleSinceEnter(e: any) {
    if (e.key === 'Enter') {
      const arrTo = since.split('/');
      // string-format 2nd calendar
      const sCalendarDay = getDate(secondCalendar);
      const sCalendarMonth = months[getMonth(secondCalendar)];
      const sCalendarYear = getYear(secondCalendar);

      if (arrTo.length !== 3) {
        setSince(`${sCalendarDay}/${sCalendarMonth}/${sCalendarYear}`);
      } else if (arrTo[0] !== sCalendarDay.toString()) {
        setSince(`${arrTo[0]}/${sCalendarMonth}/${sCalendarYear}`);
        setValueSecondCalendar(setDate(secondCalendar, parseInt(arrTo[0], 10)));
      } else if (arrTo[1] !== sCalendarMonth && months.indexOf(arrTo[1]) !== -1) {
        setSince(`${sCalendarDay}/${arrTo[1]}/${sCalendarYear}`);
        setValueSecondCalendar(setMonth(secondCalendar, months.indexOf(arrTo[1])));
      } else if (arrTo[2] !== sCalendarYear.toString() && (arrTo[2].length === 4)) {
        setSince(`${sCalendarDay}/${sCalendarMonth}/${arrTo[2]}`);
        setValueSecondCalendar(setYear(secondCalendar, parseInt(arrTo[2], 10)));
      } else {
        setSince(`${sCalendarDay}/${sCalendarMonth}/${sCalendarYear}`);
      }
    }
  }

  function handleSinceChange(e: any) {
    setSince(e.target.value);
  }

  function handleFirstCalendar(newDate: any) {
    if (calendarOptionsState === TimeFilterOption.CUSTOM_PERIOD) {
      // convert to a date without hours, minutes,
      // seconds (otherwise binary search  in UserLineChart
      const dateZeroHoured = new Date(newDate);
      dateZeroHoured.setHours(0, 0, 0);
      if (activeForm === 1) setValueFirstCalendar(dateZeroHoured);
      else setValueSecondCalendar(dateZeroHoured);
    } else if (calendarOptionsState !== TimeFilterOption.LAST_HOUR) {
      setValueFirstCalendar(newDate);
    }
  }

  function handleToggleInput() {
    if (dropDown) {
      toggleDropDown(false);
    } else {
      toggleDropDown(true);
    }
  }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  function handleOnClose() {
    switch (calendarOptionsState) {
      case TimeFilterOption.LAST_HOUR:
        onClose(subMinutes(firstCalendar, 60), firstCalendar, 'LAST_HOUR');
        break;
      case TimeFilterOption.LAST_DAY:
        const { startDate, endDate } = getCurrentDate();
        onClose(startDate, endDate, 'LAST_DAY');
        break;
      case TimeFilterOption.LAST_WEEK:
        onClose(secondCalendar, addMilliseconds(endOfDay(firstCalendar), 1), 'LAST_WEEK');
        break;
      case TimeFilterOption.LAST_MONTH:
        onClose(secondCalendar, addMilliseconds(endOfDay(firstCalendar), 1), 'LAST_MONTH');
        break;
      case TimeFilterOption.CUSTOM_PERIOD:
        // just in case user mistakenly chooses an end date earlier than a start date
        if (firstCalendar < secondCalendar) {
          onClose(
            startOfDay(firstCalendar),
            addMilliseconds(endOfDay(secondCalendar), 1),
            'CUSTOM_PERIOD',
          );
        } else if (secondCalendar <= firstCalendar) {
          onClose(
            startOfDay(secondCalendar),
            addMilliseconds(endOfDay(firstCalendar), 1),
            'CUSTOM_PERIOD',
          );
        }
        break;
      default: break;
    }
  }

  // close calendar and lift data up on toggle
  useEffect(() => {
    handleOnClose();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dropDown]);
  // endregion Hooks

  if (!mount) {
    const previousMonth: any = new Date();
    previousMonth.setMonth(previousMonth.getMonth() - 1);
    setValueSecondCalendar(previousMonth);

    switch (initialState) {
      case 'LAST_HOUR':
        setOption(TimeFilterOption.LAST_HOUR);
        break;
      case 'LAST_DAY':
        setOption(TimeFilterOption.LAST_DAY);
        break;
      case 'LAST_WEEK':
        setOption(TimeFilterOption.LAST_WEEK);
        break;
      case 'LAST_MONTH':
        setOption(TimeFilterOption.LAST_MONTH);
        break;
      case 'CUSTOM_PERIOD':
        setOption(TimeFilterOption.CUSTOM_PERIOD);
        break;
      default:
        setOption(TimeFilterOption.LAST_DAY);
        break;
    }
    calculateStart();
    secondCalendarVisible();
    setMount(true);
  }

  // endregion handlers

  function renderCustomHeader(options: any, type: any = 0) {
    const {
      date,
      decreaseMonth,
      increaseMonth,
    } = options;

    return (
      <div className="header">
        <div className="stringContainer">
          {type < 2
            ? <p className="stringHeader small">Hasta</p>
            : <p className="stringHeader small">Desde</p> }
          <p className="stringHeader">{`${months[date.getMonth()]} ${date.getYear() + 1900}`}</p>
        </div>
        <div className="headerIcons">
          <img onClick={() => decreaseMonth()} src={arrowBack} alt="back" />
          <img onClick={() => increaseMonth()} src={arrowNext} alt="back" />
        </div>
      </div>
    );
  }

  function renderSecondCalendar(propsCalendar: any) {
    const { children } = propsCalendar;

    return (
      <CalendarContainer className="secondCalendarContainer">
        {children}
      </CalendarContainer>
    );
  }

  const aditionalPropsFirstCalendar = () => {
    if (calendarOptionsState === TimeFilterOption.LAST_HOUR
      || calendarOptionsState === TimeFilterOption.LAST_DAY) {
      return {};
    }
    return {
      startDate: secondCalendar,
      endDate: firstCalendar,
      dayClassName: (date: any) => ((getDate(date) === getDate(secondCalendar))
        && (getMonth(date) === getMonth(secondCalendar) && !showSecondCalendar) ? 'startDate'
        : ''),
    };
  };

  const aditionalPropsSecondCalendar = () => {
    if (calendarOptionsState === TimeFilterOption.LAST_HOUR
      || calendarOptionsState === TimeFilterOption.LAST_DAY) {
      return {};
    }
    return {
      startDate: secondCalendar,
      endDate: firstCalendar,
      dayClassName: (date: any) => ((getDate(date) === getDate(firstCalendar))
        && (getMonth(date) === getMonth(firstCalendar) && !showSecondCalendar) ? 'startDate'
        : ''),
    };
  };

  function renderCalendarContainer(propsCalendar: any) {
    const { children } = propsCalendar;

    return (
      <CalendarContainer className="calendarContainer">
        {showSecondCalendar && (
          <DatePicker
            selected={secondCalendar}
            onChange={handleSecondCalendar}
            renderCustomHeader={(options: any) => renderCustomHeader(options, calendarOptionsState)}
            calendarContainer={renderSecondCalendar}
            // @ts-ignore
            locale={es}
            inline
            {...aditionalPropsSecondCalendar()}
          />
        )}
        <div className="headerDivider" />
        {children}
      </CalendarContainer>
    );
  }

  function getCustomPeriodDDMMYY() {
    const sinceString = format(secondCalendar, 'dd.MM.yy');
    const toString = format(firstCalendar, 'dd.MM.yy');
    return `${sinceString} - ${toString}`;
  }

  const daysInRange = Math.round((new Date(firstCalendar).getTime()
  - new Date(secondCalendar).getTime()) / 86400000) || 1;

  let calendarLabel = calendarOptions.find(o => o.value === calendarOptionsState)?.label;
  if (!calendarLabel) {
    calendarLabel = getCustomPeriodDDMMYY();
  }

  return (
    <div className="dropdown-component">
      {/* dropdown (when closed) */}
      <div
        role="button"
        className="dropDownButton"
        onClick={handleToggleInput}
      >
        <p className="text">
          {(calendarOptions.find(o => o.value === calendarOptionsState) || {} as any).label
          || `${getCustomPeriodDDMMYY()}`}
        </p>
        <img className={`${dropDown ? 'rotate' : ''}`} src={arrow} alt="arrow" />
      </div>

      {/* calendar + sidemenu */}
      <div className={`${dropDown ? 'show-dropdown' : 'hide-dropdown'} dropdown-menu`}>
        {dropDown
          ? (
            <>
              {/* 2 calendars (check the renderCalendarContainer to see
                the implementation of both calendars) */}
              <DatePicker
                selected={firstCalendar}
                onChange={handleFirstCalendar}
                calendarContainer={renderCalendarContainer}
                renderCustomHeader={renderCustomHeader}
                // @ts-ignore
                locale={es}
                inline
                maxDate={startOfTomorrow()}
                {...aditionalPropsFirstCalendar()}
              />

              {/* Right-menu (Period-menu) */}
              <div className="boxOptions">
                {/* 4 built-in periods */}
                {calendarOptions.map(option => (
                  <div
                    role="button"
                    className={`boxOption ${calendarOptionsState === option.value ? 'selected' : ''}`}
                    onClick={() => setOption(option.value)}
                    key={option.value}
                  >
                    <p>{option.label}</p>
                  </div>
                ))}
                <div
                  role="button"
                  className={`boxOption select ${calendarOptionsState === TimeFilterOption.CUSTOM_PERIOD ? 'selected' : ''}`}
                  onClick={() => setOption(TimeFilterOption.CUSTOM_PERIOD)}
                >
                  <p>Seleccionar periodo</p>
                </div>
                {/* Custom Period */}
                {calendarOptionsState === TimeFilterOption.CUSTOM_PERIOD && (
                  <div className="formsContainer">
                    <p className="formString">Desde:</p>
                    {/* Since input */}
                    <input
                      className={`form-control formStyles ${activeForm === 0 ? 'active' : ''}`}
                      type="text"
                      placeholder="Default input"
                      value={since}
                      onChange={handleSinceChange}
                      onKeyDown={handleSinceEnter}
                      onClick={() => setActiveForm(0)}
                    />
                    <p className="formString">Hasta:</p>
                    {/* To input */}
                    <input
                      className={`form-control formStyles ${activeForm === 1 ? 'active' : ''}`}
                      type="text"
                      placeholder="Default input"
                      value={to}
                      onChange={handleToChange}
                      onKeyDown={handleToEnter}
                      onClick={() => setActiveForm(1)}
                    />
                    {/* Days gone */}
                    <small className="numberOfDaysInfo">
                      *consultarás {daysInRange} días
                      hábile
                    </small>
                    {/* Apply */}
                    <button
                      type="button"
                      className="applyButton"
                      onClick={() => {
                      // simulate enter press (methods are designed to handled a key press)
                        handleSinceEnter({ key: 'Enter' });
                        handleToEnter({ key: 'Enter' });
                        handleToggleInput();
                      }}
                    >
                      Aplicar
                    </button>
                  </div>
                )}
              </div>
            </>
          )
          : null }
      </div>
    </div>
  );
};

export default Calendar;
