import React from 'react';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import MenuItem from '@material-ui/core/MenuItem';
import ListSubheader from '@material-ui/core/ListSubheader';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';

const useStyles = makeStyles(() => createStyles({
  formControl: {
    minWidth: 150,
    width: '100%',
    border: '1px solid #DEDEDE',
    borderRadius: '8px',
    padding: '6px',
    margin: 0,
  },
}));

interface GroupDropDownProps {
  options: {
    groupIndex: number,
    groupTitle: string;
    subOptions: {
      optionId: number;
      value: string;
      label: string;
    }[]
  }[];
  value: string | number | any | Object;
  label: string;
  defaultValue?: string | number | any | Object;
  onChange: (value: string) => void;
}

const GroupDropDown = ({ options, value, label, defaultValue, onChange }: GroupDropDownProps) => {
  const classes = useStyles();

  const handleChange = (event: any) => {
    onChange(event.target.value);
  };
  const renderSelectGroup = (entry: {
    groupIndex: number,
    groupTitle: string;
    subOptions: {
      optionId: number;
      value: string;
      label: string;
    }[]
  }) => {
    const items = entry.subOptions.map(p => {
      return (
        <MenuItem key={p.optionId} value={p.value}>
          {p.label}
        </MenuItem>
      );
    });
    return [<ListSubheader>{entry.groupTitle}</ListSubheader>, items];
  };
  return (
    <div style={{ overflow: 'hidden' }}>
      <FormControl className={`${classes.formControl} bg-white`}>
        <Select placeholder={label} onChange={handleChange} value={value} defaultValue={defaultValue} id="grouped-select">
          {options.map(entry => renderSelectGroup(entry))}
        </Select>
      </FormControl>
    </div>
  );
};

export default GroupDropDown;
