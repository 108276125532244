import { MonthlyOptions } from 'components/MonthlyTimeFilter/MonthlyTimeFilter';
import moment from 'moment';

export const SET_TIME_FILTER = 'time-filter/set-time-filter';
export const SET_TIME_FILTER_RAW = 'time-filter/set-time-filter-raw';

export function setTimeFilter(time: { startTime: Date, endTime: Date, slug: string }) {
  return { type: SET_TIME_FILTER_RAW, time };
}

export function setMonthlyDropdownChange(option: string) {
  const selectedPeriod = MonthlyOptions.find(period => period.value === option);
  const time = {
    startTime: selectedPeriod?.startTime || moment().clone().startOf('month').toISOString(),
    endTime: selectedPeriod?.endTime || moment().clone().endOf('month').toISOString(),
  };

  return { type: SET_TIME_FILTER, ...time, dropdownValue: option };
}
