import { useAsync } from 'react-async-hook';
import { DetailedYearDropDownOption, generateQueryDates } from 'utils/dateHelpers';
import request from 'utils/request';
import {
  selectLocationsToAccess,
} from 'store/selectors/globalFilters.selectors';
import { useSelector } from 'react-redux';
import { TypeLabelSlug } from 'types/types';
import { addDays, endOfMonth } from 'date-fns';

export enum UserRoutineError {
  POSITION_NOT_SELECTED = 'POSITION_NOT_SELECTED',
  SELECT_A_SUBZONE = 'SELECT_A_SUBZONE'
}

export const getUsersRoutine = async (
  selectedPosition: TypeLabelSlug,
  locationsToAccess: number[],
  selectedDropDownItem: DetailedYearDropDownOption,
) => {
  if (!selectedPosition?.slug) {
    throw new Error(UserRoutineError.POSITION_NOT_SELECTED);
  }
  const routinesT = await request.post('/api/v1/routines/users', {
    // @ts-ignore
    position: selectedPosition.slug,
    locationsToAccess,
    ...generateQueryDates(
      new Date(addDays(selectedDropDownItem.content.startWeek, 1)),
      new Date(endOfMonth(addDays(selectedDropDownItem.content.startWeek, 1))),
    ),
  });
  console.log('%c⧭', 'color: #cc0088', addDays(selectedDropDownItem.content.startWeek, 1));
  console.log('%c⧭', 'color: #1d3f73', endOfMonth(addDays(selectedDropDownItem.content.startWeek, 1)));
  console.log('%c⧭', 'color: #bfffc8', selectedDropDownItem);
  return routinesT;
};

const useGetUsersRoutine = ({
  selectedPosition,
  selectedDropDownItem,
}: {
  selectedPosition: TypeLabelSlug,
  selectedDropDownItem: DetailedYearDropDownOption
}) => {
  const locationsToAccess = useSelector(selectLocationsToAccess);
  return useAsync(
    getUsersRoutine,
    [selectedPosition, locationsToAccess, selectedDropDownItem],
  );
};

export default useGetUsersRoutine;
