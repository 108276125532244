import React, { useEffect } from 'react';
import { format } from 'date-fns';
import { useParams } from 'react-router-dom';
import { gql, useQuery } from '@apollo/client';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import FilePreview from 'components/FilePreview/FilePreview';
import HeaderWithNavigation from 'components/HeaderWithNavigation';
import LoadingSpinner from 'components/LoadingSpinner';

import { selectUserType, selectUserLocation } from 'store/selectors/user.selectors';

// import checkIcon from 'assets/icons/only-check.svg';
// import clipboard from 'assets/icons/clipboard.svg';
import safetyAcademyLogo from 'assets/icons/safety-academy-logo.png';
import profilePicture from 'assets/icons/user-icon.svg';

import defaultRoutes from 'utils/defaultRoutes';

import './ExtendedView.scss';

const GET_POST = gql`query ExampleQuery($getPostSlug: String!) {
  getPost(slug: $getPostSlug) {
    id
    title
    subtitle
    content
    type
    postedAt
    bannerImage
    author
    attachments {
      url
      type
    }
  }
}`;

const ExtendedView = () => {
  const { t } = useTranslation();
  const { slug } : {slug: string} = useParams();
  const userType = useSelector(selectUserType);
  const location = useSelector(selectUserLocation);
  // const [copied, setCopied] = useState(false);

  const { loading, data } = useQuery(GET_POST, {
    variables: { getPostSlug: slug },
  });

  const postObject = data?.getPost;
  const {
    title,
    subtitle,
    content,
    postedAt,
    attachments,
    bannerImage,
    author,
  } = postObject || {};

  // Needed to style the whole page white
  const div = document.getElementById('root');

  useEffect(() => {
    if (div) {
      div.style.background = 'white';
      return () => {
        div.style.background = '#F7F7F7';
      };
    }
  }, [div]);

  // const hanldeCopyLink = async () => {
  //   const currentUrl = window.location.href;
  //   await navigator.clipboard.writeText(currentUrl);
  //   setCopied(true);
  // };

  if (loading) {
    return (
      <HeaderWithNavigation
        routes={
        defaultRoutes(t, userType, location, undefined)
      }
        hideLocations
      >
        <div className="extended-view">
          <LoadingSpinner />
        </div>
      </HeaderWithNavigation>
    );
  }

  return (
    <HeaderWithNavigation
      routes={
      defaultRoutes(t, userType, location, undefined)
      }
      hideLocations
    >
      <div className="extended-view">
        <div className="extended-view__top">
          <h1 className="extended-view__top__title">{title}</h1>
          <h2 className="extended-view__top__subtitle">{subtitle}</h2>
          <img
            className="extended-view__banner"
            height={300}
            src={bannerImage || safetyAcademyLogo}
            alt="banner"
          />
          <div className="extended-view__info">
            <div>
              <img src={profilePicture} alt="profile" />
              <div className="extended-view__info__author">
                <span>{author}</span>
                <span>{postedAt && format(new Date(postedAt), 'dd/MM/yyyy')}</span>
              </div>
            </div>
            <div className="extended-view__info__right">
              {/* <button type="button" onClick={hanldeCopyLink}>
                <img src={copied ? checkIcon : clipboard} alt="clipboard" />
              </button> */}
            </div>
          </div>
        </div>
        <div className="extended-view__description" dangerouslySetInnerHTML={{ __html: content }} />
        {attachments?.length > 0 && (
          <div className="extended-view__attached-files">
            <span className="extended-view__attached-files__title">Archivos adjuntos</span>
            <div className="extended-view__attached-files__files-list">
              {attachments?.length && attachments.map((file: any) => (
                <FilePreview file={file} />
              ))}
            </div>
          </div>
        )}
      </div>
    </HeaderWithNavigation>
  );
};

export default ExtendedView;
