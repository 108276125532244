import React from 'react';
import Menu, { Item as MenuItem } from 'rc-menu';
import { SelectInfo } from 'rc-menu/es/interface';
import Dropdown from 'rc-dropdown';
import 'rc-dropdown/assets/index.css';
import './Filter.scss';
import ImageLinks from 'utils/ImageLinks';

export type Item = {
  label: string,
  value: any,
};
type Props = {
  onChange: (info: SelectInfo) => void,
  selectedDropDownItem: any,
  dropDownItems: Item[],
  dropDownOpen: boolean;
  setDropDownOpen: (opened: boolean) => void;
  labelClassName?: string;
};

const Filter = ({
  onChange,
  selectedDropDownItem,
  dropDownItems,
  dropDownOpen,
  setDropDownOpen,
  labelClassName,
}: Props) => {
  const menu = (
    <Menu
      onSelect={onChange}
      selectedKeys={[selectedDropDownItem?.value]}
      className="menu-container"
    >
      {dropDownItems.map(item => <MenuItem key={item.value}>{item.label}</MenuItem>)}
    </Menu>
  );

  return (
    <div className="chart-dropdown">
      <Dropdown
        trigger={['click']}
        overlay={menu}
        animation="slide-up"
        visible={dropDownOpen}
        onVisibleChange={setDropDownOpen}
      >
        <div
          className={`dropdown-label ${labelClassName}`}
        >
          <img
            alt="filter-img"
            src={ImageLinks.filter}
            className="image-view"
          />
          <span>{selectedDropDownItem?.label}</span>
        </div>
      </Dropdown>
    </div>
  );
};
export default Filter;
