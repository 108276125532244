import React, { useState } from 'react';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import { Button } from '@material-ui/core';
import DragHandleIcon from '@material-ui/icons/DragHandle';
import {
  Droppable,
  Draggable,
  DropResult,
  DragDropContext,
  DraggableProvided,
  DroppableProvided,
  DraggableStateSnapshot,
} from 'react-beautiful-dnd';
import { createPortal } from 'react-dom';
import './style.scss';

interface MultiValueInputProps {
  items: string[];
  maxLength?: number;
  placeholder?: string;
  validationRegEx?: RegExp;
  resetDefaultAnswer: () => void;
  onAddItem: (val: string[]) => void;
  onDeleteItem: (val: string[]) => void;
}

interface PortalAwareItemProps {
  item: string;
  index: number;
  provided: DraggableProvided;
  snapshot: DraggableStateSnapshot;
  onDelete: (index: number) => void;
}

const portal = document.createElement('div');
document.body.appendChild(portal);

const PortalAwareItem = ({ provided, snapshot, item, index, onDelete }: PortalAwareItemProps) => {
  const usePortal = snapshot.isDragging;

  const child = (
    <div
      ref={provided.innerRef}
      {...provided.draggableProps}
      {...provided.dragHandleProps}
      {...snapshot}
      className="MultiValueInputList__list_item"
    >
      <div className="MultiValueInputList__list_item_inner">
        <DragHandleIcon className="MultiValueInputList__grabbable" />
        <p>{item}</p>
        <Button
          variant="text"
          onClick={() => onDelete(index)}
          className="multiValue_input-item-delete-button"
        >
          <DeleteOutlineIcon style={{ width: 16, height: 16 }} />
        </Button>
      </div>
    </div>
  );

  if (!usePortal) {
    return child;
  }

  return createPortal(child, portal);
};

const MultiValueInputList = ({
  items,
  onAddItem,
  onDeleteItem,
  resetDefaultAnswer,
  maxLength = undefined,
  validationRegEx = undefined,
  placeholder = '+ Add option and enter',
}: MultiValueInputProps) => {
  const [inputValue, setInputValue] = useState<string>('');
  const [highlightValidationFailure, setShowHighlightValidationFailure] = useState<boolean>(false);

  const onAdd = (value: string, e: React.KeyboardEvent<HTMLInputElement>) => {
    e.preventDefault();
    const item = value.trim();
    const updatedInputsList = [...items, value];
    if (item !== '' && !items.includes(value)) {
      if (validationRegEx !== undefined) {
        const validationTest = validationRegEx?.test(item);
        if (!validationTest) {
          setShowHighlightValidationFailure(true);
          return;
        }
      }
      setInputValue('');
      onAddItem(updatedInputsList);
    }
  };

  const onDelete = (index: number) => {
    if (index >= 0) {
      const itemList = [...items];
      itemList.splice(index, 1);
      if (itemList.length === 0) {
        resetDefaultAnswer();
      }
      onDeleteItem(itemList);
    }
  };

  async function onDragEnd(result: DropResult) {
    const { source, destination } = result;
    console.log({ source, destination });

    if (!destination) return;

    const reorderedItems = [...items];
    const [removed] = reorderedItems.splice(source.index, 1);
    reorderedItems.splice(destination.index, 0, removed);

    // Update the state with the reordered items
    onAddItem(reorderedItems);
  }

  return (
    <div className="multiValue_input-container">
      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId="question-options">
          {(droppableProvided: DroppableProvided) => (
            <div
              className="MultiValueInputList__list_inner"
              {...droppableProvided.droppableProps}
              ref={droppableProvided.innerRef}
            >
              {items.map((item, index) => (
                <Draggable key={item} draggableId={item} index={index}>
                  {(providedInner: DraggableProvided, snapshot: DraggableStateSnapshot) => (
                    <PortalAwareItem
                      item={item}
                      index={index}
                      snapshot={snapshot}
                      onDelete={onDelete}
                      provided={providedInner}
                    />
                  )}
                </Draggable>
              ))}
              {droppableProvided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>
      <input
        type="text"
        value={inputValue}
        maxLength={maxLength}
        placeholder={placeholder}
        onChange={e => {
          if (highlightValidationFailure) setShowHighlightValidationFailure(false);
          setInputValue(e.target.value);
        }}
        onKeyDown={(e: React.KeyboardEvent<HTMLInputElement>) => {
          if (e.key === 'Enter') onAdd(inputValue, e);
        }}
        className={highlightValidationFailure ? 'checklist-builder_input_error' : 'add_answers-checklist-bit-input-option'}
      />
    </div>
  );
};

export default MultiValueInputList;
