import React from 'react';

import ListWithTitle from 'components/ListWithTitle/ListWithTitle';
import SafetyAcademyPreview from 'features/safety-academy/Preview/SafetyAcademyPreview';

import './SafetyAcademyOverview.scss';
import { POSTS_TYPES, TRANSLATIONS } from 'types/enums';
import { selectUserType } from 'store/selectors/user.selectors';
import { useSelector } from 'react-redux';
import { useGetPostsQuery } from 'generated/graphql';
import LoadingSpinner from 'components/LoadingSpinner';
import { useTranslation } from 'react-i18next';

type Props = { fromHome?: boolean };

const SafetyAcademyOverview = ({ fromHome = false }: Props) => {
  const { t } = useTranslation();
  const { loading, data } = useGetPostsQuery({
    variables: { limit: 10, type: POSTS_TYPES.NEWS as any },
  });
  const userType = useSelector(selectUserType);

  if (userType === 'SubzoneGeneralManagerSupply' || userType === 'DistributionCenterManagerSupply' || userType === 'CorporateSupply') return null;

  const news = data?.getPosts?.posts;

  if (loading) {
    return <LoadingSpinner />;
  }

  return (
    <div className="safety-academy-overview">
      <div className="col-9 card">
        <SafetyAcademyPreview />
      </div>
      <div className="col-3 card" style={fromHome ? { marginTop: '-108px' } : undefined}>
        <ListWithTitle
          text={t(TRANSLATIONS.SAFETY_NEWS)}
          list={news as any}
          loading={loading}
        />
      </div>
    </div>
  );
};

export default SafetyAcademyOverview;
