import React, { useEffect, useState } from 'react';
import { useCubeQuery } from '@cubejs-client/react';
import sum from 'lodash.sum';
import { format, parseISO } from 'date-fns';
import ChartTabs from 'components/Tabs/ChartTabs';
import ChartDropdown from 'components/Dropdown/ChartDropdown';
import LoadingSpinner from 'components/LoadingSpinner';
import Chart from 'react-apexcharts';
import { useTranslation } from 'react-i18next';
import { TRANSLATIONS } from 'types/enums';
import { set, subDays } from 'date-fns/esm';
import { lastDayOfMonth } from 'date-fns/esm/fp';
import { useSelector } from 'react-redux';
import { selectLocationsToAccess } from 'store/selectors/globalFilters.selectors';

type Props = {

};

export default function ProductionTimeChart() {
  const { t } = useTranslation();
  const locationsToAccess = useSelector(selectLocationsToAccess);

  const dropDownItems = [{
    slug: 'last-7-days',
    label: t(TRANSLATIONS.LAST_7_DAYS),
  }, {
    slug: 'last-30-days',
    label: t(TRANSLATIONS.LAST_30_DAYS),
  }, {
    slug: 'month-to-date',
    label: 'Mes actual',
  }];

  const [selectedDropDownItem, setSelectedDropDownItem] = useState(dropDownItems[0]);
  const [dropDownOpen, setDropDownOpen] = useState(false);

  const getDateRange = () => {
    const currentDate = new Date();
    let filter = ['', ''] as any[];
    if (selectedDropDownItem.slug === 'last-7-days') {
      filter = [subDays(currentDate, 14), currentDate];
    }
    if (selectedDropDownItem.slug === 'last-30-days') {
      filter = [subDays(currentDate, 60), currentDate];
    }
    if (selectedDropDownItem.slug === 'month-to-date') {
      const lastMonthDay = lastDayOfMonth(currentDate);
      const firstDayLastMonth = set(subDays(lastMonthDay, 50), { date: 1 });
      filter = [firstDayLastMonth, lastMonthDay];
    }
    return [format(filter[0], 'yyyy-MM-dd'), format(filter[1], 'yyyy-MM-dd')];
  };

  // @ts-ignore
  const handleDropDownChange = ({ key }) => {
    setSelectedDropDownItem(dropDownItems.find(
      item => item.slug === key,
    ) || dropDownItems[0]);
    setDropDownOpen(false);
  };

  const dateRange = getDateRange() as unknown as string[];
  const { resultSet: realResult } = useCubeQuery({
    measures: ['DailyProductionPlannings.realSum'],
    timeDimensions: [{
      dimension: 'DailyProductionPlannings.day',
      granularity: 'day',
      dateRange,
    }],
    dimensions: [],
    filters: [{
      dimension: 'DailyProductionPlannings.locationId',
      operator: 'equals',
      values: locationsToAccess,
    }],
    order: {},
  });

  let chartApiResponse = null as any;

  if (realResult?.series()[0]) {
    const incidents = realResult.series()[0];

    const midOfSeries = Math.ceil(incidents.series.length / 2);
    const incidentsData = incidents.series.map((v: any) => v.value);

    chartApiResponse = {
      totalReports: sum(incidentsData.slice(midOfSeries)),
      incidentsByTime: {
        data: incidentsData,
        labels: incidents.series.map((v: any) => v.x),
      },
    };
  }

  const tabsItems = [{
    title: 'Vol. real',
    value: `${(chartApiResponse?.totalReports.toLocaleString() || 0)} hl`,
  }];
  const [selectedTab, setSelectedTab] = useState(tabsItems[0]);

  const getChartVars = () => {
    if (chartApiResponse === null) {
      return ({
        solidLineData: [],
        dottedLineData: [],
        averageLineData: [],
        labels: [],
      });
    }
    let seriesToConsider = null;
    let labelsToConsider = null;

    if (selectedTab.title === tabsItems[0].title) {
      seriesToConsider = chartApiResponse.incidentsByTime.data;
      labelsToConsider = chartApiResponse.incidentsByTime.labels;
    }
    // if (selectedTab.title === tabsItems[1].title) {
    //   seriesToConsider = chartApiResponse.uniqueReportersByTime.data;
    //   labelsToConsider = chartApiResponse.uniqueReportersByTime.labels;
    // }
    // if (selectedTab.title === tabsItems[2].title) {
    //   seriesToConsider = chartApiResponse.avgPerUser.data;
    //   labelsToConsider = chartApiResponse.avgPerUser.labels;
    // }

    if (seriesToConsider === null) {
      seriesToConsider = chartApiResponse.incidentsByTime.data.map(
        (d: number, i: number) => parseFloat((chartApiResponse.incidentsByTime.data[i]
          / chartApiResponse.uniqueReportersByTime.data[i]
        ).toFixed(2)),
      );
      labelsToConsider = chartApiResponse.incidentsByTime.labels;
    }

    const midOfSeries = Math.floor(seriesToConsider.length / 2);
    const solidLineData = seriesToConsider.slice(midOfSeries);
    const dottedLineData = seriesToConsider.slice(0, midOfSeries);
    const theLabels = labelsToConsider.slice(midOfSeries);
    const averageLinePos = (solidLineData.reduce(
      (acc: number, node: number) => acc + node,
      0,
    )) / solidLineData.length;
    const averageLinePoints = new Array(solidLineData.length).fill(averageLinePos.toFixed(2));
    return ({
      solidLineData,
      dottedLineData,
      averageLineData: averageLinePoints,
      labels: theLabels.map((l: string) => {
        return format(parseISO(l), 'd MMM');
      }),
    });
  };

  const chartVars = getChartVars();

  const chartData = {
    series: [{
      name: selectedDropDownItem.label,
      data: chartVars.solidLineData,
    },
    {
      name: t(TRANSLATIONS.PRECEDING_PERIOD),
      data: chartVars.dottedLineData,
    },
    {
      name: t(TRANSLATIONS.AVERAGE),
      data: chartVars.averageLineData,
    }],
    options: {
      colors: ['#70AD47', '#4472C4', '#F39C1F'],
      zoom: {
        enabled: true,
        autoScaleYaxis: true,
      },
      chart: {
        toolbar: {
          show: false,
        },
        height: 350,
        type: 'line',
        zoom: {
          enabled: false,
        },
      },
      dataLabels: {
        enabled: false,
      },
      stroke: {
        width: [3, 3, 3],
        curve: 'straight',
        dashArray: [0, 4, 0],
      },
      markers: {
        size: 0,
        hover: {
          sizeOffset: 6,
        },
      },
      xaxis: {
        categories: chartVars.labels,
        labels: {
          rotate: 0,
          style: {
            // fontSize: '10px',
          },
        },
      },
      yaxis: {
        opposite: true,
        tickAmount: 4,
        decimalsInFloat: 0,
        forceNiceScale: true,
        min: 0,

      },
      legend: {
        offsetY: 7,
      },
      tooltip: {
        y: [
          {
            title: {
              formatter(val: any) {
                return `${val}`;
              },
            },
          },
          {
            title: {
              formatter(val: any) {
                return `${val}`;
              },
            },
          },
        ],
      },
      grid: {
        borderColor: '#f1f1f1',
        padding: {
          top: 0,
          right: 25,
          bottom: 20,
          left: 25,
        },
      },
    },
  };

  const isLoading = !(chartApiResponse);

  return (
    <div className="overview-incidents-chart">
      <div
        className="tabs-container"
      >
        <ChartTabs
          tabsItems={tabsItems}
          setSelectedTab={setSelectedTab as any}
          selectedTab={selectedTab}
        />
        <div className="hc-details" />
      </div>
      <div
        style={{
          marginLeft: 15,
          marginRight: 15,
        }}
      >
        { isLoading ? (
          <LoadingSpinner height={231} />
        ) : (
          <Chart options={chartData.options} series={chartData.series} type="line" height={250} />
        ) }
        <ChartDropdown
          onChange={handleDropDownChange}
          selectedDropDownItem={selectedDropDownItem}
          dropDownItems={dropDownItems}
          dropDownOpen={dropDownOpen}
          setDropDownOpen={setDropDownOpen}
          labelClassName={`label-default ${isLoading ? 'dropdown-loading-labels' : 'dropdown-label-o'}`}
        />
      </div>
    </div>
  );
}
