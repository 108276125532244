import React from 'react';

import './SeparatorWithTitle.scss';

const SeparatorWithTitle = ({ title, className }: {title: string, className?: string}) => {
  return (
    <div className={`row row-separator ${className}`}>
      <h2>{title}</h2>
    </div>
  );
};

export default SeparatorWithTitle;

SeparatorWithTitle.defaultProps = {
  className: '',
};
