import React from 'react';
import ImageLinks from 'utils/ImageLinks';
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
  KeyboardTimePicker,
} from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import { differenceInDays } from 'date-fns';
import { getTranslatedDateTimeValue } from 'utils/dateHelpers';
import i18next from 'i18n';
import { ACTION_LOG_STATUS, ACTION_LOG_SUMMARY_MODAL_STATUS, TRANSLATIONS } from 'types/enums';
import { es, enUS } from 'date-fns/locale';
import './SummaryModalDatePickerStyle.scss';
import { useTranslation } from 'react-i18next';
import { isEspectro } from '../../../../../constants';

const DateLocale = i18next.language?.indexOf('en') === 0 ? enUS : es;

type SummaryModalDatePickerProps = {
  primaryDate: Date;
  text: string;
  secondaryDate: Date;
  modalStatus: ACTION_LOG_SUMMARY_MODAL_STATUS;
  status: ACTION_LOG_STATUS;
  onDateChange: (date: any) => void;
  showTimePicker?: boolean;
  minDate?: Date;
  isEndDate?: boolean
};

function SummaryModalDatePicker(props: SummaryModalDatePickerProps) {
  const { t } = useTranslation();
  const lng = useTranslation()[1].language;
  const {
    primaryDate,
    text,
    modalStatus,
    onDateChange,
    showTimePicker = false,
    minDate,
    status,
    isEndDate,
  } = props;
  function handleTimePicker(date: any) {
    const dateWithTimeUpdate = new Date(primaryDate);
    dateWithTimeUpdate.setHours(date?.getHours());
    dateWithTimeUpdate.setMinutes(date?.getMinutes());
    dateWithTimeUpdate.setSeconds(date?.getSeconds());
    onDateChange(dateWithTimeUpdate);
  }
  const isDueDateArriving: boolean = !!(
    isEndDate
    && status !== ACTION_LOG_STATUS.COMPLETED
    && differenceInDays(
      (new Date(primaryDate)),
      (new Date()),
    ) < 3);
  return (
    <div style={isEspectro ? { width: '100%', display: 'flex', justifyContent: 'space-between' } : {}}>
      <div>
        <div className="calender_section">
          {modalStatus === ACTION_LOG_SUMMARY_MODAL_STATUS.SUMMARY ? (
            <><img src={ImageLinks.greenCalender} alt="calendar" />
              <p className="end_date_text">
                {getTranslatedDateTimeValue(
                  primaryDate,
                  'dd MMM',
                )}
              </p>
            </>
          ) : (
            <div className="date_time_picker_container">
              <MuiPickersUtilsProvider utils={DateFnsUtils} locale={DateLocale}>
                <KeyboardDatePicker
                  disableToolbar
                  variant="inline"
                  format="MM/dd/yyyy"
                  minDate={minDate}
                  margin="normal"
                  inputVariant="outlined"
                  id="date-picker-inline"
                  value={primaryDate}
                  onChange={onDateChange}
                  KeyboardButtonProps={{
                    'aria-label': 'change date',
                  }}
                />
              </MuiPickersUtilsProvider>
              {showTimePicker && (
              <MuiPickersUtilsProvider utils={DateFnsUtils} locale={DateLocale}>
                <KeyboardTimePicker
                  disableToolbar
                  margin="normal"
                  id="time-picker"
                  value={primaryDate}
                  onChange={handleTimePicker}
                  inputVariant="outlined"
                  KeyboardButtonProps={{
                    'aria-label': 'change time',
                  }}
                />
              </MuiPickersUtilsProvider>
              )}
            </div>
          )}
        </div>
        {modalStatus === ACTION_LOG_SUMMARY_MODAL_STATUS.SUMMARY && (
        <>
          <p className="create_date">{text} </p>
          {!isEspectro && (
          <p className={`time_passed ${isDueDateArriving ? 'alert_end_date' : ''}`}>
            {t(TRANSLATIONS.SINCE)} {lng === 'es' ? 'el' : ''}{' '}
            {getTranslatedDateTimeValue(primaryDate, 'eee dd')} {lng === 'es' ? 'de' : 'of'}{' '}
            {getTranslatedDateTimeValue(primaryDate, 'MMM')}
          </p>
          )}
        </>
        )}
      </div>
      {modalStatus === ACTION_LOG_SUMMARY_MODAL_STATUS.SUMMARY && isEspectro && (
        <div>
          <p className={`time_passed ${isDueDateArriving ? 'alert_end_date' : ''}`}>
            {t(TRANSLATIONS.SINCE)} {lng === 'es' ? 'el' : ''}{' '}
            {getTranslatedDateTimeValue(primaryDate, 'eee dd')} {lng === 'es' ? 'de' : 'of'}{' '}
            {getTranslatedDateTimeValue(primaryDate, 'MMM')}
          </p>
        </div>
      )}
    </div>
  );
}

export default SummaryModalDatePicker;
