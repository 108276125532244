import React, { FC, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import './DefineLocation.scss';
import LoadingSpinner from 'components/LoadingSpinner';
import { CountryMap, DistributionCenterMap, ZoneMap } from 'types/interfaces';
import FormHeader from 'containers/FormHeader';
import Separator from 'components/Separator';
import { fromJS } from 'immutable';
import FormLocationSelection from 'containers/FormLocationSelection';
import Toggle from 'components/Button/Toggle';
import AnonymousFormDropdownWithLabel from '../../containers/AnonymousFormDropdownWithLabel';
import { COLOR_CODE, COLOR_CODE_NEW, FETCH_STATUS, MAIN_LOCATION, TRANSLATIONS } from '../../../../types/enums';

const DefineLocation: FC<Props> = (props: Props) => {
  const {
    onPageValidation,
    countries,
    handleCountryChange,
    selectedCountry,
    selectedZone,
    selectedDistributionCenter,
    selectedIsOutsideDistCenter,
    selectedLocationType,
    handleZoneChangeV2,
    handleLocationChangeV2,
    handleLocationTypeChange,
    setUsualWorkLocation,
    handleIsOutsideDistCenter,
    isFactory,
    t,
    loadingDataStatus,
    hasUsualWorkLocation,
  } = props;
  const activeTabStyle = { background: COLOR_CODE_NEW.COMPLEMENTARY,
    borderColor: COLOR_CODE_NEW.COMPLEMENTARY_LIGHT };
  const inActiveTabStyle = { background: COLOR_CODE_NEW.WHITE, borderColor: undefined };
  const [usualWL, setUsualWL] = useState(false);

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-unused-expressions
    setUsualWL(true);
  }, [hasUsualWorkLocation]);

  // componentDidUpdate(prevProps: Readonly<Props>): void {
  //   if (this.props.selectedDistributionCenter !== prevProps.selectedDistributionCenter) {
  //     this.handleValidation();
  //   }
  // }

  function usePrevious(value: any) {
    const ref = useRef();
    useEffect(() => {
      ref.current = value;
    });
    return ref.current;
  }

  const prevSelectedDistributionCenter = usePrevious(selectedDistributionCenter);

  useEffect(() => {
    function handleValidation() {
      // country add hogi yaha
      // if (!selectedZone || !selectedDistributionCenter) return onPageValidation(false);

      // if (selectedDistributionCenter?.size && selectedZone?.size) {
      //   return onPageValidation(true);
      // }
    }
    if (selectedDistributionCenter !== prevSelectedDistributionCenter) {
      handleValidation();
    }
  }, [onPageValidation, prevSelectedDistributionCenter, selectedDistributionCenter, selectedZone]);

  const zoneOfSelectedCountry = useMemo(() => {
    const getZonesBu = usualWL ? countries?.map((country: any) => {
      if (country?.get('code') === selectedCountry || (selectedCountry?.size && country?.get('code') === selectedCountry?.get('code'))) {
        return country?.get('businessUnits');
      }
    }).filter((value: any) => value != null) : null;
    return getZonesBu?.get(0) || selectedCountry?.get('zones');
    // return isFactory ? selectedCountry?.get('businessUnits') : selectedCountry?.get('zones');
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedCountry]);

  const sort = useCallback((zone: any) => {
    return zone.sort((a: ZoneMap, b: ZoneMap) => {
      if (a?.get('name') > b?.get('name')) return 1;
      return -1;
    });
  }, []);
  const sortedZonesOfSelectedCountry = useMemo(() => {
    if (usualWL ? selectedCountry! : selectedCountry?.get('code').trim().length) {
      return [...sort([...zoneOfSelectedCountry])
        .map((zone: any) => {
          return {
            value: zone?.get('slug'),
            label: zone?.get('name'),
            locations: zone?.get('locations'),
          };
          // return sort(zone).map((response: ZoneMap) => {
          //   console.log({ response });
          //   return {
          //     value: response?.get('slug'),
          //     label: response?.get('name'),
          //     locations: response?.get('locations'),
          //   };
          // });
        })];
    }
    return [];
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    selectedCountry, zoneOfSelectedCountry, sort, isFactory,
  ]);

  const sortedDistributionCenterOfSelectedCountry = useMemo(() => {
    const sortedBu = fromJS(sortedZonesOfSelectedCountry?.find((bu: any) => bu.value === selectedZone));
    if (!sortedBu && selectedZone?.get('slug')) {
      return [
        ...sort([...selectedZone?.get('locations')]),
      ].map((location: any) => ({
        value: location.get('slug'),
        label: location.get('name'),
      }));
    }
    if (sortedBu) {
      return [
        ...sort([...sortedBu?.get('locations')]),
      ].map((location: any) => ({
        value: location.get('slug'),
        label: location.get('name'),
      }));
    }
    return [];
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    sort, selectedZone,
  ]);

  function onChangeZone(e: any) {
    const zoneSlug = e?.target?.value;
    const chosenZone = sortedZonesOfSelectedCountry?.find((zone: any) => zone.value === zoneSlug);

    const mappedChosenZone = fromJS({
      slug: chosenZone.value,
      name: chosenZone.label,
      locations: chosenZone.locations,
    });

    handleZoneChangeV2(mappedChosenZone);
  }

  function onChangeLocation(e: any) {
    const locationSlug = e?.target?.value;
    const chosenLocation = sortedDistributionCenterOfSelectedCountry?.find(
      (location: any) => location.value === locationSlug
    );
    const mappedChosenLocation = fromJS({
      slug: chosenLocation?.value,
      name: chosenLocation?.label,
    });

    handleLocationChangeV2(mappedChosenLocation);
  }

  return (
    <>
      <FormHeader containerClassName="justify-start" />

      <Separator />
      <FormLocationSelection
        setSelected={handleLocationTypeChange}
        selectedLocationType={selectedLocationType}
        setUsualWorkLocation={setUsualWorkLocation}
      />
      <Separator />
      {/* {selectedLocationType === 'DISTRIBUTION_CENTER' && (
        <>
          <Toggle
            options={[
              { key: MAIN_LOCATION[0], text: t(TRANSLATIONS.INSIDE_DC) },
              { key: MAIN_LOCATION[1], text: t(TRANSLATIONS.OUTSIDE_DC) },
            ]}
            onOptionSelect={handleIsOutsideDistCenter}
            selectedOption={selectedIsOutsideDistCenter || ''}
            activeTabStyle={activeTabStyle}
            inActiveTabStyle={inActiveTabStyle}
            containerClassName="mt-2"
          />
          <Separator />
        </>
      )} */}
      {props.showDropdowns && loadingDataStatus && (
        <div className="mt-4 mb-4 dropdownLayout">
          {/* country dropdown */}
          <AnonymousFormDropdownWithLabel
            name={t(TRANSLATIONS.BU)}
            value={usualWL && (hasUsualWorkLocation || !selectedCountry?.size) ? selectedCountry! : (selectedCountry?.size && selectedCountry?.get('code')) || ''}
            onChange={handleCountryChange}
            dropdownContent={countries?.map((country: any) => ({
              value: country.get('code'),
              label: country.get('name'),
            }))}
            placeholder={`${t(TRANSLATIONS.SELECT)} ${t(TRANSLATIONS.BU).toLowerCase()}`}
            label={t(TRANSLATIONS.BU)}
            mainContainerClassName="mt-2"
          />
          {/* zone dropdown */}
          {(selectedCountry! || selectedCountry?.get('code').trim().length) && (
            <>
              <AnonymousFormDropdownWithLabel
                name={t(TRANSLATIONS.REGION)}
                value={usualWL && (hasUsualWorkLocation || !selectedZone?.size) ? selectedZone! : (selectedZone?.size && selectedZone?.get('slug')) || ''}
                onChange={onChangeZone}
                dropdownContent={sortedZonesOfSelectedCountry as any}
                placeholder={isFactory ? `${t(TRANSLATIONS.SELECT)} ${t(TRANSLATIONS.BUSINESS_UNIT)}` : t(TRANSLATIONS.SELECT_REGION)}
                label={isFactory ? t(TRANSLATIONS.BUSINESS_UNIT) : t(TRANSLATIONS.REGION)}
                mainContainerClassName="mt-2 form-details"
              />
              {
                (selectedDistributionCenter! || selectedZone?.get('slug')) && (
                  <AnonymousFormDropdownWithLabel
                    name={t(TRANSLATIONS.SELECT_DC)}
                    value={usualWL && hasUsualWorkLocation ? selectedDistributionCenter || '' : (selectedDistributionCenter?.size && selectedDistributionCenter?.get('slug')) || ''}
                    onChange={onChangeLocation}
                    dropdownContent={sortedDistributionCenterOfSelectedCountry}
                    placeholder={isFactory ? `${t(TRANSLATIONS.SELECT)} ${t(TRANSLATIONS.LOCATION)}` : t(TRANSLATIONS.SELECT_DC)}
                    label={isFactory ? t(TRANSLATIONS.LOCATION) : t(TRANSLATIONS.AGENCIA_DC)}
                    mainContainerClassName="mt-2 form-details"
                  />
                )
              }
            </>
          )}
        </div>
      )}
    </>
  );
};

type Props = {
  zones?: any;
  countries?: any;
  selectedZone?: ZoneMap;
  selectedCountry?: CountryMap;
  selectedDistributionCenter?: DistributionCenterMap;
  handleZoneChange?: Function,
  handleZoneChangeV2: Function,
  handleLocationChangeV2: Function,
  handleCountryChange: any,
  handleDistCenterChange: Function,
  // stepped pagination
  onPageValidation?: any;
  handleLocationTypeChange?: Function;
  setUsualWorkLocation?: Function;
  handleIsOutsideDistCenter?: Function;
  selectedIsOutsideDistCenter?: any;
  selectedLocationType?: string;
  isFactory: boolean;
  showDropdowns: boolean;
  t: any;
  loadingDataStatus?: FETCH_STATUS;
  hasUsualWorkLocation?: boolean;
};
type State = {};

export default DefineLocation;

//   {/* distribution center dropdown */}

// </>
