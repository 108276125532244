import React, { useState } from 'react';
import Chart from 'react-apexcharts';
import { useCubeQuery } from '@cubejs-client/react';
import { format, parseISO } from 'date-fns';

import { DefaultOptions, LineOptions } from 'utils/ChartOptions';
import ChartTabs from 'components/Tabs/ChartTabs';
import CheckBoxWithIcon from 'components/CheckBoxWithIcon';
import checkedBox from 'assets/icons/checked-box.svg';
import uncheckedBox from 'assets/icons/unchecked-box.svg';

import '../Accidentability.scss';
import LoadingSpinner from 'components/LoadingSpinner';
import getAccidentabilityQuery from 'utils/CubeObject';
import { Query } from '@cubejs-client/core';
import { Item } from 'components/Dropdown/ChartDropdown/ChartDropdown';
import { generateYearlyRange } from 'utils/dateHelpers';

type Props = {
  year: Item,
};

const IncidentsOverTime = (props: Props) => {
  const selectedDateRange = generateYearlyRange(props.year.label);
  const lastYearDateRange = generateYearlyRange((Number(props.year.label) - 1).toString());
  const checkboxOptions = ['T1', 'T2', 'Warehouse'];
  const [selectedCheckboxes, setSelectedCheckbox] = useState([checkboxOptions[0]]);

  const { resultSet: thisYearLTIResult, isLoading } = useCubeQuery(
    getAccidentabilityQuery({
      measure: ['AnalyticsIncidents.count'],
      dateOptions: {
        dateRange: selectedDateRange,
        dimension: 'AnalyticsIncidents.dateOfIncidentOccurred',
        granularity: 'month',
      },
      filterOptions: [{
        dimension: 'AnalyticsIncidents.clasificationOfIncidents',
        operator: 'contains',
        values: ['LTI'],
      }, {
        dimension: 'AnalyticsIncidents.region',
        operator: 'contains',
        values: selectedCheckboxes,
      }],
    }) as Query,
  );

  const thisYearLTI = thisYearLTIResult?.tablePivot()
    .map(i => (i['AnalyticsIncidents.count']))
    .map(i => (typeof i === 'undefined' ? 0 : i));

  const { resultSet: thisYearSIFResult } = useCubeQuery(
    getAccidentabilityQuery({
      dateOptions: {
        dateRange: selectedDateRange,
        dimension: 'AnalyticsIncidents.dateOfIncidentOccurred',
        granularity: 'month',
      },
      measure: ['AnalyticsIncidents.sifCount'],
      filterOptions: [{
        dimension: 'AnalyticsIncidents.region',
        operator: 'contains',
        values: selectedCheckboxes,
      }],
    }) as Query,
  );

  const thisYearSFI = thisYearSIFResult?.tablePivot()
    .map(i => (i['AnalyticsIncidents.sifCount']))
    .map(i => (typeof i === 'undefined' ? 0 : i));

  const { resultSet: thisYearTRIResult } = useCubeQuery(
    getAccidentabilityQuery({
      measure: ['AnalyticsIncidents.countTRI'],
      dateOptions: {
        dateRange: selectedDateRange,
        dimension: 'AnalyticsIncidents.dateOfIncidentOccurred',
        granularity: 'month',
      },
      filterOptions: [{
        dimension: 'AnalyticsIncidents.region',
        operator: 'contains',
        values: selectedCheckboxes,
      }],
    }) as Query,
  );

  const thisYearTRI = thisYearTRIResult?.tablePivot()
    .map(i => (i['AnalyticsIncidents.countTRI']))
    .map(i => (typeof i === 'undefined' ? 0 : i));

  const { resultSet: lastYearLTIResult, isLoading: isLoadingLast } = useCubeQuery(
    getAccidentabilityQuery({
      measure: ['AnalyticsIncidents.count'],
      dateOptions: {
        dateRange: selectedDateRange,
        dimension: 'AnalyticsIncidents.dateOfIncidentOccurred',
        granularity: 'month',
      },
      filterOptions: [{
        dimension: 'AnalyticsIncidents.clasificationOfIncidents',
        operator: 'contains',
        values: ['LTI'],
      }, {
        dimension: 'AnalyticsIncidents.region',
        operator: 'contains',
        values: selectedCheckboxes,
      }],
    }) as Query,
  );

  const ltiLastYear = lastYearLTIResult?.tablePivot()
    .map(i => (i['AnalyticsIncidents.count']))
    .map(i => (typeof i === 'undefined' ? 0 : i));

  const { resultSet: lastYearSIFResult } = useCubeQuery(
    getAccidentabilityQuery({
      measure: ['AnalyticsIncidents.sifCount'],
      dateOptions: {
        dateRange: lastYearDateRange,
        dimension: 'AnalyticsIncidents.dateOfIncidentOccurred',
        granularity: 'month',
      },
      filterOptions: [{
        dimension: 'AnalyticsIncidents.region',
        operator: 'contains',
        values: selectedCheckboxes,
      }],
    }) as Query,
  );

  const sifLastYear = lastYearSIFResult?.tablePivot()
    .map(i => (i['AnalyticsIncidents.sifCount']))
    .map(i => (typeof i === 'undefined' ? 0 : i));

  const { resultSet: lastYearTRIResult } = useCubeQuery(
    getAccidentabilityQuery({
      measure: ['AnalyticsIncidents.countTRI'],
      dateOptions: {
        dateRange: lastYearDateRange,
        dimension: 'AnalyticsIncidents.dateOfIncidentOccurred',
        granularity: 'month',
      },
      filterOptions: [{
        dimension: 'AnalyticsIncidents.region',
        operator: 'contains',
        values: selectedCheckboxes,
      }],
    }) as Query,
  );

  const ACPreviousYear = `AC${(Number(props.year.label) - 1).toString().slice(2)}`;
  const ACSelectedYear = `AC${props.year.label.slice(2)}`;
  const BUSelectedYear = `BU${props.year.label.slice(2)}`;

  const lastYearTRI = lastYearTRIResult?.tablePivot()
    .map(i => (i['AnalyticsIncidents.countTRI']))
    .map(i => (typeof i === 'undefined' ? 0 : i));

  const sif = [{
    name: ACPreviousYear,
    data: sifLastYear,
  },
  {
    name: BUSelectedYear,
    data: [2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2],
  },
  {
    name: ACSelectedYear,
    data: thisYearSFI,
  }];

  const lti = [{
    name: ACPreviousYear,
    data: ltiLastYear,
  },
  {
    name: BUSelectedYear,
    data: [2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2],
  },
  {
    name: ACSelectedYear,
    data: thisYearLTI,
  }];

  const tri = [{
    name: ACPreviousYear,
    data: lastYearTRI,
  },
  {
    name: BUSelectedYear,
    data: [2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2],
  },
  {
    name: ACSelectedYear,
    data: thisYearTRI,
  }];

  const tabsItems = [{
    title: 'SIF',
    series: sif,
  }, {
    title: 'LTI',
    series: lti,
  },
  {
    title: 'TRI',
    series: tri,
  }];

  const [selectedTab, setSelectedTab] = useState({ title: 'SIF', series: sif });

  const customOptions = {
    ...LineOptions,
    ...DefaultOptions,
    stroke: {
      ...DefaultOptions.stroke,
      dashArray: [0, 4, 0],
    },
    xaxis: {
      categories: thisYearLTIResult?.series()[0]?.series.map((x: any) => format(parseISO(x.x), 'MMM')),
    },
  };

  const changeCheckboxes = (label: string) => {
    if (selectedCheckboxes.includes(label)) {
      setSelectedCheckbox(selectedCheckboxes.filter(checkbox => checkbox !== label));
    } else {
      setSelectedCheckbox([...selectedCheckboxes, label]);
    }
  };

  return (
    <div className="incidents-over-time">
      <div className="incidents-over-time__top-row">
        <ChartTabs
          tabsItems={tabsItems}
          setSelectedTab={setSelectedTab as any}
          selectedTab={selectedTab}
        />
        {checkboxOptions.map(label => (
          <span>
            <CheckBoxWithIcon
              label={label}
              selected={selectedCheckboxes.includes(label)}
              selectedIcon={checkedBox}
              deSelectedIcon={uncheckedBox}
              onClick={() => changeCheckboxes(label)}
            />
          </span>
        ))}
      </div>
      {!isLoading && !isLoadingLast && thisYearLTIResult && lastYearLTIResult ? (
        <Chart
          type="line"
          options={customOptions}
          series={selectedTab.title === 'SIF' ? sif : selectedTab.title === 'LTI' ? lti : tri}
          height="320px"
        />
      ) : (
        <LoadingSpinner height={320} />
      )}
    </div>
  );
};

export default IncidentsOverTime;
