import React, { useCallback, useEffect, useState } from 'react';
import { cloneDeep } from 'lodash';
import { TRANSLATIONS } from 'types/enums';
import { useTranslation } from 'react-i18next';
import ImageLinks from 'utils/ImageLinks';
import { ChecklistAnswers, ChecklistBit, ChecklistBitTypes, ChecklistItem, ChecklistProps, ChecklistSection } from '../../../espectro-utils/checklistRendererUtils/types';
import { getAnswersAndAnswerableQuestions, getPercentageCompletedBasedOnSection, shouldComponentRender } from '../../../espectro-utils/checklistRendererUtils/ChecklistRendererUtils';
import './ChecklistRenderer.scss';
import Renderbits, { RenderBitInitializer } from './ChecklistQuestions';
import AcknowledgementBit from '../AcknowledgementBit/AcknowledgementBit';

export default function ChecklistRenderer({
  getAnswersAndAnswerableQuestions: callerGetAnswersAndAnswerableQuestions,
  checklist,
  customBits,
  header,
  footer,
  setAnswers: callerSetAnswers,
  answers: callerAnswers,
  customBitProgressOverride,
  sectionalProgress,
  isChecklistAnswered,
  answeredChecklistConfig,
  showNotes = () => null,
  getDefaultNoteValue = () => undefined,
}: ChecklistProps) {
  const { t } = useTranslation();

  const [answers, setAnswers] = useState<ChecklistAnswers>(
    () => callerAnswers
      || checklist.map(s => s.items.map(i => i.bits.map(q => RenderBitInitializer(q)))),
  );

  useEffect(() => {
    if (callerAnswers && callerAnswers.length) {
      setAnswers(callerAnswers);
    }
  }, [callerAnswers]);

  useEffect(() => {
    if (!isChecklistAnswered) {
      const points = new Array(checklist.length).fill(0);
      checklist.forEach((section, index) => {
        const score = getPercentageCompletedBasedOnSection(index, '', answers, checklist, customBitProgressOverride);
        if (score === 100) points[index] = 1;
      });
      sectionalProgress?.(points);
    }
  }, [checklist, answers, isChecklistAnswered, sectionalProgress, customBitProgressOverride]);

  const setAnswerForSectionItemAndBit = useCallback(
    (section: number, item: number, bit: number, answer: any) => {
      const cloneAnswers = cloneDeep(answers);
      cloneAnswers[section][item][bit] = answer;
      setAnswers(cloneAnswers);
      if (callerSetAnswers) callerSetAnswers(cloneAnswers);
    },
    [answers, callerSetAnswers],
  );

  const setBulkAnswers = useCallback((updatedAnswers: any) => {
    setAnswers(updatedAnswers);
    if (callerSetAnswers) callerSetAnswers(updatedAnswers);
  }, [callerSetAnswers]);

  useEffect(() => {
    if (callerGetAnswersAndAnswerableQuestions) {
      callerGetAnswersAndAnswerableQuestions(getAnswersAndAnswerableQuestions(checklist, answers));
    }
  }, [answers, callerGetAnswersAndAnswerableQuestions, checklist]);
  const RenderSection = useCallback(
    (
      section: ChecklistSection,
      // index: number,
      // isChecklistAnswered?: boolean,
    ) => {
      return (
        <div style={{ marginTop: 20 }} id={section?.key}>
          <h1>{section.title}</h1>
          <h2>{section.description}</h2>
        </div>
      );
    }, [],
  );
  const RenderBitsComponent = useCallback(
    (
      bits: ChecklistBit[],
      bitAnswers,
      sIndex,
      iIndex,
      isChecklistAnsweredProp,
      answeredChecklistConfigProp,
    ) => (
      <Renderbits
        bits={bits}
        bitAnswers={bitAnswers}
        answers={answers}
        setAnswer={
          (index:number, answer: any) => setAnswerForSectionItemAndBit(
            sIndex, iIndex, index, answer,
          )
        }
        setBulkAnswers={(updatedAnswers: any) => setBulkAnswers(updatedAnswers)}
        customBits={customBits}
        sectionIndex={sIndex}
        itemIndex={iIndex}
        checklist={checklist}
        isChecklistAnswered={isChecklistAnsweredProp}
        answeredChecklistConfig={answeredChecklistConfigProp}
      />
    ),
    [answers, customBits, checklist, setAnswerForSectionItemAndBit, setBulkAnswers],
  );

  const RenderItems = useCallback(
    (
      items: ChecklistItem[],
      sIndex: number,
      isChecklistAnsweredProp?: boolean,
      answeredChecklistConfigProp?,
    ) => (
      <div style={{ backgroundColor: 'white' }}>
        {items.map((item, index) => {
          if (!shouldComponentRender(answers, item.showIf, sIndex, index, 0, checklist)) return null;
          const isAcknowledgementBit = item.bits[0].type === ChecklistBitTypes.ACK;
          const defaultNoteString = item.bits[0]?.additionalFeatures?.note || getDefaultNoteValue();
          const noteValue = [null, undefined, ''].includes(defaultNoteString)
            ? t(TRANSLATIONS.NOTE)
            : defaultNoteString?.substring(0, 80);

          return (
            <div>
              <div
                className={isAcknowledgementBit ? '' : 'checklist-question_container'}
                id={`${sIndex}_${index}`}
              >
                <div
                  style={{
                    padding: !isAcknowledgementBit ? '20px' : '0px',
                  }}
                  id={`${sIndex}_${index}`}
                >
                  {(!!item.title && !isAcknowledgementBit) && (
                  <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                    <p>{item.title}</p>
                    {item.required && <p style={{ color: 'red', marginLeft: 6 }}>*</p>}
                  </div>
                  )}
                  {(!!item.description && !isAcknowledgementBit) && <p>{item.description}</p>}
                  {
                    RenderBitsComponent(
                      item.bits,
                      answers[sIndex][index],
                      sIndex,
                      index,
                      isChecklistAnsweredProp,
                      answeredChecklistConfigProp,
                    )
                  }
                  {item.bits[0].additionalFeatures
                    && item.bits[0].additionalFeatures.allowsNote && (
                      <div className="notes-container" onClick={() => { showNotes(sIndex, index); }} role="button">
                        <img src={ImageLinks.espectro.noteIcon} alt="note section" width={24} height={24} style={{ marginRight: 6 }} />
                        <text className="notesTitle">{noteValue}</text>
                      </div>
                  )}
                </div>
                {isAcknowledgementBit && !!item.showIf && (
                  <AcknowledgementBit
                    // @ts-ignore
                    text={item.title}
                    // @ts-ignore
                    type={item.bits[0].props.instructionType}
                  />
                )}
              </div>
              <div
                style={{
                  backgroundColor: 'white',
                  height: '22px',
                }}
              />
            </div>
          );
        })}
      </div>
    ),
    [answers, checklist, getDefaultNoteValue, t, RenderBitsComponent, showNotes],
  );

  return (
    <div>
      {header}
      {checklist.map((section, index) => {
        const isExpandable = !!(section.title || section.description);
        if (!shouldComponentRender(answers, section.showIf, index)) return null;
        return (
          <>
            {isExpandable && RenderSection(
              section,
              // index,
              // isChecklistAnswered,
            )}
            {/* {isChecklistAnswered
            && RenderItems(section.items, index, isChecklistAnswered, answeredChecklistConfig)} */}
            {RenderItems(section.items, index, isChecklistAnswered, answeredChecklistConfig)}
          </>
        );
      })}
      {footer}
    </div>
  );
}
