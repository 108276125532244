/* eslint-disable @typescript-eslint/camelcase */
import React, { useState, useEffect, useMemo, useContext, useRef } from 'react';
import MainNavigationHeader from 'components/MainNavigationHeader';
import req from 'utils/request';
// @ts-ignore
import ReactTable from 'react-table-legacy';
import { selectUser, selectUserType } from 'store/selectors/user.selectors';
import LoadingSpinner from 'components/LoadingSpinner';
import InputWithLabel from 'components/Input/InputWithLabel';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { Button, Tooltip, Drawer } from '@material-ui/core';
import BasicButton from 'components/Button/BasicButton';
import { TRANSLATIONS, UserTypes } from 'types/enums';
import EmptyState from 'components/EmptyState/EmptyState';
import _ from 'lodash';
import { useSelector } from 'react-redux';
import ImageLinks from 'utils/ImageLinks';
import { useUpdateUserTypeMutation, useCreateEspectroUserMutation, useGetUserAvailableLocationsLazyQuery, Espectro_User_Types, useUpdateUserDefaultLocationMutation } from 'generated/graphql';
import InputWithIcon from 'components/Input/InputWithIcon';
import UserManagementContextProvider, { UserManagementContext } from './context';
import './userList.scss';
import userImg from '../../../../assets/icons/user-espectro.svg';
import copy from '../../../../assets/icons/copy-icon.svg';
import UserFilters from './filters';
import userSearch from '../../../../assets/icons/input-search.svg';
import UserStatusDropdown from './UserStatusDropdown';
import AddUserModal from './AddUserModal';
import UserRegisterSuccess from './UserRegisterSuccess';
import UserManagement from './UserManagement';

import { isEspectroEnabled } from '../../../Espectro/constants';
import UserTypeDropdown from './UserTypeDropdown';

export const getQueryParamString = (params: any) => {
  const queryKeys = Object.keys(params);
  const string = queryKeys
    .filter(key => params[key])
    .map(key => `${key}=${encodeURIComponent(params[key])}`)
    .join('&');
  return string ? `?${string}` : '';
};

const useDebounce = (value: any, timeout: any) => {
  const [state, setState] = useState(value);

  useEffect(() => {
    const collection = document.getElementsByClassName('rt-tr-group');
    for (let i = 0; i < collection.length; i++) {
      collection[i].setAttribute('tabIndex', '1');
    }
  });

  useEffect(() => {
    const handler = setTimeout(() => setState(value), timeout);

    return () => clearTimeout(handler);
  }, [value, timeout]);

  return state;
};

type UserInfoType = {
  position: string;
  location: string;
  name: string;
  pin: string;
  shortSlug: string;
};

const UsersIndex = (props: any) => {
  const userSlug = props.history.location.search.split('=')[1];
  const loggedInUserType = useSelector(selectUserType);

  const distCenterSlug = props.match.params.distCenter;
  const { t } = useTranslation();
  const resetFilters = { userType: '', locationSlug: '', roleType: '', limit: 20, page: 1 };
  const [totalPages, setTotalPages] = useState(1);
  const [filters, setFilters] = useState(resetFilters);
  const [users, setUsers] = useState([]);
  const [search, setSearch] = useState('');
  const [isLoading, setIsLoading] = useState(true);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [emptyData, setEmpty] = useState(false);
  const [showAddUserModal, setShowAddUserModal] = useState<boolean>(false);
  const [showPinPopup, setShowPinPopup] = useState<boolean>(false);
  const [createdUserInfo, setCreatedUserInfo] = useState<UserInfoType>({ position: '', location: '', name: '', pin: '', shortSlug: '' });
  const fileRef = useRef<HTMLInputElement>(null);
  const inputRef = useRef<HTMLInputElement>(null);
  const loggedInUser = useSelector(selectUser);
  const QrCopyText = t(TRANSLATIONS.COPY_QR_CODE);
  const [showAddUserDrawer, setShowAddUserDrawer] = useState(false);
  const { email: loggedInUserEmail } = loggedInUser.toJS();
  const userState = useContext(UserManagementContext);
  const [displayForm, setDisplayForm] = useState(false);
  const [email, setEmail] = useState('');
  const [userType, setNewUserType] = useState();
  const [userDefaultLocationForInvite, setUserDefaultLocationForInvite] = useState<any>();

  const updateSelectedUserDetails = (user: any) => {
    userState.setUserDetails(user);
  };

  const copyToClipboard = async (text: string) => {
    navigator.clipboard.writeText(text);
  };

  const getUserInfo = async () => {
    setIsLoading(true);
    const url = `/api/v1/users-management${getQueryParamString({
      shortSlug: search || userSlug || '',
      ...filters,
    })}`;
    try {
      const res = await req.get(url);
      setIsLoading(false);
      setTotalPages(res?.userManagement?.paginationInfo?.totalPages);
      setUsers(res.userManagement.users);
    } catch (error) {
      if (error?.name !== 'DEBOUNCED_REQUEST_ERROR') {
        toast.error('Error occurred while fetching users list');
      }
    } finally {
      inputRef.current?.focus();
    }
  };

  const [updateUserType, { loading: updateUserLoader }] = useUpdateUserTypeMutation({
    onError: (error: any) => {
      console.log('ERROR UPDATE USER TYPE', error);
    },
    onCompleted: (data: any) => {
      console.log('DATA from update user type', data);
    },
  });

  const [createEspectroUser, { loading: createUserLoader }] = useCreateEspectroUserMutation({
    onError: error => {
      console.log('create UPDATE USER TYPE', error);
      toast.error(error.message);
    },
    onCompleted: (data: any) => {
      toast.success(t(TRANSLATIONS.INVITATION_SENT));
      setShowAddUserDrawer(false);
      setEmail('');
      setNewUserType('');
      console.log('DATA from create user type', data);
    },
  });

  const [getUserAvailableLocations, {
    data: availableLocationsForCompany,
    loading: isGetUserLocationsQueryLoading,
  }] = useGetUserAvailableLocationsLazyQuery({
    onError: e => toast.error(e.message),
  });

  const [
    updateUserDefaultLocationMutation, {
      loading: isUpdateUserDefaultLocationLoading,
    },
  ] = useUpdateUserDefaultLocationMutation({
    onError: e => toast.error(e?.message),
    onCompleted: () => getUserInfo(),
  });

  const updateUserDefaultLocation = (locationId: string, userId: number) => {
    updateUserDefaultLocationMutation({
      variables: {
        locationId: String(locationId),
        userId: String(userId),
      },
    });
  };

  const searchBy = isEspectroEnabled ? `${t(TRANSLATIONS.SEARCH_USERS)}` : `${t(TRANSLATIONS.SEARCH)} ${t(TRANSLATIONS.BY)} ${t(TRANSLATIONS.NAME)}, ${t(
    TRANSLATIONS.QR_CODE,
  )}, ${t(TRANSLATIONS.EMAIL)}, ${t(TRANSLATIONS.USER_TYPE)}, ${t(TRANSLATIONS.POSITION)}, ${t(
    TRANSLATIONS.SHARP,
  )}.`;

  const debouncedUsersReq = useDebounce(search, 1500);

  const dropDownItem = [
    { slug: 'ReporterOnlyDPO', label: 'Lite' },
    { slug: 'SubzoneGeneralManagerDPO', label: 'Business' },
    { slug: 'EspectroViewOnly', label: 'Read Only' },
  ];

  useEffect(() => {
    getUserInfo();
    getUserAvailableLocations();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    getUserInfo();
    setIsLoading(true);
    // eslint-disable-next-line
  }, [debouncedUsersReq, filters.userType, filters.roleType, filters.locationSlug, filters.page,updateUserLoader]);

  useEffect(() => {
    if (users?.length === 0) {
      return setEmpty(true);
    }
    setEmpty(false);
  }, [users]);

  const handleSearch = async (event: any) => {
    setSearch(event.target.value);
  };

  const onChangeUserType = (event:any, userId:any) => {
    updateUserType({ variables: {
      id: userId,
      type: event,
    } });
  };

  const onCreateUser = () => {
    createEspectroUser({
      variables: {
        email,
        type: userType as unknown as Espectro_User_Types,
        // userDefaultLocation: userDefaultLocationForInvite?.id,
      },
    });
  };

  const getUserType = (type: string) => {
    const label = dropDownItem.find(d => d.slug === type)?.label;
    return label;
  };

  const columns = useMemo(() => {
    return [
      {
        Header: () => {
          return <img alt="user" width={30} height={30} src={userImg} />;
        },
        accessor: 'name',
        Cell: (v: any) => {
          return v.value.includes('NEW_ESPECTRO') ? t(TRANSLATIONS.INVITATION_SENT) : v.value;
        },
      },
      // (false) && {
      //   id: 'defaultLocation',
      //   Header: 'Ubicacion',
      //   Cell: (v: any) => {
      //     if (isUpdateUserDefaultLocationLoading) {
      //       return (
      //         <div>
      //           <LoadingSpinner text="Loading.." />
      //         </div>
      //       );
      //     }
      //     return (
      //       <select
      //         style={{
      //           background: '#FFFFFF',
      //           border: '1px solid #DEDEDE',
      //           width: '100%',
      //           padding: '8px',
      //           borderRadius: '4px',
      //           paddingRight: '8px',
      //           fontSize: '14px',
      //         }}
      //         onChange={event => {
      //           updateUserDefaultLocation(event.target.value, v.original.id);
      //         }}
      //       >
      //         <option style={{ display: 'none' }} selected>{v.original.distributionCenter.name}</option>

      //         {availableLocationsForCompany?.getUserAvailableLocations?.map(option => (
      //           <option
      //             value={option?.id}
      //             selected={option?.name === v.original.distributionCenter.name}
      //           >
      //             {option?.name}
      //           </option>
      //         ))}
      //       </select>
      //     );
      //   },
      // },
      {
        Header: t(TRANSLATIONS.EMAIL),
        accessor: 'email',
        Cell: (v: any) => {
          return String(v.value ?? 'N/A');
        },
      },
      {
        Header: t(TRANSLATIONS.QR_CODE),
        accessor: 'shortSlug',
        Cell: (v: any) => {
          return <div style={{ display: 'flex' }}><b>{String(v.value ?? 'N/A')}</b><Tooltip title={QrCopyText} placement="right-start"><img alt="copy" style={{ cursor: 'pointer', marginLeft: 5 }} width={25} height={25} src={copy} onClick={() => copyToClipboard(v.value || 'N/A')} /></Tooltip></div>;
        },
      },
      {
        id: 'configuration',
        Header: `${t(TRANSLATIONS.USER_TYPE)} / ${t(TRANSLATIONS.USER_ROLE_TRANSLATION)}`,
        accessor: 'type',
        width: 240,
        Cell: (v: any) => {
          if (loggedInUserType === UserTypes.EspectroViewOnly) {
            return <p style={{ fontSize: 14 }}>{getUserType(v.value)}</p>;
          }
          return (
            <UserTypeDropdown
              onChange={(event: any) => {
                onChangeUserType(event, v?.original?.id);
              }}
              userType={v.value}
              usersTypesArray={dropDownItem}
              isLoading={isLoading}
            />
          );
        },
      },
    ] as any;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [t, users]);

  const onRowClick = (state: any, rowInfo: any, column: any) => {
    return {
      onClick: () => {
        if (loggedInUserType !== UserTypes.EspectroViewOnly) {
          if (
            column?.id !== 'configuration'
            && column?.id !== 'shortSlug'
            && column?.id !== 'defaultLocation'
          ) {
            updateSelectedUserDetails({
              ...(rowInfo?.original as any),
            });
            if (rowInfo?.original?.id) {
              userState.setUMDrawerVisibility(!userState.isUMDrawerVisible);
            }
          }
        }
      },
    };
  };
  const handleUploadButton = () => {
    if (fileRef.current !== null) {
      fileRef.current.click();
    }
  };
  const handleAttachmentsUpload = async (e: any) => {
    const { files } = e.target;
    if (files?.length) {
      const config = {
        headers: { 'content-type': 'multipart/form-data' },
      };
      const data = new FormData();

      data.set('file', files[0]);

      try {
        await req.post('/api/v1/upload-user-positions', data, config);
        toast.success('Uploaded Successfully!');
      } catch (err) {
        toast.error('Error while uploading');
        console.log('error para David del file upload: ', err);
      } finally {
        if (fileRef.current !== null) {
          fileRef.current.value = '';
        }
      }
    }
  };

  const handleAddUser = () => {
    if (isEspectroEnabled) setShowAddUserDrawer(true);
    else setShowAddUserModal(true);
    // isEspectroEnabled ? setShowAddUserDrawer(true)
    //   : setShowAddUserModal(true);
  };

  const handleCloseAddUserModal = () => {
    setShowAddUserModal(false);
  };

  const handleShowPinPopup = (userInfo: UserInfoType) => {
    setCreatedUserInfo(userInfo);
    setShowAddUserModal(false);
    setShowPinPopup(true);
  };

  const handleCloseShowPinPopup = () => {
    getUserInfo();
    setShowPinPopup(false);
  };

  const goBack = () => {
    props.history.goBack();
  };

  const onChangeEmail = (event:any) => {
    setEmail(event.target.value);
  };

  const onChangeNewUserType = (event:any) => {
    setNewUserType(event.target.value);
  };

  const getUserNameForDropdown = (label: string) => {
    const dropDownUserObj: any = {
      SubzoneGeneralManagerDPO: 'Business',
      ReporterOnlyDPO: 'Lite',
      EspectroViewOnly: 'Read Only',
    };

    return dropDownUserObj[label];
  };

  const onHandleClosingModal = () => {
    if (displayForm) {
      setDisplayForm(false);
    } else setShowAddUserDrawer(false);
    setEmail('');
    setNewUserType('');
  };

  const showTooltip = loggedInUserType === UserTypes.EspectroViewOnly;

  return (
    <>
      {isEspectroEnabled ? (
        <div className="user-checklistHeader__back">
          <div className="user-roles__flex-row" role="button" onClick={goBack}>
            <img alt="backArrow" src={ImageLinks.espectro.backArrow} />
            <p>{t(TRANSLATIONS.GO_BACK)}</p>
          </div>
        </div>
      )
        : (
          <MainNavigationHeader
            userName=""
            goBack={distCenterSlug ? props.history.goBack : null}
            nutIconOnly
          />
        )}
      <div className=" user-list-container">
        <>
          <div className="user-search-container">

            <InputWithLabel
              inputRef={inputRef}
              showTooltip={showTooltip}
              inputImg={userSearch}
              onChange={handleSearch}
              placeholder={searchBy}
              value={search}
              label={isEspectroEnabled ? t(TRANSLATIONS.USERS_ESP) : t(TRANSLATIONS.SEARCH_USERS)}
              labelClassName="search-header"
              inputClassName="search-input"
            />
            <UserFilters {...{ setFilters, filters, resetFilters, setSearch, isLoading }} />

            <div className="add_user_container">
              <BasicButton
                text={t(TRANSLATIONS.ADD_USER)}
                disabled={loggedInUserType === UserTypes.EspectroViewOnly}
                onClick={handleAddUser}
                style={{ backgroundColor: loggedInUserType === UserTypes.EspectroViewOnly ? '#6C63FF80' : '#6C63FF' }}
                className="add_user_button_filled"
              />
            </div>

            {(!isEspectroEnabled) && (
            <div className={loggedInUserEmail.includes('arvolution') ? 'upload_button_container' : 'hidden'}>
              <BasicButton
                text="Subir template"
                className="upload_button_filled"
                onClick={handleUploadButton}
              />
              <input
                ref={fileRef}
                className="hidden"
                type="file"
                onChange={handleAttachmentsUpload}
                name="file"
                multiple
                accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
              />
            </div>
            )}
          </div>
          <div
            style={{
              padding: '0px 0',
              margin: '15px 0',
              position: 'relative',
              display: isLoading || users?.length === 0 ? 'flex' : '',
            }}
            className="user-table"
          >
            {isLoading ? (
              <LoadingSpinner />
            ) : users?.length > 0 ? (
              <>
                <ReactTable
                  minRows={0}
                  data={users}
                  columns={columns}
                  showPagination={false}
                  className="-striped max-height"
                  NoDataComponent={EmptyState}
                  getTdProps={onRowClick}
                />
                <div className="table-footer">
                  <Button
                    variant="contained"
                    onClick={() => {
                      setFilters(f => ({ ...f, page: f.page - 1 }));
                    }}
                    disabled={filters.page < 2}
                  >
                    {t(TRANSLATIONS.PREVIOUS)}
                  </Button>
                  <div className="page">
                    <input
                      type="number"
                      defaultValue={filters.page}
                      onChange={e => {
                        const debounce = _.debounce(() => {
                          if (e.target.value
                              && Number(e.target.value) > 0
                              && !(Number(e.target.value) > totalPages)) {
                            setFilters(f => ({ ...f, page: Number(e.target.value) }));
                          }
                        }, 1500);
                        debounce();
                      }}
                    />
                    <div>{t(TRANSLATIONS.TOTAL_PAGES)}: <span>{totalPages}</span></div>
                  </div>
                  <Button
                    variant="contained"
                    onClick={() => {
                      setFilters(f => ({ ...f, page: f.page + 1 }));
                    }}
                    disabled={filters.page === totalPages}
                  >
                    {t(TRANSLATIONS.NEXT)}
                  </Button>
                </div>
              </>
            ) : (
              <EmptyState />
            )}
          </div>
        </>
      </div>
      {userState?.isUMDrawerVisible && <UserManagement getUserInfo={getUserInfo} />}
      {showAddUserDrawer && (
      <Drawer
        anchor="right"
        open={showAddUserDrawer}
        onClose={() => {
          setShowAddUserDrawer(false);
        }}
      >
        <div style={{
          padding: '72px 32px 32px 32px',
        }}
        >
          <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
            <h2>{t(TRANSLATIONS.NEW_USER)}</h2>
            <div style={{ borderRadius: '6px', textAlign: 'center', lineHeight: '40px', verticalAlign: 'center', height: '40px', width: '86px', alignItems: 'center', cursor: 'pointer', border: '1px solid black', borderColor: '#F0F0F0', backgroundColor: '#FFFFFF', fontSize: '12px', color: '#E92B36' }} role="button" onClick={onHandleClosingModal}>{displayForm ? t(TRANSLATIONS.GO_BACK) : t(TRANSLATIONS.CANCEL)}</div>
          </div>
          <span>{t(TRANSLATIONS.INVITE_USER_MAIL)}</span>
        </div>
        {
          createUserLoader && <LoadingSpinner text="Creando usuario..." style={{ marginTop: '83px' }} />
        }

        {(!createUserLoader)
          ? (
            <div style={{ padding: '32px' }}>

              <p style={{ opacity: 0.6 }}>{t(TRANSLATIONS.EMAIL)}</p>
              <InputWithIcon
                style={{
                  background: '#FFFFFF',
                  border: '1px solid #DEDEDE ',
                  height: '50px',
                  fontSize: '16px',
                }}
                onChange={onChangeEmail}
                value={email}
                placeholder="ejemplo@mail.com"
                icon={ImageLinks.espectro.mail}
              />
              {/* <>
                <p style={{ opacity: 0.6, marginTop: '25px' }}>Ubicación</p>
                <select
                  style={{
                    background: '#FFFFFF',
                    border: '1px solid #DEDEDE',
                    width: '100%',
                    padding: '8px',
                    height: '50px',
                    fontSize: '16px',
                  }}
                  onChange={event => {
                    const selectedLocation = availableLocationsForCompany
                      ?.getUserAvailableLocations.find(l => String(l?.id) === String(event.target.value));
                    // console.log({ selectedLocation, event: event.target.value, a: availableLocationsForCompany?.getUserAvailableLocations });
                    setUserDefaultLocationForInvite(selectedLocation);
                  }}
                  value={userDefaultLocationForInvite?.name || ''}
                >
                  <option style={{ display: 'none' }} selected> {userDefaultLocationForInvite?.name ?? t(TRANSLATIONS.SELECT_OPTION)} </option>

                  {availableLocationsForCompany?.getUserAvailableLocations?.map(option => (
                    <option
                      value={option?.id}
                      selected={String(userDefaultLocationForInvite?.id) === String(option?.id)}
                    >
                      {option?.name}
                    </option>
                  ))}
                </select>
              </> */}
              <>
                <p style={{ opacity: 0.6, marginTop: '25px' }}>{t(TRANSLATIONS.USER_TYPE)}</p>
                <select

                  style={{
                    background: '#FFFFFF',
                    border: '1px solid #DEDEDE ',
                    // color: '#ffffff',
                    width: '100%',
                    padding: '8px',
                    height: '50px',
                    fontSize: '16px',
                  }}
                  onChange={event => onChangeNewUserType(event)}
                  value={userType}
                >
                  <option style={{ display: 'none' }} selected> {t(TRANSLATIONS.SELECT_OPTION)} </option>

                  {dropDownItem.map(option => (
                    <option value={option.slug}>{getUserNameForDropdown(option.slug)}</option>
                  ))}
                </select>
                {
                  userType === 'ReporterOnlyDPO' ? t(TRANSLATIONS.LITE).split('\n').map(line => line.trim()).map(line => (<span style={{ fontSize: 12 }}>{line}<br /></span>)) : userType === 'SubzoneGeneralManagerDPO' ? t(TRANSLATIONS.BUSINESS).split('\n').map(line => line.trim()).map(line => (<span style={{ fontSize: 12 }}>{line}<br /></span>)) : null
                }
              </>
              <BasicButton
                style={{ marginTop: '25px', alignItems: 'center', width: '100%', height: '40px', fontWeight: 'normal', lineHeight: '40px', verticalAlign: 'center', paddingTop: 0, borderRadius: '4px' }}
                disabled={(!email)}
                onClick={onCreateUser}
                className="checklist-builder__upload_button"
                text={t(TRANSLATIONS.SEND_INVITATION)}
                background="#6C63FF"
              />
            </div>
          )
          : null}
      </Drawer>
      )}
      {showAddUserModal && (
        <AddUserModal
          closeModal={handleCloseAddUserModal}
          onAdd={(userInfo: any) => handleShowPinPopup(userInfo)}
        />
      )}
      {showPinPopup && (
        <UserRegisterSuccess
          userInfo={createdUserInfo}
          close={handleCloseShowPinPopup}
        />
      )}
    </>
  );
};

const UsersIndexN = (props: any) => {
  return (
    <UserManagementContextProvider>
      <UsersIndex {...{ ...props }} />
    </UserManagementContextProvider>
  );
};

export default UsersIndexN;
