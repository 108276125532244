import { isEqual, cloneDeep } from 'lodash';
import cloneDeepWith from 'lodash/cloneDeepWith';
import get from 'lodash/get';
import { ChecklistAnswers, ChecklistBit, ChecklistBitTypes, ChecklistItem, ChecklistProps, ChecklistSection, DateFormat, NestedChecklistAnswerMap, bitsShouldNotBeConsideredTowardsProgress, showIf, showIfAccesors, showIfOperators } from './types';

/**
 * Mapping of accesors. This returns the correct strarting element from the
 * answers for each accesor
 */
const accesorMapping = {
  $checklist: (answers: ChecklistAnswers) => answers,
  $items: (answers: ChecklistAnswers, section?: number) => answers[section!],
  $bits: (answers: ChecklistAnswers, section?: number, item?: number) => (
    answers[section!][item!]
  ),
  $previousBit: (answers: ChecklistAnswers, section?: number, item?: number, bit?: number) => (
    answers[section!][item!][bit! - 1]
  ),
  $previousItem: (answers: ChecklistAnswers, section?: number, item?: number, bit?: number) => (
    answers[section!][item! - 1]
  ),
};

export const shouldRenderItem = (
  showIf: showIf,
  answers: ChecklistAnswers,
  sectionIndex?: number,
  itemIndex?: number,
  bitIndex?: number,
  checklist?: ChecklistSection[],
) => {
  if (!showIf) return true;
  const { key, operator, value } = showIf;
  const accesor: showIfAccesors = key.match(/^[^.|[]*/)![0] as unknown as showIfAccesors;
  const indices = getSectionAndItemIndex(key);
  
  const searchItem = accesorMapping[accesor](answers, sectionIndex, itemIndex, bitIndex);
  const getter = key.slice(accesor.length, key.length).replace('.items', '').replace('.bits', '');

  const itemAnswer = getter ? get(searchItem, getter) : searchItem;
  
  if (operator === showIfOperators.equals) {
    if (checklist) {
      const typeOfQuestionWithCondition = checklist[indices.sectionIndex].items[indices.itemIndex].bits[0].type;
      const isQuestionOfTypeDate = typeOfQuestionWithCondition ? typeOfQuestionWithCondition === ChecklistBitTypes.DATE : false;
      const answeredItemDate = !!itemAnswer ? new Date(itemAnswer) : null;
      if (isQuestionOfTypeDate && answeredItemDate !== null) {
        const dayNumber = answeredItemDate.getDay();
        return dayNumber === value;
      }
    }
    if (Array.isArray(itemAnswer)) {
      return isEqual(itemAnswer, value);
    } return itemAnswer === value;
  }

  if (operator === showIfOperators.notEmpty) {
    return Boolean(Array.isArray(itemAnswer) ? itemAnswer
      .filter((answer: any) => !!answer).length : itemAnswer === 0 ? true : itemAnswer);
  }

  if (operator === showIfOperators.empty) {
    if(Array.isArray(itemAnswer)){
      return itemAnswer
      .filter((answer: any) => answer === "" || answer === null || answer === undefined).length === itemAnswer.length
    }
    return itemAnswer === "" || itemAnswer === null || itemAnswer === undefined
  }

  if (operator === showIfOperators.greaterThan) {
    return Number(itemAnswer) > Number(value);
  }

  if (operator === showIfOperators.lessThan && itemAnswer !== '') {
    return Number(itemAnswer) < Number(value);
  }
  return false;
};

export const shouldComponentRender = (
  answers: ChecklistAnswers,
  showIf?: showIf,
  sectionIndex?: number,
  itemIndex?: number,
  bitIndex?: number,
  checklist?: ChecklistSection[],
) => {
  if (!showIf) return true;

  if (showIf.values) {
    const valuesAnswers = showIf.values.map(s => (
      shouldRenderItem(s, answers, sectionIndex, itemIndex, bitIndex, checklist)
    ));
    if (showIf.operator === showIfOperators.and) {
      return valuesAnswers.filter(s => s).length === valuesAnswers.length;
    }
    if (showIf.operator === showIfOperators.or) {
      return !!valuesAnswers.filter(s => s).length;
    }
  }

  return shouldRenderItem(showIf, answers, sectionIndex, itemIndex, bitIndex, checklist);
};

export const getSectionAndItemIndex = (key: string) => {
  const regex = /\[(\d+)\]/g;
  const matches = key.match(regex) || [];
  const indexes: string[] = matches.map((match: string) => match.replace(/\D/g, ''));

  return {
    sectionIndex: Number(indexes[0]),
    itemIndex: Number(indexes[1]),
  };
};

const clockFormatQuestions = [ChecklistBitTypes.TIME, ChecklistBitTypes.DATE];

export const mergeChecklistWithAnswers = (checklist: ChecklistSection[], answers: ChecklistAnswers, nestedChecklistAnswersMap?: any, notesArray?: any, isPartialSave?: boolean, restrictAnswerUpdate?: boolean, updateNotes?: boolean, isWorkOrder?: boolean) => {
  const checklistCopy = cloneDeepWith(checklist);
  const { timeZone } = Intl.DateTimeFormat().resolvedOptions();

  if (checklistCopy && checklistCopy.length > 0) {
    checklistCopy.forEach((section, sectionIndex) => {
      section.items.forEach((item, itemIndex) => {
        if (!restrictAnswerUpdate) {
          item.bits.forEach((bit, bitIndex) => {
            if (isPartialSave && bit?.type === ChecklistBitTypes.NESTED_CHECKLIST) {
              bit.answer = answers?.[sectionIndex]?.[itemIndex];
            } else {
              bit.answer = answers?.[sectionIndex]?.[itemIndex]?.[bitIndex];
              if (clockFormatQuestions.includes(bit.type)) {
                bit.deviceTimezone = timeZone;
              }
              if (updateNotes) {
                if (bit?.additionalFeatures?.allowsNote) {
                  bit.additionalFeatures.note = notesArray?.[sectionIndex]?.[itemIndex]
                }
              }
            }
          });
        }
      });
    });
  }

  if (nestedChecklistAnswersMap) {
    let nestedRepetableAnswers: any = {};
    Object.keys(nestedChecklistAnswersMap).forEach(key => {
      const { sectionIndex, itemIndex } = getSectionAndItemIndex(key);
      const ncAnswers = isWorkOrder ? nestedChecklistAnswersMap[key]?.answers : nestedChecklistAnswersMap[key];
      const ncChecklist = isWorkOrder ? nestedChecklistAnswersMap[key]?.checklist : checklist[sectionIndex as number].items[itemIndex as number].bits[0].props.content!.checklist as ChecklistSection[];
      const mergedAnswers = mergeChecklistWithAnswers(ncChecklist, ncAnswers, undefined, notesArray?.[key], false, false, true);
      if (isPartialSave) {
        nestedRepetableAnswers = {
          ...nestedRepetableAnswers,
          [sectionIndex]: {
            ...nestedRepetableAnswers?.[sectionIndex],
            [itemIndex]: [...nestedRepetableAnswers?.[sectionIndex]?.[itemIndex] ?? [], mergedAnswers]
          }
        }
        checklistCopy[sectionIndex].items[itemIndex].bits[0].answer = nestedRepetableAnswers?.[sectionIndex]?.[itemIndex];
      } else {
        if (checklistCopy[sectionIndex].items[itemIndex].bits[0].answer) {
          checklistCopy[sectionIndex].items[itemIndex].bits[0].answer = [...checklistCopy[sectionIndex].items[itemIndex].bits[0].answer, mergedAnswers];
        } else {
          checklistCopy[sectionIndex].items[itemIndex].bits[0].answer = [mergedAnswers];
        }
        if (notesArray && notesArray?.length > 0) {
          checklistCopy[sectionIndex].items[itemIndex].bits[0].answer.forEach((ans: any, ansI: number) => {
            let countNotes = 0;
            ans.forEach((a: any, aIndex: number) => {
              a.items.forEach((item: ChecklistItem, i: number) => {
                item.bits.forEach(bit => {
                  if (bit.additionalFeatures?.allowsNote) {
                    if (notesArray[ansI]) {
                      bit.additionalFeatures.note = notesArray[ansI][countNotes];
                    }
                  }
                });
                countNotes += 1;
              });
            });
          });
        }
      }
    });
  }
  return checklistCopy;
};

export const getDate = (dateFormat: DateFormat) => {
  const year = dateFormat.year !== undefined ? dateFormat.year : new Date().getFullYear();
  const month = dateFormat.month !== undefined ? dateFormat.month : new Date().getMonth();
  const day = dateFormat.day !== undefined ? dateFormat.day : new Date().getDate();
  return new Date(year, month, day);
};
export const getMinDate = (dateFormat: DateFormat) => {
  const year = dateFormat.year !== undefined ? new Date().getFullYear() - dateFormat.year : new Date().getFullYear();
  const month = dateFormat.month !== undefined ? new Date().getMonth() - dateFormat.month : new Date().getMonth();
  const day = dateFormat.day !== undefined ? new Date().getDate() - dateFormat.day : new Date().getDate();
  return new Date(year, month, day);
};
export const getMaxDate = (dateFormat: DateFormat) => {
  const year = dateFormat.year !== undefined ? new Date().getFullYear() + dateFormat.year : new Date().getFullYear();
  const month = dateFormat.month !== undefined ? new Date().getMonth() + dateFormat.month : new Date().getMonth();
  const day = dateFormat.day !== undefined ? new Date().getDate() + dateFormat.day : new Date().getDate();
  return new Date(year, month, day);
};
export const getAnswersAndAnswerableQuestions = (checklist: ChecklistSection[], answers: ChecklistAnswers) => {
  const checklistCopy = cloneDeepWith(checklist);
  const answerableBits = [ChecklistBitTypes.INPUT, ChecklistBitTypes.TEXT, ChecklistBitTypes.CHECKLIST, ChecklistBitTypes.VERTICAL_SINGLE_OPTION, ChecklistBitTypes.VERTICAL_MULTIPLE_OPTION, ChecklistBitTypes.HORIZONTAL_SINGLE_OPTION, ChecklistBitTypes.HORIZONTAL_CHECKLIST, ChecklistBitTypes.COUNTER, ChecklistBitTypes.DATE, ChecklistBitTypes.DROPDOWN];
  if (answers) {
    checklistCopy.forEach((section, sectionIndex) => {
      section.items.forEach((item, itemIndex) => {
        item.bits.forEach((bit, bitIndex) => {
          bit.answer = answers[sectionIndex][itemIndex][bitIndex];
          bit.shouldBitProgressConsidered = answerableBits.includes(bit.type);
          if (!ChecklistBitTypes[bit.type]) {
            bit.shouldBitProgressConsidered = true;
          }
          if (bit.props.isNAMaster) {
            bit.shouldBitProgressConsidered = false;
          }
          if (item.showIf) {
            if (!shouldRenderItem(item.showIf, answers, sectionIndex, itemIndex, bitIndex, checklistCopy)) {
              bit.isRendered = false;
            } else {
              bit.isRendered = shouldComponentRender(answers, bit.showIf, sectionIndex, itemIndex, bitIndex, checklistCopy);
            }
          } else {
            bit.isRendered = shouldComponentRender(answers, bit.showIf, sectionIndex, itemIndex, bitIndex, checklistCopy);
          }
        });
      });
    });
  }
  return checklistCopy;
};

export const RenderBitInitializer = (
  bit: ChecklistBit, customBitsInitializer: ChecklistProps['customBitsInitializer'] = () => { },
) => {
  const customInitializer = customBitsInitializer(bit.type, bit);
  if (customInitializer !== undefined) return customInitializer;
  if (bit.defaultAnswer !== undefined) return bit.defaultAnswer;
  if (bit.type === ChecklistBitTypes.INPUT) return '';
  if (bit.type === ChecklistBitTypes.TEXT) return '';
  if (bit.type === ChecklistBitTypes.CHECKLIST) return null;
  if (bit.type === ChecklistBitTypes.VERTICAL_SINGLE_OPTION) return null;
  if (bit.type === ChecklistBitTypes.HORIZONTAL_SINGLE_OPTION) return null;
  if (bit.type === ChecklistBitTypes.HORIZONTAL_CHECKLIST) return null;
  if (bit.type === ChecklistBitTypes.COUNTER) return 0;
  if (bit.type === ChecklistBitTypes.DATE) return new Date();
  if (bit.type === ChecklistBitTypes.DROPDOWN) return null;
  if (bit.type === ChecklistBitTypes.ACK) return undefined;
  return '';
};

export const shouldBitProgressConsidered = (
  bit: ChecklistBit, answer: any,
) => {
  if (bit.type === ChecklistBitTypes.INPUT) return !!answer;
  if (bit.type === ChecklistBitTypes.TEXT) return !!answer;
  if (bit.type === ChecklistBitTypes.CHECKLIST) return answer ? answer.some((element: boolean) => element === true) : false;
  if (bit.type === ChecklistBitTypes.VERTICAL_SINGLE_OPTION) return (answer || answer === 0);
  if (bit.type === ChecklistBitTypes.HORIZONTAL_SINGLE_OPTION) return (answer || answer === 0);
  if (bit.type === ChecklistBitTypes.HORIZONTAL_CHECKLIST) return answer ? answer.some((element: boolean) => element === true) : false;
  if (bit.type === ChecklistBitTypes.COUNTER) return (answer || answer !== 0);
  if (bit.type === ChecklistBitTypes.DATE) return !!answer;
  if (bit.type === ChecklistBitTypes.DROPDOWN) return (answer || answer === 0);
  if (bit.type === ChecklistBitTypes.VERTICAL_MULTIPLE_OPTION) return (answer ? answer?.length > 0 : false);
  if (bit.type === ChecklistBitTypes.LIST_ITEM) return (answer ? Object.keys(answer || {}).length > 0 : false);
  return false;
};

export const getPercentageCompletedBasedOnSection = (
  section: number,
  returnType: string = '',
  answers: any[][],
  checklist: ChecklistSection[],
  customBitProgressOverride?: {
    bitType: string;
    shouldProgressConsidered: (bit: ChecklistBit) => boolean;
  }[],
  considerNestedChecklistProgress?: boolean,
  selectedNestedChecklistAnswers?: any,
): number => {
  let totalScore = 0;
  let bitsContributingTowardsProgress = 0;
  checklist[section].items.forEach((item, itemIndex) => {
    item.bits.forEach((bit, bitIndex) => {
      const bitAnswer = answers[section][itemIndex][bitIndex];
      let shouldRenderItemVal = true;
      if (item.showIf) {
        if ((item?.showIf?.operator === showIfOperators.and
          || item?.showIf?.operator  === showIfOperators.or)
          && item?.showIf?.values?.length as any > 0
        ) {
          const { values } = item?.showIf;
          let flagV1 = 0;
          values?.forEach?.((val) => {
            const { key: valueKeyV1 } = val;
            const { sectionIndex: sIndexV1, itemIndex: iIndexV1 } = getSectionAndItemIndex(valueKeyV1);
    
            const isAnswered = shouldRenderItem(
              val,
              answers,
              sIndexV1,
              iIndexV1,
              bitIndex,
              checklist,
            );
            if (isAnswered) {
              flagV1 += 1;
            }
          });
          if ((
            item?.showIf?.operator === showIfOperators.and
            && flagV1 === values?.length
          ) || (
            item?.showIf?.operator === showIfOperators.or
            && flagV1 > 0
          )) {
            shouldRenderItemVal = true;
          } else {
            shouldRenderItemVal = false;
          }
        } else {
          shouldRenderItemVal = shouldRenderItem(
            item.showIf,
            answers,
            section,
            itemIndex,
            bitIndex,
            checklist,
          );
        }
      }
      const shouldComponentsRenderVal = shouldComponentRender(
        answers,
        bit.showIf,
        section,
        itemIndex,
        bitIndex,
        checklist,
      );
      if (
        bit.countsTowardProgress !== false
        && !bit.props.isNAMaster
        && shouldRenderItemVal
        && shouldComponentsRenderVal
        && !bitsShouldNotBeConsideredTowardsProgress.includes(bit.type)
      ) {
        bitsContributingTowardsProgress += 1;
      }
      if (customBitProgressOverride?.length) {
        if (considerNestedChecklistProgress && bit.type === ChecklistBitTypes.NESTED_CHECKLIST) {
          const nestedAnswers = selectedNestedChecklistAnswers?.[section]?.[itemIndex];
          let isCompleted = Object.values(nestedAnswers ?? {})
            ?.some?.((arr: any) =>
              arr
                ?.flat?.(3)
                ?.every?.((item: any) => item !== null && item !== '')
          );
          if (isCompleted) {
            totalScore += 1;
          }
        }
        
        let customBitArr = cloneDeep(customBitProgressOverride);
        
        if (considerNestedChecklistProgress) {
          customBitArr = customBitProgressOverride?.filter?.(i => i?.bitType !== ChecklistBitTypes.NESTED_CHECKLIST);
        }
        customBitArr?.forEach?.((customBit: any) => {
          if (
            bit?.type === customBit?.bitType 
            && customBit?.shouldProgressConsidered?.({ ...bit, answer: bitAnswer })
          ) {
            totalScore += 1;
          }
        });
      }
      if (
        (bit.countsTowardProgress ?? true) &&
          !bit.props.isNAMaster &&
          shouldBitProgressConsidered(bit, bitAnswer) &&
          shouldComponentsRenderVal
      ) {
        totalScore += 1;
      }
    });
  });
  if (returnType === 'total') {
    return bitsContributingTowardsProgress;
  } if (returnType === 'answered') {
    return totalScore;
  }
  return bitsContributingTowardsProgress === 0 ? 0 : Math.floor((totalScore / bitsContributingTowardsProgress) * 100);
};
