import React from 'react';
import { useTranslation } from 'react-i18next';

import disclaimerIcon from 'assets/icons/disclaimer.svg';

import './Disclaimer.scss';

const Disclaimer = ({ message } : {message: string}) => {
  const { t } = useTranslation();
  return (
    <div className="disclaimer">
      <img src={disclaimerIcon} alt="disclaimer" />
      <p>
        {t(message)}
      </p>
    </div>
  );
};

export default Disclaimer;
