import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Redirect, Route, withRouter } from 'react-router-dom';
import i18next from 'i18next';
import { UserTypes } from 'types/enums';
import { isUserBreweryPlantManager, userCanReportSIOs, userHasWebAccess, userOnlyHasAccessToReportSIOs, userOnlyHasAccessToSearchUserScreen } from 'utils/canUserAccessFeature';

class AuthWrapper extends Component<any, any> {
  render() {
    const lang = i18next.language; // es or en
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { user, isAuthenticating, path, userType, location, userDetails } = this.props;
    const currentPath = location.pathname;
    if (currentPath === '/logout' && userOnlyHasAccessToSearchUserScreen(userType)) {
      window.location.replace('/logout');
    }

    // still authenticating
    if (isAuthenticating) {
      return <p style={{ textAlign: 'center', marginTop: '20px' }}>{lang === 'es' ? 'Cargando' : 'Loading'}...</p>;
    }

    if (userType === 'BackendEdit') {
      if (!currentPath.includes('backend-edit')) {
        return <Redirect to="/backend-edit" />;
      }
    }
    if (userType === 'BreweryViewer' || userType === 'BreweryPlanner' || userType === 'BreweryViewerManager' || isUserBreweryPlantManager(userDetails?.getIn(['roleDetails', 'slug']), userType)) {
      if (window.innerWidth < 500 && !currentPath.includes('mobile/daily-routine')) {
        return <Redirect to="/mobile/daily-routine" />;
      }
      if (window.innerWidth >= 500
        && !currentPath.includes('analytics/production-planning')
        && !currentPath.includes('upload/daily-production-plannings')
        && !currentPath.includes('upload/daily-routine')
      ) {
        return <Redirect to="/analytics/production-planning" />;
      }
    }

    if (
      userType === UserTypes.LocationSecurityDoctor
      || userType === UserTypes.LocationSecurity
      || userType === UserTypes.LocationManager
    ) {
      if (!currentPath.includes('all-users')) {
        return <Redirect to="/all-users" />;
      }
    }
    if (currentPath.includes('/analytics') && userHasWebAccess(userDetails.getIn(['roleDetails', 'slug']), userDetails.get('features')) === false && userCanReportSIOs(userDetails.getIn(['roleDetails', 'slug']), userDetails.get('features'))) {
      return <Redirect to="/ac" />;
    }

    if (userOnlyHasAccessToReportSIOs(userDetails.getIn(['roleDetails', 'slug']), userDetails.get('features'), userType) && !(userType === 'BreweryViewer' || userType === 'BreweryPlanner' || userType === 'BreweryViewerManager' || isUserBreweryPlantManager(userDetails?.getIn(['roleDetails', 'slug']), userType))) {
      if (!currentPath.includes('/action-log') && !currentPath.includes('ac')) {
        return <Redirect to="/ac" />;
      }
    }

    if ((!currentPath.includes('/action-log') && currentPath.includes('/ac')) || currentPath.includes('/login')) {
      if (!userHasWebAccess(userDetails.getIn(['roleDetails', 'slug']), userDetails.get('features')) && !userOnlyHasAccessToReportSIOs(userDetails.getIn(['roleDetails', 'slug']), userDetails.get('features'), userType) && !userCanReportSIOs(userDetails.getIn(['roleDetails', 'slug']), userDetails.get('features'))) {
        return <Redirect to="/" />;
      }
    }

    if (currentPath.includes('/action-log')) {
      if (userType !== UserTypes.SubzoneGeneralManagerDPO
        && userType !== UserTypes.DistributionCenterManagerDPO
        && userType !== UserTypes.EspectroViewOnly
        && userType !== UserTypes.CorporateDPO) {
        return <Redirect to="/" />;
      }
    }

    // After redirecting to suitable AuthWrapper, TAKE THE USER TO THE ROUTE:
    if (user) return <Route path={path} component={this.props.component} />;

    // in case of no user
    return (<Redirect to="/login" />);
  }
}

function mapStateToProps(state: any) {
  return {
    isAuthenticating: state.getIn(['user', 'isAuthenticating']),
    user: !!state.getIn(['user', 'email']),
    userType: state.getIn(['user', 'type']),
    userDetails: state.get('user'),
    // userType: state.user,
    // userDistCenters: selectUserDistributionCenters(state),
  };
}

// @ts-ignore
export default connect(mapStateToProps)(withRouter(AuthWrapper));
