import React from 'react';
import './Table.scss';
import { useTable } from 'react-table';
import ErrorMessage from 'components/ErrorMessage';
import EmptyState from 'components/EmptyState/EmptyState';
import LoadingSpinner from 'components/LoadingSpinner';
import ImageLinks from 'utils/ImageLinks';

type Props = {
  error: boolean;
  loading: boolean;
  data: any;
  columns: any;
  onClickReload?: any;
};

const Table = ({ error, loading, data, columns, onClickReload }: Props) => {
  const tableInstance = useTable({ columns, data });
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
  } = tableInstance;

  if (error) return <ErrorMessage onClickReload={onClickReload} errorImage={ImageLinks.errorImage} buttonText="Recargar página" />;
  if (loading) return <LoadingSpinner height={350} />;
  if (data.length === 0 || columns.length === 0) return <EmptyState />;

  return (
    <table className="table-view" {...getTableProps()}>
      <thead className="text-view">
        {
          headerGroups.map(headerGroup => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {
                headerGroup.headers.map(column => (
                  <th {...column.getHeaderProps()} className="border-view">
                    {
                      column.render('Header')
                    }
                  </th>
                ))
              }
            </tr>
          ))
        }
      </thead>
      <tbody {...getTableBodyProps()}>
        {
          rows.map(row => {
            prepareRow(row);
            return (
              <tr
                className="text-view"
                {...row.getRowProps()}
              >
                {
                  row.cells.map(cell => {
                    return (
                      <td {...cell.getCellProps()} className="border-view">
                        {
                          cell.render('Cell')
                        }
                      </td>
                    );
                  })
                }
              </tr>
            );
          })
        }
      </tbody>
    </table>
  );
};

export default Table;
