import * as React from 'react';
import { CircularProgress } from '@material-ui/core';
import { useUserHealthStatusUnAuthenticatedLazyQuery } from 'generated/graphql';
import { compareAsc } from 'date-fns';
import ConcludeStatus from './DifferentPages/ConcludeStatus';
import UpdateStatus from './DifferentPages/UpdateStatus';
import UpdateStatusResult from './DifferentPages/UpdateStatusResult';

import './PatientStatusExternalLinkStyle.scss';

function PatientStatusExternalLink(props: any) {
  const { userSlug: slug, userName } = props.match.params;
  const [getHealthDetails, healthDetails] = useUserHealthStatusUnAuthenticatedLazyQuery();
  const [showStep, setShowStep] = React.useState<number>(0);
  const [location, setLocation] = React.useState<string>('');
  const [status, setStatus] = React.useState<string>('');

  function changeStep(step: number) {
    setShowStep(step);
    if (healthDetails.refetch && step === 1) {
      healthDetails.refetch();
    }
  }

  React.useEffect(() => {
    getHealthDetails({
      variables: {
        slug,
      },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  React.useEffect(() => {
    if (
      healthDetails.data?.userHealthStatusUnAuthenticated.quarantineUntil
      && compareAsc(
        new Date(),
        new Date(healthDetails.data?.userHealthStatusUnAuthenticated.quarantineUntil),
      ) === 1
    ) {
      setShowStep(2);
    }
    setStatus(healthDetails.data?.userHealthStatusUnAuthenticated.healthStatus?.status as string);
    setLocation(
      healthDetails
        .data?.userHealthStatusUnAuthenticated.healthStatus?.location as string,
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [healthDetails?.data]);

  return !healthDetails?.loading && healthDetails?.data ? (
    <section className="update_status_container">
      <div className="update_status_sub_container">
        {
          [
            <UpdateStatus
              slug={slug}
              userName={userName}
              location={location}
              setLocation={setLocation}
              status={status}
              setStatus={setStatus}
              healthStatus={healthDetails.data?.userHealthStatusUnAuthenticated.healthStatus}
              changeStep={changeStep}
            />,
            <UpdateStatusResult
              changeStep={changeStep}
              status={status}
              location={location}
              healthStatus={healthDetails.data?.userHealthStatusUnAuthenticated.healthStatus}
            />,
            <ConcludeStatus />,
          ][showStep]
        }
      </div>
    </section>
  ) : (
    <div className="center_loader">
      <CircularProgress />
    </div>
  );
}

export default PatientStatusExternalLink;
