import { TFunction } from 'i18next';
import { PLACE_OF_QUARANTINE, TRANSLATIONS } from 'types/enums';
import ImageLinks from 'utils/ImageLinks';

// eslint-disable-next-line import/prefer-default-export
export function getStatusOptions(t:TFunction) {
  return ([
    { name: t(TRANSLATIONS.AT_HOME), image: ImageLinks.home, label: PLACE_OF_QUARANTINE.HOME },
    {
      name: t(TRANSLATIONS.HOSPITAL),
      image: ImageLinks.hospital,
      label: PLACE_OF_QUARANTINE.HOSPITAL,
    },
  ]);
}
