import React, { CSSProperties, FC, HTMLAttributes } from 'react';
import './AnonymousFormDropdownWithLabel.scss';
// eslint-disable-next-line import/no-unresolved
import { SelectDropdownProps } from 'components/Dropdown/SelectDropdown/SelectDropdown';
import AnonymousDropDown from '../AnonymousDropDown';

const AnonymousFormDropdownWithLabel: FC<Props> = (props: Props) => {
  const { label, ...rest } = props;
  return (
    <AnonymousDropDown
      {...rest}
      labelComponent={<p className="text-light anonymous-form-dropdown-label">{label}</p>}
    />
  );
};

interface Props extends SelectDropdownProps {
  label: string;
  mainContainerClassName?: HTMLAttributes<HTMLDivElement>['className'];
  mainContainerStyle?: CSSProperties;
}

export default AnonymousFormDropdownWithLabel;
