import React, { useEffect } from 'react';
import EmptyState from 'components/EmptyState/EmptyState';
import LoadingSpinner from 'components/LoadingSpinner';
import { FilterParams, GetSifStatsBy, useGetSifStatsQuery } from 'generated/graphql';
import Chart from 'react-apexcharts';
import { TypeLabelSlugArray } from 'types/types';

type Props = {
  barGraphData?: any[];
  lineGraphData?: any[];
  xCategories?: any[];
  titlePosition?: string;
  lineGraphTitle?: string;
  barGraphTitle?: string;
  barGraphColor?: string;
  lineGraphColor?: string;
  xLabel?: string;
  yLabel?: string;
  yOppositeLabel?: string;
  by: GetSifStatsBy,
  title: string
  filter: FilterParams
  year: number
  setCausesAndNatureFilter: React.Dispatch<React.SetStateAction<{
    cause: TypeLabelSlugArray;
    nature: TypeLabelSlugArray;
  }>>
  causesAndNatureFilter: {
    cause: TypeLabelSlugArray;
    nature: TypeLabelSlugArray;
  }
};

function LineAndColumnChart(props: Props) {
  const { by, title, filter, year, setCausesAndNatureFilter, causesAndNatureFilter } = props;
  const { data: chartData, loading, error } = useGetSifStatsQuery({
    variables: {
      filter,
      by,
      year,
    },
  });

  const handleFilter = (type: GetSifStatsBy.Nature | GetSifStatsBy.Cause) => {
    const nature: TypeLabelSlugArray = [];
    const cause: TypeLabelSlugArray = [];
    chartData?.getSifStats.forEach(chartDataItem => {
      if (chartDataItem?.name) {
        if (type === GetSifStatsBy.Nature) {
          nature.push({ label: chartDataItem?.name, slug: chartDataItem?.name });
          setCausesAndNatureFilter({ cause: causesAndNatureFilter.cause, nature });
        }
        if (type === GetSifStatsBy.Cause) {
          cause.push({ label: chartDataItem?.name, slug: chartDataItem?.name });
          setCausesAndNatureFilter({ cause, nature: causesAndNatureFilter.nature });
        }
      }
    });
  };

  useEffect(() => {
    if (by === GetSifStatsBy.Nature) {
      if (causesAndNatureFilter.nature.length === 0) {
        handleFilter(GetSifStatsBy.Nature);
      }
    }
    if (by === GetSifStatsBy.Cause) {
      if (causesAndNatureFilter.cause.length === 0) {
        handleFilter(GetSifStatsBy.Cause);
      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [chartData, causesAndNatureFilter]);

  const categories: string[] = [];
  const values: number[] = [];
  const paretoSerie = [];
  const data: any[] = [];

  if (chartData?.getSifStats) {
    chartData.getSifStats.forEach(chartDataItem => {
      data.push(chartDataItem);
    });
    data.sort((itemFirst, itemSecond) => {
      const keyA = itemFirst?.totalSif || 0;
      const keyB = itemSecond?.totalSif || 0;
      if (keyA < keyB) return 1;
      if (keyA > keyB) return -1;
      return 0;
    });
    data.forEach(chartDataItem => {
      if (chartDataItem?.name) {
        categories.push(chartDataItem?.name);
        values.push(chartDataItem?.totalSif);
      }
    });
  }

  let totalSerie = 0;
  for (let i = 0; i < values.length; i++) {
    totalSerie += values[i];
  }
  for (let i = 0; i < values.length; i++) {
    if (i === 0) {
      paretoSerie.push(parseFloat(((values[0] / totalSerie) * 100).toFixed(2)));
    } else {
      const newPercent: number = ((values[i] / totalSerie) * 100) + paretoSerie[i - 1];
      paretoSerie.push(parseFloat(newPercent.toFixed(2)));
    }
  }
  const {
    lineGraphTitle,
    barGraphTitle,
    barGraphColor,
    lineGraphColor,
    xLabel,
    yLabel,
    yOppositeLabel,
  } = props;
  const series = [{
    name: barGraphTitle,
    type: 'column',
    data: values,
  }, {
    name: lineGraphTitle,
    type: 'line',
    data: paretoSerie,
  }];

  const options = {
    chart: {
      height: 350,
      type: 'line',
      stacked: false,
      toolbar: {
        show: false,
      },
      zoom: {
        enabled: true,
      },
    },
    colors: [barGraphColor || '#5572B9', lineGraphColor || '#2574FB'],
    stroke: {
      width: [0, 2],
    },
    dataLabels: {
      enabled: false,
      enabledOnSeries: [1],
    },
    labels: categories,
    xaxis: {
      title: {
        text: xLabel,
      },
      labels: {
        show: true,
        rotate: -50,
        rotateAlways: true,
        hideOverlappingLabels: false,
        showDuplicates: true,
        trim: true,
        style: {
          fontSize: '11px',
        },
        formatter: (val: string) => {
          if (val) {
            if (val?.length > 10) return `${val?.match(/.{1,10}/g)}`;
            return val;
          }
          return val;
        },
      },
    },
    yaxis: [{
      title: {
        text: yLabel || 'Clasificación',
      },

    }, {
      opposite: true,
      title: {
        text: yOppositeLabel || 'Acumulado',
      },
    }],
    legend: {
      show: false,
    },
  };
  if (error) return <h5> Something Went Wrong. Please Try Again  </h5>;
  if (loading) return <LoadingSpinner height={200} />;
  if (chartData?.getSifStats.length === 0) return <EmptyState />;
  return (
    <>
      <h4> {title} </h4>
      <Chart height={350} series={series} options={options} />
    </>
  );
}

export default LineAndColumnChart;
