import React, { useEffect, useMemo, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { fromJS } from 'immutable';
import { useTranslation } from 'react-i18next';

// eslint-disable-next-line @typescript-eslint/camelcase
import { Location_Type, useLocationDetailsQuery } from 'generated/graphql';
import ImageLinks from 'utils/ImageLinks';
import Modal from '../../../components/Modal';
import { COLOR_CODE_NEW, TRANSLATIONS, UserTypes } from '../../../types/enums';
import LabelledInputWithImage from '../../../components/Input/LabelledInputWithImage/LabelledInputWithImage';
import CheckBoxWithIcon from '../../../components/CheckBoxWithIcon';
import BasicButton from '../../../components/Button/BasicButton';
import request from '../../../utils/request';
import LoadingSpinner from '../../../components/LoadingSpinner';
import './UserInfo.scss';
import RoutinesSelector from './routinesSelector';

const { userIcon, positionIcon, emptyCheck, checkRedSelected, emailIcon } = ImageLinks;
type props = {
  config: any,
  setUserInfoPopupConfig: (config: any)=>void,
  setShowPinPopup: (config: any)=>void,
  updateData: any,
  reloadUsers: () =>void,
  hasManagmentAccess:boolean,
};

const UserInfo = (props: props) => {
  const { t } = useTranslation();
  const history = useHistory();

  const [invalidFields, setInvalidFields] = useState([] as string[]);
  const { config, setUserInfoPopupConfig, setShowPinPopup } = props;
  const { userData, action, formTitle, distributionCenterSlug, successBtnText } = config;
  const pathName = history.location.pathname.replace('/distribution-centers', '');
  const cdSlug = pathName.replace('/users', '');

  const location = useLocationDetailsQuery({
    variables: {
      locationSlug: distributionCenterSlug,
    },
  });

  const [loading, setLoading] = useState(true);
  const [positionsForBu, setPositionsForBu] = useState([]);
  const [selectedPosition, setSelectedPosition] = useState();
  const [selectedPermisson, setSelectedPermisson] = useState(2);
  const [formData, setFormData] = useState(fromJS({
    firstName: '',
    middleName: '',
    lastName: '',
    picture: '',
    email: '',
    position: '',
    type: '',
    permissions: {},
  }));

  const isDistributionCenter = !location.loading
    && location.data?.locationDetails.type
    !== Location_Type.Factory;

  const routinesPermissions = isDistributionCenter && formData?.get('permissions')?.get('routines')?.toJS();

  const permissionsOptions = useMemo(() => {
    if (isDistributionCenter) {
      return [
        {
          id: 1,
          label: `${t(TRANSLATIONS.CERTIFICATES)}`,
          type: UserTypes.CertificatesOnly,
        },
        {
          id: 2,
          label: `${t(TRANSLATIONS.CERTIFICATES)}, ${t(TRANSLATIONS.REPORT)} ${t(TRANSLATIONS.INCIDENTS)}, ${t(TRANSLATIONS.ACTION_LOGS)}`,
          type: UserTypes.ReporterOnlyDPO,
        },
        {
          id: 3,
          label: `${t(TRANSLATIONS.CERTIFICATES)}, ${t(TRANSLATIONS.REPORT)} ${t(TRANSLATIONS.INCIDENTS)}, ${t(TRANSLATIONS.ACTION_LOGS)}, ${t(TRANSLATIONS.WEB_ACCESS)}`,
          type: UserTypes.CorporateDPO,
        },
        {
          id: 4,
          label: `${t(TRANSLATIONS.CERTIFICATES)}, ${t(TRANSLATIONS.REPORT)}, ${t(TRANSLATIONS.CLOSE)} ${t(TRANSLATIONS.INCIDENTS)}, ${t(TRANSLATIONS.ACTION_LOGS)}, ${t(TRANSLATIONS.WEB_ACCESS)}`,
          type: UserTypes.DistributionCenterManagerDPO,
        },
        {
          id: 5,
          label: `${t(TRANSLATIONS.CERTIFICATES)}, ${t(TRANSLATIONS.REPORT)}, ${t(TRANSLATIONS.CLOSE)}, ${t(TRANSLATIONS.ASSIGN)} ${t(TRANSLATIONS.INCIDENTS)}, ${t(TRANSLATIONS.ACTION_LOGS)}, ${t(TRANSLATIONS.WEB_ACCESS)}`,
          type: UserTypes.SubzoneGeneralManagerDPO,
        },
      ];
    }
    return [
      {
        id: 1,
        label: `${t(TRANSLATIONS.CERTIFICATES)}`,
        type: UserTypes.CertificatesOnly,
      },
      {
        id: 2,
        label: `${t(TRANSLATIONS.CERTIFICATES)}, ${t(TRANSLATIONS.REPORT)} ${t(TRANSLATIONS.INCIDENTS)}, ${t(TRANSLATIONS.AMBIENTAL_RISKS)}`,
        type: UserTypes.ReporterOnlySupply,
      },
      {
        id: 3,
        label: `${t(TRANSLATIONS.CERTIFICATES)}, ${t(TRANSLATIONS.REPORT)} ${t(TRANSLATIONS.INCIDENTS)}, ${t(TRANSLATIONS.AMBIENTAL_RISKS)}, ${t(TRANSLATIONS.WEB_ACCESS)}`,
        type: UserTypes.CorporateSupply,
      },
      {
        id: 4,
        label: `${t(TRANSLATIONS.CERTIFICATES)}, ${t(TRANSLATIONS.REPORT)}, ${t(TRANSLATIONS.CLOSE)} ${t(TRANSLATIONS.INCIDENTS)}, ${t(TRANSLATIONS.AMBIENTAL_RISKS)}, ${t(TRANSLATIONS.WEB_ACCESS)}`,
        type: UserTypes.DistributionCenterManagerSupply,
      },
      {
        id: 5,
        label: `${t(TRANSLATIONS.CERTIFICATES)}, ${t(TRANSLATIONS.REPORT)}, ${t(TRANSLATIONS.CLOSE)}, ${t(TRANSLATIONS.ASSIGN)} ${t(TRANSLATIONS.INCIDENTS)}, ${t(TRANSLATIONS.AMBIENTAL_RISKS)}, ${t(TRANSLATIONS.WEB_ACCESS)}`,
        type: UserTypes.SubzoneGeneralManagerSupply,
      },
      {
        id: 6,
        label: t(TRANSLATIONS.PRODUCTION_PERFORMANCE_TRACKING),
        type: UserTypes.BreweryViewer,
      },
    ];
  }, [isDistributionCenter, t]);

  useEffect(() => {
    const getPositionsByBu = async () => {
      try {
        const response = await request.get(`/api/v1/positions-by-bu?cdSlug=${cdSlug.replace('/', '')}`);
        const positions = response?.positions?.map(({ position }: {position: string}) => (
          { text: position, value: position })
        );
        setPositionsForBu(positions);
      } catch (err) {
        console.error(err);
      }
    };

    getPositionsByBu();
  }, [cdSlug]);

  const getPermissionString = (permissions: any) => {
    return permissions.reduce(
      (acc: string, perm: any) => {
        if (perm.get('children')) {
          return acc + (acc.length ? ', ' : '') + (
            perm.get('children').filter((childPerm: any) => childPerm.get('granted')).map(
              (childPerm: any) => `${childPerm.get('name')}, `,
            ).join('')
          );
        }
        if (perm.get('granted')) {
          return acc + (acc.length ? ', ' : '') + perm.get('name');
        }
        return acc;
      }, '',
    );
  };

  const loadInitialData = () => {
    const runFn = async () => {
      if (userData && action === 'edit-user') {
        if (userData.slug && userData.slug.length > 2) {
          try {
            let tempObj = {};
            const userRequest = request.get(`/api/v1/users/${userData.slug}/info`).then(
              res => {
                const obj = {
                  firstName: res.info.name,
                  position: res.info.position || '',
                  picture: res.info.picture || '',
                  email: res.info.email || '',
                  showEmail: (res.info.email && res.info.email.length > 3),
                };
                tempObj = { ...tempObj, ...obj };
                setSelectedPosition(res.info.position);
                const typeFromApi = permissionsOptions.find(({ type }) => res.info.type === type);
                setSelectedPermisson(typeFromApi?.id || 2);
              },

            );

            const permissionRequest = request.get(`/api/v1/users/${userData.slug}/permissions`).then(
              res => {
                const routinesGroup = {} as any;
                res.permissions.forEach((routine: any) => {
                  if (routinesGroup[routine.pillar]) {
                    routinesGroup[routine.pillar].routines = (
                      routinesGroup[routine.pillar].routines.concat(routine)
                    );
                  } else {
                    routinesGroup[routine.pillar] = {
                      routines: [].concat(routine),
                      selected: true,
                    };
                  }
                });
                tempObj = {
                  ...tempObj,
                  permissions: {
                    routines: {
                      ...routinesGroup,
                    },
                  },
                };
              },
            );
            await Promise.all([userRequest, permissionRequest]);
            setFormData(formData.merge(fromJS(tempObj)));

            setLoading(false);
          } catch (e) {
            setLoading(false);
            console.error(e);
          }
        }
      }

      if (action === 'add-user') {
        request.get('/api/v1/routines/permissions').then(
          res => {
            const routinesGroup = {} as any;
            res.permissions.forEach((routine: any) => {
              if (routinesGroup[routine.pillar]) {
                routinesGroup[routine.pillar].routines = routinesGroup[
                  routine.pillar
                ].routines.concat(routine);
              } else {
                routinesGroup[routine.pillar] = {
                  routines: [].concat(routine),
                  selected: true,
                };
              }
            });

            setFormData(formData.set('permissions', fromJS(
              {
                routines: {
                  ...routinesGroup,
                },
              },
            )));
            setLoading(false);
          },
        ).catch(err => {
          setLoading(false);
          console.log(err);
        });
      }
    };
    runFn().catch(() => {});
  };

  // @ts-ignore
  useEffect(loadInitialData, []);

  const submitHandler = async () => {
    setInvalidFields([]);
    const invalidFieldsTemp = [];
    let routinesGroupByPillars = formData.getIn(['permissions', 'routines']).toJS();
    const routines: any[] = [];
    Object.values(routinesGroupByPillars).forEach((routinesByPillar: any) => {
      routines.push(...routinesByPillar.routines);
    });

    if (formData.get('firstName').length < 3) {
      invalidFieldsTemp.push('firstName');
    }

    if ((formData.get('position').length < 3) && !selectedPosition) {
      invalidFieldsTemp.push('position');
    }

    if (invalidFieldsTemp.length > 0) {
      setInvalidFields(invalidFieldsTemp);
      return;
    }

    if (action === 'add-user') {
      setLoading(true);
      try {
        const userType = permissionsOptions.find(({ id }) => id === selectedPermisson);
        const data = await request.post(`/api/v1/distribution-centers/${distributionCenterSlug}/users`, {
          name: formData.get('firstName'),
          position: formData.get('position') || selectedPosition,
          type: userType?.type,
        });

        if (isDistributionCenter) {
          routinesGroupByPillars = formData.getIn(['permissions', 'routines'])?.toJS();
          Object.values(routinesGroupByPillars).forEach((routinesByPillar: any) => {
            routines.push(...routinesByPillar.routines);
          });
          await request.post(`/api/v1/users/${data.user.slug}/permissions`, {
            permissions: routines,
          });
        }

        props.updateData();
        setShowPinPopup({
          show: true,
          name: data.user.name,
          pin: data.user.pin,
          position: data.user.position,
          permissionsInString: getPermissionString(formData.get('permissions')).replace(/(, )$/, ''),
        });
      } catch (e) {
        console.error(e);
        setLoading(false);
      }
    }

    if (action === 'edit-user') {
      setLoading(true);
      try {
        const userType = permissionsOptions.find(({ id }) => id === selectedPermisson);
        await request.put(`/api/v1/locations/${distributionCenterSlug}/users/${userData.slug}`, {
          name: formData.get('firstName'),
          position: selectedPosition,
          type: userType?.type,
        });
        if (isDistributionCenter) {
          routinesGroupByPillars = formData.getIn(['permissions', 'routines'])?.toJS();
          Object.values(routinesGroupByPillars).forEach((routinesByPillar: any) => {
            routines.push(...routinesByPillar.routines);
          });
          await request.post(`/api/v1/users/${userData.slug}/permissions`, {
            permissions: routines,
          });
        }
        props.updateData();
        props.reloadUsers();
        setUserInfoPopupConfig({
          show: false,
        });
      } catch (e) {
        setLoading(false);
        console.error(e);
      }
    }
  };

  if (loading) {
    return (
      <Modal modalContainerStyles={{
        width: '60vw',
        left: '20%',
        maxHeight: '80vh',
        minHeight: '30vh',
        height: 'fit-content',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        position: 'absolute',
        top: '50%',
        transform: 'translateY(-50%)',
      }}
      >
        <LoadingSpinner />
      </Modal>
    );
  }

  return (
    <div className="user-info-modal">
      <Modal
        modalContainerStyles={{ width: '60vw',
          left: '20%',
          maxHeight: '90vh',
          minHeight: '20vh',
          height: 'fit-content',
          display: 'flex',
          flexDirection: 'column',
          position: 'absolute',
          top: '50%',
          transform: 'translateY(-50%)' }}
        onOverlayClick={() => {
          setUserInfoPopupConfig({ show: false });
        }}
      >
        <div className="userInfoHeader">
          <div className="userNameAndIcon">
            <span>{formTitle}</span>
          </div>

          <div className="userIcon">
            <img
              style={formData.get('picture').length ? {
                padding: 0,
              } : {}}
              src={(formData.get('picture').length ? formData.get('picture') : userIcon)}
              alt="UserPicture"
            />
          </div>

        </div>
        <div className="upperForm">
          <LabelledInputWithImage
            onChange={e => {
              setFormData(formData.set('firstName', e.target.value));
            }}
            placeholder={t(TRANSLATIONS.ENTER_NAME)}
            label={`${t(TRANSLATIONS.NAME)}(s)*`}
            value={formData.get('firstName')}
            inputStyle={invalidFields.includes('firstName') ? {
              borderColor: COLOR_CODE_NEW.PRIMARY,
            } : {}}
          />
        </div>
        <div style={{
          padding: '0 0 15px 0',
          borderBottom: `2px solid ${COLOR_CODE_NEW.BORDERS}`,
          marginBottom: '15px',
        }}
        >
          {formData.get('showEmail')
          && (
          <LabelledInputWithImage
            disabled
            onChange={e => {
              setFormData(formData.set('email', e.target.value));
            }}
            icon={emailIcon}
            placeholder={t(TRANSLATIONS.EMAIL)}
            label={`${t(TRANSLATIONS.EMAIL)}*`}
            value={formData.get('email')}
            inputStyle={{
              border: 'none',
              color: COLOR_CODE_NEW.BLACK,
            }}
          />
          )}
          <LabelledInputWithImage
            inputStyle={invalidFields.includes('position') ? {
              borderColor: COLOR_CODE_NEW.PRIMARY,
              width: '250px',
            } : { width: '250px' }}
            onChange={e => {
              setSelectedPosition(e.target.value);
            }}
            icon={positionIcon}
            placeholder={t(TRANSLATIONS.POSITION)}
            label={t(TRANSLATIONS.POSITION)}
            value={selectedPosition}
            dropdownOptions={positionsForBu || []}
            type="dropdown"
          />
          {(invalidFields.includes('position') && !selectedPosition) && (
            <span className="user-info__input--error">{t(TRANSLATIONS.SELECT_POSITION)}</span>
          )}
        </div>
        <div className="permissionsWrapper">
          {t(TRANSLATIONS.PERMISSIONS)}
          {permissionsOptions.map(({ id, label }) => (
            <CheckBoxWithIcon
              key={id}
              onClick={() => {
                if (props.hasManagmentAccess) {
                  setSelectedPermisson(id);
                }
              }}
              deSelectedIcon={emptyCheck}
              selectedIcon={checkRedSelected}
              selected={selectedPermisson === id}
              label={label}
              disabled={!props.hasManagmentAccess}
            />
          ))}
        </div>
        <div className="user-info__bottom_container">
          { routinesPermissions && Object.keys(routinesPermissions).length > 0
        && (
        <>
          <span>{t(TRANSLATIONS.ROUTINES)}</span>
          <div className="user-info__bottom">
            <RoutinesSelector
              routinesByPillars={routinesPermissions}
              setFormData={setFormData}
            />
          </div>
        </>
        )}
          <div className="user-info-wrapper">
            <div>
              <BasicButton
                text={t(TRANSLATIONS.CANCEL)}
                onClick={() => {
                  setUserInfoPopupConfig({ show: false });
                }}
                style={{
                  backgroundColor: COLOR_CODE_NEW.PRIMARY_LIGHT,
                  padding: '10px 22px',
                  color: COLOR_CODE_NEW.PRIMARY,
                  marginRight: '10px',
                }}
              />

              <BasicButton
                text={successBtnText || t(TRANSLATIONS.ADD)}
                onClick={submitHandler}
                style={{
                  backgroundColor: COLOR_CODE_NEW.PRIMARY,
                  padding: '10px 22px',
                }}
              />
            </div>
          </div>
        </div>

      </Modal>
    </div>
  );
};

export default UserInfo;
