import React, { useMemo, useState, useEffect } from 'react';
import { FormControl, MenuItem, Select } from '@material-ui/core';
import { ACTION_LOG_SUMMARY_MODAL_STATUS, TRANSLATIONS } from 'types/enums';
import './ManagementToolForSummaryModalStyle.scss';
import i18next from 'i18next';
import { useTranslation } from 'react-i18next';

import { useGetAllManagementToolQuery } from 'generated/graphql';
import { ManagementTool } from 'features/action-logs/createEditActionLog/createEditActionLogModal.state';

type ManagementToolForSummaryModalProps = {
  modalStatus: ACTION_LOG_SUMMARY_MODAL_STATUS;
  managementTool: ManagementTool;
  handleManagementToolChange: (managementTool: ManagementTool) => void;
};
function ManagementToolForSummaryModal(props: ManagementToolForSummaryModalProps) {
  const [managementToolList, setManagementToolList] = useState<ManagementTool[]>();
  const { data } = useGetAllManagementToolQuery();
  const { t } = useTranslation();

  useEffect(() => {
    const structuredManagementToolData: ManagementTool[] = [];
    (data?.getAllManagementTool || []).forEach(managementTool => {
      const label = (i18next.language === 'en' ? managementTool?.enTitle : managementTool?.esTitle) || '';
      const value = managementTool?.id!;
      structuredManagementToolData.push({ label, value });
    });
    setManagementToolList(structuredManagementToolData!);
  }, [data]);

  const managementToolListHash = useMemo(() => {
    const hash: {
      [label: string ]: string
    } = {};

    managementToolList?.forEach(managementToolListItem => {
      if (!hash[managementToolListItem.value]) {
        hash[managementToolListItem.value] = managementToolListItem.label;
      }
    });
    return hash;
  }, [managementToolList]);

  const { modalStatus, managementTool, handleManagementToolChange } = props;
  function onChange(e: React.ChangeEvent<{ value: string, }>) {
    handleManagementToolChange({
      label: managementToolListHash[e?.target?.value] || '' as string,
      value: e?.target?.value || '' as string,
    });
  }

  return modalStatus === ACTION_LOG_SUMMARY_MODAL_STATUS.SUMMARY ? (
    <div className="check">
      <p className="extra_title">{ t(TRANSLATIONS.MANAGEMENT_TOOLS) }</p>
      <p className="extra_sub_title">{managementTool?.label || '-'}</p>
    </div>
  ) : (
    <div className="management-tool-container">
      <div className="check selection_dropdown">
        <p className="extra_title">{ t(TRANSLATIONS.MANAGEMENT_TOOLS)}</p>
        <FormControl>
          <Select
            displayEmpty
            id="meeting_select"
            variant="outlined"
            value={managementTool?.value}
            label={managementTool?.label}
            name={managementTool?.label}
            onChange={onChange as any}
            renderValue={() => (managementToolListHash[managementTool?.value]
              ? managementTool?.label
              : t(TRANSLATIONS.SELECT))}
          >
            <MenuItem value="">
              {t(TRANSLATIONS.SELECT)}
            </MenuItem>
            {managementToolList?.map((item: ManagementTool) => (
              <MenuItem
                value={item.value}
                key={item.value}
              >{item.label}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </div>
    </div>
  );
}
export default ManagementToolForSummaryModal;
