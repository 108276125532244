/* eslint-disable react-hooks/exhaustive-deps */
import axios from 'axios';
import './PdfRenderer.scss';
import React, { useEffect, useState } from 'react';
import { CircularProgress } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { TRANSLATIONS } from 'types/enums';

const PdfViewer = (props: any) => {
  const { workOrderId } = props.match.params;

  const [pdfUrl, setPdfUrl] = useState('');

  const { t } = useTranslation();

  useEffect(() => {
    const fetchPdf = async () => {
      try {
        const response = await axios.request({
          method: 'get',
          url: `/api/v1/work-order/download-report-by-work-order/${workOrderId}`,
          headers: {
            'Access-Control-Allow-Origin': '*',
            'Access-Control-Allow-Headers': 'Content-Type',
          },
          responseType: 'arraybuffer',
        });
        const downloadurl = window.URL.createObjectURL(new Blob([response.data], { type: 'application/pdf' }));
        setPdfUrl(downloadurl);
      } catch (error) {
        console.error('Error fetching the PDF:', error);
      }
    };

    fetchPdf();

    return () => {
      if (pdfUrl) {
        URL.revokeObjectURL(pdfUrl);
      }
    };
  }, []);

  return (
    <div className="pdf_renderer-report-container">
      {pdfUrl ? (
        <iframe
          src={pdfUrl}
          width="100%"
          height="100%"
          title="Work order report"
        />
      ) : (
        <div className="pdf_renderer-loader-container">
          <CircularProgress color="inherit" size={28} />
          <p>{t(TRANSLATIONS.FETCHING_REPORT)}</p>
        </div>
      )}
    </div>
  );
};

export default PdfViewer;
