import React, { useEffect } from 'react';
import './PasswordSetSuccess.scss';
import useOS from 'utils/hooks/useOS';
import { useTranslation } from 'react-i18next';
import { TRANSLATIONS } from 'types/enums';
import { ReactComponent as Logo } from '../../../../assets/icons/logo.svg';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const PasswordSetSuccess = (props:any, _: any) => {
  const { isAndroid } = useOS();
  const { t } = useTranslation();

  useEffect(() => {
    const timer = setTimeout(() => {
      window.location.assign('/login');
    }, 10000);
    return () => clearTimeout(timer);
  }, []);

  return (
    <div className="success-wrapper">
      <div className="success-container">
        <div className="nav-bar">
          <div className="brand-name">
            <Logo
              style={{ width: 150, marginTop: 15, marginRight: 15 }}
              className="arvolution-logo"
            />
          </div>
          <a href="mailto:info@arvolution.com" className="contact-button">
            {t(TRANSLATIONS.CONTACT)}
          </a>
        </div>
        <div className="password-reset-main-container">
          <div className="title-container">
            <div className="title">
              {props.register
                ? <p>{t(TRANSLATIONS.WELCOME_TO)}</p>
                : <p>{t(TRANSLATIONS.PASSWORD_RECOVERY)}</p>}
              <br />
              <div className="title font-bold">Espectro</div>

            </div>
          </div>
          <div className="main-text">
            {props.register
              ? <p>{t(TRANSLATIONS.YOUR_PASSWORD_WAS_SUCCESSFULLY_CREATED)}</p>
              : <p>{t(TRANSLATIONS.YOUR_PASSWORD_WAS_SUCCESSFULLY_RESTORED)}</p>}
            <br /><br /><br />
            {props.register
              ? <p>{t(TRANSLATIONS.YOU_CAN_START_USING_THE_APP)}</p>
              : <p>{t(TRANSLATIONS.YOU_CAN_USE_THE_APP_AGAIN)}</p>}
            <br /><br /><br />
            {t(TRANSLATIONS.REDIRECT_TIMEOUT)}  <a href="/login"> <p className="link">{t(TRANSLATIONS.HERE)}</p> </a>
          </div>
          {isAndroid
          && (
          <a href="https://play.google.com/store/apps/details?id=com.arvolution.gm.safety" className="app-button">
            {t(TRANSLATIONS.OPEN_APP).toUpperCase()}
          </a>
          )}
        </div>
      </div>
    </div>
  );
};
export default PasswordSetSuccess;
