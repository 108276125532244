import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useCubeQuery } from '@cubejs-client/react';
import { Query } from '@cubejs-client/core';
import { format } from 'date-fns';
// @ts-ignore
import ReactTable from 'react-table-legacy';
import { TRANSLATIONS } from 'types/enums';
import getCashlessQuery from 'utils/CubeObject';
import { getShortMonth } from 'utils/stringHelpers';
import { generateYearOptionsAsPerABInDevCalender } from 'utils/dateHelpers';
import EmptyState from 'components/EmptyState/EmptyState';
import { groupBy } from 'lodash';
import { getZonesWithIncidents } from 'store/actions/corporateDashboard.actions';
import { selectLocationsToShow } from 'store/selectors/distributionCenters.selectors';
import LoadingSpinner from 'components/LoadingSpinner';
import './CashlessTable.scss';
import ChartDropdown from 'components/Dropdown/ChartDropdown';
import { SelectInfo } from 'rc-menu/lib/interface';

const CashlessTable = () => {
  const yearOptions = useMemo(() => {
  // CASHLESS Feature started supporting in Safety-web from the following date.
    const CASHLESS_START_DATE = new Date(2021, 6, 1);
    return generateYearOptionsAsPerABInDevCalender(CASHLESS_START_DATE).reverse();
  }, []);
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const locationsObject = useSelector(selectLocationsToShow);
  const [selectedDropDownItem, setSelectedDropDownItem] = useState(
    yearOptions[0],
  );
  const [dropDownOpen, setDropDownOpen] = useState(false);

  const updateZonesWithIncidents = useCallback(() => {
    dispatch(getZonesWithIncidents());
  }, [dispatch]);

  useEffect(() => {
    updateZonesWithIncidents();
  }, [updateZonesWithIncidents]);

  const filter = {
    dimension: `AnalyticsCashless.${locationsObject?.level}Slug`,
    operator: 'equals',
    values: locationsObject?.list?.map((item: any) => item.slug),
  };

  const { resultSet: cashlessCount, isLoading: countLoading } = useCubeQuery(
    getCashlessQuery({
      measure: ['AnalyticsCashless.countGral'],
      dimensions: [
        `AnalyticsCashless.${locationsObject?.level}`,
        `AnalyticsCashless.${locationsObject?.level}Id`,
        `AnalyticsCashless.${locationsObject?.level}Slug`,
      ],
      dateOptions: {
        dateRange: selectedDropDownItem.dateRange,
        dimension: 'AnalyticsCashless.date',
      },
      filterOptions: filter.values ? [filter] : undefined,
    }) as Query,
  );

  const handleDropDownChange = ({ key }:SelectInfo) => {
    setSelectedDropDownItem(yearOptions.find(item => item.slug === key) || yearOptions[0]);
    setDropDownOpen(false);
  };

  const countCriticalClients = cashlessCount?.tablePivot().map(i => ({
    id: i[`AnalyticsCashless.${locationsObject?.level}Id`],
    label: i[`AnalyticsCashless.${locationsObject?.level}`],
    slug: i[`AnalyticsCashless.${locationsObject?.level}Slug`],
    criticalClients: i['AnalyticsCashless.countGral'],
  }));

  const { resultSet: cashlessAvgTotal, isLoading } = useCubeQuery(
    getCashlessQuery({
      measure: ['AnalyticsCashless.countGral'],
      dimensions: [
        `AnalyticsCashless.${locationsObject?.level}`,
        `AnalyticsCashless.${locationsObject?.level}Id`,
        `AnalyticsCashless.${locationsObject?.level}Slug`,
        'AnalyticsCashless.condition',
      ],
      dateOptions: {
        dateRange: selectedDropDownItem.dateRange,
        dimension: 'AnalyticsCashless.date',
        granularity: 'month',
      },
      filterOptions: [
        {
          dimension: `AnalyticsCashless.${locationsObject?.level}Slug`,
          operator: 'equals',
          values: locationsObject?.list?.map((item: any) => item.slug) ?? [],
        },
        {
          dimension: 'AnalyticsCashless.condition',
          operator: 'equals',
          values: ['1', '0', '3'],
        },
      ],
    }) as Query,
  );

  const data: any[] = [];

  if (cashlessAvgTotal?.tablePivot()) {
    const totalData = cashlessAvgTotal?.tablePivot();
    const totalDataG = groupBy(totalData, `AnalyticsCashless.${locationsObject?.level}Slug`);

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    Object.entries(totalDataG).forEach(([keyOne, valueOne]) => {
      const byMonth = groupBy(valueOne, 'AnalyticsCashless.date.month');

      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      Object.entries(byMonth).forEach(([keyTwo, valueTwo]) => {
        const c = valueTwo.reduce((obj, item) => {
          const total = valueTwo.reduce((acum, current) => acum + (current['AnalyticsCashless.countGral'] as number), 0);
          const real = valueTwo
            .filter(i => i['AnalyticsCashless.condition'] === '1' || i['AnalyticsCashless.condition'] === '3')
            .reduce((acum, current) => acum + (current['AnalyticsCashless.countGral'] as number), 0);

          obj.id = item[`AnalyticsCashless.${locationsObject?.level}Id`];
          obj.label = (item[`AnalyticsCashless.${locationsObject?.level}`] as string);
          obj.month = (item['AnalyticsCashless.date.month'] as string);
          obj.average = `${Math.round(Number((real / total) * 100))}%`;

          return obj;
        }, {});
        data.push(c);
      });
    });
  }

  const merged = data.length > 0 ? data.reduce((newArray: Array<any>, item) => {
    const criticalCount = countCriticalClients?.find(
      countItem => countItem.id === item.id
    )?.criticalClients;

    return [
      ...newArray,
      { ...item, criticalClients: criticalCount },
    ];
  }, []) : [];

  let savedValues: any[] = [];

  const formattedData = merged.length > 0 ? merged?.reduce((newArr, item) => {
    const itemId = item.id;
    const currentMonth = item.month;

    const index = newArr.findIndex(({ id }: { id: Number }) => id === itemId);
    const currentMonthNumber = parseInt(format(new Date(currentMonth), 'M'), 10);
    const monthsList = Array.from({ length: 12 }, (_item, itemIndex) => (
      { month: itemIndex + 1, average: '0%' }
    ));

    savedValues = [...savedValues, {
      month: currentMonthNumber,
      average: item.average,
      id: item.id,
    }];

    return index !== -1
      ? [...newArr]
      : [
        ...newArr,
        {
          id: item.id,
          criticalClients: item.criticalClients,
          location: item.label,
          months: monthsList,
        },
      ];
  }, []) : [];

  savedValues.forEach(savedItem => {
    const foundIndex = formattedData.findIndex(({ id }: { id: Number }) => id === savedItem.id);
    formattedData[foundIndex].months[savedItem.month - 1] = {
      month: savedItem.month,
      average: savedItem.average,
    };
  });

  const conditionalColumns = (is2021: boolean) => (is2021
    ? []
    : [
      {
        Header: getShortMonth(t(TRANSLATIONS.JANUARY)),
        id: 'january',
        accessor: 'months[0].average',
        minWidth: 95,
        Cell: (row: any) => (
          <p className="text-left font-bold margin-left-25">
            {row.value || '0%'}
          </p>
        ),
      },
      {
        Header: getShortMonth(t(TRANSLATIONS.FEBRUARY)),
        id: 'february',
        accessor: 'months[1].average',
        minWidth: 95,
        Cell: (row: any) => (
          <p className="text-left font-bold margin-left-25">
            {row.value || '0%'}
          </p>
        ),
      },
      {
        Header: getShortMonth(t(TRANSLATIONS.MARCH)),
        id: 'march',
        accessor: 'months[2].average',
        minWidth: 95,
        Cell: (row: any) => (
          <p className="text-left font-bold margin-left-25">
            {row.value}
          </p>
        ),
      },
      {
        Header: getShortMonth(t(TRANSLATIONS.APRIL)),
        id: 'april',
        accessor: 'months[3].average',
        minWidth: 95,
        Cell: (row: any) => (
          <p className="text-left font-bold margin-left-25">
            {row.value}
          </p>
        ),
      },
      {
        Header: getShortMonth(t(TRANSLATIONS.MAY)),
        id: 'may',
        accessor: 'months[4].average',
        minWidth: 95,
        Cell: (row: any) => (
          <p className="text-left font-bold margin-left-25">
            {row.value}
          </p>
        ),
      },
      {
        Header: getShortMonth(t(TRANSLATIONS.JUNE)),
        id: 'june',
        accessor: 'months[5].average',
        minWidth: 95,
        Cell: (row: any) => (
          <p className="text-left font-bold margin-left-25">
            {row.value}
          </p>
        ),
      },
    ]
  );

  const columns = [
    {
      Header: t(TRANSLATIONS.DIST_CENTERS),
      minWidth: 140,
      id: 'Location',
      accessor: 'location',
      Cell: (row: any) => (
        <p className="text-left font-bold margin-left-25">
          {row.value}
        </p>
      ),
    },
    {
      Header: t(TRANSLATIONS.CRITICAL_CLIENTS),
      minWidth: 110,
      id: 'criticalClients',
      accessor: 'criticalClients',
      Cell: (row: any) => (
        <p className="text-left font-bold margin-left-25">
          {row.value.toLocaleString() || '0%'}
        </p>
      ),
    },
    ...conditionalColumns(Number(selectedDropDownItem.slug) === 2021),
    {
      Header: getShortMonth(t(TRANSLATIONS.JULY)),
      id: 'july',
      accessor: 'months[6].average',
      minWidth: 95,
      Cell: (row: any) => (
        <p className="text-left font-bold margin-left-25">
          {row.value}
        </p>
      ),
    },
    {
      Header: getShortMonth(t(TRANSLATIONS.AUGUST)),
      id: 'august',
      accessor: 'months[7].average',
      minWidth: 95,
      Cell: (row: any) => (
        <p className="text-left font-bold margin-left-25">
          {row.value}
        </p>
      ),
    },
    {
      Header: getShortMonth(t(TRANSLATIONS.SEPTEMBER)),
      id: 'september',
      accessor: 'months[8].average',
      minWidth: 95,
      Cell: (row: any) => (
        <p className="text-left font-bold margin-left-25">
          {row.value}
        </p>
      ),
    },
    {
      Header: getShortMonth(t(TRANSLATIONS.OCTOBER)),
      id: 'october',
      accessor: 'months[9].average',
      minWidth: 95,
      Cell: (row: any) => (
        <p className="text-left font-bold margin-left-25">
          {row.value}
        </p>
      ),
    },
    {
      Header: getShortMonth(t(TRANSLATIONS.NOVEMBER)),
      id: 'november',
      accessor: 'months[10].average',
      minWidth: 95,
      Cell: (row: any) => (
        <p className="text-left font-bold margin-left-25">
          {row.value}
        </p>
      ),
    },
    {
      Header: getShortMonth(t(TRANSLATIONS.DECEMBER)),
      id: 'december',
      accessor: 'months[11].average',
      minWidth: 95,
      Cell: (row: any) => (
        <p className="text-left font-bold margin-left-25">
          {row.value}
        </p>
      ),
    },
  ];
  if (isLoading || countLoading || locationsObject === null) {
    return <LoadingSpinner height={231} />;
  }
  return (
    <div className="cashless-table">
      <ChartDropdown
        onChange={handleDropDownChange}
        selectedDropDownItem={selectedDropDownItem}
        dropDownItems={yearOptions}
        dropDownOpen={dropDownOpen}
        setDropDownOpen={setDropDownOpen}
        labelClassName="label-default"
      />
      <ReactTable
        data={formattedData}
        columns={columns}
        showPagination={false}
        defaultPageSize={formattedData?.length}
        className="-striped -highlight"
        NoDataComponent={() => <EmptyState />}
      />
    </div>
  );
};

export default CashlessTable;
