import { fromJS } from 'immutable';
import { SET_YEARS_DATA } from '../actions/driverManagementSystem.actions';

const INITIAL_STATE = fromJS({});

const driverManagementSystemReducer = (state = INITIAL_STATE, action: any) => {
  switch (action.type) {
    case SET_YEARS_DATA:
      return state.set(action.yearsData?.year, fromJS(action.yearsData?.data));
    default:
      return state;
  }
};

export default driverManagementSystemReducer;
