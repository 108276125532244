import React, { useState, useEffect } from 'react';
import './ToastStyles.scss';

type Props = {
  toastList: any[];
  position: 'top-right' | 'bottom-right' | 'top-left' | 'bottom-left';
  updateToastList: (list: any[]) => void;
  reUploadCallback: (e: any, row: any) => void;
};

const Toast = (props: Props) => {
  const { toastList, position, updateToastList, reUploadCallback } = props;
  const [list, setList] = useState<any[]>(toastList);
  useEffect(() => {
    setList([...toastList]);
  }, [toastList]);

  const deleteToast = (id: number) => {
    const listItemIndex = list.findIndex((_value, index: number) => index === id);
    list.splice(listItemIndex, 1);
    updateToastList(list);
    setList([...list]);
  };

  useEffect(() => {
    const interval = setInterval(() => {
      if (toastList.length && list.length) {
        deleteToast(0);
      }
    }, 5000);

    return () => {
      clearInterval(interval);
    };
    // eslint-disable-next-line
    }, [toastList, list]);

  return (
    <div className={`toast-container ${position}`}>
      {
        list.map((toast: any, index: number) => (
          <div
            // eslint-disable-next-line react/no-array-index-key
            key={index}
            className={`toast ${position}`}
          >
            <p className="toast-title">{toast.title}</p>
            <span
              role="button"
              onClick={
                toast?.reUploadObj
                  ? () => reUploadCallback(toast?.reUploadObj.e, toast?.reUploadObj.row)
                  : () => deleteToast(index)
              }
              className="toast-message"
            >
              {toast.description}
            </span>
          </div>
        ))
      }
    </div>
  );
};

export default Toast;
