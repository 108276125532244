import React, { useState, useEffect } from 'react';
// @ts-ignore
import ReactTable from 'react-table-legacy';
import { useSelector } from 'react-redux';
import MainNavigationHeader from 'components/MainNavigationHeader';
import OutlineButton from 'components/Button/OutlineButton';
import req from 'utils/request';
import LoadingSpinner from 'components/LoadingSpinner';
// import InputWithIcon from 'components/Input/InputWithIcon';
import InputWithLabel from 'components/Input/InputWithLabel';
import { useTranslation } from 'react-i18next';
import { TRANSLATIONS } from 'types/enums';

const useDebounce = (value: any, timeout: any) => {
  const [state, setState] = useState(value);

  useEffect(() => {
    const handler = setTimeout(() => setState(value), timeout);

    return () => clearTimeout(handler);
  }, [value, timeout]);

  return state;
};

const UsersIndex = (props: any) => {
  const userSlug = props.history.location.search.split('=')[1];
  const distCenterSlug = props.match.params.distCenter;
  const { t } = useTranslation();
  const userType = useSelector((state: any) => state.getIn(['user', 'type']));

  const [users, setUsers] = useState(undefined) as any;
  const [search, setSearch] = useState('');

  const getUserInfo = async () => {
    try {
      const res = await req.get(`/api/v1/users-list?shortSlug=${search || userSlug || ''}`);
      setUsers(res.users);
    } catch (e) {
      console.log('the errror: ', e);
    }
  };

  const debouncedUsersReq = useDebounce(search, 500);

  useEffect(() => {
    getUserInfo();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    getUserInfo();
    // eslint-disable-next-line
  }, [debouncedUsersReq]);

  const handleSearch = async (event: any) => {
    setSearch(event.target.value);
    // debouncedUsersReq();
    // debounced();
  };

  const setUserReqStatus = (slug: string, status: boolean) => {
    const i = users.findIndex((u: any) => u.slug === slug);
    const a = JSON.parse(JSON.stringify(users));
    a[i].sendingRequest = status;
    setUsers(a);
  };

  const updateUserBlockStatus = async (slug: string, isBlocked: boolean, c: any) => {
    const i = users.findIndex((u: any) => u.slug === slug);
    const a = JSON.parse(JSON.stringify(users));
    a[i].isBlocked = isBlocked;
    setUsers(a, c);
  };

  const blockUser = async (slug: string) => {
    setUserReqStatus(slug, true);
    const url = `/api/v1/users/${slug}/block`;
    try {
      await req.patch(url);
      updateUserBlockStatus(slug, true, () => setUserReqStatus(slug, false));
    } catch (e) {
      setUserReqStatus(slug, false);
    }
  };

  const unblockUser = async (slug: string) => {
    setUserReqStatus(slug, true);
    const url = `/api/v1/users/${slug}/unblock`;
    try {
      await req.patch(url);
      updateUserBlockStatus(slug, false, () => setUserReqStatus(slug, false));
    } catch (e) {
      setUserReqStatus(slug, false);
    }
  };

  const renderConfigurationCell = (row: any) => {
    return row.original.sendingRequest ? <LoadingSpinner height={43} text="" style={{ marginLeft: 100, marginTop: -5 }} /> : (
      <div style={{ display: 'flex', flexDirection: 'row-reverse', justifyContent: 'space-between' }}>
        <OutlineButton
          text={row.value ? t(TRANSLATIONS.UNBLOCK) : t(TRANSLATIONS.BLOCK)}
          onClick={
            () => (row.value ? unblockUser(row.original.slug) : blockUser(row.original.slug))
          }
          disabled={(userType === 'LocationSecurity' && row.value)}
        />
        {/* <OutlineButton text="Bloquear" onClick={() => null} /> */}
      </div>
    );
  };

  const createTableColumns = () => {
    const columns = [] as any;
    columns.push({
      Header: t(TRANSLATIONS.NAME),
      id: 'name',
      accessor: 'name',
      Cell: (row: any) => (
        <p className="text-left font-bold" style={{ marginLeft: 25 }}>
          {row.value}
        </p>
      ),
    });

    columns.push({
      Header: 'ID',
      id: 'id',
      accessor: 'shortSlug',
      Cell: (row: any) => (
        <p className="text-left font-bold" style={{ marginLeft: 25 }}>
          {row.value}
        </p>
      ),
    });

    columns.push({
      Header: t(TRANSLATIONS.BLOCKED_REASON),
      id: 'reason',
      accessor: 'blockedReason',
      Cell: (row: any) => (
        <p className="text-left font-bold" style={{ marginLeft: 25 }}>

          {row.value && (t(row.value))}
        </p>
      ),
    });
    // col 4: configuration menu
    columns.push({
      id: 'configuration',
      Header: '',
      accessor: 'isBlocked',
      Cell: renderConfigurationCell,
      width: 250,
    });

    return columns;
  };

  const columns = users !== undefined && createTableColumns();
  return (
    <>
      <MainNavigationHeader
        userName=""
        goBack={distCenterSlug ? props.history.goBack : null}
        nutIconOnly
      />
      <div className="container" style={{ marginTop: 60 }}>
        { users === undefined ? <LoadingSpinner height={100} /> : (
          <>
            <InputWithLabel
              onChange={handleSearch}
              placeholder={t(TRANSLATIONS.SEARCH)}
              value={search}
            />
            <div className="card">
              <ReactTable
                data={users}
                columns={columns}
                defaultPageSize={users.length}
                showPagination={false}
                className="-striped"
                NoDataComponent={() => null}
              />
            </div>
          </>
        )}
      </div>
    </>
  );
};
export default UsersIndex;
