import React, { useMemo } from 'react';
import Chart from 'react-apexcharts';
import LoadingSpinner from 'components/LoadingSpinner';
import ErrorMessage from 'components/ErrorMessage';
import { useSelector } from 'react-redux';
import EmptyState from 'components/EmptyState/EmptyState';
import selectDriverManagementSystem from 'store/selectors/driverManagementSystem.selectors';
import {
  customDMSDataSelectorByYearMonthTypeBuAndSubType,
  DMSSelectorTypes,
} from '../dmsHelper';
import '../DriverManagmentSystemStyles.scss';

type Props = {
  loading: boolean;
  month: string;
  year: number;
  error: boolean;
};

const CurrentMonthBarChart = ({ loading, month, year, error }: Props) => {
  const driverManagementSystemData = useSelector(selectDriverManagementSystem);
  const selectedMonthData = customDMSDataSelectorByYearMonthTypeBuAndSubType(
    driverManagementSystemData,
    year,
    month,
    DMSSelectorTypes.perMonthGraph,
  );

  const totalIncidentsForCurrentAndPreviousMonth = useMemo(() => {
    const temptotalIncidentsForCurrentAndPreviousMonth = {
      currentMontIncidentsData: [] as number[],
      previousMontIncidentsData: [] as number[],
    };
    if (!loading) {
      if (selectedMonthData) {
        Object.values(selectedMonthData).forEach((bu: any) => {
          temptotalIncidentsForCurrentAndPreviousMonth.currentMontIncidentsData.push(
            bu.currentMonth.totalIncidents,
          );
          temptotalIncidentsForCurrentAndPreviousMonth.previousMontIncidentsData.push(
            bu.previousMonth.totalIncidents,
          );
        });
        if (
          temptotalIncidentsForCurrentAndPreviousMonth.currentMontIncidentsData.every(
            item => item === 0,
          )
          && temptotalIncidentsForCurrentAndPreviousMonth.previousMontIncidentsData.every(
            item => item === 0,
          )
        ) {
          return {
            currentMontIncidentsData: [],
            previousMontIncidentsData: [],
          };
        }
        return temptotalIncidentsForCurrentAndPreviousMonth;
      }
    }
    return temptotalIncidentsForCurrentAndPreviousMonth;
  }, [loading, selectedMonthData]);

  const customOptions = {
    plotOptions: {
      bar: {
        horizontal: false,
        columnWidth: '55%',
        endingShape: 'flat',
      },
    },
    dataLabels: {
      enabled: true,
      style: {
        fontSize: '14px',
        fontWeight: 'bold',
        colors: ['#333', '#333', '#333', '#333', '#333'],
      },
      // Formatter for showing "0" when there is no data.
      formatter(value: number) {
        if (value === 0) {
          return value;
        }
      },
    },
    stroke: {
      show: true,
      width: 2,
      colors: ['transparent'],
    },
    xaxis: {
      categories: ['México', 'Ecuador', 'Colombia', 'Perú', 'CAC'],
    },
    yaxis: {
      title: {
        text: 'Registro',
        style: {
          color: '#002033',
          fontSize: '12px',
          fontFamily: 'Roboto',
          fontWeight: 400,
        },
      },
      labels: {
        formatter: (value: any) => { return value.toLocaleString(); },
      },
    },
    fill: {
      opacity: 1,
    },
    colors: ['#304473', '#5572B9'],
    legend: {
      position: 'top',
      horizontalAlign: 'left',
      itemMargin: {
        horizontal: 10,
        vertical: 20,
      },
    },
    chart: {
      toolbar: {
        show: false,
      },
    },
  };

  const chartData = [
    {
      name: 'Mes anterior',
      data: totalIncidentsForCurrentAndPreviousMonth.previousMontIncidentsData,
    },
    {
      name: 'Mes actual',
      data: totalIncidentsForCurrentAndPreviousMonth.currentMontIncidentsData,
    },
  ];

  if (error) return <ErrorMessage />;
  if (loading) return <LoadingSpinner height={320} />;
  if (
    totalIncidentsForCurrentAndPreviousMonth.previousMontIncidentsData.length === 0
    && totalIncidentsForCurrentAndPreviousMonth.currentMontIncidentsData.length === 0
  ) return <EmptyState />;

  return (
    <Chart
      type="bar"
      options={customOptions}
      series={chartData}
      height="320px"
    />
  );
};

export default CurrentMonthBarChart;
