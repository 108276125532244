import ChartDropdown from 'components/Dropdown/ChartDropdown';
import { User_Type as USER_TYPE } from 'generated/graphql';
import { sortBy } from 'lodash';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { TRANSLATIONS } from 'types/enums';
import refresh from '../../../../assets/icons/reset.svg';
import request from '../../../../utils/request';
import { UserTypesWithLabels } from '../../../../constants';

interface UserFilterProps {
  filters: any,
  setFilters: any
  resetFilters: any
  setSearch: any,
  isLoading: boolean
}

const UserFilters = (props: UserFilterProps) => {
  const { filters, setFilters, resetFilters, setSearch, isLoading } = props;
  const { t } = useTranslation();

  const [dropDownOpenLocation, setDropDownOpenLocation] = useState(false);
  const [dropDownOpenType, setDropDownOpenType] = useState(false);
  // const [dropDownOpenLogistic, setDropDownOpenLogistic] = useState(false);

  const dropDownItemsExtInt = [{
    slug: '',
    label: t(TRANSLATIONS.ALL),
  },
  {
    slug: USER_TYPE.Contractor,
    label: t(TRANSLATIONS.EXTERNAL),
  }, {
    slug: USER_TYPE.Internal,
    label: t(TRANSLATIONS.INTERNAL),
  },
  ];
  const [dropDownItemsLocation, setDropdownItemsLocation] = useState([{
    slug: '',
    label: t(TRANSLATIONS.SEARCH_LOCATION),
  }]);

  let userTypesArray = Object.keys(UserTypesWithLabels).map((item: any) => ({
    slug: item,
    label: UserTypesWithLabels[item],
  }));
  userTypesArray = sortBy(userTypesArray, 'label');

  const dropDownItemsType = [{
    slug: '',
    label: t(TRANSLATIONS.USER_TYPE),
  }, ...userTypesArray];

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [selectedDropDownItem, setSelectedDropDownItem] = useState(
    dropDownItemsExtInt.find((item: any) => item.slug === filters.userType),
  );
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [selectedLocation, setSelectedLocation] = useState(
    dropDownItemsLocation.find((item: any) => item.slug === filters.locationSlug),
  );
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [selectedDropDownItemType, setSelectedDropDownItemType] = useState(
    dropDownItemsType.find((item: any) => item.slug === filters.roleType),
  );

  const handleFilter = (filter: string, value: any) => {
    setDropDownOpenLocation(false);
    setDropDownOpenType(false);
    const updatedValue = value === 'item_0' ? '' : value;
    setFilters((f: any) => ({ ...f, [filter]: updatedValue, page: 1 }));
  };

  const handleResetFilters = () => {
    setFilters(resetFilters);
    setSearch('');
  };

  useEffect(() => {
    const fetchLocation = async () => {
      let locations = await request.post('/api/v1/users/locations', {}) || [];
      locations = sortBy(locations, 'name');
      setDropdownItemsLocation([{
        slug: '',
        label: t(TRANSLATIONS.SEARCH_LOCATION),
      }, ...locations.map((item: any) => ({ slug: item?.slug, label: item?.name }))]);
    };

    fetchLocation();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setSelectedDropDownItem(dropDownItemsExtInt.find(
      (item: any) => item.slug === filters.userType,
    ));
    setSelectedLocation(dropDownItemsLocation.find(
      (item: any) => item.slug === filters.locationSlug,
    ));
    setSelectedDropDownItemType(dropDownItemsType.find(
      (item: any) => item.slug === filters.roleType,
    ));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters]);

  return (
    <div className="user-filter">
      {/* <div className="filter">
        <div className="label"> Interno/Externo </div>
        <ChartDropdown
          onChange={({ key }) => handleFilter('userType', key)}
          selectedDropDownItem={selectedDropDownItem}
          dropDownItems={dropDownItemsExtInt}
          dropDownOpen={dropDownOpenLogistic}
          setDropDownOpen={setDropDownOpenLogistic}
          labelClassName="label-default"
          isLoading={isLoading}
        />
      </div>
      <div className="filter">
        <div className="label"> Ubicación </div>
        <ChartDropdown
          onChange={({ key }) => handleFilter('locationSlug', key)}
          selectedDropDownItem={selectedLocation}
          dropDownItems={dropDownItemsLocation}
          dropDownOpen={dropDownOpenLocation}
          setDropDownOpen={setDropDownOpenLocation}
          labelClassName="label-default"
          isLoading={isLoading}
        />
      </div> */}
      <div className="filter">
        <div className="label"> Tipo de usuario </div>
        <ChartDropdown
          onChange={({ key }) => handleFilter('roleType', key)}
          selectedDropDownItem={selectedDropDownItemType}
          dropDownItems={dropDownItemsType}
          dropDownOpen={dropDownOpenType}
          setDropDownOpen={setDropDownOpenType}
          labelClassName="label-default"
          isLoading={isLoading}
        />
      </div>
      <div className="reset" onClick={handleResetFilters} role="button" style={{ pointerEvents: isLoading ? 'none' : 'auto', opacity: isLoading ? '0.6' : '1' }}>
        <img src={refresh} alt="reset" />
        <span>Resetear filtros</span>
      </div>
    </div>
  );
};

export default UserFilters;
