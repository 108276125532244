/* eslint-disable no-shadow */
import React, { FC, useState, useEffect } from 'react';
import { connect, useSelector } from 'react-redux';
import { bindActionCreators } from 'redux';
import { useHistory } from 'react-router-dom';
import { COLOR_CODE, TRANSLATIONS } from 'types/enums';
import { useTranslation } from 'react-i18next';
import { selectUserLocation } from 'store/selectors/user.selectors';
import SearchBar from 'components/SearchBar/SearchBar';
import { groupLocationsByCluster } from 'utils/arrayHelpers';
import { sortBy } from 'lodash';
import ActionButton from 'components/Espectro/Buttons/Action/Action';
import Overlay from 'components/Espectro/Overlay';
import EspectroSettings from 'components/Espectro/Settings';
import useDidUpdateEffect from '../../utils/hooks/useDidUpdateEffect';
import {
  setBusinessUnit,
  setZone,
  setAllZones,
  setSubzone,
  setAllSubzones,
  setLocation,
  setAllLocations,
  getAnalyticsFilters,
  updateAnalyticsLocations,
  setCluster,
  setAllClusters,
} from '../../store/actions/globalFilters.actions';
import { BusinessUnitJSON, ZoneJSON, SubzoneJSON, NameAndSlug } from '../../types/interfaces';
import Settings from './Settings';
import Dropdown from './Dropdown';
import RefreshButton from './RefreshButton';
import { ReactComponent as Safety360Icon } from '../../assets/icons/safety-360.svg';
import { ReactComponent as EspectroFullLogo } from '../../assets/icons/espectro/espectroLogo.svg';

import './Header.scss';
import GlobalSearch from './GlobalSearch';
import { isEspectro } from '../../constants';

const CONTAINED_ROUTE_TO_DISABLE_LOCATION_SELECTOR = ['/analytics/GKPI', '/analytics/sif-statistics', '/analytics/driver-management-system'];

interface Props {
  businessUnitName?: string,
  setBusinessUnit?: Function,
  zoneName?: string,
  setZone?: Function,
  allZones?: NameAndSlug[],
  setAllZones: Function,
  subzoneName?: string,
  setSubzone?: Function,
  allSubzones?: NameAndSlug[],
  setAllSubzones: Function,
  locationName?: string,
  setLocation?: Function,
  allLocations?: NameAndSlug[],
  setAllLocations: Function,
  analyticsFilters?: BusinessUnitJSON[]
  getAnalyticsFilters?: Function,
  hideFilters?: boolean,
  showSearchbar?: boolean,
  updateAnalyticsLocations: Function,
  userType: string,
  clusterName?: string,
  setCluster?: Function,
  allClusters?: NameAndSlug[],
  setAllClusters: Function,
  showLogo?: boolean,
  showSettings?: boolean,
}

const Header: FC<Props> = ({
  businessUnitName,
  setBusinessUnit = () => { },
  zoneName,
  setZone = () => { },
  allZones = [],
  setAllZones = () => { },
  subzoneName,
  setSubzone = () => { },
  allSubzones = [],
  setAllSubzones = () => { },
  locationName,
  setLocation = () => { },
  allLocations = [],
  setAllLocations = () => { },
  analyticsFilters = [],
  getAnalyticsFilters = () => { },
  hideFilters = false,
  showSearchbar = false,
  updateAnalyticsLocations = () => { },
  userType,
  clusterName,
  setCluster = () => { },
  allClusters = [],
  setAllClusters,
  showLogo = true,
  showSettings = true,
}: Props) => {
  /* The initial name for every zone/businessUnit/etc. is all zones/businessUnits/etc.,
     e.g. Todas Las BUs(means all business units), Todo Mexico(all zones in Mexico)
   */

  // To make the logo glow on hover
  const [isHovered, setHovered] = useState(false);
  const location = useSelector(selectUserLocation);
  const [canAccessGlobalLocationSelector, setCanAccessGlobalLocationSelector] = useState(true);
  const [showLogoutOverlay, setShowLogoutOverlay] = useState(false);

  const isFactory = location?.get('type') === 'FACTORY';

  const history = useHistory();
  const { t } = useTranslation();
  useEffect(() => {
    if (CONTAINED_ROUTE_TO_DISABLE_LOCATION_SELECTOR.includes(history.location.pathname)) {
      setCanAccessGlobalLocationSelector(false);
    }
    history.listen(e => {
      if (CONTAINED_ROUTE_TO_DISABLE_LOCATION_SELECTOR.includes(e.pathname)) {
        setCanAccessGlobalLocationSelector(false);
      } else {
        setCanAccessGlobalLocationSelector(true);
      }
    });
  }, [history]);

  const handleLogout = () => {
    // Close the overlay
    setShowLogoutOverlay(false);
    history.push('/logout');
  };

  const enableResetFiltersButton = !!((businessUnitName && analyticsFilters.length > 1)
    || (zoneName && allZones.length > 1)
    || (subzoneName && allSubzones.length > 1)
    || (locationName && allLocations.length > 1));

  /* Since the Header shouldn't change on mount/unmount, we wan't this to run only when the
     dependencies change, see https://stackoverflow.com/questions/53253940/make-react-useeffect-hook-not-run-on-initial-render
*/
  useDidUpdateEffect(() => {
    if (businessUnitName && analyticsFilters.length) {
      const bu = analyticsFilters?.find(b => b.name === businessUnitName) as BusinessUnitJSON;
      const { zones } = bu;
      setAllZones(zones);
      const isCurrentZoneExistsInBusinessUnit = !!zones.find(z => z.name === zoneName);
      if (isCurrentZoneExistsInBusinessUnit) {
        return;
      }
      /* If there's only one subzone in the zone, then set the subzone to that one
         to be shown as <p> immediately
      */
      if (zones.length === 1) {
        setZone({ name: zones[0].name, slug: zones[0].slug });
      } else {
        setZone({ name: '', slug: '' });
      }
    }
    if (!businessUnitName) {
      setZone({ name: '', slug: '' });
    }
    updateAnalyticsLocations();
  }, [businessUnitName]);

  useDidUpdateEffect(() => {
    if (zoneName && allZones.length) {
      const zone = allZones.find(z => z.name === zoneName) as ZoneJSON;
      if (!zone) {
        return;
      }
      const { subzones } = zone;
      setAllSubzones(subzones);
      const isCurrentSubzoneExistsInZone = !!subzones.find(sz => sz.name === subzoneName);
      if (isCurrentSubzoneExistsInZone) {
        return;
      }
      if (subzones.length === 1) {
        setSubzone({ name: subzones[0].name, slug: subzones[0].slug });
      } else {
        setSubzone({ name: '', slug: '' });
      }
    }
    if (!zoneName) {
      setSubzone({ name: '', slug: '' });
    }
    updateAnalyticsLocations();
  }, [zoneName, `${allZones?.length && allZones[0].slug}`]);

  useDidUpdateEffect(() => {
    if (subzoneName && allSubzones.length) {
      const subzone = allSubzones.find(s => s.name === subzoneName) as SubzoneJSON;
      if (!subzone) {
        return;
      }
      const { locations } = subzone;
      const { clusterExists, clusters } = groupLocationsByCluster(locations);
      if (clusterExists) {
        setAllClusters(clusters);
        if (clusters.length === 1) {
          setCluster({ name: clusters[0].name, slug: clusters[0].slug });
        } else {
          setCluster({ name: '', slug: '' });
        }
      } else {
        setAllClusters(null);
        setCluster({ name: '', slug: '' });
        setAllLocations(locations);
        const isCurrentLocationExistsInSubZone = !!locations.find(l => l.name === locationName);
        if (isCurrentLocationExistsInSubZone) {
          return;
        }
        if (locations.length === 1) {
          setLocation({ name: locations[0].name, slug: locations[0].slug });
        } else {
          setLocation({ name: '', slug: '' });
        }
      }
    }
    if (!subzoneName) {
      setCluster({ name: '', slug: '' });
      setLocation({ name: '', slug: '' });
    }
    updateAnalyticsLocations();
  }, [subzoneName, `${allSubzones?.length && allSubzones[0].slug}`]);

  useDidUpdateEffect(() => {
    if (clusterName && allClusters?.length) {
      const cluster = allClusters.find(cluster => cluster.name === clusterName) as SubzoneJSON;
      if (!cluster) {
        return;
      }
      const { locations } = cluster;
      setAllLocations(locations);
      const isCurrentLocationExistsInSubZone = !!locations.find(l => l.name === locationName);
      if (isCurrentLocationExistsInSubZone) {
        return;
      }
      if (locations.length === 1) {
        setLocation({ name: locations[0].name, slug: locations[0].slug });
      } else {
        setLocation({ name: '', slug: '' });
      }
    }
    updateAnalyticsLocations();
  }, [clusterName, `${allClusters?.length && allClusters[0].slug}`]);

  useEffect(() => {
    updateAnalyticsLocations();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [locationName]);

  useEffect(() => {
    if (
      analyticsFilters && analyticsFilters.length === 1
      && analyticsFilters[0].name !== businessUnitName
    ) {
      const { name, slug } = analyticsFilters[0];
      setBusinessUnit({ name, slug });
      const { zones } = analyticsFilters[0];
      setAllZones(zones);
      if (zones.length === 1 && zoneName !== zones[0].name) {
        const { name, slug } = zones[0];
        setZone({ name, slug });
        const { subzones } = zones[0];
        setAllSubzones(subzones);
        if (subzones.length === 1 && subzoneName !== subzones[0].name) {
          const { name, slug } = subzones[0];
          setSubzone(name, slug);
          const { locations } = subzones[0];
          const { clusterExists, clusters } = groupLocationsByCluster(locations);
          if (clusterExists) {
            setAllClusters(clusters);
            if (clusters.length === 1 && clusterName !== clusters[0].name) {
              const { name, slug } = clusters[0];
              setCluster({ name, slug });
            }
          } else {
            setAllClusters(null);
            setAllLocations(clusters);
            if (locations.length === 1 && locationName !== locations[0].name) {
              const { name, slug } = locations[0];
              setLocation({ name, slug });
            }
          }
        }
      }
    }
    // if (!analyticsFilters.length) getAnalyticsFilters();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [analyticsFilters]);
  if (isEspectro) {
    return (
      <div className="header espectro-header">
        <div
          className="espectro-header-flex-between"
          role="presentation"
        >
          <div style={{
            display: 'flex',
            alignItems: 'center',
          }}
          >
            <div role="button" onClick={() => history.push('/')} className="icon-wrapper">
              <EspectroFullLogo />
            </div>
            <div className="line" />
          </div>
          <EspectroSettings />
          <Overlay open={showLogoutOverlay} onClose={() => setShowLogoutOverlay(false)}>
            <div style={{ maxWidth: 500, display: 'flex', flexDirection: 'column', marginLeft: 'auto', marginRight: 'auto', backgroundColor: 'white', justifyContent: 'center', alignItems: 'center', marginTop: 200, padding: 10, paddingBottom: 15, paddingTop: 15 }}>
              <h2>Do you want to Logout?</h2>
              <div style={{ display: 'flex', gap: 20 }}>
                <ActionButton text="Cancel" onClick={() => setShowLogoutOverlay(false)} background="whitesmoke" style={{ color: COLOR_CODE.ESPECTRO_PRIMARY }} />
                <ActionButton text="Logout" background={COLOR_CODE.ESPECTRO_PRIMARY} onClick={handleLogout} />
              </div>
            </div>
          </Overlay>
        </div>
      </div>
    );
  }

  return (
    <div className="header">
      {showLogo && (
        <div
          className="home-button-container"
          onMouseEnter={() => setHovered(true)}
          onMouseLeave={() => setHovered(false)}
          onClick={() => history.push('/')}
          role="presentation"
        >
          <div className={`icon-wrapper ${isHovered ? 'hovered' : ''}`}>
            <Safety360Icon />
          </div>
          <p className="title">Safety</p>
        </div>
      )}
      <div className="line" />
      {!showSearchbar && !hideFilters && canAccessGlobalLocationSelector ? (
        <div className="info">
          {analyticsFilters.length > 1 && (<p>{location?.get('slug') === 'newlands-brewery' ? 'AFR' : 'MAZ'}</p>)}
          {(allZones.length !== 1 || analyticsFilters.length > 1) && (
            <div className={!businessUnitName ? 'info__dropdown info__dropdown--grey' : 'info__dropdown'}>
              <span>BU</span>
              <Dropdown
                name="businessUnit"
                value={businessUnitName as string}
                content={analyticsFilters}
                onChange={(e: Event) => setBusinessUnit((e.target as HTMLInputElement).value)}
              />
            </div>
          )}
          {
            (businessUnitName
              && (analyticsFilters.length > 1
                || allZones.length > 1
                || (allZones.length === 1 && allSubzones.length > 1))
            ) && (
              <div className={!zoneName ? 'info__dropdown info__dropdown--grey' : 'info__dropdown'}>
                <span>{t(TRANSLATIONS.REGION)}</span>
                <Dropdown
                  name="zone"
                  value={zoneName as string}
                  content={allZones}
                  onChange={(e: Event) => setZone((e.target as HTMLInputElement).value)}
                  lastSelected={businessUnitName}
                />
              </div>
            )
          }
          {
            businessUnitName && zoneName && (
              <div className={!subzoneName ? 'info__dropdown info__dropdown--grey' : 'info__dropdown'}>
                <span>{t(TRANSLATIONS.ZONE)}</span>
                <Dropdown
                  name="subzone"
                  value={subzoneName as string}
                  content={allSubzones}
                  onChange={(e: Event) => setSubzone((e.target as HTMLInputElement).value)}
                  lastSelected={zoneName}
                />
              </div>
            )
          }
          {
            businessUnitName && zoneName && subzoneName && !!allClusters?.length && (
              <div className={!clusterName ? 'info__dropdown info__dropdown--grey' : 'info__dropdown'}>
                <span>Clusters</span>
                <Dropdown
                  name="cluster"
                  value={clusterName as string}
                  content={allClusters}
                  onChange={(e: any) => {
                    setCluster((e.target as HTMLInputElement).value);
                    // history.push(`/distribution-centers/${e.target.value.slug}`);
                  }}
                  lastSelected={subzoneName}
                />
              </div>
            )
          }
          {
            businessUnitName
            && zoneName
            && subzoneName
            && (clusterName || !allClusters?.length)
            && (
              <div className={!locationName ? 'info__dropdown info__dropdown--grey' : 'info__dropdown'}>
                <span>{isFactory ? t(TRANSLATIONS.OPERATION)
                  : t(TRANSLATIONS.DISTRIBUTION_CENTERS, { count: 1 })}
                </span>
                <Dropdown
                  name="location"
                  value={locationName as string}
                  content={allLocations}
                  onChange={(e: any) => {
                    setLocation((e.target as HTMLInputElement).value);
                    // history.push(`/distribution-centers/${e.target.value.slug}`);
                  }}
                  lastSelected={allClusters ? clusterName : subzoneName}
                />
              </div>
            )
          }
        </div>
      ) : (
        showSearchbar && <SearchBar />
      )}
      <div className="icons">
        {canAccessGlobalLocationSelector && <GlobalSearch />}
        {canAccessGlobalLocationSelector
          && <RefreshButton {...{ enableResetFiltersButton, setBusinessUnit }} />}
        {showSettings && (
          <>
            <div className="line" />
            <Settings userType={userType} />
          </>
        )}
      </div>
    </div>
  );
};

function mapStateToProps(state: any) {
  return {
    businessUnitName: state.getIn(['globalFilters', 'businessUnit', 'name']),
    zoneName: state.getIn(['globalFilters', 'zone', 'name']),
    allZones: sortBy(state.getIn(['globalFilters', 'allZones'])?.toJS() || [], 'name'),
    subzoneName: state.getIn(['globalFilters', 'subzone', 'name']),
    allSubzones: sortBy(state.getIn(['globalFilters', 'allSubzones'])?.toJS() || [], 'name'),
    locationName: state.getIn(['globalFilters', 'location', 'name']),
    allLocations: sortBy(state.getIn(['globalFilters', 'allLocations'])?.toJS() || [], 'name'),
    analyticsFilters: sortBy(state.getIn(['globalFilters', 'analyticsFilters', 'businessUnits'])?.toJS() || [], 'name'),
    userType: state.getIn(['user', 'type']),
    clusterName: state.getIn(['globalFilters', 'cluster', 'name']),
    allClusters: sortBy(state.getIn(['globalFilters', 'allClusters'])?.toJS() || [], 'name')
    ,
  };
}

function mapDispatchToProps(dispatch: any) {
  return bindActionCreators({
    setBusinessUnit,
    setZone,
    setAllZones,
    setSubzone,
    setAllSubzones,
    setLocation,
    setAllLocations,
    getAnalyticsFilters,
    updateAnalyticsLocations,
    setCluster,
    setAllClusters,
  }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(Header);
