/* eslint-disable import/prefer-default-export */
/* eslint-disable @typescript-eslint/no-unused-vars */
import axios from 'axios';
import uuidv4 from 'uuid/v4';
import { cloneDeep } from 'lodash';
import { Company } from 'generated/graphql';
import {
  Actions,
  ChecklistBitTypes,
  ChecklistSection,
  LocalLogic,
  LocalLogicTrigger,
  showIf,
  showIfOperators,
} from '../types';
import { ABI_COMPANY_ID } from '../../../constants';

export const getSectionAndItemIndex = (key: string) => {
  const regex = /\[(\d+)\]/g;
  const matches = key.match(regex) || [];
  const indexes: string[] = matches.map(match => match.replace(/\D/g, ''));

  return {
    sectionIndex: Number(indexes[0]),
    itemIndex: Number(indexes[1]),
    bitIndex: Number(indexes?.[2]),
    count: Number(indexes?.[3]),
  };
};

export async function requestImageUploadPermission(imageExtension: string, target: string) {
  try {
    const url = '/api/v1/image-upload';
    /*
    image validation on server side is checking if file extension is in between 3 to 4 length
    so we are splitting it in the next line of code and if file extension is not
    available the we are hard coding the image type
    */
    let fileExtension = 'jpeg';
    if (imageExtension.includes('/')) {
      [, fileExtension] = imageExtension.split('/');
    }
    return await axios.post(url, {
      fileExtension,
      target,
    });
  } catch (e) {
    console.log('e requestImageUploadPermission(): ', (e as Error).message || e);
  }
}

export async function uploadObjectImage(presignedUrl: string, uri: string) {
  try {
    const url = presignedUrl;

    const img = await fetch(uri);
    const blob = await img.blob();

    const response = await fetch(url, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/octet-stream',
      },
      body: blob,
    });

    if (response && response.status >= 200 && response.status <= 299) {
      console.log('uploadImage() uploaded image to S3 successfully');
      /*
      returning the parsed url
      (eg -https://arvolution-safety-development.s3.us-east-2.amazonaws.com/ACTION_LOG/c792a13b-e153-49ca-baa6-1e25d331dafa.jpeg )
      received in response from S3
      as when we created the manual url with function
      getImageUrlForS3 (removed after discussion), it
      is not accessible globally because it lack time zone
      */
      return response.url.split('?')[0];
    }
  } catch (e) {
    console.log('e uploadImage(): ', (e as Error).message || e);
  }
}

export const mergeLocalLogicAndSections = (
  localLogics: { [key: number]: LocalLogic[] },
  localLogicTriggers: LocalLogicTrigger[],
  sections: ChecklistSection[],
  globalEmailRecipients: string[],
  multiTriggerOptions: showIfOperators[],
  captureSignatureBeforeSubmit: boolean,
) => {
  // console.log('\n\nMERGING!! \n\n', localLogics);
  const copySections = [...sections];
  /**
   * Delete all the stale logics from the checklist and add new logics from localLogics
   */
  copySections.forEach(section => {
    section.items.forEach(item => {
      if (item.bits[0].type !== ChecklistBitTypes.SIGNATURE) {
        delete item.showIf;
      }
      if (item.bits[0].type === ChecklistBitTypes.SIGNATURE
        && !item.bits[0].props.hideQuestionUntilSectionAnswered
      ) {
        delete item.showIf;
      }
      delete item.communication;
      delete item.bits[0].additionalFeatures?.triggerActionLog;
    });
  });

  let mergedLogicAndTriggers = [];
  // console.log({ localLogics });

  mergedLogicAndTriggers = Object.keys(localLogics).map((logicKey, index) => {
    const logic = localLogics[Number(logicKey)];
    // console.log({ logic });
    const additionalCondition = logic.length > 1 ? multiTriggerOptions[Number(logicKey)] : null;
    // console.log({ multiTriggerOptions, additionalCondition });
    if (logic.length > 1) {
      const logics = logic.filter(l => l.sectionIndex > -1 && l.itemIndex > -1 && l.operation);
      return {
        operator: additionalCondition,
        key: `$checklist[${localLogicTriggers[index].sectionIndex}].items[${localLogicTriggers[index].itemIndex}].bits[0]`,
        value: localLogicTriggers[index].trigger,
        triggers: [localLogicTriggers[index]],
        values: logics.map(l => ({
          operator: l.operation,
          key: `$checklist[${l.sectionIndex}].items[${l.itemIndex}].bits[0]`,
          value: l.value,
        })),
      };
    }
    return {
      ...logic[0],
      triggers: [localLogicTriggers[index]],
    };
    // ...logic,
    // triggers: [localLogicTriggers[index]],
  });

  // console.log({ mergedLogicAndTriggers });

  const filterdLocalLogics = mergedLogicAndTriggers.filter((l: any) => {
    if (![showIfOperators.and, showIfOperators.or].includes(l.operator as showIfOperators)) {
      return l.sectionIndex > -1 && l.itemIndex > -1 && l.operation;
    }
    return l;
  });
  // console.log({ filterdLocalLogics });
  filterdLocalLogics.forEach((logic: any, id) => {
    console.log('Match!', logic.operator);
    const logicCopy = cloneDeep(logic);
    delete logicCopy.triggers;
    // delete logic.triggers;
    if ([showIfOperators.and, showIfOperators.or].includes(logic.operator as showIfOperators)) {
      // console.log('Match!');
      if (logic.value as Actions === Actions.SHOW_ANSWER) {
        copySections[logic.triggers[0].sectionIndex].items[logic
          .triggers[0].itemIndex].showIf = logicCopy;
      }
      if (logic.value as Actions === Actions.SEND_EMAIL
        && logic.triggers[0]?.recipient?.length > 0) {
        logicCopy.recipient = logic.triggers[0]?.recipient;
        logic.values?.map((l: any) => {
          const {
            sectionIndex: si,
            itemIndex: ii,
          } = getSectionAndItemIndex(l.key);
          copySections[si].items[ii].communication = logicCopy;
        });
      }
      if (logic.value as Actions === Actions.TRIGGER_ACTION_LOG) {
        logic.values?.map((l: any) => {
          const {
            sectionIndex: si,
            itemIndex: ii,
          } = getSectionAndItemIndex(l.key);
          copySections[si].items[ii].bits[0]
            .additionalFeatures = {
              allowsNote: Boolean(copySections[si].items[ii].bits[0]
                .additionalFeatures?.allowsNote),
              note: '',
              triggerActionLog: {
                operator: logicCopy.operator,
                value: logicCopy.value,
                values: logicCopy.values as showIf[],
              },
            };
        });
      }
    } else {
      logic.triggers?.forEach((logicTrigger: any) => {
        if (
          logicTrigger.trigger === Actions.SHOW_ANSWER
          && copySections[logicTrigger?.sectionIndex]?.items[logicTrigger?.itemIndex]
        ) {
          copySections[logicTrigger.sectionIndex].items[logicTrigger.itemIndex].showIf = {
            operator: logic.operation,
            value: logic.value,
            key: `$checklist[${logic.sectionIndex}].items[${logic.itemIndex}].bits[0]`,
          };
        }
        if (
          logicTrigger.recipient?.length
          && logicTrigger.trigger === Actions.SEND_EMAIL
          && copySections[logicTrigger?.sectionIndex]?.items[logicTrigger?.itemIndex]
        ) {
          copySections[logicTrigger.sectionIndex].items[logicTrigger.itemIndex].communication = {
            operator: logic.operation,
            value: logic.value,
            key: `$checklist[${logic.sectionIndex}].items[${logic.itemIndex}].bits[0]`,
            recipient: logicTrigger.recipient,
          };
        }
        if (
          logicTrigger.trigger === Actions.TRIGGER_ACTION_LOG
          && copySections[logic?.sectionIndex]?.items[logic?.itemIndex]
        ) {
          copySections[logic.sectionIndex
          ].items[logic.itemIndex]!.bits[0].additionalFeatures = {
            ...copySections[logic.sectionIndex
            ].items[logic.itemIndex]!.bits[0].additionalFeatures,
            triggerActionLog: {
              operator: logic?.operation,
              value: logic?.value,
            },
          } as any;
        }
      });
    }
  });
  copySections[0] = {
    ...copySections[0],
    captureSignatureBeforeSubmit,
    recipients: globalEmailRecipients,
  };
  // console.log({ copySections });
  return copySections;
};

const localLogicHasCurrentIterationDetails = (
  logicsAlreadyConsidered: showIf[],
  currentIterationLogic: showIf,
) => {
  const { key, value, values, operator } = currentIterationLogic;
  const currentLogicHasValuesKey = Array.isArray(values);
  let isSameAvailable = false;

  logicsAlreadyConsidered.forEach(l => {
    const { key: savedKey, operator: savedOperator, value: savedValue, values: savedValues } = l;
    if (key === savedKey
      && savedOperator === operator
      && value === savedValue) {
      const hasValuesKey = Array.isArray(savedValues);

      if (hasValuesKey && currentLogicHasValuesKey) {
        isSameAvailable = savedValues!.every((s, i) => {
          if (s.key === values![i].key
            && values![i].operator === s.operator
            && s.value === values![i].value) return true;
          return false;
        });
      }
    }
  });
  console.log({ isSameAvailable });
  return isSameAvailable;
};

export const getLocalLogicFromSections = (
  sections: ChecklistSection[],
) => {
  const copySections = [...sections];
  let localLogics: { [key: number]: LocalLogic[] } = {};
  const localLogicTriggers: LocalLogicTrigger[] = [];
  const multiTriggerOptions: showIfOperators[] = [];
  const globalEmailRecipients: string[] | undefined = copySections[0].recipients;
  const captureSignatureBeforeSubmit: boolean = copySections[0]
    ?.captureSignatureBeforeSubmit || false;

  let idxCount = 0;
  const logicsAlreadyConsidered: showIf[] = [];
  copySections.forEach((section, sectionIndex) => {
    let isQuestionUsed = false;
    section.items.forEach((item, itemIndex) => {
      let currentLocalLogic = localLogics[idxCount];
      if (!Array.isArray(currentLocalLogic)) {
        localLogics = {
          ...localLogics,
          [idxCount]: [],
        };
      }
      currentLocalLogic = localLogics[idxCount];

      // SHOW IF
      if (item.showIf?.key) {
        if (!item.bits[0].props?.hideQuestionUntilSectionAnswered) {
          const { value, values, key, operator } = item.showIf;
          const hasValuesKey = Array.isArray(values);

          const hasSameCondition = localLogicHasCurrentIterationDetails(
            logicsAlreadyConsidered,
            item.showIf,
          );
          if (!hasSameCondition) {
            localLogicTriggers.push({
              itemIndex,
              bitIndex: 0,
              sectionIndex,
              recipient: [],
              trigger: Actions.SHOW_ANSWER,
            });
            // idxCount = localLogicTriggers.length;
          }
          if (hasValuesKey) {
            if (!hasSameCondition) {
              if (isQuestionUsed) {
                idxCount += 1;
                localLogics = {
                  ...localLogics,
                  [idxCount]: [],
                };
                currentLocalLogic = localLogics[idxCount];
              }
              isQuestionUsed = true;
              values?.forEach(v => {
                currentLocalLogic.push({
                  id: uuidv4(),
                  value: v.value,
                  operation: v.operator as showIfOperators,
                  itemIndex: getSectionAndItemIndex(v.key).itemIndex,
                  sectionIndex: getSectionAndItemIndex(v.key).sectionIndex,
                });
              });

              multiTriggerOptions.push(operator as showIfOperators);
              logicsAlreadyConsidered.push(item.showIf);
            }
          } else {
            if (isQuestionUsed) {
              idxCount += 1;
              localLogics = {
                ...localLogics,
                [idxCount]: [],
              };
              currentLocalLogic = localLogics[idxCount];
            }
            isQuestionUsed = true;
            currentLocalLogic.push({
              value,
              id: uuidv4(),
              operation: operator as showIfOperators,
              itemIndex: getSectionAndItemIndex(key).itemIndex,
              sectionIndex: getSectionAndItemIndex(key).sectionIndex,
            });
          }
        }
      }
      const hasAdditionalFeat = 'additionalFeatures' in item.bits[0];

      // AXN LOG
      if (hasAdditionalFeat && 'triggerActionLog' in (item.bits[0]?.additionalFeatures ?? {})) {
        const triggerActionLogJSON = item.bits[0]?.additionalFeatures?.triggerActionLog;
        const hasValuesKey = Array.isArray(triggerActionLogJSON?.values);
        const hasSameCondition = localLogicHasCurrentIterationDetails(
          logicsAlreadyConsidered,
          triggerActionLogJSON as showIf,
        );
        if (!hasSameCondition) {
          localLogicTriggers.push({
            trigger: Actions.TRIGGER_ACTION_LOG,
            sectionIndex,
            itemIndex,
            bitIndex: 0,
            recipient: [],
          });
          // idxCount = localLogicTriggers.length;
        }
        if (hasValuesKey) {
          if (!hasSameCondition) {
            if (isQuestionUsed) {
              idxCount += 1;
              localLogics = {
                ...localLogics,
                [idxCount]: [],
              };
              currentLocalLogic = localLogics[idxCount];
            }
            isQuestionUsed = true;
            triggerActionLogJSON?.values?.forEach(v => {
              currentLocalLogic.push({
                id: uuidv4(),
                sectionIndex: getSectionAndItemIndex(v.key).sectionIndex,
                itemIndex: getSectionAndItemIndex(v.key).itemIndex,
                operation: v.operator as showIfOperators,
                value: v.value,
              });
            });

            multiTriggerOptions.push(triggerActionLogJSON?.operator as showIfOperators);
            logicsAlreadyConsidered.push(triggerActionLogJSON as showIf);
          }
        } else {
          if (isQuestionUsed) {
            idxCount += 1;
            localLogics = {
              ...localLogics,
              [idxCount]: [],
            };
            currentLocalLogic = localLogics[idxCount];
          }
          isQuestionUsed = true;
          currentLocalLogic.push({
            id: uuidv4(),
            sectionIndex,
            itemIndex,
            operation: item.bits[0]?.additionalFeatures
              ?.triggerActionLog?.operator as showIfOperators,
            value: item.bits[0]?.additionalFeatures?.triggerActionLog?.value,
          });
        }
      }

      // SEND MAIL
      if (item.communication?.recipient.length) {
        const communicationJSON = item.communication;
        const hasValuesKey = Array.isArray(communicationJSON.values);
        const hasSameCondition = localLogicHasCurrentIterationDetails(
          logicsAlreadyConsidered,
          communicationJSON as showIf,
        );
        if (!hasSameCondition) {
          localLogicTriggers.push({
            trigger: Actions.SEND_EMAIL,
            sectionIndex,
            itemIndex,
            bitIndex: 0,
            recipient: item.communication.recipient,
          });
          // idxCount = localLogicTriggers.length;
        }
        if (hasValuesKey) {
          if (!hasSameCondition) {
            if (isQuestionUsed) {
              idxCount += 1;
              localLogics = {
                ...localLogics,
                [idxCount]: [],
              };
              currentLocalLogic = localLogics[idxCount];
            }
            isQuestionUsed = true;
            communicationJSON.values?.forEach(v => {
              console.log({ currentLocalLogic });
              currentLocalLogic.push({
                id: uuidv4(),
                sectionIndex: getSectionAndItemIndex(v.key).sectionIndex,
                itemIndex: getSectionAndItemIndex(v.key).itemIndex,
                operation: v.operator as showIfOperators,
                value: v.value,
              });
            });

            multiTriggerOptions.push(communicationJSON.operator as showIfOperators);
            logicsAlreadyConsidered.push(communicationJSON as showIf);
          }
        } else {
          console.log({ currentLocalLogic });
          if (isQuestionUsed) {
            idxCount += 1;
            localLogics = {
              ...localLogics,
              [idxCount]: [],
            };
            currentLocalLogic = localLogics[idxCount];
          }
          isQuestionUsed = true;
          currentLocalLogic.push({
            id: uuidv4(),
            sectionIndex: getSectionAndItemIndex(item.communication.key).sectionIndex,
            itemIndex: getSectionAndItemIndex(item.communication.key).itemIndex,
            operation: item.communication.operator as showIfOperators,
            value: item.communication.value,
          });
        }
      }
      if (localLogics[idxCount].length === 0) {
        delete localLogics[idxCount];
      } else {
        idxCount += 1;
      }
    });
  });
  return {
    localLogics,
    localLogicTriggers,
    multiTriggerOptions,
    globalEmailRecipients,
    captureSignatureBeforeSubmit,
  };
};

export const getChecklistHash = (
  {
    sectionIndex,
    itemIndex,
    bitIndex,
    completedChecklists,
    prefilledIndex,
  }: {
    sectionIndex: number,
    itemIndex: number,
    bitIndex: number,
    completedChecklists: {name: string, id: number}[]
    prefilledIndex?: number
  },
) => {
  if (typeof prefilledIndex === 'number') {
    return `checklist[${sectionIndex}].items[${itemIndex}].bits[${bitIndex}].count[${prefilledIndex + 1}]`;
  }

  let count = 1;
  if (completedChecklists && completedChecklists.length) {
    count = completedChecklists.length + 1;
  }

  return `checklist[${sectionIndex}].items[${itemIndex}].bits[${bitIndex}].count[${count}]`;
};

export const userBelongsToABI = (companyData: Company) => {
  return companyData?.name === 'ABI Supply' && Number(companyData?.id) === ABI_COMPANY_ID;
};
