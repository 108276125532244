import LoadingSpinner from 'components/LoadingSpinner';
import { useGetListItemsFromSearchInputLazyQuery } from 'generated/graphql';
import { fromJS } from 'immutable';
import { debounce } from 'lodash';
import React, { CSSProperties, useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { TRANSLATIONS } from 'types/enums';
import { ReactComponent as DropIcon } from '../../../assets/icons/drop.svg';
import OnClickDropdown from '../OnClickDropdown';
import './SelectGroupDropdown.scss';

export type SelectDropdownPropsListItem = {
  name: string;
  value: string | Object;
  onChange(e: any): void;
  placeholder?: string;
  className?: string;
  inputStyle?: CSSProperties;
  dropDownStyle?: CSSProperties;
  dropDownClassName?: string;
  dropdownWrapperClassName?: string;
  dropdownElementsWidth?: any;
  selectWidth?: number;
  highlightSelected?: boolean;
  valueStyle?: any;
  placeHolderStyle?: any;
  disabled?: boolean;
  bgColor?: string;
  icon?: string;
  props: any
};

const SelectGroupDropdownListItem = ({
  value,
  onChange, // Function that executes when one of the select options is clicked
  placeholder, // Text that will appear on the Select when there is no selected value
  // className, // Classname for the OnClickDropdown className prop
  inputStyle, // Style for the OnClickDropdown style prop
  dropdownElementsWidth,
  selectWidth, // Width of the OnClickDropdown children prop
  highlightSelected = false, // When set to true, displays a blue dot
  dropDownStyle,
  dropDownClassName,
  // clasName of the dropdown wrapper
  dropdownWrapperClassName,
  valueStyle,
  placeHolderStyle,
  disabled = false,
  bgColor,
  icon,
  props,
  // on the Select option that is currently the Select value
}: SelectDropdownPropsListItem) => {
  const createEvent = (
    element: any,
    e: any,
    toggleDropDownVisibility: Function,
  ) => {
    // Because we are not using default select element, the event lacks a name and a value.
    // We add them here.
    onChange(element);
    toggleDropDownVisibility();
  };

  const [searchInput, setSearchInput] = useState<string>('');
  const { t } = useTranslation();

  const [getListItems, listContent] = useGetListItemsFromSearchInputLazyQuery();

  const searchItems = (searchValue:any) => {
    if (searchValue) {
      getListItems({
        variables: {
          searchInput: searchValue,
          listShortSlugs: props?.shortSlug,
        },
      });
    }
  };
  const debouncedHandleSearch = useCallback(debounce(searchItems, 1000), []);

  const renderDropDownContent = (toggleDropDownVisibility: Function) => {
    const dropdownContentValidation = !!(fromJS(listContent?.data?.
      getListItemsFromSearchInput)?.toJS());
    return (
      <div className={dropDownClassName}>
        <input
          value={searchInput}
          placeholder={`${t(TRANSLATIONS.SEARCH_QUESTION_TYPE)}`}
          style={{ height: '48px',
            padding: '12px 16px',
            border: '1px solid #DEDEDE',
            width: '96%',
            borderRadius: '8px',
            margin: '16px 0px 16px 16px',
            fontSize: 16,
            fontWeight: 400 }}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
            debouncedHandleSearch(e.target.value);
            setSearchInput(e.target.value);
          }}
        />

        {dropdownContentValidation ? (
          listContent?.data?.getListItemsFromSearchInput?.map((element: any) => (
            <div
              className={`option ${element.className || ''}`}
              key={element.name}
              role="button"
              onClick={(e: any) => createEvent(element, e, toggleDropDownVisibility)}
            >
              <p className="label">{element.name}</p>
              {highlightSelected && (element.value === value)
                ? (
                  <div
                    style={{
                      width: 10,
                      height: 10,
                      backgroundColor: '#2574FB',
                      borderRadius: 50,
                    }}
                  />
                )
                : ''}
            </div>
          ))

        ) : listContent.loading ? <LoadingSpinner height={30} style={{ marginBottom: 20 }} /> : (
          <p style={{ width: '100%', textAlign: 'center', paddingBottom: 8 }}>
            Search for assets
          </p>
        )}

      </div>
    );
  };

  const currentValue = listContent?.data?.
    getListItemsFromSearchInput?.find((el: any) => el.value === value);

  return (
    <OnClickDropdown
      disabled={disabled}
      className={dropdownWrapperClassName}
      dropdownContent={renderDropDownContent}
      inputStyle={inputStyle}
      dropDownStyle={dropDownStyle}
      dropdownElementsWidth={dropdownElementsWidth}
    >
      <div
        className={`select ${disabled ? 'disabled' : ''}`}
        style={{
          width: selectWidth,
          color: value === '' ? '#A6A6A6' : '#000',
          backgroundColor: bgColor,
        }}
      >
        {icon && <img src={icon} alt="left icon" />}
        <p
          style={{
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
            color: '#808080',
            maxWidth: selectWidth ? selectWidth - 15 : '95%',
            ...(currentValue ? valueStyle : placeHolderStyle),
            marginRight: 8,
          }}
        >
          {currentValue ? currentValue.name : placeholder}
        </p>
        <DropIcon />
      </div>
    </OnClickDropdown>
  );
};

export default SelectGroupDropdownListItem;
