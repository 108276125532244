import React, { useMemo } from 'react';
import ReactApexChart from 'react-apexcharts';

type Props = {
  usedBudget: number[];
  differenceBudget: number[];
  countries: String[];
  type: string;
  max?: number;
};
const CapexBars = (props: Props) => {
  const series = [{
    name: 'Ejercido',
    data: props.usedBudget,
  }, {
    name: 'Pendiente',
    data: props.differenceBudget,
  }];

  const options = useMemo(() => (
    {
      chart: {
        type: 'bar',
        height: 350,
        stacked: true,
        toolbar: {
          show: false,
        },
        zoom: {
          enabled: false,
        },
      },
      colors: ['#5572B9', '#A33223'],
      responsive: [{
        breakpoint: 480,
        options: {
          legend: {
            position: 'bottom',
            offsetX: -10,
            offsetY: 0,
          },
        },
      }],
      plotOptions: {
        bar: {
          horizontal: false,
          borderRadius: 10,
        },
      },
      dataLabels: {
        enabled: true,
        formatter: (val : number) => val.toLocaleString(),
      },
      xaxis: {
        categories: props.countries,
        labels: {
          trim: true,
          rotate: -50,
          style: {
            fontSize: props.countries.length < 7 ? '14px' : '8px',
          },
        },
      },
      yaxis: {
        max: props?.max,
        labels: { formatter: (v:number) => Math.trunc(v).toLocaleString() },
      },
      legend: {
        position: 'top',
        offsetY: 5,
        offsetX: 240,
      },
      fill: {
        opacity: 1,
      },
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  ), []);
  return (
    <ReactApexChart options={options} series={series} type="bar" height={350} />
  );
};

export default CapexBars;
