export type DateFormat = {
  year?: number,
  month?: number,
  day?: number
};

export interface NestedChecklistAnswerMap {
  [key: string]: ChecklistAnswers;
}

export enum ChecklistBitTypes {
  CUSTOM = 'CUSTOM',
  INPUT = 'INPUT',
  DROPDOWN = 'DROPDOWN',
  CHECKLIST = 'CHECKLIST',
  HORIZONTAL_CHECKLIST = 'HORIZONTAL_CHECKLIST',
  VERTICAL_SINGLE_OPTION = 'VERTICAL_SINGLE_OPTION',
  HORIZONTAL_SINGLE_OPTION = 'HORIZONTAL_SINGLE_OPTION',
  CAROUSEL = 'CAROUSEL',
  COUNTER = 'COUNTER',
  TEXT = 'TEXT',
  SECTION = 'SECTION',
  DATE = 'DATE',
  LIST_OF_ITEMS = 'LIST_OF_ITEMS',
  BODY_BOLD = 'BODY_BOLD',
  BODY20 = 'BODY20',
  H2 = 'H2',
  VERTICAL_MULTIPLE_OPTION = 'VERTICAL_MULTIPLE_OPTION',
  TIME = 'TIME',
  DRIBBLE = 'DRIBBLE',
  SIGNATURE = 'SIGNATURE',
  IMAGE_PICKER = 'IMAGE_PICKER',
  NESTED_CHECKLIST = 'NESTED_CHECKLIST',
  LIST_ITEM = 'LIST_ITEM',
  ACK = 'ACK',
}

/**
 * Add bit in bitsShouldNotBeConsideredTowardsProgress list if it's not for progress
 */
export const bitsShouldNotBeConsideredTowardsProgress = [
  ChecklistBitTypes.CAROUSEL,
  // ChecklistBitTypes.TEXT,
  ChecklistBitTypes.SECTION,
  ChecklistBitTypes.LIST_OF_ITEMS,
  ChecklistBitTypes.BODY_BOLD,
  ChecklistBitTypes.BODY20,
  ChecklistBitTypes.H2,
  ChecklistBitTypes.ACK,
];

export enum showIfAccesors {
  checklist = '$checklist',
  items = '$items',
  previousItem = '$previousItem',
  bits = '$bits',
  previousBit = '$previousBit',
}

export enum showIfOperators {
  equals = 'equals',
  and = 'and',
  or = 'or',
  notEmpty = 'notEmpty',
  empty = 'empty',
  greaterThan = 'greaterThan',
  lessThan = 'lessThan'
}

export type showIf = {
  operator: keyof typeof showIfOperators;
  key: string;
  /** Prefix: $checklists[0], $items, $bits, $previousBit */
  value?: any;
  values?: showIf[];
};
export enum SIZE {
  SM = 'sm',
  MD = 'md',
  LG = 'lg',
}
export type ChecklistBit = {
  drawingHistoryLength: number;
  otherInputValue?: string;
  type: ChecklistBitTypes;
  /** default = true. If it should count towards progress indicator */
  countsTowardProgress?: boolean;
  /**
   * Indirect props not for use
   */
  shouldBitProgressConsidered?: boolean;
  isRendered?: boolean;
  capturedSigneeName?: string;
  additionalFeatures?: {
    allowsNote: boolean;
    note: string;
    triggerActionLog?: showIf;
  };
  deviceTimezone?: string;
  /** */
  props: {
    enableParagraphInput: any;
    autoRemoveWhenSectionTampered?: boolean;
    boundaryValues?: {
      min: number;
      max: number;
    };
    hideQuestionUntilSectionAnswered?: boolean;
    showInputForSigneeName?: boolean;
    enableCameraCapture: boolean;
    placeholder?: string;
    instructionType?: any;
    keyboardType?: 'numeric' | 'default';
    numericType?: 'integer' | 'decimal';
    selectedAssetType?: {
      name: string;
      shortSlug: string;
      slug: string;
      id: number;
      // items is to hold assets for offline support
      items: any[];
    };
    text?: string;
    title?: string;
    description?: string;
    subTitle?: string;
    /**
     * Counter/Stepper props
     */
    counterSize?: SIZE;
    step?: number;
    minimumValue?: number;
    toggleKeyboardOnPress?: boolean;
    onSubmitEditing?: (val: number) => any;
    onIncrementPress?: (val: number) => any;
    showOthersOption?: boolean;
    options?: {
      title: string;
      description?: string;
    }[];
    /**
     * Carousel Props
     */
    images?: {
      url: string;
    }[];
    autoplay?: boolean;
    /**
     * List_OF_ITEMS props
     */
    head?: string;
    listItems?: {
      iconUrl: string;
      text: string;
    }[];
    /**
     * dateTimeProps
     */
    dateTimeProps?: any;
    minimumDate?: DateFormat;
    maximumDate?: DateFormat;
    /**
     * operational props
     */
    isNAMaster?: boolean;
    parentKey?: string;
    blueprint?: string;
    content?: {
      checklist?: ChecklistSection[]
    }
    instructionImage?: string;
  };
  /**
   * Provide correct answer
   * for custom colors in case of a already answered checklist
   */
  correctAnswer?: any;
  answer?: any;
  defaultAnswer?: any;
  showIf?: showIf;
};

export type dividerOptions = 'off' | 'medium';

export type ChecklistItem = {
  title: string;
  description: string;
  key: string;
  required: boolean;
  bits: ChecklistBit[];
  showIf?: showIf;
  divider?: dividerOptions;
  completedNames: {
    name: string;
    id: number;
  }[];
  canBeRepeated: boolean;
};

export type ChecklistSection = {
  title: string;
  description: string;
  key: string;
  isExpandedDefault?: boolean;
  captureSignatureBeforeSubmit?: boolean;
  authorisedSignature?: {
    signeeName: string;
    signaturePath: string;
  };
  items: ChecklistItem[];
  sectionProgressVisible?: boolean;
  showIf?: showIf;
  styles?: {
    sectionHeaderActive?: string;
    sectionHeaderInActive?: string;
    progressActive?: string;
    progressInActive?: string;
  };
};

export type CustomComponentValues = {
  props: any;
  answer: any;
  /** sibling questions * */
  questions: ChecklistBit[];
};

export type SetAnswer = (answer: any) => void;
export type ChecklistAnswers = any[][][];

export type AnsweredChecklistConfig = {
  correctAnswerContainerStyles?: any;
  wrongAnswerContainerStyles?: any;
  correctAnswerTextStyles?: any;
  wrongAnswerTextStyles?: any;
};
export type ChecklistProps = {
  setChecklist?: any;
  checklist: ChecklistSection[];
  getDefaultNoteValue?: () => string | undefined;
  answerMode?: 'work_order' | 'checklist';
  customBits?: (
    type: string,
    answer: any,
    setAnswer: SetAnswer,
    bit: ChecklistBit,
    sectionIndex: number,
    bitIndex: number,
    itemIndex: number,
  ) => JSX.Element | void;
  /** Undefined counts as not handled */
  customBitsInitializer?: (type: string, bit: ChecklistBit) => any;
  customBitsIsPending?: (type: string, answer: any, props: any) => boolean | void;
  customBitProgressOverride?: {
    bitType: string;
    shouldProgressConsidered: (bit: ChecklistBit) => boolean;
  }[];
  header?: any;
  footer?: any;
  setAnswers: (answers: ChecklistAnswers, sectionIndex?: number, questionIndex?: number) => void;
  getAnswersAndAnswerableQuestions?: (checklistSection: ChecklistSection[]) => void;
  styles?: {
    sectionHeaderActive?: string;
    sectionHeaderInActive?: string;
    progressActive?: string;
    progressInActive?: string;
  };
  sectionalProgress?: (arr: any) => void;
  answers?: ChecklistAnswers | undefined;
  showNotes?: (sIndex: number, iIndex: number) => void;
  /**
   * pass this as true if the checklist is already answered
   */
  isChecklistAnswered?: boolean;
  setOtherOptionValueForMultipleChoiceQuestions?: (
    val: string,
    sectionIndex: number,
    itemIndex: number,
  ) => void;
  answeredChecklistConfig?: AnsweredChecklistConfig
  considerNestedChecklistProgress?: boolean;
  selectedNestedChecklistAnswers?: any;
  assetNetworkRequest?: any;
  handleRedirectToActionLog?: () => void;
};

export type RenderbitsProps = {
  bits: ChecklistBit[];
  answers: any;
  bitAnswers: any[];
  setAnswer: (index: number, answer: any) => void;
  customBits?: ChecklistProps['customBits'];
  sectionIndex: number;
  itemIndex: number;
  setBulkAnswers: (updatedAnswers: any) => void;
  checklist: ChecklistSection[];
  isChecklistAnswered?: boolean;
};

export enum ACK_TYPE {
  INFO = 'info',
  SUCCESS = 'success',
  ERROR = 'error',
}
