import React from 'react';
import { COLOR_CODE, COLOR_CODE_NEW } from 'types/enums';
import ImageLinks from 'utils/ImageLinks';
import './AcknowledgementBit.scss';
import { ACK_TYPE } from 'features/Espectro/types';

type Props = {
  type: ACK_TYPE;
  text: string;
};

function AcknowledgementBit({ type, text }: Props) {
  const getIcon = () => {
    switch (type) {
      case ACK_TYPE.INFO:
        return ImageLinks.espectro.infoBlue;

      case ACK_TYPE.ERROR:
        return ImageLinks.espectro.danger;

      case ACK_TYPE.SUCCESS:
        return ImageLinks.espectro.successCheck;
      default:
        break;
    }
  };

  return (
    <div className="acknowledgementBitContainer">
      <img src={getIcon()} alt="ack icon" height={16} width={16} />
      <span style={{
        color: type === ACK_TYPE.ERROR ? COLOR_CODE.RED : COLOR_CODE_NEW.BLACK,
      }}
      >
        {text}
      </span>
    </div>
  );
}

export default AcknowledgementBit;
