/* eslint-disable react/no-array-index-key */
import React, { useState } from 'react';
import { withStyles, createStyles, makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import ArrowUpwardIcon from '@material-ui/icons/ArrowDropUp';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDropDown';

type RowData = {
  rowData: any[];
  index: string;
};

type CustomTableProps = {
  header: string[];
  onClickRow: (id: string) => void;
  rows: RowData[];
  sortableColumns?: number[]; // Array of column indices that are sortable
};

const StyledTableCell = withStyles(() => createStyles({
  head: {
    backgroundColor: '#fff',
    color: '#404040',
    cursor: 'pointer',
    padding: '4px',
    fontFamily: 'Jost',
    border: 'none',
  },
  body: {
    fontSize: 14,
    padding: '4px',
    fontFamily: 'Jost',
  },
}))(TableCell);

const StyledTableCellHeader = withStyles(() => createStyles({
  head: {
    backgroundColor: '#fff',
    color: '#404040',
    padding: '4px',
    border: 'none',
  },
  body: {
    fontSize: 17,
    padding: '4px',
  },
}))(TableCell);

const StyledTableRow = withStyles(() => createStyles({
  root: {
    '&:hover': {
      backgroundColor: '#FAFAFA',
      cursor: 'pointer',
    },
    height: '40px', // Set a strict height for each row
  },
}))(TableRow);

const useStyles = makeStyles({
  table: {
    overflowY: 'scroll',
  },
  arrowIcon: {
    fontSize: '2em',
    marginLeft: '0.2em',
  },
  cell: {
    flexDirection: 'row',
    alignItems: 'center',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    maxWidth: '200px',
  },
});

const CustomTable: React.FC<CustomTableProps> = (
  { header, rows, onClickRow, sortableColumns = [] },
) => {
  const classes = useStyles();
  const [sortConfig, setSortConfig] = useState<{ key: string, direction: 'ascending' | 'descending' | null }>({ key: '', direction: 'ascending' });

  const sortedRows = [...rows].sort((a, b) => {
    if (!sortConfig.key || sortConfig.direction === null) {
      return 0;
    }
    const columnIdx = Number(sortConfig.key);
    if (sortConfig.direction === 'ascending') {
      if (a.rowData[columnIdx] < b.rowData[columnIdx]) return -1;
      if (a.rowData[columnIdx] > b.rowData[columnIdx]) return 1;
      return 0;
    }
    if (sortConfig.direction === 'descending') {
      if (a.rowData[columnIdx] > b.rowData[columnIdx]) return -1;
      if (a.rowData[columnIdx] < b.rowData[columnIdx]) return 1;
      return 0;
    }
    return 0;
  });

  const requestSort = (key: string) => {
    let direction: 'ascending' | 'descending' | null = 'ascending';
    if (sortConfig.key === key && sortConfig.direction === 'ascending') {
      direction = 'descending';
    }
    setSortConfig({ key, direction });
  };

  const renderHeaderCells = () => {
    return header.map((headerLabel, index) => {
      const isSortable = sortableColumns.includes(index);
      const isSortingColumn = sortConfig.key === String(index);
      const arrowIcon = () => {
        if (isSortable && isSortingColumn) {
          return sortConfig.direction === 'ascending' ? <ArrowUpwardIcon className={classes.arrowIcon} /> : <ArrowDownwardIcon className={classes.arrowIcon} />;
        }
        return null;
      };
      return (
        <StyledTableCellHeader
          key={headerLabel}
          onClick={() => {
            if (sortableColumns.includes(index)) {
              requestSort(String(index));
            }
          }}
        >
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              paddingTop: '8px',
              paddingBottom: '8px',
              fontSize: 16,
              fontWeight: 700,
              fontFamily: 'Jost',
            }}
          >
            {headerLabel.toLocaleUpperCase()}
            {arrowIcon()}
          </div>
        </StyledTableCellHeader>
      );
    });
  };

  return (
    <TableContainer component={Paper}>
      <Table className={classes.table} aria-label="customized table">
        <TableHead>
          <TableRow>
            {renderHeaderCells()}
          </TableRow>
        </TableHead>
        <TableBody>
          {sortedRows.map(row => (
            <StyledTableRow onClick={() => onClickRow(row.index)} key={row.index}>
              {row.rowData.map((cellLabel, index) => (
                <StyledTableCell key={`${row.index}-${index}`} className={classes.cell}>
                  {cellLabel}
                </StyledTableCell>
              ))}
            </StyledTableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default CustomTable;
