import React from 'react';
import Chart from 'react-apexcharts';
import EmptyState from 'components/EmptyState/EmptyState';
import ErrorMessage from 'components/ErrorMessage';
import LoadingSpinner from 'components/LoadingSpinner';

type Props = {
  labels: string;
  series: number;
  error: boolean;
  loading: boolean;
};

const DonutChart = ({ labels, series, error, loading }: Props) => {
  const color = [series >= 90 ? '#4472C4' : series >= 80 ? '#CAA038' : '#C00000'];

  const options = {
    states: {
      hover: {
        filter: {
          type: 'none',
        },
      },
    },
    plotOptions: {
      hover: { mode: null },
      radialBar: {
        offsetY: 0,
        startAngle: -120,
        endAngle: 120,
        hollow: {
          size: '30%',
        },
        dataLabels: {
          name: {
            offsetY: 60,
            show: true,
            color: '#2B323B',
            fontSize: '24px',
            fontFamily: 'Roboto',
          },
          value: {
            show: true,
            color: '#2B323B',
            fontSize: '15px',
          },
        },
      },
    },
    labels: [labels],
    colors: color,
  };

  if (error) return <ErrorMessage />;
  if (loading) return <LoadingSpinner height={175} />;
  if (labels === null || series === null) return <EmptyState />;

  return (
    <div>
      <Chart
        options={options}
        series={[series]}
        type="radialBar"
        width="240"
      />
    </div>
  );
};

export default DonutChart;
