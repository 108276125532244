import request from '../../utils/request';
import { getTimeFiltersFromState } from '../../utils/dateHelpers';

export const SET_INCIDENTS = 'distribution-center/set-incidents';
export const SET_INCIDENTS_BY_TIME = 'distribution-center/set-incidents-by-time';

export function setIncidents(incidents: any) {
  return { type: SET_INCIDENTS, incidents };
}

export function setIncidentsByTime(incidentsByTime: any) {
  return { type: SET_INCIDENTS_BY_TIME, incidentsByTime };
}

export function getIncidents(distCenter: string) {
  return async (dispatch: any, getState: any) => {
    dispatch(setIncidents(undefined));
    const url = '/api/v1/list-incidents';
    const timeFilters = getTimeFiltersFromState(getState());
    const response = await request.get(url, { ...timeFilters, distributionCenter: distCenter });
    dispatch(setIncidents(response.incidents));
  };
}

export function getTotalIncidentsByTime(distCenter: string) {
  return async (dispatch: any, getState: any) => {
    dispatch(setIncidentsByTime(undefined));
    const url = `/api/v1/distribution-centers/${distCenter}/incidents-by-time`;
    const timeFilters = getTimeFiltersFromState(getState());
    const response = await request.get(url, {
      ...timeFilters,
      timezoneOffset: timeFilters.timezoneOffsetEnd,
    });
    dispatch(setIncidentsByTime(response));
  };
}

export function downloadIncidents(location: string, factory?: boolean) {
  return async (_: any, getState: any) => {
    const timeFilters = getTimeFiltersFromState(getState());
    window.open(
      `/api/v1/incidents-report?startTime=${timeFilters.startTime}&endTime=${timeFilters.endTime}&timezoneOffsetStart=${timeFilters.timezoneOffsetStart}&timezoneOffsetEnd=${timeFilters.timezoneOffsetEnd}&${location}&factory=${factory}`,
      '_blank',
    );
  };
}

export function downloadAmbientalRisks(location: string) {
  return async (_: any, getState: any) => {
    const timeFilters = getTimeFiltersFromState(getState());
    window.open(
      `/api/v1/incidents-report?startTime=${timeFilters.startTime}&endTime=${timeFilters.endTime}&timezoneOffsetStart=${timeFilters.timezoneOffsetStart}&timezoneOffsetEnd=${timeFilters.timezoneOffsetEnd}&${location}&ambientalRisk=1`,
      '_blank',
    );
  };
}

export function downloadCertificates(location: string) {
  return async (_: any, getState: any) => {
    const timeFilters = getTimeFiltersFromState(getState());
    window.open(
      `/api/v2/user-access-certificates-report?startTime=${timeFilters.startTime}&endTime=${timeFilters.endTime}&timezoneOffsetStart=${timeFilters.timezoneOffsetStart}&timezoneOffsetEnd=${timeFilters.timezoneOffsetEnd}&${location}`,
      '_blank',
    );
  };
}

export function downloadRoutinesExcel(
  location: string,
  timeFilters: {
    startTime: Date;
    endTime: Date;
    timezoneOffsetStart: number;
    timezoneOffsetEnd: number;
  },
) {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  return async (_: any, getState: any) => {
    console.log('the location: ', location, `/api/v1/get-routines-excel?startTime=${timeFilters.startTime}&endTime=${timeFilters.endTime}&timezoneOffsetStart=${timeFilters.timezoneOffsetStart}&timezoneOffsetEnd=${timeFilters.timezoneOffsetEnd}&${location}`);

    window.open(
      `/api/v1/get-routines-excel?startTime=${timeFilters.startTime}&endTime=${timeFilters.endTime}&timezoneOffsetStart=${timeFilters.timezoneOffsetStart}&timezoneOffsetEnd=${timeFilters.timezoneOffsetEnd}&${location}`,
      '_blank',
    );
  };
}
