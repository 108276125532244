import React from 'react';
import LoadingSpinner from 'components/LoadingSpinner';
import { COMMENT_CREATE_TYPE } from 'types/enums';
import Comment from './Comment';
import EmptyStateComments from './EmptyStateComments';

type CommentsDisplayProps = {
  type: COMMENT_CREATE_TYPE;
  customContext: any;
  detailedCommentsQuery: Function;
  editCommentsMutation: Function;
  deleteCommentsMutation: Function;
  refetchQueries: Function;
  dynamicKeyName: string;
  errorMessage: string;
  requestIdKey: string;
  responseKey: string;
};

export default function CommentsDisplay(props: CommentsDisplayProps) {
  const commonContext = props.customContext();
  const { loading, data, error } = props.detailedCommentsQuery(
    {
      variables: {
        [props.requestIdKey]: commonContext.id,
      },
      fetchPolicy: 'cache-and-network',
    },
  );

  if (loading) {
    return <LoadingSpinner />;
  }
  if (error) {
    return (
      <div>
        <p>
          Something went wrong
        </p>
      </div>
    );
  }

  return (
    <div>
      {data[props.responseKey]?.length
        ? data[props.responseKey]?.map((comment: { id: string | undefined;
          updatedAt: any; comment: string | undefined; createdAt: Date | undefined;
          user: { slug: any; picture: any; name: any; }; }) => (
            <Comment
              customContext={props.customContext}
              type={props.type}
              refetchQueries={props.refetchQueries}
              editCommentsMutation={props.editCommentsMutation}
              deleteCommentsMutation={props.deleteCommentsMutation}
              key={comment?.id + comment?.updatedAt}
              message={comment?.comment}
              id={comment?.id}
              createdAt={comment?.createdAt}
              user={{
                slug: comment?.user?.slug,
                picture: comment?.user?.picture,
                name: comment?.user?.name,
              }}
              sourceId={commonContext.id}
              dynamicKeyName={props.dynamicKeyName}
              errorMessage={props.errorMessage}
            />
        ))
        : <EmptyStateComments />}
    </div>
  );
}
