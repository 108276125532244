import React from 'react';
import ReactApexChart from 'react-apexcharts';
import LoadingSpinner from 'components/LoadingSpinner';
import ErrorMessage from 'components/ErrorMessage';
import EmptyState from 'components/EmptyState/EmptyState';
import ImageLinks from 'utils/ImageLinks';

export type RampUpChartProps = {
  countSeries: any;
  totalSeries:any;
  categories: string[];
  error: boolean;
  loading: boolean;
};

const RampUpChart = ({ countSeries, totalSeries, categories, loading, error }
: RampUpChartProps) => {
  const seriesData = [{
    data: countSeries,
  }, {
    data: totalSeries,
    color: '#D6D6D6',
  }];
  const options = {
    chart: {
      stacked: true,
    },
    plotOptions: {
      bar: {
        horizontal: true,
        distributed: false,
      },
    },
    tooltip: {
      y: {
        formatter(value: any) {
          return value.toFixed(2);
        },
      },
    },
    colors: [
      ({ value } : {value:any}) => {
        return value >= 90 ? '#4472C4' : value >= 80 ? '#CAA038' : '#C00000';
      },
    ],
    dataLabels: {
      enabled: false,
    },
    legend: {
      show: false,
    },
    xaxis: {
      categories,
    },
  };

  if (error) return <ErrorMessage errorImage={ImageLinks.errorImage} buttonText="Recargar página" />;
  if (loading) return <LoadingSpinner height={215} />;
  if (
    countSeries.length === 0
    || totalSeries.length === 0
    || categories.length === 0
  ) return <EmptyState />;

  return (
    <ReactApexChart options={options} series={seriesData} type="bar" height={350} />
  );
};

export default RampUpChart;
