import React, { useMemo } from 'react';
// @ts-ignore
import ReactTable from 'react-table-legacy';
import EmptyState from 'components/EmptyState/EmptyState';

import './StatisticsSIFTableStyle.scss';
import { useListAllSifStatsByMonthQuery } from 'generated/graphql';
import LoadingSpinner from 'components/LoadingSpinner';

interface $RowType {
  value: string;
}
function StatisticsSIFTable(props:
{ monthNumber: number, year: number, filter: any }) {
  const { monthNumber, year, filter } = props;
  const { data: tableData, loading, error } = useListAllSifStatsByMonthQuery({
    variables: {
      monthNumber,
      year,
      filter,
    },
  });
  const data: any = [];
  if (tableData?.listAllSifStatsByMonth) {
    tableData?.listAllSifStatsByMonth.forEach(item => {
      data.push({
        referencia: item?.reference || '',
        sif: item?.shiftType || '',
        cd: item?.location || '',
        causa: item?.incidentCause || '',
        clasificación: item?.classificationOfInjury || '',
        departamento: item?.department || '',
        día: item?.incidentDate || '',
        turno: item?.shiftType || '',
        hora: item?.incidentTime || '',
      });
    });
  }

  const columns = useMemo(() => [
    {
      Header: 'Referencia',
      accessor: 'referencia',
      minWidth: 110,
      Cell: (row: $RowType) => (
        <p className="text-left margin-left-25">
          {row.value}
        </p>
      ),
    },
    // {
    //   Header: 'SIF',
    //   accessor: 'sif',
    //   minWidth: 110,
    //   Cell: (row: $RowType) => (
    //     <p className="text-left margin-left-25">
    //       {row.value}
    //     </p>
    //   ),
    // },
    {
      Header: 'CD',
      accessor: 'cd',
      minWidth: 120,
      Cell: (row: $RowType) => (
        <p className="text-left margin-left-25">
          {row.value}
        </p>
      ),
    },
    {
      Header: 'Causa',
      accessor: 'causa',
      minWidth: 120,
      Cell: (row: $RowType) => (
        <p className="text-left margin-left-25">
          {row.value}
        </p>
      ),
    },
    {
      Header: 'Clasificación',
      accessor: 'clasificación',
      minWidth: 70,
      Cell: (row: $RowType) => (
        <p className="text-left margin-left-25">
          {row.value}
        </p>
      ),
    },
    {
      Header: 'Departamento',
      accessor: 'departamento',
      minWidth: 110,
      Cell: (row: $RowType) => (
        <p className="text-left margin-left-25">
          {row.value}
        </p>
      ),
    },
    {
      Header: 'Día',
      accessor: 'día',
      minWidth: 80,
      Cell: (row: $RowType) => (
        <p className="text-left margin-left-25">
          {row.value}
        </p>
      ),
    },
    {
      Header: 'Turno',
      accessor: 'turno',
      minWidth: 80,
      Cell: (row: $RowType) => (
        <p className="text-left margin-left-25">
          {row.value}
        </p>
      ),
    },
    {
      Header: 'Hora (incidente)',
      accessor: 'hora',
      minWidth: 110,
      Cell: (row: $RowType) => (
        <p className="text-left margin-left-25">
          {row.value}
        </p>
      ),
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
  ], []);

  if (error) return <h5> Something Went Wrong. Please Try Again  </h5>;
  if (loading) return <LoadingSpinner />;
  if (data?.length === 0) return <div className="mt-4"> <EmptyState /> </div>;

  return (
    <ReactTable
      key={monthNumber}
      data={data}
      columns={columns}
      showPagination={false}
      defaultPageSize={data?.length}
      className="-striped max-height"
      NoDataComponent={() => <EmptyState />}
    />
  );
}

export default StatisticsSIFTable;
