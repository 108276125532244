export default {
  valueOutOfBoundsModalContainer: {
    maxWidth: '30%',
    display: 'flex',
    flexDirection: 'column',
    marginLeft: 'auto',
    marginRight: 'auto',
    backgroundColor: 'white',
    justifyContent: 'center',
    alignItems: 'stretch',
    marginTop: 200,
    padding: 10,
    paddingBottom: 15,
    paddingTop: 15,
    borderRadius: '12px',
  },
};
