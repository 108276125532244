import React, { FC, useState } from 'react';
import OutlineButton from 'components/Button/OutlineButton';
import LoadingSpinner from 'components/LoadingSpinner';

type Props = {
  onPress: () => void;
  image: any;
  text: string;
};

const SettingsItem: FC<Props> = (props: Props) => {
  const [isLoading, setIsLoading] = useState(false);
  const [uploadSuccess, setUploadSuccess] = useState(false);
  const [uploadFail, setUploadFail] = useState(false);
  const url = 'http://localhost:3001/certificados-8510044b-7619-46d0-b250-cc5df64db227.xlsx';

  const fetchExcel = () => {
    window.location.assign(url);
  };

  const fileUploaded = async (t: any) => {
    setUploadSuccess(false);
    setUploadFail(false);
    const fileName = t.target.files[0].name;
    setIsLoading(true);
    setTimeout(() => {
      setIsLoading(false);
      if (fileName.includes('certificados')) {
        setUploadSuccess(true);
      } else {
        setUploadFail(true);
      }
      // setUploadSuccess(true);
    }, 5000);
  };

  return (
    <div className="container" style={{ marginTop: 20 }}>
      <p>
        Para modificar la información, descarga el Excel, modifica los campos necesarios y subelo de nuevo
      </p>
      <div style={{ justifyContent: 'space-around', marginTop: 20, display: 'flex' }}>
        <OutlineButton
          onClick={fetchExcel}
          text="Descargar Excel"
        />
        <form encType="multipart/form-data" action="/api/v1/backend-edit" method="post" onChange={fileUploaded}>
          <input id="image-file" type="file" />
        </form>
      </div>
      { isLoading && (
        <LoadingSpinner text="Procesando archivo" />
      )}
      { uploadSuccess && (
        <div style={{ justifyContent: 'space-around', display: 'flex' }}>
          <div />
          <h3
            style={{ textAlign: 'right' }}
          >
            Archivo procesado exitosamente!
          </h3>
        </div>
      )}
      { uploadFail && (
        <div style={{ justifyContent: 'space-around', display: 'flex' }}>
          <div />
          <h3
            style={{ textAlign: 'right' }}
          >
            Archivo no valido
          </h3>
        </div>
      )}
    </div>
  );
};

export default SettingsItem;
