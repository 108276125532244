import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';

const itemCommon = {
  borderTopRightRadius: '30px',
  borderBottomRightRadius: '30px',
  padding: '5px 20px',
  marginRight: '15px',
};

const navigationDrawerStyles = makeStyles((theme: Theme) => createStyles({
  drawer: {
    [theme.breakpoints.up('sm')]: {
      flexShrink: 0,
      width: 248,
    },
  },
  drawerPaper: {
    top: '20px',
    backgroundColor: 'transparent',
    height: '70vh',
    overflowX: 'hidden',
    paddingTop: '2.6rem',
    gap: '8px',
  },
  DrawerPaperAnchorLeft: {
    borderRight: 'none',
  },
  root: {
    ...itemCommon,
    '&$selected': {
      backgroundColor: '#E4F2DA',
      color: '#70AD47',
    },
    color: '#878787',
    fontSize: '12px',
  },
  selected: {},
  primary: {
    color: '#56CD9D',
  },
  version: {
    position: 'absolute',
    bottom: '15px',
    width: 200,
  },
  userManual: {
    position: 'absolute',
    zIndex: 2,
    bottom: '80px',
    width: 161,
  },
  highlightBackground: {
    backgroundColor: '#E4F2DA',
  },
  rootInsideCollapse: {
    '&$selected': {
      backgroundColor: '#E4F2DA',
    },
    ...itemCommon,
    borderRadius: '30px',
  },
  selectedInsideCollapse: {
    backgroundColor: '#F0F0F0',
    borderRadius: '30px',
  },
  nested: {
    paddingLeft: theme.spacing(4),
    marginRight: theme.spacing(1),
    marginLeft: '2px',
    marginTop: theme.spacing(1),
    minHeight: 'none',
  },
  listIcon: {
    height: '20px',
    width: '20px',
    marginRight: '10px',
  },
  userManualListIcon: {
    height: '20px',
    width: '20px',
    marginRight: '10px',
    marginBottom: '1px',
  },
  rootText: {
    fontSize: '14px',
  },
}));

export default navigationDrawerStyles;
