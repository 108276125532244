import React, { useEffect, useMemo, useState } from 'react';
import { CircularProgress, Popover, Tooltip, Typography } from '@material-ui/core';
import InputWithIcon from 'components/Input/InputWithIcon';
import {
  getParticipantIncludingOwner,
  participantNumber,
} from 'features/action-logs/actionLogshelper';
import hashGenerator from 'utils/hashGenerator.helper';
import {
  actionLogLocationState,
  Responsible,
} from 'features/action-logs/createEditActionLog/createEditActionLogModal.state';
import { debounce, isEqual } from 'lodash';
import { ACTION_LOG_SUMMARY_MODAL_STATUS, TRANSLATIONS } from 'types/enums';
import getAvatarAlternateUI from 'utils/avatarAlternateUi';
import ImageLinks from 'utils/ImageLinks';
import { contentExtractor } from 'utils/userHelper';

import { useTranslation } from 'react-i18next';
import searchHandlerAPI from './searchHandlerAPI';
import { ExtractedContent } from './userSearchTypes';
import './ResponsiblesAvatarWithTooltipStyle.scss';

type ResponsiblesAvatarWithTooltipProps = {
  responsibles: Responsible[];
  owner: string;
  modalStatus: ACTION_LOG_SUMMARY_MODAL_STATUS;
  onRemoveResponsibleHandler: (slug: string) => void;
  changeOwner: (slug: string) => void;
  handleAddResponsible: (user: Responsible) => void;
  location: actionLogLocationState;
};

function ResponsiblesAvatarWithTooltip(props: ResponsiblesAvatarWithTooltipProps) {
  const { t } = useTranslation();
  const {
    responsibles,
    owner,
    modalStatus,
    onRemoveResponsibleHandler,
    changeOwner,
    location,
    handleAddResponsible,
  } = props;

  const [anchorEl, setAnchorEl] = useState<HTMLImageElement | null>(null);
  const [searchInProgress, setSearchInProgress] = useState(false);
  const [searchText, setSearchText] = useState<string>('');
  const [searchResult, setSearchResult] = useState<ExtractedContent[]>([]);
  const [previousResponsibles, setPreviousResponsibles] = useState<Responsible[]>([]);
  const [previousOwner, setPreviousOwner] = useState<string>('');
  const hash = useMemo(() => hashGenerator(responsibles, 'slug'), [responsibles]);

  function getRemainingParticipantName() {
    return responsibles?.map((responsible: { name: string }) => responsible.name).join();
  }
  const handleClick = (event: React.MouseEvent<HTMLImageElement>) => {
    if (ACTION_LOG_SUMMARY_MODAL_STATUS.SUMMARY !== modalStatus) setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  function handleSearchTextChange(e: React.ChangeEvent<HTMLInputElement>) {
    setSearchText(e.target.value);
  }
  function addResponsible(user: any) {
    if (!hash[user.slug]?.slug) {
      const newResponsibleData = {
        userId: user.id || '',
        name: user.name || '',
        slug: user.slug || '',
        position: user.position || '',
        profile: user.picture || '',
        shortSlug: user.shortSlug,
      };
      handleAddResponsible((newResponsibleData as unknown) as Responsible);
      setSearchResult([]);
      setSearchText('');
    }
  }
  function handleOKBtnClick() {
    if (!(previousOwner === owner && isEqual(previousResponsibles, responsibles))) {
      handleClose();
    }
  }
  function getResponsibleUI(responsibleLength = 4) {
    return (
      responsibles?.length
      && getParticipantIncludingOwner(responsibles, owner, responsibleLength)?.map(responsible => {
        // replace index check with slug check for owner and responsible
        if (responsible.slug === owner) {
          return (
            <Tooltip arrow placement="top" title={responsible?.name} key={responsible.slug}>
              <div className="owner_image">
                <img
                  className="participant_image"
                  src={
                    responsible?.picture
                      ? responsible.picture
                      : getAvatarAlternateUI({
                        name: responsible?.name,
                        isRandomBackground: true,
                      })
                  }
                  alt="participant"
                />
                <img className="crown_image" src={ImageLinks.crown} alt="owner" />
                <p className="owner_image_sub_title">{t(TRANSLATIONS.OWNER)}</p>
              </div>
            </Tooltip>
          );
        }
        return (
          <Tooltip arrow placement="top" title={responsible?.name} key={responsible.slug}>
            <div className="image_relative_container">
              {modalStatus !== ACTION_LOG_SUMMARY_MODAL_STATUS.SUMMARY && (
                <img
                  title="Remove"
                  className="remove_responsible_img"
                  src={ImageLinks.grayCross}
                  alt="remove responsible"
                  onClick={() => onRemoveResponsibleHandler(responsible.slug)}
                />
              )}
              <img
                className="participant_image"
                onClick={() => changeOwner(responsible.slug)}
                src={
                  responsible?.picture
                    ? responsible.picture
                    : getAvatarAlternateUI({
                      name: responsible?.name,
                      isRandomBackground: true,
                    })
                }
                alt="participant"
              />
            </div>
          </Tooltip>
        );
      })
    );
  }

  async function searchHandler(searchBy: string) {
    try {
      const result = await searchHandlerAPI('/api/v1/users-list', {
        search: searchBy.trim(),
        locationSlug: location.slug,
      });
      // @ts-ignore
      setSearchResult(contentExtractor(result.users || []));
      setSearchInProgress(false);
    } catch (e) {
      console.error('searchHandler e()', e);
      setSearchInProgress(false);
    }
  }
  useEffect(() => {
    const trimmedSearch = searchText.trim();
    if (trimmedSearch.length >= 2) {
      setSearchInProgress(true);
      const debouncedSearch = debounce(searchHandler, 200);
      debouncedSearch(trimmedSearch.toLowerCase());
    } else {
      setSearchInProgress(false);
      setSearchResult([]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchText]);
  useEffect(() => {
    setPreviousOwner(owner);
    setPreviousResponsibles(responsibles);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [anchorEl]);
  return (
    <>
      {getResponsibleUI()}
      <Tooltip
        arrow
        placement="top"
        title={
          responsibles?.length - participantNumber <= 0
            ? 'Add responsibles'
            : getRemainingParticipantName()
        }
      >
        <div
          style={{
            display:
              modalStatus !== ACTION_LOG_SUMMARY_MODAL_STATUS.SUMMARY
              || responsibles?.length - participantNumber > 0
                ? 'flex'
                : 'none',
          }}
          role="presentation"
          aria-describedby="popover-edit-for-responsible"
          onClick={handleClick}
          className="participant_image remaining_participant"
        >
          {' '}
          {responsibles?.length - participantNumber <= 0
            ? '+'
            : `+${responsibles?.length - participantNumber}`}
        </div>
      </Tooltip>
      <Popover
        id="popover-edit-for-responsible"
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
      >
        <Typography>
          <div className="popover_container">
            <div className="search_bar_with_btn">
              <InputWithIcon
                style={{
                  background: '#F2F2F2',
                  border: 'none',
                  color: '#808080',
                  height: '50px',
                  fontSize: '16px',
                }}
                value={searchText}
                onChange={handleSearchTextChange}
                placeholder={t(TRANSLATIONS.SEARCH_USERS)}
                icon={ImageLinks.searchIcon}
              />
              <p
                onClick={handleOKBtnClick}
                className={`ok_btn_secondary ${
                  previousOwner === owner && isEqual(previousResponsibles, responsibles)
                    ? 'disabled_ok_btn_secondary'
                    : ''
                }`}
              >
                OK
              </p>
            </div>
            <div className="responsible_list_container">
              {searchText.length >= 2
                && (searchInProgress ? (
                  <div className="circular_progress">
                    {' '}
                    <CircularProgress />{' '}
                  </div>
                ) : searchResult.length ? (
                  searchResult.map(user => (
                    <div className="single_responsible_container" key={user.email}>
                      <div className="avatar_name_container">
                        <img
                          className="participant_image"
                          onClick={() => addResponsible(user)}
                          src={
                            user?.picture
                              ? user.picture
                              : getAvatarAlternateUI({
                                name: user?.name,
                                isRandomBackground: true,
                              })
                          }
                          alt="participant"
                        />
                        <p className="responsible_name"> {user.name}</p>
                      </div>
                      <img
                        onClick={() => addResponsible(user)}
                        src={
                          user?.slug === owner
                            ? ImageLinks.crown
                            : hash[user.slug]?.slug
                              ? ImageLinks.greenChecked
                              : ImageLinks.addBlue
                        }
                        alt="status"
                        className="status_image"
                      />
                    </div>
                  ))
                ) : (
                  <p className="no_result_found">{t(TRANSLATIONS.NO_RESULTS_FOUND)}</p>
                ))}

              {searchText.length < 2
                && getParticipantIncludingOwner(responsibles, owner, responsibles.length).map(
                  responsible => (
                    <div className="single_responsible_container" key={responsible.slug}>
                      <div className="avatar_name_container">
                        <img
                          className="participant_image"
                          onClick={() => changeOwner(responsible.slug)}
                          src={
                            responsible?.picture
                              ? responsible.picture
                              : getAvatarAlternateUI({
                                name: responsible?.name,
                                isRandomBackground: true,
                              })
                          }
                          alt="participant"
                        />
                        <p className="responsible_name"> {responsible.name}</p>
                      </div>
                      <img
                        onClick={() => responsible?.slug !== owner
                          && onRemoveResponsibleHandler(responsible.slug)}
                        src={responsible?.slug === owner ? ImageLinks.crown : ImageLinks.grayCross}
                        alt="status"
                        className="status_image"
                      />
                    </div>
                  ),
                )}
            </div>
          </div>
        </Typography>
      </Popover>
    </>
  );
}

export default ResponsiblesAvatarWithTooltip;
