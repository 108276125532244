import React, { useContext, useEffect, useState } from 'react';
import { Divider, FormControl, InputAdornment, MenuItem, Select } from '@material-ui/core';
import PinIcon from 'assets/icons/pin.svg';
import UserIcon from 'assets/icons/user.svg';
import { Visibility, VisibilityOff } from '@material-ui/icons';
import LocationMarkerIcon from 'assets/icons/location-marker.svg';
import MazFlag from 'assets/icons/maz-icon.svg';
import GraduateIcon from 'assets/icons/graduate.svg';
import { useGetAllLocationsUserPositionsQuery } from 'generated/graphql';
import { fromJS } from 'immutable';
import _ from 'lodash';
import { useTranslation } from 'react-i18next';
import { TRANSLATIONS } from 'types/enums';
import homeIcon from 'assets/icons/home-black.svg';
import i18next from 'i18next';
import { isEspectroEnabled } from 'features/Espectro/constants';
import ImageLinks from 'utils/ImageLinks';
import { UserManagementContext } from '../../context';

const General = () => {
  const { t } = useTranslation();
  const userContext = useContext(UserManagementContext);
  const { originalUser, isEditing, editingUser, setEditingUser } = userContext;
  const allLocationsPositions = useGetAllLocationsUserPositionsQuery();
  const [showPin, setShowPin] = useState(false);
  const handleClickShowPin = () => setShowPin(!showPin);
  const originalUserInfo = originalUser?.get('general')?.toJS();

  const editingUserInfo = editingUser?.get('general')?.toJS();
  const [accessLocationId, setAccessLocationId] = useState(
    originalUserInfo?.distributionCenter?.id,
  );
  const [accessLocationType, setAccessLocationType] = useState(
    originalUserInfo?.distributionCenter?.type,
  );
  const [AccessLocationBuId, setAccessLocationBuId] = useState();
  const [positionByBU, setPositionByBU] = useState(originalUserInfo?.position);
  const [positionsSelectedBu, setPositionsSelectedBu] = useState();

  useEffect(() => {
    const tempObj = {
      general: {
        distributionCenter: {
          id: accessLocationId || originalUserInfo?.distributionCenter?.id,
          type: accessLocationType || originalUserInfo?.distributionCenter?.type,
        },
        position: positionByBU || originalUserInfo?.position,
      },
    };

    setEditingUser(editingUser.merge(fromJS(tempObj)));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [accessLocationId, positionByBU]);

  const getBuIdByLocationId = async (id: number) => {
    const locationSelected = await
    allLocationsPositions?.data?.getAllLocationsUserPositions?.locations.filter(
      (location: any) => {
        return +location.id === +id;
      }
    );
    // eslint-disable-next-line @typescript-eslint/no-unused-expressions
    locationSelected && setAccessLocationBuId(locationSelected[0]?.buId);
  };

  const getLocationTypeByLocationId = (id: number) => {
    const locationSelected = allLocationsPositions?.data
    ?.getAllLocationsUserPositions?.locations.filter(
      (location: any) => {
        return +location.id === +id;
      }
    );

    return locationSelected[0]?.type;
  };

  const handleAccessLocationChange = (event: any) => {
    setAccessLocationId(event.target.value);
    getBuIdByLocationId(event.target.value);
    setAccessLocationType(getLocationTypeByLocationId(event.target.value));
  };
  const handlePositionByBUChange = (event: any) => setPositionByBU(event.target.value);

  // function for Capitalize Character
  const toCamelCase = (str: string) => str.replace(/(^\w|\s\w)(\S*)/g, (_ignore, m1, m2) => m1.toUpperCase() + m2.toLowerCase());

  useEffect(() => {
    const filteredPositions = AccessLocationBuId
      && allLocationsPositions?.data?.getAllLocationsUserPositions?.businessUnits
        .map((bu: any) => {
          return bu.userPositions.filter((positionx: any) => {
            return positionx.buId === AccessLocationBuId;
          });
        })
        .filter((element: any) => {
          if (Object.keys(element).length > 0) {
            return element;
          }
        })[0];
    setPositionsSelectedBu(filteredPositions);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [AccessLocationBuId]);

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-unused-expressions
    originalUserInfo?.distributionCenter?.id
      && positionsSelectedBu === undefined
      && getBuIdByLocationId(originalUserInfo?.distributionCenter?.id);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [originalUserInfo]);

  return (
    <div className="p-2">
      {(!isEspectroEnabled) ? (
        <>
          <div className="d-flex justify-content-between">
            <DistributionCenterInfoCard
              label="BU"
              value={originalUserInfo?.distributionCenter?.bu ?? 'N/A'}
            />
            <DistributionCenterInfoCard label="Zona" img={MazFlag} value="MAZ" />
          </div>
          <div className="py-4">
            <InfoRow
              icon={LocationMarkerIcon}
              label={t(TRANSLATIONS.WORKS_AT)}
              value={
                isEditing ? (
                  <FormControl variant="outlined">
                    <Select
                      style={{ minWidth: '164px' }}
                      value={editingUserInfo?.distributionCenter?.id}
                      onChange={handleAccessLocationChange}
                    >
                      {_.sortBy(
                        allLocationsPositions?.data?.getAllLocationsUserPositions?.locations,
                        ['name'],
                      )?.map((item: any) => {
                        return (
                          <MenuItem key={item.id} value={item.id}>
                            {item.name}
                          </MenuItem>
                        );
                      })}
                    </Select>
                  </FormControl>
                ) : (
                  originalUserInfo?.distributionCenter?.name || 'N/A'
                )
              }
            />{' '}
            <InfoRow
              icon={GraduateIcon}
              label={t(TRANSLATIONS.POSITION)}
              value={
                isEditing ? (
                  <FormControl variant="outlined">
                    <Select
                      value={editingUserInfo?.position?.toLowerCase()}
                      onChange={handlePositionByBUChange}
                      style={{ minWidth: '164px' }}
                    >
                      {_.sortBy(positionsSelectedBu, ['position'])?.map((item: any) => {
                        return (
                          <MenuItem key={item.position} value={item.position?.toLowerCase()}>
                            {toCamelCase(item.position)}
                          </MenuItem>
                        );
                      })}
                    </Select>
                  </FormControl>
                ) : (
                  _.startCase(_.toLower(originalUserInfo?.position)) || 'N/A'
                )
              }
            />
            {originalUserInfo?.userWorkLocation
            && originalUserInfo?.distributionCenter?.type === 'FACTORY' ? (
              <>
                <InfoRow
                  icon={homeIcon}
                  label={t(TRANSLATIONS.DEPARTMENT)}
                  value={
                    (i18next.language === 'en'
                      ? originalUserInfo?.userWorkLocation?.workLocation?.nameEN
                      : originalUserInfo?.userWorkLocation?.workLocation?.name) || 'N/A'
                  }
                />
                <InfoRow
                  icon={homeIcon}
                  label={t(TRANSLATIONS.AREA)}
                  value={
                    (i18next.language === 'en'
                      ? originalUserInfo?.userWorkLocation?.subLocation?.nameEN
                      : originalUserInfo?.userWorkLocation?.subLocation?.name) || 'N/A'
                  }
                />
                <InfoRow
                  icon={homeIcon}
                  label={t(TRANSLATIONS.SUBAREA)}
                  value={
                    (i18next.language === 'en'
                      ? originalUserInfo?.userWorkLocation?.subArea?.nameEn
                      : originalUserInfo?.userWorkLocation?.subArea?.name) || 'N/A'
                  }
                />
              </>
            ) : null}
            <Divider light className="my-3" />
            <InfoRow
              icon={UserIcon}
              label={t(TRANSLATIONS.TYPE_OF_COLLABORATOR)}
              value={
                originalUserInfo?.employeeNumber
                  ? t(TRANSLATIONS.INTERNAL)
                  : t(TRANSLATIONS.EXTERNAL)
              }
            />
            {originalUserInfo?.employeeNumber ? (
              <InfoRow
                icon={PinIcon}
                label="Email"
                value={
                  originalUserInfo?.email ? (
                    <span style={{ textTransform: 'lowercase' }}>{originalUserInfo?.email}</span>
                  ) : (
                    'Not Available'
                  )
                }
              />
            ) : (
              <InfoRow
                icon={PinIcon}
                label="PIN"
                value={
                  originalUserInfo?.pin ? (
                    <>
                      <InputAdornment position="start">
                        <span role="button" onClick={handleClickShowPin}>
                          {showPin ? <Visibility /> : <VisibilityOff />}
                        </span>
                      </InputAdornment>
                      <input
                        style={{ width: `${originalUserInfo?.pin?.length + 1}ch` }}
                        readOnly
                        type={showPin ? 'text' : 'password'}
                        value={originalUserInfo?.pin}
                      />
                    </>
                  ) : (
                    'N/A'
                  )
                }
              />
            )}
          </div>
        </>
      ) : (
        <>
          <InfoRow
            icon={PinIcon}
            label="Email"
            value={
                originalUserInfo?.email ? (
                  <span style={{ textTransform: 'lowercase' }}>{originalUserInfo?.email}</span>
                ) : (
                  'Not Available'
                )
              }
          />
          <InfoRow
            icon={ImageLinks.espectro.fileUser}
            label="User Type"
            value={
                originalUserInfo?.email ? (
                  <span style={{ textTransform: 'lowercase' }}>{originalUserInfo?.userPermissionType}</span>
                ) : (
                  'Not Available'
                )
              }
          />
        </>
      )}
    </div>
  );
};

function DistributionCenterInfoCard(props: any) {
  const { label, img, value } = props;
  return (
    <div className="distribution-center-card">
      <span className="label">{label}</span>
      <div>
        {img && <img width="24px" src={img} alt={value} />}
        <span className="value">{value || 'Not Available'}</span>
      </div>
    </div>
  );
}

function InfoRow(props: any) {
  return (
    <div className="info-row-container">
      <div className="d-flex">
        <div className="info-row-icon">
          <img src={props.icon} width="100%" height="100%" alt="delete file" />
        </div>
        <span className="info-row-label">{props.label}</span>
      </div>
      <span className="info-row-value">{props.value}</span>
    </div>
  );
}

export default General;
