import ChartDropdown from 'components/Dropdown/ChartDropdown';
import React, { useEffect, useState } from 'react';
import './RoutinesFollowup.scss';
import { TRANSLATIONS } from 'types/enums';
import { useTranslation } from 'react-i18next';
import { addWeeks, format, getWeek } from 'date-fns';
import { useSelector } from 'react-redux';
import { selectLocationsToAccess } from 'store/selectors/globalFilters.selectors';
import { useCubeQuery } from '@cubejs-client/react';
import LoadingSpinner from 'components/LoadingSpinner';
import { routinesMonthSelectordropDownItems } from 'utils/dateHelpers';

const dropDownItems = routinesMonthSelectordropDownItems();

const RoutinesFollowup = () => {
  const { t } = useTranslation();

  const [selectedDropDownItem, setSelectedDropDownItem] = useState(dropDownItems[5]);
  const [dropDownOpen, setDropDownOpen] = useState(false);
  const [weeks, setWeeks] = useState<any>([]);
  const [selectedTab, setSelectedTab] = useState<any>([]);
  const locationsToAccess = useSelector(selectLocationsToAccess);
  const skip = !locationsToAccess.length;
  const getDateRangeCurrent = () => {
    const weekDate = new Date(selectedDropDownItem.content.startWeek);
    return [
      format(weekDate, 'yyyy-MM-dd'),
      format(addWeeks(weekDate, selectedDropDownItem.content.weeks - 1), 'yyyy-MM-dd'),
    ];
  };

  const dateRange = getDateRangeCurrent();

  const { resultSet: routinesToDoQuery, isLoading: loadingToDo } = useCubeQuery({
    measures: ['UserRoutines.count'],
    timeDimensions: [],
    dimensions: ['RoutinesAvailable.frequency'],
    filters: [{
      dimension: 'Users.locationId',
      operator: 'equals',
      values: locationsToAccess,
    }],
  }, { skip });
  const { resultSet: routinesDoneQuery, isLoading: loadingDone } = useCubeQuery({
    measures: ['Routines.count'],
    timeDimensions: [{
      dimension: 'Routines.createdat',
      granularity: 'week',
      dateRange,
    }],
    dimensions: ['RoutinesAvailable.frequency'],
    filters: [{
      dimension: 'Users.locationId',
      operator: 'equals',
      values: locationsToAccess,
    }],
  }, { skip });

  const isLoading = (loadingToDo && loadingDone) || skip;

  useEffect(() => {
    const routinesToDo = routinesToDoQuery?.series()[0];
    const routinesDone = routinesDoneQuery?.rawData();
    const data = new Array(
      selectedDropDownItem.content.weeks,
    ).fill(1).map((_, i) => ({
      title: `${t(TRANSLATIONS.WEEK)} ${i + 1}`,
      completionForWeek: 0,
      doneForWeek: 0,
    }));
    let routinesPerWeek = 0;
    routinesToDo?.series.forEach((r: any) => {
      if (r.category === '0 0 * * *') routinesPerWeek += r.value * 7;
      if (r.category === '0 0 * * 0') routinesPerWeek += r.value;
      if (r.category === '0 0 1 * *') routinesPerWeek += r.value;
    });
    routinesDone?.forEach((r: any) => {
      const week = getWeek(
        new Date(r['Routines.createdat.week']),
      ) - selectedDropDownItem.content.weekNumber;
      data[week].doneForWeek += r['Routines.count'];
    });

    const result = data.map((d: any) => ({
      ...d,
      completionForWeek: (d.doneForWeek / routinesPerWeek) * 100,
    }));
    setWeeks(result);
    setSelectedTab(result[0]);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [routinesToDoQuery, routinesDoneQuery]);

  const updateTab = (option: any) => {
    setSelectedTab(option);
  };

  const handleDropDownChange = ({ key }: any) => {
    setSelectedDropDownItem(dropDownItems.find(
      item => item.slug === key,
    ) || dropDownItems[0]);
    setDropDownOpen(false);
  };

  const average = (weeks?.reduce(
    (a: any, c: any) => a + c.completionForWeek, 0) / weeks.length) || 0;

  return (
    <div className="routine-followup">
      { !isLoading ? (
        <>
          <div
            className="header-row"
          >
            <p
              className="header-title"
            >
              {t(TRANSLATIONS.ROUTINES_FOLLOWUP)}
            </p>
            <div>
              <p className="header-right-content">{t(TRANSLATIONS.MONTHLY_TOTAL)} <strong>{average?.toFixed(0) || 0}%</strong></p>
              {/* <p className="header-percent-title">
                <img src={upIcon} alt="rate-icon" className="upicon-size" />
                +-%
              </p> */}
            </div>
          </div>
          <div className="pb-4">
            <div className="monthly-tabs">
              {weeks.length > 0 ? weeks.map(
                ((item: any) => {
                  return (
                    <div
                      key={`${item.title}`}
                      role="button"
                      onClick={() => {
                        updateTab(item);
                      }}
                      className={`tab-item ${item.title === selectedTab?.title ? 'tab-item-selected' : ''}`}
                    >
                      <p className="tab-title">
                        {item.title}
                      </p>
                      <p className="tab-value">
                        {(item.completionForWeek || 0).toFixed(0)}%
                      </p>
                      {/* {
                        item.title === selectedTab.title && (
                          <p className="header-percent-title">
                            <img src={upIcon} alt="rate-icon" className="upicon-size" />
                            + -%
                          </p>
                        )
                      } */}
                    </div>
                  );
                }),
              ) : null}
            </div>
          </div>
          <div className="center-content">
            {/* <p>
              {t(TRANSLATIONS.PRECEDING_PERIOD)} -
            </p> */}
          </div>
          <ChartDropdown
            onChange={(handleDropDownChange)}
            selectedDropDownItem={selectedDropDownItem}
            dropDownItems={dropDownItems}
            dropDownOpen={dropDownOpen}
            setDropDownOpen={setDropDownOpen}
            labelClassName="label-default"
          />

        </>
      ) : (
        <LoadingSpinner height={200} />
      )}
    </div>
  );
};

export default RoutinesFollowup;
