import React, { useEffect, useMemo, useState, useRef } from 'react';
import { cloneDeep } from 'lodash';
import { FormControlLabel, FormLabel, Radio, RadioGroup, Switch, withStyles } from '@material-ui/core';
import { toast, ToastOptions } from 'react-toastify';
import EspectroBasicButton from 'components/Espectro/Buttons/Basic';
import { useTranslation } from 'react-i18next';
import { TRANSLATIONS } from 'types/enums';
import SelectDropdown from 'components/Dropdown/SelectDropdown';
import SelectGroupDropdown from 'components/Dropdown/SelectGroupDropdown';
import LoadingSpinner from 'components/LoadingSpinner';
import Input from 'components/Input';
import { DragDropContext, Draggable, Droppable, DropResult } from 'react-beautiful-dnd';
import ImageLinks from 'utils/ImageLinks';
import { useGetListsForChecklistQuery, useGetRoutinesAvailableQuery } from 'generated/graphql';
import { ACK_TYPE } from 'espectro-utils/checklistRendererUtils/types';
import MultiValueInputList from 'components/Espectro/MultiValueInputList';
import Modal from 'components/Modal';
import {
  ITEM_INIT,
  SECTION_INIT,
  TYPE_OF_INPUT,
  QUESTION_MAX_LENGTH,
  BIT_TYPE_SELECT_OPTIONS_V2,
  CHECKLIST_DESCRIPTION_MAX_LENGTH,
} from '../constants';
import {
  Actions,
  Frequency,
  LocalLogic,
  ChecklistBit,
  ChecklistState,
  ChecklistSection,
  ChecklistBitTypes,
  LocalLogicTrigger,
} from '../types';
import { requestImageUploadPermission, uploadObjectImage } from '../utils';
import QuestionPopUp from '../QuestionPopUp';
import SectionHeader from '../components/SectionHeader/SectionHeader';

interface AddAnswersProps {
  state: ChecklistState;
  headerHeight?: number;
  localLogics: { [key: number]: LocalLogic[] };
  sections: ChecklistSection[];
  onChangeDescription: (e:any) => void;
  localLogicTriggers: LocalLogicTrigger[];
  setState: React.Dispatch<React.SetStateAction<ChecklistState>>;
  setSections: React.Dispatch<React.SetStateAction<ChecklistSection[]>>
  passedRoutineAvailableId?: string | undefined;
  captureSignatureBeforeSubmit: boolean;
  setCaptureSignatureBeforeSubmit: (val: boolean) => void;
}
const AddAnswers = ({
  state,
  onChangeDescription,
  setState,
  sections,
  localLogics,
  setSections,
  localLogicTriggers,
  headerHeight,
  setCaptureSignatureBeforeSubmit,
  captureSignatureBeforeSubmit,
  passedRoutineAvailableId,
}: AddAnswersProps) => {
  const [selectedSection, setSelectedSection] = useState<number>(0);
  const [selectedSectionItem, setSelectedSectionItem] = useState<number>(0);
  const [isQuestionTypesPopupOpen, setIsQuestionTypesPopupOpen] = useState<boolean>(false);
  const [isUploadingImageToS3, setIsUploadingImageToS3] = useState<boolean>(false);
  const [isQuestionTitleInputFocused, setIsQuestionTitleInputFocused] = useState<boolean>(false);
  const [showImageModal, setShowImageModal] = useState<boolean>(false);
  const [selectedImage, setSelectedImage] = useState<string>('');

  const inputFileRef = useRef<HTMLInputElement | null>(null);
  const replaceFileRef = useRef<HTMLInputElement | null>(null);

  useEffect(() => {
    setSelectedSection(0);
    setSelectedSectionItem(0);
  }, []);

  const { data, refetch } = useGetRoutinesAvailableQuery({
    variables: { excludeNestedChecklist: true },
  });

  const { data: assetTypesList } = useGetListsForChecklistQuery();

  const routinesAvailable = useMemo(() => {
    const tempData = data?.getRoutinesAvailable.filter(routine => {
      if (passedRoutineAvailableId) {
        return Number(routine?.id!) !== Number(passedRoutineAvailableId);
      }
      return routine;
    }).map(eachData => {
      return { searchKey: eachData?.name,
        value: eachData?.id,
        label: eachData?.name as string,
        className: eachData?.name };
    });
    return tempData;
  }, [data, passedRoutineAvailableId]);

  const companyAssetTypesList = useMemo(() => {
    const tempData = assetTypesList?.getListsForCompany.map(assetType => {
      return {
        searchKey: assetType?.name as string,
        value: assetType?.id as string,
        label: assetType?.name as string,
        className: assetType?.shortSlug as string,
      };
    });
    return tempData;
  }, [assetTypesList]);

  useEffect(() => {
    refetch();
  }, [refetch]);

  const { t } = useTranslation();
  const toastConfig = {
    position: 'top-right',
    autoClose: 4000,
    hideProgressBar: true,
    closeOnClick: true,
    pauseOnHover: false,
    draggable: false,
    progress: undefined,
  } as ToastOptions;

  const FREQUENCY = [
    {
      value: Frequency.DAILY, label: t(TRANSLATIONS.DAILY),
    },
    {
      value: Frequency.ANYTIME, label: 'Anytime',
    },
    {
      value: Frequency.WEEKLY, label: 'Weekly',
    },
    {
      value: Frequency.HOURLY, label: 'Hourly',
    },
    {
      value: Frequency.ONCE_EVERY_SHIFT, label: 'Once every shift',
    },
  ];

  const uploadToS3 = async (imageUrl: string) => {
    setIsUploadingImageToS3(true);
    const s3Target = 'ROUTINES_AVAILABLE';
    const response = await requestImageUploadPermission('jpeg', s3Target);
    if (response?.data?.preSignedUrl) {
      const binaryData = [];
      binaryData.push(imageUrl);
      const imgUrl = window.URL.createObjectURL(new Blob(binaryData, { type: 'image/jpg' }));
      const url = await uploadObjectImage(response.data.preSignedUrl, imgUrl);
      return url;
    }
  };

  const onChangeFrequency = (e: any) => {
    setState({ ...state, frequency: e.target.value });
  };

  const onDeleteSection = (sectionIndex: number) => {
    const copySections = [...sections];
    copySections.splice(sectionIndex, 1);
    setSections(copySections);
    toast.success(t(TRANSLATIONS.SECTION_DELETED), toastConfig);
  };

  const onChangeSectionFields = (value: any, fieldKey: string, sectionIndex: number) => {
    const copySections = [...sections];
    switch (fieldKey) {
      case 'title':
        copySections[sectionIndex].title = value as string;
        break;
      case 'description':
        copySections[sectionIndex].description = value as string;
        break;
      case 'isExpandedDefault':
        copySections[sectionIndex].isExpandedDefault = value as boolean;
        break;
      default:
        break;
    }
    setSections(copySections);
  };

  const onDeleteItem = (sectionIndex: number, itemIndex: number) => {
    const copySections = [...sections];
    copySections[sectionIndex].items.splice(itemIndex, 1);
    setSections(copySections);
    toast.success(t(TRANSLATIONS.ITEM_DELETED), toastConfig);
  };

  const onChangeItemFields = (
    value: any,
    fieldKey: string,
    sectionIndex: number,
    itemIndex: number,
  ): void => {
    const copySections = [...sections];
    switch (fieldKey) {
      case 'title':
        copySections[sectionIndex].items[itemIndex].title = value as string;
        break;
      case 'description':
        copySections[sectionIndex].items[itemIndex].description = value as string;
        break;
      case 'canBeRepeated':
        copySections[sectionIndex].items[itemIndex].canBeRepeated = value as boolean;
        break;
      case 'isRequired':
        copySections[sectionIndex].items[itemIndex].required = value as boolean;
        break;
      default:
        break;
    }
    setSections(copySections);
  };

  const dismissAndShowToastById = (toastId: string, toastContent: string) => {
    toast.dismiss('minValueWarningToast');
    toast.dismiss('maxValueWarningToast');
    toast.warning(toastContent, { toastId });
  };

  const onChangeBitFields = (
    value: any,
    fieldKey: string,
    sectionIndex: number,
    itemIndex: number,
    bitIndex: number,
    title: string,
  ): void => {
    const copySections = [...sections];
    const selectedProp = copySections[sectionIndex]?.items?.[itemIndex]?.bits?.[bitIndex]?.props!;
    const isIntegerOnlyInput = selectedProp?.numericType! === 'integer';

    switch (fieldKey) {
      case 'minBoundaryValue': {
        if ((Number(value) || Number(value) === 0)
          && selectedProp?.boundaryValues!.max <= Number(value)) {
          dismissAndShowToastById('minValueWarningToast', t(TRANSLATIONS.MINIMUM_VALUE_SHOULD_BE_LESSER_THAN_MAXIMUM_VALUE));
        }
        copySections[sectionIndex].items[itemIndex].bits[bitIndex]
          .props = {
            ...selectedProp,
            boundaryValues: {
              max: selectedProp?.boundaryValues!.max,
              min: isIntegerOnlyInput ? Math.trunc(value as number) : value as number,
            },
          };
        break;
      }
      case 'maxBoundaryValue': {
        if ((Number(value) || Number(value) === 0)
          && selectedProp?.boundaryValues!.min >= Number(value)) {
          dismissAndShowToastById('maxValueWarningToast', t(TRANSLATIONS.MAXIMUM_VALUE_SHOULD_BE_GREATER_THAN_MINIMUM_VALUE));
        }
        copySections[sectionIndex].items[itemIndex].bits[bitIndex]
          .props = {
            ...selectedProp,
            boundaryValues: {
              min: selectedProp?.boundaryValues!.min,
              max: isIntegerOnlyInput ? Math.trunc(value as number) : value as number,
            },
          };
        break;
      }
      case 'sectionAccordion':
        copySections[
          sectionIndex].isExpanded = value as boolean;
        break;
      case 'type':
        copySections[
          sectionIndex].items[
          itemIndex].bits[bitIndex].type = value as ChecklistBitTypes;
        if (value === ChecklistBitTypes.INPUT) {
          copySections[
            sectionIndex].items[
            itemIndex].bits[bitIndex].props.keyboardType = 'numeric';
        }
        if (value === ChecklistBitTypes.TEXT) {
          copySections[
            sectionIndex].items[
            itemIndex].bits[bitIndex].props.keyboardType = 'default';
        }
        if (value === ChecklistBitTypes.SIGNATURE) {
          copySections[
            sectionIndex].items[
            itemIndex].bits[bitIndex].props.showInputForSigneeName = true;
        }
        break;
      case 'paragraphInput':
        copySections[
          sectionIndex].items[
          itemIndex].bits[bitIndex].props.enableParagraphInput = value as boolean;
        break;
      case 'autoRemoveWhenSectionTampered':
        copySections[
          sectionIndex].items[
          itemIndex].bits[bitIndex].props.autoRemoveWhenSectionTampered = value as boolean;
        break;
      case 'hideQuestionUntilSectionAnswered':
        copySections[
          sectionIndex].items[
          itemIndex].bits[bitIndex].props.hideQuestionUntilSectionAnswered = value as boolean;
        break;
      case 'showInputForSigneeName':
        copySections[
          sectionIndex].items[
          itemIndex].bits[bitIndex].props.showInputForSigneeName = value as boolean;
        break;
      case 'showOthersOption':
        copySections[
          sectionIndex].items[
          itemIndex].bits[bitIndex].props.showOthersOption = value as boolean;
        break;
      case 'enableCameraCapture':
        copySections[
          sectionIndex].items[
          itemIndex].bits[bitIndex].props.enableCameraCapture = value as boolean;
        break;
      case 'decimal':
        copySections[
          sectionIndex].items[
          itemIndex].bits[bitIndex].props.numericType = value;
        break;
      case 'placeholder':
        copySections[
          sectionIndex].items[
          itemIndex].bits[bitIndex].props.placeholder = value;
        break;
      case 'title':
        copySections[
          sectionIndex].items[
          itemIndex].bits[bitIndex].props.title = value;
        break;
      case 'keyboardType':
        copySections[
          sectionIndex].items[
          itemIndex].bits[bitIndex].props.keyboardType = value;
        break;
      case 'listType':
        copySections[
          sectionIndex].items[
          itemIndex].bits[bitIndex].props.selectedAssetType = value;
        break;
      case 'numericType':
        copySections[
          sectionIndex].items[
          itemIndex].bits[bitIndex].props.numericType = value;
        break;
      case 'options':
        copySections[
          sectionIndex].items[
          itemIndex].bits[bitIndex].props.options = value;
        break;
      case 'text':
        copySections[
          sectionIndex].items[
          itemIndex].bits[bitIndex].props.text = value;
        break;
      case 'description':
        copySections[
          sectionIndex].items[
          itemIndex].bits[bitIndex].props.description = value;
        break;
      case 'counterSize':
        copySections[
          sectionIndex].items[
          itemIndex].bits[bitIndex].props.counterSize = value;
        break;
      case 'step':
        copySections[
          sectionIndex].items[
          itemIndex].bits[bitIndex].props.step = value;
        break;
      case 'blueprint':
        copySections[
          sectionIndex].items[
          itemIndex].bits[bitIndex].props.blueprint = value;
        break;
      case 'dropdown':
        copySections[
          sectionIndex].items[
          itemIndex].bits[bitIndex].props.checklistId = value;
        copySections[
          sectionIndex].items[
          itemIndex].bits[bitIndex].props.title = title;
        const selectedRoutineFromDropdown = data?.getRoutinesAvailable
          ?.find(r => Number(r?.id as unknown as number) === Number(value));
        copySections[
          sectionIndex].items[
          itemIndex].bits[bitIndex].props.content = {
          checklist: selectedRoutineFromDropdown?.content.checklist,
          checklistTitle: selectedRoutineFromDropdown?.name,
          checklistVersionNumber: selectedRoutineFromDropdown?.versionNumber,
          checklistVersionId: selectedRoutineFromDropdown?.versionId,
        };
        break;
      case 'allowsNote':
        copySections[
          sectionIndex].items[
          itemIndex].bits[bitIndex].additionalFeatures = {
          allowsNote: value as boolean,
          note: '',
        };
        break;
      case 'boundaryValues':
        copySections[
          sectionIndex].items[
          itemIndex].bits[bitIndex].props = {
          ...copySections[
            sectionIndex].items[
            itemIndex].bits[bitIndex].props,
          boundaryValues: value,
        };
        break;
      case 'instructionType':
        copySections[
          sectionIndex].items[
          itemIndex].bits[bitIndex].props.instructionType = value?.type ?? '';
        copySections[
          sectionIndex].items[
          itemIndex].bits[bitIndex].props.instructionImage = value?.image ?? '';
        break;
      default:
        break;
    }
    setSections(copySections);
  };

  const onChangeDefaultAnswer = (
    value: any,
    sectionIndex: number,
    itemIndex: number,
    bitIndex: number,
  ): void => {
    const copySections = [...sections];
    const options = copySections[sectionIndex].items[itemIndex].bits[0]?.props?.options;
    const isQuestionTypeDropdown = copySections[sectionIndex].items[itemIndex]
      .bits[0].type === ChecklistBitTypes.DROPDOWN;

    if (!!options && options?.length > 0) {
      const optionIndex = options?.findIndex(o => o.title === value);
      const storedDefaultAnswer = copySections[
        sectionIndex].items[
        itemIndex].bits[bitIndex].defaultAnswer;
      if (storedDefaultAnswer === optionIndex) {
        copySections[
          sectionIndex].items[
          itemIndex].bits[bitIndex].defaultAnswer = undefined;
        copySections[
          sectionIndex].items[
          itemIndex].bits[bitIndex].answer = undefined;
      } else {
        copySections[
          sectionIndex].items[
          itemIndex].bits[bitIndex].defaultAnswer = optionIndex as any;
        copySections[
          sectionIndex].items[
          itemIndex].bits[bitIndex].answer = isQuestionTypeDropdown ? options[optionIndex].title
          : optionIndex as any;
      }
      setSections(copySections);
    }
  };

  const toggleSectionAccordion = (
    sectionIndex: number,
    overrideValue = !sections[sectionIndex].isExpanded,
  ) => {
    onChangeBitFields(
      overrideValue,
      'sectionAccordion',
      sectionIndex,
      0,
      0,
      '',
    );
  };

  const focusInput = (id: string) => {
    const inputElement = document.getElementById(id);
    inputElement?.focus();
  };

  const scrollToSectionItems = (sectionIndex: number, itemIndex: number, block: ScrollLogicalPosition = 'start') => {
    toggleSectionAccordion(sectionIndex, true);
    setTimeout(() => {
      const element = document.getElementById(`${sectionIndex}_${itemIndex}`);
      setSelectedSectionItem(itemIndex);
      setSelectedSection(sectionIndex);
      setIsQuestionTitleInputFocused(true);
      focusInput(`section${sectionIndex}_item${itemIndex}`);
      if (element) {
        element.scrollIntoView({ behavior: 'smooth', block });
      }
    }, 0);
  };

  const scrollToSectionHelper = (sectionKey: string, block: ScrollLogicalPosition = 'start') => {
    setTimeout(() => {
      const element = document.getElementById(sectionKey);
      focusInput(sectionKey);
      if (element) {
        element.scrollIntoView({ behavior: 'smooth', block });
      }
    }, 0);
  };

  const onAddItem = (sectionIndex: number) => {
    const copySections = [...sections];
    copySections[sectionIndex].items = [...copySections[sectionIndex].items, { ...ITEM_INIT,
      bits: [{
        key: `bit_key__${copySections[sectionIndex].items.length + 1}`,
        props: { keyboardType: 'default' },
        additionalFeatures: {
          allowsNote: false,
          note: '',
        },
      }],
      required: true,
      key: `item_key__${copySections[sectionIndex].items.length + 1}` }];
    setSections(copySections);
  };

  const onAddInstruction = (sectionIndex: number) => {
    const copySections = [...sections];
    copySections[sectionIndex].items = [...copySections[sectionIndex].items, { ...ITEM_INIT,
      bits: [{
        type: ChecklistBitTypes.ACK,
        key: `bit_key__${copySections[sectionIndex].items.length + 1}`,
        props: { keyboardType: 'default', instructionType: ACK_TYPE.INFO },
        additionalFeatures: {
          allowsNote: false,
          note: '',
        },
      }],
      required: false,
      key: `item_key__${copySections[sectionIndex].items.length + 1}` }];
    setSections(copySections);
  };

  const scrollToSection = (index: number, key: string) => {
    toggleSectionAccordion(index, true);
    const element = document.getElementById(key);
    setSelectedSection(index);
    setSelectedSectionItem(0);
    if (element) {
      element.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }
  };

  const uploadImage = () => {
    inputFileRef.current?.click();
  };

  const replaceImage = () => {
    replaceFileRef.current?.click();
  };

  const renderConfigureComponents = (
    type: string, sectionIndex: number, itemIndex: number, bitIndex: number, bit: ChecklistBit,
  ) => {
    const splitArray = type.split('?');
    let typeOfProp;
    let isOptional = false;
    if (splitArray.length === 2) {
      isOptional = true;
      [, typeOfProp] = splitArray;
    } else {
      [typeOfProp] = splitArray;
    }
    let selectedType = null;
    if (sections[
      sectionIndex].items[itemIndex].bits[bitIndex].props.keyboardType === 'numeric') {
      selectedType = sections[sectionIndex].items[itemIndex].bits[bitIndex].props.numericType === 'integer' ? 'enteros' : sections[sectionIndex].items[itemIndex].bits[bitIndex].props.numericType === 'decimal' ? 'decimales' : 'enteros';
    } else selectedType = 'alfabético';

    const multiValueInputValue = sections[sectionIndex].items[itemIndex]
      .bits[bitIndex].props.options?.map(
      item => item.title) || [];
    const selectedProps = sections[
      sectionIndex].items[itemIndex].bits[bitIndex].props;
    switch (typeOfProp) {
      case 'placeholder':
        return (
          <>
            <input
              type="text"
              className="add_answers-checklist-bit-input-option"
              placeholder={` ${t(TRANSLATIONS.PLACEHOLDER_TEXT)} ${selectedType === 'alfabético' ? 'teclado ' : ''}${selectedType}.`}
              value={sections[sectionIndex].items[itemIndex].bits[bitIndex].props.placeholder}
              onChange={
                e => onChangeBitFields(e.target.value, 'placeholder', sectionIndex, itemIndex, bitIndex, '')
              }
            />
            <p className="body_xl mt-2">{t(TRANSLATIONS.PLACEHOLDER_INFO)}</p>
          </>
        );
      case 'listType':
        return (
          <div className="add_answer-checklist-question-type-nested-checklist-container">
            <SelectGroupDropdown
              placeholder={bit.props.selectedAssetType?.name as string
                || t(TRANSLATIONS.SELECT_OPTION)}
              name={t(TRANSLATIONS.ASSETS)}
              bgColor="white"
              dropdownWrapperClassName="add_answers-checklist-builder_select-dropdown"
              value={bit.type as string}
              onChange={e => {
                const selectedAssetTypeId = Number(e.target.value);
                const selectedAssetType = assetTypesList
                  ?.getListsForCompany.find(a => Number(a?.id) === selectedAssetTypeId);
                onChangeBitFields(selectedAssetType, 'listType', sectionIndex, itemIndex, bitIndex, e.target.innerText);
              }}
              dropdownContent={companyAssetTypesList || []}
            />
          </div>
        );
      case 'title':
        return (
          <input
            type="text"
            className="add_answers-checklist-bit-input-option mt-4"
            placeholder={t(TRANSLATIONS.ENTER_TITLE)}
            value={sections[sectionIndex].items[itemIndex].bits[bitIndex].props.title}
            onChange={
              e => onChangeBitFields(e.target.value, 'title', sectionIndex, itemIndex, bitIndex, '')
            }
          />
        );
      case 'description':
        return (
          <div className="checklist-builder__input-row">
            <input
              type="text"
              placeholder={t(TRANSLATIONS.ENTER_PLACEHOLDER_TEXT)}
              className="add_answers-checklist-bit-input-option"
              value={sections[sectionIndex].items[itemIndex].bits[bitIndex].props.description}
              onChange={
                e => onChangeBitFields(e.target.value, 'description', sectionIndex, itemIndex, bitIndex, '')
              }
            />
          </div>
        );

      case 'text':
        return (
          <>
            <input
              type="text"
              placeholder="Enter text"
              className="add_answers-checklist-bit-input-option"
              value={sections[sectionIndex].items[itemIndex].bits[bitIndex].props.text}
              onChange={
                e => onChangeBitFields(e.target.value, 'text', sectionIndex, itemIndex, bitIndex, '')
              }
            />
            <p className="body_xl">{t(TRANSLATIONS.PLACEHOLDER_INFO)}</p>
          </>
        );
      case 'keyboardType':
        const selectedValue = selectedProps.keyboardType;
        const typeOfNumber = selectedProps.numericType || 'integer';
        return (
          <div className="checklist-builder__keyboard-type">
            <p>Tipo de teclado</p>
            <div className="checklist-builder_select-keyboard-type">
              {TYPE_OF_INPUT.map(typeOfInput => {
                const isActive = typeOfInput.value === (sections[
                  sectionIndex].items[itemIndex].bits[bitIndex].props.keyboardType || 'default');
                return (
                  <>
                    <div
                      role="button"
                      onClick={() => {
                        onChangeBitFields(typeOfInput.value, 'keyboardType', sectionIndex, itemIndex, bitIndex, '');
                      }}
                      className={isActive ? 'checklist-builder_select-keyboard-type-active' : 'checklist-builder_select-keyboard-type-disabled'}
                      key={typeOfInput.value}
                    >
                      <img alt="img" src={isActive ? typeOfInput.icon.active : typeOfInput.icon.disabled} />
                      <p>{typeOfInput.label}</p>
                    </div>
                  </>
                );
              })}
            </div>
            {selectedValue === 'numeric' && (
            <div>
              <FormLabel component="legend">{t(TRANSLATIONS.TYPE_OF_NUMBER)}</FormLabel>
              <RadioGroup
                aria-label={t(TRANSLATIONS.TYPE_OF_NUMBER)}
                name={t(TRANSLATIONS.TYPE_OF_NUMBER)}
                value={typeOfNumber}
                onChange={event => {
                  onChangeBitFields(event.target.value, 'numericType', sectionIndex, itemIndex, bitIndex, '');
                }}
              >
                <FormControlLabel value="integer" control={<Radio />} label={t(TRANSLATIONS.NUMBERS)} />
                <FormControlLabel value="decimal" control={<Radio />} label={t(TRANSLATIONS.DECIMAL)} />
              </RadioGroup>
            </div>
            )}
          </div>
        );
      case 'enableCameraCapture':
        return (
          <div
            role="button"
            onClick={() => {
              onChangeBitFields(
                !selectedProps.enableCameraCapture,
                'enableCameraCapture',
                sectionIndex,
                itemIndex,
                bitIndex,
                '',
              );
            }}
            className="add_answers-checklist-radio-buttons"
            style={{
              marginBottom: 12,
            }}
          >
            <img
              src={selectedProps.enableCameraCapture as boolean
                ? ImageLinks.espectro.successCheck
                : ImageLinks.espectro.emptyCheck}
              alt="enable camera capture"
              width={24}
              height={24}
              style={{ pointerEvents: 'all' }}
            />
            <p className="add_answers-enable-repitition">
              {t(TRANSLATIONS.ENABLE_CAMERA_CAPTURE)}
            </p>
          </div>
        );
      case 'enableParagraphInput':
        return (
          <div
            role="button"
            onClick={() => {
              onChangeBitFields(
                !selectedProps.enableParagraphInput,
                'paragraphInput',
                sectionIndex,
                itemIndex,
                bitIndex,
                '',
              );
            }}
            className="add_answers-checklist-radio-buttons"
            style={{
              marginBottom: 12,
            }}
          >
            <img
              src={selectedProps.enableParagraphInput as boolean
                ? ImageLinks.espectro.successCheck
                : ImageLinks.espectro.emptyCheck}
              alt="enable paragraph"
              width={24}
              height={24}
              style={{ pointerEvents: 'all' }}
            />
            <p className="add_answers-enable-repitition">
              {t(TRANSLATIONS.ENABLE_PARAGRAPH)}
            </p>
          </div>
        );
      case 'hideQuestionUntilSectionAnswered':
        return (
          <div
            role="button"
            onClick={() => {
              onChangeBitFields(
                !selectedProps.hideQuestionUntilSectionAnswered,
                'hideQuestionUntilSectionAnswered',
                sectionIndex,
                itemIndex,
                bitIndex,
                '',
              );
            }}
            className="add_answers-checklist-radio-buttons"
            style={{
              marginBottom: 12,
            }}
          >
            <img
              src={selectedProps.hideQuestionUntilSectionAnswered as boolean
                ? ImageLinks.espectro.successCheck
                : ImageLinks.espectro.emptyCheck}
              alt="enable paragraph"
              width={24}
              height={24}
              style={{ pointerEvents: 'all' }}
            />
            <p className="add_answers-enable-repitition">
              {t(TRANSLATIONS.AUTO_HIDE_SIGNATURE)}
            </p>
          </div>
        );
      case 'autoRemoveWhenSectionTampered':
        return (
          <div
            role="button"
            onClick={() => {
              onChangeBitFields(
                !selectedProps.autoRemoveWhenSectionTampered,
                'autoRemoveWhenSectionTampered',
                sectionIndex,
                itemIndex,
                bitIndex,
                '',
              );
            }}
            className="add_answers-checklist-radio-buttons"
            style={{
              marginBottom: 12,
            }}
          >
            <img
              src={selectedProps.autoRemoveWhenSectionTampered as boolean
                ? ImageLinks.espectro.successCheck
                : ImageLinks.espectro.emptyCheck}
              alt="enable paragraph"
              width={24}
              height={24}
              style={{ pointerEvents: 'all' }}
            />
            <p className="add_answers-enable-repitition">
              {t(TRANSLATIONS.AUTO_REMOVE_SIGNATURE)}
            </p>
          </div>
        );
      case 'showInputForSigneeName':
        return (
          <div
            role="button"
            onClick={() => {
              onChangeBitFields(
                !selectedProps.showInputForSigneeName,
                'showInputForSigneeName',
                sectionIndex,
                itemIndex,
                bitIndex,
                '',
              );
            }}
            className="add_answers-checklist-radio-buttons"
            style={{
              marginBottom: 12,
            }}
          >
            <img
              src={selectedProps.showInputForSigneeName as boolean
                ? ImageLinks.espectro.successCheck
                : ImageLinks.espectro.emptyCheck}
              alt="enable paragraph"
              width={24}
              height={24}
              style={{ pointerEvents: 'all' }}
            />
            <p className="add_answers-enable-repitition">
              {t(TRANSLATIONS.SHOW_INPUT_FOR_SIGNEE_NAME)}
            </p>
          </div>
        );
      case 'showOthersOption':
        return (
          <div
            role="button"
            onClick={() => {
              onChangeBitFields(
                !selectedProps.showOthersOption,
                'showOthersOption',
                sectionIndex,
                itemIndex,
                bitIndex,
                '',
              );
            }}
            className="add_answers-checklist-radio-buttons add_answers-margin-top"
            style={{ marginBottom: 12 }}
          >
            <img
              src={selectedProps.showOthersOption as boolean
                ? ImageLinks.espectro.successCheck
                : ImageLinks.espectro.emptyCheck}
              alt="enable paragraph"
              width={24}
              height={24}
              style={{ pointerEvents: 'all' }}
            />
            <p className="add_answers-enable-repitition">
              {t(TRANSLATIONS.SHOW_OTHER_AS_OPTION)}
            </p>
          </div>
        );
      case 'decimal':
        return (
          <div
            role="button"
            onClick={() => {
              onChangeBitFields(
                selectedProps.numericType === 'decimal' ? 'integer' : 'decimal',
                'decimal',
                sectionIndex,
                itemIndex,
                bitIndex,
                '',
              );
            }}
            className="add_answers-checklist-radio-buttons"
            style={{ marginBottom: 12 }}
          >
            <img
              src={selectedProps.numericType === 'decimal'
                ? ImageLinks.espectro.successCheck
                : ImageLinks.espectro.emptyCheck}
              alt="enable paragraph"
              width={24}
              height={24}
              style={{ pointerEvents: 'all' }}
            />
            <p className="add_answers-enable-repitition">
              {t(TRANSLATIONS.ENABLE_DECIMALS)}
            </p>
          </div>
        );
      case 'boundaryValues':
        return (
          <>
            <div
              role="button"
              onClick={() => {
                onChangeBitFields(
                  selectedProps?.boundaryValues ? undefined : {
                    max: null,
                    min: null,
                  },
                  'boundaryValues',
                  sectionIndex,
                  itemIndex,
                  bitIndex,
                  '',
                );
              }}
              className="add_answers-checklist-radio-buttons"
              style={{ marginBottom: 12 }}
            >
              <img
                src={selectedProps?.boundaryValues
                  ? ImageLinks.espectro.successCheck
                  : ImageLinks.espectro.emptyCheck}
                alt="enable paragraph"
                width={24}
                height={24}
                style={{ pointerEvents: 'all' }}
              />
              <p className="add_answers-enable-repitition">
                {t(TRANSLATIONS.SET_MINIMUM_AND_MAXIMUM)}
              </p>
            </div>
            {
              selectedProps?.boundaryValues && (
                <div className="add_answers-boundary-values-input-wrapper">
                  <input
                    type="number"
                    placeholder={t(TRANSLATIONS.MINIMUM_VALUE)}
                    className="add_answers-boundary-values-input"
                    value={sections[sectionIndex].items[itemIndex]
                      .bits[bitIndex].props?.boundaryValues!.min}
                    onChange={
                      e => onChangeBitFields(e.target.value, 'minBoundaryValue', sectionIndex, itemIndex, bitIndex, '')
                    }
                  />
                  <input
                    type="number"
                    placeholder={t(TRANSLATIONS.MAXIMUM_VALUE)}
                    className="add_answers-boundary-values-input"
                    value={sections[sectionIndex].items[itemIndex]
                      .bits[bitIndex].props?.boundaryValues!.max}
                    onChange={
                      e => onChangeBitFields(e.target.value, 'maxBoundaryValue', sectionIndex, itemIndex, bitIndex, '')
                    }
                  />
                </div>
              )
            }
          </>
        );
      case 'step':
        return (
          <div className="checklist-builder__flex-row checklist-builder__flex-justify-start checklist-builder__input-row">
            <p>{!isOptional && <span style={{ color: 'red' }}>*</span>} Steps:</p>
            <input
              className="add_answers-checklist-bit-input-option"
              type="number"
              placeholder="Enter Steps (Number only)"
              value={String(sections[sectionIndex].items[itemIndex].bits[bitIndex].props.step)}
              onChange={
                e => onChangeBitFields(Number(e.target.value), 'step', sectionIndex, itemIndex, bitIndex, '')
              }
            />
          </div>
        );
      case 'options':
        return (
          <>
            <MultiValueInputList
              // maxLength={24}
              items={multiValueInputValue}
              resetDefaultAnswer={() => {
                onChangeDefaultAnswer(undefined, sectionIndex, itemIndex, bitIndex);
              }}
              onAddItem={(allItems: string[]) => {
                onChangeBitFields(allItems.map(i => {
                  return { title: i };
                }), 'options', sectionIndex, itemIndex, bitIndex, '');
              }}
              onDeleteItem={(allItems: string[]) => {
                onChangeBitFields(allItems.map(i => {
                  return { title: i };
                }), 'options', sectionIndex, itemIndex, bitIndex, '');
              }}
              placeholder={t(TRANSLATIONS.ENTER_OPTION_HIT_ENTER)}
            />
            {multiValueInputValue.length < 2 && <p className="body_xl mt-2">{t(TRANSLATIONS.ATLEAST_TWO_OPTIONS)}</p>}
          </>
        );
      case 'instructionType':
        const obj = {
          type: bit?.props?.instructionType ?? '',
          image: sections?.[sectionIndex]?.items?.[itemIndex]
            ?.bits?.[bitIndex]?.props?.instructionImage ?? '',
        };
        return (
          <div className="add_answer-checklist-question-type-nested-checklist-container">
            <SelectGroupDropdown
              placeholder={bit.props.title as string || t(TRANSLATIONS.SELECT_OPTION)}
              name={t(TRANSLATIONS.CHECKLIST_OPTIONS)}
              bgColor="white"
              value={obj.type as string}
              onChange={e => {
                obj.type = e.target.value;
                onChangeBitFields(obj, 'instructionType', sectionIndex, itemIndex, bitIndex, e.target.innerText);
              }}
              dropdownWrapperClassName="add_answers-checklist-builder_select-dropdown"
              dropdownContent={[
                { searchKey: ACK_TYPE.INFO,
                  value: ACK_TYPE.INFO,
                  label: 'Info',
                  className: '' },
                { searchKey: ACK_TYPE.ERROR,
                  value: ACK_TYPE.ERROR,
                  label: 'Danger',
                  className: '' },
                { searchKey: ACK_TYPE.SUCCESS,
                  value: ACK_TYPE.SUCCESS,
                  label: 'Success',
                  className: '' },
              ]}
            />
            <div className="image_upload_wrap">
              {String(obj.image) === ''
                ? (isUploadingImageToS3
                && sectionIndex === selectedSection
                && itemIndex === selectedSectionItem) ? <LoadingSpinner text="" /> : (
                  <>
                    <input
                      type="file"
                      id="file"
                      ref={inputFileRef}
                      style={{ display: 'none' }}
                      onChange={
                        async e => {
                          const url: any = await uploadToS3(e?.target?.files?.[0] as any);
                          obj.image = url;
                          if (url) {
                            onChangeBitFields(obj, 'instructionType', sectionIndex, itemIndex, bitIndex, '');
                          }
                          setIsUploadingImageToS3(false);
                        }
                      }
                      accept="image/png, image/jpeg"
                    />
                    <div
                      role="button"
                      onClick={uploadImage}
                      className="instruction_image_wrap"
                    >
                      <img
                        src={ImageLinks.espectro.media}
                        alt="checklist question required"
                        width={24}
                        height={24}
                        style={{ pointerEvents: 'all' }}
                      />
                      <p className="instruction_image_upload_title">{t(TRANSLATIONS.UPLOAD_MEDIA_REFERENCE)}</p>
                    </div>
                  </>
                )
                : (
                  (isUploadingImageToS3
                    && sectionIndex === selectedSection
                    && itemIndex === selectedSectionItem
                  ) ? (
                    <LoadingSpinner text="" />
                    ) : (
                      <div className="addAnswerImageContainer">
                        <input
                          type="file"
                          id="file"
                          ref={replaceFileRef}
                          style={{ display: 'none' }}
                          onChange={
                          async e => {
                            const url: any = await uploadToS3(e?.target?.files?.[0] as any);
                            obj.image = url;
                            if (url) {
                              onChangeBitFields(obj, 'instructionType', sectionIndex, itemIndex, bitIndex, '');
                            }
                            setIsUploadingImageToS3(false);
                          }
                        }
                          accept="image/png, image/jpeg"
                        />
                        <img
                          alt=""
                          loading="eager"
                          src={String(obj.image)}
                          height="100%"
                          className="instruction_image"
                        />
                        <div className="imageTextContainer">
                          <div
                            role="button"
                            onClick={replaceImage}
                            className="instruction_image__replace_wrap"
                          >
                            <img
                              src={ImageLinks.espectro.media}
                              alt="checklist question required"
                              width={24}
                              height={24}
                              style={{ pointerEvents: 'all' }}
                            />
                            <p className="instruction_image_upload_title">{t(TRANSLATIONS.REPLACE_MEDIA_REFERENCE)}</p>
                          </div>
                          <div
                            role="button"
                            onClick={() => {
                              obj.image = '';
                              onChangeBitFields(obj, 'instructionType', sectionIndex, itemIndex, bitIndex, '');
                            }}
                            className="instruction_image_delete_wrap"
                          >
                            <img
                              src={ImageLinks.espectro.deleteFill}
                              alt="delete section"
                              width={24}
                              height={24}
                              color="white"
                            />
                          </div>
                        </div>
                      </div>
                    )
                )}
            </div>
          </div>
        );
      case 'dropdown':
        return (
          <div className="add_answer-checklist-question-type-nested-checklist-container">
            <SelectGroupDropdown
              placeholder={bit.props.title as string || t(TRANSLATIONS.SELECT_OPTION)}
              name={t(TRANSLATIONS.CHECKLIST_OPTIONS)}
              bgColor="white"
              value={bit.type as string}
              onChange={e => {
                onChangeBitFields(e.target.value, 'dropdown', sectionIndex, itemIndex, bitIndex, e.target.innerText);
              }}
              dropdownWrapperClassName="add_answers-checklist-builder_select-dropdown"
              dropdownContent={routinesAvailable || []}

            />
          </div>
        );
      case 'blueprint':
        return (
          <>
            <div style={{ alignContent: 'center' }}>
              {String(sections[sectionIndex].items[itemIndex].bits[bitIndex].props.blueprint) === '' || sections[sectionIndex].items[itemIndex].bits[bitIndex].props.blueprint === undefined
                ? (isUploadingImageToS3
                  && sectionIndex === selectedSection
                  && itemIndex === selectedSectionItem) ? <LoadingSpinner text="" /> : (
                    <Input
                      type="file"
                      acceptType="image/png, image/jpeg"
                      placeholder="Enter text"
                      className="checklist-builder_padding_8 bg-white"
                      value=""
                      onChange={
                        async e => {
                          const url = await uploadToS3(e.target.files[0]);
                          if (url) {
                            onChangeBitFields(url, 'blueprint', sectionIndex, itemIndex, bitIndex, '');
                          }
                          setIsUploadingImageToS3(false);
                        }
                      }
                    />
                  )
                : (
                  <div className="addAnswerImageContainer">
                    <img
                      alt=""
                      loading="eager"
                      src={String(sections[sectionIndex].items[itemIndex]
                        .bits[bitIndex].props.blueprint)}
                      height="100%"
                      style={{
                        textAlign: 'center',
                        display: 'block',
                        justifyContent: 'center',
                        alignItems: 'center',
                        margin: 'auto',
                        width: '100%',
                      }}
                    />
                    <div className="imageTextContainerV1">
                      <button
                        type="button"
                        onClick={() => {
                          onChangeBitFields('', 'blueprint', sectionIndex, itemIndex, bitIndex, '');
                        }}
                        className="reUploadImage"
                      >
                        <img src={ImageLinks.espectro.upload} alt="delete section" width={24} height={24} color="white" style={{ marginRight: 10 }} />
                        {t(TRANSLATIONS.REPLACE_FILE)}
                      </button>
                      <button
                        type="button"
                        onClick={() => {
                          onChangeBitFields('', 'blueprint', sectionIndex, itemIndex, bitIndex, '');
                        }}
                        style={{ backgroundColor: 'red', borderColor: 'red', display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center', fontSize: 10, marginTop: 12 }}
                        className="delete-image"
                      >
                        <img src={ImageLinks.espectro.whiteDelete} alt="delete section" width={24} height={24} color="white" style={{ marginRight: 10 }} />
                        {t(TRANSLATIONS.DELETE)}
                      </button>
                    </div>
                  </div>
                )}
            </div>
          </>
        );

      default:
        break;
    }
  };

  const onAddSection = () => {
    setSections([...sections, { ...SECTION_INIT, key: `key__${sections.length + 1}` }]);
  };

  function onDragEnd(result: DropResult) {
    if (!result.destination) return;
    const { source, destination } = result;
    const destinationSectionIndex = sections.findIndex(
      section => section.key === destination.droppableId,
    );
    const updatedSections: ChecklistSection[] = JSON.parse(JSON.stringify(sections));
    if (source.droppableId !== destination.droppableId) {
      const sourceSectionIndex = sections.findIndex(section => section.key === source.droppableId);
      const sourceSection = sections[sourceSectionIndex];
      const destinationSection = sections[destinationSectionIndex];
      const sourceItems = [...sourceSection.items];
      const [removed] = sourceItems.splice(source.index, 1);
      const destItems = [...destinationSection.items];
      destItems.splice(destination.index, 0, removed);
      sourceSection.items = sourceItems;
      destinationSection.items = destItems;
      updatedSections[sourceSectionIndex] = sourceSection;
      updatedSections[destinationSectionIndex] = destinationSection;
    } else {
      const section = updatedSections[destinationSectionIndex];
      const copiedItems = [...section.items];
      const [removed] = copiedItems.splice(source.index, 1);
      copiedItems.splice(destination.index, 0, removed);
      section.items = copiedItems;
      updatedSections[destinationSectionIndex] = section;
    }
    setSections(updatedSections);
  }

  const onDragEndSection = (result: DropResult) => {
    if (!result.destination) return;
    const { source, destination } = result;
    const destinationSectionIndex = sections.findIndex(
      section => section.key === destination.droppableId,
    );
    const updatedSections: ChecklistSection[] = JSON.parse(JSON.stringify(sections));
    if (source.droppableId !== destination.droppableId) {
      const sourceSectionIndex = sections.findIndex(section => section.key === source.droppableId);
      const sourceSection = sections[sourceSectionIndex];
      const destinationSection = sections[destinationSectionIndex];
      const sourceItems = [...sourceSection.items];
      const destItems = [...destinationSection.items];
      sourceSection.items = sourceItems;
      destinationSection.items = destItems;
      updatedSections[destinationSectionIndex] = sourceSection;
      updatedSections[sourceSectionIndex] = destinationSection;
    } else {
      const section = updatedSections[destinationSectionIndex];
      const copiedItems = [...section.items];
      const [removed] = copiedItems.splice(source.index, 1);
      copiedItems.splice(destination.index, 0, removed);
      section.items = copiedItems;
      updatedSections[destinationSectionIndex] = section;
    }
    setSections(updatedSections);
  };

  const getPlaceholderInfoFromQuestionBit = (bit: ChecklistBit) => {
    if (bit.type) {
      const labelTag = BIT_TYPE_SELECT_OPTIONS_V2.find(b => b.value === bit.type)?.label;
      return labelTag;
    }
    return t(TRANSLATIONS.SELECT_ANSWER);
  };

  const updateSectionAndItemIndexes = (sectionIndex: number, itemIndex: number) => {
    setSelectedSection(sectionIndex);
    setSelectedSectionItem(itemIndex);
  };

  const enableQuestionTypesPopupAndUpdateIndices = (
    sectionIndex: number,
    questionIndex: number,
  ): void => {
    focusInput(`section${sectionIndex}_item${questionIndex}`);
    setIsQuestionTypesPopupOpen(true);
    setIsQuestionTitleInputFocused(true);
    updateSectionAndItemIndexes(sectionIndex, questionIndex);
  };

  const duplicateQuestion = (sectionIndex: number, itemIndex: number) => {
    const copySections = [...sections];
    const item = cloneDeep(copySections[sectionIndex].items[itemIndex]);
    const currentSectionQuestions = copySections[sectionIndex].items;
    item.key = `item_key__${currentSectionQuestions.length + 2}`;
    item.bits[0].key = `bit_key__${copySections[sectionIndex].items.length + 2}`;
    item.bits[0].defaultAnswer = undefined;
    copySections[sectionIndex].items.splice(itemIndex, 0, item);
    setSections(copySections);
    scrollToSectionItems(sectionIndex, itemIndex, 'center');
  };

  function extractNumber(key: string) {
    return parseInt(key?.split?.('__')[1], 10);
  }

  const duplicateSections = (sectionIndex: number) => {
    const copySections = [...sections];
    const keys = copySections?.map(s => s.key);
    const maxNum = Math.max(...keys?.map?.(extractNumber));
    const section = cloneDeep(copySections[sectionIndex]);
    section.key = `key__${maxNum + 2}`;
    copySections.splice(sectionIndex, 0, section);
    setSections(copySections);
    scrollToSectionHelper(section.key, 'center');
  };

  const getLogicalTriggerForCurrentQuestion = (
    sectionIndex: number,
    itemIndex: number,
  ): string => {
    const questionLogicTriggerIndex = localLogicTriggers.findIndex(trigger => (
      trigger.sectionIndex === sectionIndex
      && trigger.itemIndex === itemIndex
    ) && (
      trigger.trigger === Actions.SHOW_ANSWER
    ));
    // if (questionLogicTriggerIndex >= 0) {
    //   const dependingQuestion = localLogics[questionLogicTriggerIndex];
    //   const { title } = sections[dependingQuestion.sectionIndex].items[dependingQuestion.itemIndex];
    //   const operationTypeEquals = dependingQuestion.operation === showIfOperators.equals;
    //   if (operationTypeEquals) {
    //     return t(TRANSLATIONS.LOGICAL_CONDITION_HIGHLIGHT, {
    //       questionTitle: title,
    //       answer: dependingQuestion.value,
    //     });
    //   }
    //   return t(TRANSLATIONS.LOGICAL_CONDITION_TEXT_WHEN_ANSWERED, {
    //     questionTitle: title,
    //   });
    // }
    return '';
  };

  const onClickImage = (image: string) => {
    setShowImageModal(true);
    setSelectedImage(image);
  };

  const onCloseImageModal = () => {
    setShowImageModal(false);
  };

  const ThemeSwitch = withStyles({
    switchBase: {
      color: '#D3D3D3',
      '&$checked': {
        color: '#6C63FF',
      },
      '&$checked + $track': {
        backgroundColor: '#6C63FF',
      },
    },
    checked: {},
    track: {},
  })(Switch);

  return (
    <>
      {showImageModal && (
      <Modal
        modalContainerStyles={{
          width: '48.2vw',
          height: '66vh',
          display: 'flex',
          flexDirection: 'column',
          position: 'absolute',
          left: '50%',
          top: '50%',
          transform: 'translate(-50%, -50%)',
          overflow: 'hidden',
          padding: '0px',
          borderRadius: '0px',
        }}
        onOverlayClick={onCloseImageModal}
      >
        <div className="instruction_image_v1_modal_wrap">
          <img
            alt=""
            loading="eager"
            src={selectedImage}
            height="100%"
            className="instruction_image_v1_modal"
          />
        </div>
      </Modal>
      )}
      <div className="sidebar" style={{ top: `${(headerHeight ?? 88) + 12}px`, maxHeight: `calc(100% - ${(headerHeight ?? 88) + 12}px)` }}>
        <h1>{t(TRANSLATIONS.OUTLINES).toUpperCase()}</h1>
        <DragDropContext onDragEnd={onDragEndSection}>
          <div style={{ width: '100%' }}>
            {sections.map((section, index) => (
              <Droppable key={section.key} droppableId={section.key}>
                {provided => (
                  <div
                    className="checklist-builder__item-container"
                    {...provided.droppableProps}
                    ref={provided.innerRef}
                  >
                    {provided.placeholder}
                    <Draggable key={section.key} draggableId={section.key} index={index}>
                      {dragProvided => (
                        <div
                          ref={dragProvided.innerRef}
                          {...dragProvided.draggableProps}
                          {...dragProvided.dragHandleProps}
                        >
                          <div key={section.key} className={selectedSection === index ? 'checklist-builder__logic-title-active' : 'checklist-builder__logic-title'} role="button" onClick={() => scrollToSection(index, section.key)}>
                            {index + 1}. {section.title}
                          </div>
                          {section.items.map((item, itemIndex) => (
                            <div
                              role="button"
                              key={item.key}
                              className={(selectedSectionItem === itemIndex && selectedSection === index) ? 'checklist-builder__logic-title-active item-outline' : 'checklist-builder__logic-title item-outline'}
                              onClick={() => {
                                scrollToSectionItems(index, itemIndex);
                              }}
                            >
                              - {item.title}
                            </div>
                          ))}
                        </div>
                      )}
                    </Draggable>
                  </div>
                )}
              </Droppable>
            ))}
          </div>
        </DragDropContext>
      </div>
      <div className="checklist-container">
        <div className="checklist-description-container">
          <input
            type="text"
            placeholder={t(TRANSLATIONS.ENTER_DESCRIPTION)}
            name="description"
            value={state.description}
            onChange={onChangeDescription}
            maxLength={CHECKLIST_DESCRIPTION_MAX_LENGTH}
            className="header-description-v1"
          />
          <div className="add_answers-vertical-margin">
            <div className="checklist-builder__flex-row checklist-builder__input-row checklist-builder__flex-justify-start no-margin flex-between">
              <p>{t(TRANSLATIONS.REQUIRES_SIGNATURE)}:</p>
              <ThemeSwitch
                checked={captureSignatureBeforeSubmit}
                onChange={(_: any, checked: boolean) => setCaptureSignatureBeforeSubmit(checked)}
                name="requires signature"
              />
            </div>
            <div className="checklist-builder__flex-row checklist-builder__input-row checklist-builder__flex-justify-start no-margin flex-between">
              <p>{t(TRANSLATIONS.FREQUENCY)}:</p>
              <SelectDropdown
                selectWidth={180}
                name="Frequency"
                bgColor="transparent"
                value={state.frequency}
                onChange={onChangeFrequency}
                dropdownContent={FREQUENCY}
              />
            </div>
          </div>
        </div>
        <DragDropContext onDragEnd={onDragEnd as any}>
          <div style={{ width: '100%' }}>
            {sections.map((section, index) => (
              <Droppable key={section.key} droppableId={section.key}>
                {provided => (
                  <div className="section-container">
                    <div
                      className="add_answers-section-header-row"
                    >
                      <div
                        id={section.key}
                        className="checklist-toggle-container"
                      >
                        <SectionHeader
                          onClickDropdownIcon={() => toggleSectionAccordion(index, undefined)}
                          iconClass={section.isExpanded ? 'add_answers-section-drop-icon' : 'add_answers-section-collapsed-icon'}
                          onChangeSectionTitle={(e: any) => onChangeSectionFields(e.target.value, 'title', index)}
                          sectionTitle={section.title}
                        />
                        <span
                          role="button"
                          onClick={toggleSectionAccordion.bind(null, index, undefined)}
                          className="clickable-area"
                        />
                      </div>
                      <div className="duplicate_section_container">
                        <img
                          src={ImageLinks.espectro.duplicateV1}
                          alt="duplicate sections"
                          width={38}
                          height={38}
                          style={{ cursor: 'pointer' }}
                          onClick={() => duplicateSections(index)}
                        />
                      </div>
                      {
                        sections.length > 1 && (
                          <div className="add_answers-section-actions-wrapper">
                            <img
                              src={ImageLinks.espectro.delete}
                              alt="Delete question"
                              width={38}
                              height={38}
                              style={{ cursor: 'pointer' }}
                              onClick={e => {
                                e.stopPropagation();
                                onDeleteSection(index);
                              }}
                            />
                          </div>
                        )
                      }
                    </div>
                    {
                      section.isExpanded && (
                        <div style={{ flex: 1, width: '100%' }}>
                          <input
                            className="question-box"
                            onChange={e => { onChangeSectionFields(e.target.value, 'description', index); }}
                            type="text"
                            maxLength={CHECKLIST_DESCRIPTION_MAX_LENGTH}
                            placeholder={t(TRANSLATIONS.ADD_SECTION_DESCRIPTION)}
                            value={section.description}
                          />
                        </div>
                      )
                    }
                    <div
                      className="checklist-builder__item-container"
                      {...provided.droppableProps}
                      ref={provided.innerRef}
                    >
                      {provided.placeholder}
                      {section.isExpanded && section.items.map((item, itemIndex) => {
                        const logicaltrigger = getLogicalTriggerForCurrentQuestion(
                          index,
                          itemIndex,
                        );
                        return (
                          <Draggable key={`${section.key}_${item.key}`} draggableId={`${section.key}_${item.key}`} index={itemIndex}>
                            {(dragProvided, snapshot) => (
                              <div
                                id={`${index}_${itemIndex}`}
                                ref={dragProvided.innerRef}
                                {...dragProvided.draggableProps}
                                {...dragProvided.dragHandleProps}
                                className="add_answer-checklist-question-container"
                              >
                                <div
                                  role="button"
                                  key={item.key}
                                  className="checklist_question_container"
                                  style={{ opacity: snapshot.isDragging ? 0.5 : 1 }}
                                >
                                  <div className="question_input_wrapper-v1">
                                    {
                                      logicaltrigger !== '' && (
                                        <div className="add_answers-floating-question-logic-tag">
                                          <img
                                            src={ImageLinks.espectro.thunderIcon}
                                            alt="logic trigger"
                                            width={16}
                                            height={16}
                                          />
                                          <p>{logicaltrigger}</p>
                                        </div>
                                      )
                                    }
                                    <div
                                      className="checklist_add_answers-question-actions-wrapper-Icons"
                                      style={{ top: logicaltrigger !== '' ? 36 : 22 }}
                                    >
                                      <div style={{ display: 'flex', flexDirection: 'column' }}>
                                        <img
                                          src={ImageLinks.espectro.notesIcon}
                                          alt="notes enabled"
                                          title={t(TRANSLATIONS.NOTES)}
                                          width={16}
                                          height={16}
                                          style={{ opacity: item.bits[0].additionalFeatures
                                            ?.allowsNote ? 1 : 0 }}
                                        />
                                        <img
                                          src={ImageLinks.espectro.blackDot}
                                          alt="question required"
                                          width={16}
                                          title="Question is mandatory"
                                          height={16}
                                          style={{ opacity: item.required ? 1 : 0 }}
                                        />
                                      </div>
                                      <div style={{ display: 'flex', flexDirection: 'column' }}>
                                        <img
                                          src={ImageLinks.espectro.refresh}
                                          alt="nested checklist"
                                          title="Checklist is repeatable"
                                          width={16}
                                          height={16}
                                          style={{ opacity: item.canBeRepeated ? 1 : 0 }}
                                        />
                                        <img
                                          src={ImageLinks.espectro.flash}
                                          alt="logic trigger"
                                          title="Logical trigger"
                                          width={16}
                                          height={16}
                                          style={{ opacity: logicaltrigger !== '' ? 1 : 0 }}
                                        />

                                      </div>
                                    </div>
                                    <div className="question_input_wrapper">
                                      <div
                                        role="button"
                                        className="input-container"
                                      >
                                        <input
                                          type="text"
                                          className="input-style"
                                          placeholder={t(TRANSLATIONS.ENTER_TITLE)}
                                          value={item.title}
                                          maxLength={QUESTION_MAX_LENGTH}
                                          id={`section${index}_item${itemIndex}`}
                                          onFocus={() => {
                                            setSelectedSection(index);
                                            setSelectedSectionItem(itemIndex);
                                            setIsQuestionTitleInputFocused(true);
                                          }}
                                          onBlur={setIsQuestionTitleInputFocused.bind(null, false)}
                                          onChange={e => {
                                            onChangeItemFields(
                                              e.target.value,
                                              'title',
                                              index,
                                              itemIndex,
                                            );
                                          }}
                                          style={
                                            (item?.bits?.[0]?.type === ChecklistBitTypes.ACK
                                              && item?.bits?.[0]?.props?.instructionImage)
                                              ? {
                                                paddingRight: 50,
                                              }
                                              : {}
                                          }
                                        />
                                        {item?.bits?.[0]?.type === ChecklistBitTypes.ACK && item?.bits?.[0]?.props?.instructionImage ? (
                                          <div
                                            role="button"
                                            className="instruction_image_v1_wrap"
                                            onClick={() => onClickImage(String(item?.bits?.[0]?.props?.instructionImage ?? ''))}
                                          >
                                            <img
                                              alt=""
                                              loading="eager"
                                              src={String(item?.bits?.[0]?.props?.instructionImage ?? '')}
                                              height="100%"
                                              className="instruction_image_v1"
                                            />
                                          </div>
                                        ) : null}
                                      </div>
                                      <div className="dropdown-container">
                                        <div
                                          role="button"
                                          className="checklist-question-popup"
                                          onClick={
                                            enableQuestionTypesPopupAndUpdateIndices.bind(
                                              null,
                                              index,
                                              itemIndex,
                                            )
                                          }
                                        >
                                          <p>{getPlaceholderInfoFromQuestionBit(item.bits[0])}</p>
                                          <img src={ImageLinks.chevronDown} width={24} height={24} alt="chevron-down" />
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div
                                    className="checklist_add_answers-question-actions-wrapper"
                                    style={logicaltrigger !== '' ? { marginTop: 14 } : {}}
                                  >
                                    <img
                                      src={ImageLinks.copyIcon}
                                      alt="duplicate question"
                                      width={20}
                                      height={20}
                                      style={{ cursor: 'pointer' }}
                                      onClick={() => duplicateQuestion(index, itemIndex)}
                                    />
                                    <img
                                      width={38}
                                      height={38}
                                      src={ImageLinks.espectro.threeDots}
                                      alt="moreoptions"
                                      style={{ marginLeft: 12, cursor: 'pointer' }}
                                    />
                                    <img
                                      src={ImageLinks.espectro.delete}
                                      alt="Delete question"
                                      width={38}
                                      height={38}
                                      style={{ marginLeft: 5, cursor: 'pointer' }}
                                      onClick={() => onDeleteItem(index, itemIndex)}
                                    />
                                  </div>
                                  {/* <div className="add_answers-ai-suggestion-container">
                                    <AISuggestionForQuestion
                                      titleText={item.title}
                                    />
                                  </div> */}
                                </div>
                                {
                                  isQuestionTitleInputFocused
                                    && index === selectedSection
                                    && itemIndex === selectedSectionItem
                                    && item.bits[0].type !== ChecklistBitTypes.ACK
                                    && (
                                    <div className="add_answer-checklist-add-question-description">
                                      {/* Can be repeated */}
                                      {
                                        item.bits[0].type === ChecklistBitTypes.NESTED_CHECKLIST
                                        && (
                                          <span
                                            role="button"
                                            onClick={() => {
                                              onChangeItemFields(
                                                !item.canBeRepeated,
                                                'canBeRepeated',
                                                index,
                                                itemIndex,
                                              );
                                            }}
                                            className="add_answers-checklist-radio-buttons"
                                          >
                                            <img
                                              src={item.canBeRepeated as boolean
                                                ? ImageLinks.espectro.successCheck
                                                : ImageLinks.espectro.emptyCheck}
                                              alt="checklist repeatable"
                                              width={16}
                                              height={16}
                                              style={{ pointerEvents: 'all' }}
                                            />
                                            <p style={{ marginLeft: 6 }}>
                                              {t(TRANSLATIONS.ENABLE_REPEATITION)}
                                            </p>
                                          </span>
                                        )
                                      }
                                      <span
                                        role="button"
                                        onClick={() => {
                                          onChangeBitFields(
                                            !(item.bits[0].additionalFeatures?.allowsNote),
                                            'allowsNote',
                                            index,
                                            itemIndex,
                                            0,
                                            '',
                                          );
                                        }}
                                        className="add_answers-checklist-radio-buttons"
                                      >
                                        <img
                                          src={item.bits[0].additionalFeatures
                                              ?.allowsNote as boolean
                                            ? ImageLinks.espectro.successCheck
                                            : ImageLinks.espectro.emptyCheck}
                                          alt="checklist allows note"
                                          width={16}
                                          height={16}
                                          style={{ pointerEvents: 'all' }}
                                        />
                                        <p style={{ marginLeft: 6 }}>{t(TRANSLATIONS.NOTE)}s</p>
                                      </span>

                                      <span
                                        role="button"
                                        onClick={() => {
                                          onChangeItemFields(
                                            !item.required,
                                            'isRequired',
                                            index,
                                            itemIndex,
                                          );
                                        }}
                                        className="add_answers-checklist-radio-buttons"
                                      >
                                        <img
                                          src={item.required as boolean
                                            ? ImageLinks.espectro.successCheck
                                            : ImageLinks.espectro.emptyCheck}
                                          alt="checklist question required"
                                          width={16}
                                          height={16}
                                          style={{ pointerEvents: 'all' }}
                                        />
                                        <p style={{ marginLeft: 6 }}>{t(TRANSLATIONS.REQUIRED)}</p>
                                      </span>
                                    </div>
                                    )
                                }
                              </div>
                            )}
                          </Draggable>
                        );
                      })}
                    </div>
                    {section.isExpanded && (
                      <div className="checklist-builder__button-container">
                        <EspectroBasicButton
                          onClick={() => { onAddItem(index); }}
                          text={`+ ${t(TRANSLATIONS.ADD_ITEM)}`}
                          className="checklist-builder_add-item-button"
                        />
                        <EspectroBasicButton
                          onClick={() => { onAddInstruction(index); }}
                          text={`+ ${t(TRANSLATIONS.ADD_INSRUCTION)}`}
                          className="checklist-builder_add-item-button"
                        />
                      </div>
                    )}
                  </div>
                )}
              </Droppable>
            ))}
          </div>
        </DragDropContext>

        {/* Horizontal line */}
        <div className="add_answer-horizontal-break" />
        <EspectroBasicButton
          onClick={onAddSection}
          text={`+ ${t(TRANSLATIONS.ADD_SECTION)}`}
          className="checklist-builder_add-section-button"
        />

        {
          isQuestionTypesPopupOpen && (
            <QuestionPopUp
              isOpen={isQuestionTypesPopupOpen}
              defaultData={sections[selectedSection].items[selectedSectionItem]}
              onCancel={() => !isUploadingImageToS3 && setIsQuestionTypesPopupOpen(false)}
              onClose={() => !isUploadingImageToS3 && setIsQuestionTypesPopupOpen(false)}
              sectionIndex={selectedSection}
              itemIndex={selectedSectionItem}
              onChangeBitFields={onChangeBitFields}
              onChangeItemFields={onChangeItemFields}
              renderConfigureComponents={renderConfigureComponents}
              onChangeDefaultAnswer={onChangeDefaultAnswer}
            />
          )
        }
      </div>
    </>
  );
};

export default AddAnswers;
