import 'core-js/es/map';
import 'core-js/es/set';
import 'core-js/es/object/assign';

import 'raf/polyfill';
import 'es6-promise/auto';

import React from 'react';
import ReactDOM from 'react-dom';
import './styles/application.scss';
import { Provider } from 'react-redux';
import { applyMiddleware, compose, createStore } from 'redux';
import ReduxThunk from 'redux-thunk';
import cubejs from '@cubejs-client/core';
import { CubeProvider } from '@cubejs-client/react';
import { ApolloProvider } from '@apollo/client';
import { ToastContainer } from 'react-toastify';
import Bugsnag from '@bugsnag/js';
import BugsnagPluginReact from '@bugsnag/plugin-react';

import { isUserAuthenticated } from 'store/actions/user.actions';
import rootReducer from 'store/reducers';
import Routes from 'routes';
import apolloClient from 'apolloClient';
import * as serviceWorker from './serviceWorker';

import 'react-toastify/dist/ReactToastify.css';
import './i18n';

Bugsnag.start({
  apiKey: 'e33856cf4c56863803c5dcf34c88823a',
  plugins: [new BugsnagPluginReact()],
});
// @ts-ignore
const ErrorBoundary = Bugsnag.getPlugin('react')
  .createErrorBoundary(React);
// console.log = logIntercept;

/* eslint-disable no-undef */
const composeEnhancers = (window
  && (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) || compose;
/* eslint-enable no-undef */
const store = createStore(rootReducer, composeEnhancers(applyMiddleware(ReduxThunk)));

// INITIAL AUTHENTICATION!!!
(async () => {
  await store.dispatch(isUserAuthenticated());
})();

// ios viewport bug fix
(function iosViewPortBugFix() {
  window.onresize = () => {
    // @ts-ignore
    document.getElementById('root').style.height = `${window.innerHeight}px`;
  };
  // @ts-ignore
  window.onresize();
}());

const cubejsApi = cubejs('', { apiUrl: '/api/cubejs-api/v1' });

const App = () => {
  return (
    <ErrorBoundary>
      <ApolloProvider client={apolloClient}>
        <Provider store={store}>
          <CubeProvider cubejsApi={cubejsApi}>
            <ToastContainer />
            <Routes />
          </CubeProvider>
        </Provider>
      </ApolloProvider>
    </ErrorBoundary>
  );
};

// const HotReloadApp = hot(App);
if (window.self === window.top) {
  ReactDOM.render(<App />, document.getElementById('root'));
}

serviceWorker.unregister();
