import React, { useEffect, useRef, useState } from 'react';
import { COLOR_CODE } from 'types/enums';
import ImageLinks from 'utils/ImageLinks';
import ActionButton from '../Buttons/Action/Action';
import './ImageUpload.scss';

interface ImageUploadProps {
  image: Blob[];
  onImageChange: any;
}

const ImageUpload = ({ image, onImageChange }: ImageUploadProps) => {
  const [images, setImages] = useState<string[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const inputFileRef = useRef<HTMLInputElement | null>(null);
  const [imageBlobs, setImageBlobs] = useState<Blob[]>([]);

  const uploadImage = () => {
    inputFileRef.current?.click();
  };

  useEffect(() => {
    if (imageBlobs.length) {
      onImageChange(imageBlobs);
      const tempImages = imageBlobs.map(imgBlob => URL.createObjectURL(imgBlob));
      setImages(tempImages);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [imageBlobs]);

  useEffect(() => {
    if (image && image.length > 0) setImageBlobs(image);
  }, [image]);

  const onChangeImage = async (event: any) => {
    event.stopPropagation();
    event.preventDefault();
    setIsLoading(true);
    const localImage = event.target.files[0];
    setIsLoading(false);
    if (localImage) {
      setImageBlobs([...imageBlobs, localImage]);
      setImages([...images, URL.createObjectURL(localImage) || '']);
    }
  };

  const deleteImage = (index: number) => {
    const tempImages = [...images.slice(0, index), ...images.slice(index + 1)];
    setImages(tempImages);
    const tempImagesBlobs = [...imageBlobs.slice(0, index), ...imageBlobs.slice(index + 1)];
    // onImageChange(tempImagesBlobs);
    setImageBlobs(tempImagesBlobs);
  };

  return (
    <>
      <ActionButton
        text="Upload media"
        background={COLOR_CODE.ESPECTRO_PRIMARY}
        style={{
          fontSize: 15,
          fontWeight: 700,
          width: 200,
          height: 40,
          marginTop: 10,
        }}
        onClick={uploadImage}
      />

      <input
        type="file"
        id="file"
        ref={inputFileRef}
        style={{ display: 'none' }}
        onChange={onChangeImage}
        accept="image/png, image/jpeg"
      />

      <div style={{ display: 'flex', flexWrap: 'wrap', gap: 12, marginTop: 20 }}>
        {images.map((img: string, i: number) => (
          <div key={String(i)} style={{ position: 'relative' }}>
            <img
              src={img}
              alt="uploaded images"
              width={80}
              height={80}
              style={{ borderRadius: 4, objectFit: 'cover' }}
            />

            <div
              role="button"
              style={{ position: 'absolute', top: -6, right: -5 }}
              onClick={() => deleteImage(i)}
            >
              <img src={ImageLinks.crossIcon} width={20} height={20} alt="cross" />
            </div>
          </div>
        ))}
        {isLoading && <div className="spinner" />}

      </div>
    </>
  );
};

export default ImageUpload;
