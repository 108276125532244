import React, { useState } from 'react';
import Chart from 'react-apexcharts';
import { Query } from '@cubejs-client/core';
import { useCubeQuery } from '@cubejs-client/react';
import { format, startOfMonth, subYears } from 'date-fns';

import LoadingSpinner from 'components/LoadingSpinner';

import getAccidentabilityQuery from 'utils/CubeObject';
import { DefaultOptions, LineOptions } from 'utils/ChartOptions';

import '../Accidentability.scss';
import ChartDropdown, { Item } from 'components/Dropdown/ChartDropdown/ChartDropdown';
import { generateYearlyRange, getMonthNameAndIndex } from 'utils/dateHelpers';
import i18next from 'i18next';

type Props = {
  year: Item,
  showMonthDropDown: boolean,
};

const LtiVsSif = (props: Props) => {
  // TODO Comment out lines 24 to 27 and uncomment line 29 when it is approved in the delivery scope
  const currentMonthIndex = (new Date()).getMonth();
  // const currentMonthIndex = 11;
  const monthOptions = [
    getMonthNameAndIndex(i18next.language, Number(props.year.label))[currentMonthIndex],
  ];
  // const monthOptions = getMonthNameAndIndex(i18next.language, Number(props.year.label));

  const selectedDateRange = generateYearlyRange(props.year.label);
  const lastYearDateRange = generateYearlyRange((Number(props.year.label) - 1).toString());
  const [dropDownOpen, setDropDownOpen] = useState(false);
  // TODO Uncomment line 35 and comment out line 36 when it is approved in delivery scope
  // const [selectedMonth, setSelectedMonth] = useState(monthOptions[currentMonthIndex]);
  const [selectedMonth, setSelectedMonth] = useState(monthOptions[0]);

  const incidentsCountMeassure = { measure: ['AnalyticsIncidents.count'] };
  const thisYearDimension = {
    dateOptions: {
      dateRange: selectedDateRange,
      dimension: 'AnalyticsIncidents.dateOfIncidentOccurred',
    },
  };
  const defFilterOptions = {
    filterOptions: [{
      dimension: 'AnalyticsIncidents.clasificationOfIncidents',
      operator: 'contains',
      values: ['LTI'],
    }],
  };

  const mtdGraphMonthBasedDimensions = {
    dateOptions: {
      dateRange: [
        format(startOfMonth(new Date(Number(props.year.label), Number(selectedMonth.slug))), 'yyyy-MM-dd'),
        format(new Date(Number(props.year.label), Number(selectedMonth.slug) + 1), 'yyyy-MM-dd'),
      ],
      dimension: 'AnalyticsIncidents.dateOfIncidentOccurred',
    },
  };

  const mtdLastResultsYearBasedDimension = {
    dateOptions: {
      dateRange: [
        format(startOfMonth(subYears(new Date(Number(props.year.label), Number(selectedMonth.slug)), 1)), 'yyyy-MM-dd'),
        format(subYears(new Date(Number(props.year.label), Number(selectedMonth.slug) + 1), 1), 'yyyy-MM-dd'),
      ],
      dimension: 'AnalyticsIncidents.dateOfIncidentOccurred',
    },
  };

  // @ts-ignore
  const handleDropDownChange = ({ key }) => {
    setSelectedMonth(monthOptions.find(month => month.slug === key)
      || monthOptions[(new Date()).getMonth()]);
    setDropDownOpen(false);
  };

  const { resultSet: thisYearLTIResult, isLoading } = useCubeQuery(
    getAccidentabilityQuery({
      ...incidentsCountMeassure,
      ...thisYearDimension,
      ...defFilterOptions,
    }) as Query,
  );

  const { resultSet: lastYearLTIResult } = useCubeQuery(
    getAccidentabilityQuery({
      measure: ['AnalyticsIncidents.count'],
      dateOptions: {
        dateRange: lastYearDateRange,
        dimension: 'AnalyticsIncidents.dateOfIncidentOccurred',
      },
      ...defFilterOptions,
    }) as Query,
  );

  const { resultSet: mtdLTIResult } = useCubeQuery(
    getAccidentabilityQuery({
      measure: ['AnalyticsIncidents.count'],
      ...mtdGraphMonthBasedDimensions,
      ...defFilterOptions,
    }) as Query,
  );

  const { resultSet: thisYearSIFResult } = useCubeQuery(
    getAccidentabilityQuery({
      measure: ['AnalyticsIncidents.sifCount'],
      ...thisYearDimension,
    }) as Query,
  );

  const { resultSet: lastYearSIFResult } = useCubeQuery(
    getAccidentabilityQuery({
      measure: ['AnalyticsIncidents.sifCount'],
      dateOptions: {
        dateRange: lastYearDateRange,
        dimension: 'AnalyticsIncidents.dateOfIncidentOccurred',
      },
    }) as Query,
  );

  const { resultSet: mtdSIFResult } = useCubeQuery(
    getAccidentabilityQuery({
      measure: ['AnalyticsIncidents.sifCount'],
      ...mtdGraphMonthBasedDimensions,
    }) as Query,
  );

  const { resultSet: mtdLastResultLTI } = useCubeQuery(
    getAccidentabilityQuery({
      measure: ['AnalyticsIncidents.count'],
      ...mtdLastResultsYearBasedDimension,
      ...defFilterOptions,
    }) as Query,
  );

  const { resultSet: mtdLastSFIResult } = useCubeQuery(
    getAccidentabilityQuery({
      measure: ['AnalyticsIncidents.sifCount'],
      ...mtdLastResultsYearBasedDimension,
    }) as Query,
  );

  const lastYearLTI = lastYearLTIResult?.tablePivot().length
    ? lastYearLTIResult?.tablePivot()[0]['AnalyticsIncidents.count']
    : 0;
  const mtdLastLTI = mtdLastResultLTI?.tablePivot().length
    ? mtdLastResultLTI?.tablePivot()[0]['AnalyticsIncidents.count']
    : 0;

  const thisYearLTI = thisYearLTIResult?.tablePivot().length
    ? thisYearLTIResult?.tablePivot()[0]['AnalyticsIncidents.count']
    : 0;
  const thisMonthLTI = mtdLTIResult?.tablePivot().length
    ? mtdLTIResult?.tablePivot()[0]['AnalyticsIncidents.count']
    : 0;

  const lastYearSIF = lastYearSIFResult?.tablePivot().length
    ? lastYearSIFResult?.tablePivot()[0]['AnalyticsIncidents.sifCount']
    : 0;
  const mtdLastSFI = mtdLastSFIResult?.tablePivot().length
    ? mtdLastSFIResult?.tablePivot()[0]['AnalyticsIncidents.sifCount']
    : 0;

  const thisYearSFI = thisYearSIFResult?.tablePivot().length
    ? thisYearSIFResult?.tablePivot()[0]['AnalyticsIncidents.sifCount']
    : 0;
  const thisMonthSFI = mtdSIFResult?.tablePivot().length
    ? mtdSIFResult?.tablePivot()[0]['AnalyticsIncidents.sifCount']
    : 0;

  const ACPreviousYear = `AC${(Number(props.year.label) - 1).toString().slice(2)}`;
  const ACSelectedYear = `AC${props.year.label.slice(2)}`;
  const BUSelectedYear = `BU${props.year.label.slice(2)}`;

  const seriesLti = [{
    name: ACPreviousYear,
    data: [lastYearLTI, mtdLastLTI],
  }, {
    name: BUSelectedYear,
    data: [7, 4],
  }, {
    name: ACSelectedYear,
    data: [thisYearLTI, thisMonthLTI],
  }];

  const seriesSif = [{
    name: ACPreviousYear,
    data: [lastYearSIF, mtdLastSFI],
  }, {
    name: ACSelectedYear,
    data: [thisYearSFI, thisMonthSFI],
  }];

  const customOptions = {
    ...LineOptions,
    ...DefaultOptions,
    xaxis: {
      categories: ['YTD', 'MTD'],
      labels: {
        rotate: 0,
        style: {
          fontSize: '10px',
        },
      },
    },
  };

  if (isLoading) return <div className="lti-vs-sif"><LoadingSpinner /></div>;

  return (
    <div className="lti-vs-sif">
      {props.showMonthDropDown && (
      <ChartDropdown
        onChange={handleDropDownChange}
        selectedDropDownItem={selectedMonth}
        dropDownItems={monthOptions}
        dropDownOpen={dropDownOpen}
        setDropDownOpen={setDropDownOpen}
        labelClassName="label-default"
      />
      )}
      <div className="lti-vs-sif__top-row">
        <span>LTIs</span>
        <span>SIF</span>
      </div>
      <div className="lti-vs-sif__bottom-row">
        <div>
          <Chart type="bar" options={customOptions} series={seriesLti} height="320px" />
        </div>
        <div>
          <Chart type="bar" options={customOptions} series={seriesSif} height="320px" />
        </div>
      </div>
    </div>
  );
};

export default LtiVsSif;
