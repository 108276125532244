import React from 'react';

import file from 'assets/icons/file.svg';
import gallery from 'assets/icons/gallery.svg';
import video from 'assets/icons/video.svg';

import './PillWithIcon.scss';

type props = {
  pill: any;
};

const PillWithIcon = (props: props) => {
  const { url, type } = props.pill;
  const handleClick = () => {
    window.open(url, '_blank');
  };

  const text = url?.split('/').slice(-1)[0];

  let icon = gallery;
  let extension = '.jpg';
  if (type === 'VIDEO') {
    icon = video;
    extension = '.mp4';
  } else if (type === 'PDF') {
    icon = file;
    extension = '.pdf';
  }

  return (
    <button className="pill-with-icon" onClick={handleClick} type="button">
      <img src={icon} alt="file type" className="pill-with-icon__icon" />
      <span className="pill-with-icon__text">{text}</span>
      <span className="pill-with-icon__extension">{extension}</span>
    </button>
  );
};

export default PillWithIcon;
