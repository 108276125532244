import React from 'react';
import './Tabs.scss';

export type TabItem = {
  title: string;
  value?: string;
};
export type TabsProps = {
  tabsItems: TabItem[];
  setSelectedTab: (item: TabItem) => void;
  selectedTab: TabItem;
  tabStyle?: string;
  tabNotSelectedStyle?: string;
};

const Tabs = (props: TabsProps) => {
  const { tabsItems, setSelectedTab, selectedTab, tabStyle = '', tabNotSelectedStyle = '' } = props;

  return (
    <div className={`tabs ${tabStyle}`}>
      {tabsItems.map(item => (
        <div
          key={`${item.title}`}
          role="button"
          onClick={() => {
            setSelectedTab(item);
          }}
          className={`tab-item ${item.title === selectedTab.title ? 'tab-item-selected' : tabNotSelectedStyle}`}
        >
          <p className="tab-title">{item.title}</p>
        </div>
      ))}
    </div>
  );
};

export default Tabs;
