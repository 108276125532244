import React, { useEffect, useMemo, useState } from 'react';
import clsx from 'clsx';
import WebsiteVersion from 'components/WebsiteVersion';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Collapse from '@material-ui/core/Collapse';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import Drawer from '@material-ui/core/Drawer';
import Hidden from '@material-ui/core/Hidden';
import { useHistory } from 'react-router-dom';
import infoGreen from 'assets/icons/espectro/info-green.svg';
import infoGrey from 'assets/icons/espectro/info-grey.svg';
import { TRANSLATIONS } from 'types/enums';
import { useTranslation } from 'react-i18next';
import { RoutesForNavigationDrawerWithVerticalMenu } from '../../../types/interfaces';
import useStyles from '../NavigationDrawerStyles';
import { isEspectro } from '../../../constants';

type TCollapsibleRoute = {
  routeName: string;
  isOpen: boolean;
  parentRouteName: string;
};

export type Props = {
  routes: RoutesForNavigationDrawerWithVerticalMenu[];
  openDrawer: boolean
};

const userManualRoute = '/espectro/user-manual';

function NavigationDrawerWithVerticalMenu(
  { routes, openDrawer }: Props,
) {
  const { t } = useTranslation();
  const classes = useStyles();
  const history = useHistory();
  const [
    collapsibleRoutes, setCollapsibleRoutes,
  ] = React.useState<TCollapsibleRoute[]>([]);
  const [currentActiveRoute, setCurrentActiveRoute] = useState<string>(
    history.location.pathname || '',
  );

  function handleNavigation(routeName: string) {
    history.push(routeName);
    setCurrentActiveRoute(routeName);
  }

  function handleCollapse(toOpenRouteConfig: TCollapsibleRoute, parentRouteName: string) {
    const [{ collapseList }] = routes.filter(route => route.routeName === parentRouteName);
    const collapsibleRoutesValue: TCollapsibleRoute[] = [];
    collapseList?.forEach(
      route => collapsibleRoutesValue.push(
        { ...route, parentRouteName, isOpen: !toOpenRouteConfig.isOpen },
      )
    );
    setCollapsibleRoutes(collapsibleRoutesValue);
  }

  function handleNavItemClick(
    routeName: string, isCollapse: boolean, toOpenRouteConfig: TCollapsibleRoute,
  ) {
    if (isCollapse) {
      handleCollapse(toOpenRouteConfig, routeName);
    } else {
      handleNavigation(routeName);
    }
  }
  const selectedRoute = useMemo(
    () => history.location.pathname, [history.location.pathname],
  );

  useEffect(() => {
    const routesWithCollapseList = routes
      .filter(route => !!route.collapseList?.length)[0];

    setCollapsibleRoutes((routesWithCollapseList?.collapseList || [])
      .map(({ routeName }) => ({
        routeName,
        isOpen: selectedRoute === routeName,
        parentRouteName: routesWithCollapseList.routeName,
      })));

    /* setCollapsibleRoutes(routes
      .filter(route => !!route.collapseList?.length)
      .map(({ routeName }) => ({ routeName, isOpen: selectedRoute === routeName }))); */
  }, [selectedRoute, routes]);

  // @ts-ignore
  return (
    <Hidden xsDown implementation="css">
      <Drawer
        variant="permanent"
        anchor="left"
        open={openDrawer}
        classes={{
          paper: classes.drawerPaper,
          paperAnchorLeft: classes.DrawerPaperAnchorLeft,
        }}
      >
        {routes //  all my routes
          .map(
            (route, key) => {
              const [toOpenCheck] = collapsibleRoutes
                .filter(
                  collapse => collapse.parentRouteName === route.routeName,
                );

              return (
                <React.Fragment {...{ key }}>
                  <ListItem
                    classes={{
                      root: classes.root,
                      selected: classes.selected,
                    }}
                    button
                    onClick={() => handleNavItemClick(
                      route.routeName, !!route.collapseList?.length, toOpenCheck,
                    )}
                    selected={currentActiveRoute === route.routeName}
                    className={clsx(
                      (toOpenCheck?.isOpen
                        && route.collapseList?.map(collapseList => collapseList.routeName)
                          .includes(currentActiveRoute))
                        || (route.matchKey && currentActiveRoute.includes(route.matchKey))
                        ? [classes.primary, classes.highlightBackground]
                        : null,
                    )}
                  >
                    <img
                      src={(currentActiveRoute === route.routeName
                        || route
                          .collapseList?.map(collapseList => collapseList.routeName)
                          .includes(currentActiveRoute))
                          || (route.matchKey && currentActiveRoute.includes(route.matchKey))
                        ? route.listItemIconActive : route.listItemIcon}
                      alt={route.routeName}
                      className={classes.listIcon}
                    />
                    <ListItemText
                      primary={route.listItemText}
                      className={clsx(
                        (currentActiveRoute === route.routeName
                          || (route.matchKey && currentActiveRoute.includes(route.matchKey))
                        ) && classes.primary,
                      )}
                      classes={{
                        primary: classes.rootText,
                      }}
                    />
                    {!!route.collapseList?.length && (toOpenCheck?.isOpen
                      ? <ExpandLess /> : <ExpandMore />)}
                  </ListItem>
                  {!!route.collapseList?.length && route.collapseList && toOpenCheck?.isOpen && (
                    <Collapse
                      in={toOpenCheck.isOpen}
                      timeout="auto"
                      unmountOnExit
                      classes={{
                        wrapperInner: classes.nested,
                      }}
                      style={{ minHeight: 'none' }}
                    >
                      <List component="div" disablePadding>
                        {route.collapseList
                          .map((collapseList, keyCollapse) => (
                            <ListItem
                              classes={{
                                root: classes.rootInsideCollapse,
                                selected: classes.selectedInsideCollapse,
                              }}
                              button
                              key={keyCollapse.toString()}
                              onClick={() => handleNavigation(collapseList.routeName)}
                              selected={currentActiveRoute === collapseList.routeName}
                            >
                              <ListItemText
                                style={{ width: '100px' }}
                                className={clsx(
                                  currentActiveRoute === collapseList.routeName && classes.primary,
                                )}
                                primary={collapseList.listItemText}
                              />
                            </ListItem>
                          ))}
                      </List>
                    </Collapse>
                  )}
                </React.Fragment>
              );
            },
          )}
      </Drawer>
      {!isEspectro ? (
        <div className={clsx(
          classes.version,
        )}
        >
          <WebsiteVersion />
        </div>
      ) : (
        <div className={clsx(
          classes.userManual,
        )}
        >
          <ListItem
            classes={{
              root: classes.root,
              selected: classes.selected,
            }}
            button
            onClick={() => handleNavItemClick(
              userManualRoute, false, {} as any,
            )}
            selected={currentActiveRoute === userManualRoute}
          >
            <img
              src={currentActiveRoute === userManualRoute
                ? infoGreen : infoGrey}
              alt={userManualRoute}
              className={classes.userManualListIcon}
            />
            <ListItemText
              primary={t(TRANSLATIONS.USER_MANUAL)}
              className={clsx(
                (currentActiveRoute === userManualRoute)
               && classes.primary,
              )}
              classes={{
                primary: classes.rootText,
              }}
            />
          </ListItem>
        </div>
      )}
    </Hidden>
  );
}

export default NavigationDrawerWithVerticalMenu;
