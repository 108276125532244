import React from 'react';
import { Backdrop, Button, CircularProgress, Fade, Modal } from '@material-ui/core';
import { TRANSLATIONS } from 'types/enums';
import './ShareableChecklistModal.styles.scss';
import { useTranslation } from 'react-i18next';

interface ModalProps {
  isOpen: boolean;
  onConfirm: () => void;
  onClose: () => void;
  link: string;
  routineName: string;
  isLoading: boolean;
}

const ShareableChecklistModal: React.FC<ModalProps> = ({
  isOpen,
  onConfirm,
  link,
  onClose,
  isLoading,
  routineName,
}) => {
  const { t } = useTranslation();

  const handleOnConfirm = () => {
    onConfirm();
  };

  return (
    <Modal
      className="comment-delete-modal"
      open={isOpen}
      closeAfterTransition
      BackdropComponent={Backdrop}
      onBackdropClick={() => (!isLoading ? onClose() : () => null)}
      BackdropProps={{
        timeout: 1,
      }}
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
    >
      <Fade in={isOpen}>
        <div className="comment-delete-modal__content">
          {
            isLoading && (
              <div className="shareable_link_model_loader_wrapper">
                <CircularProgress />
              </div>
            )
          }
          {
            !isLoading && (
              <>
                <header>
                  <span className="header_title">{t(TRANSLATIONS.SHAREABLE_COMMENT)} {routineName}</span>
                </header>
                <body>
                  <div>
                    <div className="descriptionBox"><span className="description">{link}</span></div>
                    <Button variant="contained" onClick={handleOnConfirm} className="copy_button">Copy link</Button>
                  </div>
                </body>
                <footer>
                  <Button variant="contained" onClick={handleOnConfirm} className="delete_button">
                    {t(TRANSLATIONS.DONE)}
                  </Button>
                </footer>
              </>
            )
          }
        </div>
      </Fade>
    </Modal>
  );
};

export default ShareableChecklistModal;
