import { fromJS } from 'immutable';
import {
  SET_ZONES_WITH_INCIDENTS,
} from '../actions/corporateDashboard.actions';

const INITIAL_STATE = fromJS({});

const corporateDashboardReducer = (state = INITIAL_STATE, action: any) => {
  switch (action.type) {
    case SET_ZONES_WITH_INCIDENTS:
      return state.set('zones', fromJS(action.zones));
    default:
      return state;
  }
};

export default corporateDashboardReducer;
