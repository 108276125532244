import React, { CSSProperties } from 'react';
import './BasicButton.styles.scss';

type BasicButtonProps = {
  text: string;
  onClick?(e?: any): void;
  className?: string;
  background?: string;
  disabled?: boolean;
  style?: CSSProperties;
};
const BasicButton = ({ text, style, disabled, onClick, className }:BasicButtonProps) => {
  return <button className={`${className} basicButton__main`} type="button" disabled={disabled} onClick={onClick} style={style}>{text}</button>;
};

export default BasicButton;
