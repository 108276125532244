import CheckBoxWithIcon from 'components/CheckBoxWithIcon';
import LoadingSpinner from 'components/LoadingSpinner';
// eslint-disable-next-line @typescript-eslint/camelcase
import { Location_Type } from 'generated/graphql';
import { fromJS } from 'immutable';
import React, { useContext, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { LOCATION_TYPES, TRANSLATIONS, UserTypes, USER_ROLE } from 'types/enums';
import ImageLinks from 'utils/ImageLinks';
import request from 'utils/request';
import { isEspectroEnabled } from 'features/Espectro/constants';
import { UserManagementContext, userPermissionType } from '../../context';
import RoutinesSelector from './routinesSelector';
import RoutinesViewer from './routinesViewer';

const { emptyRadioUnchecked, yellowRadioChecked } = ImageLinks;

const PermissionsTab = (props: any) => {
  const { t } = useTranslation();
  const { isAuthorisedForOWD, setIsAuthorisedForOWD } = props;
  const state = useContext(UserManagementContext);
  const userData = state.user;
  const {
    isEditing,
    originalUser,
    setOriginalUser,
    editingUser,
    setEditingUser,
    setIsLoading,
    isLoading,
    setUserAvailableRoles,
    user,
  } = state;

  const {
    userAvailableRoles,
  } = state as unknown as { userAvailableRoles: userPermissionType[] };

  const distributionCenterType = originalUser?.get('general')?.get('distributionCenter')?.get('type');

  const isDistributionCenter = distributionCenterType
    === Location_Type.DistributionCenter;

  const permissionsOptions = useMemo(() => {
    if (isDistributionCenter) {
      return [
        {
          id: 1,
          label: `${t(TRANSLATIONS.CERTIFICATES)}`,
          type: UserTypes.CertificatesOnly,
        },
        {
          id: 2,
          label: `${t(TRANSLATIONS.CERTIFICATES)}, ${t(TRANSLATIONS.REPORT)} ${t(
            TRANSLATIONS.INCIDENTS,
          )}, ${t(TRANSLATIONS.ACTION_LOGS)}`,
          type: UserTypes.ReporterOnlyDPO,
        },
        {
          id: 3,
          label: `${t(TRANSLATIONS.CERTIFICATES)}, ${t(TRANSLATIONS.REPORT)} ${t(TRANSLATIONS.INCIDENTS)}, ${t(TRANSLATIONS.ACTION_LOGS)}, ${t(TRANSLATIONS.WEB_ACCESS)}`,
          type: UserTypes.CorporateDPO,
        },
        {
          id: 4,
          label: `${t(TRANSLATIONS.CERTIFICATES)}, ${t(TRANSLATIONS.REPORT)}, ${t(
            TRANSLATIONS.CLOSE,
          )} ${t(TRANSLATIONS.INCIDENTS)}, ${t(TRANSLATIONS.ACTION_LOGS)}, ${t(TRANSLATIONS.WEB_ACCESS)}`,
          type: UserTypes.DistributionCenterManagerDPO,
        },
        {
          id: 5,
          label: `${t(TRANSLATIONS.CERTIFICATES)}, ${t(TRANSLATIONS.REPORT)}, ${t(
            TRANSLATIONS.CLOSE,
          )}, ${t(TRANSLATIONS.ASSIGN)} ${t(TRANSLATIONS.INCIDENTS)}, ${t(
            TRANSLATIONS.ACTION_LOGS,
          )}, ${t(TRANSLATIONS.WEB_ACCESS)}`,
          type: UserTypes.SubzoneGeneralManagerDPO,
        },
      ];
    }
    return [
      {
        id: 1,
        label: `${t(TRANSLATIONS.CERTIFICATES)}`,
        type: UserTypes.CertificatesOnly,
      },
      {
        id: 2,
        label: `${t(TRANSLATIONS.CERTIFICATES)}, ${t(TRANSLATIONS.REPORT)} ${t(
          TRANSLATIONS.INCIDENTS,
        )}, ${t(TRANSLATIONS.AMBIENTAL_RISKS)}`,
        type: UserTypes.ReporterOnlySupply,
      },
      {
        id: 3,
        label: `${t(TRANSLATIONS.CERTIFICATES)}, ${t(TRANSLATIONS.REPORT)} ${t(TRANSLATIONS.INCIDENTS)}, ${t(TRANSLATIONS.WEB_ACCESS)}`,
        type: UserTypes.CorporateSupply,
      },
      {
        id: 4,
        label: `${t(TRANSLATIONS.CERTIFICATES)}, ${t(TRANSLATIONS.REPORT)}, ${t(
          TRANSLATIONS.CLOSE,
        )} ${t(TRANSLATIONS.INCIDENTS)}, ${t(TRANSLATIONS.AMBIENTAL_RISKS)}, ${t(TRANSLATIONS.WEB_ACCESS)}`,
        type: UserTypes.DistributionCenterManagerSupply,
      },
      {
        id: 5,
        label: `${t(TRANSLATIONS.CERTIFICATES)}, ${t(TRANSLATIONS.REPORT)}, ${t(
          TRANSLATIONS.CLOSE,
        )}, ${t(TRANSLATIONS.ASSIGN)} ${t(TRANSLATIONS.INCIDENTS)}, ${t(
          TRANSLATIONS.AMBIENTAL_RISKS,
        )}, ${t(TRANSLATIONS.WEB_ACCESS)}`,
        type: UserTypes.SubzoneGeneralManagerSupply,
      },
      {
        id: 6,
        label: t(TRANSLATIONS.PRODUCTION_PERFORMANCE_TRACKING),
        type: UserTypes.BreweryViewer,
      },
    ];
  }, [isDistributionCenter, t]);

  const loadPermissions = async () => {
    let tempObj = {} as any;
    let availablePermissons;
    try {
      setIsLoading(true);
      const userRequest = request.get(`/api/v1/users/${encodeURIComponent(userData.slug)}/info`).then(res => {
        const typeFromApi = permissionsOptions.find(
          ({ type }) => res.info.type === type,
        );
        const obj = {
          permissions: {
            type: typeFromApi || permissionsOptions[1],
            roleId: res.info.roleId,
            ...tempObj.permissions,
          },
        };
        tempObj = { ...tempObj, ...obj };
      });
      const permissionRequest = request.get(`/api/v1/users/${encodeURIComponent(userData.slug)}/permissions`).then(
        res => {
          const routinesGroup = {} as any;
          res.permissions.forEach((routine: any) => {
            if (routinesGroup[routine.pillar]) {
              routinesGroup[routine.pillar].routines = (
                routinesGroup[routine.pillar].routines.concat(routine)
              );
            } else {
              routinesGroup[routine.pillar] = {
                routines: [].concat(routine),
                selected: true,
              };
            }
          });
          tempObj = {
            ...tempObj,
            permissions: {
              routines: {
                ...routinesGroup,
              },
              ...tempObj.permissions,
            },
          };
        },
      );
      const availablePermissionesRequest = request.get('/api/v1/users-available-roles').then(
        res => {
          availablePermissons = res.roles;
        },
      );
      await Promise.all([userRequest, permissionRequest, availablePermissionesRequest]);
      setOriginalUser(originalUser.merge(fromJS(tempObj)));
      setEditingUser(editingUser.merge(fromJS(tempObj)));
      setUserAvailableRoles(availablePermissons);
    } catch (e) {
      console.error(e);
    } finally {
      setIsLoading(false);
    }
  };
  useEffect(() => {
    loadPermissions();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const editableRoutinesPermissions = editingUser
    ?.get('permissions')
    ?.get('routines')
    ?.toJS();

  const routinesPermissions = originalUser
    ?.get('permissions')
    ?.get('routines')
    ?.toJS();

  const originalUserType = originalUser
    ?.get('permissions')
    ?.get('type')
    ?.toJS();

  const originalUserRoleId = originalUser
    ?.getIn(['permissions', 'roleId']);

  const editedUserRoleId = editingUser
    ?.getIn(['permissions', 'roleId']);

  const locationType = originalUser
    ?.getIn(['general', 'distributionCenter', 'type']);

  const EditableUserType = editingUser
    ?.get('permissions')
    ?.get('type')
    ?.toJS();

  function changeUserType(id: number) {
    setEditingUser((editingUserData: any) => editingUserData.setIn(['permissions', 'type'], fromJS(permissionsOptions[id - 1])));
  }

  function changeUserRoleId(id: number) {
    setEditingUser((editingUserData: any) => editingUserData.setIn(['permissions', 'roleId'], id));
  }

  const isSupplyUser = Object.values(USER_ROLE).includes(user?.userRole?.slug) || user?.type?.includes('Supply');

  if (isLoading) {
    return <LoadingSpinner />;
  }

  return (
    <div>
      {routinesPermissions && Object.keys(routinesPermissions).length > 0 && (
        <>
          {!isEspectroEnabled && (
          <div className="permissionsWrapper">
            { locationType === LOCATION_TYPES.FACTORY ? (
              !originalUserRoleId && !isEditing ? (
                <p>El usuario no tiene ningun rol asignado aun</p>
              ) : (
                userAvailableRoles.map((
                  { id, name },
                ) => (
                  isEditing || id === (isEditing ? editedUserRoleId : originalUserRoleId)
                ) && (
                <CheckBoxWithIcon
                  key={id}
                  onClick={() => {
                    if (isEditing) changeUserRoleId(id);
                  }}
                  deSelectedIcon={emptyRadioUnchecked}
                  selectedIcon={yellowRadioChecked}
                  selected={(isEditing ? editedUserRoleId : originalUserRoleId) === id}
                  label={name}
                  disabled={!isEditing}
                />
                ))
              )
            ) : (
              permissionsOptions.map(
                ({ id, label }) => (
                  isEditing || id === (isEditing ? EditableUserType : originalUserType)?.id
                ) && (
                <CheckBoxWithIcon
                  key={id}
                  onClick={() => {
                    if (isEditing) {
                      changeUserType(id);
                    }
                  }}
                  deSelectedIcon={emptyRadioUnchecked}
                  selectedIcon={yellowRadioChecked}
                  selected={(isEditing ? EditableUserType : originalUserType)?.id === id}
                  label={label}
                  disabled={!isEditing}
                />
                ),
              )
            )}
          </div>
          ) }
          {(!isEspectroEnabled) && (!isSupplyUser) && (
            <div>
              <h4>OWDs</h4>
              <CheckBoxWithIcon
                onClick={() => {
                  if (isEditing) setIsAuthorisedForOWD(!isAuthorisedForOWD);
                }}
                deSelectedIcon={emptyRadioUnchecked}
                selectedIcon={yellowRadioChecked}
                selected={isAuthorisedForOWD}
                label={t(TRANSLATIONS.APPLY_OWDS)}
                disabled={!isEditing}
              />
            </div>
          )}
          {(isEspectroEnabled || isDistributionCenter)
          && (
          <>
            {!isEspectroEnabled && <span>{t(TRANSLATIONS.ROUTINES)}</span>}
            {isEditing ? (
              <div className="user-info__bottom">
                <RoutinesSelector
                  routinesByPillars={editableRoutinesPermissions || {}}
                  setFormData={setEditingUser}
                />
              </div>
            ) : (
              <div className="user-info__bottom">
                <RoutinesViewer routinesByPillars={routinesPermissions} />
              </div>
            )}
          </>
          )}
        </>
      )}
    </div>
  );
};

export default PermissionsTab;
