/* eslint-disable react/jsx-closing-tag-location */
import React from 'react';
import keyboardActive from 'assets/icons/keyboardActive.svg';
import keyboardDisabled from 'assets/icons/keyboardDisabled.svg';
import numKeyboardDisabled from 'assets/icons/NumKeyboardDisabled.svg';
import numKeyboardActive from 'assets/icons/NumKeyboardActive.svg';
import verticalActive from 'assets/verticalActive.svg';
import verticalDisabled from 'assets/verticalDisabled.svg';
import ImageLinks from 'utils/ImageLinks';
import { TRANSLATIONS } from 'types/enums';
import { Actions, ChecklistBitTypes, showIfOperators } from './types';
import './styles.scss';
import i18 from '../../i18n';
/**
 * NOW depricated
 */
export const BIT_TYPE_SELECT_OPTIONS = [
  {
    value: ChecklistBitTypes.INPUT,
    label: 'Input de Texto',
  },
  {
    value: ChecklistBitTypes.DROPDOWN,
    label: 'Menu desplegable',
  },
  // {
  //   value: ChecklistBitTypes.CHECKLIST,
  //   label: 'Lista de opciones (selección múltiple)',
  // },
  {
    value: ChecklistBitTypes.HORIZONTAL_SINGLE_OPTION,
    label: 'Lista de opciones horizontal (selección única)',
  },
  {
    value: ChecklistBitTypes.VERTICAL_SINGLE_OPTION,
    label: 'Lista de opciones (selección única)',
  },
  {
    value: ChecklistBitTypes.VERTICAL_MULTIPLE_OPTION,
    label: 'Lista de opciones (selección múltiple)',
  },
  // {
  //   value: ChecklistBitTypes.CAROUSEL,
  //   label: 'Imágenes',
  // },
  {
    value: ChecklistBitTypes.TEXT,
    label: 'Texto',
  },
  {
    value: ChecklistBitTypes.SECTION,
    label: 'Sección con título',
  },
  // {
  //   value: ChecklistBitTypes.DATE,
  //   label: 'Selección de fecha',
  // },
  // {
  //   value: ChecklistBitTypes.LIST_OF_ITEMS,
  //   label: 'Lista de objetos',
  // },
  {
    value: ChecklistBitTypes.BODY_BOLD,
    label: 'Tipografía/Negritas',
  },
  {
    value: ChecklistBitTypes.H2,
    label: 'Tipografía/Subtítulo',
  },
  {
    value: ChecklistBitTypes.BODY20,
    label: 'Tipografía/Delgada',
  },
  {
    value: ChecklistBitTypes.SIGNATURE,
    label: 'Signature',
  },
  {
    value: ChecklistBitTypes.IMAGE_PICKER,
    label: 'Choose Image',
  },
  {
    value: ChecklistBitTypes.DATE,
    label: 'Fecha',
  },
  {
    value: ChecklistBitTypes.TIME,
    label: 'Hora',
  },
  {
    value: ChecklistBitTypes.DRIBBLE,
    label: 'Draw on Image',
  },
];
export const BIT_TYPE_SELECT_OPTIONS_V2 = [
  {
    value: ChecklistBitTypes.TEXT,
    searchKey: 'Campo para texto',
    label: <div className="checklist-builder_row">
      <img alt="text" src={ImageLinks.espectro.abc} width={32} height={32} />
      <p>Campo para texto</p>
    </div>,
    OPERATORS: [
      {
        label: 'equals',
        value: showIfOperators.equals,
      },
      {
        label: 'is answered',
        value: showIfOperators.notEmpty,
      },
    ],
  },
  {
    value: ChecklistBitTypes.INPUT,
    searchKey: 'Campo para números',
    label: <div className="checklist-builder_row">
      <img alt="text" src={ImageLinks.espectro.numberInputIcon} width={32} height={32} />
      <p>Campo para números</p>
    </div>,
    OPERATORS: [
      {
        label: 'equals',
        value: showIfOperators.equals,
      },
      {
        label: 'is answered',
        value: showIfOperators.notEmpty,
      },
      {
        label: 'is greater than',
        value: showIfOperators.greaterThan,
      },
      {
        label: 'is less than',
        value: showIfOperators.lessThan,
      },
    ],
  },
  {
    value: ChecklistBitTypes.VERTICAL_SINGLE_OPTION,
    searchKey: 'Única opción',
    label: <div className="checklist-builder_row">
      <img alt="vertical checklist" src={ImageLinks.espectro.radioIcon} width={32} height={32} />
      <p>Única opción</p>
    </div>,
    OPERATORS: [
      {
        label: 'equals',
        value: showIfOperators.equals,
      },
      {
        label: 'is answered',
        value: showIfOperators.notEmpty,
      },
    ],
  },
  {
    value: ChecklistBitTypes.VERTICAL_MULTIPLE_OPTION,
    searchKey: 'Casillas (varias opciones)',
    label: <div className="checklist-builder_row">
      <img alt="multple checklist" src={ImageLinks.espectro.checkboxIcon} width={32} height={32} />
      <p>Casillas (varias opciones)</p>
    </div>,
    OPERATORS: [
      {
        label: 'equals',
        value: showIfOperators.equals,
      },
      {
        label: 'is answered',
        value: showIfOperators.notEmpty,
      },
    ],
  },
  {
    value: ChecklistBitTypes.DROPDOWN,
    searchKey: 'Lista desplegable',
    label: <div className="checklist-builder_row">
      <img alt="menu" src={ImageLinks.espectro.dropdownIconSvg} width={32} height={32} />
      <p>Lista desplegable</p>
    </div>,
    OPERATORS: [
      {
        label: 'equals',
        value: showIfOperators.equals,
      },
      {
        label: 'is answered',
        value: showIfOperators.notEmpty,
      },
    ],
  },
  {
    value: ChecklistBitTypes.DATE,
    searchKey: 'Fecha',
    label: <div className="checklist-builder_row">
      <img alt="calender-date" src={ImageLinks.espectro.calendar} width={32} height={32} />
      <p>Fecha</p>
    </div>,
    OPERATORS: [
      {
        label: 'equals',
        value: showIfOperators.equals,
      },
      {
        label: 'is answered',
        value: showIfOperators.notEmpty,
      },
    ],
  },
  {
    value: ChecklistBitTypes.TIME,
    searchKey: 'Hora',
    label: <div className="checklist-builder_row">
      <img alt="calender-time" src={ImageLinks.espectro.calendar} width={32} height={32} />
      <p>Hora</p>
    </div>,
    OPERATORS: [
      {
        label: 'equals',
        value: showIfOperators.equals,
      },
      {
        label: 'is answered',
        value: showIfOperators.notEmpty,
      },
    ],
  },
  {
    value: ChecklistBitTypes.DRIBBLE,
    searchKey: 'Diagrama (para anotaciones)',
    label: <div className="checklist-builder_row">
      <img alt="dribble" src={ImageLinks.espectro.diagramIcon} width={32} height={32} />
      <p>Diagrama (para anotaciones)</p>
    </div>,
    OPERATORS: [
      {
        label: 'equals',
        value: showIfOperators.equals,
      },
      {
        label: 'is answered',
        value: showIfOperators.notEmpty,
      },
    ],
  },
  {
    value: ChecklistBitTypes.IMAGE_PICKER,
    searchKey: 'Cargador de fotos',
    label: <div className="checklist-builder_row">
      <img alt="images" src={ImageLinks.espectro.imagePickerIcon} width={32} height={32} />
      <p>Cargador de fotos & videos</p>
    </div>,
    OPERATORS: [
      {
        label: i18.t(TRANSLATIONS.IS_IMAGE_UPLOADED),
        value: showIfOperators.notEmpty,
      },
      {
        label: i18.t(TRANSLATIONS.IS_NOT_UPLOADED),
        value: showIfOperators.empty,
      },
    ],
  },
  {
    value: ChecklistBitTypes.SIGNATURE,
    searchKey: 'Firma',
    label: <div className="checklist-builder_row">
      <img alt="signature" src={ImageLinks.espectro.signature} width={32} height={32} />
      <p>Firma</p>
    </div>,
    OPERATORS: [
      {
        label: i18.t(TRANSLATIONS.IS_SIGNED),
        value: showIfOperators.notEmpty,
      },
      {
        label: i18.t(TRANSLATIONS.IS_NOT_SIGNED),
        value: showIfOperators.empty,
      },
    ],
  },
  {
    value: ChecklistBitTypes.NESTED_CHECKLIST,
    searchKey: 'Checklist',
    label: <div className="checklist-builder_row">
      <img alt="checklist" src={ImageLinks.espectro.checklistIconSvg} width={32} height={32} />
      <p>Checklist</p>
    </div>,
    OPERATORS: [
      {
        label: 'equals',
        value: showIfOperators.equals,
      },
      {
        label: 'is answered',
        value: showIfOperators.notEmpty,
      },
    ],
  },
  {
    value: ChecklistBitTypes.LIST_ITEM,
    searchKey: 'Assets',
    label: <div className="checklist-builder_row">
      <img alt="signature" src={ImageLinks.espectro.assetsIcon} width={32} height={32} />
      <p>Assets</p>
    </div>,
    OPERATORS: [
      {
        label: 'equals',
        value: showIfOperators.equals,
      },
      {
        label: 'is answered',
        value: showIfOperators.notEmpty,
      },
    ],
  },
  {
    value: ChecklistBitTypes.ACK,
    searchKey: 'Instruction',
    label: <div className="checklist-builder_row">
      <img alt="checklist" src={ImageLinks.espectro.instructionSvg} width={32} height={32} />
      <p>Instruction</p>
    </div>,
  },
];

export const blackListedBitsFromLogic = [
  ChecklistBitTypes.ACK,
];

export type IHash = {
  [type in string]: string[];
};
/**
 * Contains a map of props for each bit
 * Note: ? prefix denotes no a compulsory prop
 */
export const BIT_TO_PROPS_MAP:IHash = {
  [ChecklistBitTypes.INPUT]: ['?decimal', '?boundaryValues', 'placeholder'],
  [ChecklistBitTypes.DROPDOWN]: ['options', '?title'],
  [ChecklistBitTypes.NESTED_CHECKLIST]: ['dropdown'],
  [ChecklistBitTypes.VERTICAL_SINGLE_OPTION]: ['options', '?showOthersOption'],
  [ChecklistBitTypes.HORIZONTAL_SINGLE_OPTION]: ['options'],
  [ChecklistBitTypes.VERTICAL_MULTIPLE_OPTION]: ['options', '?showOthersOption'],
  // [ChecklistBitTypes.CAROUSEL]: ['images', '?autoplay'],
  [ChecklistBitTypes.COUNTER]: ['counterSize', 'toggleKeyboardOnPress', 'step'],
  [ChecklistBitTypes.TEXT]: ['?enableParagraphInput', 'placeholder'],
  [ChecklistBitTypes.SECTION]: ['title', '?description'],
  // [ChecklistBitTypes.DATE]: ['minimumDate', 'maximumDate', 'description'],
  // [ChecklistBitTypes.LIST_OF_ITEMS]: ['listItems', 'head'],
  [ChecklistBitTypes.BODY_BOLD]: ['text'],
  [ChecklistBitTypes.BODY20]: ['text'],
  [ChecklistBitTypes.H2]: ['text'],
  [ChecklistBitTypes.SIGNATURE]: ['?autoRemoveWhenSectionTampered', '?hideQuestionUntilSectionAnswered', '?showInputForSigneeName'],
  [ChecklistBitTypes.IMAGE_PICKER]: ['?enableCameraCapture'],
  [ChecklistBitTypes.DATE]: ['?description'],
  [ChecklistBitTypes.DRIBBLE]: ['blueprint'],
  [ChecklistBitTypes.TIME]: ['?description'],
  [ChecklistBitTypes.LIST_ITEM]: ['listType'],
  [ChecklistBitTypes.ACK]: ['instructionType'],
};

export const TYPE_OF_INPUT = [
  {
    value: 'default',
    label: 'Alfabético',
    icon: {
      active: keyboardActive,
      disabled: keyboardDisabled,
    },
  },
  {
    value: 'numeric',
    label: 'Numérico',
    icon: {
      active: numKeyboardActive,
      disabled: numKeyboardDisabled,
    },
  },
];

export const TYPE_OF_CHECKLIST_DIRECTION = [
  {
    value: ChecklistBitTypes.VERTICAL_SINGLE_OPTION,
    label: 'Vertical',
    icon: {
      active: verticalActive,
      disabled: verticalDisabled,
    },
  },
  // {
  //   value: ChecklistBitTypes.HORIZONTAL_SINGLE_OPTION,
  //   label: 'Horizontal',
  //   icon: {
  //     active: horizontalActive,
  //     disabled: horizontalDisabled,
  //   },
  // },
];

export const SECTION_INIT = {
  title: '',
  description: '',
  isExpandedDefault: false,
  isExpanded: true,
  items: [],
};
export const ITEM_INIT = {
  title: '',
  description: '',
  bits: [],
};

export const TRIGGER_INT = {
  trigger: Actions.SHOW_ANSWER,
  sectionIndex: -1,
  itemIndex: -1,
  bitIndex: 0,
  recipient: [],
};

export const LOCAL_LOGIC_INIT = {
  itemIndex: -1,
  sectionIndex: -1,
  operation: showIfOperators.equals,
  value: '',
  // triggers: [TRIGGER_INT],
};

export const isEspectroEnabled = process.env.IS_ESPECTRO_ENABLED || true;
export const QUESTION_MAX_LENGTH = 10000;
export const SECTION_HEADER_MAX_LENGTH = 10000;
export const CHECKLIST_TITLE_MAX_LENGTH = 50;
export const CHECKLIST_TITLE_MAX_LENGTH_V1 = 200;
export const CHECKLIST_DESCRIPTION_MAX_LENGTH = 140;
export const MAX_SOURCE_ITEM_TRIGGER = 8;
