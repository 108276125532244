/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable react/no-array-index-key */
/* eslint-disable import/prefer-default-export */
import './ChecklistQuestions.scss';
import React, { Dispatch, FC, SetStateAction, useState } from 'react';
import { COLOR_CODE, TRANSLATIONS } from 'types/enums';
import { KeyboardDatePicker, KeyboardTimePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import SelectGroupDropdown from 'components/Dropdown/SelectGroupDropdown/SelectGroupDropdown';
import ImageLinks from 'utils/ImageLinks';
import es from 'date-fns/esm/locale/es/index.js';
import { cloneDeepWith } from 'lodash';
import Input from 'components/Input/Input';
import SelectGroupDropdownListItem from 'components/Dropdown/SelectGroupDropdown/SelectGroupDropdownListItem';
import ValueOutOfBoundsModal from 'components/Modal/ValueOutOfBoundsModal';
import { useTranslation } from 'react-i18next';
import { ACK_TYPE } from 'features/Espectro/types';
import ActionButton from '../Buttons/Action/Action';
import { shouldComponentRender } from '../../../espectro-utils/checklistRendererUtils/ChecklistRendererUtils';
import { AnsweredChecklistConfig, ChecklistBit, ChecklistBitTypes, ChecklistProps, ChecklistSection, SetAnswer } from '../../../espectro-utils/checklistRendererUtils/types';
import Dribble from '../Dribble/Dribble';
import SignatureComponent from '../Signature/Signature';
import ImageUpload from '../ImageUpload/ImageUpload';

type Props = {
  bits: ChecklistBit[];
  answers: any;
  bitAnswers: any[];
  setAnswer: (index: number, answer: any) => void;
  customBits?: ChecklistProps['customBits'];
  sectionIndex: number;
  itemIndex: number;
  setBulkAnswers: (updatedAnswers: any) => void;
  checklist: ChecklistSection[];
  isChecklistAnswered?: boolean;
  answeredChecklistConfig?: AnsweredChecklistConfig
};

export const RenderBitInitializer = (
  bit: ChecklistBit, customBitsInitializer: ChecklistProps['customBitsInitializer'] = () => { },
) => {
  const customInitializer = customBitsInitializer(bit.type, bit);
  if (customInitializer !== undefined) return customInitializer;
  // if (bit.defaultAnswer !== undefined) return bit.defaultAnswer;
  if (bit.type === ChecklistBitTypes.INPUT) return '';
  if (bit.type === ChecklistBitTypes.CHECKLIST) return null;
  if (bit.type === ChecklistBitTypes.VERTICAL_SINGLE_OPTION) return null;
  if (bit.type === ChecklistBitTypes.HORIZONTAL_SINGLE_OPTION) return null;
  if (bit.type === ChecklistBitTypes.HORIZONTAL_CHECKLIST) return null;
  if (bit.type === ChecklistBitTypes.COUNTER) return 0;
  if (bit.type === ChecklistBitTypes.DATE) return new Date();
  if (bit.type === ChecklistBitTypes.DROPDOWN) return null;
  if (bit.type === ChecklistBitTypes.LIST_ITEM) return null;
  return '';
};

export const shouldBitProgressConsidered = (
  bit: ChecklistBit, answer: any,
) => {
  if (bit.type === ChecklistBitTypes.INPUT) return !!answer;
  if (bit.type === ChecklistBitTypes.CHECKLIST) {
    return answer ? answer.some(
      (element: boolean) => element === true,
    ) : false;
  }
  if (bit.type === ChecklistBitTypes.VERTICAL_SINGLE_OPTION) return (answer || answer === 0);
  if (bit.type === ChecklistBitTypes.HORIZONTAL_SINGLE_OPTION) return (answer || answer === 0);
  if (bit.type === ChecklistBitTypes.HORIZONTAL_CHECKLIST) {
    return answer ? answer.some(
      (element: boolean) => element === true,
    ) : false;
  }
  if (bit.type === ChecklistBitTypes.COUNTER) return (answer || answer !== 0);
  if (bit.type === ChecklistBitTypes.DATE) return !!answer;
  if (bit.type === ChecklistBitTypes.DROPDOWN) return (answer || answer === 0);
  if (bit.type === ChecklistBitTypes.LIST_ITEM) return (answer || answer === 0);
  return false;
};

const RenderBit = (
  bit: ChecklistBit,
  answer: any,
  setAnswer: SetAnswer,
  customBits: ChecklistProps['customBits'] = () => { },
  componentVisibleId: number,
  setComponentVisibleId: Dispatch<SetStateAction<number | null>>,
  setBulkAnswers: (updatedAnswers: any) => void,
  callerChecklist: ChecklistSection[],
  answers: any,
  sectionIndex: number,
  itemIndex: number,
  bitIndex: number,
  isChecklistAnswered?: boolean,
  answeredChecklistConfig?: AnsweredChecklistConfig,
) => {
  const { t } = useTranslation();
  const { props } = bit;

  const customBitRender = customBits(
    bit.type, answer, setAnswer, bit, sectionIndex, itemIndex, bitIndex,
  );

  const [showValueOutOfBoundsModal, setShowValueOutOfBoundsModal] = useState<boolean>(false);

  if (customBitRender) return customBitRender;

  const toggleValueOutOfBoundsModal = () => setShowValueOutOfBoundsModal(
    !showValueOutOfBoundsModal,
  );

  const onPressNo = () => {
    setAnswer('');
    toggleValueOutOfBoundsModal();
  };

  const onPressYes = () => {
    setAnswer(answer);
    toggleValueOutOfBoundsModal();
  };

  const isCurrentQuestionOfTypeInputAndHasBoundariesProp = (callerChecklist[sectionIndex]
    ?.items[itemIndex].bits[bitIndex].type === ChecklistBitTypes.INPUT)
    && (callerChecklist[sectionIndex]?.items[itemIndex]
    .bits[bitIndex].props?.boundaryValues);

  const validateOutOfBoundaryValue = (input: any) => {
    if (isCurrentQuestionOfTypeInputAndHasBoundariesProp) {
      const boundaryValues = callerChecklist[sectionIndex]?.items[itemIndex]
        .bits[bitIndex].props?.boundaryValues!;

      const { max, min } = boundaryValues;

      if (Number(input || 0) > max || Number(input || 0) < min) {
        setShowValueOutOfBoundsModal(true);
      }
    }
  };

  if (bit.type === ChecklistBitTypes.DROPDOWN) {
    if (isChecklistAnswered) {
      return (
        <div className="answeredBitView">
          <p color="white">{bit.answer}</p>
        </div>
      );
    }
    return (
      <div style={{ borderWidth: 10, borderColor: 'black', borderRadius: 20 }}>
        {Boolean(props.title) && <p className="dropDownLabel">{props.title}</p>}
        <div className="dropdownInputStyle">
          <SelectGroupDropdown
            placeholder={answer ? 'answer' : 'Select'}
            name="Bit Type"
            bgColor="white"
            value={answer || bit.type as string}
            onChange={e => { setComponentVisibleId(null); setAnswer(e.target.value); }}
            dropdownContent={
              props.options!.map(o => ({ label: o.title, value: o.title, searchKey: o.title }))
            }
          />
        </div>
      </div>
    );
  }
  if (bit.type === ChecklistBitTypes.LIST_ITEM) {
    if (isChecklistAnswered) {
      return (
        <div className="answeredBitView">
          <p color="white">{bit.answer}</p>
        </div>
      );
    }

    return (
      <div style={{ borderWidth: 10, borderColor: 'black', borderRadius: 20 }}>
        {
        // @ts-ignore
        Boolean(props?.selectedAssetType) && <p className="dropDownLabel">{props?.selectedAssetType?.name}</p>
        }
        <div className="dropdownInputStyle">
          <SelectGroupDropdownListItem
            placeholder={answer ? answer?.name : 'Select'}
            name="Bit Type"
            // @ts-ignore
            props={props?.selectedAssetType}
            bgColor="white"
            value={answer || bit.type as string}
            onChange={element => {
              setComponentVisibleId(null); setAnswer(element);
            }}
          />
        </div>
      </div>
    );
  }

  if (bit.type === ChecklistBitTypes.DATE) {
    return (
      <div
        style={{ paddingTop: 10, paddingBottom: 10 }}
        onClick={() => setComponentVisibleId(bitIndex)}
      >
        <div
          style={{ flexDirection: 'row', alignContent: 'center' }}
        >
          <p style={{ color: COLOR_CODE.ESPECTRO_PRIMARY, alignItems: 'center' }}>{props.description}</p>
          <div className="checklist-question_calendar_picker">
            <MuiPickersUtilsProvider utils={DateFnsUtils} locale={es}>
              <KeyboardDatePicker
                label={null}
                format={answer ? "'Fecha' dd/MM/yyyy" : `'${t(TRANSLATIONS.PICK_A_DATE)}'`}
                style={{ width: '100%' }}
                value={(answer ? new Date(answer) : new Date())}
                onChange={data => {
                  if (data && !isNaN(data.getTime())) {
                    setAnswer(data);
                  }
                }}
                keyboardIcon={<img src={ImageLinks.calendarCircle} alt="Calender pic" height={32} width={32} style={{ cursor: 'pointer' }} />}
                allowKeyboardControl={false}
                InputAdornmentProps={{
                  position: 'start',
                }}
              />
            </MuiPickersUtilsProvider>
          </div>
        </div>
      </div>
    );
  }

  if (bit.type === ChecklistBitTypes.TIME) {
    // if(isChecklistAnswered){
    //   return (
    //     <View style={dStyles.answeredBitView}>
    //       <Body1620 text={bit.answer} color={"white"} />
    //     </View>
    //   )
    // }
    return (
      <div
        style={{ paddingTop: 10, paddingBottom: 10 }}
        onClick={() => setComponentVisibleId(bitIndex)}
      >
        <div
          style={{ flexDirection: 'row', alignContent: 'center' }}
        >
          <p style={{ color: COLOR_CODE.ESPECTRO_PRIMARY, alignItems: 'center' }}>{props.description}</p>
          <div className="checklist-question_calendar_picker">
            <MuiPickersUtilsProvider utils={DateFnsUtils} locale={es}>
              <KeyboardTimePicker
                label={null}
                format="'Fecha' hh:mm a"
                style={{ width: '100%' }}
                value={(answer ? new Date(answer) : new Date())}
                onChange={data => {
                  if (data && !isNaN(data.getTime())) {
                    setAnswer(data.toString());
                  }
                }}
                keyboardIcon={<img src={ImageLinks.calendarCircle} alt="Calender pic" height={32} width={32} style={{ cursor: 'pointer' }} />}
                InputAdornmentProps={{
                  position: 'start',
                }}
              />
            </MuiPickersUtilsProvider>
          </div>
        </div>
      </div>
    );
  }

  if (bit.type === ChecklistBitTypes.VERTICAL_SINGLE_OPTION
    || bit.type === ChecklistBitTypes.HORIZONTAL_SINGLE_OPTION) {
    /**
     * Added this handler on request of the project team
     * The solution might not be optimal at this time
     * but we've plans to optimize it in the coming future
     */
    const handleOnClickAnswer = (index: number) => {
      const isAlreadySelected = answer === index;
      if (bit.props.isNAMaster) {
        const answersCopy = cloneDeepWith(answers);
        let isNAMaster: boolean | undefined = false;
        let masterParentKey: string | undefined = '';
        answersCopy[sectionIndex][itemIndex][bitIndex] = index;
        callerChecklist.forEach((section, sI) => {
          section.items.forEach((item, iI) => {
            item.bits.forEach((_bit, bI) => {
              isNAMaster = callerChecklist[sI].items[iI].bits[bI]?.props?.isNAMaster;
              if (isNAMaster) {
                masterParentKey = answersCopy[sectionIndex][itemIndex][bitIndex] === 0 ? callerChecklist[sectionIndex].items[itemIndex].bits[bitIndex]?.props?.parentKey : '';
              }
              if (callerChecklist[sI].items[iI].bits[bI]?.props?.parentKey === masterParentKey
                && !isNAMaster) {
                if (isAlreadySelected) {
                  answersCopy[sI][iI][bI] = null;
                } else {
                  answersCopy[sI][iI][bI] = 1;
                }
              }
            });
          });
        });
        if (isAlreadySelected) {
          answersCopy[sectionIndex][itemIndex][bitIndex] = null;
        }
        setBulkAnswers(answersCopy);
      } else {
        let isNAMasterActive: boolean = false;
        if (bit.props.parentKey) {
          callerChecklist[sectionIndex].items.forEach((item, itemI) => {
            item.bits.forEach((bit, bitI) => {
              if (bit.props.isNAMaster && answers[sectionIndex][itemI][bitI] === 0
                && callerChecklist[sectionIndex].items[itemIndex]
                  .bits[bitIndex].props.parentKey === bit.props.parentKey) {
                isNAMasterActive = true;
              }
            });
          });
          if (!isNAMasterActive) {
            if (isAlreadySelected) setAnswer(null);
            else setAnswer(index);
          }
        } else if (isAlreadySelected) setAnswer(null);
        else setAnswer(index);
      }
    };
    // if(isChecklistAnswered){
    //   return <View style={dStyles.answeredBitView}>
    //     <Body1620 text={bit!.props!.options![bit.answer].title} color={"white"} />
    //   </View>
    // }
    return (
      <div>
        {
          props!.options!.length > 8 ? (
            <>
              {Boolean(props.title) && <p className="dropDownLabel">{props.title}</p>}
              {/* <div
                onClick={() => setComponentVisibleId(bitIndex)}
                className="dropdownInput"
              >
                <p>{answer ? 'answer' : 'Select'}</p>
              </div> */}
              {/* <BottomSheetDropDown
                isOpen={componentVisibleId === bitIndex}
                onClose={(i) => { setComponentVisibleId(null); setAnswer(i?.value) }}
                list={props.options?.map(o => ({ label: o.title, value: o.title }))}
                searchBarProps={{
                  isSearchBarEnabled: true,
                  searchBarPlaceholder: "Busca aquí"
                }}
              /> */}
            </>
          )
            : (
              <div style={{ marginTop: 16, marginBottom: 16, display: 'flex', flexDirection: 'column', gap: 2 }}>
                {props!.options!.map((entry, index) => (
                  <ActionButton
                    key={`${entry.title}-${index}`}
                    onClick={() => { handleOnClickAnswer(index); }}
                    text={entry.title}
                    background={index === answer ? '#6C63FF' : 'whitesmoke'}
                    style={{ color: index === answer ? 'white' : 'gray', textAlign: 'left' }}
                    className="verticalSingleOption"
                  />
                ))}
              </div>
            )
        }
      </div>
    );
  }

  if (bit.type === ChecklistBitTypes.VERTICAL_MULTIPLE_OPTION) {
    // if(isChecklistAnswered){
    //   return <View style={dStyles.answeredBitView}>
    //     {bit.answer.map((a: string) => (
    //       <Body1620 text={a} color={"white"} />
    //     ))}
    //   </View>
    // }
    const handleOnClickAnswer = (index:number, item:string) => {
      const isAlreadySelected = answer.includes(item);
      if (isAlreadySelected) {
        let temp = [...answer];
        temp = temp.filter(value => {
          return value !== item;
        });
        setAnswer([...temp]);
      } else {
        setAnswer([...answer, item]);
      }
    };
    console.log({ answer });
    return (
      <div style={{ marginTop: 16, marginBottom: 16, display: 'flex', flexDirection: 'column', gap: 2 }}>
        {props!.options!.map((entry, index) => (
          <ActionButton
            key={`${entry.title}-${index}`}
            onClick={() => { handleOnClickAnswer(index, entry.title); }}
            text={entry.title}
            background={answer !== null && String(answer).includes(entry?.title) ? '#6C63FF' : 'whitesmoke'}
            style={{ color: answer !== null && String(answer).includes(entry?.title) ? 'white' : 'gray', textAlign: 'left' }}
            className="verticalSingleOption"
          />
        ))}
      </div>
    );
  }

  if (bit.type === ChecklistBitTypes.DRIBBLE) {
    return (
      <>
        {answer === '' || answer === undefined ? (
          <Dribble
            onDribleSave={setAnswer}
            blueprint={bit.props.blueprint}
          />
        ) : (
          <Dribble
            imageUrl={answer}
            onDribleSave={setAnswer}
            blueprint={bit.props.blueprint}
          />
        )}
      </>
    );
  }

  if (bit.type === ChecklistBitTypes.SIGNATURE) {
    return (
      <>
        <text className="signatureTitle">Agregar firma</text>
        <SignatureComponent onSignatureSave={setAnswer} />
      </>
    );
  }

  if (bit.type === ChecklistBitTypes.IMAGE_PICKER) {
    return (
      <ImageUpload
        image={answer}
        onImageChange={setAnswer}
      />
    );
  }

  if (bit.type === ChecklistBitTypes.INPUT) {
    return (
      <>
        {Boolean(props.title)
        && (
        <div style={{ marginTop: 16, marginBottom: 16 }}>
          <p>{props.title as string}</p>
        </div>
        )}
        <Input
          value={answer}
          placeholder={props.placeholder}
          style={{ marginTop: 10 }}
          handleOnBlurCapture={
            (e: React.FocusEvent<HTMLInputElement>) => validateOutOfBoundaryValue(e.target?.value)
          }
          onChange={e => {
            let formattedText = e.target.value;
            if (props.keyboardType === 'numeric') {
              if (props.numericType === 'decimal') {
                formattedText = e.target.value.replace(/,/g, '');
                const decimalIndex = formattedText.indexOf('.');
                if (decimalIndex !== -1) {
                  formattedText = formattedText.slice(0, decimalIndex + 1) + formattedText
                    .slice(decimalIndex + 1).replace(/\./g, '');
                }
              } else {
                formattedText = e.target.value.replace(/[.,]/g, '');
              }
            }
            setAnswer(formattedText);
          }}
        />
        {
          showValueOutOfBoundsModal && (
            <ValueOutOfBoundsModal
              onPressNo={onPressNo}
              onPressYes={onPressYes}
              isOpen={showValueOutOfBoundsModal}
              isOpenToggle={toggleValueOutOfBoundsModal}
            />
          )
        }
      </>
    );
  }
  if (bit.type === ChecklistBitTypes.TEXT) {
    return (
      <>
        {Boolean(props.title)
        && (
        <div style={{ marginTop: 16, marginBottom: 16 }}>
          <p>{props.title as string}</p>
        </div>
        )}
        {props.enableParagraphInput ? (
          <textarea
            value={answer}
            placeholder={props.placeholder}
            style={{ marginTop: 10,
              width: '100%',
              minHeight: '100px',
              maxHeight: '200px',
              overflowY: 'auto' }}
            onChange={e => setAnswer(e.target.value)}
          />
        ) : (
          <Input
            value={answer}
            placeholder={props.placeholder}
            style={{ marginTop: 10 }}
            onChange={e => {
              let formattedText = e.target.value;
              if (props.keyboardType === 'numeric') {
                if (props.numericType === 'decimal') {
                  formattedText = e.target.value.replace(/[^\d.-]+/g, '');
                } else {
                  formattedText = e.target.value.replace(/[^0-9]/g, '');
                }
              }
              setAnswer(formattedText);
            }}
          />
        )}
      </>
    );
  }
  if (bit.type === ChecklistBitTypes.ACK) {
    const infoData = callerChecklist?.[sectionIndex]?.items?.[itemIndex];
    return (
      <div className="info_contaier">
        <div>
          <img src={bit?.props?.instructionType === ACK_TYPE.INFO ? ImageLinks.espectro.info : bit?.props?.instructionType === ACK_TYPE.ERROR ? ImageLinks.espectro.dangerRed : ImageLinks.espectro.success} alt="info" height={16} width={16} />
        </div>
        <div className="info_right_container">
          <p className="info_title">{infoData?.title}</p>
          <p className="info_decription">{infoData?.description}</p>
        </div>
      </div>
    );
  }
};

const Renderbits: FC<Props> = ({
  bits,
  bitAnswers,
  answers,
  setAnswer,
  customBits,
  sectionIndex,
  itemIndex,
  setBulkAnswers,
  checklist,
  isChecklistAnswered,
  answeredChecklistConfig,
}) => {
  const [componentVisibleId, setComponentVisibleId] = useState < number | null>(null);
  return (
    <>
      {bits.map((bit: ChecklistBit, index: number) => {
        const answer = bitAnswers[index];
        if (!shouldComponentRender(
          answers,
          bit.showIf,
          sectionIndex,
          itemIndex,
          index,
          checklist,
        )) return null;

        return (
          <React.Fragment key={`${bit.type}-${index}`}>
            {
              RenderBit(
                bit,
                answer,
                a => setAnswer(index, a),
                customBits,
                componentVisibleId as number,
                setComponentVisibleId,
                updatedAnswers => setBulkAnswers(updatedAnswers),
                checklist,
                answers,
                sectionIndex,
                itemIndex,
                index,
                isChecklistAnswered,
                answeredChecklistConfig,
              )
            }
          </React.Fragment>
        );
      })}
    </>
  );
};

export default Renderbits;
