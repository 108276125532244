import React, { FC, useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { COLOR_CODE_NEW, TRANSLATIONS } from 'types/enums';
import InputWithLabel from 'components/Input/InputWithLabel';
import DayPickerInput from 'react-day-picker/DayPickerInput';
import 'react-day-picker/lib/style.css';
import request from 'utils/request';
import useDebounce from 'utils/hooks/useDebounce';
import BasicButton from 'components/Button/BasicButton';
import { format } from 'date-fns/esm';
import LoadingSpinner from 'components/LoadingSpinner';
import { addMinutes } from 'date-fns';

type Props = {
  removeHeader?: boolean;
};

const VerifyRoutine: FC<Props> = () => {
  const { t } = useTranslation();

  const [shortId, setShortId] = useState('');
  const [location, setLocation] = useState<any>();
  const [pendingDays, setPendingDays] = useState([] as { day: string }[]);
  const [isLoading, setIsLoading] = useState(false);

  const memCallbackLocation = useCallback(async (externalId: any) => {
    setLocation({ name: '...' });
    const res = await request.get('/api/v1/location-from-external-id', {
      externalId,
    });
    setLocation(res.location);
    if (res?.location?.slug) {
      setIsLoading(true);
      const days = await request.get(
        `/api/v1/pending-production-plannings/${res?.location?.slug}`,
      );
      setIsLoading(false);
      setPendingDays(days.pendingDays || []);
    }
  }, []);

  const getLocationFromServer = useDebounce(memCallbackLocation, 1000);

  const updateShortId = (event: any) => {
    setShortId(event.target.value);
    getLocationFromServer(event.target.value);
  };

  return (
    <div className="col-md-4">
      <p style={{ fontSize: 16, fontWeight: 'bold' }}>Verificar dias pendientes</p>
      <div className="card">
        <InputWithLabel
          label="Short ID"
          onChange={updateShortId}
          placeholder="Short ID"
          value={shortId}
          error={location === null ? 'Ubicación no encontrada' : undefined}
        />
        <div style={{ height: 40 }}>
          <p>{ location?.name }</p>
        </div>

        { isLoading ? (
          <LoadingSpinner text=" " height={50} />
        ) : (
          !!pendingDays.length && (
            <div style={{ marginTop: 30 }}>
              <p>Pendientes mes corriente:</p>
              { pendingDays.map(({ day }) => (
                <p key={day}>
                  {
                    format(addMinutes(new Date(day), new Date().getTimezoneOffset()), 'dd LLL')
                  }
                </p>
              ))}
            </div>
          )
        )}

      </div>
    </div>
  );

};

export default VerifyRoutine;
