import React, { useState } from 'react';
import FileDropZone from 'components/FileDropZone';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { TRANSLATIONS } from 'types/enums';
import {
  GkpiFileProcessingStatus,
  useCreateSignedS3UrlForGkpiUploadMutation,
  useStartProcessingGkpiFileMutation,
} from 'generated/graphql';
import { ReactComponent as DocsIcon } from '../../../../assets/icons/docs-icon.svg';
import './UploadGKPIStyles.scss';

// max file-size = 4MB
const MAX_FILE_SIZE = 10 * 1048576;

const UploadGKPI = () => {
  const [createS3Url, S3UrlData] = useCreateSignedS3UrlForGkpiUploadMutation();
  const [startProcessingGKPIFile, processingStatus] = useStartProcessingGkpiFileMutation();
  const [showFileDropZone, setShowFileDropZone] = useState(true);
  const history = useHistory();
  const { t } = useTranslation();

  const fetchSignedS3Url = async () => {
    if (S3UrlData.data?.createSignedS3UrlForGKPIUpload.preSignedUrl) {
      return S3UrlData.data?.createSignedS3UrlForGKPIUpload.preSignedUrl;
    }
    const signedUrl = await createS3Url();
    return signedUrl.data?.createSignedS3UrlForGKPIUpload.preSignedUrl;
  };
  const onFinishUploading = async () => {
    if (!S3UrlData.data?.createSignedS3UrlForGKPIUpload.preSignedUrl) {
      return;
    }
    setShowFileDropZone(false);
    await startProcessingGKPIFile({
      variables: {
        fileName: S3UrlData.data.createSignedS3UrlForGKPIUpload.fileName,
      },
    });
  };

  function onRetryUpload() {
    setShowFileDropZone(true);
  }

  function onRedirectToAnalytics() {
    history.push('/analytics/GKPI');
  }

  const isProcessingFailed = processingStatus.error
    || processingStatus.data?.startProcessingGKPIFile.status === GkpiFileProcessingStatus.Failed;
  const isProcessingSucceed = processingStatus.data?.startProcessingGKPIFile.status
  === GkpiFileProcessingStatus.Finished;
  return (
    <div className="container d-flex justify-content-center align-items-center hide-overflow-x">
      {showFileDropZone ? (
        <FileDropZone
          fetchSignedS3Url={fetchSignedS3Url}
          onFinishUploading={onFinishUploading}
          maxSize={MAX_FILE_SIZE}
          accept={['application/vnd.ms-excel', 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet']}
          S3fileUrl={S3UrlData.data?.createSignedS3UrlForGKPIUpload.preSignedUrl}
        />
      ) : (
        <div className="file-processing-container">
          <div className="upload-file-Icon">
            <DocsIcon />
          </div>
          <p>{processingStatus.loading && t(TRANSLATIONS.PROCESSING_YOUR_FILE)}</p>
          {isProcessingFailed && (
            <div className="error-container">
              <p className="error-heading"> {t(TRANSLATIONS.ERROR)} </p>
              <p className="error-description">
                {t(TRANSLATIONS.PROCESSING_FAILED_WITH_MESSAGE, {
                  msg: processingStatus.data?.startProcessingGKPIFile.message,
                })}
              </p>
              <p className="retry-upload" onClick={onRetryUpload}>
                {t(TRANSLATIONS.RETRY)}
              </p>
            </div>
          )}
          {isProcessingSucceed && (
            <div className="success-container">
              <p className="success-heading">{t(TRANSLATIONS.DONE)} </p>
              <p className="success-description">{t(TRANSLATIONS.FINISHED_FILE_PROCESSING)}</p>
              <p className="visit-analytics" onClick={onRedirectToAnalytics}>
                {t(TRANSLATIONS.CLICK_TO_VISIT, {
                  location: t(TRANSLATIONS.ANALYTICS),
                })}
              </p>
              <p className="retry-upload" onClick={onRetryUpload}>
                {t(TRANSLATIONS.REUPLOAD)}
              </p>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default UploadGKPI;
