import React, { useEffect, useState } from 'react';
import EmptyState from 'components/EmptyState/EmptyState';
import { useSelector } from 'react-redux';
import PeriodSelector from 'components/PeriodSelector/PeriodSelector';
import { selectTimeFilter } from 'store/selectors/timeFilter.selector';
import axios from 'axios';
import moment from 'moment';
import { switchMonthToSpanish } from 'utils/dateHelpers';
import LoadingSpinner from 'components/LoadingSpinner';
import usePrevious from 'utils/hooks/usePrevious';
import HorizontalBarByMonth from './HorizontalBarByMonth/HorizontalBarByMonth';
import './SifRoute.scss';

const SifRouteBU = () => {
  const [data, setData] = useState<any>([]);
  const timeFilter = useSelector(selectTimeFilter);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const prevTimeFilterSlug = usePrevious(timeFilter.get('slug'));

  const startDate: string = switchMonthToSpanish(
    moment(timeFilter.get('startTime')).format('MMMM'),
  );
  const endDate: string = switchMonthToSpanish(moment(timeFilter.get('endTime')).format('MMMM'));
  const date: string = moment(timeFilter.get('endTime')).format('YYYY');

  function renderContent() {
    if (isLoading) {
      return <LoadingSpinner />;
    }
    if (!data?.length) {
      return <EmptyState />;
    }

    return data?.map((row: any, i: number) => {
      const { data: totalSifRoutes, months, sifPercent } = row[1].reduce(
        (acum: any, curr: any) => ({
          ...acum,
          months: [...acum.months, curr.month],
          data: [...acum.data, curr.totalSifRoutes],
          sifPercent: [...acum.sifPercent, curr.sifPercent.toFixed(2)],
        }),
        { data: [], months: [], sifPercent: [] },
      );
      return (
        <div className="col-6 card routines-home__card" key={i}>
          <h4>{row[0]}</h4>
          <HorizontalBarByMonth
            data={totalSifRoutes}
            percents={sifPercent}
            months={months}
          />
        </div>
      );
    });
  }
  const getRoutinesData = async () => {
    try {
      setIsLoading(true);
      const response = await axios.get('/api/v1/sif-by-bu', {
        params: {
          from_month: startDate,
          to_month: endDate,
          year: date,
        },
      });
      if (response.data) {
        const { sifRoutesBU } = response.data;
        setData(Object.entries(sifRoutesBU));
      }
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };
  useEffect(() => {
    if (!!timeFilter.get('slug') && timeFilter.get('slug') !== prevTimeFilterSlug) {
      getRoutinesData();
    } // it depends only to timeFilter changes
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [timeFilter.get('slug'), prevTimeFilterSlug]);

  return (
    <>
      <div className="row routines-home__filters-row">
        <div role="button">
          <PeriodSelector />
        </div>
      </div>
      <div className="row">
        {renderContent()}
      </div>
    </>
  );
};

export default SifRouteBU;
