import React, { useContext, useEffect, useState, useMemo } from 'react';
import { Tabs, Tab, Box, Drawer } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { fromJS } from 'immutable';
import {
  useGetUserInfoLazyQuery,
  useGetAllAccessLocationsLazyQuery,
  useGetUserWorkLocationsLazyQuery,
} from 'generated/graphql';
import { useTranslation } from 'react-i18next';
import { TRANSLATIONS } from 'types/enums';
import { isEspectroEnabled } from 'features/Espectro/constants';
import { UserManagementContext } from '../context';
import PermissionsTab from './PermissionsTab';
import Profile from './Profile';
import './UserManagement.scss';
import General from './General';
import Accesos from './Accesos';
// import Checklists from './Checklists';
import { getAccessLocationsIds, getFormattedData } from './utils';

const useStyles = makeStyles(theme => ({
  indicator: {
    backgroundColor: isEspectroEnabled ? '#6C63FF' : '#EFBE3F',
    border: 'none',
  },
  root: {
    borderBottom: '1px solid #e8e8e8',
  },
  tabRoot: {
    textTransform: 'none',
    minWidth: 72,
    fontWeight: theme.typography.fontWeightRegular,
    marginRight: theme.spacing(4),
    '&:hover': {
      color: isEspectroEnabled ? '#6C63FF' : '#EFBE3F',
      opacity: 1,
    },
    '&$selected': {
      color: isEspectroEnabled ? '#6C63FF' : '#EFBE3F',
      fontWeight: theme.typography.fontWeightMedium,
    },
    '&:focus': {
      color: isEspectroEnabled ? '#6C63FF' : '#EFBE3F',
    },
  },
}));

const Usermanagement = (props: any) => {
  const [tabIndex, setTabIndex] = useState(0);
  const [isAuthorisedForOWD, setIsAuthorisedForOWD] = useState(false);
  const { t } = useTranslation();
  const classes = useStyles();
  const userContext = useContext(UserManagementContext);
  const {
    user,
    originalUser,
    editingUser,
    isEditing,
    setOriginalUser,
    setEditingUser,
    isUMDrawerVisible,
    setUMDrawerVisibility,
    setIsLoading,
    setAccessLocationsIds,
  } = userContext;

  const [callUserInfo, { data, loading, refetch }] = useGetUserInfoLazyQuery({ fetchPolicy: 'no-cache' });

  const [
    callUserWorkLocations,
    {
      data: dataUserWorkLocations,
      loading: loadingUserWorkLocations,
      refetch: refetchUserWorkLocations,
    },
  ] = useGetUserWorkLocationsLazyQuery({ fetchPolicy: 'no-cache' });

  const [callAccessLocations, { data: userAccessData,
    refetch: userAccessRefetch }] = useGetAllAccessLocationsLazyQuery({
    fetchPolicy: 'no-cache',
  });

  useEffect(() => {
    if (userAccessRefetch) {
      userAccessRefetch({
        id: user?.id,
      });
    } else {
      callAccessLocations({
        variables: {
          id: user?.id,
        },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userAccessRefetch]);

  useEffect(() => {
    const locationAccessData = userAccessData?.getAllAccessLocations;

    setOriginalUser(originalUser.merge(fromJS({ locationAccess: locationAccessData })));
    setEditingUser(editingUser.merge(fromJS({ locationAccess: locationAccessData })));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userAccessData?.getAllAccessLocations]);

  useEffect(() => {
    if (isUMDrawerVisible) {
      if (refetch || refetchUserWorkLocations) {
        if (refetch) {
          refetch({
            id: user?.id,
          });
        }
        if (refetchUserWorkLocations) {
          refetchUserWorkLocations({
            userId: user?.id,
          });
        }
      } else {
        callUserInfo({
          variables: {
            id: user?.id,
          },
        });
        callUserWorkLocations({
          variables: {
            userId: user?.id,
          },
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isUMDrawerVisible]);

  useEffect(() => {
    let tempData;

    const originalAccessLocations = originalUser?.get('locationAccess')?.toJS();
    const editingAccessLocations = editingUser?.get('locationAccess')?.toJS();

    if (isEditing) {
      tempData = getFormattedData(editingAccessLocations);
    } else {
      tempData = getFormattedData(originalAccessLocations);
    }

    if (tempData) {
      setAccessLocationsIds(getAccessLocationsIds(tempData));
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isEditing, originalUser]);

  useEffect(() => {
    const tempObj = {
      profile: data?.getUserInfo,
      general: {
        position: data?.getUserInfo?.position,
        employeeNumber: data?.getUserInfo?.employeeNumber,
        email: data?.getUserInfo?.email,
        pin: data?.getUserInfo?.pin,
        distributionCenter: {
          id: data?.getUserInfo?.distributionCenter?.id,
          name: data?.getUserInfo?.distributionCenter?.name,
          bu: data?.getUserInfo?.distributionCenter?.bu,
          type: data?.getUserInfo?.distributionCenter?.type,
        },
        userWorkLocation: dataUserWorkLocations?.getUserWorkLocations[0] ?? null,
        doctorStatus: data?.getUserInfo?.doctorStatus,
      },
    };

    setIsAuthorisedForOWD(data?.getUserInfo?.info?.isAuthorisedForOWD || false);

    setOriginalUser(fromJS(tempObj));
    setEditingUser(fromJS(tempObj));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, loading, isUMDrawerVisible, dataUserWorkLocations, loadingUserWorkLocations]);

  useEffect(() => {
    setIsLoading(loading);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loading]);

  const isUserNotOnboarded = originalUser?.getIn(['profile', 'name'])?.includes('NEW_ESPECTRO_USER');

  const tabsData = useMemo(() => {
    return [
      {
        id: '1',
        label: t(TRANSLATIONS.GENERAL),
      },
      {
        id: '2',
        label: t(TRANSLATIONS.PERMISSIONS),
      },
      {
        id: '3',
        label: t(TRANSLATIONS.ACCESS),
      },
    ] as any;
  }, [t]);

  const tabsDataEspectro = useMemo(() => {
    if (isUserNotOnboarded) {
      return [
        {
          id: '1',
          label: t(TRANSLATIONS.GENERAL),
        },
      ];
    }
    return [
      {
        id: '1',
        label: t(TRANSLATIONS.GENERAL),
      },
      {
        id: '2',
        label: t(TRANSLATIONS.INSPECTIONS),
      }] as any;
  }, [t, isUserNotOnboarded]);

  return (
    <Drawer
      anchor="right"
      open={isUMDrawerVisible}
      onClose={() => {
        setUMDrawerVisibility(false);
        setTabIndex(0);
      }}
    >
      <div>
        <Profile getUserInfo={props.getUserInfo} isAuthorisedForOWD={isAuthorisedForOWD} />
        <div className="pt-2">
          <Tabs
            classes={{ indicator: classes.indicator, root: classes.root }}
            value={tabIndex}
            onChange={(event: any, newValue: number) => setTabIndex(newValue)}
          >
            {isEspectroEnabled
              ? tabsDataEspectro?.map((tab:any) => {
                return (
                  <Tab
                    disableRipple
                    key={tab.id}
                    classes={{ root: classes.tabRoot }}
                    id={tab.id}
                    label={tab.label}
                  />
                );
              })
              : tabsData?.map((tab:any) => {
                return (
                  <Tab
                    disableRipple
                    key={tab.id}
                    classes={{ root: classes.tabRoot }}
                    id={tab.id}
                    label={tab.label}
                  />
                );
              })}
          </Tabs>
          <TabPanel value={tabIndex} index={0}>
            <General />
          </TabPanel>

          <TabPanel value={tabIndex} index={1}>
            <PermissionsTab {...{ isAuthorisedForOWD, setIsAuthorisedForOWD }} />
          </TabPanel>
          {!isEspectroEnabled
          && (
            <>

              <TabPanel value={tabIndex} index={2}>
                <Accesos />
              </TabPanel>
            </>
          )}

        </div>
      </div>
    </Drawer>
  );
};

function TabPanel(props: any) {
  const { children, value, index, ...other } = props;
  return (
    <div role="tabpanel" hidden={value !== index} id={index} {...other}>
      {value === index && (
        <Box p={3}>
          {children}
        </Box>
      )}
    </div>
  );
}

export default Usermanagement;
