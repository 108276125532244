import React, { Suspense } from 'react';
import {
  BrowserRouter as Router,
  Route,
  Switch,
} from 'react-router-dom';
import SubmitSuccessful from 'features/external-users/AnonymousComplaint/SubmitSuccessful';
import TruckFormSuccess from 'features/external-users/AnonymousTruckComplaint/TruckFormSuccess';
import LoadingSpinner from 'components/LoadingSpinner';
import ResetPassword from 'features/internal-users/user/ForgotPassword/ResetPassword';
import AnonymousTruckScann from 'features/external-users/AnonymousTruckScan';
import HRProfileInformation from 'features/doctor/HRProfileInformation';
import ExtendedView from 'features/safety-academy/ExtendedView/ExtendedView';
import SafetyAcademyHome from 'features/safety-academy/SafetyAcademyHome';
import FileUploadHome from 'features/file-upload/FileUploadHome';
import PostUpload from 'features/file-upload/PostUpload/PostUpload';
import Boards from 'features/action-logs/Boards';
import PatientStatusExternalLink from 'features/PatientStatusExternalLink';
import FileUploadAnalytics from 'features/file-upload/FileUploadAnalytics';
import RecoverPassword from 'features/internal-users/user/RecoverPassword/RecoverPassword';
import useReloadWebsite from 'utils/hooks/useReloadWebsite';

import { useSelector } from 'react-redux';
import { selectUserType } from 'store/selectors/user.selectors';
import { userOnlyHasAccessToSearchUserScreen } from 'utils/canUserAccessFeature';
import UserManagement from 'features/internal-users/user/UsersIndexN/UserManagement';
import OWD from 'features/owd';
import RegisterNewAccount from 'features/internal-users/user/RegisterNewAccount/RegisterNewAccount';
import PDT from 'features/pdt';
import DownloadReport from 'features/Espectro/user-download/DownloadReport';
import ChecklistReport from 'features/Espectro/user-download/ChecklistReport/ChecklistReport';
import ChecklistRenderer from 'features/Espectro/ChecklistRenderer';
import AddAsset from 'features/Espectro/AssetBuilder/AddAsset';

import AssetsDashboard from 'features/Espectro/Assets/AssetsDashboard';
// import AssetType from 'features/Espectro/Assets/AssetType';

import AssetInfo from 'features/Espectro/Assets/AssetInfo';
import AuthWrapper from './features/internal-users/user/AuthWrapper';
import Login from './features/internal-users/user/Login';
import Register from './features/internal-users/user/Register';
import PrivacyPolicy from './features/privacy-policy/PrivacyPolicy';
import DistributionCenters from './features/internal-users/DistributionCenters/DistributionCenters';
import DistributionCenterInfo from './features/internal-users/DistCenterWrapper/DistributionCenter';
import SafetyDashboard from './features/internal-users/SafetyDashboard';
import UsersIndex from './features/internal-users/user/UsersIndex';
import UsersIndexN from './features/internal-users/user/UsersIndexN';

import UserManagementDashboard from './features/users-management/UserManagementDashboard';
import Logout from './features/Logout';
import BackendEdit from './features/internal-users/BackendEdit';
import AnalyticsWrapper from './features/analyticsWrapper/AnalyticsWrapper';
import OverviewPage from './features/analytics/OverviewPage';
import DailyProductionPlanningUpload from './features/internal-users/DailyProductionPlanningUpload';
import AddSupplyRoutine from './features/internal-users/AddSupplyRoutine';
import SupplyRoutineMobile from './features/internal-users/SupplyRoutineMobile';
import AnonymousComplaint from './features/external-users/AnonymousComplaint';
import AnonymousTruckComplaint from './features/external-users/AnonymousTruckComplaint';
import AnonymousSellBrands from './features/external-users/AnonymousSellBrands';
/**
 * Espectro Screens
*/
import ChecklistBuilder from './features/Espectro/ChecklistBuilder';
import NewChecklist from './features/Espectro/NewChecklist';
import WorkOrders from './features/Espectro/WorkOrders';
import AssetType from './features/Espectro/Assets/AssetType';
import NewWorkOrder from './features/Espectro/NewWorkOrder';
import CancelWorkOrder from './features/Espectro/CancelWorkOrder';
import UserManual from './features/Espectro/UserManual';
import PdfViewer from 'features/Espectro/components/PdfRenderer/PdfRenderer';

export default () => {
  const userType = useSelector(selectUserType);
  return (
    <Router>
      <Suspense
        fallback={(
          <LoadingSpinner
            style={{
              position: 'fixed',
              top: '50%',
              right: '50%',
              marginLeft: 30,
              marginBottom: 30,
            }}
          />
      )}
      >
        {useReloadWebsite()}
        <Switch>
          <Route path="/register/:token" component={Register} />
          <Route path="/answer-checklist/:token" component={ChecklistRenderer} />
          <Route path="/recover-password/:token" component={RecoverPassword} />
          <Route path="/login" component={Login} />
          <Route path="/logout" component={Logout} />
          <Route path="/reset-password" component={ResetPassword} />
          <Route path="/register-account" component={RegisterNewAccount} />
          <Route path="/tc/thank-you" component={TruckFormSuccess} />
          <Route path="/tc" component={AnonymousTruckComplaint} />
          <Route path="/sell-brands" component={AnonymousSellBrands} />
          <Route path="/ts" component={AnonymousTruckScann} />
          <Route path="/privacy-policy" component={PrivacyPolicy} />
          <Route path="/academy/extended-view/:slug" component={ExtendedView} />
          <Route path="/health-status/:userSlug/:userName" component={PatientStatusExternalLink} />
          {(userOnlyHasAccessToSearchUserScreen(userType))
          && (
          <Router>
            <AuthWrapper path="/all-users" component={UsersIndexN} />
          </Router>
          )}
          <AuthWrapper
            path="/distribution-centers/:distCenter/users"
            component={UserManagementDashboard}
          />
          <AuthWrapper
            path="/distribution-centers/:distCenter/users-access"
            component={UsersIndex}
          />
          <AuthWrapper
            path="/distribution-centers/:distCenter/s"
            component={SafetyDashboard}
          />
          <AuthWrapper
            path="/distribution-centers/:distCenter"
            component={DistributionCenterInfo}
          />
          <AuthWrapper
            path="/distribution-centers"
            component={DistributionCenters}
          />
          <AuthWrapper
            path="/upload/daily-production-plannings"
            component={DailyProductionPlanningUpload}
          />
          <AuthWrapper
            path="/upload/daily-routine"
            component={AddSupplyRoutine}
          />
          <AuthWrapper
            path="/mobile/daily-routine"
            component={SupplyRoutineMobile}
          />
          <AuthWrapper path="/users" component={UsersIndex} />
          <AuthWrapper path="/all-users" component={UsersIndexN} />

          {/* TODO: Edit following AuthWrapper */}
          <AuthWrapper path="/charts-demo" component={OverviewPage} />
          {/* <AuthWrapper path="/users-manage" component={UserManagementDashboard} /> */}
          {/* <AuthWrapper path="/" component={() => <Redirect to="/users-manage" />} /> */}
          <AuthWrapper path="/backend-edit" component={BackendEdit} />
          <AuthWrapper path="/doctor/hr-profile-information" component={HRProfileInformation} />
          <AuthWrapper path="/safety-academy" component={SafetyAcademyHome} />
          <AuthWrapper exact path="/file-upload" component={FileUploadHome} />
          <AuthWrapper exact path="/file-upload/analytics" component={FileUploadAnalytics} />
          <AuthWrapper exact path="/file-upload/academy" component={PostUpload} />
          <AuthWrapper path="/ac/thank-you" component={SubmitSuccessful} />
          <AuthWrapper path="/ac" component={AnonymousComplaint} />
          <AuthWrapper path="/action-log/board" component={Boards} />
          <AuthWrapper path="/owd" component={OWD} />
          <AuthWrapper path="/pdt" component={PDT} />
          <AuthWrapper path="/user-management-drawer" component={UserManagement} />
          <AuthWrapper path="/espectro/user-manual" component={UserManual} />
          <AuthWrapper path="/espectro/checklist-builder" component={ChecklistBuilder} />
          <AuthWrapper path="/espectro/new-checklist-builder" component={NewChecklist} />
          <AuthWrapper path="/espectro/download-report" component={DownloadReport} />
          <AuthWrapper path="/espectro/checklist-report" component={ChecklistReport} />
          <AuthWrapper path="/espectro/work-orders" component={WorkOrders} />
          <AuthWrapper path="/espectro/new-work-order" component={NewWorkOrder} />
          <AuthWrapper path="/espectro/work-order-info" component={CancelWorkOrder} />
          <AuthWrapper path="/espectro/work-order-report/:workOrderId" component={PdfViewer} />

          <AuthWrapper path="/espectro/assets/add-asset/:assetId" component={AddAsset} />
          <AuthWrapper path="/espectro/assets/add-asset" component={AddAsset} />
          <AuthWrapper path="/espectro/assets-dashboard" component={AssetsDashboard} />
          <AuthWrapper path="/espectro/assets/assets-info/:assetsId" component={AssetInfo} />
          <AuthWrapper path="/espectro/assets/add-asset-type" component={AssetType} />
          <AuthWrapper path="/" component={AnalyticsWrapper} />
        </Switch>
      </Suspense>
    </Router>
  );
};
