import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import Header from 'components/Header';
import NavigationDrawerWithSideMenu
  from 'components/NavigationDrawer/NavigationDrawerWithVerticalMenu';
import defaultRoutes from 'utils/defaultRoutes';
import { selectUser, selectUserLocation, selectUserType } from 'store/selectors/user.selectors';
import './ChecklistBuilder.scss';
import { TRANSLATIONS, UserTypes } from 'types/enums';
import BasicButton from 'components/Button/BasicButton';
import { useHistory } from 'react-router-dom';
import {
  Maybe,
  RoutineAvailable,
  useGetRoutinesAvailableQuery,
  useGenerateShareableLinkForRoutineAvailableMutation,
  useDuplicateChecklistMutation,
  useGetWorkOrdersByRoutineAvailableLazyQuery,
  useScheduleRoutineDeletionMutation,
} from 'generated/graphql';
import InputWithLabel from 'components/Input/InputWithLabel/InputWithLabel';
import CustomTable from 'components/Espectro/Table';
import moment from 'moment';
import 'moment/locale/es';
import { Tooltip, Button, Menu, MenuItem } from '@material-ui/core';
import ImageLinks from 'utils/ImageLinks';
import ShareableChecklistModal from 'components/Espectro/ShareableChecklistModal';
import LoadingSpinner from 'components/LoadingSpinner';
import Modal from 'components/Modal';
import { ToastOptions, toast } from 'react-toastify';
import checklistSearch from '../../../assets/icons/input-search.svg';
import noChecklists from '../../../assets/icons/noChecklists.svg';
import deleteIcon from '../../../assets/icons/delete-empty.svg';

const ChecklistBuilder = () => {
  const userType = useSelector(selectUserType);
  const location = useSelector(selectUserLocation);
  const { t } = useTranslation();
  const history = useHistory();
  moment.locale('es');
  const [selectedRoutineIndex, setSelectedRoutineIndex] = useState(-1);

  const inputRef = useRef<HTMLInputElement>(null);
  const [search, setSearch] = useState('');
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [selectedChecklistId, setSelectedChecklistId] = useState<any>(-1);
  const [selectedChecklisData, setSelectedChecklistData] = useState<any>({});
  const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false);

  const open = Boolean(anchorEl);

  const [searchedRoutines, setSearchedRoutines] = useState<Maybe<{
    __typename?: 'RoutineAvailable' | undefined;
  } & Pick<RoutineAvailable, 'name' | 'slug' | 'id' | 'frequency' | 'updatedAt' | 'createdAt' | 'shareableToken' | 'isShareableLinkActive'>>[] | undefined>([]);

  const onClickNewChecklist = () => {
    if (userType !== UserTypes.EspectroViewOnly) {
      history.push('/espectro/new-checklist-builder');
    }
  };
  const { loading, data, refetch } = useGetRoutinesAvailableQuery();

  const toastConfig = {
    position: 'top-right',
    autoClose: 4000,
    hideProgressBar: true,
    closeOnClick: true,
    pauseOnHover: false,
    draggable: false,
    progress: undefined,
  } as ToastOptions;

  const [
    getWorkOrdersByRoutineAvailableQuery, {
      loading: getWorkOrdersByRoutineAvailableQueryLoading,
      data: getWorkOrdersByRoutineAvailableData,
    },
  ] = useGetWorkOrdersByRoutineAvailableLazyQuery({
    onError(e) {
      setShowDeleteModal(false);
      toast.error(e?.message, toastConfig);
    },
    fetchPolicy: 'cache-and-network',
  });

  let routinesAvailable = data?.getRoutinesAvailable;
  const workOrdersOfSelectedChecklist = getWorkOrdersByRoutineAvailableData
    ?.getWorkOrdersByRoutineAvailable;

  const [generateShareableLink, {
    loading: isGenerateShareableLinkMutationLoading,
  }] = useGenerateShareableLinkForRoutineAvailableMutation({
    onCompleted: d => {
      routinesAvailable = d.generateShareableLinkForRoutineAvailable.routines;
    },
  });

  const [duplicateChecklist, {
    loading: duplicateChecklistLoading,
  }] = useDuplicateChecklistMutation({
    onCompleted: () => {
      refetch();
    },
  });

  const [scheduleRoutineDeletion, {
    loading: scheduleRoutineDeletionLoading,
  }] = useScheduleRoutineDeletionMutation({
    onCompleted: () => {
      setShowDeleteModal(false);
      refetch();
    },
    onError(e) {
      setShowDeleteModal(false);
      toast.error(e?.message, toastConfig);
    },
  });

  function customSort(a: any, b: any) {
    const extractParts = (str: any) => str?.split?.(/([0-9]+)/)?.map?.((s: any) => (isNaN(s) ? s?.toLowerCase() : parseInt(s, 10)));

    const partsA = extractParts(a?.name);
    const partsB = extractParts(b?.name);

    for (let i = 0; i < Math.max(partsA?.length, partsB?.length); i++) {
      if (partsA?.[i] === undefined) return -1;
      if (partsB?.[i] === undefined) return 1;

      if (typeof partsA?.[i] === 'number' && typeof partsB?.[i] === 'number') {
        if (partsA?.[i] < partsB?.[i]) return -1;
        if (partsA?.[i] > partsB?.[i]) return 1;
      } else if (typeof partsA?.[i] === 'number') {
        return -1;
      } else if (typeof partsB?.[i] === 'number') {
        return 1;
      } else {
        const comparison = partsA?.[i]?.localeCompare?.(partsB?.[i]);
        if (comparison !== 0) return comparison;
      }
    }

    return 0;
  }

  const targetList = useMemo(() => {
    if (routinesAvailable) {
      let arrV1 = JSON.parse(
        JSON.stringify(search ? searchedRoutines : routinesAvailable),
      ) as RoutineAvailable[];
      arrV1 = arrV1?.sort(customSort);
      return arrV1;
    }
    return [];
  }, [search, searchedRoutines, routinesAvailable]);

  useEffect(() => {
    refetch();
  }, [refetch]);

  const onClickCard = (id: string) => {
    if (userType !== UserTypes.EspectroViewOnly) {
      history.push('/espectro/new-checklist-builder', { id });
    }
  };

  const convertLastUpdatedDate = (updatedAtDate: string) => {
    const date = moment(updatedAtDate).format('D MMM, hh:mm A');
    const splitDate = date.split(' ');
    let monthName = splitDate[1];
    monthName = monthName[0].toUpperCase() + monthName.slice(1);
    splitDate[1] = monthName;
    return splitDate.join(' ');
  };

  const showShareableLinkPopup = () => {
    const targetRoutineIndex = targetList
      .findIndex((tRoutine: any) => tRoutine.id === selectedChecklistId);
    setSelectedRoutineIndex(targetRoutineIndex);

    const isTokenAvailable = targetList?.[targetRoutineIndex]?.shareableToken !== null;

    if (!isTokenAvailable) {
      generateShareableLink({
        variables: {
          routineAvailableId: String(selectedChecklistId),
        },
      });
    }
    setIsModalOpen(true);
  };

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const onDuplicateChecklist = () => {
    setAnchorEl(null);
    duplicateChecklist({
      variables: {
        routineAvailableId: selectedChecklistId,
      },
    });
  };

  const closeModal = () => {
    setShowDeleteModal(false);
  };

  const onClickDeleteChecklist = () => {
    setAnchorEl(null);
    const isNotDependent = workOrdersOfSelectedChecklist?.length === 0;
    scheduleRoutineDeletion({
      variables: {
        isNotDependent,
        routineAvailableId: selectedChecklistId,
      },
    });
  };

  const onDeleteChecklist = () => {
    setAnchorEl(null);
    setShowDeleteModal(true);
    getWorkOrdersByRoutineAvailableQuery({
      variables: {
        routineAvailableId: selectedChecklistId,
      },
    });
  };

  const share = (index: number, id: any) => (
    <div className="editWrap">
      <div style={{ margin: 0 }}>
        <Button
          id="demo-positioned-button"
          aria-controls={open ? 'demo-positioned-menu' : undefined}
          aria-haspopup="true"
          aria-expanded={open ? 'true' : undefined}
          style={{ borderRadius: 20, boxShadow: 'none', margin: 0 }}
          onClick={(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
            handleClick(e);
            const selectedChecklist = targetList?.filter(i => i?.id === id);
            if (selectedChecklist?.length) {
              setSelectedChecklistData(selectedChecklist?.[0]);
            }
            setSelectedChecklistId(id);
          }}
        >
          <img
            alt="dots"
            style={{ cursor: 'pointer' }}
            width={15}
            height={15}
            src={ImageLinks.espectro.verticalDots}
          />
        </Button>
      </div>
    </div>
  );

  const convertCreatedDate = (createdAtData: string) => {
    const date = moment(createdAtData).fromNow();
    const splitDate = date.split(' ');
    let splitDate1 = splitDate[0];
    splitDate1 = splitDate1[0].toUpperCase() + splitDate1.slice(1);
    splitDate[0] = splitDate1;

    let splitDate2 = splitDate[2];
    splitDate2 = splitDate2[0].toUpperCase() + splitDate2.slice(1);
    splitDate[2] = splitDate2;

    return splitDate.join(' ');
  };

  const searchChecklists = useCallback(() => {
    if (!routinesAvailable || routinesAvailable.length === 0) return;

    const tempRoutines = routinesAvailable?.filter(routine => routine
      ?.name?.toLowerCase().includes(search?.toLocaleLowerCase()));
    if (tempRoutines.length > 0) setSearchedRoutines(tempRoutines);
    else setSearchedRoutines([]);
  }, [routinesAvailable, search]);

  useEffect(() => {
    if (search) {
      searchChecklists();
    } else {
      setSearchedRoutines([]);
    }
  }, [search, searchChecklists]);

  const handleSearch = async (event: any) => {
    setSearch(event.target.value);
  };

  const onHandleConfirm = () => {
    navigator.clipboard.writeText(String(targetList?.[selectedRoutineIndex]?.shareableToken));
    setIsModalOpen(false);
  };

  const userDetails = useSelector(selectUser);

  const shareableToken = String(targetList?.[selectedRoutineIndex]?.shareableToken);

  return (
    <div className="checklist_builder-main-container">
      {selectedRoutineIndex >= 0 && (
        <ShareableChecklistModal
          isOpen={isModalOpen}
          onConfirm={onHandleConfirm}
          onClose={() => setIsModalOpen(false)}
          link={shareableToken}
          routineName={targetList[selectedRoutineIndex]!.name ?? ''}
          isLoading={isGenerateShareableLinkMutationLoading}
        />
      )}
      {showDeleteModal && (
      <Modal
        modalContainerStyles={{
          width: '632px',
          height: 'auto',
          display: 'flex',
          flexDirection: 'column',
          position: 'absolute',
          left: '50%',
          top: '50%',
          transform: 'translate(-50%, -50%)',
          overflow: 'auto',
          padding: '24px',
          maxHeight: '80%',
        }}
      >
        {(getWorkOrdersByRoutineAvailableQueryLoading || scheduleRoutineDeletionLoading) ? (
          <div className="checklist-builder__delete-loader-wrap">
            <LoadingSpinner className="checklist-builder__delete-loader" />
          </div>
        ) : (
          <div>
            <p className="checklist-builder__deleteMoal-title">{t(TRANSLATIONS.DELETE_CHECKLIST)}</p>
            <p className="checklist-builder__deleteMoal-description">{`"${selectedChecklisData?.name}" ${t(TRANSLATIONS.WILL_BE_DELETED_THIS_ACTION_CANNOT_BE_UNDONE)}`}</p>
            {workOrdersOfSelectedChecklist?.length ? (
              <div className="checklist-builder__deleteMoal-wo-description-wrap">
                <p className="checklist-builder__deleteMoal-wo-description">{t(TRANSLATIONS.IT_WILL_GET_DELETED_AS_SOON_AS_THE_FOLLOWING_WORK_ORDERS_ARE_FNISHED)}</p>
                <ul className="custom-bullet">
                  {workOrdersOfSelectedChecklist?.map?.(wo => (
                    <li><p className="checklist-builder__deleteMoal-wo-description">{wo?.name}</p></li>
                  ))}
                </ul>
              </div>
            ) : null}
            <p className="checklist-builder__deleteMoal-pdf-description">{t(TRANSLATIONS.YOU_WILL_STILL_SEE_THE_PDF_REPORTS_AFTER_DELETION)}</p>
            <div className="checklist-builder__deleteMoal-button_container">
              <BasicButton text={t(TRANSLATIONS.CANCEL)} className="checklist-builder__deleteMoal-cancel_button" onClick={closeModal} />
              <BasicButton text={t(TRANSLATIONS.DELETE_PERMANENTLY)} className="checklist-builder__deleteMoal-delete_button" onClick={onClickDeleteChecklist} />
            </div>
          </div>
        )}
      </Modal>
      )}
      <Header />
      <NavigationDrawerWithSideMenu
        {...{
          routes: defaultRoutes(t, userType, location, userDetails),
          openDrawer: true,
        }}
      />
      <div className="checklist-builder__container-v1">
        <div className="checklist-builder__flex-row_v1">
          {
            userType === UserTypes.EspectroViewOnly ? (
              <Tooltip title="Read Only" arrow placement="right">
                <h1>{t(TRANSLATIONS.CHECKLISTS)}</h1>
              </Tooltip>
            ) : (
              <h1>{t(TRANSLATIONS.CHECKLISTS)}</h1>
            )
          }
          <BasicButton
            onClick={onClickNewChecklist}
            className="checklist-builder__upload_button"
            text={t(TRANSLATIONS.NEW_CHECKLIST)}
            background="#6C63FF"
          />
        </div>

        <InputWithLabel
          inputRef={inputRef}
          inputImg={checklistSearch}
          onChange={handleSearch}
          placeholder={t(TRANSLATIONS.SEARCH_CHECKLIST)}
          value={search}
          // label={isEspectroEnabled ? t(TRANSLATIONS.USERS_ESP) : t(TRANSLATIONS.SEARCH_USERS)}
          labelClassName="search-header"
          type="search"
          inputClassName="search-input"
        />

        {(!loading
        && !duplicateChecklistLoading && !scheduleRoutineDeletionLoading) ? (
          <div
            className="checklist-builder__cards-row"
          >
            <CustomTable
              onClickRow={onClickCard}
              header={[
                t(TRANSLATIONS.CHECKLIST_NAME),
                'Version',
                t(TRANSLATIONS.LAST_ACTIVITY),
                t(TRANSLATIONS.CREATION_DATE),
                '',
              ]}
              rows={
                targetList!.map((row, index) => {
                  return {
                    rowData: [
                      row!.name,
                      `${(row!.versionNumber || 1)!.toFixed(2)}`,
                      convertLastUpdatedDate(row!.updatedAt),
                      convertCreatedDate(row!.createdAt),
                      share(index, row!.id),
                    ],
                    index: row!.id,
                  };
                })
              }
            />
          </div>
          ) : (
            <div style={{ height: 400 }}>
              <LoadingSpinner positionTop={200} />
            </div>
          )}
        {
          open && selectedChecklistId !== -1 && (
            <Menu
              id="demo-positioned-menu"
              aria-labelledby="demo-positioned-button"
              anchorEl={anchorEl}
              open={open}
              onClose={handleClose}
              className="checklist_dashboard-option-menu"
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'left',
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
              }}
            >
              <MenuItem
                className="checklist_dashboard-menu-item "
                onClick={(e: React.MouseEvent<HTMLElement>) => {
                  e.stopPropagation();
                  onDuplicateChecklist();
                }}
                style={{
                  paddingLeft: 8,
                }}
              >
                <div>
                  <img src={ImageLinks.espectro.duplicate} style={{ height: 18, width: 18 }} alt="delete" />
                  <p className="duplicateTitle">{t(TRANSLATIONS.DUPLICATE)}</p>
                </div>
              </MenuItem>
              <MenuItem
                className="checklist_dashboard-menu-item"
                style={{
                  paddingLeft: 8,
                }}
                onClick={e => {
                  e.stopPropagation();
                  onDeleteChecklist();
                }}
              >
                <div>
                  <img src={deleteIcon} style={{ height: 18, width: 18 }} alt="delete" />
                  <p className="deleteTitle">{t(TRANSLATIONS.DELETE)}</p>
                </div>
              </MenuItem>
              <MenuItem
                className="checklist_dashboard-menu-item"
                style={{
                  paddingLeft: 8,
                }}
                onClick={e => {
                  e.stopPropagation();
                  showShareableLinkPopup();
                }}
              >
                <div>
                  <img src={ImageLinks.espectro.shareIcon} style={{ height: 18, width: 18 }} alt="share" />
                  <p className="duplicateTitle">{t(TRANSLATIONS.SHARE)}</p>
                </div>
              </MenuItem>
            </Menu>
          )
        }
        {
          targetList?.length === 0 && !loading
            && (
            <div className="checklist-builder__no-checklist-container">
              <img src={noChecklists} style={{ margin: '2rem 0' }} alt="noChecklists" />
              <p className="checklist-builder__no-checklist-h">{t(TRANSLATIONS.NEW_CHECKLIST)}</p>
              <p className="checklist-builder__no-checklist-p">{t(TRANSLATIONS.FIRST_CHECKLIST)}</p>
              <BasicButton
                onClick={onClickNewChecklist}
                className="checklist-builder__upload_button checklist-builder__no-checklist-margin-hor-16"
                text={t(TRANSLATIONS.NEW_CHECKLIST)}
                background="#6C63FF"
              />
            </div>
            )
          }
      </div>
    </div>
  );
};

export default ChecklistBuilder;
