import React, { useEffect, useState } from 'react';
import { Backdrop, Fade, Modal } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { TRANSLATIONS } from 'types/enums';
import './QuestionPopUp.scss';
import BasicButton from 'components/Button/BasicButton';
import SelectGroupDropdown from 'components/Dropdown/SelectGroupDropdown';
import { BIT_TO_PROPS_MAP, BIT_TYPE_SELECT_OPTIONS_V2, QUESTION_MAX_LENGTH } from '../constants';
import { ChecklistBitTypes, ChecklistItem } from '../types';

interface ModalProps {
  isOpen: boolean;
  onClose: () => void;
  defaultData: ChecklistItem;
  onCancel: ()=> void;
  renderConfigureComponents: any;
  sectionIndex: number;
  itemIndex: number;
  onChangeBitFields: any;
  onChangeDefaultAnswer: any;
  onChangeItemFields: any;
}

const QuestionPopUp: React.FC<ModalProps> = ({
  isOpen,
  onCancel,
  defaultData,
  renderConfigureComponents,
  sectionIndex,
  itemIndex,
  onChangeBitFields,
  onChangeDefaultAnswer,
  onChangeItemFields,
}) => {
  const { t } = useTranslation();

  const [search, setSearch] = useState<string>('');
  const [selectedQuestionType, setSelectedQuestionType] = useState<ChecklistBitTypes>();
  const [filteredList, setFilteredList] = useState<any[]>([]);
  const [showUploadImageError, setShowUploadImageError] = useState<boolean>(false);

  const filterQuestionTypes = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value?.trim();
    setSearch(value);
    const filteredQuestionTypes = BIT_TYPE_SELECT_OPTIONS_V2.filter(b => b
      .searchKey.toLowerCase().includes(value.toLowerCase()));
    setFilteredList(filteredQuestionTypes);
  };

  const questionTypesList = filteredList.length > 0 ? filteredList : BIT_TYPE_SELECT_OPTIONS_V2;

  useEffect(() => {
    const bitType = BIT_TYPE_SELECT_OPTIONS_V2
      .find(b => b.value === defaultData?.bits?.[0]?.type)?.value;
    setSelectedQuestionType(bitType as ChecklistBitTypes);
  }, [defaultData]);

  const indexesForBorder = [1, 4, 6, 9, 11];
  const multiValueInputValue = defaultData.bits[0].props.options?.map(
  item => {
    return { value: item.title,
      searchKey: item.title as string,
      label: item.title as string };
  }) || [];

  const getDefaultAnswer = () => {
    const options = defaultData.bits[0]?.props.options;
    const selectedDefaultAnswer = defaultData?.bits?.[0]?.defaultAnswer;
    if (selectedDefaultAnswer !== undefined
      && (options?.length! - 1) >= selectedDefaultAnswer
    ) {
      const defaultAnswer = selectedDefaultAnswer >= 0
        ? options?.[selectedDefaultAnswer].title as string
        : undefined;
      return defaultAnswer;
    }
    return undefined;
  };

  const onSaveClick = () => {
    setShowUploadImageError(true);
    if (defaultData?.bits?.[0]?.type === ChecklistBitTypes.DRIBBLE && (!defaultData?.bits?.[0]?.props?.hasOwnProperty('blueprint') || defaultData?.bits?.[0]?.props?.blueprint === '')) return;
    onCancel();
  };

  return (
    <Modal
      className="comment-modal"
      open={isOpen}
      onClose={onCancel}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 1,
        onClick: () => null,
      }}
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
    >
      <Fade in={isOpen}>
        <div className="question_popup-modal__content">
          <div className="popupContainer">
            <div className="questionContainer">
              <div className="question_popup-search-input">
                <input
                  type="search"
                  className="questionInputLayout"
                  placeholder={`${t(TRANSLATIONS.SEARCH)} ${t([TRANSLATIONS.ANSWER])}`}
                  value={search}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => filterQuestionTypes(e)}
                />
              </div>
              <div className="list-container">
                {
                  questionTypesList?.map((element: any, idx: number) => (
                    <div
                      className={`optionBox ${selectedQuestionType === element.value ? 'question_popup-question-selected' : ''} ${indexesForBorder.includes(idx) ? 'question_popup-border-bottom' : ''}`}
                      key={element.value}
                      role="button"
                      onClick={() => {
                        setSelectedQuestionType(element.value);
                        onChangeBitFields(element.value, 'type', sectionIndex, itemIndex, 0, '');
                      }}
                    >
                      {element.label}
                    </div>
                  ))
                }
              </div>
            </div>
            <div className="questions_popup-configure-bits-wrapper">
              <div className="questions_popup-configure-bits-wrapper-inner">
                <div className="QuestionPopUp__w-full">
                  <div className="pop-up-input-container">
                    <input
                      type="text"
                      style={{ height: 45 }}
                      className="quesion_popup-input-style"
                      placeholder={t(TRANSLATIONS.TITLE)}
                      maxLength={QUESTION_MAX_LENGTH}
                      value={defaultData.title}
                      onChange={e => {
                        onChangeItemFields(
                          e.target.value,
                          'title',
                          sectionIndex,
                          itemIndex,
                        );
                      }}
                    />
                  </div>
                  <div className="pop-up-input-container">
                    <input
                      type="text"
                      style={{ height: 60, marginTop: 10, marginBottom: 29 }}
                      className="input-style"
                      placeholder={t(TRANSLATIONS.DESCRIPTION)}
                      maxLength={QUESTION_MAX_LENGTH}
                      value={defaultData.description}
                      onChange={e => {
                        onChangeItemFields(
                          e.target.value,
                          'description',
                          sectionIndex,
                          itemIndex,
                        );
                      }}
                    />
                  </div>
                </div>
                <div className="QuestionPopUp__w-full QuestionPopUp__flex-1">
                  <p>{t(TRANSLATIONS.ADJUSTMENTS)}</p>
                  <div style={{ paddingLeft: 10, paddingRight: 10, width: '100%', marginTop: 16 }}>
                    {defaultData?.bits?.[0]?.type
                  && BIT_TO_PROPS_MAP[defaultData?.bits?.[0]?.type as ChecklistBitTypes].map(
                    prop => renderConfigureComponents(
                      prop, sectionIndex, itemIndex, 0, defaultData?.bits?.[0],
                    ),
                  )}
                  </div>
                </div>
                {(defaultData?.bits?.[0]?.type === ChecklistBitTypes.DROPDOWN
                  || defaultData?.bits?.[0]?.type === ChecklistBitTypes?.VERTICAL_SINGLE_OPTION)
                  && (
                  <>
                    <span className="defaultAnswerDescription">
                      {t(TRANSLATIONS.THIS_WILL_BE_PREFILLED_AS_DEFAULT_ANSWER)}
                    </span>
                    <div className="dropdownInputStyle" style={{ width: '100%', paddingLeft: 10, paddingRight: 10 }}>
                      <SelectGroupDropdown
                        placeholder={t(TRANSLATIONS.SELECT_A_DEFAULT_OPTION)}
                        name="Bit Type"
                        bgColor="white"
                        value={getDefaultAnswer() as string | Object}
                        onChange={e => {
                          onChangeDefaultAnswer(e.target.value, sectionIndex, itemIndex, 0);
                        }}
                        dropdownContent={multiValueInputValue || []}
                      />
                    </div>
                  </>
                  )}
                {(showUploadImageError && (defaultData?.bits?.[0]?.type === ChecklistBitTypes.DRIBBLE && (!defaultData?.bits?.[0]?.props?.hasOwnProperty('blueprint') || defaultData?.bits?.[0]?.props?.blueprint === ''))) ? (
                  <p className="uploadImageErrorTitle">{t(TRANSLATIONS.ADD_AN_IMAGE_TO_SAVE)}</p>
                ) : null}
              </div>
              <div className="QuestionPopUp__w-full QuestionPopUp__flex-end">
                <BasicButton
                  className="checklist-builder__upload_button"
                  onClick={onSaveClick}
                  text={t(TRANSLATIONS.SAVE)}
                  background="#6C63FF"
                />
              </div>
            </div>
          </div>
        </div>
      </Fade>
    </Modal>
  );
};

export default QuestionPopUp;
