import React from 'react';
import Chart from 'react-apexcharts';
import { useTranslation } from 'react-i18next';
import { TRANSLATIONS } from 'types/enums';

const HorizontalBarByMonth = (props : any) => {
  const { t } = useTranslation();
  const { data, months } = props;

  const series = [
    { data, name: t(TRANSLATIONS.TOTAL_SIF_ROUTES) },
    props?.percents
      ? { data: props?.percents, name: t(TRANSLATIONS.SIF_ROUTE_PERCENTAGE) }
      : {},
  ];
  const options = {
    chart: {
      type: 'bar',
      height: 350,
    },
    colors: ['#777DB9'],
    plotOptions: {
      bar: {
        borderRadius: 4,
        horizontal: true,
      },
    },
    dataLabels: {
      enabled: true,
      formatter: (val : number) => {
        return val.toLocaleString();
      },
    },
    xaxis: {
      categories: months,
    },
  };
  return (
    <Chart type="bar" height={350} series={series} options={options} />
  );
};

export default HorizontalBarByMonth;
