import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import MainNavigationHeader from 'components/MainNavigationHeader';
import LoadingSpinner from 'components/LoadingSpinner';
import UserLineChart from 'components/UserLineChart';
import MonthlyTimeFilter from 'components/MonthlyTimeFilter';
import {
  getIncidents,
  downloadIncidents,
  getTotalIncidentsByTime,
} from 'store/actions/distributionCenter.actions';
import {
  setMonthlyDropdownChange,
} from 'store/actions/timeFilter.actions';
import { withTranslation } from 'react-i18next';
import request from 'utils/request';
import IncidentsIndex from './IncidentsIndex';
import { formatTimeLabel } from '../../../../utils/dateHelpers';
import { ReactComponent as LogoutIcon } from '../../../../assets/icons/log-out.svg';
import { TRANSLATIONS } from '../../../../types/enums';

import './DistributionCenter.scss';

type Props = {
  userName: string;
  match: any;
  incidents: any;
  getTotalIncidentsByTime: any;
  getIncidents: any;
  downloadIncidents: any;
  incidentsByTime: any;
  setMonthlyDropdownChange: any;
  timeFilterOption: any;
  startTimeFilter: any;
  t: any;
};

type State = {
};

class DistributionCenter extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {};

    this.downloadIncidentsData = this.downloadIncidentsData.bind(this);
  }

  componentDidMount() {
    const { match } = this.props;
    this.props.getTotalIncidentsByTime(match.params.distCenter);
    this.props.getIncidents(match.params.distCenter);
  }

  componentDidUpdate(prevProps: Props) {
    const { match, startTimeFilter } = this.props;
    if (startTimeFilter !== prevProps.startTimeFilter) {
      this.props.getTotalIncidentsByTime(match.params.distCenter);
      this.props.getIncidents(match.params.distCenter);
    }
  }

  async downloadIncidentsData() {
    const { match } = this.props;
    const { distCenterDetails } = await request.get(`/api/v1/${match.params.distCenter}/dist-center`);
    const isFactory = distCenterDetails.type === 'FACTORY';
    const location = `distributionCenter=${match.params.distCenter}`;
    this.props.downloadIncidents(location, isFactory);
  }

  render() {
    const { incidents, incidentsByTime, timeFilterOption, match, t } = this.props;
    let labels = [];

    if (incidentsByTime) {
      labels = formatTimeLabel(
        incidentsByTime.get('slots').toJS(),
        incidentsByTime.get('slotsType'),
        new Date().getTimezoneOffset(),
      );
    }
    return (
      <>
        <MainNavigationHeader
          configRoutes={[{
            url: `/distribution-centers/${match.params.distCenter}/users-access`,
            label: (
              /* use span since it's going to be inside of a p tag. */
              <span
                className="routes_name"
              >
                {`${t(TRANSLATIONS.BLOCK)}/${t(TRANSLATIONS.UNBLOCK)}`}
              </span>
            ),
          }, {
            url: `/distribution-centers/${match.params.distCenter}/users`,
            label: (
              /* use span since it's going to be inside of a p tag. */
              <span
                className="routes_name"
              >
                {t(TRANSLATIONS.USERS)}
              </span>
            ),
          }, {
            url: '/logout',
            label: (
              /* use span since it's going to be inside of a p tag. */
              <span
                className="routes_name"
              >
                {t(TRANSLATIONS.LOGOUT)}
                <LogoutIcon className="logout_icon" />
              </span>
            ),
          }]}
          userName={`${this.props.userName}`}
          distCenter={match.params.distCenter.replace(/-/g, ' ')}
        />
        <div className="container main-content-margin dist">
          <div className="card row justify-content-between align-items-center p-4">
            <div
              className="row col-12 col-sm-auto  align-items-center clickable clickableOpacity"
              role="button"
              onClick={() => { }}
            >
              <p className="text-big">{t(TRANSLATIONS.YOU_ARE_VIEWING)} <span>{match.params.distCenter.replace(/-/g, ' ') || 'Safety'}</span></p>
            </div>
            <MonthlyTimeFilter
              onChange={this.props.setMonthlyDropdownChange}
              currentValue={timeFilterOption}
              t={t}
            />
          </div>
          <section>
            <h4>{t(TRANSLATIONS.WEEKLY_REPORTS)}</h4>
            <div className="card row flex-column flex-lg-row justify-content-between align-items-center p-4">
              {incidentsByTime ? (
                <UserLineChart
                  title={t(TRANSLATIONS.ACIS)}
                  data={incidentsByTime.get('slotsMapped').toJS()}
                  immutableData={incidentsByTime.get('slotsMapped')}
                  labels={labels}
                  isAreaChart={false}
                  isCheckBox={false}
                />
              ) : <LoadingSpinner height={341} />}
            </div>
          </section>
          <IncidentsIndex
            incidents={incidents}
            downloadData={this.downloadIncidentsData}
          />
        </div>
      </>
    );
  }
}

function mapStateToProps(state: any) {
  return {
    userName: state.getIn(['user', 'name']),
    incidents: state.getIn(['distCenter', 'incidents']),
    incidentsByTime: state.getIn(['distCenter', 'incidentsByTime']),
    timeFilterOption: state.getIn(['timeFilter', 'dropdownValue']),
    startTimeFilter: state.getIn(['timeFilter', 'startTime']),
  };
}

function mapDispatchToProps(dispatch: any) {
  return bindActionCreators({
    getIncidents,
    downloadIncidents,
    getTotalIncidentsByTime,
    setMonthlyDropdownChange,
  }, dispatch);
}
// @ts-ignore
export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(DistributionCenter));
