import React, { Component, FC, ReactComponentElement } from 'react';
import hoistNonReactStatic from 'hoist-non-react-statics';
import { FetchStatus } from 'types/types';

const withAsyncTrack = (
  WrappedComponent: ReactComponentElement<any, any>
  | FC<any> |
  React.ClassType<any, any, any>
  | React.ComponentType
  | React.ElementType,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  config?: any,
) => {
  function immutablyUpdateFetchStatusObject(
    obj: any,
    key: string | number | symbol,
    value: FetchStatus,
  ) {
    if (typeof obj !== 'object') return obj;

    const copy = { ...obj };

    copy[key] = value;

    return copy;
  }

  class WithNetworkRequest extends Component<any, withAsyncRequestState> {
    // eslint-disable-next-line react/static-property-placement
    static displayName: string;

    constructor(props: any) {
      super(props);
      this.state = {
        fetchStatus: {},
      };
      this.makeRequest = this.makeRequest.bind(this);
    }

    async makeRequest(requestName: string,
      requestPromise: Promise<any>,
      isRevertsToInitial?: boolean,
      revertToInitialTimeoutInMs?: number) {
      this.setState(cur => ({
        fetchStatus: immutablyUpdateFetchStatusObject(cur.fetchStatus,
          requestName,
          'PENDING'),
      }));

      // eslint-disable-next-line no-async-promise-executor
      return new Promise((async (resolve, reject) => {
        try {
          const response = await requestPromise;

          this.setState(cur => ({
            fetchStatus: immutablyUpdateFetchStatusObject(cur.fetchStatus,
              requestName,
              'SUCCESS'),
          }));
          resolve(response);
        } catch (e) {
          this.setState(cur => ({
            fetchStatus: immutablyUpdateFetchStatusObject(cur.fetchStatus,
              requestName,
              'FAILURE'),
          }));
          reject(e);
        }

        if (isRevertsToInitial === false) return;

        setTimeout(() => {
          this.setState(cur => ({
            fetchStatus: immutablyUpdateFetchStatusObject(cur.fetchStatus,
              requestName,
              'NO_REQUEST'),
          }));
        }, revertToInitialTimeoutInMs ?? 1500);
      }));
    }

    render() {
      const { fetchStatus } = this.state;
      return (
        <WrappedComponent
          asyncTrack={{
            makeRequest: this.makeRequest,
            fetchStatus,
          }}
          {...this.props}
        />
      );
    }
  }

  hoistNonReactStatic(WithNetworkRequest, WrappedComponent);
  WithNetworkRequest.displayName = `WithNetworkRequest(${WrappedComponent.displayName || WrappedComponent.name || 'Component'})`;
  return WithNetworkRequest;
};

export type withAsyncRequestState = {
  fetchStatus: { [name: string]: FetchStatus }
};

export default withAsyncTrack;
