import React, { useEffect, useState } from 'react';
import GeneralPlanning from './GeneralPlanning';
import ProductionTimeChart from './ProductionTimeChart';
import FactoryRanking from './FactoryRanking';
import FactoryPlanning from './FactoryPlanning';
import BusinessUnitRanking from './BusinessUnitRanking';

type Props = {
};

export default function AnalyticsProductionPlanning() {
  return (
    <div className="container-fluid">
      <div className="row">
        <div className="col-3 pr-2">
          <div className="card mb-3">
            <GeneralPlanning />
          </div>
        </div>
        <div className="col-9 px-2">
          <div className="card p-0 mb-3">
            <ProductionTimeChart />
          </div>
        </div>
        {/* <div className="col-4 pl-2">
          <div className="card mb-3">
            <BusinessUnitRanking />
          </div>
        </div> */}
      </div>
      <div className="row">
        <div className="col-4 pr-2">
          <div className="card mb-3">
            <FactoryPlanning />
          </div>
        </div>
        <div className="col-8 pl-2">
          <div className="card mb-3">
            <FactoryRanking />
          </div>
        </div>
      </div>
    </div>
  );
}
