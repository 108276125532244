import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { format } from 'date-fns';

import { HEALTH_STATUS, PLACE_OF_QUARANTINE, TRANSLATIONS } from 'types/enums';
import ImageLinks from 'utils/ImageLinks';

type props = {
  changeStep: (step: number) => void;
  healthStatus: any;
  status: string;
  location: string;
};
function UpdateStatusResult(props: props) {
  const { t } = useTranslation();
  const { changeStep, healthStatus, status, location } = props;

  function goToStep0() {
    changeStep(0);
  }
  return (
    <section className="success_container">
      <div className="root_padding success_icon_container">
        <img className="success_image" src={ImageLinks.greenSuccess} alt="success" />
        <p className="success_text">
          {t(TRANSLATIONS.STATUS_UPDATED_AS_OF_TODAY_DATE_MONTH, {
            month: format(new Date(healthStatus?.createdAt ?? new Date()), 'MMM'),
            date: format(new Date(healthStatus?.createdAt ?? new Date()), 'dd'),
          })}
        </p>
      </div>
      <div className="root_padding success_description">
        <img
          className="details_image"
          src={
            location === PLACE_OF_QUARANTINE.HOSPITAL
              ? ImageLinks.medicalKit
              : ImageLinks.house
          }
          alt="medical kit"
        />
        <div className="details_text">
          <p className="details_title">
            {location === PLACE_OF_QUARANTINE.HOSPITAL
              ? t(TRANSLATIONS.HOSPITAL)
              : t(TRANSLATIONS.AT_HOME)}
            ,{t(TRANSLATIONS[(status || healthStatus.status) as HEALTH_STATUS])}
          </p>
          <p className="details_sub_title">
            {t(TRANSLATIONS.UPDATED_TODAY_AT_TIME, {
              time: format(new Date(healthStatus.createdAt), 'h:mm a'),
            })}
            .
          </p>
          <p className="details_footer_text" onClick={goToStep0}>
            Actualizar
          </p>
        </div>
      </div>
      <p className="root_padding ending_text">
        {t(TRANSLATIONS.IF_NOT_NECESSARY_RETURN_TO_UPDATE_TOMORROW)}
      </p>
    </section>
  );
}

export default UpdateStatusResult;
